<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
                    <span class="text-muted ms-1"><Translate :path="'dizionario.base.ELENCO'"></Translate> Varianti</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;" v-on:click="nuovaVariante()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1 ms-1">
							<div class="fs-sm text-muted mb-1">
								<Translate :path="'dizionario.base.BTN_NUOVA'"></Translate> Variante
							</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(5)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>

						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(this.modulo)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
            </div>
        </div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#varianti-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7"></i>
								<span class="d-lg-none ms-1">Varianti</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate> Varianti</span>
							</div>
						</a>
					</li>
				</ul>

				<a class="rounded btn bg-danger bg-opacity-20 btn-sm-light me-2 mt-xs-1" v-on:click="getVarianti()" v-show="ordineHasChanged">Annulla ordinamento</a>
				<a class="rounded btn bg-success bg-opacity-20 btn-sm-light mt-xs-1" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</a>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0 select-small">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
									<i class="ph-gear me-1"></i> Azioni Multiple
									<span class="d-lg-none ms-2">More</span>
								</a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Prodotti selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti i Prodotti</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina Utenti</a>
								</div>
							</div>
						</li>
						<li class="nav-item">

						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="varianti-a">
        		<div class="card shadow mb-xs-3 mb-lg-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="variante-a">
			                        <div class="row">           
			                            <div class="col-5 col-md-1 mbx-1">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Variante" :tabindex="1" v-model="search.ID_VARIANTE.VALUE" />
			                            </div>           
			                            <div class="col-7 col-md-1 mbx-1">
			
			                            </div>                
			                            <div class="col-12 col-md-2 mbx-1">
			
			                            </div>
			                            <div class="d-none d-sm-block col-md-3 mbx-1">
			
			                            </div>
			                            <div class="col-8 col-md-2 mbx-1">
										</div>
										<div class="col-12 col-md-3">
											<div class="row">
												<div class="col-8 col-md-3">
												</div>
												<div class="col-6 col-md-5">
					                                <select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="4">
					                                    <option value="">Tutti </option>   
					                                    <option value="1"> &nbsp; Attivati</option>
					                                    <option value="0"> &nbsp; Disattivati</option>
					                                </select>
					                            </div>
												<div class="col-6 col-md-4 col-lg-4">
													<a href="#collapse-a" class="btn btn-flat-info btn-sm w-100 collapsed" data-bs-toggle="collapse" v-on:click="filters.resizeGrid('collapse-a','tableFixHead','box-card')" role="button" aria-expanded="false">
														+ FILTRI
													</a>
					                            </div>  
					                        </div>
										</div>
					                </div>
								</form>
				            </div>
		                </div>
		            </div>
							 
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="div_table_resp tableFixHead" id="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable text-nowrap"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
			                            <th class="table-head-sm" style="width:2%;"> <input id="chkAllVarianti" v-on:click="filters.selectAll('chkAllVarianti',paginatedList.arr)" type="checkbox" class=""> </th>
			                            <th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
			                            <th class="table-head-sm" style="width:6%;"> #<br /><span class="float-end">Ord. </span></th>
			                            <th class="table-head-sm">Nome Prodotto / SEO /<br />Codice</th>
			                            <th class="table-head-sm" style="width:12%;">Valido Dal /<br />Valido Al</th>
			                            <th class="table-head-sm text-center" style="width:15%;">Tipo pax</th>
										<th class="table-head-sm text-center" style="width:5%;">Hits</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Visibile</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Stato</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Azioni</th>
			                        </tr>
			                    </thead>
		                        <draggable tag="tbody" handle=".handle" @end="ordineChangeHandler($event)">
		                            <tr v-for="variante in paginatedList.arr" v-bind:key="variante.ID_VARIANTE">
		                                <td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="variante.SELEZIONATO" class=""> 
										</td>
		                                <td class="td-class text-center cursor-pointer handle">
											<img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" />
										</td>
		                                <td class="td-class fs-sm">
											<span>{{variante.ID_VARIANTE}}</span><br />
											<small class="badge bg-light text-primary float-end" v-on:click="modificaOrdinamento(prodotto)">{{variante.ORDINE}}</small>
										</td>
		                                <td class="td-class fs-sm">
		                                    <span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="modificaVariante(variante)">{{variante.CODICE_VARIANTE}}</span>
											<br />
											<span class="me-2" style="border: 1px #dddddd solid;"><i class="far fa-code fa-fw"></i> {{variante.CODICE_PRODOTTO}}</span> 
		                                    <span class="badge bg-info me-1">Metti in Evidenza</span> 
		                                    <span class="badge bg-success">Visibile</span> 
		                                </td>
		                                <td class="td-class fs-sm">{{variante.PAX_TYPE}}</td>
		                                <td class=""><span class="badge" v-bind:class="{'bg-success': (variante.TIPO_AZIONE == 'MINUS_VAL' || variante.TIPO_AZIONE == 'MINUS_PERC'),'bg-info':(variante.TIPO_AZIONE == 'FIXED_VAL'), 'badge-danger': (variante.TIPO_AZIONE == 'PLUS_VAL' || variante.TIPO_AZIONE == 'PLUS_PERC')}">{{variante.TARGET}} |  {{variante.TIPO_AZIONE}}  | {{variante.VALORE}}</span></td>
		                                <td class="td-class fs-sm text-center">

										</td>	 
										<td class="td-class fs-sm text-center">
				
										</td>
		                                <td class="td-class text-center">
											<button class="btn btn-warning" v-on:click="openVariante(variante)">

											</button>
										</td>
		                                <td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
	                                                <a href="#" class="dropdown-item" v-on:click="modificaVariante(variante)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Variante</a>
													<div class="dropdown-divider"></div>
	                                                <a href="#" class="dropdown-item" v-on:click="copiaVariante(variante)"><i class="fad fa-copy fa-fw me-2"></i><span class="text-muted me-1">|</span>Copia Variante</a>
													<div class="dropdown-divider"></div>
	                                                <a href="#" class="dropdown-item" v-on:click="deleteVariante(variante)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Variante</a>
	                                            </div>
	                                        </div>
		                                </td>
		                            </tr>
		                        </draggable>
		                    </table>
	                    </div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">VARIANTI: {{ paginatedList.count }}</span> 
								</div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end pag-mobile">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
			                    </div>
	                		</div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
	            			</div>
	        			</div>
					</div>
				</div>	   
    		</div>	
 
		</div>    
	</div>


	<!-- START MODALI -->
	<div class="modal fade" id="popUpVariante" role="dialog" tabindex="-1">
	    <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 85% !important;">SCHEDA VARIANTE</h6>   
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
                </div> 
                <div class="modal-body p-2" style="height: 33rem;"> 
						
					<div class="box_450">

						<div class="row mt-1">
							<div class="col-12 col-md-9">
								<label class="text-danger custom-control custom-control-right">Titolo Variante
									<a class="pop_over" href="#" data-popup="popover" title="" data-html="true" data-content="Add <u>some</u> <i>HTML</i> <strong>stuff</strong>." data-original-title="Popover <span class='text-danger'>title</span>" aria-describedby="popover877761"><i class="far fa-info-circle float-end"></i></a>
								</label>
								<div class="input-group">
									<input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Codice" tabindex="2" v-model="varianteSelezionato.CODICE_VARIANTE">
								</div>
							</div>
						</div>
						<div class="row mt-1">
							<div class="form-group col-6 col-md-4">
								<label style="color:#008a59;font-weight:bold">Cumulabile</label>
								<select class="form-control form-control-sm"   v-model="varianteSelezionato.CUMULABILE">
									<option v-bind:value="1">SI</option>
									<option v-bind:value="0">NO</option>
								</select>
							</div>
							<div class="form-group col-md-4" style="margin-bottom:.75rem">
								<label style="color:#008a59;font-weight:bold">Tipo pax</label>
								<select class="form-control form-control-sm"   v-model="varianteSelezionato.PAX_TYPE">
									<option v-bind:value="'ALL'">ALL</option>
									<option v-bind:value="'INF'">INF</option>
									<option v-bind:value="'CHD'">CHD</option>
									<option v-bind:value="'ADU'">ADU</option>
								</select>
							</div> 
							<div class="form-group col-md-4" style="margin-bottom:.75rem">
								<label style="color:#008a59;font-weight:bold">N° Pax<i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
								<select class="form-control form-control-sm" v-model="varianteSelezionato.PAX_NUM">
									<option value="0">Tutti</option>
									<option v-for="i in 10" v-bind:value="i" v-bind:key="i">{{i}}</option>
								</select>
							</div>
						</div>	
						<div class="row mt-1">
							<div class="form-group col-md-3" style="margin-bottom:.75rem">
								<label style="color:#008a59;font-weight:bold">Anno nascita min</label>
								<select class="form-control form-control-sm"   v-model="varianteSelezionato.ANNO_NASCITA_MIN">
									<option v-for="i in 120" v-bind:value="i + (new Date().getFullYear() - 120)" v-bind:key="i">{{new Date().getFullYear() - 120 + i}}</option>
								</select>
							</div>
							<div class="form-group col-md-3" style="margin-bottom:.75rem">
								<label style="color:#008a59;font-weight:bold">Anno nascita max</label>
								<select class="form-control form-control-sm"   v-model="varianteSelezionato.ANNO_NASCITA_MAX">
									<option v-for="i in 120" v-bind:value="i + (new Date().getFullYear() - 120)" v-bind:key="i">{{new Date().getFullYear() - 120 + i}}</option>
								</select>
							</div>
							<div class="form-group col-md-3" style="margin-bottom:.75rem">
								<label style="color:#008a59;font-weight:bold">Età min</label>
								<select class="form-control form-control-sm" v-model="varianteSelezionato.ETA_MIN">
									<option value="0">0</option>
									<option v-for="i in 100" v-bind:value="i + 1" v-bind:key="i">{{1 + i}}</option>
								</select>
							</div>
							<div class="form-group col-md-3" style="margin-bottom:.75rem">
								<label style="color:#008a59;font-weight:bold">Età max</label>
								<select class="form-control form-control-sm" v-model="varianteSelezionato.ETA_MAX">
									<option value="0">0</option>
									<option v-for="i in 100" v-bind:value="i + 1" v-bind:key="i">{{1 + i}}</option>
								</select>
							</div>
						</div> 
															

					</div>

				</div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaVariante()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>                
            </div>
        </div>
    </div>

</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import { VueDraggableNext } from 'vue-draggable-next'


export default {
    name:"Varianti",  	
	 
    components:{
        Pagine,
        draggable: VueDraggableNext,
    }, 

    data:function(){
        return {
            apiStartup:'travel/varianti',
            filters:global.filters,
			rs : utils.generateRandomString(30),
			GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            arrayVarianti:new Array(),
            varianteSelezionato:{},

            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
	            ID_VARIANTE:{TYPE:'TEXT',VALUE:''},


            },
			ordineHasChanged: false,
            selezionaTutto:0,
            prodotto:{},
        }
    },

    methods : {
		getVarianti : function(){
			utils.ajax(this.apiStartup,{ID_VARIANTE:this.ID_VARIANTE,ANNO:this.search.ANNO_ORDINE.VALUE},(response) => {
				this.ordineHasChanged = false;
				this.arrayVarianti = response.data;
			});
		},

        nuovaVariante : function(){
            this.varianteSelezionato = {
                ID_VARIANTE:0,
                PAX_TYPE:'ALL',
                PAX_NUM:0,
				FLG_COSTO:0,
                ANNO_NASCITA_MIN:new Date().getFullYear()-119,
                ANNO_NASCITA_MAX:new Date().getFullYear(),
                ETA_MIN:0,
                ETA_MAX:100,
                ORDINE:0,
                CUMULABILE:1,
            };
			this.filters.openPopUp('popUpVariante');
        },

        salvaVariante : function(){
            var api = this.varianteSelezionato.ID_VARIANTE == 0 ? "travel/variante/insert" : "travel/variante/update"; 
            utils.ajax(api,this.varianteSelezionato,(response)=>{
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.varianteSelezionato.ID_VARIANTE = response.id_inserito;
					this.filters.closePopUp("popUpVariante"); 
					this.getVarianti();
                }
            });    
        },	

        copiaVariante : function(variante){
            //console.log(variante);
            var rCopy = utils.clone(variante);
            rCopy.ID_VARIANTE = 0;
            rCopy.DATA_START = filters.formatDate(rCopy.DATA_START);
            rCopy.DATA_END = filters.formatDate(rCopy.DATA_END);
            this.varianteSelezionato = rCopy;
			this.filters.openPopUp("popUpVariante"); 
        },

        modificaVariante : function (variante) {
            utils.ajax("travel/variante",{ID_VARIANTE:variante.ID_VARIANTE},(response)=>{
                this.varianteSelezionato = response.data;
				this.filters.openPopUp("popUpVariante"); 
            });
        },	

        deleteVariante : function(variante){      
            utils.alert.confirm("Sei sicuro di voler eliminare la variante selezionata?",
                ()=>{
                    utils.ajax("travel/variante/delete",variante,(response)=>{
                        if (response.esito == 0){
                            utils.alert.success(response.messaggio);
                            for (var i = 0; i < this.arrayVarianti.length ; i++) {
                                if (this.arrayVarianti[i].ID_VARIANTE == variante.ID_VARIANTE){
                                    this.arrayVarianti.splice(i, 1);
                                    break;
                                }
                            }
                        }
                    });                
                },
            this.$root);
        },

      	esportazione : function(formato){
        	utils.report.token({modalita:'esportaProdotti',formato:formato});
      	},

		ordineChangeHandler : function(event){
			this.arrayVarianti = this.filters.draggableChangeHandler(event,this.arrayVarianti,this.search,"ID_VARIANTE");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arrayVarianti.length ; i++){
					arr.push({"ID": this.arrayVarianti[i].ID_VARIANTE,"O": this.arrayVarianti[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'ProdottoTuristico',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getVarianti();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},
		
		openVariante : function(variante){
			this.$root.appoggio.variante = utils.clone(variante);
			global.router.push("Variante");
		}

		
    },	  

    computed:{
        paginatedList(){
            var arr = utils.inGrid(this.arrayVarianti,this.search);
            return arr;
        }

    },		

    created : function(){
        utils.ajax("travel/varianti/startup",{},(response)=>{
            this.arrayVarianti = response.data.VARIANTI;
            
        });
    }
}
</script>