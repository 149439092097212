<template>

    <DatePicker autocomplete="off" class="ms-lg-auto bg-light text-center mt-xs-1" 
        v-model="localValue" 
        :enable-date="isDateEnabled"
        :min-date="minDate"
        :max-date="maxDate"
        :format="'yyyy-MM-dd'"
        auto-apply :hide-navigation="['time']"
        :placeholder="placeholder"  
    />

</template>



<script>

    import DatePicker from '@vuepic/vue-datepicker'


    export default {
        name: 'DatePickerNew',

        components: { DatePicker },

        data:function(){
            return{
                filters:global.filters,
                localValue: this.modelValue
            }
        },

        props: {
            modelValue: String,
            dateAbilitate: Array,
            placeholder: String,
            label: String
        },

        methods: {
            isDateEnabled(date) {
                const str = date.toLocaleDateString('sv-SE');
                const result = this.dateAbilitate.includes(str);
                return result;
            }
        
        },  

        watch:{
            localValue(newVal) {
                console.log(newVal)
                const soloData = this.filters.formatDateOnly(new Date(newVal));
                console.log(soloData);
                if (this.dateAbilitate.length && !this.dateAbilitate.includes(soloData)) {
                    return;
                }
                this.$emit('update:modelValue', soloData);
            },
            modelValue(newVal) {
                this.localValue = newVal;
            }
        },

        computed: {
            dateOrdinate() {
                return [...this.dateAbilitate].sort();
            },
            minDate() {
                return this.dateOrdinate?.[0] ?? null;
            },
            maxDate() {
                return this.dateOrdinate?.at(-1) ?? null;
            }
        },

    }

</script>