<template>

    <div class="modal fade" id="popUpSchedaAzienda" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-xl"> 
            <div class="modal-content"> 
                <div class="modal-header bg-secondary text-white border-0">
                     <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                   	<h6 class="modal-title" style="width: 85% !important;">Scheda: {{azienda.personaGiuridica.ALIAS}}</h6>                           
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body p-2" style="height: 32rem;"> 
                    
                    <div class="card card-noshadow mb-0">
                        <div class="card-body card-body-2">
                            <div class="row">
                                <div class="col-12 col-md-3 col-lg-3">
		                            <div class="sidebar-content">
		                                <div class="card sidebar-section-body text-center">
		                                    <div class="card-img-actions d-inline-block">
		                                        <img v-if="azienda.ID_AZIENDA > 0 && azienda.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('AZIENDE',azienda.URL_AVATAR)" class="img-fluid rounded-circle" alt="" width="150" height="150" />
		                                        <span v-if="azienda.URL_AVATAR == 'placeholder.jpg' || azienda.ID_AZIENDA == 0">
		                                            <img src="../assets/images/placeholders/placeholder.jpg" class="img-fluid rounded-circle" width="150" height="150" alt="">
		                                        </span>
		                                        <div class="card-img-actions-overlay card-img rounded-circle">
		                                            <a href="#" class="btn btn-outline-white btn-icon rounded-pill" v-on:click="uploadImmagineUtente()">
		                                                <i class="ph-pencil"></i>
		                                            </a>
		                                        </div>
		                                    </div>
		                                </div>
		
		                                <ul class="nav nav-sidebar" role="tablist">
		                                    <li class="nav-item" role="presentation">
		                                        <a href="#profile" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">
		                                            <i class="ph-globe me-2"></i>Anagrafica
		                                        </a>
		                                    </li>
		                                    <li v-show="azienda.ID_AZIENDA > 0" class="nav-item" role="presentation">
		                                        <a href="#users" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
		                                            <i class="ph-users me-2"></i>Elenco Collaboratori
		                                        </a>
		                                    </li>
		                                    <li v-show="azienda.FK_ID_LIVELLO == 4 && azienda.ID_AZIENDA > 0" class="nav-item" role="presentation">
		                                        <a href="#commission" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
		                                            <i class="ph-cash me-2"></i>Gestione Commissioni
		                                        </a>
		                                    </li>
		                                    <li v-show="azienda.ID_AZIENDA > 0" class="nav-item" role="presentation">
		                                        <a href="#orders" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
		                                            <i class="ph-bag me-2"></i>Elenco Pratiche
		                                        </a>
		                                    </li>
		                                    <li v-show="azienda.ID_AZIENDA > 0" class="nav-item" role="presentation">
		                                        <a href="#testi" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
		                                            <i class="ph-text-t me-2"></i>Gestione Contenuti
		                                        </a>
		                                    </li>
		                                    <li v-show="azienda.ID_AZIENDA > 0" class="nav-item" role="presentation">
		                                        <a href="#password" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
		                                            <i class="ph-gear me-2"></i>Configurazioni Azienda
		                                        </a>
		                                    </li>
		                                    <li v-show="azienda.ID_AZIENDA > 0" class="nav-item" role="presentation">
		                                        <a href="#logs" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
		                                            <i class="ph-code me-2"></i>Log Accessi
		                                        </a>
		                                    </li>
		                                </ul>
		                            </div>
                                
                                </div>
                                <div class="col-12 col-md-9 col-lg-9">
			                        <div class="tab-content flex-fill">
			                            
			                            <div class="tab-pane fade active show" id="profile" role="tabpanel">
			                               <h5 class="label-bar"><i class="fad fa-globe fa-fw me-1"></i> Anagrafica</h5>
			                               <div class="box-utenti">  
			
				                                <div class="row" v-show="azienda.ID_AZIENDA == 0">
			                                        <div class="col-6 col-md-4 form-group-1">
			                                            <label class="text-danger custom-control custom-control-right">Tipo Azienda:</label>
			                                            <select class="form-select form-select-sm" data-placeholder="Tipo Agenzia" :tabindex="1" v-model="azienda.FK_ID_TIPOLOGIA_AZIENDA">
			                                                <option v-for="t in (tipo == 1 ? $root.arrayTipoAgenzia : $root.arrayTipoFornitore)" v-bind:value="t.ID_OPZIONE_TIPOLOGICA" v-bind:key="t.ID_OPZIONE_TIPOLOGICA">{{ t.DESCRIZIONE }}</option>
			                                            </select> 
			                                        </div>
												</div>
			                                    <PersonaGiuridica :ref="'pg'" :dim="'sm'" v-model="azienda.personaGiuridica"></PersonaGiuridica>
			                                    <!--
			                                    <div class="row">
			                                        <div class="col-6 col-md-4 form-group-1">
			                                            <label class="text-danger custom-control custom-control-right">Stato</label>
			                                            <select class="form-select form-select-sm" data-placeholder="Tipo Agenzia" :tabindex="1" v-model="azienda.FLG_ATTIVO">
			                                                <option v-bind:value="0">NON ATTIVO</option>
			                                                <option v-bind:value="1">ATTIVO</option>
			                                            </select> 
			                                        </div>
			                                    </div>
			                                    -->
				                            </div>
			                            </div>	
			
			                            <div class="tab-pane fade" id="users" role="tabpanel">
			                                <h5 class="label-bar"><i class="fad fa-users fa-fw me-1"></i> Elenco Collaboratori</h5>
			                                <div class="tableFixHead tableFixHeadRoles">
			                                    <table class="table table-hover table-striped table-bordered">
			                                        <thead class="sticky-top">
			                                            <tr class="bg-secondary text-white">
			                                                <th class="table-head-sm-2" style="width: 6.6%;">#</th> 
			                                                <th class="table-head-sm-2" style="">Cognome e Nome</th> 
			                                                <th class="table-head-sm-2" style="width: 20%;">Ruolo</th>  
			                                            </tr>
			                                        </thead> 
			                                        <tbody>
			                                            <tr v-for="utente in azienda.utenti" v-bind:key="utente.ID_UTENTE">
			                                                <td><img v-if="utente.ID_UTENTE > 0" :src="filters.getImgPath('USERS',utente.URL_AVATAR)" class="rounded shadow" alt="" :width="30" ></td>
			                                                <td>
			
			
			                                                    <span class="text-primary fw-bold text-uppercase cursor-pointer" v-on:click="modificaUtente(utente)">{{ utente.SESSO == 1 ? 'Mr' : 'Mrs' }} - {{ utente.NOME }} {{ utente.COGNOME }}</span>
			                                                    <span class="badge badge-danger badge-xs font-size-xs float-end cursor-pointer" v-on:click="showNoteUtente(utente)" style="padding: 0.20rem 0.3rem !important;"><i class="fal fa-info fa-fw"></i> Note</span><br />
			                                                    <a class="badge bg-light rounded-0 badge-xs font-size-xs text-dark me-1" style="border: 1px #dddddd solid; padding: 0.18rem 0.3rem !important;" v-bind:href="`mailto:${utente.EMAIL}`"><i class="fal fa-envelope fa-fw"></i> {{ utente.EMAIL }}</a>
			                                                    <a class="badge bg-light rounded-0 badge-xs font-size-xs text-dark" style="border: 1px #dddddd solid; padding: 0.18rem 0.3rem !important;" v-bind:href="`tel:${utente.TELEFONO}`"><i class="fal fa-phone fa-fw"></i> {{utente.TELEFONO}}</a>
			                                                    <a class="badge bg-light rounded-0 badge-xs font-size-xs text-dark cursor-pointer float-end" style="border: 1px #dddddd solid; padding: 0.18rem 0.3rem !important;margin-top: 2.5px; " v-on:click="resetPassword(utente)"><i class="fal fa-key fa-fw" data-popup="tooltip" title="" data-original-title="Reset Password"></i> Reset Password</a>
			                                                </td> 
			                                                <td style="">
			                                                    Titolare
			                                                    <span class="badge badge-secondary badge-xs font-size-xs cursor-pointer" v-on:click="login(utente)"><i class="fal fa-sign-in-alt fa-fw"></i> Accedi ora</span>
			                                                </td>
			                                            </tr>
			                                        </tbody>
			                                    </table>
			                                </div>
			                            </div>
			
			                            <div class="tab-pane fade" id="commission" role="tabpanel">
			                                <h5 class="label-bar"><i class="fad fa-users fa-fw me-1"></i> Gestione Commissioni</h5>
			                                <div class="box-users">
			                                    <div class="row">
			                                        <div class="col-md-6 mt-2">
			                                            <span class="">Puoi impostare una commissione fissa a tutti i servizi <strong>oppure</strong> personalizzata.</span>
			                                        </div>
			                                        <div class="col-md-3">
			                                            <label class="text-dark custom-control custom-control-right">OnLine</label>
			                                            <select class="form-select form-select-sm" name="agency_gain" id="agency_gain" tabindex="19" onchange="comboGainChangeHandler()">
			                                                <option value="0" selected="selected">0</option> 
			                                                <option value="1">1</option> 
			                                                <option value="2">2</option> 
			                                                <option value="3">3</option> 
			                                                <option value="4">4</option> 
			                                                <option value="5">5</option> 
			                                                <option value="6">6</option> 
			                                                <option value="7">7</option> 
			                                                <option value="8">8</option> 
			                                                <option value="9">9</option> 
			                                                <option value="10">10</option> 
			                                                <option value="11">11</option> 
			                                                <option value="12">12</option> 
			                                                <option value="13">13</option> 
			                                                <option value="14">14</option> 
			                                                <option value="15">15</option> 
			                                                <option value="16">16</option> 
			                                                <option value="17">17</option> 
			                                                <option value="18">18</option> 
			                                                <option value="19">19</option> 
			                                                <option value="20">20</option> 
			                                                <option value="21">21</option> 
			                                                <option value="22">22</option> 
			                                                <option value="23">23</option> 
			                                                <option value="24">24</option> 
			                                                <option value="25">25</option>
			                                            </select>
			                                        </div>
			                                        <div class="col-md-3">
			                                            <label class="text-dark custom-control custom-control-right">OffLine</label>
			                                            <input autocomplete="off" class="form-control form-control-sm" id="agency_comm_offline_diff" name="agency_comm_offline_diff" type="text" value="0.00" tabindex="21">
			                                        </div>
			                                    </div>
			
			                                    <div id="accordion-group" class="mt-3">
			                                        <div class="card mb-0 rounded-bottom-0">
			                                            <div class="card-header card-header-1">
			                                                <h6 class="card-title">
			                                                    <a data-bs-toggle="collapse" class="text-body collapsed" href="#accordion-item-group1" aria-expanded="false">Accordion Item #1</a>
			                                                </h6>
			                                            </div>
			
			                                            <div id="accordion-item-group1" class="collapse" data-parent="#accordion-group" style="">
			                                                <div class="card-body">
			                                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 
			                                                    3 wolf moon officia aute, non cupidatat skateboard dolor brunch.
			                                                </div>
			                                            </div>
			                                        </div>
			                                    </div>                        
			                                </div>
			                            </div>	
			
			                            <div class="tab-pane fade" id="orders" role="tabpanel">
			                                <h5 class="label-bar"><i class="fad fa-shopping-bag fa-fw me-1"></i> Elenco Pratiche</h5>
			                                <div class="box-users">       
			                                    <OrdiniPopUp :modalita="2" :ref="'ordini'"></OrdiniPopUp>
			
			                                </div>
			                            </div>
			
			                           <div class="tab-pane fade" id="testi" role="tabpanel">
			                                <h5 class="label-bar"><i class="fad fa-text fa-fw me-1"></i> Gestione Contenuti</h5>
			                                <div class="box-utenti">
			                                    <div class="alert alert-primary border-0 alert-dismissible fade show">
													Scrivi in questo spazio, note inerenti l'utente.
													<button type="button" class="btn-close" data-bs-dismiss="alert"></button>
			                                    </div>
			                                    <ckeditor :editor="editor" v-model="azienda.NOTE" :config="editorConfig"></ckeditor> 
			                                </div>
			                            </div>	  
			
			                            <div class="tab-pane fade" id="password" role="tabpanel">
			                                <h5 class="label-bar"><i class="fad fa-cogs fa-fw me-1"></i> Configurazioni Personali</h5>
			                                <!-- 17/04/2023 - UTILIZZIAMO LA CORE_OPZIONI_UTENTE (IN QUESTA TABELLA NON CI SONO RIFERIMENTI AD OPZIONI 
			                                     REALMENTE CODIFICATE MA NEL CAMPO LABEL VIENE INSERITA LA DESCRIZIONE DELL'OPZIONE MENTRE NEL CAMPO VALORE IL RELATIVO VALORE) -->
			                                <div class="box-users">
			                                    <form action="#">
			                                        <div class="row mb-2">
			                                            <label class="col-lg-3 col-form-label">SITO WEB:</label>
			                                            <div class="col-lg-1">
															<span class="btn btn-light btn-sm" v-on:click="azienda.enti = filters.select2AddAll(azienda.enti,filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL'))">Tutti</span>
														</div>
			                                            <div class="col-lg-8">
			                                                <Select2 style="padding: 0px 0px 0px 10px; line-height: 19px !important;" :multiple="true" v-bind:options="filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL')" v-model="azienda.enti" :tabindex="17"></Select2>   
			                                            </div>
			                                        </div>
			                                        <div class="row mb-2">
			                                            <label class="col-lg-3 col-form-label">PROMOTORE:</label>
			                                            <!--
			                                            <div class="col-lg-9">
			                                                <div class="col-lg-9">
			                                                    <select v-model="azienda.FK_ID_PROMOTORE" class="form-select form-select-sm" :disabled="opzione.FLG_MODIFICABILE == 0 && $root.utente.FK_ID_LIVELLO > 1">
			                                                        <option v-for="opz in opzione.OPZIONI" v-bind:value="opz.ID_OPZIONE_TIPOLOGICA" v-bind:key="opz.DESCRIZIONE">{{ opz.DESCRIZIONE }}</option>
			                                                    </select>
			                                                </div>
			                                            </div>
			                                            -->
			                                        </div>
			                                        <div class="row mb-2" v-for="opzione in arrayOpzioniAzienda" v-bind:key="opzione.ID_OPZIONE" > <!-- v-show="opzione.FK_ID_LIVELLO == utente.FK_ID_LIVELLO" -->
			                                            <template v-if="opzione.FK_ID_TIPO == this.tipo">
			                                                <label class="col-lg-3 col-form-label">{{ opzione.LABEL }}:</label>
			                                                <div class="col-lg-9">
			                                                    <select v-model="azienda[opzione.CAMPO]" class="form-select form-select-sm" :disabled="opzione.FLG_MODIFICABILE == 0 && $root.utente.FK_ID_LIVELLO > 1">
			                                                        <option v-for="opz in opzione.OPZIONI" v-bind:value="opz.ID_OPZIONE_TIPOLOGICA" v-bind:key="opz.DESCRIZIONE">{{ opz.DESCRIZIONE }}</option>
			                                                    </select>
			                                                </div>
			                                            </template>                                            
					                                </div>
			                                    </form>
				                            </div>
				                        </div>	
			
			                            <div class="tab-pane fade" id="logs" role="tabpanel">
			                                <h5 class="label-bar mb-1"><i class="fad fa-code fa-fw me-1"></i> Log Accessi</h5>
			                                <div class="row mb-1">
			                                    <div class="col-6 col-md-4">
			                                        <select class="form-select form-select-sm">
			                                            <option value="opt1">Seleziona Data</option>
			                                            <option value="opt2">Option 2</option>
			                                            <option value="opt3">Option 3</option>
			                                            <option value="opt4">Option 4</option>
			                                            <option value="opt5">Option 5</option>
			                                            <option value="opt6">Option 6</option>
			                                            <option value="opt7">Option 7</option>
			                                            <option value="opt8">Option 8</option>
			                                        </select>
			                                    </div>
			                                    <div class="col-6 col-md-4">
			                                        <select class="form-select form-select-sm">
			                                            <option value="opt1">Seleziona Modulo</option>
			                                            <option value="opt2">Option 2</option>
			                                            <option value="opt3">Option 3</option>
			                                            <option value="opt4">Option 4</option>
			                                            <option value="opt5">Option 5</option>
			                                            <option value="opt6">Option 6</option>
			                                            <option value="opt7">Option 7</option>
			                                            <option value="opt8">Option 8</option>
			                                        </select>
			                                    </div>
			                                    <div class="d-block d-xl-none col-md-4">
			                                    </div>
			                                </div>
			                                <div class="tableFixHead tableFixHeadRoles">
			                                    <table class="table table-bordered table-hover table-striped table-togglable">
			                                        <thead class="sticky-top">
			                                            <tr class="bg-secondary text-white">
			                                                <th class="p-1 fs-12" style="width:20%;"> Data </th>
			                                                <th class="p-1 fs-12" style="width:20%;">Modulo</th>
			                                                <th class="p-1 fs-12">Pagina</th>
			                                                <th class="p-1 fs-12 text-center" style="width:5%;">Azioni</th>
			                                            </tr>
			                                        </thead>
			                                        <tbody>
			                                            <tr>
			                                                <td class="p-1 font-size-xs">30/12/2021 12:58:01</td>
			                                                <td class="p-1 font-size-xs"><span class="text-primary fw-bold">Home</span></td>
			                                                <td class="p-1 font-size-xs">op=users_list</td>
			                                                <td class="p-1 text-center">
			                                                    <a href="#" class="badge badge-flat border-secondary text-secondary"><i class="fal fa-eye"></i></a>
			
			                                                </td>
			                                            </tr>
			                                        </tbody>
			                                    </table>
			                                </div> 
			                            </div>

			                        </div> 
								</div>
							</div>
						</div>            
                    </div>
					
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi</button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaAzienda()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>
            </div>
        </div>
    </div>	
				   

	<!-- Modal -->		
    <div class="modal fade" id="modalPassword" role="dialog"  tabindex="-1" >
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-secondary text-white">
					<h6 class="modal-title"><i class="fa fa-fw fa-key fa-fw"></i> Reset Password</h6>
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<label>Nuova password</label>
						<input type="password" class="form-control" placeholder="Nuova password" v-model="utenteSelezionato.PASSWORD">
					</div>
					<div class="form-group">
						<label>Conferma password</label>
						<input type="password" class="form-control" placeholder="Conferma nuova password" v-model="utenteSelezionato.CONFERMA_PASSWORD">
					</div>
				</div>
				<div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
					<button type="button" class="btn btn-success float-end btn-sm" v-on:click="doResetPassword()"><i class="fal fa-save fa-fw"></i> SALVA</button>
				</div>
			</div>
		</div>
	</div> 
 
	<div class="modal fade" id="modalNoteUtente" role="dialog"  tabindex="-2" >
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-secondary text-white">
					<h6 class="modal-title"><i class="fa fa-fw fa-key fa-fw"></i> NOTE IMPORTANTI</h6>
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
				</div>
				<div class="modal-body">

					<div class="textarea rounded" v-html="utenteSelezionato.NOTE"></div>

				</div>
				<div class="modal-footer modal-footer-1 bg-light">                                  
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
				</div>
			</div>
		</div>
	</div>

    <div class="modal fade" :id="'popUpUploadImgAzienda'+rs" role="dialog"  tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-light text-dark">
                    <h6 class="modal-title">Upload immagine Profilo</h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">    
                    <Upload :args="argsAzienda"  @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div>
    </div>

    <SchedaUtente :ref="'schedaUtente'" @updateGriglia="refreshAzienda()"></SchedaUtente>

</template>



<script>	 	 

//import PersonaFisica from '@/components/common/PersonaFisica.vue';
import PersonaGiuridica from '@/components/common/PersonaGiuridica.vue';
import SchedaUtente from '@/components/SchedaUtente.vue';
import Select2 from '@/components/utils/Select2.vue'
import Upload from '@/components/utils/Upload.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import OrdiniPopUp from '@/components/OrdiniPopUp.vue'	

export default {
    name:"SchedaAzienda",
    emits:['aziendaSalvata'],
    props:['tipo'],
    components:{
        //PersonaFisica,
        PersonaGiuridica,
        SchedaUtente,
        Select2,
        Upload,
        OrdiniPopUp
    },
    data:function(){
        return{
			filters:global.filters,
            azienda : {personaGiuridica:{}},
            utenteSelezionato:{personaFisica:{}}, 
            arrayTipoAzienda:new Array(),
            arrayNetwork:new Array(),
            //CORRISPONDENZA CON ID DELLE TIPOLOGICHE
            arrayGruppi:new Array(),
            arrayOpzioniTipologiche:new Array(),
            arrayOpzioniAzienda:new Array(
                {CAMPO:'FK_ID_TIPOLOGIA_AZIENDA',LABEL:"TIPOLOGIE",FK_ID_TIPOLOGICA:36,FK_ID_TIPO:1,FLG_MODIFICABILE:0},
                {CAMPO:'FK_ID_NETWORK',LABEL:"NETWORK",FK_ID_TIPOLOGICA:38,FK_ID_TIPO:1,FLG_MODIFICABILE:0},
                {CAMPO:'FK_ID_MODALITA_PAGAMENTO',LABEL:"PAGAMENTI",FK_ID_TIPOLOGICA:39,FK_ID_TIPO:1,FLG_MODIFICABILE:0},
                {CAMPO:'FK_ID_TIPOLOGIA_AZIENDA',LABEL:"TIPOLOGIE",FK_ID_TIPOLOGICA:44,FK_ID_TIPO:2,FLG_MODIFICABILE:0},
                {CAMPO:'FK_ID_MODALITA_PAGAMENTO',LABEL:"PAGAMENTI",FK_ID_TIPOLOGICA:46,FK_ID_TIPO:2,FLG_MODIFICABILE:0},
            ),
			rs : utils.generateRandomString(30),
		    tabSelezionato : "datiAziendali",
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            },
            argsAzienda:{
                modalita:'MOONLIGHT_uploadImgAzienda',
            }
        }
    },

    methods:{
        openSchedaAzienda: function(azienda){
            if (azienda.ID_AZIENDA == 0){
                this.azienda = utils.clone(azienda);
                this.filters.openPopUp("popUpSchedaAzienda");
                this.getTipologicaTipoAzienda();
            }
            else{
                var params = {ID_AZIENDA:azienda.ID_AZIENDA,TIPO:this.tipo};
                utils.ajax("travel/azienda/detail",params, (response) => {
                    this.azienda = response.data;
                    this.argsAzienda.ID_AZIENDA = this.azienda.ID_AZIENDA;
					this.filters.openPopUp("popUpSchedaAzienda");
                    this.getTipologicaTipoAzienda();
                    if (this.tipo == 1){
                        this.$refs.ordini.getOrdini(azienda.ID_AZIENDA);
                    }
                });
            }
            setTimeout(() => {
                //$("#datiAziendali-tab").click();
            }, 300);
        },	  

        getTipologicaTipoAzienda:function(){  
            utils.ajax("travel/azienda/tipologiche",{TIPO:this.tipo}, (response) => {
                var opz = response.data;
                for (var i = 0 ; i < this.arrayOpzioniAzienda.length ; i++){                    
                    var idTipologica = this.arrayOpzioniAzienda[i].FK_ID_TIPOLOGICA;
                    this.arrayOpzioniAzienda[i].OPZIONI = utils.array.select(opz,"FK_ID_TIPOLOGICA",idTipologica);
                }
            });            
        },

        salvaAzienda : function(){
			if (this.tabSelezionato == "datiAziendali"){
	            var api = this.azienda.ID_AZIENDA > 0 ? "travel/azienda/update" : "travel/azienda/insert";
	            this.azienda.FK_ID_TIPO = this.tipo;
	            utils.ajax(api,this.azienda, (response) => {
	                if (response.esito == 0){
	                    utils.alert.success(response.messaggio);
	                    if (!this.azienda.ID_AZIENDA > 0){
	                        this.azienda.ID_AZIENDA = response.id_inserito;
	                    }
	                    this.$emit("aziendaSalvata",this.azienda); 
	                    this.refreshAzienda();
						//this.filters.closePopUp("popUpSchedaAzienda");
	                }
	            });
			}
        }, 

        nuovoUtente : function(){
            this.utenteSelezionato = {ID_UTENTE:0,persona:{},FK_ID_LIVELLO:(this.tipo == 1 ? 4 : 3 ),FK_ID_ENTE:this.$root.idEnteCorrente,FK_ID_PERSONA_GIURIDICA:this.azienda.FK_ID_PERSONA_GIURIDICA};
            this.$refs.schedaUtente.openSchedaUtente(this.utenteSelezionato);
        }, 

        modificaUtente : function(utente){
            utils.ajax('core/admin/utente',{ID_UTENTE:utente.ID_UTENTE}, (response) => {
                this.utenteSelezionato = response.data;
                this.$refs.schedaUtente.openSchedaUtente(response.data);
            });
        },	 

        refreshAzienda : function(){
            utils.ajax("travel/azienda/detail",{ID_AZIENDA:this.azienda.ID_AZIENDA}, (response) => {
                this.azienda = response.data;
            });
        },  
      
        resetPassword : function(utente){
            this.utenteSelezionato = utils.clone(utente);
            this.filters.openPopUp("modalPassword");  
        },

        doResetPassword : function(){
            this.filters.closePopUp('#modalPassword');
            utils.ajax('core/admin/utente/password/reset',this.utenteSelezionato, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }  
            }); 
        },
        
        showNoteUtente : function(utente){
            this.utenteSelezionato = utils.clone(utente);
			this.filters.openPopUp("modalNoteUtente"); 
        },

        login : function(utente){
            utils.ajax('core/admin/utente',{ID_UTENTE:utente.ID_UTENTE}, (response) => {
                if (response.esito == 0){
                    window.location.reload();
                }
            });
        }, 	   

        uploadImmagineAzienda : function(){
            this.filters.openPopUp("popUpUploadImgAzienda"+this.rs);
        },		

        successCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgAzienda"+this.rs);
            utils.ajax("travel/azienda/detail",{ID_AZIENDA:this.azienda.ID_AZIENDA}, (response) => {
                this.azienda = response.data;
            });
        },

        errorCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgAzienda"+this.rs);
        },
    
    }, 

    computed:{
        
    },

  

}
</script>