<template>

    <!-- Page header -->
    <div class="page-header page-header-light">
        <div class="page-header-content header-elements-lg-inline">
            <div class="page-title d-flex">
                <h4 class="d-none d-sm-block">
                    <router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
                    <a href="#" class="d-inline-block text-dark"> Moduli </a> \ 
                    <span class="text-muted">Elenco Banners</span>
					<code> - Lingua italiano</code> 
                </h4>
                <h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Elenco Banners </h4>
                <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
            </div>
            <div class="header-elements d-none">
                <div class="d-flex justify-content-center">
                    <a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovoBanner()"><i class="icon-googleplus5 text-success"></i><span>NUOVO</span></a>
					<a href="#" class="btn btn-link btn-float text-body" v-on:click="$root.confTravelTipologiche(26)"><i class="icon-power2"></i><span>OPZIONI</span></a>
					<a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
                </div>
            </div>
        </div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg navbar-light border-top">
			<div class="text-center d-lg-none w-100">
				<button type="button" class="navbar-toggler dropdown-toggle" data-bs-toggle="collapse" data-target="#navbar-second">
					<i class="icon-menu7 me-2"></i> Menu Banners
				</button>
			</div>
			<div class="navbar-collapse collapse" id="navbar-second">
				<ul class="nav navbar-nav">
					<li class="nav-item">
						<a href="#stato-a" class="navbar-nav-link active" data-bs-toggle="tab">
							<i class="icon-menu7 me-2"></i> Elenco Banners
						</a>
					</li>
				</ul>
				
				<button class="ms-lg-auto btn btn-danger btn-sm text-center" v-on:click="getBanners()" v-show="ordineHasChanged">Annulla ordinamento</button>
				<button class="ms-lg-auto btn btn-success btn-sm text-center" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</button>

				<ul class="navbar-nav ms-lg-auto">
					<li class="nav-item">
						<div class="btn-group btn-group-sm justify-content-center">
							<a href="#" class="btn btn-light btn-labeled btn-labeled-left dropdown-toggle" data-bs-toggle="dropdown"><i class="icon-cog5 me-1"></i> Azioni Multiple</a>

							<div class="dropdown-menu">
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Stato</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-thumbs-up2"></i> Attiva tutti</a>
										<a href="#" class="dropdown-item"><i class="icon-thumbs-down2"></i> Disattiva tutti</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-cog5"></i> Modifiche varie</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-file-excel"></i> Esporta dati</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item"><i class="icon-trash"></i> Elimina</a>
							</div>
						</div>
					</li>
					<li class="nav-item">
						<SelectLingue @change="getBanners()" v-model="currentLang">
						</SelectLingue>
					</li>
				</ul>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-2">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="stato-a">
        		<div class="card shadow mb-0">
		            <div class="collapse show">
			            <div class="card-body card-body-2">
		                    <div class="alert alert-2 alert-secondary alert2 border-0">
								<form action="#" class="">
				                    <div class="row ">
				                  		<div class="col-5 col-md-1 form-group-filter">
				                     		<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Utente" :tabindex="1" />
				                    	</div>	
			                            <div class="col-7 col-md-1 form-group-filter">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Codice" :tabindex="2" />
			                            </div>                
			                            <div class="col-12 col-md-2 form-group-filter">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome" :tabindex="3" />
			                            </div>
			                            <div class="col-md-2 form-group-filter">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Email" :tabindex="4" />
			                            </div>
										<div class="col-12 col-md-6">
											<div class="row"> 
												<div class="col-12 col-md-4 form-group-filter">
			
			                            		</div>
												<div class="col-12 col-md-6 form-group-filter">
		
												</div>
												<div class="col-12 col-md-2 form-group-filter">
													<select class="form-control form-control-sm" data-placeholder="Stato" :tabindex="4" v-model="search.FLG_ATTIVO.VALUE">
					                                    <option value="">Tutti </option>   
					                                    <option value="1"> &nbsp; Attivati</option>
					                                    <option value="0"> &nbsp; Disattivati</option>
					                                </select>
					                            </div>
					                        </div>
								        </div>
					          		</div> 
								</form>  
			            	</div>
						</div>
					</div>
				
			        <div class="card-body card-body-2 card-body-nobottom card-body-notop">
						<div class="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead>
			                        <tr>
			                            <th class="table-head-sm" style="width:2%;"> <input id="chkAllBanners" v-on:click="filters.selectAll('chkAllBanners',paginatedList.arr)" type="checkbox" class=""> </th>
			                            <th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
			                            <th class="table-head-sm" style="width:6%;"> # </th>
			                            <th class="table-head-sm" style="width:4%;"> Img </th>
			                            <th class="table-head-sm">Denominazione</th>
			                            <th class="table-head-sm" style="width:10%"><span>Contratto</span></th>
			                            <th class="table-head-sm" style="width:10%"><span>CF/PI</span></th>
			                            <th class="table-head-sm" style="width:15%;"><span>EMAIL/<br />TEL</span></th>
			                            <th class="table-head-sm" style="width:5%;">Hits</th>
			                            <th class="table-head-sm" style="width:5%;">Stato</th>
			                        	<th class="table-head-sm" style="width:5%;">Azioni</th>
			                        </tr>
			                    </thead>
								<draggable tag="tbody" @end="ordineChangeHandler($event)">
		                            <tr v-for="banner in paginatedList.arr" v-bind:key="banner.ID_BANNER">
		                                <td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="banner.SELEZIONATO" class=""> </td>
		                                <td class="text-center"><img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" /></td>
		                                <td class="fs-12">
											{{banner.ID_BANNER}}<br />
										</td>
		                                <td class="text-center">
											<img v-if="banner.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('COUPONS',banner.URL_AVATAR)" class="rounded" width="50" height="35" alt="" />
											<div v-if="banner.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto">No<br />Photo</div>
		                                </td>
		                                <td class="">
											<span v-on:click="modificaBanner(banner)" class="text-primary text-uppercase fw-bold cursor-pointer">{{banner.TITOLO}}</span><br />
		                                    <span class="badge bg-light badge-xs text-dark me-1" style="border: 1px #dddddd solid; padding: 0.18rem 0.3rem !important;"><i class="fal fa-calendar-alt fa-fw"></i> data</span>
											<span class="badge bg-light badge-xs text-dark me-1" style="border: 1px #dddddd solid; padding: 0.18rem 0.3rem !important;"><i class="fal fa-thumbs-down fa-fw"></i> metti in evidenza</span>
										</td>
		                                <td class=""></td>
		                                <td class=""></td>
		                                <td class="fs-12">
		                                    <i class="fal fa-envelope fa-fw"></i> 
		                                    <br />
		                                    <button class="badge badge-flat border-secondary text-secondary font-size-xs text-muted"><i class="far fa-sign-in-alt fa-fw fa-lg"></i> Accedi ora</button>
										</td>
		                                <td class="fs-12">1</td>
		                                <td class="text-center">
		                                    <ISwitch @toggle="updateStatoBanner(banner)" :size="'sm'" v-model="banner.STATO" style="z-index: 0;"></ISwitch>
		                                </td>
		                                <td class="text-center">
											<div class="list-icons" :tabindex="26">
		                                        <div class="dropdown">
		                                            <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
		                                            <div class="dropdown-menu dropdown-menu-right">
		                                                <a href="#" class="dropdown-item" v-on:click="modificaBanner(banner)"><i class="fal fa-pencil fa-fw"></i> Modifica Agenzia</a>
		                                                <div class="dropdown-divider"></div>
		                                                <a href="#" class="dropdown-item" v-on:click="eliminaBanner(banner)"><i class="fal fa-ban fa-fw"></i> Sospendi Agenzia</a>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </td>
                            		</tr>
								</draggable>
		                    </table>
						</div>
		
		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
		                        <div class="float-end">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
			                    </div>
			                </div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-control wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
                                </select>
                            </div> 
        				</div>
		
                    </div>
                </div>
            </div>

        </div>	
	</div>	


    <!-- Modal -->
    <div class="modal fade" id="popUpBanner"  role="dialog"  >
        <div class="modal-dialog modal-lg"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title modal-title-2 w-89 d-none d-sm-block"> SCHEDA {{bannerSelezionato.ID_BANNER == 0 ? 'NUOVO' : 'MODIFICA'}} COUPON </h6>                                                        
                    <div class="btn-group float-end" v-show="bannerSelezionato.ID_BANNER > 0" :tabindex="2">
                        <button type="button" class="btn btn-yellow btn-yellow-100 btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-bolt fa-fw"></i> AZIONI
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-print"></i> Stampa scheda </a>
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-envelope"></i> Invia comunicazione </a> 
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-paper-plane"></i> Invia sms </a> 
                        </div>
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal">X</button>
                </div>
                <div class="modal-body" style="padding: 0 !important;">

					<div class="row" style="margin-top:10px">
                        <div class="col-md-12 form-group">
                            <label>Username</label>
                            <input v-model="bannerSelezionato.DENOMINAZIONE" class="input-sm form-control input-sm" placeholder="Username" type="text">
                        </div>
                        <div class="col-md-6 form-group" v-show="bannerSelezionato.ID_BANNER == 0">
                            <label>Password</label>
                            <input v-model="bannerSelezionato.PASSWORD" class="input-sm form-control input-sm" placeholder="Password" type="password">
                        </div>
                        <div class="col-md-6 form-group" v-show="bannerSelezionato.ID_BANNER == 0">
                            <label>Conferma password</label>
                            <input v-model="bannerSelezionato.CONFERMA_PASSWORD" class="input-sm form-control input-sm" placeholder="Password" type="password">
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Email</label>
                            <input v-model="bannerSelezionato.EMAIL" class="input-sm form-control input-sm" placeholder="Email" type="text">
                        </div>
                        <div class="col-md-6 form-group" v-show="bannerSelezionato.ID_BANNER == 0">
                            <label>Conferma email</label>
                            <input v-model="bannerSelezionato.CONFERMA_EMAIL" class="input-sm form-control input-sm" placeholder="Conferma Email" type="text">
                        </div>
                        <div class="col-md-6 form-group" v-show="bannerSelezionato.ID_BANNER == 0">
                            <label>Nome</label>
                            <input v-model="bannerSelezionato.NOME" class="input-sm form-control input-sm" placeholder="Nome" type="text">
                        </div>
                        <div class="col-md-6 form-group" v-show="bannerSelezionato.ID_BANNER == 0">
                            <label>Cognome</label>
                            <input v-model="bannerSelezionato.COGNOME" class="input-sm form-control input-sm" placeholder="Cognome" type="text">
                        </div>
                    </div>
                       
                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">CHIUDI <i class="far fa-sign-out-alt fa-fw"></i> </button>
					<button type="button" class="btn btn-success float-end btn-sm" v-on:click="salvaBanner()"><i class="far fa-plus-square fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div>
    </div>
        					   

</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
//import Select2 from '@/components/utils/Select2.vue'
import { VueDraggableNext } from 'vue-draggable-next'
//import Upload from '@/components/utils/Upload.vue'
				
export default {
    name:"Banners",	  

    components:{
        Pagine,
        ISwitch,
		//Select2,
        draggable: VueDraggableNext,
        //Upload
    },
    data: function (){
      	return{
            apiStartup:'travel/banners',
	        filters:global.filters,	
			rs : utils.generateRandomString(30),
	        arrayBanners: new Array(),
            arrayCampiPopUpBanners : new Array(),
            bannerSelezionato:{},
            bannerCorrente:{},
            GLOBAL_IMAGES_PATH:'',
	        search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_BANNER:{TYPE:'TEXT',VALUE:''},	
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
	        },

      	}
    },

    methods: {
        getBanners: function () {
            utils.ajax(this.apiStartup,{}, (response) => {
                this.arrayBanners = response.data;
            }); 
        },

        nuovoBanner : function(){ 
			this.bannerSelezionato = {ID_BANNER:0,personaGiuridica:{}};
            this.$refs.schedaBanners.openSchedaBanner(this.bannerSelezionato);
        },

        modificaBanner : function(banner){
            this.bannerSelezionato = utils.clone(banner);
            this.$refs.schedaBanners.openSchedaBanner(banner);
        },

        updateStatoBanner : function(banner){
            setTimeout(() => {
                utils.ajax('core/banners/stato/update',banner, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
						this.filters.closePopUp('#popUpBanner'); 
                    }  
                }); 
            },250);
        },	

        openSchedaBanner: function(banner){
            this.$refs.schedaBanner.openSchedaBanner(banner);
        },
        
      	esportazione : function(formato){
        	utils.report.token({modalita:'esportaBanners',formato:formato});
      	},

		openContenuti : function(banner){
			this.$root.bannerSelezionato = utils.clone(stato);
			global.router.push("bannerSelezionato");
		},	

		ordineChangeHandler : function(event){
			this.arrayBanners = this.filters.draggableChangeHandler(event,this.arrayBanners,this.search,"ID_BANNER");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arrayBanners.length ; i++){
					arr.push({"ID": this.arrayBanners[i].ID_BANNER,"O": this.arrayBanners[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'Stato',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getBanners();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},

        uploadImmagineBanner : function(){
            this.filters.openPopUp("popUpUploadImgBanner"+this.rs);
        },
        successCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgBanner"+this.rs);
            utils.ajax("travel/banners/detail",{ID_BANNER:this.banner.ID_BANNER}, (response) => {
                this.banner = response.data;
            });
        },
        errorCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgBanner"+this.rs);
        }
	         
    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayBanners,this.search);
            return arr;
        },
	},

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.arrayEntiSelect2 = new Array();
        for (var i = 0 ; i < this.$root.arrayEnti.length ; i++){
            this.arrayEntiSelect2.push({id: this.$root.arrayEnti[i].ID_ENTE,text: this.$root.arrayEnti[i].LABEL,})
        }
        this.bannerCorrente = utils.clone(this.$root.Banner);
        utils.ajax('travel/banners',{}, (response) => {
           this.arrayBanners = response.data;
        });
    }

}
</script>