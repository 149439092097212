<template>

    <div class="isprint-grid-container" style="margin-top: 20px;">
        <ul class="list-group">
            <draggable
                :list="arrayItems"
                group="tree"
                @update="onUpdate"
                @start="onDragStart"
                @end="onDragEnd"
            >
                <TreeNode
                v-for="item in arrayItems"
                :key="item[propsMap.id]"
                :item="item"
                :propsMap="propsMap"
                @child-change="onRootChange"
                :array-types="arrayTypes"
                @modifica-item="modificaItem"
                @elimina-item="eliminaItem"
                @aggiungi-item="aggiungiItem"
                />
            </draggable>
        </ul>
    </div>

    
    <div class="modal fade" id="popUpMenuChildren" role="dialog" tabindex="-1">
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
					<h5 class="modal-title">Elementi Menu: {{itemSelezionato.DESCRIZIONE}}</h5>
					<button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
				</div>
				<div class="modal-body">

					<div class="row">
						<div class="col-md-6">
							<label class="text-danger custom-control custom-control-right">Posizione</label>
							<select class="form-select" v-model="itemSelezionato.FK_ID_TYPE" :tabindex="20">
								<option value="0">Seleziona Posizione</option>
								<option v-for="position in this.arrayTypes" v-bind:key="position.ID_TYPE" v-bind:value="position.ID_TYPE" v-show="position.FLG_ATTIVO == 1">&nbsp;&nbsp;&nbsp;{{position.DESCRIZIONE}}</option>
							</select>
						</div>
						<div class="col-md-6">
                            <label class="text-danger custom-control custom-control-right">Padre</label>
							<select class="form-select" v-model="itemSelezionato.ID_MENU_PADRE" :tabindex="20">
								<option value="0">Seleziona Padre</option>
								<option v-for="padre in this.arrayItemsFilter" v-bind:key="padre.ID_MENU" v-bind:value="padre.ID_MENU" v-show="padre.FLG_ATTIVO == 1">&nbsp;&nbsp;&nbsp;{{padre.DESCRIZIONE}}</option>
							</select>                          
                        </div>
					</div>
					
					<hr />

                    <div class="row">
						<div class="col-md-3 mb-2">
							<label class="text-danger custom-control custom-control-right">Titolo</label>
							<select class="form-select" v-model="itemSelezionato.FLG_TIPOLOGIA">
								<option value="1">Voce Menu</option>
								<option value="0">Linea divisoria</option>
							</select> 							
						</div>
						<div class="col-md-9 mb-2"></div>

						<div class="col-md-6 mb-2">
							<label class="text-danger custom-control custom-control-right">Titolo</label>
							<input type="text" v-model="itemSelezionato.DESCRIZIONE" class="form-control" placeholder="Descrizione">
						</div>
						<div class="col-md-6 mb-2">
							<label class="text-danger custom-control custom-control-right">Link Web</label>
							<input type="text" v-model="itemSelezionato.ROUTE" class="form-control" placeholder="Link Web">
						</div>

						<div class="col-md-3 mb-2">
							<label class="text-danger custom-control custom-control-right">Colore</label>
							<input type="text" v-model="itemSelezionato.COLORE" class="form-control" placeholder="Colore">
						</div>
						<div class="col-md-3 mb-2">

						</div>
						<div class="col-md-6 mb-2">
							<label class="custom-control custom-control-right">Icona</label>
							<input type="text" v-model="itemSelezionato.ICONA" class="form-control" placeholder="Icona">
						</div>

						<div class="col-md-3 mb-2">

						</div>
						<div class="col-md-3 mb-2">
							
						</div>
						<div class="col-md-3 mb-2">
							<label class="text-danger custom-control custom-control-right">Ordine</label>
							<input type="number" v-model="itemSelezionato.ORDINE" class="form-control" placeholder="Ordine">
						</div>
						<div class="col-md-3 mb-2">
							<label class="text-danger custom-control custom-control-right">Stato</label>
							<select class="form-select" v-model="itemSelezionato.FLG_ATTIVO" :tabindex="21">
								<option value="1">&nbsp;&nbsp;&nbsp;Attivo</option>
								<option value="0">&nbsp;&nbsp;&nbsp;Disattivo</option>
							</select>
						</div>
					</div>

				</div>
				<div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
					<button class="btn btn-secondary float-end" v-on:click="saveMenu()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>
				</div>
			</div>
		</div>
	</div>

</template>



<script>
import TreeNode from '@/components/TreeNode.vue';
import { VueDraggableNext } from 'vue-draggable-next';
import { utils } from '../assets/js/utils';


export default {
    name: 'Tree',

    emits: ["update-success", "salva-menu"],
    
    components: {
        TreeNode,
        draggable: VueDraggableNext,
    },

    props: {
        arrayItems: {
            type: Array,
            required: true,
        },
        arrayItemsFilter: {
            type: Array,
            required: true,
        },
        arrayTypes:{
            type: Array,
            required: true,
        },
        propsMap: {
            type: Object,
            default: () => ({
                id: 'ID_MENU',
                parentId: 'ID_MENU_PADRE',
                label: 'DESCRIZIONE',
                flg: 'FLG_ATTIVO',
                children: 'children',
            }),
        },
    },

    data() {
        return {
            filters:global.filters,
            draggedItem: null, 
            itemSelezionato : {FLG_TIPOLOGIA : 1},
        };
    },

    methods: {
        onUpdate(evt) {
            console.log('[ROOT] Update event fired:', evt);
            this.arrayItems.forEach((item) => {
                if (!item[this.propsMap.parentId]) {
                item[this.propsMap.parentId] = 0; 
                }
            });
            this.updateItemsOnServer(this.arrayItems);
        },

        onRootChange(evt) {
            console.log('[ROOT] Child change event caught:', evt);
            this.updateItemsOnServer(this.arrayItems);
        },

        onDragStart(evt) {
            console.log('[ROOT] Drag start:', evt);
            this.draggedItem = evt.item; 
        },

        onDragEnd(evt) {
            console.log('[ROOT] Drag end:', evt);
            const draggedItem = this.draggedItem;
            if (draggedItem) {
                draggedItem[this.propsMap.parentId] = 0;
                console.log(`Aggiornato parentId per ${draggedItem[this.propsMap.id]} a 0`);
            }
            this.updateItemsOnServer(this.arrayItems);
        },
    
        updateItemsOnServer(items) {
            console.log('Aggiornamento al server con struttura:', items);
            utils.ajax('core/menu/items/update', { items }, (response) => {
                if (response.esito == 0) {
                    utils.alert.success('Struttura aggiornata con successo!');
                    this.$emit('update-success');
                } else {
                    utils.alert.danger('Errore nell\'aggiornamento della struttura');
                }
            });
        },

        initializeChildren(nodes, childrenKey) {
            nodes.forEach(node => {
                if (!node[childrenKey]) {
                    node[childrenKey] = [];
                }
                if (Array.isArray(node[childrenKey]) && node[childrenKey].length) {
                    this.initializeChildren(node[childrenKey], childrenKey);
                }
            });
        },

        modificaItem : function(item){
            this.itemSelezionato = utils.clone(item);
            this.filters.openPopUp("popUpMenuChildren");
        },

        eliminaItem : function(item){
            utils.alert.confirm("Sicuro di voler eliminare la Posizione e tutti i suoi Menu?",() => {
                utils.ajax('core/basic/delete',{className:"MenuItem","id" : item.ID_MENU}, (response) => {
                    this.$emit('salva-menu');
                });
            },this.$root);
        },

        aggiungiItem : function(item){
            this.itemSelezionato = {ID_MENU:0,FK_ID_TYPE:item.FK_ID_TYPE,ID_MENU_PADRE:item.ID_MENU,FLG_VISIBILE:1,FLG_TIPOLOGIA:1};
            this.filters.openPopUp("popUpMenuChildren");
        },

        saveMenu : function(){
			if(this.itemSelezionato.FK_ID_TYPE > 0){
				if(this.itemSelezionato.ID_MENU > 0){
					utils.ajax('core/menu/children/update',this.itemSelezionato, (response) => {
						if (response.esito == 0){
							this.filters.closePopUp("popUpMenuChildren");
                            utils.alert.success(response.messaggio);
							this.$emit('salva-menu');
						}
					});
				}
				else {
					utils.ajax('core/menu/children/insert',this.itemSelezionato,(response)=>{
						if (response.esito == 0){
							this.filters.closePopUp("popUpMenuChildren");
                            utils.alert.success(response.messaggio);
							this.$emit('salva-menu');
						}
					});
				}
			}
			else {
				utils.alert.danger('Selezionare una tipologia di Menu');
			}	
        },


    },

    created() {
        this.initializeChildren(this.arrayItems, this.propsMap.children);
    },

};
</script>

<style scoped>
.list-group {
  padding-left: 0;
}
</style>
