<template>

    <div class="modal fade" id="popUpSchedaProdotto" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-xl">
            <div class="modal-content" >
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 85% !important;">Scheda: {{prodotto.lang.TITOLO}}</h6>
                    <div class="btn-group float-end" v-show="prodotto.ID_PRODOTTO > 0">
						<a href="#" class="btn btn-sm btn-yellow btn-yellow-100 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Comunicazioni</a>
						<div class="dropdown-menu dropdown-position dropdown-menu-end" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);">
							<a href="#" class="dropdown-item me-1"><i class="fal fa-print me-2"></i> Stampa scheda </a>
							<a href="#" class="dropdown-item me-1"><i class="fal fa-envelope me-2"></i> Invia comunicazione </a>
							<a href="#" class="dropdown-item me-1"><i class="fal fa-paper-plane me-2"></i> Invia sms </a>
						</div>
	               	</div> 	 
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body p-2" style="height: 32rem;"> 

                    <div class="d-lg-flex align-items-lg-start">
                        <div class="sidebar sidebar-component sidebar-expand-lg bg-transparent shadow-none me-lg-3">
                            <div class="sidebar-content">
                                <div class="card sidebar-section-body text-center p-2">
                                    <div class="card-img-actions d-inline-block">
                                        <img v-if="prodotto.ID_PRODOTTO > 0 && prodotto.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('PRODOTTI',prodotto.URL_AVATAR)" class="img-fluid squared-circle rounded-0" alt="" width="215" height="150" />
                                        <span v-if="prodotto.URL_AVATAR == 'placeholder.jpg' || prodotto.ID_PRODOTTO == 0">
                                            <img src="../assets/images/placeholders/placeholder.jpg" class="img-fluid rounded-circle" width="150" height="150" alt="">
                                        </span>
                                        <div class="card-img-actions-overlay card-img rounded-circle">
                                            <a href="#" class="btn btn-outline-white btn-icon rounded-pill" v-on:click="uploadImmagineUtente()">
                                                <i class="ph-pencil"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <ul class="nav nav-sidebar" role="tablist">
									<li class="nav-item" role="presentation">
		                                <a href="#pro-main" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">
		                                    <i class="ph-info me-2"></i>Info Generali
		                                </a>
                                    </li>
                                    <li v-if="modulo != 5 && prodotto.ID_PRODOTTO > 0" class="nav-item" role="presentation">
		                                <a href="#pro-info" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
		                                    <i class="ph-gauge me-2"></i>Info Tecniche
		                                </a> 
                                    </li>
                                    <li v-if="modulo == 4 && prodotto.ID_PRODOTTO > 0" class="nav-item" role="presentation">
		                                <a href="#pro-flight" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
		                                    <i class="ph-faders me-2"></i>Gestione Rotte
		                                </a>
                                    </li>
                                    <li v-if="modulo == 3 && prodotto.ID_PRODOTTO > 0" class="nav-item" role="presentation">
		                                <a href="#pro-package" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
		                                    <i class="ph-bag me-2"></i>Gestione Pacchetto
		                                </a>
                                    </li>
                                    <li v-if="modulo != 5 && prodotto.ID_PRODOTTO > 0" class="nav-item" role="presentation">
		                                <a href="#pro-geo" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
		                                    <i class="ph-map-pin me-2"></i>Geo Localizzazione
		                                </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
		                                <a href="#pro-seo" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
		                                    <i class="ph-cloud me-2"></i>Configurazioni Seo 
		                                </a>
                                    </li>
                                    <li v-show="modulo != 2" class="nav-item" role="presentation">
		                                <a href="#pro-opzpro" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
		                                    <i class="ph-funnel me-2"></i>Configurazioni Prodotto 
		                                </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
		                                <a href="#pro-logs" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
		                                    <i class="ph-code me-2"></i>Log Accessi
		                                </a>
                                    </li>
                                </ul>
                            </div>
                    	</div> 	 

                        <div class="tab-content flex-fill">

                            <div class="tab-pane fade active show" id="pro-main" role="tabpanel">
                                <h5 class="label-bar"><i class="fad fa-info-circle fa-fw me-1"></i> Info Generali</h5>
                                <div class="box-utenti">  

                                    <div class="row">
                                        <div class="col-6 col-md-3 mb-2">
                                            <label class="form-label text-danger mb-0">Codice {{descrizioneTipoProdotto}} </label>
                                            <span class="float-end" data-bs-popup="popover" data-bs-trigger="hover" data-bs-placement="left"  data-bs-original-title="Codice  descrizioneTipoProdotto" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                            <div class="input-group input-group-sm" tabindex="1">
                                                <span class="input-group-text"><i class="far fa-code fa-fw"></i></span>
                                                <input autocomplete="off" type="text" class="form-control form-control-sm valid" v-model="prodotto.CODICE_PRODOTTO">
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-3 mb-2">
                                            <label class="form-label text-danger mb-0">Tipologia {{descrizioneTipoProdotto}} </label>
                                            <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Tipologia descrizioneTipoProdotto" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                            <div class="input-group input-group-sm" :tabindex="2">
                                                <span class="input-group-text"><i class="far fa-tags fa-fw"></i></span>
                                                <select class="form-select form-select-sm" v-model="prodotto.FK_ID_TIPO">
                                                    <option value="0" selected="selected">Seleziona</option>
                                                    <option v-for="tipoStruttura in arrayTipoStruttura" v-bind:value="tipoStruttura.ID_OPZIONE_TIPOLOGICA" v-bind:key="tipoStruttura.ID_OPZIONE_TIPOLOGICA">{{ tipoStruttura.DESCRIZIONE }}</option>                                                   
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 mb-2">
                                            <label class="form-label text-danger mb-0">Nome {{descrizioneTipoProdotto}} </label>
                                            <div class="input-group input-group-sm" :tabindex="3">
                                                <span class="input-group-text"><i class="far fa-pennant fa-fw"></i></span>
                                                <input autocomplete="off" type="text" class="form-control form-control-sm valid" placeholder="Descrizione" v-model="prodotto.DESCRIZIONE">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 col-md-6">

                                            <div class="row">
                                                <div class="col-6 col-md-4 mb-2">
                                                    <label class="form-label text-danger mb-0">Prenotabile </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Prenotabile" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <div class="input-group input-group-sm" :tabindex="4">
                                                        <span class="input-group-text"><i class="far fa-suitcase fa-fw"></i></span>
                                                        <select class="form-select form-select-sm" v-model="prodotto.FLG_PRENOTABILE">
                                                            <option value="1" selected="selected">Si</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-md-4 mb-2">
                                                    <label class="form-label text-danger mb-0">In giornata</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="In giornata" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <div class="input-group input-group-sm" :tabindex="5">
                                                        <select class="form-select form-select-sm" v-model="prodotto.FLG_INGIORNATA">
                                                            <option selected="selected">Seleziona</option>
                                                            <option value="1">Si</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div class="col-6 col-md-4 mb-2">
                                                    <label class="form-label text-danger mb-0">Prezzo base </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Prezzo base " data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <div class="input-group input-group-sm" :tabindex="6">
                                                        <span class="input-group-text"><i class="far fa-euro-sign fa-fw"></i></span>
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="prodotto.PREZZO_BASE" placeholder="Es: 0.00">
                                                    </div>
                                                </div>

                                                <div class="col-6 col-md-4 mb-2" v-show="modulo != 4">
                                                    <label class="form-label text-danger mb-0">Stelle </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Stelle" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <div class="input-group input-group-sm" :tabindex="7">
                                                        <span class="input-group-text"><i class="far fa-star fa-fw"></i></span>
                                                        <select class="form-select form-select-sm select-icons" v-model="prodotto.FK_ID_STELLE">
                                                            <option selected="selected">Seleziona</option>
                                                            <option v-for="stella in arrayStelle" v-bind:value="stella.ID_OPZIONE_TIPOLOGICA" v-bind:key="stella.ID_OPZIONE_TIPOLOGICA">{{ stella.DESCRIZIONE }}</option>                                                   
                                                        </select>
                                                    </div>    
                                                </div>                                               

                                                <div class="col-md-8 mb-2" v-if="modulo == 1 || modulo == 3">
                                                    <label class="form-label text-danger mb-0">Durata </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Stato" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <div class="input-group input-group-sm" :tabindex="8">
                                                        <span class="input-group-text"><i class="far fa-clock fa-fw"></i></span>
                                                        <select class="form-select form-select-sm" v-model="prodotto.dettagli.ATTIVITA_DURATA" v-if="modulo == 1">
                                                            <option v-for="durata in arrayDurata" v-bind:value="durata.ID_OPZIONE_TIPOLOGICA" v-bind:key="durata.ID_OPZIONE_TIPOLOGICA">{{ durata.DESCRIZIONE }}</option>                                                   
                                                        </select>
                                                        <select class="form-select form-select-sm" v-model="prodotto.dettagli.PACCHETTO_DURATA" v-if="modulo == 3">
                                                            <option v-for="i in 28" v-bind:value="i" v-bind:key="i">{{ i }}</option>                                                   
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-md-4 mb-2" v-if="modulo == 2">
                                                    <label class="form-label text-danger mb-0">Check-in </label>
                                                    <div class="input-group input-group-sm" :tabindex="8">
                                                        <span class="input-group-text"><i class="far fa-clock fa-fw"></i></span>
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="prodotto.dettagli.HOTEL_CHECKIN" placeholder="00:00 PM">
                                                    </div>
                                                </div>
                                                <div class="col-md-4 mb-2" v-if="modulo == 2">
                                                    <label class="form-label text-danger mb-0">Check-out </label>
                                                    <div class="input-group input-group-sm" :tabindex="9">
                                                        <span class="input-group-text"><i class="far fa-clock fa-fw"></i></span>
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="prodotto.dettagli.HOTEL_CHECKOUT" placeholder="00:00 AM">
                                                    </div>
                                                </div> 

                                                <div class="col-12 col-md-6 mb-2" v-show="modulo == 4">
                                                    <label class="form-label text-danger mb-0">Hub di partenza</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-original-title="Tipologia Fiscale" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <Select2 v-model="prodotto.dettagli.VOLO_FK_ID_AEROPORTO_FROM" :w="250" :api="'travel/s2/aeroporti'" :apiselected="'travel/s2/aeroporto?a=0'" :tabindex="10"></Select2>
                                                </div> 
                                                <div class="col-12 col-md-6 mb-2" v-show="modulo == 4">
                                                    <label class="form-label text-danger mb-0">Hub di arrivo</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Tipologia Fiscale" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <Select2 v-model="prodotto.dettagli.VOLO_FK_ID_AEROPORTO_TO" :w="250" :api="'travel/s2/aeroporti'" :apiselected="'travel/s2/aeroporto?a=0'" :tabindex="11"></Select2>
                                                </div>

                                                <div class="col-6 col-md-6 mb-2" v-if="modulo == 4">
                                                    <label class="form-label text-danger mb-0">Vettore </label>
                                                    <select class="form-select form-select-sm" name="hotel_active" v-model="prodotto.lang.FLG_ATTIVO" :tabindex="12">
                                                        <option value="1">Attivo</option>
                                                        <option value="0">Disattivo</option>
                                                    </select>
                                                </div>
                                                <div class="col-6 col-md-6 mb-2" v-if="modulo == 4">
                                                    <label class="form-label text-danger mb-0">Combinazione </label>
                                                    <select class="form-select form-select-sm" name="hotel_active" v-model="prodotto.lang.FLG_ATTIVO" :tabindex="13">
                                                        <option value="1">Attivo</option>
                                                        <option value="0">Disattivo</option>
                                                    </select>
                                                </div>

                                                <div class="col-md-12 mb-2">
                                                    <label class="form-label text-danger mb-0">Tematiche associate  </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Tematiche associate" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <Multiple  v-bind:options="arrayCategorie" v-model="prodotto.categorie"></Multiple>
                                                </div> 

                                                <div class="col-md-12 mb-2">
                                                    <label class="form-label text-danger mb-0">Pubblica sul Sito</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Tematiche associate" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <div class="input-group input-group-sm" :tabindex="15">
                                                        <span class="btn btn-light btn-sm" v-on:click="prodotto.enti = filters.select2AddAll(prodotto.enti,filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL'))">Tutti</span>
                                                        <Multiple v-bind:options="filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL')" v-model="prodotto.enti"></Multiple>
                                                    </div>
                                                </div>  
                                            </div>
                                            
                                        </div>
                                        <div class="col-12 col-md-6" style="border-left: 1px solid #ddd;">

                                            <div class="row">
                                                <div class="col-md-12 mb-2">
                                                    <label class="form-label text-danger mb-0">Fornitore </label>
                                                    <span v-on:click="nuovoFornitore()"  class="badge bg-yellow badge-xs text-dark cursor-pointer float-end">nuovo</span>
                                                    <Select2 :w="250" :api="'travel/s2/fornitori'" :apiselected="'travel/s2/fornitore?a=0'" v-model="prodotto.FK_ID_AZIENDA_FORNITORE" :tabindex="16"></Select2>
                                                </div>

                                                <div class="col-6 col-md-5 mb-2">
                                                    <label class="form-label text-danger mb-0">Tipologia Fiscale </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Tipologia Fiscale" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <select class="form-select form-select-sm" v-model="prodotto.FK_ID_TIPO_FISCALE" :tabindex="17" >
                                                        <option selected="selected">Seleziona</option>
                                                        <option v-for="fiscale in arrayFiscale" v-bind:value="fiscale.ID_OPZIONE_TIPOLOGICA" v-bind:key="fiscale.ID_OPZIONE_TIPOLOGICA">{{ fiscale.DESCRIZIONE }}</option>                                                   
                                                    </select> 
                                                </div>
                                                <div class="col-6 col-md-4 mb-2">
                                                    <label class="form-label text-danger mb-0">Netto IVA al </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Netto IVA al" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <select class="form-select form-select-sm" v-model="prodotto.FK_ID_IVA" :tabindex="18" >
                                                        <option selected="selected">Seleziona</option>
                                                        <option v-for="iva in arrayIva" v-bind:value="iva.ID_OPZIONE_TIPOLOGICA" v-bind:key="iva.ID_OPZIONE_TIPOLOGICA">{{ iva.DESCRIZIONE }}</option>                                                   
                                                    </select> 
                                                </div>
                                                <div class="col-6 col-md-3 mb-2">
                                                    <label class="form-label text-danger mb-0">Comm.</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Commissione" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <select class="form-select form-select-sm" v-model="prodotto.COMMISSIONE" :tabindex="19">
                                                        <option value="0">0</option>
                                                    </select>
                                                </div> 
                                                
                                                <div class="col-6 col-md-4 mb-2">
                                                    <label class="form-label text-danger mb-0">Inf. occupa?</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Inf. occupa posto?" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <div class="input-group input-group-sm" :tabindex="20">
                                                        <span class="input-group-text"><i class="far fa-baby fa-fw"></i></span>
                                                        <select class="form-select form-select-sm" v-model="prodotto.FLG_INF_NOCALCOLO">
                                                            <option selected="selected">Seleziona</option>
                                                            <option value="1">Si</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </div>
                                                </div>                                            
                                                <div class="col-6 col-md-4 mb-2">
                                                    <label class="form-label text-danger mb-0">Chd <small class="text-muted">- Età Min.</small> </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Chd Età Min." data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <div class="input-group input-group-sm" :tabindex="21">
                                                        <span class="input-group-text"><i class="far fa-child fa-fw"></i></span>
                                                        <input autocomplete="off" type="number" class="form-control form-control-sm valid" v-model="prodotto.ETA_MIN_CHD" placeholder="Es: 2" min="0" maxlength="2">
                                                    </div>
                                                </div>
                                                <div class="col-6 col-md-4 mb-2">
                                                    <label class="form-label text-danger mb-0">Adu <small class="text-muted">- Età Min.</small> </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Inf. Età Min." data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <div class="input-group input-group-sm" :tabindex="22">
                                                        <span class="input-group-text"><i class="far fa-male fa-fw"></i></span>
                                                        <input autocomplete="off" type="number" class="form-control form-control-sm valid" v-model="prodotto.ETA_MIN_ADU" placeholder="Es: 12" min="0" maxlength="2">
                                                    </div>
                                                </div>
                                                
                                                <div class="col-6 col-md-4 mb-2">
                                                    <label class="form-label text-danger mb-0">Qta. Iscr. CHD </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Quota Iscrizione CHD" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <div class="input-group input-group-sm" :tabindex="23">
                                                        <span class="input-group-text"><i class="far fa-euro-sign fa-fw"></i></span>
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="prodotto.ISCRIZIONE_CHD" placeholder="ES: 15 &euro;">
                                                    </div>
                                                </div>
                                                <div class="col-6 col-md-4 mb-2">
                                                    <label class="form-label text-danger mb-0">Qta. Iscr. ADU </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Quota Iscrizione ADU" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <div class="input-group input-group-sm" :tabindex="24">
                                                        <span class="input-group-text"><i class="far fa-euro-sign fa-fw"></i></span>
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="prodotto.ISCRIZIONE_ADU" placeholder="ES: 35 &euro;">
                                                    </div>
                                                </div>
                                                <div class="col-6 col-md-4 mb-2">
                                                    <label class="form-label text-danger mb-0">Modello vendita </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Modello vendita" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <select class="form-select form-select-sm" v-model="prodotto.FK_ID_MODELLO_VENDITA" :tabindex="25">
                                                        <option value="1" selected="selected">Per tutti</option>
                                                        <option value="2" selected="selected">Solo adulti</option>
                                                        <option value="3" selected="selected">Solo bambini</option>                                                
                                                    </select>
                                                </div>
  
                                                <div class="col-6 col-md-4 mb-2">
                                                    <label class="form-label text-danger mb-0">Tag speciale </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Tag speciale" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <select class="form-select form-select-sm" v-model="prodotto.FK_ID_TAG_SPECIALE" :tabindex="26">
                                                        <option value="0" selected="selected">Seleziona</option>
                                                        <option v-for="tag in arrayTagSpeciali" v-bind:value="tag.ID_OPZIONE_TIPOLOGICA" v-bind:key="tag.ID_OPZIONE_TIPOLOGICA">{{ tag.DESCRIZIONE }}</option>                                                   
                                                    </select>
                                                </div>
                                                <div class="col-6 col-md-4 mb-2">
                                                    <label class="form-label text-danger mb-0">Visibile </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left"  data-bs-original-title="Visibile" data-bs-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    <select class="form-select form-select-sm" v-model="prodotto.FLG_VISIBILE" :tabindex="27">
                                                        <option selected="selected">Seleziona</option>
                                                        <option v-bind:value="1">Pubblico</option>        
                                                        <option v-bind:value="0">Admin</option>        
                                                    </select>
                                                </div>
                                                <div class="col-6 col-md-4 mb-2">
                                                    <label class="form-label text-danger mb-0">Stato </label>
                                                    <select class="form-select form-select-sm" name="hotel_active" v-model="prodotto.lang.FLG_ATTIVO" :tabindex="28">
                                                        <option v-bind:value="1">Attivo</option>
                                                        <option v-bind:value="0">Disattivo</option>
                                                    </select>
                                                </div>

                                            </div>
                                            
                                        </div>
                                    </div> 
                                </div>

                            </div>

                            <div class="tab-pane fade" id="pro-info" role="tabpanel">
                                <h5 class="label-bar"><i class="fad fa-tachometer-fast fa-fw me-1"></i> Info Tecniche</h5>
                                <div class="box-utenti"> 
                                        
                                    <div class="row mt-1">
                                        <div class="col-md-6 mb-2">
                                            <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Slogan </label>
                                            <input autocomplete="off" type="text" class="form-control form-control-sm" id="hotel_subtitle" v-model="prodotto.lang.SOTTOTITOLO" tabindex="5">
                                        </div>
                                        <div class="col-6 col-md-2 mb-2">
                                            <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Pagina Catalogo </label>
                                            <select class="form-select form-select-sm" :tabindex="26">
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>
                                    </div>

                                    <hr class="hr-1" />

                                    <div class="row mt-2">
                                        <div class="col-12 col-md-6">

                                            <div class="col-6 col-md-2 mb-2" v-if="modulo == 2">
                                                <label class="text-muted custom-control custom-control-right">Channel 
                                                    <a class="float-end" href="#" data-bs-popup="popover" title="" data-html="true" data-bs-content="Add <u>some</u> <i>HTML</i> <strong>stuff</strong>." data-bs-original-title="Popover <span class='text-danger'>title</span>" aria-describedby="popover-custom877761"><i class="far fa-info-circle text-primary"></i></a>
                                                </label>
                                                <select class="form-select form-select-sm" name="hotel_type" data-placeholder="Scegli" :tabindex="30">
                                                    <option value="">Scegli</option>
                                                </select>
                                            </div>
                                            <div class="col-6 col-md-2 mb-2" v-if="modulo == 2">
                                                <label class="text-muted custom-control custom-control-right">Tipo Channel
                                                    <a class="float-end" href="#" data-bs-popup="popover" title="" data-html="true" data-bs-content="Add <u>some</u> <i>HTML</i> <strong>stuff</strong>." data-bs-original-title="Popover <span class='text-danger'>title</span>" aria-describedby="popover-custom877761"><i class="far fa-info-circle text-primary"></i></a>
                                                </label>
                                                <select class="form-select form-select-sm" name="hotel_type" data-placeholder="Scegli" :tabindex="30">
                                                    <option value="">Scegli</option>
                                                </select>
                                            </div>

                                            <div class="col-md-6 mb-2" v-if="modulo == 2">
                                                <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Email Struttura </label>
                                                <div class="input-group input-group-sm">
                                                    <span class="input-group-text"><i class="far fa-at"></i></span>
                                                    <input type="text" class="form-control form-control-sm" name="hotel_email" value="">
                                                </div>
                                            </div>
                                            <div class="col-md-3 mb-2" v-if="modulo == 2">
                                                <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Telefono Hotel </label>
                                                <div class="input-group input-group-sm">
                                                    <span class="input-group-text"><i class="far fa-phone"></i></span>
                                                    <input type="text" class="form-control form-control-sm" name="hotel_phone" value="">
                                                </div>
                                            </div>
                                            <div class="col-md-3 mb-2" v-if="modulo == 2">
                                                <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Fax Hotel </label>
                                                <div class="input-group input-group-sm">
                                                    <span class="input-group-text"><i class="far fa-fax"></i></span>
                                                    <input type="text" class="form-control form-control-sm" name="hotel_fax" value="">
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-12 col-md-6" style="border-left: 1px solid #ddd;">

                                            <div class="col-12 mb-2">
                                                <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Servizi </label>
                                                <Select2 :multiple="true" v-bind:options="arrayTest" v-model="prodotto.servizi"></Select2>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Interessi/attività </label>
                                                <Select2 :multiple="true" v-bind:options="arrayTest" v-model="prodotto.interessi"></Select2>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Spiagge vicine </label>
                                                <Select2 :multiple="true" v-bind:options="arrayTest" v-model="prodotto.spiagge"></Select2>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Carte di Credito Accettate </label>
                                                <Select2 :multiple="true" v-bind:options="arrayTest" v-model="prodotto.cc"></Select2>
                                            </div> 
                                            
                                            <div class="col-12 mb-2">
                                                <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Lingue Guida </label>
                                                <Select2 :multiple="true" v-bind:options="arrayTest" v-model="prodotto.cc"></Select2>
                                            </div> 

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="tab-pane fade" id="pro-flight" role="tabpanel">
                                <h5 class="label-bar"><i class="fad fa-sliders-v fa-fw me-1"></i> Gestione Rotte</h5>
                                <div class="box-utenti"> 

                                    <div class="col-md-2 mb-2">
                                        <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Volo diretto </label>
                                        <select class="form-select form-select-sm" name="hotel_active" tabindex="17" v-model="prodotto.dettagli.VOLO_FLG_DIRETTO">
                                            <option value="1">SI</option>
                                            <option value="0">NO</option>
                                        </select>
                                    </div>
                                    <button class="btn btn-success" v-show="prodotto.dettagli.VOLO_FLG_DIRETTO == 0" v-on:click="addScalo()"><i class="fal fa-plus"></i>Aggiungi scalo</button>

                                    <div id="accordion_servizi">
                                        <div class="card" v-for="servizio in prodotto.servizi" v-bind:key="servizio.ID_SERVIZIO">
                                            <div class="card-header" :id="'heading_serv_'+servizio.ID_SERVIZIO" >
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link" data-bs-toggle="collapse" :data-target="'#collapse_serv_'+servizio.ID_SERVIZIO" aria-expanded="true" :aria-controls="'collapse_serv_'+servizio.ID_SERVIZIO">
                                                        {{servizio.DESCRIZIONE}}
                                                    </button>
                                                </h5>
                                            </div>

                                            <div :id="'collapse_serv_'+servizio.ID_SERVIZIO" class="collapse" :aria-labelledby="'heading_serv_'+servizio.ID_SERVIZIO" data-parent="#accordion_servizi">
                                                <div class="card-body">
                                                    <button class="btn btn-danger float-end" v-on:click="deleteServizio(servizio)">Elimina</button>
                                                    <ul class="list-group">
                                                        <li class="list-group-item" v-for="element in servizio.elementi" v-bind:key="element.ID_ELEMENTO">
                                                            {{element.prodotto.CODICE_PRODOTTO}} - {{element.prodotto.lang.TITOLO}}
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                
                                </div>
                            </div>

                            <div class="tab-pane fade" id="pro-package" role="tabpanel">
                                <h5 class="label-bar"><i class="fas fa-shopping-bag fa-fw me-1"></i> Gestione Pacchetto
                                    <span class="badge badge-flat border-success text-success cursor-pointer float-end mt-1 me-2" v-on:click="addServizio({ID_PRODOTTO:0})"><i class="fal fa-plus fa-fw"></i> AGGIUNGI SERVIZIO</span>
                                </h5>
                                <div class="box-utenti"> 
								
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="table-head-sm-2" style="width: 10%;">Tipo</th> 
                                                <th class="table-head-sm-2">Elementi</th> 
                                                <th class="table-head-sm-2" style="width: 17%;">Azioni</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="servizio in prodotto.servizi" v-bind:key="servizio.ID_SERVIZIO">
                                                <td class="text-center align-top"><i class="fa-fw fa-2x fal fa-plane-departure"></i></td> 
                                                <td class="align-top" id="accordion_servizi_pacchetto">
                                                    <span class="badge badge-flat d-block border-success text-success cursor-pointer" style="line-height: 1.5rem;" data-bs-toggle="collapse" :data-target="'#collapse_serv_'+servizio.ID_SERVIZIO" aria-expanded="true" :aria-controls="'collapse_serv_'+servizio.ID_SERVIZIO">
                                                        <strong>{{servizio.DESCRIZIONE}}</strong>
                                                    </span>
                                                    <div :id="'collapse_serv_'+servizio.ID_SERVIZIO" class="collapse show" :aria-labelledby="'heading_serv_'+servizio.ID_SERVIZIO" data-parent="#accordion_servizi_pacchetto">
                                                        <ul class="list-group list-group-2 rounded-0">
                                                            <li class="list-group-item list-group-item-2" style="border-bottom: 1px dashed #dedede;" v-for="element in servizio.elementi" v-bind:key="element.ID_ELEMENTO">
                                                                <small class="text-primary fs-12">{{element.prodotto.ID_PRODOTTO}} - {{element.prodotto.lang.TITOLO}}</small>
                                                                <span class="float-end cursor-pointer" v-on:click="deleteElemento(servizio)"><i class="far fa-trash text-danger fa-fw"></i></span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                                <td class="text-center align-top">
                                                    <button class="btn btn-warning btn-xs me-2" v-on:click="modificaServizio(servizio)"><i class="far fa-pencil fa-fw"></i></button>
                                                    <button class="btn btn-danger btn-xs" v-on:click="deleteServizio(servizio)"><i class="far fa-trash fa-fw"></i></button><br />
                                                    <span class="badge badge-flat border-primary text-primary d-block cursor-pointer mt-2" v-on:click="addElemento(servizio)"><i class="far fa-arrow-to-left fa-fw"></i> Aggiungi Elemento</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                    <!--
                                    <div id="accordion_servizi_pacchetto">
                                        <div class="card" v-for="servizio in prodotto.servizi" v-bind:key="servizio.ID_SERVIZIO">
                                            <div class="card-header" :id="'heading_serv_'+servizio.ID_SERVIZIO" >
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link" data-bs-toggle="collapse" :data-target="'#collapse_serv_'+servizio.ID_SERVIZIO" aria-expanded="true" :aria-controls="'collapse_serv_'+servizio.ID_SERVIZIO">
                                                        {{servizio.DESCRIZIONE}}
                                                    </button>
                                                    <button class="btn btn-success float-end ms-4" v-on:click="addElemento(servizio)">Aggiungi Elemento</button>
                                                    <button class="btn btn-warning float-end ms-2" v-on:click="modificaServizio(servizio)">Modifica Servizio</button>
                                                    <button class="btn btn-danger float-end ms-2" v-on:click="deleteServizio(servizio)">Elimina Servizio</button>
                                                </h5>
                                            </div>

                                            <div :id="'collapse_serv_'+servizio.ID_SERVIZIO" class="collapse" :aria-labelledby="'heading_serv_'+servizio.ID_SERVIZIO" data-parent="#accordion_servizi_pacchetto">
                                                <div class="card-body">
                                                    
                                                    <div class="w-100 mt-2">
                                                        <ul class="list-group">
                                                            <li class="list-group-item" v-for="element in servizio.elementi" v-bind:key="element.ID_ELEMENTO">
                                                                {{element.prodotto.CODICE_PRODOTTO}} - {{element.prodotto.lang.TITOLO}}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                   
                                    -->
                                </div>
                            </div>

                            <div class="tab-pane fade" id="pro-geo" role="tabpanel">
                                <h5 class="label-bar"><i class="fad fa-map-marker-alt fa-fw me-1"></i> Geo Localizzazione</h5>
                                <div class="box-utenti"> 

                                    <div class="card card-nobottom">
                                        <div class="card-body card-body-2 bg-light">
                                            <h5 class="card-title">Geolocalizzazione Automatica tramite Google Maps
                                                <code class="d-block font-size-base">(permette di individuare la struttura tramite Google)</code>
                                            </h5>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-md-12 mb-2" style="position: relative; bottom: -15px;">
                                                            <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Inserire indirizzo completo e selezionarlo dal risultato. 
                                                                <a class="float-end" title="" href="#"><i class="far fa-info-circle float-end"></i></a>
                                                            </label>
                                                            <AddressField @selezionato="indirizzoSelezionato($event)"></AddressField>
                                                            <!-- <input autocomplete="off" type="text" class="form-control form-control-sm pac-target-input" id="autocomplete" placeholder="(Es. Via Reggio Calabria, 101, Messina.)" tabindex="10">-->
                                                            <input type="hidden" v-model="prodotto.dettagli.LATITUDINE" >
                                                            <input type="hidden" v-model="prodotto.dettagli.LONGITUDINE" >
                                                        </div>
                                                    
                                                        <div class="col-md-12 mb-2">
                                                            <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Indirizzo Hotel 
                                                                <small class="text-body">(può essere personalizzato, campi facoltativi)</small>
                                                            </label>
                                                            <input type="text" class="form-control form-control-sm" v-model="prodotto.dettagli.INDIRIZZO"  tabindex="11">
                                                        </div>
                                                    
                                                        <div class="col-md-4 mb-2">
                                                            <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Civico </label>
                                                            <input type="text" class="form-control form-control-sm" v-model="prodotto.dettagli.CIVICO" >
                                                        </div>
                                                        <div class="col-md-4 mb-2">
                                                            <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Cap </label>
                                                            <input type="text" class="form-control form-control-sm" v-model="prodotto.dettagli.CAP" >
                                                        </div>
                                                        <div class="col-md-4 mb-2">
                                                            <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Prov. </label>
                                                            <input type="text" class="form-control form-control-sm" v-model="prodotto.dettagli.PROV" >
                                                        </div>
                                                    
                                                        <div class="col-md-3 mb-2 mt-3">
                                                            <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Città {{ prodotto.FK_ID_COMUNE }}</label>
                                                        </div>
                                                        <div class="col-md-9 mb-2 mt-2">
                                                            <input type="text" class="form-control form-control-sm" v-model="prodotto.dettagli.CITTA">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mb-2">
                                                    <label class="text-body custom-control custom-control-right"> &nbsp; </label>
                                                    <GoogleMaps :ref="'mappa'" style="border: 3px solid #fff;"></GoogleMaps>
                                                </div>
                                            </div>
                                              
                                        </div>
                                    </div>                                    
                                
                                </div>
                            </div>

                            <div class="tab-pane fade" id="pro-seo" role="tabpanel">
                                <h5 class="label-bar"><i class="fad fa-cloud fa-fw me-1"></i> Configurazioni Seo</h5>
                                <div class="box-utenti"> 
                                    
                                    <Seo v-model="prodotto.lang"></Seo>
                                            
                                </div>
                            </div>

                            <div class="tab-pane fade" id="pro-opzpro" role="tabpanel">
                                <h5 class="label-bar"><i class="fad fa-filter fa-fw me-1"></i> Opzioni Prodotto</h5>
                                <div class="box-utenti"> 

                                    <div class="kt-portlet__head" >
                                        <form class="isprint-filter" autocomplete="off"> 
                                            <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Filtra per opzioni prodotto" type="text" v-model="searchOpzioni.DESCRIZIONE.VALUE">
                                        </form>
                                    </div>
                                    <hr class="hr-2"/>
                                    <div class="row">
                                        <div class="col-md-4" v-show="opzione.FLG_ATTIVO == 1" v-on:click="setOpzione(opzione)" v-for="opzione in paginatedListOpzioni.arr" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA">
                                            <div class="card mb-1 lift-panel shadow " v-bind:style="getStyleOpzione(opzione)" style="height:35px;">
                                                <div class="card-body d-flex h-100" style="padding:.25rem;padding-left:.5rem;padding-right:.5rem;font-weight:bold">
                                                    <div class="justify-content-center align-self-center" style="font-size:12px">
                                                        <i class="fal fa-tag fa-fw"></i> {{opzione.DESCRIZIONE}} 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                
                                    </div>                                     

                                </div>


                                <!-- 
                                <div class="box_26">

                                    <ul class="list-group">
                                        <li class="list-group-item" v-for="opzione in arrayOpzioniModulo" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA">
                                            {{opzione.DESCRIZIONE}} ({{opzione.CODICE}})    -
                                            <ISwitch @toggle="updateStatoOpzione(opzione)" :size="'sm'" v-model="opzione.SELEZIONATO" class="float-end" style="margin-top:6px"></ISwitch>
                                        </li>    
                                    </ul>                                     
                                
                                </div>-->
                            </div>

                            <div class="tab-pane fade" id="pro-logs" role="tabpanel">
                                <h5 class="label-bar mb-1"><i class="fad fa-code fa-fw me-1"></i> Log Accessi</h5>
                                <div class="row mb-1">
                                    <div class="col-6 col-md-4">
                                        <select class="form-select form-select-sm">
                                            <option value="opt1">Seleziona Data</option>
                                            <option value="opt2">Option 2</option>
                                            <option value="opt3">Option 3</option>
                                            <option value="opt4">Option 4</option>
                                            <option value="opt5">Option 5</option>
                                            <option value="opt6">Option 6</option>
                                            <option value="opt7">Option 7</option>
                                            <option value="opt8">Option 8</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-md-4">
                                        <select class="form-select form-select-sm">
                                            <option value="opt1">Seleziona Modulo</option>
                                            <option value="opt2">Option 2</option>
                                            <option value="opt3">Option 3</option>
                                            <option value="opt4">Option 4</option>
                                            <option value="opt5">Option 5</option>
                                            <option value="opt6">Option 6</option>
                                            <option value="opt7">Option 7</option>
                                            <option value="opt8">Option 8</option>
                                        </select>
                                    </div>
                                    <div class="d-block d-xl-none col-md-4">
                                    </div>
                                </div>
                                <div class="tableFixHead tableFixHeadRoles">
                                    <table class="table table-bordered table-hover table-striped table-togglable">
                                        <thead class="sticky-top">
                                            <tr class="bg-secondary text-white">
                                                <th class="p-1 fs-12" style="width:20%;"> Data </th>
                                                <th class="p-1 fs-12" style="width:20%;">Modulo</th>
                                                <th class="p-1 fs-12">Pagina</th>
                                                <th class="p-1 fs-12 text-center" style="width:5%;">Azioni</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="p-1 font-size-xs">30/12/2021 12:58:01</td>
                                                <td class="p-1 font-size-xs"><span class="text-primary fw-bold">Home</span></td>
                                                <td class="p-1 font-size-xs">op=users_list</td>
                                                <td class="p-1 text-center">
                                                    <a href="#" class="badge badge-flat border-secondary text-secondary"><i class="fal fa-eye"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> 
                            </div>

                        </div>
                    </div>
                   
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaProdotto()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>                
            </div>
        </div>
    </div>
				   

    <!-- Modal -->
    <div class="modal fade" id="popUpUploadImgProdotto" role="dialog"  tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-light text-dark">
                    <h6 class="modal-title">Upload immagine Profilo</h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">    
                    <Upload :args="argsProdotto" @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div>
    </div>	

    <div class="modal fade" id="popUpServizio" role="dialog"  tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content" >
                <div class="modal-header modal-header-2 bg-light text-dark">
                    <h6 class="modal-title">Gestione servizio</h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">
                    <div class="col-md-12 mb-2">
                        <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Modulo </label>
                        <select class="form-control" v-model="servizioSelezionato.FK_ID_MODULO">
                            <option value="1">Attività</option>
                            <option value="2">Hotel</option>
                            <option value="3">Pacchetti</option>
                            <option value="4">Voli</option>
                        </select>
                    </div>
                    <div class="col-md-12 mb-2">
                        <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Descrizione </label>
                        <input type="text" class="form-control" v-model="servizioSelezionato.DESCRIZIONE">
                    </div>
                    <div class="col-md-12 mb-2">
                        <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Giorno iniziale </label>
                        <select class="form-control" v-model="servizioSelezionato.GIORNO_START">
                            <option v-for="i in 30" v-bind:key="i">{{i}}</option>                        
                        </select>
                    </div>                    
                    <div class="col-md-12 mb-2">
                        <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Durata </label>
                        <select class="form-control" v-model="servizioSelezionato.DURATA">
                            <option v-for="i in 30" v-bind:key="i">{{i}}</option>                        
                        </select>
                    </div>
                </div>
                <div class="modal-footer mt-2 pt-2" style="border: 1px solid #eee;">
                    <button class="btn btn-success float-end" v-on:click="salvaService()">Salva</button>
                </div>
            </div>
        </div>
    </div>

    <SchedaProdottoLang :ref="'lang'"></SchedaProdottoLang>
    <RicercaProdotto :ref="'ricerca'" :ts="ts" @prodottoSelezionato="elementSelezionato($event)"></RicercaProdotto>
    <SchedaAzienda @aziendaSalvata="setFornitore($event)" :ref="'schedaAzienda'" :tipo="2" ></SchedaAzienda>


</template>



<script>

//import ISwitch from '@/components/utils/ISwitch.vue'
import Select2 from '@/components/utils/Select2.vue'
import Multiple from '@/components/utils/Multiple.vue'

import Upload from '@/components/utils/Upload.vue'
import SchedaProdottoLang from '@/components/SchedaProdottoLang.vue';
import RicercaProdotto from '@/components/RicercaProdotto.vue';
import SchedaAzienda from '@/components/SchedaAzienda.vue'	
import AddressField from '@/components/utils/AddressField.vue'	
import GoogleMaps from '@/components/utils/GoogleMaps.vue'	
import Seo from '@/components/utils/Seo.vue'	

export default {
    name:"SchedaProdotto",
    emits:["updateProdotto"],
    props:["modulo","arrayCategorie"],	

    components:{
       // ISwitch,
        Select2,
        Upload,
        SchedaProdottoLang,
        RicercaProdotto,
        SchedaAzienda,
        AddressField,
        GoogleMaps,
        Seo,
        Multiple
    },

    data:function(){
        return{
			filters:global.filters,
            prodotto : {lang:{},dettagli:{},opzioni:new Array()},
            arrayOpzioniModulo : new Array(),
            ts:new Date().getTime(),
            servizioSelezionato:{},
            arrayOpzioniAll : new Array(),
            arrayTipoStruttura : new Array(),
            arrayFiscale : new Array(),
            arrayIva : new Array(),
            arrayStelle : new Array(),
            arrayTagSpeciali : new Array(),
            arrayDurata : new Array(),
            //arrayEntiSelect2: new Array(),
            arrayTest:new Array({id:1,text:"prova 1"},{id:2,text:"prova 2"},{id:3,text:"prova 3"},{id:4,text:"prova 4"},{id:5,text:"prova 5"}),
            arrayTrattamenti : new Array(),
            searchOpzioni:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:1000},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            argsProdotto:{
                modalita:'MOONLIGHT_uploadImgProdotto',
            },

        }
    },

    methods:{
        openSchedaProdotto : function(prodotto){
            //$('#linkGenerale').click();
            global.filters.popover();
            if (prodotto.ID_PRODOTTO == 0){
                this.prodotto = utils.clone(prodotto);
                this.filters.openPopUp('popUpSchedaProdotto');
                this.getOpzioniModulo();                  
            }
            else{
                utils.ajax("travel/prodotto",{ID_PRODOTTO:prodotto.ID_PRODOTTO}, (response) => {
                    this.prodotto = response.data;
                    this.filters.openPopUp('popUpSchedaProdotto');
                    this.argsProdotto.ID_PRODOTTO = prodotto.ID_PRODOTTO;
                    this.$refs.mappa.startup(this.prodotto.dettagli.LATITUDINE,this.prodotto.dettagli.LONGITUDINE);
                    this.getOpzioniModulo();
                });
            }
        },	
        
        salvaProdotto : function(){
            var api = this.prodotto.ID_PRODOTTO > 0 ? "travel/prodotto/update" : "travel/prodotto/insert";
            utils.ajax(api,this.prodotto, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    if (response.data != undefined){
                        this.$emit("updateGriglia",response.data);
                        this.filters.closePopUp('popUpSchedaProdotto');
                    }
                    this.$emit("updateProdotto",response);
                    if (this.prodotto.ID_PRODOTTO == 0){
                        this.prodotto.ID_PRODOTTO = response.id_inserito;
                        this.argsProdotto.ID_PRODOTTO = response.id_inserito;
                        this.getOpzioniModulo();
                    }
                }
            });
        },			

        getOpzioniModulo : function(){
            utils.ajax("travel/modulo/opzioni",{ID_MODULO:this.prodotto.FK_ID_MODULO},(response)=>{
                this.arrayOpzioniAll = response.data;
                this.setArrayTipologieStruttura();
                this.setArrayFiscale();
                this.setArrayIva();
                this.setArrayStelle();
                this.setArrayTagSpeciali();
                this.setArrayDurata();
                this.setArrayTrattamenti();
                this.setArrayOpzioniModulo();
                for (var i = 0 ; i < this.arrayOpzioniModulo.length ; i++) {
                    this.arrayOpzioniModulo[i].SELEZIONATO = 0;
                    for (var j = 0 ; j < this.prodotto.opzioni.length ; j++){
                        var ID_OPZIONE = this.arrayOpzioniModulo[i].ID_OPZIONE_TIPOLOGICA;
                        var FK_ID_OPZIONE = this.prodotto.opzioni[j].FK_ID_OPZIONE;
                        if (ID_OPZIONE ==  FK_ID_OPZIONE){
                            this.arrayOpzioniModulo[i].SELEZIONATO = 1;     
                            break;   
                        }
                    }             
                }
            });
        },	 

        updateStatoOpzione : function(opzione){
            utils.ajax("travel/prodotto/opzione/update",{SELEZIONATO:opzione.SELEZIONATO,ID_PRODOTTO:this.prodotto.ID_PRODOTTO,FK_ID_OPZIONE:opzione.ID_OPZIONE_TIPOLOGICA}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            });                
        }, 

        openLang : function(){
            this.$refs.lang.openSchedaProdottoLang();
        },

        addScalo : function(){
            this.servizioSelezionato = {ID_SERVIZIO:0,FK_ID_PRODOTTO:this.prodotto.ID_PRODOTTO};
            this.$refs.ricerca.openRicerca(this.prodotto.FK_ID_MODULO);
        },

        elementSelezionato : function(prodotto){
            if (this.servizioSelezionato.ID_SERVIZIO == 0){
                this.addServizio(prodotto);
            }
            else{
                utils.ajax("travel/prodotto/servizio/element/add",{FK_ID_SERVIZIO:this.servizioSelezionato.ID_SERVIZIO,FK_ID_PRODOTTO:prodotto.ID_PRODOTTO}, (response) => {
                    if (response.esito == 0){
                        this.prodotto = response.data;
                        utils.alert.success(response.messaggio);
                    }
                });                 
            }
        },

        addServizio: function(prodotto){
            if (prodotto.FK_ID_PRODOTTO == 0){
                utils.ajax("travel/prodotto/servizio/add",{FK_ID_PRODOTTO:this.prodotto.ID_PRODOTTO,FK_ID_PRODOTTO_ELEMENT_INCLUSO:prodotto.ID_PRODOTTO,FK_ID_MODULO:this.prodotto.FK_ID_MODULO}, (response) => {
                    if (response.esito == 0){
                        this.prodotto = response.data;
                        utils.alert.success(response.messaggio);
                    }
                }); 
            }
            else{
                this.servizioSelezionato = {ID_SERVIZIO:0,FK_ID_PRODOTTO:this.prodotto.ID_PRODOTTO};
                this.filters.openPopUp("popUpServizio");
            }
        }, 

        addElemento : function(servizio){
            this.servizioSelezionato = utils.clone(servizio);
            this.$refs.ricerca.openRicerca(servizio.FK_ID_MODULO);
        }, 

        salvaService : function(){
            var api = (this.servizioSelezionato.ID_SERVIZIO == 0) ? "travel/prodotto/servizio/add" :  "travel/prodotto/servizio/update";
            utils.ajax(api,this.servizioSelezionato, (response) => {
                if (response.esito == 0){
                    this.prodotto = response.data;
                    utils.alert.success(response.messaggio);
                    this.filters.closePopUp("popUpServizio");
                }
            }); 
        },

        modificaServizio : function(servizio){
            this.servizioSelezionato = utils.clone(servizio);
            this.filters.openPopUp("popUpServizio");
        }, 

        deleteServizio : function(servizio){
            //console.log("delete servizio",servizio);
            var params = {ID_SERVIZIO:servizio.ID_SERVIZIO,ID_PRODOTTO:this.prodotto.ID_PRODOTTO};
            //utils.alert.confirm("Sei sicuro di voler eliminare il servizio <strong>"+servizio.DESCRIZIONE+"</strong>?",()=>{
                utils.ajax("travel/prodotto/servizio/delete",params, (response) => {
                    if (response.esito == 0){
                        this.prodotto = response.data;
                        utils.alert.success(response.messaggio);
                    }
                }); 
          //  },this.$root);            
        },

        setArrayTipologieStruttura : function(){
            var idTipologica = 0;
            switch (this.prodotto.FK_ID_MODULO){
                //attivita
                case 1: idTipologica = 25;  break;
                //hotel
                case 2: idTipologica = 30;  break;
                //pacchetti
                case 3: idTipologica = 35;  break;
                //voli
                case 4: idTipologica = 40;  break;
                //camere
                case 5: idTipologica = 46;  break;
            }
            this.arrayTipoStruttura = utils.array.select(this.arrayOpzioniAll,"FK_ID_TIPOLOGICA",idTipologica);
        },

        setArrayOpzioniModulo : function(){
            var idTipologica = 0;
            switch (this.prodotto.FK_ID_MODULO){
                //attivita
                case 1: idTipologica = 26;  break;
                //hotel
                case 2: idTipologica = 31;  break;
                //pacchetti
                case 3: idTipologica = 36;  break;
                //voli
                case 4: idTipologica = 41;  break;
                //camere
                case 5: idTipologica = 45;  break;                    
            }
            this.arrayOpzioniModulo = utils.array.select(this.arrayOpzioniAll,"FK_ID_TIPOLOGICA",idTipologica);
        },

        setArrayFiscale : function(){
            this.arrayFiscale =   utils.array.select(this.arrayOpzioniAll,"FK_ID_TIPOLOGICA",1);
        },
        setArrayIva : function(){
            this.arrayIva =   utils.array.select(this.arrayOpzioniAll,"FK_ID_TIPOLOGICA",2);
        },
        setArrayStelle : function(){
            this.arrayStelle =   utils.array.select(this.arrayOpzioniAll,"FK_ID_TIPOLOGICA",8);
        },
        setArrayTagSpeciali : function(){
            this.arrayTagSpeciali =   utils.array.select(this.arrayOpzioniAll,"FK_ID_TIPOLOGICA",11);
        },
        setArrayDurata : function(){
            this.arrayDurata =   utils.array.select(this.arrayOpzioniAll,"FK_ID_TIPOLOGICA",27);
        },
        setArrayTrattamenti : function(){
            this.arrayTrattamenti=   utils.array.select(this.arrayOpzioniAll,"FK_ID_TIPOLOGICA",19);
        },       

        nuovoFornitore : function(){
            var fornitore = {ID_AZIENDA:0,personaGiuridica:{},FK_ID_ENTE:this.$root.idEnteCorrente,FLG_ATTIVO:1};
            this.$refs.schedaAzienda.openSchedaAzienda(fornitore);
        },

        setFornitore(fornitore){
            this.prodotto.FK_ID_AZIENDA_FORNITORE = fornitore.ID_AZIENDA;
        },
        
        indirizzoSelezionato : function(place){
            utils.google.importaPlace(place,this.prodotto.dettagli);
            this.$refs.mappa.startup(this.prodotto.dettagli.LATITUDINE,this.prodotto.dettagli.LONGITUDINE);
            utils.ajax("core/geo/comuni/select2",{term:this.prodotto.dettagli.CITTA,q:this.prodotto.dettagli.CITTA}, (response) => {
                if (response.esito == 0){
                    this.prodotto.FK_ID_COMUNE = response.data[0].id;
                }
            }); 
            
        },
        
        uploadImmagineProdotto : function(){
            if (this.prodotto.ID_PRODOTTO > 0){
                this.filters.openPopUp("popUpUploadImgProdotto");
            }
            else{
                utils.alert.info("Per caricare un'immagine è necessario procedere al primo salvataggio del prodotto");
            }            
        },
        
        successCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgProdotto"+this.rs);
            utils.ajax("travel/prodotto",{ID_PRODOTTO:this.prodotto.ID_PRODOTTO}, (response) => {
                utils.alert.success(response.messaggio);
                this.prodotto = response.data;
                this.getOpzioniModulo();
                this.$emit("updateProdotto",response);
            });
        },

        errorCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgProdotto"+this.rs);
        },

        setOpzione : function(opzione){
			opzione.SELEZIONATO = opzione.SELEZIONATO == 1 ? 0 : 1;
			utils.ajax("travel/prodotto/opzione/update",{SELEZIONATO:opzione.SELEZIONATO,ID_PRODOTTO:this.prodotto.ID_PRODOTTO,FK_ID_OPZIONE:opzione.ID_OPZIONE_TIPOLOGICA}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            });   
		}, 

		getStyleOpzione(opzione){
            if (opzione.SELEZIONATO == 1){
                return {background:'#1cc88a',color:"#fff"};
            }
            return {};
        },

    },	   

    computed:{
        paginatedListOpzioni(){
            if (this.arrayOpzioniModulo == undefined || this.arrayOpzioniModulo == null)
                return new Array();
            var arr = global.utils.inGrid(this.arrayOpzioniModulo,this.searchOpzioni);
            return arr;
        }, 
        descrizioneTipoProdotto: function(){
            switch (this.prodotto.FK_ID_MODULO){
                case 1:
                    return "Attivita'";
                case 2:
                    return "Struttura";
                case 3:
                    return "Pacchetto";
                case 4:
                    return "Trasporto";
                case 5:
                    return "Camere";
                default:
                    return "Prodotto";
            }
        }
    },

    created:function(){
        /*
        this.arrayEntiSelect2 = new Array();
        for (var i = 0 ; i < this.$root.arrayEnti.length ; i++){
            this.arrayEntiSelect2.push({id: this.$root.arrayEnti[i].ID_ENTE,text: this.$root.arrayEnti[i].LABEL,})
        }
        */
    }

}
</script>