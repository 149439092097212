<template>

    <!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
					<a href="#" class="text-dark fw-semibold" v-on:click="getAeroporti()"> Destinazioni </a> \
                    <span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Aeroporti </span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">	
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 me-2 position-10" style="text-align: left;" v-on:click="nuovoAeroporto()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Aeroporto</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(1)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(1)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(1)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
                </div>
            </div>
        </div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#aeroporti-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Aeroporti
							</div>
						</a>
					</li>
				</ul>
				


				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Utenti selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti gli Utenti</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-code-merge fa-fw me-2"></i><span class="text-muted me-1">|</span>Merge Utenti</a>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina Utenti</a>
								</div>
							</div>
						</li>
						<li class="nav-item">

						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->

	<!-- Content area -->
    <div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="utenti-a">
				<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="aeroporto-a">
									<div class="row">           
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Compagnia" :tabindex="2" v-model="search.ID_AEROPORTO.VALUE" />
										</div>          
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Codice Hub" :tabindex="3" v-model="search.CODICE_IATA.VALUE" />
										</div>   
										<div class="col-12 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Compagnia" :tabindex="4" v-model="search.NOME.VALUE" />
										</div>          
										<div class="col-12 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Hub Compagnia" :tabindex="3" />
										</div>  
										<div class="col-12 col-md-6 col-lg-6">
											<div class="row"> 
												<div class="col-12 col-md-4 col-lg-4 mbx-1">
												</div>
												<div class="col-12 col-md-4 col-lg-4 mbx-1">
		
												</div>
												<div class="col-12 col-md-2 col-lg-2">
													<select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="13" v-model="search.FLG_ATTIVO.VALUE">
														<option value="">Tutti </option>   
														<option value="1"> &nbsp; Attivati</option>
														<option value="0"> &nbsp; Disattivati</option>
													</select>
												</div>
												<div class="col-6 col-md-2 col-lg-2">
					                                <a href="#collapse-a" class="btn btn-flat-info btn-sm w-100 collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
					                                    + FILTRI
													</a>
												</div>
											</div>
										</div>
									</div>
								</form>  
							</div>
						</div>
					</div>
				
			        <div class="card-body box-card mt-0 mb-0">
						<div class="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllAeroporti" v-on:click="filters.selectAll('chkAllAeroporti',paginatedListAeroporti.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:6%;"> # </th>
										<th class="table-head-sm text-center" style="width:4%;"> Img </th>
										<th class="table-head-sm">Nome Aeroporto /<br />Codice Iata / Icao </th>
										<th class="table-head-sm" style="width:15%;">Hub Citt&agrave;</th>
										<th class="table-head-sm" style="width:15%;">Tipologia </th>
										<th class="table-head-sm" style="width:5%;">Visibile</th>
										<th class="table-head-sm" style="width:5%;">Hits</th>
										<th class="table-head-sm text-center" style="width:5%;">Stato</th>
										<th class="table-head-sm text-center" style="width:5%;">Azioni</th>
									</tr>
								</thead>
		                        <tbody>
									<tr v-for="aeroporto in paginatedListAeroporti.arr" v-bind:key="aeroporto.ID_AEROPORTO">
										<td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="aeroporto.SELEZIONATO" class=""> 
										</td>
										<td class="td-class fs-sm">
											<span>{{aeroporto.ID_AEROPORTO}}</span><br />
											<small class="badge bg-light text-primary float-end"> &nbsp; </small>
										</td>
										<td class="td-class text-center">
											<img v-if="aeroporto.ID_AEROPORTO > 0 && aeroporto.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('GEO',aeroporto.URL_AVATAR)" class="squared" style="height: 40px;" alt="" />
											<span v-if="aeroporto.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto squared fs-sm" style="height: 40px !important; line-height: 10px; padding-top: 8px; font-size: 0.6rem !important;">No<br />Photo</span>
										</td>
										<td class="td-class fs-sm">
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="openOffCanvas();openSchedaVisualizzazioneAeroporto(aeroporto)">{{aeroporto.NOME}}</span><br />
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;" data-bs-toggle="tooltip" data-bs-placement="top" :title="aeroporto.CODICE_IATA"><i class="fal fa-code fa-fw"></i> {{aeroporto.CODICE_IATA}} </span>
											<span v-show="aeroporto.CODICE_ICAO != NULL" class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;" data-bs-toggle="tooltip" data-bs-placement="top" :title="aeroporto.CODICE_ICAO"><i class="fal fa-code fa-fw"></i> {{aeroporto.CODICE_ICAO}} </span>
										</td>
										<td class="td-class fs-sm text-uppercase">
											{{aeroporto.GEO_COMUNE}}<br />
										</td>
										<td class="td-class fs-sm">
											<span class="text-uppercase">{{aeroporto.FK_ID_TIPO}}</span><br />
										</td>
		                                <td class="td-class text-center fs-sm">
											<span class="badge badge-flat badge-pill border-secondary text-secondary">0</span>
										</td>
										<td class="td-class text-center fs-sm">
											<span v-on:click="updateFlgVisibile(aeroporto)" v-show="aeroporto.FLG_VISIBILE == 1" class="badge badge-indigo badge-xs text-white cursor-pointer">Pubblico</span>
											<span v-on:click="updateFlgVisibile(aeroporto)" v-show="aeroporto.FLG_VISIBILE == 0" class="badge badge-pink badge-xs text-white cursor-pointer">Admin</span>
										</td>
										<td class="td-class text-center">
											<ISwitch @toggle="updateStatoAeroporto(aeroporto)" :size="'sm'" v-model="aeroporto.FLG_ATTIVO" style="z-index: 0;"></ISwitch>
										</td>
										<td class="td-class text-center">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="modificaAeroporto(aeroporto)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Compagnia</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteAeroporto(aeroporto)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Compagnia</a>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

		                <div class="row page-bottom">
							<div class="col-9 col-md-11" :tabindex="27">
								<div class=" float-end">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListAeroporti.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div> 
						</div> 
					</div>
				</div>
			</div>

		</div>
	</div>	
  

	<!-- START MODALI -->
	 <!--
	<div class="modal fade" id="popUpStato" role="dialog" tabindex="-1">
	    <div class="modal-dialog modal-lg"> 
            <div class="modal-content" >
				<div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title"> SCHEDA {{statoSelezionato.ID_STATO == 0 ? 'NUOVO' : 'MODIFICA'}} STATO </h6>                                                        
                    <div class="btn-group float-end" v-show="statoSelezionato.ID_STATO > 0" :tabindex="2">
                        <button type="button" class="btn btn-yellow btn-yellow-100 btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-bolt fa-fw"></i> AZIONI
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-print"></i> Stampa scheda </a>
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-envelope"></i> Invia comunicazione </a> 
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-paper-plane"></i> Invia sms </a> 
                        </div>
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal">X</button>
                </div>
                <div class="modal-body" style="padding: 0 !important;">

					<div class="d-block d-sm-flex">
                        <div class="profile-tab-nav border-right">
                            <div class="p-3">
                                <div class="img-circle text-center cursor-pointer mb-0" v-on:click="uploadImmagineStato()" :tabindex="3">
									<img v-if="statoSelezionato.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('GEO',statoSelezionato.URL_AVATAR)" class="rounded shadow" alt="" />
									<img v-if="statoSelezionato.URL_AVATAR == 'placeholder.jpg'" :src="filters.getImgPath('NOPHOTO','placeholder.jpg')" class="rounded shadow" alt="" />
                                </div>
                            </div>
                            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" style="padding-right: 5px !important; min-width: 13rem;">
                                <a class="nav-link strech-nav-pill active" id="geodata-tab" data-bs-toggle="pill" href="#geodata" role="tab" aria-controls="geodata" aria-selected="true" :tabindex="4">
                                    <i class="fal fa-globe fa-fw"></i> Dati Geografici
                                </a>
                                <a class="nav-link strech-nav-pill" id="pro-geo-tab" data-bs-toggle="pill" href="#pro-geo" role="tab" aria-controls="pro-geo" aria-selected="false" :tabindex="8">
                                    <i class="fal fa-map fa-fw"></i> Geo Localizzazione
                                </a>
                            </div>
                        </div>
                        <div class="tab-content p-md-2" style="padding-right: 0px !important;" id="v-pills-tabContent">

                            <div class="tab-pane fade show active" id="geodata" role="tabpanel" aria-labelledby="geodata-tab">
                                <h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 3rem;"><i class="fad fa-globe fa-fw me-1"></i> Dati Geografici</h3>
                                <div class="box_26">   

									<div class="row">
										<div class="col-12 col-md-6 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Continente:</label>
											<select class="form-control form-control-sm select-icons" v-model="statoSelezionato.FK_ID_CONTINENTE" :tabindex="3"> 
												<option v-for="continente in arrayContinenti" v-bind:value="continente.ID_CONTINENTE" v-bind:key="continente.ID_CONTINENTE"> {{ continente.DESCRIZIONE }} </option>
											</select> 
										</div>			
										<div class="col-12 col-md-6 form-group-1"> </div>	

										<div class="col-12 col-md-6 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Nome Nazione:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="statoSelezionato.lang.TITOLO" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-user fa-fw"></i></div>
											</div>
										</div>
										<div class="col-12 col-md-6 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Slogan Nazione:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="statoSelezionato.lang.TITOLO" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-user fa-fw"></i></div>
											</div>
										</div>

										<div class="col-12 form-group-1">
											<label class="text-danger custom-control custom-control-right">Categorie associate:</label>
											<Select2 :multiple="true" v-bind:options="arrayCategorie" v-model="statoSelezionato.categorie" :tabindex="17"></Select2>
										</div>

										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Codice Iso:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="statoSelezionato.COUNTRY_CODE" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-user fa-fw"></i></div>
											</div>
										</div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Codice Valuta:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="statoSelezionato.CURRENCY" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-user fa-fw"></i></div>
											</div>
										</div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Prefisso Tel.:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="statoSelezionato.COUNTRY_PREFIX" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-user fa-fw"></i></div>
											</div>
										</div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Fatturazione:</label>
											<select class="form-control form-control-sm select-icons" v-model="statoSelezionato.COUNTRY_VAT" :tabindex="3"> 
												<option value="" data-icon="chrome" selected>Seleziona</option>
												<option value="1"> - Cee</option>
												<option value="2"> - Fuori Cee</option>
											</select> 
										</div>

										<div class="col-12 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Documenti:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="statoSelezionato.COUNTRY_CODE" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-user fa-fw"></i></div>
											</div>
										</div>
										<div class="col-12 col-md-9 form-group-1">
											<label class="text-danger custom-control custom-control-right">Pubblica sul Sito:</label>
											<div class="row">
												<div class="col-lg-1"><span class="btn btn-light btn-sm" v-on:click="statoSelezionato.enti = filters.select2AddAll(statoSelezionato.enti,filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL'))">Tutti</span></div>
												<div class="col-lg-11">
													<Select2 style="padding: 0px 0px 0px 10px; line-height: 19px !important;" :multiple="true" v-bind:options="filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL')" v-model="statoSelezionato.enti" :tabindex="17"></Select2>  
												</div>
											</div>
										</div>

										<div class="col-5 col-md-3 form-group-1"></div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">In Evidenza.</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="statoSelezionato.COUNTRY_PREFIX" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-user fa-fw"></i></div>
											</div>
										</div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Visibile:</label>
											<select class="form-control form-control-sm select-icons" v-model="statoSelezionato.COUNTRY_VISIBLE" :tabindex="3"> 
												<option value="-1" data-icon="chrome" selected>Seleziona</option>
												<option value="0"> - Pubblico</option>
												<option value="1"> - Solo Admin</option>
											</select>
										</div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Stato:</label>
											<select class="form-control form-control-sm select-icons" v-model="statoSelezionato.lang.FLG_ATTIVO" :tabindex="3"> 
												<option value="" data-icon="chrome" selected>Seleziona</option>
												<option value="1"> - Attivo</option>
												<option value="2"> - Disattivo</option>
											</select> 
										</div>
									</div>

								</div>
							</div> 

                            <div class="tab-pane fade" id="pro-geo" role="tabpanel" aria-labelledby="pro-geo-tab">
                                <h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 3rem;"><i class="fad fa-map fa-fw me-1"></i> Geo Localizzazione</h3>
                                <div class="box_26">

                                    <div class="card card-nobottom">
                                        <div class="card-body card-body-2 bg-light">
                                            <h5 class="card-title">Geolocalizzazione Automatica tramite Google Maps
                                                <code class="d-block font-size-base">(permette di individuare le coordinate tramite Google)</code>
                                            </h5>
                                            <div class="row">
                                                <div class="col-md-6 form-group-1">
													<label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Scrivi il nome e selezionalo dal risultato. 
														<a class="pop_over float-end" title="" href="#"><i class="far fa-info-circle float-end"></i></a>
													</label>
													<AddressField @selezionato="indirizzoSelezionato($event)"></AddressField>
												</div>
												<div class="col-md-3 form-group-1">
													<label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Latitudine</label>
													<input class="form-control form-control-sm" type="text" v-model="statoSelezionato.LATITUDINE" >
												</div>
												<div class="col-md-3 form-group-1">
													<label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Longitudine </label>
													<input class="form-control form-control-sm" type="text" v-model="statoSelezionato.LONGITUDINE" >
												</div>
                                            </div>
                                        </div>
                                    </div>                                    
                                
                                </div>
                            </div>

						</div>
					</div>

                </div>
				<div class="modal-footer bg-light p-1"> 
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaStato()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>                
            </div>
        </div>
	</div>
	-->
    
	<div class="modal fade" :id="'popUpUploadImgAeroporto'+rs" role="dialog" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-light text-dark">
                    <h6 class="modal-title">Upload immagine Nazione</h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">    
                    <Upload :args="argsAeroporto" @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div>
    </div>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
//import Flag from '@/components/utils/Flag.vue'
import Upload from '@/components/utils/Upload.vue'

export default {
    name:'Aeroporti',	

    components:{
       	Pagine,
        ISwitch,
        //Flag,
        Upload,
    },
						   

    data: function () {
		return {   
			apiStartup:'travel/aeroporti',
			filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
			rs : utils.generateRandomString(30),
        	arrayAeroporti:new Array(),
        	aeroportoSelezionato:{lang:{}},
	        search:{
	          	PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	          	ID_AEROPORTO:{TYPE:'TEXT',VALUE:''},
	          	NOME:{TYPE:'TEXT',VALUE:''},
	          	CODICE_IATA:{TYPE:'TEXT',VALUE:''},
	          	CODICE_ICAO:{TYPE:'TEXT',VALUE:''},
                FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'},	
				FK_ID_COMUNE_HUB:{TYPE:'COMBO',VALUE:''},	
				FK_ID_TIPO:{TYPE:'COMBO',VALUE:''},
			},
            argsAeroporto:{
                modalita:'MOONLIGHT_uploadImgGeo',
				tipologia:1
			},

	   }

    },

    methods: {  
      	getAeroporti : function() {
	     	utils.ajax(this.apiStartup,{}, (response) => {
	          	this.arrayAeroporti = response.data;
	        });
 		}, 

    },

    computed: {
      	paginatedListAeroporti(){
        	var arr = utils.inGrid(this.arrayAeroporti,this.search);
        	return arr;
      	},
    },

    created:function(){
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		utils.ajax("travel/aeroporti",{}, (response) => {
			this.arrayAeroporti = response.data.AEROPORTI;
			this.getAeroporti();
		});
    }

}
</script>