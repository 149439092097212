<template>

    <!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
					<a href="#" class="d-none d-lg-inline-block text-dark fw-semibold cursor-pointer"> Preferenze </a> <span class="d-none d-lg-inline-block">\</span> 
                    <span class="text-muted ms-1">Configurazione Aziende </span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
                    	
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;">

					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(livello == 2 ? 21 : 22)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(livello == 2 ? 21 : 22)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
	        </div>
		</div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#webowne-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>Info
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#webowne-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-law me-2"></i>Fiscale
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#webowne-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-mail5 me-2"></i>Email
							</div>
						</a>
					</li>
				</ul>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
                            <div class="input-group">
                                <span class="input-group-text"><i class="ph-globe-hemisphere-east"></i></span>
                                <select class="form-select form-select-sm" data-placeholder="Ente" v-model="idEnteCorrente" @change="getConfigurazioniEnte()">
                                    <option v-for="ente in $root.arrayEnti" v-bind:key="ente.ID_ENTE" v-bind:value="ente.ID_ENTE"> {{ ente.LABEL }}</option>
                                </select>
                            </div>
						</li>
						<li class="nav-item">
	                        
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
    <!-- /page header -->
    
    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">

        <div class="card shadow mb-2">
            <div class="card-header d-lg-none p-1">
                <div class="d-grid gap-2">
                    <a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
                        VEDI INFO
                    </a>
                </div>
            </div>
            <div class="collapse show" id="collapse-all" style="">				
                <div class="card-body pb-2">
                    <div class="row">
                        <div class="col-12 col-md-10 col-lg-10 mbx-1">
                            <div class="alert alert-2 alert-secondary border-0">
                                <p style="padding: 0 2px; position:relative; top:5px;">
                                    I campi contrassegnati in <span class="text-danger">rosso</span> sono <strong>Obbligatori</strong>. 
                                    Inserire dati corretti e veritieri, la maggior parte sono pubblicati su documenti fiscali.
                                </p>	  
                            </div>                              
                        </div>
                        <div class="col-12 col-md-2 col-lg-2 mbx-1 d-grid gap-2">
                            <button type="submit" class="btn btn-primary btn-block float-end" v-on:click="salvaOpzioni()"><i class="icon-floppy-disk me-2"></i>Salva Modifiche</button>
                        </div>
                    </div>
                </div>	
            </div>
        </div>

		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="webowne-a">
        		<div class="card shadow mb-0 mb-xs-1">
                    <div class="card-body box-card mt-0 mb-0" id="box-card">
                        <div class="box-config" id="tableFixHead">
			          		<div class="row ">
		                        <div class="col-lg-12">
		                            <fieldset v-if="arrayOpzioniConfigurazione.length > 0">
		                                <legend class="font-weight-semibold mb-2">Dati principali</legend>
		                                <div class="row">
		                                    <div v-for="opz1 in arrayOpzioniConfigurazione[0].opzioni" v-bind:key="opz1.LABEL" class="mb-2" v-bind:class="opz1.COL">
		                                        <label class="form-label text-danger mb-1" v-html="opz1.ETICHETTA"></label>
		                                        <IControl :campo="opz1" v-model="opz1.VALORE" :inputgroup="opz1.GROUP" :icon="opz1.ICON"></IControl>
		                                    </div>
		                                </div>
		                            </fieldset>
		                        </div>                       
                            </div>                    
						</div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="webowne-b">
        		<div class="card shadow mb-0 mb-xs-1">		
                    <div class="card-body box-card mt-0 mb-0" id="box-card">
                        <div class="box-config" id="tableFixHead">
			          		<div class="row ">
		                        <div class="col-lg-12">
		                            <fieldset v-if="arrayOpzioniConfigurazione.length > 0">
		                                <legend class="font-weight-semibold mb-2">Dati Fiscali</legend>
		                                <div class="row">
		                                    <div v-for="opz2 in arrayOpzioniConfigurazione[1].opzioni" v-bind:key="opz2.LABEL" class="mb-2" v-bind:class="opz2.COL">
		                                        <label class="form-label text-danger mb-1" v-html="opz2.ETICHETTA"></label>
		                                        <IControl :campo="opz2" v-model="opz2.VALORE" :inputgroup="opz2.GROUP" :icon="opz2.ICON"></IControl>
		                                    </div>
		                                </div>
		                            </fieldset>
		                        </div>                       
                            </div>                    
						</div>
                    </div>
                </div>
			</div>

            <div class="tab-pane fade" id="webowne-c">
        		<div class="card shadow mb-0 mb-xs-1">
                    <div class="card-body box-card mt-0 mb-0" id="box-card">
                        <div class="box-config" id="tableFixHead">
			          		<div class="row ">
		                        <div class="col-lg-12">
		                            <fieldset v-if="arrayOpzioniConfigurazione.length > 0">
		                                <legend class="font-weight-semibold mb-2">Dati Email</legend>
		                                <div class="row">
		                                    <div v-for="opz3 in arrayOpzioniConfigurazione[2].opzioni" v-bind:key="opz3.LABEL" class="mb-2" v-bind:class="opz3.COL">
		                                        <label class="form-label text-danger mb-1" v-html="opz3.ETICHETTA"></label>
		                                        <IControl :campo="opz3" v-model="opz3.VALORE" :inputgroup="opz3.GROUP" :icon="opz3.ICON"></IControl>
		                                    </div>
		                                </div>
		                            </fieldset>
		                        </div>                       
                            </div>                    
						</div>
                    </div>
                </div>
			</div>

            
       </div>
    </div>

</template>



<script>
import IControl from '@/components/utils/IControl.vue'

export default {
    name:"ConfOwner", 

    components:{
        IControl,

    },

    data: function () {
        return {   
            filters:global.filters,
            arrayFunzionalita: new Array(),
            searchFunzionalita:{

            },

            idEnteCorrente:0,
            arrayOpzioniConfigurazione:new Array(),
            arrayOpzioniConfigurazioneDefault : new Array(
                {
                    titolo:"Azienda",
                    icona:'',
                    visible:false,
                    id:1,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:101, ETICHETTA:'Alias Azienda',        LABEL:'RAG_NAME',           TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',  CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-industry'       },
                        {ID_OPZIONE_BASE:102, ETICHETTA:'Ragione Sociale',      LABEL:'RAG_SOC',            TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',  CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-industry-alt'   },
                        {ID_OPZIONE_BASE:103, ETICHETTA:"Nazionalità",          LABEL:'NATION',             TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',  CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-flag'           },                       
                        {ID_OPZIONE_BASE:104, ETICHETTA:'Indirizzo',            LABEL:'ADDRESS',            TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-3',  CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-map'            },
                        {ID_OPZIONE_BASE:105, ETICHETTA:'Civico',               LABEL:'CIVIC',              TYPE:"TEXT",    VALORE:'-',     COL:'col-5 col-md-2',   CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-align-center'   },                        
                        {ID_OPZIONE_BASE:106, ETICHETTA:'Città',                LABEL:'CITY',               TYPE:"TEXT",    VALORE:'-',     COL:'col-7 col-md-3',  CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-city'           },
                        {ID_OPZIONE_BASE:107, ETICHETTA:'Cap',                  LABEL:'CAP',                TYPE:"TEXT",    VALORE:'-',     COL:'col-6 col-md-2',   CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-link'           },
                        {ID_OPZIONE_BASE:108, ETICHETTA:'Provincia',            LABEL:'PROV',               TYPE:"TEXT",    VALORE:'-',     COL:'col-6 col-md-2',  CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-circle'         },
                        {ID_OPZIONE_BASE:109, ETICHETTA:'Email',                LABEL:'EMAIL',              TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-3',  CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-envelope'       },
                        {ID_OPZIONE_BASE:110, ETICHETTA:'Telefono Ufficio',     LABEL:'OFFICE_PHONE',       TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-3',  CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-phone'          },
                        {ID_OPZIONE_BASE:111, ETICHETTA:'Telefono Booking',     LABEL:'BOOKING_PHONE',      TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-3',  CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-phone'          },
                        {ID_OPZIONE_BASE:112, ETICHETTA:'Telefono Emergenza',   LABEL:'EMERGENCY_PHONE',    TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-3',  CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-phone'          },
        
                    )
                },
                {
                    titolo:"Societario",
                    icona:'',
					visible:false,
                    id:2,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:121, ETICHETTA:'Amministratore Societario',    LABEL:'CEO',                TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-3',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-user-cog'         },
                        {ID_OPZIONE_BASE:122, ETICHETTA:'Partita IVA',                  LABEL:'IVA',                TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-2',      CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-balance-scale'  },                                            
                        {ID_OPZIONE_BASE:123, ETICHETTA:'Codice fiscale',               LABEL:'CF',                 TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-2',      CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-barcode'        },                                            
                        {ID_OPZIONE_BASE:124, ETICHETTA:'Codice SDI',                   LABEL:'SDI',                TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-2',      CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-code'           },                     
                        {ID_OPZIONE_BASE:125, ETICHETTA:'Email certificata',            LABEL:'PEC',                TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-3',      CLASSE_CTRL:'',     GROUP:1,  ICON: 'far fa-certificate'    },                        
                        {ID_OPZIONE_BASE:126, ETICHETTA:'Licenza Turistica',            LABEL:'LICENCE',            TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-3',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-file-alt'         },
                        {ID_OPZIONE_BASE:127, ETICHETTA:'REA',                          LABEL:'REA',                TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-2',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-brackets-curly'   },
                        {ID_OPZIONE_BASE:128, ETICHETTA:'Capitale Sociale',             LABEL:'CAPITAL',            TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-2',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-euro-sign'        },
                        {ID_OPZIONE_BASE:129, ETICHETTA:'Codice IBAN',                  LABEL:'IBAN',               TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-3',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-shield-check'     },
                        {ID_OPZIONE_BASE:130, ETICHETTA:'Codice SWIF',                  LABEL:'SWIF',               TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-2',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-shield-check'     },                        
                        {ID_OPZIONE_BASE:131, ETICHETTA:'Fondo di garanzia',            LABEL:'GARANTY_FOUND',      TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-project-diagram'  },
                        {ID_OPZIONE_BASE:132, ETICHETTA:'Assicurazione',                LABEL:'INSURANCE',          TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-shield-check'     },

                    )
                },
                {
                    titolo:"Email",
                    icona:'',
					visible:false,
                    id:3,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:141, ETICHETTA:'Email Amministrazione',            LABEL:'EMAIL_ADMIN',            TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-envelope' },
                        {ID_OPZIONE_BASE:142, ETICHETTA:'Email Documenti',                  LABEL:'EMAIL_DOCS',             TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-envelope' },
                        {ID_OPZIONE_BASE:143, ETICHETTA:'Email Gruppi',                     LABEL:'EMAIL_GROUP',            TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-envelope' },
                        {ID_OPZIONE_BASE:144, ETICHETTA:'Email Commerciale',                LABEL:'EMAIL_COMMERCIAL',       TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-envelope' },
                        {ID_OPZIONE_BASE:145, ETICHETTA:'Email Booking Clienti',            LABEL:'EMAIL_BOOKING_CLIENT',   TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-envelope' },
                        {ID_OPZIONE_BASE:146, ETICHETTA:'Email Booking Agenzie',            LABEL:'EMAIL_BOOKING_ADV',      TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-envelope' },
                        {ID_OPZIONE_BASE:147, ETICHETTA:'Email Registrazione Clienti',      LABEL:'EMAIL_REGISTER_CLIENT',  TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-envelope' },
                        {ID_OPZIONE_BASE:148, ETICHETTA:'Email Registrazione Agenzie',      LABEL:'EMAIL_REGISTER_ADV',     TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-envelope' },
                        {ID_OPZIONE_BASE:149, ETICHETTA:'Email Preventivi Clienti',         LABEL:'EMAIL_REQUEST_CLIENT',   TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-envelope' },
                        {ID_OPZIONE_BASE:150, ETICHETTA:'Email Preventivi Agenzie',         LABEL:'EMAIL_REQUEST_ADV',      TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-envelope' },

                    )
                },

            ),
        }
    },

    methods: {
        getOpzioni : function(){
            utils.ajax('core/admin/opzioni',{ID_ENTE:this.idEnteCorrente}, (response) => {
                var arrayOpzioni = response.data;
                this.arrayOpzioniConfigurazione = utils.clone(this.arrayOpzioniConfigurazioneDefault);
                for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                    for (var j = 0 ; j < this.arrayOpzioniConfigurazione[i].opzioni.length ; j++){
                        this.arrayOpzioniConfigurazione[i].opzioni[j].CLASSE_CTRL = 'input-sm';
                        this.arrayOpzioniConfigurazione[i].opzioni[j].VMODEL = 'VALORE';
                        this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE = 0;
                        for (var k = arrayOpzioni.length-1 ; k > -1  ; --k){
                            if (this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE_BASE == arrayOpzioni[k].ID_OPZIONE_BASE){
                                this.arrayOpzioniConfigurazione[i].opzioni[j].VALORE = arrayOpzioni[k].VALORE;
                                this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE = arrayOpzioni[k].ID_OPZIONE;
                                arrayOpzioni.splice(k,1);
                                break;
                            }
                        }
                    }
                }
                //AGGIUNGO LE OPZIONI CUSTOM DEL SITO
                for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                    if (this.arrayOpzioniConfigurazione[i].id == 100){
                        for (var j = 0 ; j < arrayOpzioni.length ; j++){
                            this.arrayOpzioniConfigurazione[i].opzioni.push({LABEL:arrayOpzioni[j].LABEL,VALORE:arrayOpzioni[j].VALORE,TYPE:'TEXTAREA',ID_OPZIONE:arrayOpzioni[j].ID_OPZIONE});
                        }
                        this.$forceUpdate();
                        break;
                    }
                }
            });
        },        

        salvaOpzioni : function(){
            var arr = new Array();
            for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                for (var j = 0 ; j < this.arrayOpzioniConfigurazione[i].opzioni.length ; j++){
                    arr.push(this.arrayOpzioniConfigurazione[i].opzioni[j]);
                }
            }
            utils.ajax('core/admin/opzioni/update',{opzioni:arr,ID_ENTE:this.idEnteCorrente}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getOpzioni();
                }
            });
        },

        getConfigurazioniEnte : function(){
            this.getOpzioni();
        },


    },

    computed: {
        uploadDisabled() {
            return this.files.length === 0;
          }
    },

    beforeCreate : function(){
        setTimeout(() => {
            global.utils.deltaGriglia = 100;   
            global.utils.resizeContent();     
        }, 300);
    },
    
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },

    created: function () {
        this.idEnteCorrente = this.$root.idEnteCorrente;
       this.getOpzioni();
    },

}
</script>