<template>

    <div id="popUpRicercaProdotti" class="modal fade">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-info text-white">
                    Ricerca prodotti
                </div>
                <div class="modal-body-2">
                    <div class="box-chat">
                        <div class="row w-100">
                            <input type="text" v-model="search.TITOLO.VALUE" class="form-control">
                            <select class="form-control" v-model="search.FK_ID_MODULO.VALUE">
                                <option value="">Tutti</option>
                                <option value="1">Attività</option>
                                <option value="2">Hotel</option>
                                <option value="3">Pacchetti</option>
                                <option value="4">Voli</option>
                            </select>
                        </div>
                        <div style="padding:20px;">
                            <div class="alberoContainer" v-for="prodotto in paginatedList.arr" v-bind:key="prodotto.ID_PRODOTTO" style="width: 100%; overflow: auto; border-left: 1px solid rgb(187, 187, 187);">
                                <div style="width: 60px; border-top: 1px solid rgb(187, 187, 187); float: left; height: 22px; margin-top: 26px; margin-left: -20px;">                                </div>
                                <div class="albero" v-on:click="gestioneAperturaNodo($event,prodotto)" style="margin-top: 5px; float: left; margin-bottom: 5px; width: calc(100% - 60px); background: rgb(104, 209, 254);">
                                    <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="prodotto.SELEZIONATO" @change="gestioneSelezioneProdotto(prodotto)"> <span>{{ prodotto.ID_PRODOTTO }} - {{ prodotto.TITOLO }}</span>
                                </div>
                                <div v-show="prodotto.APERTO == 1">
                                    <!-- CAMERE HOTEL -->
                                    <div class="alberoContainer" v-for="figlio in prodotto.FIGLI" v-bind:key="figlio.ID_PRODOTTO" style="width: calc(100% - 60px); margin-left: 60px; overflow: auto; border-left: 1px solid rgb(187, 187, 187);">
                                        <div style="width: 60px; border-top: 1px solid rgb(187, 187, 187); float: left; height: 22px; margin-top: 26px; margin-left: -20px;"></div>
                                        <div class="albero"  v-on:click="gestioneAperturaNodo($event,figlio)" style="margin-top: 5px; float: left; margin-bottom: 5px; width: calc(100% - 60px);">
                                            <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="figlio.SELEZIONATO" @change="gestioneSelezioneFiglio(figlio)"> <span></span>{{ figlio.ID_PRODOTTO }} - {{ figlio.TITOLO }}</div>
                                        <div  v-show="figlio.APERTO == 1">
                                            <div class="alberoContainer" v-for="opzione in figlio.OPZIONI" v-bind:key="opzione.FK_ID_OPZIONE" style="width: calc(100% - 60px); margin-left: 60px; overflow: auto; border-left: 1px solid rgb(187, 187, 187); ">
                                                <div style="width: 60px; border-top: 1px solid rgb(187, 187, 187); float: left; height: 22px; margin-top: 26px; margin-left: -20px;"></div>
                                                <div class="albero" style="margin-top: 5px; float: left; margin-bottom: 5px; width: calc(100% - 60px);">   <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="opzione.SELEZIONATO"><span>{{ opzione.DESCRIZIONE }}</span></div>                                          
                                            </div>
                                        </div>
                                    </div>
                                    <!-- OPZIONI -->
                                    <div class="alberoContainer"  v-for="opzione in prodotto.OPZIONI" v-bind:key="opzione.FK_ID_OPZIONE" style="width: calc(100% - 60px); margin-left: 60px; overflow: auto; border-left: 1px solid rgb(187, 187, 187);">
                                        <div style="width: 60px; border-top: 1px solid rgb(187, 187, 187); float: left; height: 22px; margin-top: 26px; margin-left: -20px;"></div>
                                        <div class="albero" style="margin-top: 5px; float: left; margin-bottom: 5px; width: calc(100% - 60px);"><i class="far fa-folder"></i> 
                                            <span> <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="opzione.SELEZIONATO">{{ opzione.DESCRIZIONE }}</span>
                                        </div>                                        
                                    </div>
                                    <div style="width: calc(100% - 60px); overflow: auto; margin-left: 60px;"></div>
                                </div>
                            </div>                            
                        </div>
                        <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
		                        <div class="float-end">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
			                    </div>
	                		</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
	            			</div>
	        			</div>
                    </div>
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="triggerSelezionaProdotti()"><i class="fal fa-save fa-fw me-1"></i> Salva </button>                                   
                </div> 
            </div>
        </div>
    </div>

</template>

<script>

import Pagine from '@/components/utils/Pagine.vue'

export default {
    name:"RicercaProdotti",
    props:["ts"],
    emits:['prodottiSelezionati'],
    components:{
        Pagine,
    },
    data:function(){
        return {
            filters:global.filters,
            modulo:0,
            descrizione:'',
            arrayProdotti:new Array(),
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            TITOLO:{TYPE:'TEXT',VALUE:''},
				FK_ID_MODULO:{TYPE:'COMBO',VALUE: ''},
            },
        }
    },
    methods:{
        openRicerca:function(modulo){
            this.modulo = modulo;
            this.filters.openPopUp('popUpRicercaProdotti');
            this.arrayProdotti = new Array();
            utils.ajax("travel/prodotti/tree",{}, (response) => {
                if (response.esito == 0){
                    this.arrayProdotti = response.data;
                }
            });   
        },
        gestioneAperturaNodo : function(event,item){
            if (event.target.type == 'checkbox'){
                return;
            }   
            item.APERTO = item.APERTO == 1 ? 0 : 1;         
        },
        gestioneSelezioneProdotto : function(prodotto){
            for (var i = 0 ; i < prodotto.FIGLI.length ; i++){
                prodotto.FIGLI[i].SELEZIONATO = prodotto.SELEZIONATO;
                for (var j = 0 ; j < prodotto.FIGLI[i].OPZIONI.length ; j++){
                    prodotto.FIGLI[i].OPZIONI[j].SELEZIONATO = prodotto.SELEZIONATO;
                }
            }
            for (var j = 0 ; j < prodotto.OPZIONI.length ; j++){
                prodotto.OPZIONI[j].SELEZIONATO = prodotto.SELEZIONATO;
            }
        },
        gestioneSelezioneFiglio : function(prodotto){
            for (var j = 0 ; j < prodotto.OPZIONI.length ; j++){
                prodotto.OPZIONI[j].SELEZIONATO = prodotto.SELEZIONATO;
            }
        },
        triggerSelezionaProdotti : function(){
            var arraySelezionati = new Array();
            for (var k = 0 ; k < this.arrayProdotti.length ; k++){
                var prodotto = this.arrayProdotti[k];
                for (var i = 0 ; i < prodotto.FIGLI.length ; i++){
                    for (var j = 0 ; j < prodotto.FIGLI[i].OPZIONI.length ; j++){
                        if (prodotto.FIGLI[i].OPZIONI[j].SELEZIONATO == 1){
                            arraySelezionati.push({FK_ID_PRODOTTO:prodotto.FIGLI[i].ID_PRODOTTO,FK_ID_OPZIONE:prodotto.FIGLI[i].OPZIONI[j].FK_ID_OPZIONE})
                        }
                    }
                }   
                for (var j = 0 ; j < prodotto.OPZIONI.length ; j++){
                    if (prodotto.OPZIONI[j].SELEZIONATO == 1){
                        arraySelezionati.push({FK_ID_PRODOTTO:prodotto.ID_PRODOTTO,FK_ID_OPZIONE:prodotto.OPZIONI[j].FK_ID_OPZIONE})
                    }
                }            
            }
            this.filters.closePopUp('popUpRicercaProdotti');
            this.$emit("prodottiSelezionati",arraySelezionati);
        }
    },
    computed:{
        paginatedList(){
            var arr = utils.inGrid(this.arrayProdotti,this.search);
            return arr;
        }
    },	
}
</script>