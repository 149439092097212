<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
					<a href="#" class="text-dark fw-semibold cursor-pointer">{{ this.$root.cartellaSelezionata }}  </a> \
					<span class="text-muted"> Gestione Logs </span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">

					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 me-2 position-10" style="text-align: left;">
						
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(this.modulo)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
				</div>
			</div>
		</div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#accessi-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded confaccessi" data-tab="livelli" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-list-numbers"></i><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Logs
							</div>
						</a>
					</li>
				</ul>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0 select-small">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
									<i class="ph-gear me-1"></i> Azioni Multiple
									<span class="d-lg-none ms-2">More</span>
								</a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
		                                <div class="dropdown-menu">
											<a href="#" @click="esportaExcelLogs(true)" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Logs selezionati</a>
											<a href="#" @click="esportaExcelLogs(false)" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti i Logs</a>
		                                </div>
	                            	</div>
								</div>
							</div>
						</li>
					</ul>
			   </div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->
	
	<!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="accessi-a">
				<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="accesso-a">
									<div class="row">
										<div class="col-4 col-md-2 col-lg-2 mbx-1">
											<DatePickerNew 
												placeholder="Seleziona Data" 
												:format="'dd/MM/yyyy'"
												:dateAbilitate="arrayLogsDates" 
												:modelValue="dataSelezionata"
												@update:modelValue="handleDataChange"
											/>
										</div> 
										<div class="col-4 col-md-2 col-lg-2 mbx-1">
											<select class="form-select form-select-sm" placeholder="Filtra per Livello" v-model="search.LIVELLO.VALUE">
												<option value="">Tutti</option>
												<option value="INFO">INFO</option>
												<option value="WARNING">WARNING</option>
												<option value="ERROR">ERROR</option>
											</select>
										</div>           
										<div class="col-8 col-md-2">
											<input class="form-control form-select" type="text" placeholder="Filtra per IP" v-model="search.IP.VALUE" />
										</div>          
										<div class="col-8 col-md-2" v-for="item in filtriDinamici" :key="item.key">
											<input type="text" class="form-control" :placeholder="'Cerca ' + item.key" v-model="item.value.VALUE" />
										</div>
									</div>
								</form>
		                    </div>
						</div>
					</div>

			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="tableFixHead" id="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllLogs" v-on:click="filters.selectAll('chkAllLogs',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:6%;"> # </th>
										<th class="table-head-sm">Orario</th>
										<th class="table-head-sm" style="width:30%;">Livello</th>
										<th class="table-head-sm" style="width:15%;">Messaggio</th>
										<th class="table-head-sm" style="width:15%;">Ip</th>
										<th class="table-head-sm text-capitalize" v-for="col in colonneDinamiche" :key="col">
											{{ col }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(log,index) in paginatedList.arr" v-bind:key="'log-' + index">
										<td class="td-class text-center"> 
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="log.SELEZIONATO" class=""> 
										</td>
										<td class="td-class fs-sm"></td>
										<td class="td-class fs-sm">
											<span>{{log.ORARIO}}</span><br />
											<small class="badge bg-light badge-xs text-muted float-end cursor-pointer"></small>
										</td>
										<td class="td-class fs-sm"> 
											<span class="text-primary text-uppercase fw-bold cursor-pointer"> {{ log.LIVELLO }}</span><br />
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;" >--</span>
										</td>
										<td class="td-class fs-sm"> 
											<span class="badge text-secondary text-muted">{{ log.MESSAGGIO }}</span>
										</td>
										<td class="td-class fs-sm"> 
											<span class="badge text-secondary text-muted">{{ log.IP }}</span>
										</td>
										<td class="td-class fs-sm" v-for="col in colonneDinamiche" :key="'ctx-' + col">
											{{ log[col] || '-' }}
											<!--
											<div><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Utenti: <a href="#"></a></div>
											<div><i class="ph-clock fs-base lh-base align-top text-danger me-1"></i> Core: <a href="#">0</a></div>
											-->
										</td>	 
									</tr>
								</tbody>
							</table>     
                   		</div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">LIVELLI: {{ paginatedList.count }}</span> 
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end">
		                            <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH"></Pagine>
		                        </div>
	                        </div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
                            </div>
						</div>
					</div>
				</div>
			</div>

		</div> 
	</div>

					
	<!-- MODAL -->




</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
//import ISwitch from '@/components/utils/ISwitch.vue'
import DatePickerNew from '@/components/utils/DatePickerNew.vue'


export default {
    name:"Logs",

    components:{
        Pagine,
        //ISwitch,
		DatePickerNew,
		    
    }, 

    props:['livello'],

    data: function () {
        return {   
			apiStartup:'core/logs/dates',
			filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
            arrayLogsDates: new Array(), 
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				LIVELLO:{TYPE: 'COMBO', VALUE: ''},
				IP: { TYPE: 'TEXT', VALUE: '' }
            },
			arrayLogs: new Array(),
			dataSelezionata: '', 
        }
    },

    methods: {
        getDates: function () {
            utils.ajax(this.apiStartup,{'DOMINIO' : this.$root.cartellaSelezionata}, (response) => {
                this.arrayLogsDates = response.data;
            });
        },

		getLogs: function () {
			if(this.dataSelezionata != ''){
				utils.ajax('core/logs', { 'DOMINIO': this.$root.cartellaSelezionata, 'DATA': this.dataSelezionata }, (response) => {
					this.arrayLogs = response.data;
					// Reset per ogni get
					this.search = { 
						PAGINATION: { LENGTH: 0, PAGE_NUMBER: 1, PAGE_SIZE: 50 },
						LIVELLO: { TYPE: 'COMBO', VALUE: '' },
						IP: { TYPE: 'TEXT', VALUE: '' }
					};

					const filtriStatici = ['FOLDER', 'ORARIO', 'LIVELLO', 'MESSAGGIO', 'IP'];
					const filtriDinamici = new Set();
					for (let i = 0; i < this.arrayLogs.length; i++) {
						const log = this.arrayLogs[i];
						for (let filtro in log) {
							if (!filtriStatici.includes(filtro)) {
								filtriDinamici.add(filtro);
							}
						}
					}
					for (let filtro of filtriDinamici) {
						this.search[filtro] = { TYPE: 'TEXT', VALUE: ''};
					}
				});
			}
			else {
				utils.alert.warning('Devi selezionare almeno una Data!');
			}

        },

		handleDataChange(newDate) {
			this.dataSelezionata = newDate;
			this.getLogs();
		},

		esportaExcelLogs(selezionati = true) {
			const dati = selezionati ? this.paginatedList.arr.filter(log => log.SELEZIONATO) : this.arrayLogs;
			if (selezionati && dati.length == 0) {
				utils.alert.warning("Nessun log selezionato.");
				return;
			}
			const nomeFile = selezionati ? "logs_selezionati.xlsx" : "logs_tutti.xlsx";
			utils.downloadExcel("core/logs/export", { data: dati }, nomeFile);
		},



    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayLogs,this.search);
            return arr;
        },

		filtriDinamici() {
			const result = [];
			const filtriStatici = ['PAGINATION', 'LIVELLO', 'IP'];
			for (let key in this.search) {
				if (!filtriStatici.includes(key)) {
					result.push({ key, value: this.search[key] });
				}
			}
			return result;
      	},

		colonneDinamiche() {
			const filtriStatici = ['FOLDER', 'ORARIO', 'LIVELLO', 'MESSAGGIO', 'IP'];
			const filtri = new Set();
			for (let i = 0; i < this.arrayLogs.length; i++) {
				const log = this.arrayLogs[i];
				for (let filtro in log) {
					if (!filtriStatici.includes(filtro)) {
					filtri.add(filtro);
					}
				}
			}
			return Array.from(filtri);
		},

    },
    
    created: function () {
		this.getDates()
	}
}
</script>


