<template>
    <li class="tree-node" :class="{'is-root': !item[propsMap.parentId]}">
        <div class="node-content">
            <div class="node-header">
                <span class="expand-icon" @click="toggle">
                    <span :class="isOpen ? 'rotate-90' : ''">›</span>
                </span>
            
                <div class="node-info">
                    <span class="node-label">
                        {{ item[propsMap.label] }}
                    </span>
                    <div class="node-actions">
                        <button class="btn-action" @click="aggiungiMenu(item)" title="Aggiungi">Aggiungi
                            <i class="fas fa-plus"></i>
                        </button>
                        <button class="btn-action" @click="editMenu(item)" title="Modifica">Modifica
                            <i class="fas fa-edit"></i>
                        </button>
                        <button class="btn-action" @click="deleteMenu(item)" title="Elimina">Elimina
                            <i class="fas fa-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
  
      <div v-if="isOpen" class="node-children">
        <draggable
          :list="item[propsMap.children]"
          group="tree"
          @update="onUpdate"
          @start="onDragStart"
          @end="onDragEnd"
          @add="onAdd"
        >
          <template v-if="!item[propsMap.children]?.length">
            <div class="empty-dropzone">
              <i class="fas fa-plus-circle"></i>
              <span>Trascina qui per aggiungere</span>
            </div>
          </template>
          <TreeNode
            v-for="child in item[propsMap.children]"
            :key="child[propsMap.id]"
            :item="child"
            :propsMap="propsMap"
            @child-change="$emit('child-change', $event)"
            @modifica-item="editMenu"
            @elimina-item="deleteMenu"
            @aggiungi-item="aggiungiMenu"
          />
        </draggable>
      </div>
    </li>
</template>


<script>
import { VueDraggableNext } from 'vue-draggable-next';
import TreeNode from './TreeNode.vue';

export default {
  name: 'TreeNode',
  components: {
    draggable: VueDraggableNext,
    TreeNode,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    arrayTypes: {
        type: Array,
        required: true,
    },
    propsMap: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
        filters:global.filters,
        isOpen: false,
        isSelectedForDrag: false,
        draggedItem: null, 
    };
  },
  methods: {
    onUpdate(evt) {
      console.log('[CHILD] Update event fired:', evt);

      const parentId = this.item[this.propsMap.id];
      this.item[this.propsMap.children].forEach((child) => {
        child[this.propsMap.parentId] = parentId; 
      });

      this.$emit('child-change', evt);
    },
    onDragStart(evt) {
      console.log('[CHILD] Drag start:', evt);
      this.draggedItem = evt.item; 
    },
    onDragEnd(evt) {
      console.log('[CHILD] Drag end:', evt);

      const parentId = this.item[this.propsMap.id];
      const draggedItem = this.draggedItem;

      if (draggedItem) {
        draggedItem[this.propsMap.parentId] = parentId; 
        console.log(`Aggiornato parentId per ${draggedItem[this.propsMap.id]} a ${parentId}`);
      }

      this.$emit('child-change', evt);
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    handleCheckboxClick(event) {
      this.isSelectedForDrag = !this.isSelectedForDrag;
      event.stopPropagation();
    },

    editMenu : function(item){
        this.$emit('modifica-item',item);
    },

    deleteMenu : function (item){
        this.$emit('elimina-item',item);
    },

    aggiungiMenu : function (item){
        this.$emit('aggiungi-item',item);
    },
    
  },
  
};
</script>

<style scoped>
li {
  list-style-type: none;
  margin-bottom: 6px;
}

.list-group-item {
  margin-bottom: 4px;
  border: 1px solid #ccc;
}

.rotate-90 {
  display: inline-block;
  transform: rotate(90deg);
  transition: transform 0.2s ease;
}
.tree-node {
  position: relative;
  list-style: none;
  padding-left: 30px;
}

.tree-node:not(.is-root):before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 2px solid #e0e0e0;
}

.tree-node:not(.is-root):after {
  content: '';
  position: absolute;
  left: 0;
  top: 20px;
  width: 25px;
  border-top: 2px solid #e0e0e0;
}

.node-content {
  position: relative;
  padding: 8px 12px;
  margin: 4px 0;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: all 0.2s ease;
}

.node-content:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.node-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.expand-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #666;
}

.node-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.node-label {
  font-size: 14px;
  color: #333;
}

.node-actions {
  display: flex;
  gap: 4px;
  opacity: 0;
  transition: opacity 0.2s;
}

.node-content:hover .node-actions {
  opacity: 1;
}

.btn-action {
  padding: 4px 8px;
  border: none;
  background: transparent;
  color: #666;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s;
}

.btn-action:hover {
  background: #f5f5f5;
  color: #333;
}

.empty-dropzone {
  padding: 12px;
  border: 2px dashed #e0e0e0;
  border-radius: 6px;
  color: #999;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
}

.node-children {
  position: relative;
  margin-left: 8px;
}

/* Drag and drop styles */
.sortable-ghost {
  opacity: 0.4;
}

.sortable-chosen {
  background: #f8f9fa;
}

</style>
