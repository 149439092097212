<template>

    <div class="d-flex flex-column flex-lg-row mb-0" >
        <div class="me-lg-3 mb-2 mb-lg-0">
            <img :src="riga.prodotto.IMMAGINE_PRODOTTO" class="squared-circle" style="width: 67px;" alt="" />
        </div>
        <!-- DATI QUOTA PRINCIPALE -->
        <div class="flex-fill">
            <span class="text-primary fw-semibold cursor-pointer" v-on:click="openProdotto(riga.prodotto)">
                <span class="d-sm-none">{{ filters.accorciaTesto(riga.prodotto.DESCRIZIONE,30) }}</span>
                <span class="d-none d-md-block">{{ riga.prodotto.DESCRIZIONE }}</span>
            </span>

            <!-- DATI QUOTA PRINCIPALE SUBMENU -->
            <div class="row ms-0">
                <div class="col-9 " v-bind:class="{'ps-0':modalita == 2}">
                    <ul class="list-inline fs-sm mb-0 mt-0">
                        <span class="badge bg-dark bg-opacity-20 text-reset badge-xs">{{ filters.getLabel($root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',riga.prodotto.FK_ID_TIPO,'DESCRIZIONE') }}</span>
                        <span class="ms-1 me-1">|</span> 
                        <li class="list-inline-item">
                            <i class="fad fa-map-signs fa-fw me-1"></i> 
                            <a href="#" class="cursor-pointer">
                                {{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',riga.prodotto.FK_ID_COMUNE,'STATO') }} - 
                                {{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',riga.prodotto.FK_ID_COMUNE,'REGIONE') }}, 
                                {{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',riga.prodotto.FK_ID_COMUNE,'COMUNE') }} 
                            </a>
                        </li>
                    </ul> 
                    <p class="fs-sm mb-3">
                        <span class="me-1"><i class="ph-thumbs-up text-success"></i>  </span>
                    </p>  
                </div>
                <div class="col-3 text-end">
                    <!--
                    <div class="text-indigo " v-bind:class="{'testo-12':modalita == 2,'testo-10':modalita == 1}">
                        <label class="form-label mb-0">Prezzo</label><br>
                        <span style="font-weight: bold;">&euro; {{ getTotaleQuote(riga.quote) }}</span>
                    </div>
                    -->
                </div>
            </div>           
        </div>
    </div>
    <!-- DATI QUOTE FIGLIE -->
    <div v-for="(quota,$i) in riga.quote" v-bind:key="quota.ID_QUOTA">
        <!-- CAMERE -->
        <div class="riga-quota-margin-figlia"  style="margin-left:50px;">
            <div class="d-flex flex-column flex-lg-row mb-1">
                <div class="me-lg-3 mb-2 mb-lg-0">
                    <img :src="quota.prodotto.IMMAGINE_PRODOTTO" class="squared-circle riga-quota-img-figlia" alt="" />
                </div>
                <!-- DATI QUOTA PRINCIPALE -->
                <div class="flex-fill">
                    <span class="badge bg-dark bg-opacity-20 text-reset badge-xs">Camera {{$i+1}}</span>
                    <span class="ms-1 me-1">|</span> 
                    <span class="text-primary fw-semibold cursor-pointer" v-on:click="openProdotto(quota.prodotto)">{{ quota.DESCRIZIONE }}</span>
                    <!-- BOTTONE AZIONI -->
                    <span class="fs-sm text-muted float-end">
                        <div class="dropdown align-self-center" v-show="modalita == 2">
                            <a href="#" class="badge bg-secondary bg-opacity-20 text-secondary text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="ph-list"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end" style="">
                                <span class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="modificaDate(quota)"><i class="icon-truck me-2"></i> Gestione </span>
                                <span class="dropdown-item" v-on:click="editQuota(quota)"><i class="icon-truck me-2"></i> Edita Quota</span>
                                <span class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-file-download me-2"></i> Quota solo Costi</span>
                                <span class="dropdown-item" v-on:click="setQuotaVisibile(quota,0)" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0 && quota.FLG_VISIBILE == 1"><i class="icon-wallet me-2"></i> Nascondi quota</span>
                                <span class="dropdown-item" v-on:click="setQuotaVisibile(quota,1)" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0 && quota.FLG_VISIBILE == 0"><i class="icon-wallet me-2"></i> Rendi quota visibile</span>
                                <div class="dropdown-divider" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"></div>
                                <span class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="eliminaQuota(quota)"><i class="icon-warning2 me-2"></i> Elimina Quota</span>
                            </div>
                        </div>
                    </span>
                    <!-- DATI QUOTA PRINCIPALE SUBMENU -->
                    <div class="row">
                        <div class="col-8 " v-bind:class="{'ps-0':modalita == 2}">
                            <div v-if="quota.FK_ID_QUOTA_PRINCIPALE == 0">
                                <ul class="list-inline fs-sm mb-0">
                                    <li class="list-inline-item">
                                        <span v-show="quota.FK_ID_PRODOTTO > 0 && (quota.TOT_ADU + quota.TOT_CHD + quota.TOT_INF) > 0">
                                            <i class="fad fa-users fa-fw me-1"></i>{{ quota.TOT_ADU }} Adu, {{ quota.TOT_CHD }} Chd, {{ quota.TOT_INF }} Inf 
                                        </span>                                
                                    </li>
                                    <span class="ms-0 me-2">|</span>
                                    <li class="list-inline-item"><i class="fad fa-utensils fa-fw ms-1 me-1"></i><a href="#" class="cursor-pointer"> All</a></li>
                                </ul>
                                <p class="fs-sm mb-3">
                                    <span class="me-1"><i class="ph-thumbs-up text-success"></i>  </span>
                                </p> 
                            </div>
                            <div v-else>
                                <ul class="list-inline fs-sm mb-0 mt-0">
                                    <li class="list-inline-item">
                                        <i class="fad fa-bed fa-fw ms-1 me-1"></i>
                                        {{filters.getLabel($root.arrayRtsType,'ID_OPZIONE_TIPOLOGICA',quota.quote[0].FK_ID_OPZIONE,'DESCRIZIONE')}} - 
                                        {{filters.getLabel($root.arrayRtsType,'ID_OPZIONE_TIPOLOGICA',quota.quote[0].FK_ID_OPZIONE,'CUSTOM_1')}}
                                    </li>
                                    <span class="ms-0 me-1">|</span>
                                    <li class="list-inline-item"><i class="fad fa-utensils fa-fw ms-1 me-1"></i><a href="#" class="cursor-pointer"> All</a></li>
                                </ul>
                                <p class="fs-sm mb-3 mt-0">
                                    <span v-show="quota.FK_ID_PRODOTTO > 0 && (quota.TOT_ADU + quota.TOT_CHD + quota.TOT_INF) > 0">
                                        <i class="fad fa-users fa-fw me-1"></i>{{ quota.TOT_ADU }} Adu, {{ quota.TOT_CHD }} Chd, {{ quota.TOT_INF }} Inf 
                                    </span>
                                </p>                    
                            </div>
                        </div>
                        <div class="col-4 text-end">
                            <div class="text-indigo " v-bind:class="{'testo-12':modalita == 2,'testo-10':modalita == 1}">
                                <label class="form-label mb-0">Prezzo</label><br>
                                <span style="font-weight: bold;">&euro; {{ quota.PREZZO_TOTALE }}</span>
                            </div>
                        </div>
                    </div>            
                </div>
            </div>	
        </div>

    </div>
 
</template>



<script>


export default {
    name:'RigaQuotaHotel',

    emits: ['elimina', 'modifica', 'modificaDate','selezionaProdotto'],

    props:['riga','modalita'],

    components:{
    },

    data:function(){
        return {
            filters:global.filters,
        }
    },
    
    methods:{
        eliminaQuota : function(quota){
            this.$emit("elimina",quota);
        },
        editQuota : function(quota){
            console.log(quota,"primo")
            this.$emit("modifica",quota);            
        },
        setQuotaVisibile : function(quota,flgVisibile){
            quota.FLG_VISIBILE = flgVisibile;
            utils.ajax("travel/ordine/quota/update",quota, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            });            
        },
        modificaDate : function(quota){
            this.$emit("modificaDate",quota);            
        },
        getStileTitolo : function(){
            var stile = {};
            if (this.quota.FK_ID_QUOTA_PRINCIPALE > 0){
                stile['padding-left'] = "40px";
            }
            return stile;
        },
        getStileRiga : function(){
            var stile = {};
            if (this.quota.FK_ID_QUOTA_PRINCIPALE > 0){
                stile['font-size'] = "14px";
            }
            else{
                stile['font-size'] = "18px";
            }
            return stile;
        },
        getLblStatoQuota : function(quota){
            switch (quota.FK_ID_STATO){
                case 1:
                    return "CONFERMATA";
                case 2:
                    return "IN RICHIESTA";
                default:
                    return "-";
            }
        },
        openProdotto : function(prodotto){
            if(this.riga.FK_ID_MODULO != 6){
                this.$emit("selezionaProdotto",prodotto); 
            }
        },
        getTotaleQuote : function(quote){
            var totale = 0;
            for (var i = 0 ; i < quote.length; i++){
                totale += parseFloat(quote[i].PREZZO_TOTALE);
            }
            return totale;
        },
        getLabel : function(FK_ID_TIPO){
            const tipo = utils.array.select(this.$root.arrayAllOpzioni,"ID_OPZIONE_TIPOLOGICA",FK_ID_TIPO,1)[0];
            return tipo != undefined ? tipo.DESCRIZIONE : '';
        }
        
       
    },
    created : function(){
    }
}
</script>
