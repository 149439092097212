<template>

    <div class="row">
        <div class="col-3">
            <div v-if="ordine.FK_ID_AZIENDA_AGENZIA > 0">
                <img v-if="ordine.agency.URL_AVATAR > 0 && ordine.agency.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',ordine.agency.URL_AVATAR)" class="rounded" style="width: 100% !important; height: 5rem; font-size: 2rem;" alt="" />
	            <div v-if="ordine.agency.URL_AVATAR == 'placeholder.jpg' || ordine.agency.URL_AVATAR == 0" class="btn-nophoto nophoto">No<br />Photo</div>
            </div>
            <div v-else>
                <img v-if="ordine.intestatarioPersonaFisica.ID_PERSONA_FISICA > 0 && ordine.intestatarioPersonaFisica.comuneResidenza.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',ordine.intestatarioPersonaFisica.URL_AVATAR)" class="rounded" style="width: 100% !important; height: 5rem; font-size: 2rem;" alt="" />
	            <div v-if="ordine.intestatarioPersonaFisica.comuneResidenza.URL_AVATAR == 'placeholder.jpg' || ordine.intestatarioPersonaFisica.ID_PERSONA_FISICA == 0 " class="btn-nophoto nophoto">No<br />Photo</div>
            </div>
        </div>
        <div class="col-9" style="height: 6.7rem;">
            <div v-if="ordine.FK_ID_AZIENDA_AGENZIA > 0">
                <h4 class="text-primary mb-1">
                    {{ ordine.agency.personaGiuridica.ALIAS }}
                    <span v-show="flgLog == false" class="badge bg-danger bg-opacity-20 text-danger p-1 cursor-pointer float-end" v-on:click="openOrdine()"><i class="fal fa-edit fa-fw text-danger"></i></span>
                </h4>
                <p class="text-dark mb-1">
                    <i class="fal fa-map-marker-check fa-fw me-1"></i> {{ ordine.agency.personaGiuridica.NOME }} ({{ ordine.agency.personaGiuridica.PROV }})<br />
                    <span class="d-sm-none"><i class="fal fa-envelope fa-fw me-1"></i> {{ filters.accorciaTesto(ordine.agency.personaGiuridica.EMAIL,30) }}</span> 
                    <span class="d-none d-md-block"><i class="fal fa-envelope fa-fw me-1"></i> {{ ordine.agency.personaGiuridica.EMAIL }}</span> 
                    <i class="fal fa-phone fa-fw me-1"></i> {{ ordine.agency.personaGiuridica.TELEFONO }}
                </p>
                <p class="mb-0">&nbsp; </p>
            </div>
            <div v-else>
                <h4 class="text-primary mb-1">
                    <span v-if="ordine.intestatarioPersonaFisica.SESSO == 1">Mr</span><span v-else>Mrs</span> - 
                    {{ ordine.intestatarioPersonaFisica.COGNOME }} {{ ordine.intestatarioPersonaFisica.NOME }}
                    <span v-show="flgLog == false" class="badge bg-danger bg-opacity-20 text-danger p-1 cursor-pointer float-end" v-on:click="openOrdine()"><i class="fal fa-edit fa-fw text-danger"></i></span>
                </h4>
                <p class="text-dark mb-1">
                    <i class="fal fa-map-marker-check fa-fw me-1"></i> {{ ordine.intestatarioPersonaFisica.comuneResidenza.NOME }} ({{ ordine.intestatarioPersonaFisica.PROV }})<br />
                    <span class="d-sm-none"><i class="fal fa-envelope fa-fw me-1"></i> {{ filters.accorciaTesto(ordine.intestatarioPersonaFisica.EMAIL,30) }}</span>
                    <span class="d-none d-md-block"><i class="fal fa-envelope fa-fw me-1"></i> {{ ordine.intestatarioPersonaFisica.EMAIL }}</span>
                    <i class="fal fa-phone fa-fw me-1"></i> {{ ordine.intestatarioPersonaFisica.TELEFONO }}
                </p>
                <p class="mb-0">&nbsp; </p>
            </div> 
        </div> 
    </div>

    <hr class="mt-2 mb-1" />

    <div class="row">
        <div class="col-12 mt-1 mb-1">
            <div class="row text-dark">
                <div class="col-6 col-md-3 mbx-1">
                    <span class="badge bg-primary d-block" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Operatore booking">{{ getUtente }}</span> 
                </div>
                <div class="col-6 col-md-3 mbx-1">
                    <span class="badge bg-primary d-block" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Tipologia Fiscale">{{ getTipoFiscale }}</span> 
                </div>
                <div class="col-4 col-md-2">
                    <span class="badge bg-primary d-block" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Tipo Pratica"> {{ getTipoPraticaBook }}</span> 
                </div>
                <div class="col-4 col-md-2">
                    <span class="badge bg-primary d-block" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Pratica Commissionabile"> {{ getFlgCommissionabile }} </span> 
                </div>
                <div class="col-4 col-md-2">
                    <span class="badge bg-primary d-block" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="N Pratica Easy"> {{ ordine.FK_ID_PRATICA_EASY != '' ? ordine.FK_ID_PRATICA_EASY : 0 }} </span>
                </div>
            </div> 
        </div>
    </div>

    <hr class="mt-1 mb-2" />

    <TabsOrdine :ref="'tabsOrdine'" @selezionaProdotto="openProdotto($event)" :ordine="ordine" :modalita="flgLog == false ? 1 : 3"></TabsOrdine>
     
</template>



<script>

import TabsOrdine from '@/components/TabsOrdine.vue'	

export default {
    name:"SchedaOrdine",
    components:{
        TabsOrdine
    },
    emits:['selezionaProdotto'],
    props:['livello'],
    data:function(){
        return{
            filters:global.filters,
            quotaSelezionata : {prodotto:{}},
            flgLog:false,
            ordine : {
                quote:new Array(),
                agency: {personaGiuridica:{}},
                intestatarioPersonaFisica:{
                    comuneResidenza:{}
                },
                intestatarioPersonaGiuridica:{},
                distinctPax:new Array()
            },
        }
    },
    methods:{
        openSchedaOrdine : function(ordine){       
            this.ordine =  {
                ID_ORDINE:0,
                quote:new Array(),
                agency: {personaGiuridica:{}},
                intestatarioPersonaFisica:{
                    comuneResidenza:{}
                },
                intestatarioPersonaGiuridica:{},
                distinctPax:new Array()
            };
            utils.ajax("travel/ordine",{ID_ORDINE:ordine.ID_ORDINE}, (response) => {
                this.ordine = response.data;
                utils.openNav();
                setTimeout(() => {
                    //$('[data-bs-toggle="popover"]').popover();
                    //this.$refs.tabsOrdine.startup(this.ordine);
                }, 500);          
                this.$refs.tabsOrdine.startup(this.ordine);
                /*
                this.filters.openPopUp('#popUpSchedaOrdine');
                var modal = global.$('#popUpSchedaOrdine');
                modal.modal('show');*/
            });            
        },
        openOrdine:function(){
            //var openedCanvas = bootstrap.Offcanvas.getInstance(document.getElementById("panel_right"));
            //if (openedCanvas != null){
                //openedCanvas.hide();
            //}
            this.$root.ordineSelezionato = utils.clone(this.ordine);
            setTimeout(() => {
                global.router.push("Ordine");
            }, 500);  
        },
        openProdotto : function(prodotto){
            this.$emit("selezionaProdotto",prodotto);
            //this.$refs.schedaProdotto.openSchedaProdotto(quota.prodotto);
        },
        openSchedaOrdineEvento  :function(log){
            utils.ajax("travel/ordine/log",{ID_LOG:log.ID_LOG}, (response) => {
                this.ordine = response.data.ORDINE;
                setTimeout(() => {
                    this.flgLog = true;
                    this.$refs.tabsOrdine.startup(this.ordine);
                }, 500);       
            });   
        }
    },
    computed:{
        getUtente : function(){
            var utente = utils.array.select(this.$root.arrayUtentiAdmin,"ID_UTENTE",this.ordine.FK_ID_UTENTE_ADMIN,1)[0];
            return utente != undefined ? (utente.USERNAME) : 'NON ASSEGNATO';
        } ,
        getTipoFiscale : function(){
            var tipo = utils.array.select(this.$root.arrayTipoFiscale,"ID_OPZIONE_TIPOLOGICA",this.ordine.FK_ID_TIPO_FISCALE,1)[0];
            return tipo != undefined ? (tipo.CUSTOM_1) : 'NON ASSEGNATO';
        } ,  
        getTipoPraticaBook : function(){
            var tipo = utils.array.select(this.$root.arrayPraticaBook,"ID_OPZIONE_TIPOLOGICA",this.ordine.FK_ID_TIPO,1)[0];
            return tipo != undefined ? (tipo.CUSTOM_1) : 'NON ASSEGNATO';
        } ,  
        getFlgCommissionabile : function(){
            if (this.ordine.FLG_COMMISSIONABILE == 1){
                return "COMM";
            }
            else{
                return "NETTA";
            }
        } ,  
    }

}
</script>

     