<template>	 

    <!-- Page header -->
    <div class="page-header page-header-light">
        <div class="page-header-content header-elements-lg-inline">
            <div class="page-title d-flex">
                <h4 class="d-none d-sm-block">
                    <router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
					<a href="#" class="d-inline-block text-dark"> Destinazioni </a> \
                    <span class="text-muted">Elenco Aree Nilsen</span>
					<code> - Lingua italiano</code>
                </h4>
                <h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Elenco Aree Nilsen </h4>
                <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
            </div>
            <div class="header-elements d-none">
                <div class="d-flex justify-content-center">
                    <a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovoArea()"><i class="icon-googleplus5 text-success"></i><span>NUOVO</span></a>
	                <a href="#" class="btn btn-link btn-float text-body" v-on:click="$root.confTravelTipologiche()"><i class="icon-power2"></i><span>OPZIONI</span></a>
                    <a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
                </div>
            </div>
        </div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg navbar-light border-top">
			<div class="text-center d-lg-none w-100">
				<button type="button" class="navbar-toggler dropdown-toggle" data-bs-toggle="collapse" data-target="#navbar-second">
					<i class="icon-menu7 me-2"></i> Menu Aree Nielsen
				</button>
			</div>
			<div class="navbar-collapse collapse" id="navbar-second">
				<ul class="nav navbar-nav">
					<li class="nav-item">
						<a href="#aree-a" class="navbar-nav-link active" data-bs-toggle="tab">
							<i class="icon-menu7 me-2"></i> Elenco Aree Nielsen
						</a>
					</li>
				</ul>
				
				<button class="ms-lg-auto btn btn-danger btn-sm text-center" v-on:click="getAree()" v-show="ordineHasChanged">Annulla ordinamento</button>
				<button class="ms-lg-auto btn btn-success btn-sm text-center" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</button>

				<ul class="navbar-nav ms-lg-auto">
					<li class="nav-item">
						<div class="btn-group btn-group-sm justify-content-center">
							<a href="#" class="btn btn-light btn-labeled btn-labeled-left dropdown-toggle" data-bs-toggle="dropdown"><i class="icon-cog5 me-1"></i> Azioni Multiple</a>
							<div class="dropdown-menu">
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Stato</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-thumbs-up2"></i> Attiva tutti</a>
										<a href="#" class="dropdown-item"><i class="icon-thumbs-down2"></i> Disattiva tutti</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-cog5"></i> Modifiche varie</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-file-excel"></i> Esporta dati</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item"><i class="icon-trash"></i> Elimina</a>
							</div>
						</div>
					</li>
					<li class="nav-item ms-4">
						<SelectLingue @change="getAree()" v-model="currentLang">
						</SelectLingue>
					</li>
				</ul>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-2">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="aree-a">
		       <div class="card shadow mb-0">
				    <div class="card-body card-body-2">
	                    <div class="alert alert-2 alert-secondary alert2 border-0">
							<form action="#" class="">
		                        <div class="row">           
		                            <div class="col-6 col-md-1 form-group-filter">
		                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Area" v-model="search.ID_AREA.VALUE" :tabindex="2" />
		                            </div>
		                            <div class="col-6 col-md-1 form-group-filter">
		                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Codice Area" v-model="search.DESC_BREVE.VALUE" :tabindex="3" />
		                            </div>            
		                            <div class="col-12 col-md-2 form-group-filter">
		                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Area" v-model="search.NOME.VALUE" :tabindex="4" />
		                            </div>          
		                            <div class="col-12 col-md-2 form-group-filter">
		                          		<Select2 :api="'/core/geo/stati/select2'" :apiselected="'core/basic/element?className=Stato&ID=ID_STATO&DESCRIZIONE=NOME&select2=1'" placeholder="Seleziona Nazione" v-model="search.FK_ID_STATO.VALUE" :tabindex="5"></Select2>
									</div>
									<div class="col-12 col-md-6">
										<div class="row">
											<div class="col-12 col-md-4 form-group-filter">
	
											</div>
											<div class="col-12 col-md-6 form-group-filter">
	
											</div>
											<div class="col-12 col-md-2 form-group-filter">
				                                <select class="form-control form-control-sm" data-placeholder="Stato" v-model="search.FLG_ATTIVO.VALUE" :tabindex="6">
				                                    <option value="">Tutte </option>   
				                                    <option value="1"> &nbsp; Attivate</option>
				                                    <option value="0"> &nbsp; Disattivate</option>
			                                    </select>
			                                </div>
			                         	</div>
			                 		</div>
		                    	</div>
							</form>
		                </div>
		            </div>
				
					<div class="card-body card-body-2 card-body-nobottom card-body-notop"> 
						<div class="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead>
			                        <tr>
			                            <th class="table-head-sm" style="width:2%;"> <input id="chkAllAree" v-on:click="filters.selectAll('chkAllAree',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
			                            <th class="table-head-sm" style="width:6%;"> # </th>
			                            <th class="table-head-sm" style="width:4%;"> Img </th>
			                            <th class="table-head-sm">Nome /<br />Impostazioni / SEO </th>
										<th class="table-head-sm" style="width:15%;">Stato /<br />Gruppo</th>
										<th class="table-head-sm" style="width:5%;">Hits</th>
										<th class="table-head-sm" style="width:5%;">Visibile</th>
										<th class="table-head-sm" style="width:5%;">Stato</th>
			                            <th class="table-head-sm" style="width:5%;">Azioni</th>
			                        </tr>
			                    </thead>
		                        <draggable tag="tbody" @end="ordineChangeHandler($event)">
		                            <tr v-for="area in paginatedListAree.arr" v-bind:key="area.ID_AREA">
		                                <td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="area.SELEZIONATO" class=""> </td>
		                                <td class="text-center"><img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" /></td>
		                                <td class="fs-12">
											{{area.ID_AREA}}<br />
											<span class="badge bg-light badge-xs text-muted float-end cursor-pointer">{{ area.ORDINE}}</span>
										</td>
		                                <td class="text-center">
											<img v-if="area.ID_AREA > 0 && area.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('GEO',area.URL_AVATAR)" class="rounded" width="50" height="35" alt="" />
											<div v-if="area.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto">No<br />Photo</div>
										</td>
		                                <td class="">
		                                    <span v-on:click="modificaArea(area)" class="text-primary text-uppercase fw-bold cursor-pointer">{{area.NOME}}</span><br />
											<span class="badge bg-light badge-xs fs-12 text-dark me-1" style="border: 1px #dddddd solid; padding: 0.18rem 0.3rem !important;"><i class="fal fa-tag fa-fw"></i> </span>
											<span class="badge bg-light badge-xs fs-12 text-dark me-1" style="border: 1px #dddddd solid; padding: 0.18rem 0.3rem !important;"><i class="fal fa-thumbs-down fa-fw"></i> Non Evidenza</span>
		                                </td>
										<td class="fs-12">
											{{ area.COMUNI }}<br />
											&nbsp;
										</td> 
		                                <td class="fs-12 text-center">
											<span class="badge badge-flat badge-pill border-secondary text-secondary">{{area.COUNTER}}</span>
										</td>
										<td class="fs-12 text-center">
											<span v-on:click="updateFlgVisibile(area)" v-show="area.FLG_VISIBILE == 1" class="badge badge-indigo badge-xs text-white cursor-pointer">Pubblico</span>
											<span v-on:click="updateFlgVisibile(area)" v-show="area.FLG_VISIBILE == 0" class="badge badge-pink badge-xs text-white cursor-pointer">Admin</span>
										</td>
		                                <td class="text-center">
											<ISwitch @toggle="updateStatoArea(area)" :size="'sm'" v-model="area.FLG_ATTIVO" style="z-index: 0;"></ISwitch>
										</td>
		                                <td class="text-center">
											<div class="list-icons" :tabindex="26">
		                                        <div class="dropdown">
		                                            <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
		                                            <div class="dropdown-menu dropdown-menu-right">
		                                                <a href="#" class="dropdown-item" v-on:click="modificaArea(area)"><i class="fad fa-pencil fa-fw"></i> Modifica Area</a>
														<div class="dropdown-divider"></div>
														<a href="#" class="dropdown-item" v-on:click="openContenuti(area)"><i class="fad fa-text fa-fw"></i> Contenuti Area</a>
		                        						<div class="dropdown-divider"></div>
														<a href="#" class="dropdown-item" v-on:click="deleteArea(area)"><i class="fad fa-trash-alt fa-fw text-danger"></i> Elimina Area</a>
		                    						</div>
		                                        </div>
		                                    </div>
		                                </td>
		                            </tr>
		                        </draggable>
		                    </table>
		                </div>

		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
		                        <div class="float-end">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListAree.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
		                        </div>
		                    </div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-control wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
		                	</div> 
		            	</div>
			        </div>
			    </div>
			</div>

		</div>
	</div>	


	<!-- Modal -->
    <div class="modal fade" id="popUpArea"  role="dialog" >
        <div class="modal-dialog modal-lg">
            <div class="modal-content" >
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title modal-title-2 w-89 d-none d-sm-block"> SCHEDA {{areaSelezionata.ID_AREA == 0 ? 'NUOVA' : 'MODIFICA'}} AREA </h6>
                    <div class="btn-group float-end" v-show="areaSelezionata.ID_AREA > 0" :tabindex="2">
                        <button type="button" class="btn btn-yellow btn-yellow-100 btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-bolt fa-fw"></i> AZIONI
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-print"></i> Stampa scheda </a>
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-envelope"></i> Invia comunicazione </a> 
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-paper-plane"></i> Invia sms </a> 
                        </div>
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal">X</button>
                </div>
                <div class="modal-body" style="padding: 0 !important;">

					<div class="d-block d-sm-flex">
                        <div class="profile-tab-nav border-right">
                            <div class="p-3">
                                <div class="img-circle text-center cursor-pointer mb-0" v-on:click="uploadImmagineRegione()" :tabindex="3">
									<img v-if="areaSelezionata.ID_AREA > 0 && areaSelezionata.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('GEO',areaSelezionata.URL_AVATAR)" class="rounded shadow" alt="" />
									<div v-if="areaSelezionata.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto" style="border-radius: 1rem; padding: 2rem 4rem; width: 100% !important; font-size: 1.5rem;">No<br />Photo</div>
                				</div>
                            </div>
                            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" style="padding-right: 5px !important; min-width: 13rem;">
                                <a class="nav-link strech-nav-pill active" id="geodata-tab" data-bs-toggle="pill" href="#geodata" role="tab" aria-controls="geodata" aria-selected="true" :tabindex="4">
                                    <i class="fal fa-globe fa-fw"></i> Dati Geografici
                                </a>
                            </div>
                        </div>
                        <div class="tab-content p-md-2" style="padding-right: 0px !important;" id="v-pills-tabContent">

                            <div class="tab-pane fade show active" id="geodata" role="tabpanel" aria-labelledby="geodata-tab">
                                <h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 3rem;"><i class="fad fa-globe fa-fw me-1"></i> Dati Geografici</h3>
                                <div class="box_26"> 
  
									<div class="row">
										<div class="col-12 col-md-6 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Nazione:</label>
											<Select2 v-model="areaSelezionata.FK_ID_STATO" :w="250" :api="'/core/geo/stati/select2'" :apiselected="'core/basic/element?className=Stato&ID=ID_STATO&DESCRIZIONE=NOME&select2=1'" ::tabindex="2">
											</Select2> 
										</div>	
										<div class="col-12 col-md-6 form-group-1"></div>	

										<div class="col-12 col-md-9 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Nome Area:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="areaSelezionata.lang.TITOLO" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-user fa-fw"></i></div>
											</div>
										</div>										
										<div class="col-12 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Codice Area:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="areaSelezionata.DESC_BREVE" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-user fa-fw"></i></div>
											</div>
										</div>

										<div class="col-12 col-md-12 form-group-1"> 
											<ul class="list-group">
												<li class="list-group-item" v-for="comune in areaSelezionata.comuni" v-bind:key="comune.FK_ID_COMUNE">
													{{ comune.TITOLO }}
													<button class="btn btn-danger" v-on:click="removeComuneFromArea(comune.FK_ID_COMUNE)">x</button>
												</li>
											</ul>
										</div>	

										<div class="col-12 col-md-12 form-group-1">
											<label class="text-danger custom-control custom-control-right">Pubblica sul Sito:</label>
											<div class="row">
												<div class="col-lg-1"><span class="btn btn-light btn-sm" v-on:click="areaSelezionata.enti = filters.select2AddAll(areaSelezionata.enti,filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL'))">Tutti</span></div>
												<div class="col-lg-11">
													<Select2 :multiple="true" v-bind:options="arrayEntiSelect2" v-model="areaSelezionata.enti" :tabindex="17"></Select2>  
												</div>
											</div>
										</div>

										<div class="col-5 col-md-3 form-group-1"></div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">In Evidenza.</label>
											<select class="form-control form-control-sm select-icons" v-model="areaSelezionata.FLG_INHOME" :tabindex="3"> 
												<option value="" data-icon="chrome" selected>Seleziona</option>
												<option value="0"> - No</option>
												<option value="1"> - Si</option>
											</select> 
										</div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Visibile:</label>
											<select class="form-control form-control-sm select-icons" v-model="areaSelezionata.FLG_VISIBILE" :tabindex="3"> 
												<option value="-1" data-icon="chrome" selected>Seleziona</option>
												<option value="0"> - Solo Admin</option>
												<option value="1"> - Pubblico</option>
											</select>
										</div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Stato:</label>
											<select class="form-control form-control-sm select-icons" v-model="areaSelezionata.lang.FLG_ATTIVO" :tabindex="3"> 
												<option value="" data-icon="chrome" selected>Seleziona</option>
												<option value="1"> - Attivo</option>
												<option value="2"> - Disattivo</option>
											</select> 
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				
				</div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaArea()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>                
            </div>
        </div>
	</div>


    <div class="modal fade" :id="'popUpUploadImgArea'+rs" role="dialog" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-light text-dark">
                    <h6 class="modal-title">Upload immagine Area</h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">    
                    <Upload :args="argsAree" @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div>
    </div>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import Select2 from '@/components/utils/Select2.vue'
import SelectLingue  from '@/components/utils/SelectLingue.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import Upload from '@/components/utils/Upload.vue'


export default {
    name:'GeoAree',	 

    components:{
        Pagine,
        ISwitch,
        Select2,
		SelectLingue,
        draggable: VueDraggableNext,
        Upload
    },

    data: function () {
        return {
			apiStartup:'travel/geo/aree',
			filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
			rs : utils.generateRandomString(30),
            arrayAree:new Array(), 
            areaSelezionata:{lang:{}},
            arrayFormElementsArea:new Array(),
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	          	ID_AREA:{TYPE:'TEXT',VALUE:''},
                NOME:{TYPE:'TEXT',VALUE:''},
	          	DESC_BREVE:{TYPE:'TEXT',VALUE:''},
                FLG_ATTIVO:{TYPE:'COMBO',VALUE:1},	
                FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},
                FK_ID_PROVINCIA:{TYPE:'COMBO',VALUE:''},
                FK_ID_STATO:{TYPE:'COMBO',VALUE:''},	
            },
			currentTab:1,
			ordineHasChanged: false,
			arrayEntiSelect2:new Array(),
            selectedMenuItem:{},
			currentLang:0,
			contenutoLangSelezionato:{},
            argsAree:{
                modalita:'MOONLIGHT_uploadImgGeo',
				tipologia:5
			},

        }      
    },

    methods: {  
        getAree : function(){
            utils.ajax(this.apiStartup,{LANG:this.currentLang}, (response) => {
				this.ordineHasChanged = false;
                this.arrayAree = response.data;
            });
        },

        nuovoArea : function(){
            this.areaSelezionata = {ID_AREA:0,lang:{}};
			this.filters.openPopUp("popUpArea");
        },

        modificaArea : function(t){
			utils.ajax('travel/geo/area',{ID_AREA:t.ID_AREA,ID_GEO_LANG:t.ID_GEO_LANG}, (response) => {
				if (response.esito == 0){
					this.areaSelezionata = utils.clone(response.data);
					this.argsAree.ID_AREA = t.ID_AREA;
					this.filters.openPopUp("popUpArea");
				}
			});
        },

        salvaArea : function(){
            utils.salvaElemento("travel/geo/area",this.areaSelezionata,"ID_AREA","popUpArea",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getAree();
                }
            });
        },

		updateFlgVisibile : function(area){
			area.FLG_VISIBILE = area.FLG_VISIBILE == 1 ? 0 : 1;
			comune.FK_ID_TIPO = 5;
			utils.ajax("travel/geo/visibilita/update",area, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					area.FLG_VISIBILE = area.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
		},	 

        updateStatoArea : function(area){
            setTimeout(() => {
                utils.ajax("travel/geo/gen/stato/update",area, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }  
                }); 
            },250);
        },
        
        deleteArea : function(t){
            utils.alert.confirm("Sicuro di voler eliminare l`area " + t.NOME + "?",()=>{
                utils.ajax('core/basic/delete',{className:'Area',id:t.ID_AREA}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getAree();
                    }
                });
            });
        },
        
		openContenuti : function(area){
			this.$root.geoLangSelezionato = utils.clone(area);
			global.router.push("GeoContenutoLang");
		},	

        esportazione : function(formato){
            utils.report.token({modalita:'esportaGeoAree',formato:formato});
      	}, 

		ordineChangeHandler : function(event){
			this.arrayAree = this.filters.draggableChangeHandler(event,this.arrayAree,this.search,"ID_AREA");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arrayAree.length ; i++){
					arr.push({"ID": this.arrayAree[i].ID_AREA,"O": this.arrayAree[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'Area',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getAree();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},		

        uploadImmagineStato : function(){
            this.filters.openPopUp("popUpUploadImgArea"+this.rs);
        },

        successCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgArea"+this.rs);
            utils.ajax("travel/geo/area",{ID_AREA:this.areaSelezionata.ID_AREA}, (response) => {
                this.areaSelezionata = response.data;
				this.getAree();
            });
        },

        errorCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgArea"+this.rs);
        },

		removeComuneFromArea : function(idComune){
			utils.ajax('travel/comuni/area/delete',{ID_AREA:this.areaSelezionata.ID_AREA,ID_COMUNE:idComune}, (response) => {
				if (response.esito == 0){
					utils.ajax("travel/geo/area",{ID_AREA:this.areaSelezionata.ID_AREA,ID_GEO_LANG:this.areaSelezionata.ID_GEO_LANG}, (response) => {
						if (response.esito == 0){
							this.areaSelezionata = utils.clone(response.data);
						}
					});
				}
			});
		}

    },

    computed: {
        paginatedListAree(){
            var arr = utils.inGrid(this.arrayAree,this.search);
            return arr;
       }
    },
    
    created:function(){
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.arrayEntiSelect2 = new Array();
        for (var i = 0 ; i < this.$root.arrayEnti.length ; i++){
            this.arrayEntiSelect2.push({id: this.$root.arrayEnti[i].ID_ENTE,text: this.$root.arrayEnti[i].LABEL,})
        }  
		this.currentLang = this.$root.currentLang;
		this.ordineHasChanged = false;
        this.getAree();
    }					   

}
</script>