<template>	 

    <!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
					<a href="#" class="d-none d-lg-inline-block text-dark fw-semibold cursor-pointer" v-on:click="getComuni()"> Destinazioni </a> <span class="d-none d-lg-inline-block">\</span>
                    <span class="text-muted ms-1"><Translate :path="'dizionario.base.ELENCO'"></Translate> Comuni </span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">	   

					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;" v-on:click="nuovoComune()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1 ms-1">
							<div class="fs-sm text-muted mb-1">
								<Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Comune
							</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche()" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(this.modulo)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
                </div>
            </div>
        </div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#comuni-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7"></i>
								<span class="d-lg-none ms-1">Comuni</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Comuni</span>
							</div>
						</a>
					</li>
					<li class="nav-item d-lg-none ms-auto">
						<a href="#profile_nav" class="navbar-nav-link navbar-nav-link-icon rounded-pill rounded-pill-small collapsed" data-bs-toggle="collapse" aria-expanded="false">
							<i class="ph-caret-down collapsible-indicator"></i>
						</a>
					</li>
				</ul>
				
				<a class="rounded btn bg-danger bg-opacity-20 btn-sm-light me-2 mt-xs-1" v-on:click="getComuni()" v-show="ordineHasChanged">Annulla ordinamento</a>
				<a class="rounded btn bg-success bg-opacity-20 btn-sm-light mt-xs-1" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</a>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0 select-small">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Comuni selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti i Comuni</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina Comuni</a>
								</div>
							</div>
						</li>
						<li class="nav-item ms-3">
							<SelectLingue class="form-select form-select-sm" @change="getComuni()" v-model="currentLang" :tabindex="4">
							</SelectLingue>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="comuni-a">
        		<div class="card shadow mb-xs-3 mb-lg-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
		                <div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="comune-a">
			                        <div class="row">           
			                            <div class="col-6 col-md-1 col-lg-1 mbx-1">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Comune" v-model="search.ID_COMUNE.VALUE" :tabindex="2" />
			                            </div>
			                            <div class="col-6 col-md-1 col-lg-1 mbx-1">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Cap Comune" v-model="search.CAP.VALUE" :tabindex="3" />
				                        </div>  
			                            <div class="col-12 col-md-2 col-lg-2 mbx-1">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Comune" v-model="search.NOME.VALUE" :tabindex="4">
			                            </div>  
			                            <div class="col-12 col-md-2 col-lg-2 mbx-1">
											<Select2 :api="'core/geo/stati/select2'" :placeholder="'Seleziona Nazione'" v-model="search.FK_ID_STATO.VALUE" :tabindex="5"></Select2>
			                            </div>  
										<div class="col-12 col-md-2 col-lg-2 mbx-1">
											<Select2 :api="'core/geo/regioni/select2'" :placeholder="'Seleziona Regione'" v-model="search.FK_ID_REGIONE.VALUE" :tabindex="6"></Select2>
			                            </div>  
			                            <div class="col-12 col-md-2 col-lg-2 mbx-1">
			                          		<Select2 :api="'core/geo/province/select2'" :placeholder="'Seleziona Provincia'" v-model="search.FK_ID_PROVINCIA.VALUE" :tabindex="7"></Select2>
			                            </div>
										<div class="col-12 col-md-2 col-lg-2">
											<div class="row">
												<div class="col-12 col-md-7 col-lg-7 mbx-1">
					                                <select class="form-select form-select-sm" data-placeholder="Stato" v-model="search.FLG_ATTIVO.VALUE" :tabindex="8">
					                                    <option value="">Tutti </option>   
					                                    <option value="1"> &nbsp; Attivati</option>
					                                   	<option value="0"> &nbsp; Disattivati</option>
				                                    </select>
				                                </div>
												<div class="col-6 col-md-5 col-lg-5">
					                                <a href="#collapse-a" class="btn btn-flat-info btn-sm w-100 collapsed" data-bs-toggle="collapse" v-on:click="filters.resizeGrid('collapse-a','tableFixHead','box-card')" role="button" aria-expanded="false">
					                                    + FILTRI
													</a> 
												</div>
											</div>  
										</div>
								 	</div>
								</form>
							</div>
		                </div>
		            </div>
				
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="div_table_resp tableFixHead" id="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable text-nowrap"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
			                            <th class="table-head-sm" style="width:2%;"> <input id="chkAllComuni" v-on:click="filters.selectAll('chkAllComuni',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
			                            <th class="table-head-sm" style="width:6%;"> #<br /><span class="float-end">Ord. </span></th>
			                            <th class="table-head-sm text-center" style="width:4%;"> Img </th>
			                            <th class="table-head-sm">Nome / SEO<br />Impostazioni </th>
										<th class="table-head-sm" style="width:15%;">Nazione /<br />Regione</th>
										<th class="table-head-sm text-center" style="width:5%;">CAP</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Hits</th>
										<th class="table-head-sm text-center" style="width:5%;">Visibile</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Stato</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Azioni</th>
									</tr>
								</thead>
								<draggable tag="tbody" handle=".handle" @end="ordineChangeHandler($event)">
									<tr v-for="comune in paginatedListComuni.arr" v-bind:key="comune.ID_COMUNE">
										<td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="comune.SELEZIONATO" class=""> 
										</td>
		                                <td class="td-class text-center cursor-pointer handle">
											<img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" />
										</td>
										<td class="td-class fs-sm">
											<span>{{comune.ID_COMUNE}}</span><br />
											<small class="badge bg-light text-primary float-end">{{ comune.ORDINE}}</small>
										</td>
										<td class="td-class text-center"> 
											<img v-if="comune.ID_COMUNE > 0 && comune.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('GEO',comune.URL_AVATAR)" class="squared" style="height: 40px;" alt="" />
											<span v-if="comune.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto squared fs-sm" style="height: 40px !important; line-height: 10px; padding-top: 8px; font-size: 0.6rem !important;">No<br />Photo</span>
										</td>
										<td class="td-class fs-sm">
											<span class="text-primary fw-bold text-uppercase cursor-pointer" v-on:click="modificaComune(comune)">{{comune.TITOLO}}</span><br />
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;"><i class="fal fa-tag fa-fw"></i> {{comune.FK_ID_ENTE}}</span>
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;"><i class="fal fa-thumbs-up fa-fw"></i> Metti in Evidenza</span>
											<span class="float-end">
												<a class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;"> Lat: {{comune.LATITUDINE}}</a>
												<a class="badge bg-light badge-xs text-dark rounded-0" style="border: 1px #dddddd solid;"> Lng: {{comune.LONGITUDINE}}</a>
											</span>
										</td>
										<td class="td-class fs-sm">
											<div><i class="ph-flag fs-base lh-base align-top text-success me-1"></i> <a href="#">{{comune.STATO}}</a></div>
											<div><i class="ph-tag fs-base lh-base align-top text-danger me-1"></i> <a href="#">{{comune.REGIONE}} - {{comune.PROVINCIA}}</a></div>
										</td>
										<td class="td-class fs-sm text-center">
											<span class="badge bg-primary badge-xs rounded-pill">{{comune.CAP}}</span><br>
										</td>
										<td class="td-class fs-sm text-center">
											<span class="text-secondary">{{comune.COUNTER}}</span>
										</td>
										<td class="td-class fs-sm text-center">
											<span v-on:click="updateFlgVisibile(comune)" v-show="comune.FLG_VISIBILE == 1" class="badge bg-indigo badge-xs text-white cursor-pointer">Pubblico</span>
											<span v-on:click="updateFlgVisibile(comune)" v-show="comune.FLG_VISIBILE == 0" class="badge bg-pink badge-xs text-white cursor-pointer">Admin</span>											
										</td>
										<td class="td-class text-center">
											<ISwitch @toggle="updateStatoComune(comune)" v-model="comune.FLG_ATTIVO" :size="'sm'" style="z-index: 0;"></ISwitch>
										</td>
										<td class="td-class text-center fs-sm">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end"> 
													<a href="#" class="dropdown-item" v-on:click="modificaComune(comune)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Comune</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="openContenuti(comune)"><i class="fad fa-text fa-fw me-2"></i><span class="text-muted me-1">|</span>Contenuti Comune</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteComune(comune)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Comune</a>
												</div>
											</div>
										</td>
									</tr>
							   	</draggable>
							</table>
						</div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">COMUNI: {{ paginatedListComuni.count }}</span> 
                                    <span v-show="livello == 5" class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">FATTURATO: </span>
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end pag-mobile">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListComuni.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
			                    </div>
			                </div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
			            	</div>
			        	</div>
					</div>
				</div>
			</div>

		</div>
	</div>	

   
	<!-- START MODALI -->
    <div class="modal fade" id="popUpComune" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" >
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title modal-title-2 w-89 d-none d-sm-block"> SCHEDA {{comuneSelezionato.ID_COMUNE == 0 ? 'NUOVO' : 'MODIFICA'}} COMUNE </h6>
                    <div class="btn-group float-end" v-show="comuneSelezionato.ID_COMUNE > 0" :tabindex="2">
                        <button type="button" class="btn btn-yellow btn-yellow-100 btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-bolt fa-fw"></i> AZIONI
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-print"></i> Stampa scheda </a>
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-envelope"></i> Invia comunicazione </a> 
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-paper-plane"></i> Invia sms </a> 
                        </div>
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal">X</button>
                </div>
                <div class="modal-body" style="padding: 0 !important;">

					<div class="d-block d-sm-flex">
                        <div class="profile-tab-nav border-right">
                            <div class="p-3">
                                <div class="img-circle text-center cursor-pointer mb-0" v-on:click="uploadImmagineGeo()" :tabindex="3">
                                    <img v-if="comuneSelezionato.ID_COMUNE > 0 && comuneSelezionato.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('GEO',comuneSelezionato.URL_AVATAR)" class="rounded shadow" alt="" />
									<div v-if="comuneSelezionato.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto" style="border-radius: 1rem; padding: 2rem 4rem; width: 100% !important; font-size: 1.5rem;">No<br />Photo</div>
                                </div>
                            </div>
                            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" style="padding-right: 5px !important; min-width: 13rem;">
                                <a class="nav-link strech-nav-pill active" id="geodata-tab" data-bs-toggle="pill" href="#geodata" role="tab" aria-controls="geodata" aria-selected="true" :tabindex="4">
                                    <i class="fal fa-globe fa-fw"></i> Dati Geografici
                                </a>
								<a class="nav-link strech-nav-pill" id="geo-map-tab" data-bs-toggle="pill" href="#geo-map" role="tab" aria-controls="geo-map" aria-selected="true" :tabindex="4">
                                    <i class="fal fa-map fa-fw"></i> Geo Localizzazione
                                </a>
                            </div>
                        </div>
                        <div class="tab-content p-md-2" style="padding-right: 0px !important;" id="v-pills-tabContent">

                            <div class="tab-pane fade show active" id="geodata" role="tabpanel" aria-labelledby="geodata-tab">
                                <h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 3rem;"><i class="fad fa-globe fa-fw me-1"></i> Dati Geografici</h3>
                                <div class="box_26"> 

									<div class="row">
										<div class="col-12 col-md-4 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Nazione:</label>
											<Select2 :api="'/core/geo/stati/select2'" :disabled="true" :apiselected="'core/basic/element?className=Stato&ID=ID_STATO&DESCRIZIONE=NOME&select2=1'" v-model="comuneSelezionato.FK_ID_STATO" :tabindex="3"></Select2>
										</div>			
										<div class="col-12 col-md-4 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Regione:</label>
											<Select2 :api="'/core/geo/regioni/select2'" @change="getNazione()" :apiselected="'core/basic/element?className=Regione&ID=ID_REGIONE&DESCRIZIONE=NOME&select2=1'" v-model="comuneSelezionato.FK_ID_REGIONE" :tabindex="4"></Select2>
										</div>	
										<div class="col-12 col-md-4 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Provincia:</label>
											<Select2 :api="'/core/geo/province/select2'" :apiselected="'core/basic/element?className=Provincia&ID=ID_PROVINCIA&DESCRIZIONE=NOME&select2=1'" v-model="comuneSelezionato.FK_ID_PROVINCIA" :tabindex="5"></Select2>
										</div>	

										<div class="col-12 col-md-6 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Nome Comune:</label>
											<input autocomplete="off" type="text" class="form-control form-control-sm" id="autocomplete"  v-model="comuneSelezionato.NOME" :tabindex="10">									
										</div> 
										
										<div class="col-12 col-md-6 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Slogan Comune:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="comuneSelezionato.lang.TITOLO" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-tag fa-fw"></i></div>
											</div>
										</div>
										<div class="col-5 col-md-2 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Cap:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="comuneSelezionato.CAP" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-code fa-fw"></i></div>
											</div>
										</div>
										<div class="col-5 col-md-2 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Prefisso Tel.:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="comuneSelezionato.PREFISSO" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-phone fa-fw"></i></div>
											</div>
										</div>
										<div class="col-12 col-md-2 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Meteo:</label>
											<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="comuneSelezionato.COD_METEO" :tabindex="4">
										</div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Codice Istat:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="comuneSelezionato.CODICE_ISTAT" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-stamp fa-fw"></i></div>
											</div>
										</div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Codice Fisco:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="comuneSelezionato.COD_FISCO" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-balance-scale-right fa-fw"></i></div>
											</div>
										</div>

										<div class="col-12 col-md-12 form-group-1">
											<label class="text-danger custom-control custom-control-right">Categorie associate:</label>
											<Select2 :multiple="true" v-bind:options="arrayCategorie" v-model="comuneSelezionato.categorie" :tabindex="17"></Select2>
										</div>

										<div class="col-12 col-md-12 form-group-1">
											<label class="text-danger custom-control custom-control-right">Pubblica sul Sito:</label>
											<div class="row">
												<div class="col-lg-1"><span class="btn btn-light btn-sm" v-on:click="comuneSelezionato.enti = filters.select2AddAll(comuneSelezionato.enti,filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL'))">Tutti</span></div>
												<div class="col-lg-11">
													<Select2 :multiple="true" v-bind:options="arrayEntiSelect2" v-model="comuneSelezionato.enti" :tabindex="17"></Select2>  
												</div>
											</div>
										</div>

										<div class="col-5 col-md-3 form-group-1"></div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">In Evidenza.</label>
											<select class="form-control form-control-sm select-icons" v-model="comuneSelezionato.FLG_INHOME" :tabindex="3"> 
												<option value="" data-icon="chrome" selected>Seleziona</option>
												<option value="0"> - No</option>
												<option value="1"> - Si</option>
											</select> 
										</div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Visibile:</label>
											<select class="form-control form-control-sm select-icons" v-model="comuneSelezionato.FLG_VISIBILE" :tabindex="3"> 
												<option value="-1" data-icon="chrome" selected>Seleziona</option>
												<option value="0"> - Solo Admin</option>
												<option value="1"> - Pubblico</option>
											</select>
										</div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Stato:</label>
											<select class="form-control form-control-sm select-icons" v-model="comuneSelezionato.lang.FLG_ATTIVO" :tabindex="3"> 
												<option value="" data-icon="chrome" selected>Seleziona</option>
												<option value="1"> - Attivo</option>
												<option value="2"> - Disattivo</option>
											</select> 
										</div>
									</div>
			                    </div>
							</div>
									   
                            <div class="tab-pane fade" id="geo-map" role="tabpanel" aria-labelledby="geo-map-tab">
                                <h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 3rem;"><i class="fad fa-map fa-fw me-1"></i> Geo Localizzazione</h3>
                                <div class="box_26">

                                    <div class="card card-nobottom">
                                        <div class="card-body card-body-2 bg-light">
                                            <h5 class="card-title">Geolocalizzazione Automatica tramite Google Maps
                                                <code class="d-block font-size-base">(permette di individuare la struttura tramite Google)</code>
                                            </h5>
                                            <div class="row">
												<div class="col-md-6 form-group-1">
													<label class="text-muted custom-control custom-control-right">Inserire nome della Nazione.</label>
													<AddressField @selezionato="indirizzoSelezionato($event)" v-model="comuneSelezionato.NOME" :placeholder="comuneSelezionato.NOME"></AddressField>
												</div>
												<div class="col-6 col-md-3 form-group-1">
													<label class="text-muted custom-control custom-control-right">Latitudine</label>
													<input type="text" class="form-control form-control-sm" v-model="comuneSelezionato.LATITUDINE">
												</div>
												<div class="col-6 col-md-3 form-group-1">
													<label class="text-muted custom-control custom-control-right">Longitudine </label>
													<input type="text" class="form-control form-control-sm" v-model="comuneSelezionato.LONGITUDINE">
												</div>
                                            </div>
                                        </div>
                                    </div>                                    
                                
                                </div>
                            </div>

						</div>
					</div>

                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaComune()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>                
            </div>
        </div>
	</div>	 


    <div class="modal fade" :id="'popUpUploadImgComune'+rs" role="dialog" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-light text-dark">
                    <h6 class="modal-title">Upload immagine Comune</h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">    
                    <Upload :args="argsComune" @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div>
    </div> 


    <div class="modal fade" id="popUpSelezionaArea"  role="dialog"  >
        <div class="modal-dialog modal-lg">
            <div class="modal-content" >
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title modal-title-2 w-89 d-none d-sm-block"> Seleziona Area </h6>
                    
                    <button type="button" class="close" data-bs-dismiss="modal">X</button>
                </div>
                <div class="modal-body" style="padding: 0 !important;">		
					<select class="form-control" v-model="areaCustomSelezionata">
						<option v-for="area in arrayAreeCustom" v-bind:key="area.ID_AREA" v-bind:value="area.ID_AREA">{{ area.NOME }}</option>
					</select>
                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="doAddToAreaNielsen()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>                
            </div>
        </div>
	</div>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import Select2 from '@/components/utils/Select2.vue'
import SelectLingue  from '@/components/utils/SelectLingue.vue'
import { VueDraggableNext } from 'vue-draggable-next'  
import Upload from '@/components/utils/Upload.vue'
import AddressField from '@/components/utils/AddressField.vue'	
//import GoogleMaps from '@/components/utils/GoogleMaps.vue'	

export default {
    name:'GeoComuni',

    components:{
        Pagine,
        ISwitch,
        Select2,
		SelectLingue,
        draggable: VueDraggableNext,
        Upload,
        AddressField,
        //GoogleMaps
    },

    data: function () {
        return {
			apiStartup:'travel/geo/comuni',
			filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
			rs : utils.generateRandomString(30),
			arrayStati : new Array(),
            arrayComuni:new Array(),
            comuneSelezionato:{lang:{}},
            arrayFormElementsComune:new Array(),
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	          	ID_COMUNE:{TYPE:'TEXT',VALUE:''},
                NOME:{TYPE:'TEXT',VALUE:''},
				CAP:{TYPE:'TEXT',VALUE:''},
                FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'},	
               	FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},
                FK_ID_PROVINCIA:{TYPE:'COMBO',VALUE:''},
				FK_ID_STATO:{TYPE:'COMBO',VALUE:''},
            },
			currentTab:1,
			ordineHasChanged: false,
			arrayEntiSelect2:new Array(),
            selectedMenuItem:{},
			currentLang:0,
			contenutoLangSelezionato:{},
			arrayCategorie:new Array(),
			arrayAreeCustom:new Array(),
			areaCustomSelezionata:0,
            argsComune:{
                modalita:'MOONLIGHT_uploadImgGeo',
				tipologia:4
			},
			selectedCountries: [],
			countries: [],
			isLoading: false

        }      
    },

    methods: {  
        getComuni : function(){
            utils.ajax(this.apiStartup,{LANG:this.currentLang}, (response) => {
				this.ordineHasChanged = false;
                this.arrayComuni = response.data;
            });
        },

        nuovoComune : function(){
            this.comuneSelezionato = {ID_COMUNE:0,lang:{}};
			this.filters.openPopUp("popUpComune");
        },

        modificaComune : function(t){
            utils.ajax('travel/geo/comune',{ID_COMUNE:t.ID_COMUNE,ID_GEO_LANG:t.ID_GEO_LANG}, (response) => {				
				if (response.esito == 0){
					this.comuneSelezionato = utils.clone(response.data);
					this.argsComune.ID_COMUNE = t.ID_COMUNE;
					this.filters.openPopUp("popUpComune");				
				}
			});
        },

        salvaComune : function(){
            utils.salvaElemento("travel/geo/comune/",this.comuneSelezionato,"ID_COMUNE","popUpComune",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getComuni();
                }
            });
        },

		updateFlgVisibile : function(comune){
			comune.FLG_VISIBILE = comune.FLG_VISIBILE == 1 ? 0 : 1;
			comune.FK_ID_TIPO = 4;
			utils.ajax("travel/geo/gen/visibilita/update",comune, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					comune.FLG_VISIBILE = comune.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
		},	 

        updateStatoComune : function(comune){
            setTimeout(() => {
                utils.ajax("travel/geo/gen/stato/update",comune, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }  
                }); 
            },250);
        },
        
        deleteComune : function(t){
            utils.alert.confirm("Sicuro di voler eliminare il comune " + t.NOME + "?",()=>{
                utils.ajax('core/basic/delete',{className:'Comune',id:t.ID_COMUNE}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getComuni();
                    }
                });
            });
        },
        
		openContenuti : function(comune){
			this.$root.geoLangSelezionato = utils.clone(comune);
			global.router.push("GeoContenutoLang");
		},	

        esportazione : function(formato){
            utils.report.token({modalita:'esportaGeoComuni',formato:formato});
        }, 

		ordineChangeHandler : function(event){
			this.arrayComuni = this.filters.draggableChangeHandler(event,this.arrayComuni,this.search,"ID_COMUNE");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arrayComuni.length ; i++){
					arr.push({"ID": this.arrayComuni[i].ID_COMUNE,"O": this.arrayComuni[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'Comune',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getComuni();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},

        uploadImmagineStato : function(){
            this.filters.openPopUp("popUpUploadImgComune"+this.rs);
        },

        successCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgComune"+this.rs);
            utils.ajax("travel/geo/comune",{ID_COMUNE:this.comuneSelezionato.ID_COMUNE}, (response) => {
                this.comuneSelezionato = response.data;
				this.getComuni();
            });
        },

        errorCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgComune"+this.rs);
        },

        indirizzoSelezionato : function(place){
            utils.google.importaPlace(place,this.comuneSelezionato);
            this.$refs.mappa.startup(this.comuneSelezionato.LATITUDINE,this.comuneSelezionato.LONGITUDINE);        
        },

		addToArea : function(){
			this.filters.openPopUp("popUpSelezionaArea");
		}, 

		doAddToAreaNielsen : function(){
			var arrayComuni = utils.array.select(this.arrayComuni,"SELEZIONATO",1);
			console.log(arrayComuni);
			if (arrayComuni.length > 0){
				utils.ajax('travel/comuni/area/insert',{ID_AREA:this.areaCustomSelezionata,comuni:utils.array.toId(arrayComuni,"ID_COMUNE")}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getComuni();
						this.ordineHasChanged = false;
					}
				});				
			}
			else{
				utils.alert.warning("Selezionare almeno un comune");
			}
		},
		getLabel : function(opzione){
			if (opzione.id > 0){
				var a = utils.array.select(this.countries,"id",opzione.id,1)[0];
				if (a != undefined){
					return a.text;
				}
				return "||";
			}
		}

    },
	watch:{
		"comuneSelezionato.FK_ID_REGIONE":function(){
			if (this.comuneSelezionato.FK_ID_STATO == undefined || this.comuneSelezionato.FK_ID_STATO == 0){
				utils.ajax('travel/geo/regione',{ID_REGIONE:this.comuneSelezionato.FK_ID_REGIONE}, (response) => {
					if (response.esito == 0){
						this.comuneSelezionato.FK_ID_STATO = response.data.FK_ID_STATO;
					}
				},false);
			}
		}
	},

    computed: {
        paginatedListComuni(){
            var arr = utils.inGrid(this.arrayComuni,this.search);
            return arr;
       }
    },
    
    created:function(){
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.arrayEntiSelect2 = new Array();
        for (var i = 0 ; i < this.$root.arrayEnti.length ; i++){
            this.arrayEntiSelect2.push({id: this.$root.arrayEnti[i].ID_ENTE,text: this.$root.arrayEnti[i].LABEL,})
        }
		this.currentLang = this.$root.currentLang;
		this.ordineHasChanged = false;
		this.arrayStati = utils.clone(utils.arrayStati);
		//this.arrayCategorie = utils.clone(utils.arrayCategorie);
		utils.ajax("travel/geo/aree",{LANG:this.currentLang}, (response) => {
			this.arrayAreeCustom = response.data;
			this.getComuni();
		});
    }

}
</script>


<style scoped>

	/* XS */
	@media (min-width: 362px) and (max-width: 575px) {
		.rounded-pill-small { padding: 0.17rem; top: 3px; background-color: #F3F4F6; }
		.select-small { width: 100%; flex-direction: row !important; flex: 1 1 auto !important; }

		.div_table_resp {  overflow: scroll; }
		.div_table_resp table { border-spacing: 0; }
		.div_table_resp th:nth-child(1) { left: 0; width: 10px; min-width: 10px; }
		.div_table_resp td:nth-child(1) { background: #fefefe; z-index: 1; }
		.div_table_resp th:nth-child(1) { z-index: 300;}
		.div_table_resp th:nth-child(1), .checkbox { position: relative; z-index: 1; }
	
	
	}
</style>