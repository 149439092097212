<template>
    <div>

        <div v-if="modalita == 1" class="card mb-0">
            <div class="card-header card-header-2 bg-light header-elements-inline py-0">
                <h3 class="float-start mb-2 mt-2">Ricerca</h3>
                <div class="header-elements">
                    <div class="list-icons">
                        <a class="list-icons-item" data-action="collapse"></a>
                    </div>
                </div>
            </div>     
            <div class="collapse show">       
                <div class="card-body card-body-2 mb-2">
                    <select class="form-select form-select-sm mb-1" placeholder="Prodotto"  v-model="dati.FK_ID_MODULO" @change="changeModulo()">
                        <option value="0">Tutto</option>
                        <option value="1">Attività</option>
                        <option value="2">Hotel</option>
                        <option value="3">Pacchetti</option>
                        <option value="4">Voli</option>
                    </select>
                    <div class="mb-1" v-show="dati.FK_ID_MODULO == 0">
                        <Select2 :w="250" :api="'travel/s2/prodotti?FK_ID_MODULO='+dati.FK_ID_MODULO" placeholder="Destinazione" :changeHandler="destinazioneChangeHandler" v-model="dati.FK_ID_PRODOTTO"></Select2>
                    </div>
                    <div class="mb-1" v-show="dati.FK_ID_MODULO != 4 && dati.FK_ID_MODULO">                                                                    
                        <SelectDestinazione :w="250" :api="'travel/s2/prodotti?FK_ID_MODULO='+dati.FK_ID_MODULO" :ref="'destinazioneAll'" placeholder="Destinazione" @change="destinazioneChangeHandler()" :changeHandler="destinazioneChangeHandler" v-model="dati.FK_ID_PRODOTTO"></SelectDestinazione>
                    </div>
                    <div class="mb-1" v-show="dati.FK_ID_MODULO == 4">
                        <select class="form-control form-control-sm" placeholder="Prodotto" v-model="dati.FLG_SOLA_ANDATA">
                            <option value="0">ANDATA E RITORNO</option>
                            <option value="1">SOLA ANDATA</option>
                        </select>
                    </div>
                    <div class="mb-1" v-show="dati.FK_ID_MODULO == 4">
                        <Select2 :w="250" :api="'travel/s2/aeroporti'"  placeholder="Partenza" v-model="dati.FK_ID_AEROPORTO_FROM" ></Select2>
                    </div>
                    <div class="mb-1" v-show="dati.FK_ID_MODULO == 4">
                        <Select2 :w="250" :api="'travel/s2/aeroporti'" placeholder="Destinazione" v-model="dati.FK_ID_AEROPORTO_TO" @change="destinazioneChangeHandler()" :changeHandler="destinazioneChangeHandler"></Select2>
                    </div>

                    <!-- DATE RANGE DISPONIBILE SOLO PER VOLI E HOTEL-->
                    <div class="mb-1" v-if="dati.FK_ID_MODULO == 2 || (dati.FK_ID_MODULO == 4 && dati.FLG_SOLA_ANDATA == 0)">
                        <Datepicker v-model="dati.DATE_RANGE" :allowed-dates="$root.allowedDates" range multi-calendars multi-calendars-solo  :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" :partial-range="false" position="left" />
                    </div>
                    <!-- DATE PICKER DISPONIBILE SOLO PER ATTIVITA' E PACCHETTI-->
                    <div class="mb-1" v-else>
                        <Datepicker :allowed-dates="allowedDates" v-model="dati.DATA_START" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']"></Datepicker>
                    </div>  
                    <div class="mb-1">
                        <div class="input-group">
                            <span class="input-group-text"><i class="far fa-users"></i></span>
                            <input type="text" v-on:click="openPopUpPax()" class="form-control form-control-sm" placeholder="Partecipanti" v-bind:value="paxText"  style="cursor:pointer;" readonly="readonly" />
                        </div>
                    </div>
                    <div class="mb-2">
                        <a href="#" class="btn btn-secondary d-block btn-sm btn-lg" v-on:click="ricerca()" :tabindex="6">
                            <span style="font-weight: 500;"><i class="fal fa-search"></i> CERCA</span>
                        </a>
                    </div>  
                </div>
            </div>
        </div>

        <div v-if="modalita == 2" class="">	  
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group-1">
                        <select class="form-control form-control-sm" placeholder="Prodotto"  v-model="dati.FK_ID_MODULO" @change="changeModulo()">
                            <option value="0">Tutto il prodotto</option>
                            <option value="1"> - Solo Attività</option>
                            <option value="2"> - Solo Hotel</option>
                            <option value="3"> - Solo Pacchetti</option>
                            <option value="4"> - Solo Voli</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4" v-show="dati.FK_ID_MODULO != 4">
                    <div class="form-group-1" v-show="dati.FK_ID_MODULO == 0">
                        <Select2 :w="250" :api="'travel/s2/prodotti?FK_ID_MODULO='+dati.FK_ID_MODULO" placeholder="Destinazione" :changeHandler="destinazioneChangeHandler" v-model="dati.FK_ID_PRODOTTO"></Select2>
                    </div>
                    <div class="form-group-1" v-show="dati.FK_ID_MODULO != 4 && dati.FK_ID_MODULO">                                                              
                        <SelectDestinazione :w="250" :api="'travel/s2/prodotti?FK_ID_MODULO='+dati.FK_ID_MODULO" placeholder="Destinazione" @change="destinazioneChangeHandler()" :changeHandler="destinazioneChangeHandler" v-model="dati.FK_ID_PRODOTTO"></SelectDestinazione>
                    </div>
                </div>
                <div class="col-md-4" v-show="dati.FK_ID_MODULO == 4">
                    <div class="form-group-1">
                        <select class="form-control form-control-sm" placeholder="Prodotto" v-model="dati.FLG_SOLA_ANDATA">
                            <option value="0">ANDATA E RITORNO</option>
                            <option value="1">SOLA ANDATA</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-5">
                    <!-- DATE RANGE DISPONIBILE SOLO PER VOLI E HOTEL-->
                    <div class="form-group-1" v-if="dati.FK_ID_MODULO == 2 || (dati.FK_ID_MODULO == 4 && dati.FLG_SOLA_ANDATA == 0)">
                        <Datepicker v-model="dati.DATE_RANGE" :allowed-dates="allowedDates" range multi-calendars multi-calendars-solo  :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" :partial-range="false" position="left" />
                    </div>
                    <!-- DATE PICKER DISPONIBILE SOLO PER ATTIVITA' E PACCHETTI-->
                    <div class="form-group-1" v-else>
                        <Datepicker :allowed-dates="allowedDates" v-model="dati.DATA_START" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']"></Datepicker>
                    </div> 
                </div>
                <div class="col-md-12">
                    <div class="row" v-show="dati.FK_ID_MODULO == 4">
                        <div class="col-md-6">
                            <div class="form-group-1">
                                <Select2 :w="250" :api="'travel/s2/aeroporti'"  placeholder="Partenza" v-model="dati.FK_ID_AEROPORTO_FROM" ></Select2>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group-1">
                                <Select2 :w="250" :api="'travel/s2/aeroporti'" placeholder="Destinazione" v-model="dati.FK_ID_AEROPORTO_TO" @change="destinazioneChangeHandler()" :changeHandler="destinazioneChangeHandler"></Select2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group-1">
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text"><i class="far fa-users"></i></span>
                            </span>
                            <input type="text" v-on:click="openPopUpPax()" class="form-control form-control-sm" placeholder="Partecipanti" v-bind:value="paxText"  style="cursor:pointer;" readonly="readonly" />
                        </div>
                    </div>
                </div>    
                <div class="col-md-4">
                    <div class="form-group-1">
                        <a href="#" class="btn btn-secondary btn-block btn-sm btn-lg" v-on:click="ricerca()" :tabindex="6">
                            <span style="font-weight: 500;"><i class="fal fa-search"></i> CERCA</span>
                        </a>
                    </div> 
                </div>
            </div>
        </div>

        <div v-if="modalita == 3" class="">	       
            <div class="row">
                <div class="col-md-1">
                    <div class="form-group-1">
                        <select class="form-control form-control-sm" placeholder="Prodotto"  v-model="dati.FK_ID_MODULO" @change="changeModulo()">
                            <option value="0">Tutto il prodotto</option>
                            <option value="1"> - Solo Attività</option>
                            <option value="2"> - Solo Hotel</option>
                            <option value="3"> - Solo Pacchetti</option>
                            <option value="4"> - Solo Voli</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2" v-show="dati.FK_ID_MODULO == 4">
                    <div class="form-group-1">
                        <select class="form-control form-control-sm" placeholder="Prodotto" v-model="dati.FLG_SOLA_ANDATA">
                            <option value="0">ANDATA E RITORNO</option>
                            <option value="1">SOLA ANDATA</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2" v-show="dati.FK_ID_MODULO != 4">
                    <div class="form-group-1" v-show="dati.FK_ID_MODULO == 0">
                        <Select2 :w="250" :api="'travel/s2/prodotti?FK_ID_MODULO='+dati.FK_ID_MODULO" placeholder="Destinazione" :changeHandler="destinazioneChangeHandler" v-model="dati.FK_ID_PRODOTTO"></Select2>
                    </div>
                    <div class="form-group-1" v-show="dati.FK_ID_MODULO != 4 && dati.FK_ID_MODULO">                                                              
                        <SelectDestinazione :w="250" :api="'travel/s2/prodotti?FK_ID_MODULO='+dati.FK_ID_MODULO" placeholder="Destinazione" @change="destinazioneChangeHandler()" :changeHandler="destinazioneChangeHandler" v-model="dati.FK_ID_PRODOTTO"></SelectDestinazione>
                    </div>
                </div>
                <div class="col-md-2">
                    <!-- DATE RANGE DISPONIBILE SOLO PER VOLI E HOTEL-->
                    <div class="form-group-1" v-if="dati.FK_ID_MODULO == 2 || (dati.FK_ID_MODULO == 4 && dati.FLG_SOLA_ANDATA == 0)">
                        <Datepicker v-model="dati.DATE_RANGE" :allowed-dates="allowedDates" range multi-calendars multi-calendars-solo  :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" :partial-range="false" position="left" />
                    </div>
                    <!-- DATE PICKER DISPONIBILE SOLO PER ATTIVITA' E PACCHETTI-->
                    <div class="form-group-1" v-else>
                        <Datepicker :allowed-dates="allowedDates" v-model="dati.DATA_START" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']"></Datepicker>
                    </div> 
                </div>
                <div class="col-md-2" v-show="dati.FK_ID_MODULO == 4">
                    <div class="form-group-1">
                        <Select2 :w="250" :api="'travel/s2/aeroporti'"  placeholder="Partenza" v-model="dati.FK_ID_AEROPORTO_FROM" ></Select2>
                    </div>
                </div>
                <div class="col-md-2" v-show="dati.FK_ID_MODULO == 4">
                    <div class="form-group-1">
                        <Select2 :w="250" :api="'travel/s2/aeroporti'" placeholder="Destinazione" v-model="dati.FK_ID_AEROPORTO_TO" @change="destinazioneChangeHandler()" :changeHandler="destinazioneChangeHandler"></Select2>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group-1">
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text"><i class="far fa-users"></i></span>
                            </span>
                            <input type="text" v-on:click="openPopUpPax()" class="form-control form-control-sm" placeholder="Partecipanti" v-bind:value="paxText"  style="cursor:pointer;" readonly="readonly" />
                        </div>
                    </div>
                </div>    
                <div class="col-md-1">
                    <div class="form-group-1">
                        <a href="#" class="btn btn-secondary btn-block btn-sm btn-lg" v-on:click="ricerca()" :tabindex="6">
                            <span style="font-weight: 500;"><i class="fal fa-search"></i> CERCA</span>
                        </a>
                    </div> 
                </div>
            </div>
        </div>

        <div class="modal fade" id="popUpPax" data-backdrop="static"  tabindex="-1" role="dialog"  >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-2 bg-light">
                        <h5 class="modal-title font-weight-semibold">Info header</h5>
                        <button type="button" class="close" data-bs-dismiss="modal">×</button>
                    </div>
                    <div class="modal-body">
                        <p>
                            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, 
                            egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                        </p>
                        <hr>
                        <div class="form-group">
                            <div class="row mb-1">
                                <div class="col-5">
                                    <label class="text-danger custom-control custom-control-right" style="font-weight:bold">Camere</label>
                                    <select class="form-control" @change="updateNumeroCamere()" v-model="numeroCamere">
                                        <option v-for="i in 10" v-bind:key="i">{{i}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <div class="row" v-for="camera in arrayCamereSelezionate"  v-bind:key="camera" style="overflow:auto;">
                                            <div class="col-2">
                                                <label class="text-danger custom-control custom-control-right" style="font-weight:bold">Adulti</label>
                                                <select class="form-control" v-model="camera.NUM_ADULTI" @change="adultiChangeHandler(camera)">
                                                    <option v-for="index in 10" v-bind:value="index" v-bind:key="index">{{index}}</option>
                                                </select>
                                            </div>
                                            <div class="col-2">
                                                <label class="text-danger custom-control custom-control-right" style="font-weight:bold">Bambini</label>
                                                <select class="form-control" v-model="camera.NUM_BAMBINI" @change="bambiniChangeHandler(camera)" >
                                                    <option value="0">0</option>
                                                    <option v-for="index in 10" v-bind:value="index" v-bind:key="index">{{index}}</option>
                                                </select>
                                            </div>
                                            <div class="col-8 form-inline">
                                                <div v-for="bambino in camera.ARRAY_BAMBINI" v-bind:key="bambino" class="me-1">
                                                    <label class="text-danger custom-control custom-control-right" style="font-weight:bold">Età {{bambino.ID_BAMBINO}}</label>
                                                    <select class="form-control" v-model="bambino.ANNI">
                                                        <option v-for="(value, index) in 18"  v-bind:key="index" v-bind:value="index">{{index}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Conferma</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>


<script>

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Select2 from '@/components/utils/Select2.vue'
import SelectDestinazione from '@/components/utils/SelectDestinazione.vue'


export default {
    name: "Ricerca",
    emits: ['onresult','changeModulo'],
    props:['modalita'],
    components: {
        Datepicker,
        Select2,
        SelectDestinazione
    },
    data:function(){
        return{
            filters:global.filters,
            dati:{FK_ID_MODULO:0,FK_ID_COMUNE:5908,FK_ID_COMUNE_TO:4071,FK_ID_AEROPORTO_TO:0},
            datiRicercaCompleti:{},
            paxDefault:{SESSO:0,COGNOME:'',NOME:'', ANNI:0},
            numeroCamere:1,
            allowedDates : [],
            arrayCamereSelezionate:new Array({ID_CAMERA:1,INDICE_RISULTATO_SELEZIONATO:0,NUM_ADULTI:1,NUM_BAMBINI:0,ARRAY_BAMBINI:new Array(),ARRAY_ADULTI:new Array({TYPE:'ADU',ID_ADULTO:1,NUM_PAX:1})})
        }
    },
    methods:{
        ricerca : function(arrayParametriAggiuntivi = new Array()){
            var dati = utils.clone(this.dati);
            //SE SERVE IL DATE RANGE
            if (dati.FK_ID_MODULO == 2 || (dati.FK_ID_MODULO == 4 && dati.FLG_SOLA_ANDATA == 0)){
                if (this.dati.DATE_RANGE != null && this.dati.DATE_RANGE != undefined){ 
                    dati.DATA_START = this.filters.formatDate(this.dati.DATE_RANGE[0]);
                    dati.DATA_END = this.filters.formatDate(this.dati.DATE_RANGE[1]);
                }
                else{
                    utils.alert.warning("Impostare un range corretto");
                }                
            } 
            dati.itemRicerca = this.$refs.destinazioneAll.itemRicerca;           
            dati.camere = this.arrayCamereSelezionate;
            for (var i = 0 ; i < arrayParametriAggiuntivi.length ; i++){
                dati[arrayParametriAggiuntivi[i]['CHIAVE']] = arrayParametriAggiuntivi[i]['VALORE'];
            }
            this.datiRicercaCompleti = utils.clone(dati);
            utils.ajax('travel/ricerca',dati, (response) => {
                if (response.esito == 0){
                    var arr = response.data;
                    for (var i = 0 ; i < arr.length ; i++ ){
                        arr[i].STATO_DISPONIBILITA = (i % 2 == 0 ? 1 : 2);
                        arr[i].DESCRIZIONE = arr[i].PRODOTTO.DESCRIZIONE;
                    }
                    this.$emit("onresult",arr);
                }
            });
        },
        reset : function(params = null){
            if (params == null){
                this.dati = {FK_ID_MODULO:0,FK_ID_COMUNE:5908,FK_ID_COMUNE_TO:4071,FK_ID_AEROPORTO_TO:0};
                this.datiRicercaCompleti = {};
                this.paxDefault = {SESSO:0,COGNOME:'',NOME:'', ANNI:0};
                this.numeroCamere = 1;
                this.allowedDates = [];
                this.arrayCamereSelezionate = new Array({ID_CAMERA:1,INDICE_RISULTATO_SELEZIONATO:0,NUM_ADULTI:1,NUM_BAMBINI:0,ARRAY_BAMBINI:new Array(),ARRAY_ADULTI:new Array({TYPE:'ADU',ID_ADULTO:1,NUM_PAX:1})});
            }
            else{
                this.dati = params;
                this.datiRicercaCompleti = params;
                switch (parseInt(this.dati.FK_ID_MODULO)){
                    case 1://ATTIVITÀ
                    case 2://HOTEL
                    case 3://PACCHETTI
                        var cloned = utils.clone(this.dati);
                        cloned.id = this.dati.FK_ID_PRODOTTO;
                        console.log(cloned);
                        this.$refs.destinazioneAll.setValue2(cloned);
                        break;
                }
                this.ricerca();
            }
        },
        camereChangeHandler : function(){
            var vecchioValore = this.arrayCamereSelezionate.length;
            var nuovoValore = this.ricerca.camere;
            if (vecchioValore > nuovoValore){
                var diff = vecchioValore - nuovoValore;
                for (var i = 0 ; i < diff ; i++){
                    this.arrayCamereSelezionate.splice(vecchioValore - i - 1);
                }
            }
            else{
                var diff = nuovoValore - vecchioValore;
                for (var i = 0 ; i < diff ; i++){
                    this.arrayCamereSelezionate.push({ID_CAMERA:this.arrayCamereSelezionate.length + 1,INDICE_RISULTATO_SELEZIONATO:0,NUM_ADULTI:1,NUM_BAMBINI:0,ARRAY_BAMBINI:new Array(),ARRAY_ADULTI:new Array(utils.mergeObjects(new Array({type:'ADU',ID_ADULTO:1,NUM_PAX:1},utils.clone(this.paxDefault))))});
                }
            }
        },
        openPopUpPax : function(){   
            this.filters.openPopUp("popUpPax");         
        },
        updateNumeroCamere : function(){
            const lunghezzaAttuale = this.arrayCamereSelezionate.length;
            if (this.numeroCamere > lunghezzaAttuale){
                for (var i = lunghezzaAttuale;  i < this.numeroCamere ; i++){
                    this.arrayCamereSelezionate.push({ID_CAMERA:i+1,INDICE_RISULTATO_SELEZIONATO:0,NUM_ADULTI:1,NUM_BAMBINI:0,ARRAY_BAMBINI:new Array(),ARRAY_ADULTI:new Array({TYPE:'ADU',ID_ADULTO:1,NUM_PAX:1})});
                }
            }
            else{
                for (var i = lunghezzaAttuale-1  ; i > this.numeroCamere -1; --i){
                    this.arrayCamereSelezionate.splice(i, 1);
                }
            }
        },  
        adultiChangeHandler : function(camera){
            this.paxChangeHandler(camera,"ADULTI","ADULTO");
        },
        bambiniChangeHandler : function(camera){
            this.paxChangeHandler(camera,"BAMBINI","BAMBINO");
        },
        paxChangeHandler : function(camera,prop,prop_id){
            var vecchioValore = camera['ARRAY_'+prop].length;
            var nuovoValore = camera['NUM_' + prop];
            if (vecchioValore > nuovoValore){
                var diff = vecchioValore - nuovoValore;
                for (var i = 0 ; i < diff ; i++){
                    camera['ARRAY_'+prop].splice(vecchioValore - i - 1);
                }
            }
            else{
                var diff = nuovoValore - vecchioValore;
                for (var i = 0 ; i < diff ; i++){
                    var item = {ANNI:0};
                    item['ID_' + prop_id] = camera['ARRAY_'+prop].length + 1;
                    item['NUM_PAX'] = camera['ARRAY_'+prop].length + 1;
                    if (prop == 'ADULTI'){
                        item['TYPE'] = "ADU";
                    }
                    item = utils.mergeObjects(new Array(item,utils.clone(this.paxDefault)));
                    camera['ARRAY_'+prop].push(item);
                }
            }
            for (var i = 0 ; i < this.arrayCamereSelezionate.length ; i++){
                if (this.arrayCamereSelezionate[i].ID_CAMERA == camera.ID_CAMERA ){
                    this.arrayCamereSelezionate[i] = camera;
                    break;
                }
            }
        },
        getSingolarePlurale:function(radice,count){
            if (count > 1 || count == 0){
                return radice + "I";
            }
            return radice + "O";
        },
        changeModulo : function(){
            this.dati.FK_ID_PRODOTTO = null;
            this.allowedDates = [];
            if (this.dati.FK_ID_MODULO == 4){
                this.dati.FLG_SOLA_ANDATA = 0;
            }
            this.$emit("changeModulo",this.dati.FK_ID_MODULO);
        },
        destinazioneChangeHandler : function(){ 
            var dati = utils.clone(this.dati);
            if ( (dati.FK_ID_PRODOTTO != null && dati.FK_ID_PRODOTTO != undefined && dati.FK_ID_PRODOTTO > 0) || (dati.FK_ID_AEROPORTO_TO != null && dati.FK_ID_AEROPORTO_TO != "")){
                //VOLI
                if (dati.FK_ID_MODULO == 4){
                    dati.FK_ID_PRODOTTO = "0-7";
                    dati.FK_ID_AEROPORTO_FROM = dati.FK_ID_AEROPORTO_FROM.toString().split("_")[0];
                    dati.FK_ID_AEROPORTO_TO = dati.FK_ID_AEROPORTO_TO.toString().split("_")[0];
                }

                utils.ajax('travel/ricerca/getProssimeDate',dati, (response) => {
                    if (response.esito == 0){
                        if (response.data.length == 0){
                            utils.alert.warning("Non ci sono date o risultati disponibili per i parametri impostati");
                            return;
                        }
                        console.log("GET PROSSIME DATE RESULT");
                        this.allowedDates = response.data;
                        if (this.allowedDates.length > 0){
                            this.dati.DATA_START = this.filters.formatDate(this.allowedDates[0]);
                            this.dati.DATE_RANGE = new Array();
                            this.dati.DATE_RANGE.push(this.allowedDates[0]);
                            this.dati.DATE_RANGE.push(this.allowedDates[1]);
                            /*
                            if (this.dati.DATE_RANGE != undefined && this.dati.DATE_RANGE.length > 0){
                                this.dati.DATE_RANGE[0] = this.filters.formatDate(this.allowedDates[0]);
                                this.dati.DATE_RANGE[1] = this.filters.formatDate(this.allowedDates[1]);
                            }
                            else{
                                this.dati.DATE_RANGE = new Array();
                                this.dati.DATE_RANGE.push(this.allowedDates[0]);
                                this.dati.DATE_RANGE.push(this.allowedDates[1]);
                            }*/
                            
                        }
                    }
                });                
            }
            //GENERO UN ARRAY DI DATE
            else{
                this.allowedDates = this.$root.allowedDates;
            }
        }    
    },
    computed : {
        paxText : function(){
            var totAdu = 0;
            var totChd = 0;
            for (var i = 0 ; i < this.arrayCamereSelezionate.length ; i++){
                totAdu += this.arrayCamereSelezionate[i].ARRAY_ADULTI.length ;
                totChd += this.arrayCamereSelezionate[i].ARRAY_BAMBINI.length;            
            }
            var s = totAdu + this.getSingolarePlurale(" ADULT",totAdu);
            if (totChd > 0){
                s+= ", " + totChd + this.getSingolarePlurale(" BAMBIN",totChd);
            }
            if (this.arrayCamereSelezionate.length > 1){
                s = this.arrayCamereSelezionate.length + " CAMERE - " + s;
            }
            return s;
        }
    },
    watch : {
        'dati.FK_ID_PRODOTTO' : function(valore,valoreOld){
            if (valore != null && valore != undefined){
                this.destinazioneChangeHandler();
            }            
        },
        'dati.FK_ID_AEROPORTO_TO' : function(valore,valoreOld){
            console.log(valore);
            if (valore != null && valore != undefined /* && valore > 0 */){
                this.destinazioneChangeHandler();
            }
        },
        'dati.DATE_RANGE' : function(valore,valoreOld){
            if (this.dati.DATE_RANGE != null && this.dati.DATE_RANGE != undefined){ 
                this.dati.DATA_START = this.filters.formatDate(this.dati.DATE_RANGE[0]);
                this.dati.DATA_END = this.filters.formatDate(this.dati.DATE_RANGE[1]);
            }
        },
        'dati.DATA_START' : function(valore,valoreOld){
            this.dati.DATA_END = this.dati.DATA_START;
        }
    },
    created : function(){
        this.changeModulo();
    }
}
</script>