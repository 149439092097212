<template>
     
    <!-- ATTIVITA -->
    <div class="" v-if="riga.FK_ID_MODULO == 1">
        <RigaQuotaAttivita :riga="riga" :modalita="modalita" @modifica="editQuota($event)" @modificaDate="modificaDate($event)" @elimina="eliminaQuota($event)" @selezionaProdotto="openProdotto($event)"></RigaQuotaAttivita>
    </div>

    <!-- HOTEL -->
    <div class="" v-if="riga.FK_ID_MODULO == 2">
        <RigaQuotaHotel :riga="riga" :modalita="modalita" @modifica="editQuota($event)" @modificaDate="modificaDate($event)" @elimina="eliminaQuota($event)" @selezionaProdotto="openProdotto($event)"></RigaQuotaHotel>
    </div>

    <!-- PACCHETTO -->
    <div class="" v-if="riga.FK_ID_MODULO == 3">
        <RigaQuotaPacchetto :riga="riga" :modalita="modalita" @modifica="editQuota($event)" @modificaDate="modificaDate($event)" @elimina="eliminaQuota($event)" @selezionaProdotto="openProdotto($event)"></RigaQuotaPacchetto>
    </div>

    <!-- FLIGHT -->
    <div class="" v-if="riga.FK_ID_MODULO == 4">
        <RigaQuotaVoli :riga="riga" :modalita="modalita" @modifica="editQuota($event)" @modificaDate="modificaDate($event)" @elimina="eliminaQuota($event)" @selezionaProdotto="openProdotto($event)"></RigaQuotaVoli>
    </div>

    <!-- GENERIC -->
    <div class="" v-if="riga.FK_ID_MODULO == 6">
        <RigaQuotaGeneriche :riga="riga" :modalita="modalita" @modifica="editQuota($event)" @modificaDate="modificaDate($event)" @elimina="eliminaQuota($event)" @selezionaProdotto="openProdotto($event)"></RigaQuotaGeneriche>    
    </div>

</template>



<script>

import RigaQuotaAttivita from '@/components/RigaQuotaAttivita.vue'
import RigaQuotaHotel from '@/components/RigaQuotaHotel.vue'
import RigaQuotaPacchetto from '@/components/RigaQuotaPacchetto.vue'
import RigaQuotaVoli from '@/components/RigaQuotaVoli.vue'
import RigaQuotaGeneriche from '@/components/RigaQuotaGeneriche.vue'

export default {
    name:'RigaQuota',

    emits: ['elimina', 'modifica', 'modificaDate','selezionaProdotto'],

    props:['riga','modalita'],

    components:{
        RigaQuotaAttivita,
        RigaQuotaHotel,
        RigaQuotaPacchetto,
        RigaQuotaVoli,
        RigaQuotaGeneriche,
    },

    data:function(){
        return {
            filters:global.filters,
        }
    },
    
    methods:{
        eliminaQuota : function(quota){
            this.$emit("elimina",quota);
        },
        editQuota : function(quota){
            console.log("Riga quota",quota);

            this.$emit("modifica",quota);            
        },
        setQuotaVisibile : function(quota,flgVisibile){
            quota.FLG_VISIBILE = flgVisibile;
            utils.ajax("travel/ordine/quota/update",quota, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            });            
        },
        modificaDate : function(quota){
            this.$emit("modificaDate",quota);            
        },
        getStileTitolo : function(){
            var stile = {};
            if (this.quota.FK_ID_QUOTA_PRINCIPALE > 0){
                stile['padding-left'] = "40px";
            }
            return stile;
        },
        getStileRiga : function(){
            var stile = {};
            if (this.quota.FK_ID_QUOTA_PRINCIPALE > 0){
                stile['font-size'] = "14px";
            }
            else{
                stile['font-size'] = "18px";
            }
            return stile;
        },
        getLblStatoQuota : function(quota){
            switch (quota.FK_ID_STATO){
                case 1:
                    return "CONFERMATA";
                case 2:
                    return "IN RICHIESTA";
                default:
                    return "-";
            }
        },
        openProdotto : function(prodotto){
            if(this.riga.FK_ID_MODULO != 6){
                this.$emit("selezionaProdotto",prodotto); 
            }
        },
        getTotaleQuote : function(quote){
            var totale = 0;
            for (var i = 0 ; i < quote.length; i++){
                totale += parseFloat(quote[i].PREZZO_TOTALE);
            }
            return totale;
        },
        getLabel : function(FK_ID_TIPO){
            const tipo = utils.array.select(this.$root.arrayAllOpzioni,"ID_OPZIONE_TIPOLOGICA",FK_ID_TIPO,1)[0];
            return tipo != undefined ? tipo.DESCRIZIONE : '';
        }
        
       
    },
    created : function(){
    }
}
</script>
