<template>

    <div class="row" v-if="ordine.ID_ORDINE > 0">
        <div class="col-md-12" v-show="ordine.ID_ORDINE > 0">

            <div class="navbar navbar-sm navbar-dark bg-secondary navbar-expand-xl rounded p-1 mt-1">
                <div class="container-fluid">
                    <ul class="nav navbar-nav flex-row flex-fill nav-justified navbar-2" role="tablist">
                        <li class="nav-item me-1" role="presentation"><a :href="'#quote'+ts" class="navbar-nav-link rounded active" data-bs-toggle="tab">QUOTE</a></li>
                        <li class="nav-item me-1" role="presentation"><a :href="'#pax'+ts" class="navbar-nav-link rounded" data-bs-toggle="tab">PAX</a></li>
                        <li class="nav-item me-1" role="presentation"><a :href="'#total'+ts" class="navbar-nav-link rounded" data-bs-toggle="tab">INCASSI</a></li>
                        <li class="nav-item me-1" role="presentation"><a :href="'#astoi'+ts" class="navbar-nav-link rounded" data-bs-toggle="tab">CONTRATTO</a></li>
                        <li v-show="modalita == 2" class="nav-item me-1" role="presentation"><a :href="'#docs'+ts" class="navbar-nav-link rounded" data-bs-toggle="tab">DOCS</a></li>
                        <li class="nav-item me-1" role="presentation"><a :href="'#note'+ts" class="navbar-nav-link rounded" data-bs-toggle="tab">NOTE</a></li>
                        <li v-show="modalita == 2" class="nav-item me-1" role="presentation"><a :href="'#payment'+ts" class="navbar-nav-link rounded" data-bs-toggle="tab">PAGAMENTI</a></li>
                        <li class="nav-item me-1" role="presentation"><a :href="'#history'+ts" class="navbar-nav-link rounded" data-bs-toggle="tab">STORICO</a></li>
                        <li class="nav-item me-1" role="presentation"><a :href="'#cost'+ts" class="navbar-nav-link rounded" data-bs-toggle="tab">COSTI</a></li>
                     </ul>   
                </div>
            </div>
            <div class="tab-content">

                <div class="tab-pane fade show active" :id="'quote'+ts">

                    <div class="navbar navbar-expand-xl py-1 px-0">
                        <div class="container-fluid justify-content-start">
                            <div class="navbar-brand flex-1 flex-xl-0">
                                <i class="fal fa-info fa-fw me-1"></i>Dettaglio quote
                            </div>
                            <ul class="nav ms-xl-auto">
                                <li class="nav-item position-relative ms-1 me-3">
                                    <ISwitch class="mt-1" :size="'lg'" v-model="mostraQuoteNascoste" style="z-index: 0;" title="Mostra quote nascoste"></ISwitch>
                                </li>
                                <li class="nav-item position-relative ms-3">
                                    <a href="#" class="badge bg-warning p-1 mt-1" v-on:click="nuovaQuota(1)">
										NUOVA QUOTA
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="box-rigaquota" v-bind:class="{'': modalita == 1,'': modalita == 2}">
                        <div class="list-feed ms-1 me-1 mt-2">
                
                            <template v-for="riga in filters.getQuoteOrdinate(this.ordine)" v-bind:key="riga">
                                <RigaQuota :modalita="modalita" :riga="riga"  @modifica="editQuota($event)" @modificaDate="modificaDate($event)" @elimina="eliminaQuota($event)"  @selezionaProdotto="openProdotto($event)"></RigaQuota>
                            </template>

                            <!--    
                            <template v-for="quota in filters.getQuoteOrdinate(this.ordine)" v-bind:key="quota.ID_QUOTA" >
                                <RigaQuota :modalita="modalita" :quota="quota" v-if="quota.FLG_VISIBILE == 1 || (quota.FLG_VISIBILE == 0 && mostraQuoteNascoste == 1)" @modifica="editQuota(quota)" @modificaDate="modificaDate(quota)" @elimina="eliminaQuota(quota)"  @selezionaProdotto="openProdotto(quota)"></RigaQuota>
                                SERVIZI PACCHETTO 
                                <template v-for="q in quota.quote" v-bind:key="q.ID_QUOTA">
                                    <RigaQuota :modalita="modalita" :quota="q"  v-if="q.FK_ID_MODULO != quota.FK_ID_MODULO && (quota.FLG_VISIBILE == 1 || (quota.FLG_VISIBILE == 0 && mostraQuoteNascoste == 1))"  @modifica="editQuota(q)" @selezionaProdotto="openProdotto(q)" ></RigaQuota>
                                </template> 
                                 EXTRA 
                                 <template v-for="q in quota.extra" v-bind:key="q.ID_QUOTA">
                                    <RigaQuota :modalita="modalita" :quota="q" v-if="quota.FLG_VISIBILE == 1 || (quota.FLG_VISIBILE == 0 && mostraQuoteNascoste == 1)" @modifica="editQuota(q)" @selezionaProdotto="openProdotto(q)" ></RigaQuota>
                                </template>                              
                            </template>
                            -->

                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" :id="'pax'+ts">
                    <h6 class="profile-username mt-1 mb-3" style="padding: 5px 0px 0 0px;">
                        <i class="far fa-users fa-fw me-1"></i> Intestatario Pratica 
                    </h6>
                    <table class="table table-bordered table-hover table-striped table-ext">
                        <thead>
                            <tr>
                                <th class="table-head-sm-2 text-center" style="width:5%; padding: 0.4rem;">Tipo</th>
                                <th class="table-head-sm-2 text-center" style="padding: 0.4rem;"><span class="d-block d-xl-none"><i class="far fa-user fa-fw"></i> </span>Nome</th>
                                <th class="table-head-sm-2 text-center" style="width:6%; padding: 0.4rem;"><span class="d-block d-xl-none"><i class="far fa-stopwatch-20 fa-fw"></i> </span>Età</th>
                                <th class="table-head-sm-2 text-center" style="width:27%; padding: 0.4rem;"><span class="d-block d-xl-none"><i class="far fa-envelope fa-fw"></i> </span>Email</th>
                                <th class="table-head-sm-2 text-center" style="width:17%; padding: 0.4rem;"><span class="d-block d-xl-none"><i class="far fa-phone fa-fw"></i> </span>Telefono</th>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr>
                                <td><span class="badge badge-info badge-xs badge-pill fs-12">Adu</span></td>
                                <td class="fs-12">
                                    <span v-if="ordine.intestatarioPersonaFisica.SESSO == 1">Mr</span><span v-else>Mrs</span>
                                    - {{ordine.intestatarioPersonaFisica.COGNOME}} {{ordine.intestatarioPersonaFisica.NOME}}
                                    <span class="badge bg-light badge-xs float-end mt-1">{{filters.getStato(ordine.intestatarioPersonaFisica.FK_ID_NAZIONALITA).COUNTRY_CODE}}</span>
                                </td>
                                <td class="fs-12 text-center">{{ordine.intestatarioPersonaFisica.eta}}</td>
                                <td class="fs-12">{{ordine.intestatarioPersonaFisica.EMAIL}}</td>
                                <td class="fs-12">{{ordine.intestatarioPersonaFisica.TELEFONO}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h6 class="profile-username mt-3" style="padding:0;">
                        <i class="far fa-bed fa-fw"></i> Partecipanti Camera 1
                    </h6> 
                    <div class="box-pax">                
                        <table class="table table-bordered table-hover table-striped table-ext">
                            <thead class="sticky-top">
                                <tr class="bg-secondary text-white">
                                    <th class="table-head-sm-2 text-center" style="width:5%; padding: 0.4rem;">Tipo</th>
                                    <th class="table-head-sm-2 text-center" style="padding: 0.4rem;"><span class="d-block d-xl-none"><i class="far fa-user fa-fw"></i> </span>Nome</th>
                                    <th class="table-head-sm-2 text-center" style="width:6%; padding: 0.4rem;"><span class="d-block d-xl-none"><i class="far fa-stopwatch-20 fa-fw"></i> </span>Età</th>
                                    <th class="table-head-sm-2 text-center" style="width:27%; padding: 0.4rem;"><span class="d-block d-xl-none"><i class="far fa-id-card fa-fw"></i> </span>C.F.</th>
                                    <th class="table-head-sm-2 text-center" style="width:17%; padding: 0.4rem;"><span class="d-block d-xl-none"><i class="far fa-birthday-cake fa-fw"></i> Data </span>Nascita</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="pax in ordine.distinctPax" v-bind:key="pax.ID_PAX">
                                    <td><span class="badge badge-info badge-xs badge-pill fs-12">Adu</span></td>
                                    <td class="fs-12">
                                        <span v-if="pax.personaFisica.SESSO == 1">Mr</span><span v-else>Mrs</span>
                                        - {{pax.personaFisica.COGNOME}} {{pax.personaFisica.NOME}}
                                        <span class="badge bg-light badge-xs float-end mt-1">{{filters.getStato(pax.personaFisica.FK_ID_NAZIONALITA).COUNTRY_CODE}}</span>
                                    </td>
                                    <td class="fs-12 text-center">{{pax.personaFisica.eta}}</td>
                                    <td class="fs-12">{{pax.personaFisica.cf}}</td>
                                    <td class="fs-12">{{pax.personaFisica.DATA_NASCITA}}</td>
                                </tr>  
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="tab-pane fade" :id="'total'+ts">
                    <div class="mt-1 mb-3">
                        <GrigliaIncassi :modalita="modalita" :ref="'grigliaIncassi'"></GrigliaIncassi>
                    </div>
                </div>

                <div class="tab-pane fade" :id="'astoi'+ts">
                    <h6 class="profile-username mt-1 mb-3" style="padding: 5px 0 0 0;">
                        <div v-if="modalita == 1"><i class="far fa-file fa-fw me-1"></i> Contratto</div>
                        <div v-else class="w-100">
                            <i class="far fa-hand-paper fa-fw me-1"></i> Compilare i dati solo se si vuole stampare il contratto ASTOI.
                            <span v-show="modalita == 2" v-on:click="salvaAstoi(1)" class="badge bg-danger bg-opacity-20 text-danger cursor-pointer float-end p-1">
                                <i class="fal fa-plus fa-lg fa-fw text-dark me-2"></i> <span class="fw-semibold text-dark"> SALVA CONTRATTO</span>
                            </span>
                        </div>
                    </h6>
                    <div class="box-rigaquota">
                        <table class="table table-bordered table-hover table-striped table-ext">
                            <thead class="sticky-top">
                                <tr class="bg-secondary text-white">
                                    <th class="table-head-sm-2" style="width: 30%;">Titolo</th> 
                                    <th class="table-head-sm-2 text-center" style="width: 20%;">Azione</th> 
                                    <th class="table-head-sm-2" style="width: 30%;">Titolo</th> 
                                    <th class="table-head-sm-2 text-center" style="width: 20%;">Azione</th>
                                </tr>
                            </thead> 
                            <tbody>
                                <tr>
                                    <td class="fs-12">Passaporto:</td> 
                                    <td class="text-center" style="padding: 0 !important;"><input true-value="1" false-value="0" type="checkbox" class="form-control" style="width: 20px; display: unset;"></td> 
                                    <td class="fs-12">Carta d‘Identità:</td> 
                                    <td class="text-center" style="padding: 0 !important;"><input type="checkbox" true-value="1" false-value="0" class="form-control" style="width: 20px; display: unset;"></td>   
                                </tr> 
                                <tr>
                                    <td class="fs-12">Visto:</td> 
                                    <td class="text-center" style="padding: 0 !important;"><input type="checkbox" true-value="1" false-value="0" class="form-control" style="width: 20px; display: unset;"></td>
                                    <td class="fs-12">Vaccinazioni e Profilassi:</td> 
                                    <td class="text-center" style="padding: 0 !important;"><input type="checkbox" true-value="1" false-value="0" class="form-control" style="width: 20px; display: unset;"></td> 
                                </tr>
                                <tr>
                                    <td class="fs-12">Assicurazioni:</td> 
                                    <td colspan="3"><input type="text" class="form-control input-sm"></td>
                                </tr> 
                                <tr>
                                    <td class="fs-12">Richieste particolari:</td> 
                                    <td colspan="3"><input type="text" class="form-control input-sm"></td>
                                </tr> 
                                <tr>
                                    <td class="fs-12">Richiesta impegnativa:</td> 
                                    <td colspan="3"><input type="text" class="form-control input-sm"></td>
                                </tr> 
                                <tr>
                                    <td class="fs-12">Altro:</td> 
                                    <td colspan="3"><input type="text" class="form-control input-sm"></td>
                                </tr> 
                                <tr>
                                    <td class="fs-12">Catalogo:</td> 
                                    <td class="text-center" style="padding: 0 !important;"><input type="checkbox" true-value="1" false-value="0" class="form-control" style="width: 20px; display: unset;"></td> 
                                    <td class="fs-12">Pacchetto:</td> 
                                    <td class="text-center" style="padding: 0 !important;"><input type="checkbox" true-value="1" false-value="0" class="form-control" style="width: 20px; display: unset;"></td>
                                </tr> 
                                <tr>
                                    <td class="fs-12">Servizio:</td> 
                                    <td class="text-center" style="padding: 0 !important;"><input type="checkbox" true-value="1" false-value="0" class="form-control" style="width: 20px; display: unset;"></td> 
                                    <td class="fs-12">Fuori Catalogo:</td> 
                                    <td class="text-center" style="padding: 0 !important;"><input type="checkbox" true-value="1" false-value="0" class="form-control" style="width: 20px; display: unset;"></td> 
                                </tr> 
                                <tr>
                                    <td class="fs-12">Viaggio su Misura:</td> 
                                    <td class="text-center" style="padding: 0 !important;"><input type="checkbox" true-value="1" false-value="0" class="form-control" style="width: 20px; display: unset;"></td> 
                                
                                </tr>
                                <tr>                                    
                                    <td class="fs-12">Dettagli Catalogo:</td> 
                                    <td colspan="3"><input type="text" class="form-control input-sm"></td>
                                </tr> 
                                <tr>
                                    <td class="fs-12">Itinerario:</td> 
                                    <td colspan="3"><input type="text" class="form-control input-sm"></td>
                                </tr> 
                                <tr>
                                    <td class="fs-12">Partenza Da:</td> 
                                    <td colspan="3"><input type="text" class="form-control input-sm"></td>
                                </tr> 
                                <tr>
                                    <td class="fs-12">Ritorno A:</td> 
                                    <td colspan="3"><input type="text" class="form-control input-sm"></td>
                                </tr> 
                                <tr>
                                    <td class="fs-12">Data saldo:</td> 
                                    <td colspan="3"><input type="text" class="form-control input-sm"></td>
                                </tr>
                            </tbody> 
                        </table>  
                          
                    </div>
                </div>

                <div class="tab-pane fade" :id="'docs'+ts">
                    <h6 class="profile-usernamemt-1 mb-3" style="padding: 5px 0 0 0;" v-show="modalita == 1">
                        <i class="far fa-files fa-fw me-1"></i> Documenti 
                    </h6>
                    
                </div>

                <div class="tab-pane fade" :id="'note'+ts">
                    <h6 class="profile-usernamemt-1 mb-3" style="padding: 5px 0 0 0;" v-show="modalita == 1">
                        <i class="far fa-files fa-fw me-1"></i> Note 
                    </h6>

                </div>

                <div class="tab-pane fade" :id="'payment'+ts">
                    <div class="mt-1 mb-3">
                        <GrigliaCosti :modalita="modalita" :ref="'grigliaCosti'"></GrigliaCosti>    
                    </div>
                </div>

                <div class="tab-pane fade" :id="'history'+ts">
                    <h6 class="profile-username mt-1 mb-3" style="padding: 5px 5px 0 5px;"><i class="fal fa-info fa-fw me-1"></i> Storico pratica</h6>
                    <div class="box-rigaquota"> 


                        <div class="history-tl-container " v-bind:class="{'history-tl-size_xs':modalita == 2,'history-tl-size_sm':modalita == 1}">
                            <ul class="tl">

                                <li class="tl-item" v-for="log in ordine.storico" v-bind:key="log.ID_LOG">
                                    <div class="timestamp">
                                        {{ filters.formatDateTimeSeconds(log.DATA_AZIONE) }}
                                        <br>{{ log.USERNAME }}
                                    </div>
                                    <div class="item-title">{{ log.DESCRIZIONE }}</div>
                                    <div class="item-detail" v-on:click="apriLog(log)">Apri</div>
                                </li>

                            </ul>
                        </div>

                       
                    </div>                    
                </div>

                <div class="tab-pane fade" :id="'cost'+ts">
                    <h6 class="profile-username mt-1 mb-3" style="padding: 5px 5px 0 5px;"><i class="fal fa-funnel-dollar fa-fw me-1"></i> Gestione dei Costi</h6>
                    <div class="box-rigaquota"> 
                                        
                        <table class="table table-bordered table-hover table-striped table-ext">
                            <thead>
                                <tr>
                                    <th class="text-primary p-2" colspan="5"><strong>FRUIT VILLAGE HAMMAMET PARADIS PALACE 4* - PACCHETTI CON VOLO</strong></th>
                                </tr>
                                <tr>
                                    <th class="table-head-sm-2 text-center">Descrizione</th>
                                    <th class="table-head-sm-2 text-center" style="width: 10%;">Tipo</th>
                                    <th class="table-head-sm-2 text-center" style="width: 15%;">Costo</th>
                                    <th class="table-head-sm-2 text-center" style="width: 12%;">Calcolo</th>
                                    <th class="table-head-sm-2 text-center" style="width: 16%;">Costo Tot</th>
                                </tr>
                            </thead>
                        </table>
                                    
                        <table style="border-collapse:collapse; width:100%; border-top:1px solid #dddddd;">
                            <thead>
                                <tr>
                                    <th colspan="6" style="font-size:13px; background-color:#efefef; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding:7px; color:#505050;"><strong>HOTELS</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="height: 30px; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">Camera Main Building</td>
                                    <td style="width: 10%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">SGL</td>
                                    <td style="width: 15%; text-align:center; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; color:#f00;"><strong>€ 207.90 </strong></td>
                                    <td style="width: 12%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"> </td>
                                    <td style="width: 16%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"> </td>
                                </tr>
                                <tr>
                                    <td colspan="6" style="font-size:13px; padding: 7px; line-height: 18px;"></td>
                                </tr>		
                            </tbody>
                        </table>
                                    
                        <table style="border-collapse:collapse; width:100%; border-top:1px solid #dddddd;">
                            <thead>
                                <tr>
                                    <th colspan="6" style="font-size:13px; background-color:#efefef;  border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding:7px; color:#505050;"><strong>TUNISI DA VENEZIA - VCE&nbsp;&nbsp;<i class="fad fa-arrow-right"></i>&nbsp;&nbsp;TUN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="height: 30px; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">Economy</td>
                                    <td style="width: 10%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"></td>
                                    <td style="width: 15%; text-align:center; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; color:#f00;"><strong>€ 34.65</strong></td>
                                    <td style="width: 12%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">x 1</td>
                                    <td style="width: 16%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"> </td>
                                </tr>
                                <tr>
                                    <td colspan="6" style="font-size:13px; padding: 7px; line-height: 18px;"></td>
                                </tr>
                            </tbody>
                        </table>
                                    
                        <table style="border-collapse:collapse; width:100%; border-top:1px solid #dddddd;">
                            <thead>
                                <tr>
                                    <th colspan="6" style="font-size:13px; background-color:#efefef;  border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding:7px; color:#505050;"><strong>TUNISI DA VENEZIA - TUN&nbsp;&nbsp;<i class="fad fa-arrow-right"></i>&nbsp;&nbsp;VCE</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="height: 30px; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">Economy</td>
                                    <td style="width: 10%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"></td>
                                    <td style="width: 15%; text-align:center; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; color:#f00;"><strong>€ 0.00</strong></td>
                                    <td style="width: 12%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">x 1</td>
                                    <td style="width: 16%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"> </td>
                                </tr>
                                <tr>
                                    <td colspan="6" style="font-size:13px; padding: 7px; line-height: 18px;"></td>
                                </tr>
                            </tbody>
                        </table>
                            
                        <table style="border-collapse:collapse; width:100%; border-top:1px solid #dddddd;">
                            <thead>
                                <tr>
                                    <th colspan="6" style="font-size:13px; background-color:#efefef; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding:7px; color:#505050;"><strong>EXTRA INCLUSO</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="height: 30px; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">Fruit Travel Card Estero Adulto</td>
                                    <td style="width: 10%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"></td>
                                    <td style="width: 15%; text-align:center; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; color:#f00;"><strong>€  0.00</strong></td>
                                    <td style="width: 12%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">x 1</td>
                                    <td style="width: 16%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"> </td>
                                </tr>
                                <tr>
                                    <td style="height: 30px; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">Fruit Formula Block Estero Adulto</td>
                                    <td style="width: 10%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"></td>
                                    <td style="width: 15%; text-align:center; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; color:#f00;"><strong>€  0.00</strong></td>
                                    <td style="width: 12%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">x 1</td>
                                    <td style="width: 16%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"> </td>
                                </tr>
                                <tr>
                                    <td style="height: 30px; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">Imposta di Bollo</td>
                                    <td style="width: 10%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"></td>
                                    <td style="width: 15%; text-align:center; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; color:#f00;"><strong>€  0.00</strong></td>
                                    <td style="width: 12%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">x 1</td>
                                    <td style="width: 16%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"> </td>
                                </tr>
                                <tr>
                                    <td style="height: 30px; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">Trasferimento a/r</td>
                                    <td style="width: 10%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"></td>
                                    <td style="width: 15%; text-align:center; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; color:#f00;"><strong>€  0.00</strong></td>
                                    <td style="width: 12%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;">x 1</td>
                                    <td style="width: 16%; font-size:13px; border-bottom:1px solid #dddddd; border-right:1px solid #dddddd; border-left:1px solid #dddddd; padding: 0px 7px;line-height:24px;"> </td>
                                </tr>
                                <tr>
                                    <td colspan="6" style="font-size:13px; padding: 7px; line-height: 18px;"></td>
                                </tr>
                            </tbody>
                        </table>
                    
                
                    </div>
                </div>
            </div>
            
        </div>
    </div>       
        
</template>



<script>

import RigaQuota from '@/components/RigaQuota.vue'
import GrigliaIncassi from '@/components/GrigliaIncassi.vue';
import GrigliaCosti from '@/components/GrigliaCosti.vue';
import ISwitch from '@/components/utils/ISwitch.vue'


export default {
    name:"TabsOrdine",

    components:{
        RigaQuota,
        GrigliaIncassi,
        GrigliaCosti,
        ISwitch
    },

    emits:['selezionaProdotto','elimina','modifica','modificaDate','eventoSelezionato'],
    //modalita 1: sola lettura - modalita 2: modifica

    props:['modalita'],

    data:function(){
        return{
            filters:global.filters, 
            ordine:{},
            ts:0,
            mostraQuoteNascoste:0
        }
    },

    methods:{
        startup : function(ordine){    
            this.ordine = utils.clone(ordine)  ;
            utils.wait(()=>{
                this.$refs.grigliaIncassi.startup(this.ordine,0);
                this.$refs.grigliaCosti.startup(this.ordine,0);
            });            
        },
        openOrdine:function(){
            utils.closeNav();
            this.$root.ordineSelezionato = utils.clone(this.ordine);
            global.router.push("Ordine");
        },
        openProdotto : function(prodotto){
            //this.quotaSelezionata = utils.clone(quota);
            this.$emit("selezionaProdotto",prodotto);
            //console.log(quota);
            //this.$refs.schedaProdotto.openSchedaProdotto(prodotto);
        },
        nuovaQuota : function(id){
            this.$root.ordineSelezionato = utils.clone(this.ordine);
            global.router.push("Quota");
        },
        eliminaQuota : function(quota){
            utils.alert.confirm("Sei sicuro di voler eliminare la quota <strong>" + quota.DESCRIZIONE + "</strong>?",()=>{
                utils.ajax("travel/ordine/quota/delete",{ID_QUOTA:quota.ID_QUOTA}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        //this.getOrdine();
                        this.$emit("elimina");
                    }
                });
            },this.$root)
        },
        editQuota : function(quota){
            console.log("tabsOrdine",quota);
            this.$emit("modifica",quota);            
        },
        modificaDate : function(quota){
            this.$emit("modificaDate",quota);            
        },
        apriLog : function(log){
            this.$emit("eventoSelezionato",log);
        }
    },

    computed:{
        
    },

    created : function(){
        this.ts = new Date().getTime();
    }

}

</script>
