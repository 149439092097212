<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
                    <a href="#" class="hidden-xs text-dark fw-semibold cursor-pointer" v-on:click="getNotificheAll()">Template</a> 
					<span class="hidden-xs ms-1">\</span> 
					<span class="text-muted ms-1">Gestione Notifiche</span>
				</h4>
				<a href="#page_header" class="hidden-lg btn btn-light align-self-center collapsed border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
					
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;">
						
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(this.modulo)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
				</div>
			</div>
		</div> 
		<!-- /breadcrumb -->

        <!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#config-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7"></i>
								<span class="hidden-lg ms-1">Notifiche</span>
								<span class="hidden-xs ms-1"><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Notifiche</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation" v-show="$root.isGod">
						<a href="#config-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>	
								<span class="hidden-lg ms-1">Tipologie</span>
								<span class="hidden-xs ms-1"><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Tipologie</span>
							</div>
						</a>
					</li>
					<li class="nav-item d-lg-none ms-auto">
						<a href="#profile_nav" class="navbar-nav-link navbar-nav-link-icon rounded-pill rounded-pill-small collapsed" data-bs-toggle="collapse" aria-expanded="false">
							<i class="ph-caret-down collapsible-indicator"></i>
						</a>
					</li>
				</ul>  

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0 select-small">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Notifiche selezionate</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti le Notifiche</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina Notifiche</a>
								</div>
							</div>
						</li>
						<li class="nav-item">					

						</li>
					</ul>
			   </div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->
	
	<!-- Content area -->
	<div class="content content-1 table-cms-wrapper">
        <div class="tab-content flex-1 order-2 order-lg-1">

            <div class="tab-pane fade active show" id="config-a">
				<div class="card shadow mb-xs-3 mb-lg-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="utente-a">
									<div class="row">
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Notifica" v-model="search.ID_NOTIFICA.VALUE" :tabindex="7" />
										</div>
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
											<select class="form-select form-select-sm"  data-placeholder="Sesso" :tabindex="13">
												<option value="">Sesso </option>   
												<option value="1"> &nbsp; Uomo</option>
												<option value="2"> &nbsp; Donna</option>
											</select>
										</div>
										<div class="col-12 col-md-5 col-lg-5">
											<div class="row">

												
												<div class="col-6 col-md-3 col-lg-3 mbx-1">
												
												</div>										
											</div>
										</div>
										<div class="col-6 col-md-2 col-lg-2 mbx-1">
											<select class="form-select form-select-sm" v-model="search.FK_ID_MODULO.VALUE">
												<option value="">Modulo</option>
												<option v-for="modulo in $root.arrayTipoModuli" v-bind:value="modulo.ID_OPZIONE_TIPOLOGICA" v-bind:key="modulo.ID_OPZIONE_TIPOLOGICA"> &nbsp; {{ modulo.DESCRIZIONE }}</option>
											</select>
										</div>
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
										</div>
										<div class="col-6 col-md-1 col-lg-1">
											<select class="form-select form-select-sm" data-placeholder="Stato" v-model="search.FLG_AZIONE.VALUE" :tabindex="13">
												<option value="">Tutti </option>   
												<option value="0"> &nbsp; Lette</option>
												<option value="1"> &nbsp; Non Lette</option>
											</select>
										</div>
										<div class="col-6 col-md-1 col-lg-1">
			                                <a href="#" class="btn btn-flat-info btn-sm w-100 collapsed" role="button" aria-expanded="false">
			                                    &nbsp;
											</a>
										</div>
									</div>		
								</form>
							</div>
						</div> 
					</div>
				
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="div_table_resp tableFixHead" id="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable text-nowrap"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllNotifiche" v-on:click="filters.selectAll('chkAllNotifiche',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:6%;"> #<br /><span class="float-end"> </span></th>
										<th class="table-head-sm">Titolo<br />Mittente - Data</th>
										<th class="table-head-sm" style="width:17%;">
											<span v-if="livello < 3">Ruolo /<br />Gruppo</span>
											<span v-else>Tipologia /<br />Nazionalità</span>
										</th>
										<th class="table-head-sm" style="width:17%;">Data di Nascita /<br />Residenza</th>
										<th class="table-head-sm" style="width:17%;">
											<div v-if="livello < 3">Accesso /<br />Livello</div>
											<div v-else>Ordini /<br />Fatturati</div>
										</th>
										<th class="table-head-sm text-center" style="width:5%;">Stato</th>
										<th class="table-head-sm text-center" style="width:5%;">Azioni</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="notifica in paginatedList.arr" v-bind:key="notifica.ID_NOTIFICA">
										<td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="notifica.SELEZIONATO" class=""> 
										</td>
										<td class="td-class fs-sm">
											<span>{{notifica.ID_NOTIFICA}}</span><br />
											<small class="badge bg-light text-primary float-end"> &nbsp; </small>
										</td>
										<td class="td-class fs-sm">
											<span class="text-primary text-uppercase fw-bold cursor-pointer">{{notifica.TITOLO}}</span><br />
											<span class="text-dark">{{ notifica.USERNAME }} - {{ filters.formatDateTime(notifica.ORARIO) }}</span>
										</td>
										<td class="td-class fs-sm">
											{{notifica.DESCRIZIONE}}
										</td>  
										<td class="td-class fs-sm">
											<div v-if="livello != 5">
												<span class="badge bg-light badge-xs text-dark rounded-0 me-1">{{ filters.getLabel($root.arrayTipoModuli,'ID_OPZIONE_TIPOLOGICA',notifica.FK_ID_MODULO,'DESCRIZIONE') }}</span><br/>
												<span>Gruppo</span>
											</div>
											<div v-if="livello == 5">
												<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;"><i class="fal fa-tag fa-fw"></i> Privato </span><br/>
												<span> </span>
											</div>
										</td>
										<td class="td-class fs-sm">
											<i class="ph-user fs-base lh-base align-top text-success me-1"></i> Utente: <span class="text-primary">{{ notifica.USERNAME_LETTURA }}</span><br />
											<i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Data Lettura: <span class="text-primary">{{ filters.formatDateTime(notifica.DATA_LETTURA) }}</span>
										</td>
										<td class="td-class text-center">
											<ISwitch @toggle="updateStatoNotifica(notifiche)" v-model="notifica.FLG_AZIONE" :size="'sm'" style="z-index: 0;"></ISwitch>
										</td>
										<td class="td-class text-center">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="editType(place)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Menu</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteType(place)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Menu</a>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI: {{ paginatedList.count }}</span>
                            	</div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end pag-mobile">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH"></Pagine>
			                    </div>
			                </div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
			            	</div>
			        	</div>
					</div>
				</div>
            </div>

			<div class="tab-pane fade" id="config-b" v-show="$root.isGod">

                <div class="card shadow mb-0 mb-xs-1">
                    <div class="card-body mt-0 mb-0">
                        <div class="tab-content">

                            <div class="tab-pane active" role="tabpanel" style="padding: 0;"> 
								<div class="d-lg-none p-1">
									<div class="d-grid gap-2">
										<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
											VEDI FILTRI
										</a>
									</div>	 
								</div>
								<div class="collapse show" id="collapse-all" style=""> 

	                                <div class="alert alert-2 alert-secondary border-0">
	                                    <form autocomplete="off" action="#">
	                                        <div class="row">
	                                            <div class="col-12 col-md-2 mbx-1">
	                                                <input autocomplete="off" class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="searchTypo.TITOLO.VALUE">
	                                            </div>           
	                                            <div class="col-12 col-md-2 mbx-1">
	                                                <input autocomplete="off" class="form-control form-control-sm" placeholder="Icona" type="text" v-model="searchTypo.DESCRIZIONE.VALUE">
	                                            </div>          
	                                            <div class="col-12 col-md-2 mbx-1">
	                                                <input autocomplete="off" class="form-control form-control-sm" placeholder="Custom 1" type="text" v-model="searchTypo.FK_ID_MODULO.VALUE">		
	                                            </div>         
	                                            <div class="col-12 col-md-2 mbx-1">
	                                                <input autocomplete="off" class="form-control form-control-sm" placeholder="Custom 2" type="text">		
	                                            </div>
	                                            <div class="col-12 col-md-4">
	                                                <div class="row">
	                                                    <div class="d-none d-sm-block col-md-6 form-group-filter">
	                                                    </div>
	                                                    <div class="col-6 col-md-3 mbx-1">
	                                                        
	                                                    </div>
	                                                    <div class="col-6 col-md-3 form-group-filter">
	                                                        <select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="13" v-model="searchTypo.FLG_ATTIVO.VALUE">
	                                                            <option value="">Tutti </option>   
	                                                            <option value="1"> &nbsp; Attivati</option>
	                                                            <option value="0"> &nbsp; Disattivati</option>
	                                                        </select>
	                                                    </div> 
	                                                </div>
	                                            </div>  
	                                        </div>
	                                    </form>
	                                </div> 
							   	</div>

                                <div class="box-card mt-2" id="box-card">
									<div class="box-config" id="tableFixHead">                                
		                                <table class="table table-bordered table-hover table-striped table-togglable">
						                    <thead class="sticky-top">
						                        <tr class="bg-secondary text-white">
		                                            <th class="table-head-sm" style="width: 6%;">#</th>
		                                            <th class="table-head-sm" style="">TITOLO</th> 
		                                            <th class="table-head-sm" style="width: 20%;">DESCRIZIONE</th>  
		                                            <th class="table-head-sm" style="width: 20%;">MODULO</th>  
		                                            <th class="table-head-sm text-center" style="width: 5.4%;">Attivo</th> 
		                                            <th class="table-head-sm text-center" style="width: 10%;">
														<div class="btn btn-light btn-sm" v-on:click="nuovaTipologiaNotifica()"><i class="fa fa-plus fa-fw"></i></div>
													</th> 
		                                        </tr>
		                                    </thead> 
		                                    <tbody>
		                                        <tr v-for="notifica in paginatedListTypo.arr" v-bind:key="notifica.ID_TIPOLOGIA_NOTIFICA">
		                                            <td class="td-class fs-12" style="height:2.72rem;">                                                
		                                                {{notifica.ID_TIPOLOGIA_NOTIFICA}}
		                                                <span class="badge badge-danger ms-4">NON SALVATO</span>
		                                            </td>
		                                            <td class="td-class"><input autocomplete="off" class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="notifica.TITOLO"></td> 
		                                            <td class="td-class"><input autocomplete="off" class="form-control form-control-sm" placeholder="Icona" type="text" v-model="notifica.DESCRIZIONE"></td> 
		                                            <td class="td-class">
                                                        <select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="13" v-model="notifica.FK_ID_MODULO">
                                                            <option value="-1">Tutti </option>   
                                                            <option v-for="modulo in $root.arrayTipoModuli" v-bind:key="modulo.ID_OPZIONE_TIPOLOGICA" v-bind:value="modulo.ID_OPZIONE_TIPOLOGICA"> &nbsp;{{ modulo.CUSTOM_1 }}  </option>
                                                        </select>
                                                    </td> 
 
		                                            <td class="td-class text-center">
		                                                <ISwitch @toggle="updateStatoOpzione(notifica)" :size="'sm'" v-model="notifica.FLG_AZIONE"></ISwitch>                                           
		                                            </td>                                              
		                                            <td class="td-class text-center">                                                
		                                                <button class="badge bg-success border-0 me-2" v-on:click="salvaTipologiaNotifica(notifica)"><i class="fal fa-save fa-fw"></i></button>
		                                                <button class="badge bg-danger border-0 me-2" v-on:click="eliminaTipologiaNotifica(notifica)"><i class="fal fa-trash fa-fw"></i></button>
		                                            </td>
		                                        </tr>
		                                    </tbody>
		                                </table>
									</div>
                                </div>
                
		                		<div class="row page-bottom">
                                    <div class="col-8 col-md-11" :tabindex="27">
                                        <div class="float-end">
                                            <Pagine v-model="searchTypo.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListTypo.count" :numpagine="searchTypo.PAGINATION.LENGTH"></Pagine>
                                        </div>
                                    </div>
                                    <div class="col-4 col-md-1" :tabindex="28">
                                        <select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="searchTypo.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="75">75</option>
                                            <option value="100">100</option>
                                            <option value="250">250</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
			</div>
		</div>
	</div>

		
	<!-- START MODALI -->


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
//import SelectLingue  from '@/components/utils/SelectLingue.vue'

export default {
    name:"ConfTravelNotifiche",		

    components:{
        Pagine,
        ISwitch,
		//SelectLingue 
    
    },

    data: function () {
        return {   
            apiStartup:'shared/notifiche/allutente',
			filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
			arrayNotifiche : new Array(),
			search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	          	ID_NOTIFICA:{TYPE:'TEXT',VALUE:''},
				TITOLO:{TYPE:'TEXT',VALUE:''},
				DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
				DATA_LETTURA:{TYPE:'TEXT',VALUE:''},
				FLG_AZIONE:{TYPE:'COMBO',VALUE:'1'},	 
                FK_ID_MODULO:{TYPE:'COMBO',VALUE:''},
                
            },

            arrayTipologieNotifiche : new Array(),
			notificaSelezionata : {},
            modificaTipologiaNotifica:0,
            currentLang:0,
			searchTypo:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                TITOLO:{TYPE:'TEXT',VALUE:''},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
                FK_ID_MODULO:{TYPE:'COMBO',VALUE:''}, 
                FLG_ATTIVO:{TYPE:'COMBO',VALUE:1}, 
            },
        }
		
    },

    methods: {
		getNotificheAll() {
            utils.ajax(this.apiStartup, {FK_ID_UTENTE : this.$root.utente.ID_UTENTE}, (response) => {
                if (response.esito == 0) {
					this.arrayNotifiche = response.data;
                }
            });
        },

        updateStatoNotifica : function(notifica){
            setTimeout(() => {
                utils.ajax('shared/notifiche/stato/update',notifica, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }  
                }); 
            },250);
        },

        getTipologieNotifiche: function () {
            utils.ajax("shared/notifiche/tipologie",{}, (response) => {
                this.arrayTipologieNotifiche = response.data;
            });
        },

		nuovaTipologiaNotifica : function(){
			this.arrayTipologieNotifiche.push({ID_TIPOLOGIA_NOTIFICA:0,TITOLO:'',DESCRIZIONE:'',FK_ID_MODULO:0,FLG_ATTIVO:1});
		},

		salvaTipologiaNotifica : function(notificaSelezionata){
            console.log(notificaSelezionata);
			this.notificaSelezionata.FK_ID_MODULO = this.notificaSelezionata.ID_OPZIONE_TIPOLOGICA;
			var api = notificaSelezionata.ID_TIPOLOGIA_NOTIFICA == 0  ? "shared/notifica/tipologica/insert" : "shared/notifica/tipologica/update";
			utils.ajax(api,notificaSelezionata, (response) => {
				if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getTipologieNotifiche();
                }
            });
		},

		eliminaTipologiaNotifica : function (notificaSelezionata){
			utils.alert.confirm("Sei sicuro di voler eliminare questo incasso?",()=>{
				utils.ajax("shared/notifica/tipologica/delete",notificaSelezionata, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
					}
				});
            },this.$root);
		},


    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayNotifiche,this.search);
            return arr;
        },
        paginatedListTypo(){
            var arr = utils.inGrid(this.arrayTipologieNotifiche,this.searchTypo);
            return arr;
        },

    },

    created: function () {
		this.getNotificheAll();
        this.getTipologieNotifiche();
    },

}
</script>
