<template>

    <!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
                    <a href="#" class="text-dark fw-semibold cursor-pointer" v-on:click="getCardsInsurance()"> Tessere </a> \ 
                    <span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Assicurazioni</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;" v-on:click="newCardInsurance()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1 ms-1">
							<div class="fs-sm text-muted mb-1">
								<Translate :path="'dizionario.base.BTN_NUOVA'"></Translate> Assicurazione
							</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(48)" title="Opzioni Card" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(48)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(48)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
	        </div> 
		</div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" v-on:click="openRoute('/CardsSeason')">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Stagioni
    						</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#insurances-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-shield me-2"></i><span class="d-none d-lg-inline-block">Elenco Assicurazioni</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#insurances-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity me-2"></i><span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate></span>
							</div>
						</a>
					</li>
				</ul>  
				
				<a class="rounded btn bg-danger bg-opacity-20 btn-sm-light me-2" v-on:click="getCardsInsurance()" v-show="ordineHasChanged">Annulla ordinamento</a>
				<a class="rounded btn bg-success bg-opacity-20 btn-sm-light" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</a>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Assicurazioni selezionate</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutte le Assicurazioni</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina selezionate</a>
								</div>
							</div>
						</li>
						<li class="nav-item ms-3">
	                        <select class="form-select form-select-sm changeyear" id="comboAnno" @change="getCardsInsurance()" v-model="search.ANNO.VALUE" :tabindex="4">
	                            <option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
	                        </select>
						</li>
					</ul>
			   </div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="insurances-b">
				<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="insurance-b">
									<div class="row ">
										<div class="col-4 col-md-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Assicurazioni" :tabindex="1" />
										</div> 
										<div class="col-8 col-md-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Descrizione" :tabindex="1"  />
										</div> 	
										<div class="col-md-7 mbx-1">
		
										</div>    
										<div class="col-6 col-md-1 col-lg-1">
											<select class="form-select form-select-sm" data-placeholder="Stato" v-model="search.FLG_ATTIVO.VALUE" :tabindex="7">
												<option value="">Stato </option>   
												<option value="1"> &nbsp; Attivate</option>
												<option value="0"> &nbsp; Disattivate</option>
											</select>
										</div>
										<div class="col-6 col-md-1 col-lg-1">
			                                <a href="#collapse-a" class="btn btn-flat-info btn-sm w-100 collapsed" data-bs-toggle="collapse" v-on:click="filters.resizeGrid('collapse-a','tableFixHead','box-card')" role="button" aria-expanded="false">
												+ FILTRI
											</a>
										</div>  
									</div>
								</form>
							</div> 
						</div>
					</div>
		
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="tableFixHead" id="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
								<thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th rowspan="2" class="" style="width:2%;"> <input id="chkAllInsurances" v-on:click="filters.selectAll('chkAllInsurances',paginatedList.arr)" type="checkbox" class=""> </th>
										<th rowspan="2" class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
										<th rowspan="2" class="table-head-sm" style="width:6%;"> #<br /><span class="float-end">Ord. </span></th>
										<th rowspan="2" class="table-head-sm text-center" style="width:4%;"> Img </th>
										<th rowspan="2" class="table-head-sm">Titolo Assicurazione /<br />Stagione </th>
										<th colspan="3" class="table-head-sm text-center">Importi </th>
										<th rowspan="2" class="table-head-sm text-center" style="width:5%;">Stato</th>
										<th rowspan="2" class="table-head-sm text-center" style="width:5%;">Azioni</th>
									</tr>
									<tr class="bg-secondary text-white">
										<th class="table-head-sm text-center" style="width:10%;">Prezzo</th>
										<th class="table-head-sm text-center" style="width:10%;">Online</th>
										<th class="table-head-sm text-center" style="width:10%;">Costo</th>
									</tr>
								</thead>
								<draggable tag="tbody" @end="ordineChangeHandler($event)">
									<tr v-for="insurance in paginatedList.arr" v-bind:key="insurance.ID_INSURANCE">
										<td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="insurance.SELEZIONATO" class=""> 
										</td>
		                                <td class="td-class text-center">
											<img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" />
										</td>
										<td class="td-class fs-sm">
											<span>{{insurance.ID_INSURANCE}}</span><br />
											<small class="badge bg-light text-primary float-end"> </small>
										</td>
										<td class="text-center">
											<a :href="$root.GLOBAL_IMAGES_PATH+'/CATEGORIA/'+insurance.ID_INSURANCE+'.jpg'" data-popup="lightbox">
												<img :src="$root.GLOBAL_IMAGES_PATH+'/CATEGORIA/'+insurance.ID_INSURANCE+'.jpg'" class="rounded-circle me-2" height="24" alt="">
											</a>
										</td>
										<td class="td-class fs-sm">
											<span class="text-secondary fw-bold">{{insurance.ID_INSURANCE}} - </span>
											<span class="text-primary fw-bold cursor-pointer" v-on:click="modificaInsurance(insurance)">DESCRIZIONE</span><br />	                                   
											<span class="badge bg-dark me-1">CODICE_PRODOTTO</span> 
											<span class="badge badge-info me-1">Metti in Evidenza</span> 
										</td>
										<td class="td-class text-center fs-sm">1</td>
										<td class="td-class text-center fs-sm">1</td>
										<td class="td-class text-center fs-sm">1</td>
										<td class="td-class text-center fs-sm">
											<ISwitch @toggle="updateStatoInsurance(insurance)" :size="'sm'" v-model="insurance.FLG_ATTIVO" style="margin-top:6px"></ISwitch>
										</td>
		                                <td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="modificaInsurance(insurance)"><i class="fal fa-pencil fa-fw"></i> Modifica Tessera</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="openPageRange(insurance)"><i class="far fa-list fa-fw"></i> Gestione Range</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteInsurance(insurance)"><i class="far fa-trash-alt fa-fw text-danger"></i> Elimina Tessera</a>
												</div>
											</div>
										</td>
									</tr>
								</draggable>
							</table>
						</div>

		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
					            <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">ASSICURAZIONI: {{ paginatedList.count }}</span> 
					            </div>
					        </div>
							<div class="col-9 col-md-6" :tabindex="27">
								<div class="float-end">
		                            <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
					        <div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>	
					</div>	
				</div>
			</div>



			<div class="tab-pane fade" id="insurances-c">
        		
				<Statistiche :modalita="tipo == 1 ? 2 : 3 "></Statistiche> 

			</div> 

		</div>		
	</div>


	<!-- START MODALI -->
    <div class="modal fade" id="popUpCardInsurance" role="dialog" tabindex="-1">
	    <div class="modal-dialog">
	        <div class="modal-content">
	            <div class="modal-header bg-secondary text-white border-0">
					<h5 class="modal-title">Menu</h5>
					<button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
	            </div>
				<div class="modal-body">



                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI </button>
                    <button type="button" class="btn btn-secondary float-end" v-on:click="saveCardInsurance()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>                
            </div>
        </div>
    </div>

        
</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import { VueDraggableNext } from 'vue-draggable-next'


export default {
    name:"CardsInsurance",  

    components:{
        Pagine,
        ISwitch,
        draggable: VueDraggableNext,

    },	 

    data: function (){
      	return{
            apiStartup:'travel/cards/insurances',
	        filters:global.filters,	
            GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            arrayCardsInsurance:new Array(),
            cardInsuranceSelezionata:new Array(),
	        search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                ANNO:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
			    FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'},  

	        },	 
			rs : utils.generateRandomString(30),
			ordineHasChanged: false,

      	}
		
    },

    methods: {
        getCardsInsurance: function () {
            utils.ajax(this.apiStartup,{ANNO:this.search.ANNO.VALUE}, (response) => {
				this.ordineHasChanged = false;
                this.arrayCardsInsurance = response.data;
            }); 
        },

        openCardInsurance : function(insurance){
            this.$root.cardInsuranceSelezionata = utils.clone(insurance);
            global.router.push("CardInsurance");
        },

        newCardInsurance : function(){ 
			this.cardInsuranceSelezionata = {ID_INSURANCE:0,FLG_ATTIVO:1};
            this.filters.openPopUp("popUpCardInsurance");  
        },

        editCardInsurance : function(insurance){
            this.cardInsuranceSelezionata = utils.clone(insurance);
            this.filters.openPopUp("popUpCardInsurance");  
        },

        saveInsurance : function(){
            var api = this.cardInsuranceSelezionata.ID_INSURANCE > 0 ? "travel/insurance/update" : "travel/insurance/insert";
            utils.ajax(api,this.cardInsuranceSelezionata, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.filters.closePopUp("popUpCardInsurance"); 
                    utils.ajax(this.apiStartup,{}, (response) => {
                        this.arrayCardsInsurance = response.data;
                    });
                }
            });
        },

		openPageRange:function(insurance){
			this.$root.cardInsuranceSelezionata = utils.clone(insurance);
			global.router.push("Range");
		},

        updateStatoCardInsurance : function(insurance){
            setTimeout(() => {
                utils.ajax('travel/cards/insurances/stato/update',insurance, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }  
                }); 
            },250);
        },

		updateCardInsuranceFlgVisibile : function(task){
			type.FLG_VISIBILE = type.FLG_VISIBILE == 1 ? 0 : 1;
			utils.ajax("travel/cards/insurances/update",type, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					type.FLG_VISIBILE = type.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
		},
		
		openRoute : function(route){
			global.router.push(route);
		},

      	esportazione : function(formato){
        	utils.report.token({modalita:'esportaCardInsurance',formato:formato});
      	},

		ordineChangeHandler : function(event){
			this.arrayStati = this.filters.draggableChangeHandler(event,this.arrayCardsInsurance,this.search,"ID_INSURANCE");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arrayCardsInsurance.length ; i++){
					arr.push({"ID": this.arrayCardsInsurance[i].ID_INSURANCE,"O": this.arrayCardsInsurance[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'CardsInsurance',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getCardsInsurance();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},

        
    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayCardsInsurance,this.search);
            return arr;
        },	

        getTotali(){
			var attive = 0;
            var inattive = 0; 
			for (var i = 0; i < this.arrayCardsInsurance.length; i++) {
				if (this.arrayCardsInsurance[i].FLG_ATTIVO == 1) {
					attive++;
				} else {
					inattive++;
				}
            }
            return {
                COUNT:this.arrayCardsInsurance.length,
				ADVOK:attive,
				ADVKO:inattive
            }
        },

    },

	watch:{

	},

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH; 
		this.ordineHasChanged = false;
        this.getCardsInsurance();
    }

}
</script>