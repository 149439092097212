<template>
    <template v-if="type == 'nav-superiore'">
        <a v-if="!item.children?.length && item.FLG_TIPOLOGIA == 1" href="#" class="dropdown-item" @click.prevent="$emit('route-click', item.ROUTE || '#')" >
            <div v-html="item.ICONA"></div><span class="text-muted me-1">|</span>{{ item.DESCRIZIONE }}
        </a>

        <div v-else-if="item.children.length > 0 && item.FLG_TIPOLOGIA == 1" class="dropdown-submenu">
            <a v-if="item.FLG_TIPOLOGIA == 1" href="#" class="dropdown-item dropdown-toggle"  @click.prevent="$emit('route-click', item.ROUTE || '#')">
                <div v-html="item.ICONA"></div>
                <span class="text-muted me-1">|</span>
                {{ item.DESCRIZIONE }}
            </a>
            <div v-else class="dropdown-divider"></div>
            <div class="dropdown-menu">
                <SubMenu
                    v-for="child in item.children"
                    :key="child.ID_ITEM"
                    :item="child"
                    :type="type"
                    @route-click="onRouteClick">
                </SubMenu>
            </div>
        </div>
        <div v-else class="dropdown-divider"></div>
    </template>

    <template v-else-if="type === 'nav-laterale'">
        <li class="nav-item" :class="{'nav-item-submenu': item.children?.length}">
            <a href="#" class="nav-link" @click.prevent="$emit('route-click', item.ROUTE || '#')">
                <div v-html="item.ICONA"></div><span>{{ item.DESCRIZIONE }}</span>
            </a>
            <ul v-if="item.children?.length" class="nav-group-sub collapse">
                <SubMenu
                    v-for="child in item.children"
                    :key="child.ID_ITEM"
                    :item="child"
                    :type="type"
                    @route-click="onRouteClick">
                </SubMenu>  
            </ul>
        </li>
    </template>

</template>
  
<script>
export default {

    name: 'SubMenu',

    emits: ['route-click'],

    props: {
        item: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: 'nav-superiore',
        },
    },

    methods: {
        onRouteClick(route) {
            this.$emit('route-click', route)
        }
    }

  }
</script>
  