<template>

    <input type="text" :placeholder="placeholder" v-on:keyup="digitHandler()" class="form-control">
    
</template>

<script>

    import moment from 'moment';

    export default {

        name: 'DatePicker',
        data:function(){
            return{
                datepicker:null,
            }
        },
        props: {
            placeholder: {
                type: String,
                default: ''
            }
        },

        mounted() {
            this.datepicker = $(this.$el).pickadate({
                min: true,
                editable: true,
                selectYears: true,
                selectMonths: true,
                format: 'dd/mm/yyyy',
                formatSubmit:'dd/mm/yyyy',
                min: "01/01/1900",
                max: "31/12/"+new Date().getFullYear()+2,
                selectYears: 200,

                onSet: (context) => {
                    var d = new Date(context.select);
                    var m = moment(String(d)).format('DD/MM/YYYY');
                    console.log(m);
                    this.$emit('update:modelValue', m);
                    setTimeout(() => {
                        global.$(this.$el).val(m);
                    },150)
                }
            });
            if (this.$attrs.modelValue != undefined){
                var v = this.$attrs.modelValue;
                var date = moment(v,"DD/MM/YYYY",true);
                var d = date._d;
                var picker =  $(this.$el).pickadate('picker');
                picker.set('select', d)
                /*
                setTimeout(() => {
                    global.$(this.$el).val(v);
                    //this.datepicker.datepicker('update', new Date(v));
                },150)*/
            }
        },
        updated : function(){
                var v = this.$attrs.modelValue;
                var date = moment(v,"DD/MM/YYYY",true);
                var d = date._d;
                var picker =  $(this.$el).pickadate('picker');
                picker.set('select', d)
            /*
            console.log("updated");
            console.log(this.$attrs.modelValue);
            
            var v = this.formatDateReverseFromString(this.$attrs.modelValue);
            this.datepicker.datepicker('update', new Date(v));*/
        // global.$(this.$el).val(this.$attrs.modelValue);
        },
        watch:{
            modelValue: {
                handler(val) {
                    console.log("asd"); 
                },
                deep: true
            }
        },
        methods: {
            digitHandler : function(){
                var date = moment($(this.$el).val(),"DD/MM/YYYY",true);
                console.log(date);
                if (date.isValid()){
                    var d = date._d;
                    var m = moment(String(date)).format('DD/MM/YYYY');
                    this.$emit('update:modelValue', m);
                    var picker =  $(this.$el).pickadate('picker');
                    picker.set('select', d);
                   
                }
                /*
                else if ($(this.$el).val() == ""){
                    this.$emit('update:modelValue', global.$(this.$el).val());
                }*/
            },

           
            editOptions : function(options){
                global.$(this.$el).datepicker("option", options);
            },
            setBeforeShowDay : function(giorniattivi){
                var f = (datee) =>  {
                    if (this.dateAbilitate == undefined){
                        return true;
                    }
                    var dmy = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
                    if(this.dateAbilitate.indexOf(dmy) != -1){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                this.editOptions({beforeShowDay:f});
            },
        

        }
      

    }

</script>