<template>

    <div class="card shadow mb-xs-3 mb-lg-0">
        <div class="card-header d-lg-none p-1">
            <div class="d-grid gap-2">
                <a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
                    VEDI FILTRI
                </a>
            </div>  
        </div>
        <div class="collapse show" id="collapse-all" style="">				
            <div class="card-body pb-0">
                <div class="alert alert-2 alert-secondary border-0">
                    <form autocomplete="off" action="#" id="utente-a">
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-2">
                                <strong>Cartella:</strong> {{ folderStyle(folderSelezionato.name) }}
                            </div>
                            <div class="col-6 col-md-1 col-lg-2 mbx-1">
                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Inviata da" v-model="search.FROM.VALUE" :tabindex="7" @keyup.enter="getEmails" />
                            </div>
                            <div class="col-6 col-md-1 col-lg-2 mbx-1">
                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Oggetto" v-model="search.OGGETTO.VALUE" :tabindex="7" @keyup.enter="getEmails" />
                            </div> 
                            <div class="col-6 col-md-1 col-lg-2 mbx-1">
                                <select v-model="search.CRITERIO.VALUE" @change="getEmails()" class="form-select form-select-sm">
                                    <option value="">Seleziona un filtro</option>
                                    <option v-for="(criteria, index) in arrayCriteria" :key="index" :value="criteria.key">
                                        {{ criteria.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-6 col-md-1 col-lg-2">
                            </div>
                            <div class="col-6 col-md-1 col-lg-1">

                            </div>
                            <div class="col-6 col-md-1 col-lg-1">
                                <button type="button" class="btn btn-primary" v-on:click="getEmails">
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div> 
                        </div>	
                    </form>
                </div>
            </div> 
        </div>

        <div class="card-body box-card mt-0 mb-0" id="box-card">
            <div class="div_table_resp tableFixHead" id="tableFixHead">
                <table class="table table-bordered table-hover table-striped table-togglable text-nowrap"> 
                    <thead class="sticky-top">
                        <tr class="bg-secondary text-white" style="height: 41px;">
                            <th class="table-head-sm text-center" style="width:2%;"> <input id="chkAllEmails" v-on:click="filters.selectAll('chkAllEmails',paginatedList.arr)" type="checkbox" class=""> </th>
                            <th class="table-head-sm text-center" style="width:2%;" title="Attach"> #<br /><span class="float-end"> </span></th>
                            <th class="table-head-sm text-center" style="width:4%;"> Img </th>
                            <th class="table-head-sm" style="width:20%;">Da</th>
                            <th class="table-head-sm">Oggetto</th>
                            <th class="table-head-sm text-center" style="width:8%;">Ricevuto</th>
                            <th class="table-head-sm text-center" style="width:2%;" title="Vista">R</th>
                            <th class="table-head-sm text-center" style="width:2%;" title="Bookmark"> i</th>
                            <th class="table-head-sm text-center" style="width:2%;" title="Elimina">D</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(email,index) in paginatedList.arr" :key="index">
                            <td class="td-class text-center">
                                <span class="footable-toggle"></span>
                                <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="email.SELEZIONATO" class=""> 
                            </td>
                            <td class="td-class text-center">
                                <div v-if="email.ALLEGATI == true" style="display: inline-block;">
                                    <i class="ph-paperclip text-muted"></i>
                                </div>
                            </td>
                            <td class="td-class text-center">
                                <div :class="[
                                        'd-inline-flex',
                                        'align-items-center',
                                        'justify-content-center',
                                        email.FROM ? filters.getColorForEmail(email.FROM) : 'bg-primary',
                                        'text-white',
                                        'lh-1',
                                        'w-20px',
                                        'h-20px',
                                        'rounded-pill'
                                    ]">
                                    <span class="letter-icon">
                                        {{ email.FROM ? filters.getInitials(email.FROM) : '--' }}
                                    </span>
                                </div>
                            </td>
                            <td class="td-class fs-sm">
                                <div class="letter-icon-title text-body">
                                    {{ getNameEmail(email.FROM) }} <br> 
                                    {{ getEmailString(email.FROM) }}
                                </div>
                            </td>
                            <td class="td-class fs-sm" v-on:click=" handleEmailClick(email)">
                                <div class="cursor-pointer" :class="{'fw-bold' : email.VISTA == false}">{{ email.OGGETTO }}</div>  
                                <span class="text-muted fw-normal"></span>
                            </td>
                            <td class="td-class fs-sm">
                                <span> {{ email.DATA ? filters.formatDayWithDateTime(email.DATA) : '' }} </span><br />
                                <span> &nbsp; </span>
                            </td>
                            <td class="td-class text-center">
                                <a v-if="email.VISTA == false" href="#" v-on:click="markAsSeen(email)"><i class="far fa-envelope fa-fw text-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Segna come letta"></i></a>
                                <a v-else href="#" v-on:click="markAsUnseen(email)"><i class="far fa-envelope-open fa-fw text-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Segna come non Letta"></i></a>
                            </td>
                            <td class="td-class text-center">
                                <button class="btn btn-default p-0" v-on:click="markAsImportant(email)">
                                    <i class="far fa-star" :class="email.IMPORTANTE == true ? 'text-warning' : 'text-secondary'" data-bs-toggle="tooltip" data-bs-placement="top" title="Segna come Importante"></i>
                                </button>
                            </td>
                            <td class="td-class text-center">
                                <a v-if="this.folderSelezionato.name == 'INBOX.Trash'" href="#" v-on:click="openRestoreModal(email)"><i class="far fa-undo-alt fa-fw text-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Ripristina Email"></i></a>
                                <a href="#" v-on:click="folderSelezionato.name == 'INBOX.Trash' ? deleteEmail(email) : openPopUpDelete(email)"><i class="far fa-trash fa-fw text-danger" data-bs-toggle="tooltip" data-bs-placement="top" title="Elimina Email"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>            
            </div>

            <div class="float-end">
                <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="pagination" @update:modelValue="handlePageChange"></Pagine>
            </div>

        </div>
        <!-- /action toolbar -->
    </div>


    <!-- MODAL -->
    <div class="offcanvas offcanvas-end offcanvas-size-xlg" id="readEmailOffcanvas" role="dialog" data-bs-backdrop="false" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 3.95rem;">
			<h5 class="offcanvas-title fw-semibold">{{ emailSelezionata.FROM }}</h5>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"></button>
		</div>

		<div class="offcanvas-body p-2">

            <ReadMail :ref="'readMail'"></ReadMail>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <div style="position: relative; top: 2px;">
                 <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>
            </div>       
        </div>  
	</div>

    <div class="offcanvas offcanvas-end offcanvas-size-xlg" id="writeEmailOffcanvas" role="dialog" data-bs-backdrop="false" tabindex="-1">
        <div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
            <h5 class="offcanvas-title fw-semibold">Invio Email</h5>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"></button>
        </div>
        <div class="offcanvas-body p-2">
            <WriteEmail ref="writeEmail" :email="emailSelezionata" @close="closeOffCanvas('writeEmailOffcanvas')"></WriteEmail>
        </div>
    </div> 


	<div class="modal fade" id="alertAllegati" role="dialog" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title"><i class="fal fa-key fa-fw"></i> Allegati della Mail</h6>  
                    <button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
                </div>
				<div class="modal-body">

                    <div class="alert alert-warning alert-dismissible fade show">
                        <i class="ph-warning-circle me-2"></i>
                        <span class="fw-semibold">Attenzione agli allegati!</span>
                        <p style="margin-left: 1.9rem; margin-bottom: 0;">Assicurati che il mittente sia affidabile prima di aprire qualsiasi file.</p>
                    </div>
                    <div class="card-body">
                        <h6 class="mb-2"> {{ allegatiSelezionati.length }} {{ allegatiSelezionati.length > 1 ? 'Allegati' : 'Allegato' }}</h6>
                        <div class="hstack gap-2 flex-wrap">
                            <div class="border rounded p-2 mt-2" v-for="(allegato, index) in allegatiSelezionati" :key="index">
                                <div class="d-inline-flex align-items-center fw-semibold my-1 text-truncate">
                                    <i class="ph-file-pdf text-danger me-2"></i>
                                    <a href="#" @click="openAllegatiOnChrome(allegato)">
                                        <span class="fw-semibold text-truncate">{{ allegato.nome }}</span>
                                    </a>
                                    <span class="fs-sm fw-normal text-muted ms-3">X</span>
                                </div>
                                <p class="fs-sm fw-normal text-muted mb-0" style="margin-left: 1.9rem;">Dimensioni del file (174 KB)</p>
                            </div>
                        </div>
                    </div>

                </div>
				<div class="modal-footer bg-light p-1"> 
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
				</div>
            </div>
        </div>
    </div>
    

	<div class="modal fade" id="popUpDeleteConfirm" role="dialog" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title"><i class="fa fa-fw fa-key fa-fw"></i> Elimina Definitivamente oppure Sposta nel Cestino</h6>  
                    <button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
                </div>
				<div class="modal-body">
    
                    <div class="row text-center">
                        <div class="col-12 col-md-6 mb-3">
                            <div class="card bg-warning text-white hover-effect cursor-pointer" v-on:click="moveToTrash(this.emailSelezionata)">
                                <div class="card-body">
                                    <i class="fas fa-trash-alt fa-3x mb-3"></i>
                                    <h5 class="card-title">Sposta nel Cestino</h5>
                                    <p class="small mb-0">Archivia il documento nel cestino</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="card bg-danger text-white hover-effect cursor-pointer" v-on:click="deleteEmail(this.emailSelezionata)">
                                <div class="card-body">
                                    <i class="fas fa-skull-crossbones fa-3x mb-3"></i>
                                    <h5 class="card-title">Elimina Definitivamente</h5>
                                    <p class="small mb-0">Cancellazione permanente dal sistema</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
				<div class="modal-footer bg-light p-1"> 
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
				</div>
            </div>
        </div>
    </div>

</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ReadMail from '@/components/ReadMail.vue';
import WriteEmail from '@/components/WriteEmail.vue';

export default {
    name:"AllMails",
    
    emits:[
            'emailSelezionata', 
            'criterioCambiato', 
            'flgCambiato', 
            'vistaCambiata', 
            'openCompose', 
            'paginationChanged', 
            'openRestoreModal', 
            'updateFolderCounter',
            'email-important-changed'
        ],

    components:{
        Pagine,
        //DatePicker,
        ReadMail,
        WriteEmail,
    },	

    props:{
        arrayEmails: {
            type: Array,
            required: true,
        },
        arrayFolders: {
            type: Array,
            required: true,
        },
        arrayCriteria: {
            type: Array,
            required: true,
        },
        folderSelezionato:{
            type: Object,
            required: true,
        },
        totalCount:{
            type: Number,
            required: true,
        },
        pagination:{
            type: Number,
            required: true,
        },
        archivioFolderFullPath: {
            type: String,
            required: true,
        },
        trashFolderFullPath: { 
            type: String, 
            required: true 
        }
    },

    data:function(){
        return {
            apiStartup:'core/imap/getUserEmails',
            filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
            allegatiSelezionati: new Array(),
            criterioSelezionato:'',
            localEmails: [...this.arrayEmails],
            emailSelezionata:{},
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                FROM:{TYPE:'TEXT',VALUE:''},
                OGGETTO:{TYPE:'TEXT',VALUE:''},
                CRITERIO:{TYPE:'COMBO',VALUE:''},

	        },
            composingReply: false,
            currentReply: null,
            currentThread: [],
            selectedRestoreFolder: "",
        }
    },

    methods:{
        getEmails() {
            let criterioParts = [];
            if (this.search.CRITERIO.VALUE) {
                criterioParts.push(this.search.CRITERIO.VALUE);
            }
            if (this.search.FROM.VALUE.trim()) {
                criterioParts.push(`FROM "${this.search.FROM.VALUE.trim()}"`);
            }
            if (this.search.OGGETTO.VALUE.trim()) {
                criterioParts.push(`SUBJECT "${this.search.OGGETTO.VALUE.trim()}"`);
            }
            const criterio = criterioParts.length > 0 ? criterioParts.join(' ') : 'ALL'; 
            console.log('Criterio di ricerca:', criterio); 
            this.$emit('criterio-cambiato', { 
                criterio: criterio,
                folder: this.folderSelezionato.name 
            });
        },

        handlePageChange(newPage) {
            const start = (newPage - 1) * this.search.PAGINATION.PAGE_SIZE;
            const limit = this.search.PAGINATION.PAGE_SIZE;
            this.$emit('pagination-changed', {
            page: newPage,
            start: start,
            limit: limit,
            totalCount: this.totalCount,
            });
        },

        openAllegatiOnChrome(allegato){
            utils.openFileOnChrome(allegato);
        },

        openAllegati(allegati) {
            this.allegatiSelezionati = [...allegati];
            this.filters.openPopUp("alertAllegati");
        },

        openSchedaEmail(email) {
            this.emailSelezionata = utils.clone(email);
            if (this.folderSelezionato.name == "INBOX.Drafts") {
                this.openOffCanvasForWrite();
            } else {
                this.openOffCanvasForRead();
                this.$refs.readMail.getSchedaEmail(this.emailSelezionata.ID_EMAIL);
                this.markAsSeen(this.emailSelezionata);
            }
            this.$nextTick(() => {
                const offcanvasEl = document.getElementById('readEmailOffcanvas');
                if (offcanvasEl) {
                    const offcanvasBody = offcanvasEl.querySelector('.offcanvas-body');
                    if (offcanvasBody) {
                    offcanvasBody.scrollTop = 0;
                    }
                }
            });
        },
        
        openOffCanvasForRead() {
            const offcanvasEl = document.getElementById('readEmailOffcanvas');
            const bsOffcanvas = new bootstrap.Offcanvas(offcanvasEl);
            bsOffcanvas.show();
        },

        openOffCanvasForWrite(email = null) {
            if (!email) {
                email = {
                    FROM: this.$root.utente.EMAIL,
                    TO: '',
                    OGGETTO: '',
                    CORPO: '',
                    ID_EMAIL: null,
                    ATTACHMENTS: [],
                };
            }
            const offcanvasEl = document.getElementById('writeEmailOffcanvas');
            const bsOffcanvas = new bootstrap.Offcanvas(offcanvasEl);
            bsOffcanvas.show();
            this.$refs.writeEmail.localEmail = utils.clone(email);
            this.$refs.writeEmail.isDraftNew = !email.ID_EMAIL;
        },

        markAsSeen(email){
            utils.ajax('core/imap/email/seen',{FK_ID_ENTE: 1, ID_UTENTE: this.$root.utente.ID_UTENTE, ID_EMAIL : email.ID_EMAIL, FOLDER: this.folderSelezionato.name}, (response) => {
                if(response.esito == 0){
                    const index = this.localEmails.findIndex(e => e.ID_EMAIL == email.ID_EMAIL);
                    if(index != -1){
                        this.localEmails[index] = {...this.localEmails[index], VISTA : true};
                        this.$emit('email-important-changed');
                    }
                }
            });
        },
        restoreEmail(email,destinationFolder) {
            const wasUnread = !email.VISTA;
            this.$emit('update-folder-counter', {
                folder: this.trashFolderFullPath,
                totalDelta: -1,
                unreadDelta: wasUnread ? -1 : 0
            });
            this.$emit('update-folder-counter', {
                folder: this.destinationFolder,
                totalDelta: +1,
                unreadDelta: wasUnread ? +1 : 0
            });
            utils.ajax('core/imap/email/move/restore', {
                FOLDER: this.folderSelezionato.name,
                DESTINATION_FOLDER : this.folderStyleForGet(destinationFolder),
                FK_ID_ENTE: 1,
                ID_UTENTE: this.$root.utente.ID_UTENTE,
                ID_EMAIL: email.ID_EMAIL
            }, (response) => {
                if (response.esito == 0) {
                this.getEmails();
                this.filters.closePopUp("restoreFolderModal")
                utils.alert.success("Email ripristinata con successo");
                }
            });
        },

        moveEmail(email) {
            const wasUnread = !email.VISTA;
            this.$emit('update-folder-counter', {
                folder: this.folderSelezionato.name,
                totalDelta: -1,
                unreadDelta: wasUnread ? -1 : 0
            });
            this.$emit('update-folder-counter', {
                folder: this.selectedRestoreFolder,
                totalDelta: +1,
                unreadDelta: wasUnread ? +1 : 0
            });
            utils.ajax('core/imap/email/move/restore', {
                FOLDER: this.folderSelezionato.name,
                DESTINATION_FOLDER : this.folderStyleForGet(this.selectedRestoreFolder),
                FK_ID_ENTE: 1,
                ID_UTENTE: this.$root.utente.ID_UTENTE,
                ID_EMAIL: email.ID_EMAIL
            }, (response) => {
                if (response.esito == 0) {
                this.getEmails();
                utils.alert.success("Email ripristinata con successo");
                }
            });
        },

        folderStyle : function (folder){
            if(folder.includes("INBOX.")){
                return folder.split("INBOX.")[1];
            }else{
                return "inBox"
            }
        },

        folderStyleForGet : function (folder){
            return folder.split("}")[1];
        },


        markAsUnseen(email){
            utils.ajax('core/imap/email/unseen', {FK_ID_ENTE: 1, ID_UTENTE: this.$root.utente.ID_UTENTE, ID_EMAIL : email.ID_EMAIL, FOLDER: this.folderSelezionato.name}, (response) => {
                if(response.esito == 0){
                    const index = this.localEmails.findIndex(e => e.ID_EMAIL == email.ID_EMAIL);
                    if(index != -1){
                        this.localEmails[index] = {...this.localEmails[index], VISTA : false};
                    }
                }
            });
        },

        markAsImportant(email) {
            const oldState = email.IMPORTANTE;
            email.IMPORTANTE = !oldState;
            const action = email.IMPORTANTE ? 'important' : 'unimportant';
            if (email.IMPORTANTE) {
                const inboxTotalDelta = -1;
                const inboxUnreadDelta = (!email.VISTA) ? -1 : 0;
                const archivioTotalDelta = +1;
                this.$emit('update-folder-counter', { 
                    folder: this.folderSelezionato.fullPath, 
                    totalDelta: inboxTotalDelta, 
                    unreadDelta: inboxUnreadDelta
                });
                this.$emit('update-folder-counter', { 
                    folder: this.archivioFolderFullPath, 
                    totalDelta: archivioTotalDelta
                });
            } else {
                const archivioTotalDelta = -1;
                const inboxTotalDelta = +1;
                const inboxUnreadDelta = (!email.VISTA) ? +1 : 0;
                // Aggiorna Archivio
                this.$emit('update-folder-counter', { 
                    folder: this.archivioFolderFullPath, 
                    totalDelta: archivioTotalDelta
                });
                this.$emit('update-folder-counter', { 
                    folder: this.inboxFolderFullPath, 
                    totalDelta: inboxTotalDelta, 
                    unreadDelta: inboxUnreadDelta
                });

            }
            
            utils.ajax('core/imap/email/' + action, {
                FK_ID_ENTE: 1,
                ID_UTENTE: this.$root.utente.ID_UTENTE,
                ID_EMAIL: email.ID_EMAIL,
                FOLDER: email.FOLDER
            }, (response) => {
                if (response.esito == 0) {
                    utils.alert.success(
                        action === 'important'
                        ? "L'email è stata spostata in Archivio"
                        : "L'email è stata riportata in INBOX"
                    );
                    this.$emit('flg-cambiato');
                } else {
                    email.IMPORTANTE = oldState;
                    utils.alert.error("Errore nell'aggiornamento dello stato di importanza");
                }
            });
        },

        openPopUpDelete(email){
            this.emailSelezionata = utils.clone(email);
            this.filters.openPopUp("popUpDeleteConfirm");
        },

        moveToTrash(email) {
            const wasUnread = !email.VISTA;
            this.$emit('update-folder-counter', {
                folder: this.folderSelezionato.fullPath, 
                totalDelta: -1,
                unreadDelta: wasUnread ? -1 : 0
            });
            this.$emit('update-folder-counter', {
                folder: this.trashFolderFullPath,
                totalDelta: +1
            });
            utils.ajax('core/imap/email/move/trash', {
                FOLDER: this.folderSelezionato.name,
                FK_ID_ENTE: 1,
                ID_UTENTE: this.$root.utente.ID_UTENTE,
                ID_EMAIL: email.ID_EMAIL
            }, (response) => {
                if (response.esito == 0) {
                    this.getEmails();
                    utils.alert.success("Email spostata nel Cestino");
                    this.filters.closePopUp("popUpDeleteConfirm");
                } else {
                    utils.alert.error("Errore nello spostamento dell'email");
                }
            });
        },

        deleteEmail : function(email){
            utils.alert.confirm("Sei sicuro di voler eliminare definitivamente l'email selezionata?",()=>{
                let payload = {
                    FK_ID_ENTE: 1, 
                    ID_UTENTE: this.$root.utente.ID_UTENTE, 
                    ID_EMAIL: email.ID_EMAIL,
                    FOLDER: email.FOLDER 
                }
                let url = 'core/imap/email/server/delete';
                utils.ajax(url,payload, (response) => {
                    if(response.esito == 0){
                        this.getEmails();
                        this.filters.closePopUp('popUpDeleteConfirm');
                        utils.alert.success('Email eliminata definitivamente dal server');
                    }
                }); 
            },this.$root);
        },

        getNameEmail : function(from){
            if(!from) return "";
            const match = from.match(/^(.*?)\s*<.*?>$/);
            return match ? match[1] : from;
        },

        getTagEmail : function(from){
            if(!from) return "";
            const match = from.match(/^(.*?)\s*<.*?>$/);
            var result = match ? match[1] : from;
            var words = result.split(/(?=[A-Z])|\s+/);
            var iniziali = words.map(word => word.charAt(0).toUpperCase()).join("");
            return iniziali
        },

        getEmailString : function(from){
            if(!from) return "";
            const match = from.match(/<(.*?)>/);
            return match ? match[1] : from;
        },

        handleEmailClick(email) {
              this.openSchedaEmail(email);
        }, 
        
        closeOffCanvas(offcanvasId) {
            const offcanvasEl = document.getElementById(offcanvasId);
            if(offcanvasEl){
                let offCanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasEl);
                if(offCanvasInstance){
                    offCanvasInstance.hide();
                }
            }
        },
        openRestoreModal(email) {
            this.$emit("open-restore-modal", email);
        },
    
    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.localEmails,this.search);
            return arr;
               
        },
    }, 

    created : function(){
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
        this.utenteCorrente = utils.clone(this.$root.utente)
    },
    
    watch: {
        folderSelezionato(newVal, oldVal) {
            if (newVal && newVal.name && (!oldVal || newVal.name !== oldVal.name)) { 
                this.criterioSelezionato = '';  
                this.getEmails();
            }
        },
        arrayEmails:{
            handler(newVal){
                this.localEmails = [...newVal];
            },
            deep:true,
            immediate:true
        }
    }
}
</script>

