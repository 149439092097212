<template>
        
    <!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
                    <a href="#" class="d-none d-lg-inline-block text-dark fw-semibold cursor-pointer" v-on:click="getCostiNonLavorati()"> Contabilità </a>  <span class="d-none d-lg-inline-block">\</span>
                    <span class="text-muted ms-1"><Translate :path="'dizionario.base.GESTIONE'"></Translate> Costi</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
						
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;">
						
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(10)" title="Opzioni Contabilità" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(10)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(10)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
            </div>
        </div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#costi-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7"></i>
								<span class="d-lg-none ms-1">Costi</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.GESTIONE'" class="me-1"></Translate>Costi</span>
							</div>
						</a>
					</li>
					<li class="nav-item d-lg-none ms-auto">
						<a href="#profile_nav" class="navbar-nav-link navbar-nav-link-icon rounded-pill rounded-pill-small collapsed" data-bs-toggle="collapse" aria-expanded="false">
							<i class="ph-caret-down collapsible-indicator"></i>
						</a>
					</li>
				</ul>

			    <Datepicker autocomplete="off" class="ms-lg-auto bg-light text-center mt-xs-1" style="width:320px;" v-model="intervalloDate" range multi-calendars multi-calendars-solo :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" :partial-range="false" placeholder="Data Ordine da > a" :tabindex="2" />
			    <span class="btn btn-primary btn-sm ms-1" v-on:click="getCostiNonLavorati()">AGGIORNA</span>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0 select-small">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
									<i class="ph-gear me-1"></i> Azioni Multiple
									<span class="d-lg-none ms-2">More</span>
								</a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<a class="dropdown-item cursor-pointer" v-on:click="generaScadenzari()"><i class="far fa-cash-register fa-fw me-2"></i><span class="text-muted me-1">|</span>Genera Scandenzari</a>
								</div>
							</div>
						</li>
						<li class="nav-item">
	
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="costi-a">
				<div class="card shadow mb-xs-3 mb-lg-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="costo-a">
									<div class="row">
										<div class="col-4 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="filtra x Ordine" :tabindex="2" v-model="search.PNR.VALUE"  />
										</div>
										<div class="col-8 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="filtra x Fornitore" :tabindex="2" v-model="search.ALIAS.VALUE" />
										</div> 
										<div class="col-12 col-md-2 col-lg-2 mbx-1">
											<select class="form-select form-select-sm" data-placeholder="Stato Pratica" v-model="search.FK_ID_STATO.VALUE" :tabindex="14">
												<option v-bind:value="''">filtra x stato</option>
												<option v-for="stato in $root.arrayPraticaStato" v-bind:key="stato.ID_OPZIONE_TIPOLOGICA" v-bind:value="stato.ID_OPZIONE_TIPOLOGICA"> - {{ stato.DESCRIZIONE }}</option>  
											</select>
										</div>
										<div class="col-12 col-md-4 col-lg-4 mbx-1">
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>

					<div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="div_table_resp box-33" id="tableFixHead">
							<table class="table table-bordered table-togglable text-nowrap"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2.3%;"> <input type="checkbox" class="" id="chkAllFornitori" v-on:click="selectAllFornitori()"> </th>
										<th class="table-head-sm">Fornitore<br >&nbsp;</th>
										<th class="table-head-sm" style="width:15%;">Importi</th>
										<th class="table-head-sm" style="width:18%;">Stato Quota</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td colspan="4" class="fs-sm" style="background-color: white;">
											<div class="accordion" id="accordion_collapsed">

												<div class="accordion-item" v-for="fornitore in paginatedList.arr" v-bind:key="fornitore.ID_AZIENDA">
													<h2 class="accordion-header bg-light" style="height: 2.75rem !important;">										
														<div class="row">	
															<div class="col-md-8" style="line-height: 2.1rem;">	
																<input type="checkbox" class="ms-1 me-3" v-bind:true-value="1" v-bind:false-value="0" v-model="fornitore.SELEZIONATO" :id="'chkAllOrdini_'+fornitore.ID_AZIENDA" v-on:click="filters.selectAll('chkAllOrdini_'+fornitore.ID_AZIENDA,filteredQuote(fornitore.ARRAY_QUOTE))">
																<button class="accordion-button fw-semibold" style="display: contents;" type="button" data-bs-toggle="collapse" :data-bs-target="'#accordion_fornitore'+fornitore.ID_AZIENDA" aria-expanded="true">
																	<span>{{ fornitore.ALIAS }}</span>
																</button>
															</div>	
															<div class="col-md-2">
																<span class="btn link-success border-success cursor-none fs-lg p-1 m-1 me-3 float-end">
																	Tot. residuo &euro; {{ filters.formattaImporto(getSommaCostiFornitoreFiltrati(fornitore)) }}
																</span>
															</div>	
															<div class="col-md-2"></div>
														</div>
													</h2>
													<div :id="'accordion_fornitore'+fornitore.ID_AZIENDA" class="accordion-collapse collapse show" style="">
														<div class="accordion-body pt-0">
															<table class="table table-bordered table-togglable text-nowrap m-1 ms-3">
																<tr v-for="quota in filteredQuote(fornitore.ARRAY_QUOTE)" v-bind:key="quota.ID_QUOTA">
																	<td class="td-class" style="width: 2%;"><input v-on:click="checkPadre(fornitore)" type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="quota.SELEZIONATO" class=""></td>
																	<td class="td-class" style="width: 8%;"><span class="text-primary fw-bold cursor-pointer" v-on:click="openOffCanvas();openSchedaOrdine(quota)">{{ filters.getPnr(quota) }}</span></td>
																	<td class="td-class" style="">{{ quota.DESCRIZIONE }}</td>
																	<td class="td-class" style="width: 13%; text-align: right;"><span class="text-dark me-2">&euro; {{ quota.COSTO_TOTALE }}</span></td>																					 
																	<td class="td-class" style="width: 18%;">
																		<span class="badge bg-secondary text-secondary bg-opacity-20 badge-xs text-body">{{ filters.getLabelOpzione(quota.FK_ID_STATO) }}</span> 
																	</td>
																</tr>
															</table>
														</div>
													</div>
												</div>

											</div>	
										</td>
									</tr>								
								</tbody>
							</table>
						</div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
								<div style="position: relative; top: 10px;">
									<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">PRATICHE: {{ paginatedList.count }}</span> 
								</div>
                          	</div>
							<div class="col-9 col-md-6" :tabindex="27">
								<div class="float-end pag-mobile">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH"></Pagine>
								</div>
						    </div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
			        	</div>

					</div>
				</div>
			</div>


        </div>
	</div>


	<!-- START MODALI -->
    <div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right_ordini" role="dialog" data-bs-backdrop="false" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
			<h5 class="offcanvas-title fw-semibold">{{ ordineSelezionato.TITOLO_PRATICA }}</h5>
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">

			<SchedaOrdine :ref="'schedaOrdine'" @selezionaProdotto="openSchedaProdotto($event)" ></SchedaOrdine>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <div style="position: relative; top: 2px;">
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="LORDO"><strong class=" me-1">L:</strong> € {{ filters.formattaImporto(ordineSelezionato.PREZZO_TOTALE) }}</span>
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="COMMISSIONE"><strong class=" me-1">A:</strong> € {{ filters.formattaImporto(ordineSelezionato.COMMISSIONI) }}</span>
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="NETTO"><strong class=" me-1">N:</strong> € {{ filters.formattaImporto(ordineSelezionato.PREZZO_TOTALE - ordineSelezionato.COMMISSIONI) }}</span>
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="COSTO"><strong class=" me-1">C:</strong> € {{ filters.formattaImporto(ordineSelezionato.COSTO_TOTALE) }}</span>
                <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>
            </div>       
        </div>  
	</div> 

	<div class="modal fade" id="popUpMovimento" role="dialog" tabindex="-1">
	    <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 85% !important;">Scheda Prodotto</h6>   
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div> 
                <div class="modal-body p-2" style="height: 32rem;"> 

						
                    
					<div class="row">
						<div class="col-md-3">
							<label class="form-label text-danger mb-1">Importo:</label>
							<div class="input-group">
								<span class="input-group-text"><i class="far fa-user fa-fw"></i></span>
								<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="movimentoSelezionato.IMPORTO" :tabindex="4">
							</div>						
						</div>
						<div class="col-md-3">
							<label class="form-label text-danger mb-1">Conto:</label>
							<div class="input-group">
								<span class="input-group-text"><i class="far fa-user fa-fw"></i></span>
								<select class="form-control" v-model="movimentoSelezionato.FK_ID_CONTO">
									<option v-for="conto in filters.getOpzioniTipologiche(3)" v-bind:value="conto.ID_OPZIONE_TIPOLOGICA" v-bind:key="conto.ID_OPZIONE_TIPOLOGICA">{{ conto.DESCRIZIONE }}</option>
								</select>
							</div>						
						</div>
						<div class="col-md-3">
							<label class="form-label text-danger mb-1">Descrizione:</label>
							
							<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="movimentoSelezionato.DESCRIZIONE" :tabindex="4">
											
						</div>
						<div class="col-md-3">
							<label class="form-label text-danger mb-1">Data Pagamento:</label>
							<div class="input-group">
								<span class="input-group-text"><i class="far fa-user fa-fw"></i></span>
								<Datepicker v-model="movimentoSelezionato.DATA_MOVIMENTO" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" :tabindex="7"></Datepicker>
							</div>						
						</div>
					</div>
        
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i>Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaMovimento()"><i class="fal fa-save fa-fw me-1"></i>Salva Modifiche </button>                                   
                </div>                
            </div>
        </div>
    </div>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import Datepicker from '@vuepic/vue-datepicker' 
import SchedaOrdine from '@/components/SchedaOrdine.vue'


export default {
    name:"Costi",		 

    components:{
        Pagine,
        Datepicker,
		SchedaOrdine,
    },		   

    data:function(){
        return {
            apiStartup:'travel/scadenzari',
            filters:global.filters,
			rs : utils.generateRandomString(30),
			GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            ordineSelezionato:{},
			arrayFornitori:new Array(),
			movimentoSelezionato:{},
			arrayScadenzari : new Array(),
			scadenzarioSelezionato:{quote:new Array()},
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ALIAS:{TYPE:'TEXT',VALUE:''},
				PNR:{TYPE:'TEXT_IN_ARRAY',VALUE:'',CUSTOM_ARRAY:'ARRAY_QUOTE'},
				FK_ID_STATO:{TYPE:'COMBO_IN_ARRAY',VALUE:'',CUSTOM_ARRAY:'ARRAY_QUOTE'},
	        },
			searchScadenzari:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ALIAS:{TYPE:'TEXT',VALUE:''},
	        },
			intervalloDate:{},
			dataStart:'',
			dataEnd:'',

        }
    },

    methods:{
		getCostiNonLavorati : function(){
			global.utils.ajax('travel/costinonlavorati',{DATA_START:this.dataStart,DATA_END:this.dataEnd,FK_ID_MODULO:1}, (response,status) => {    
				this.arrayFornitori = response.data;
			});
		},  
    
		selectAllFornitori : function () {
			var selezionato = document.getElementById("chkAllFornitori").checked == true ? 1 : 0;
			for (var i = 0 ; i < this.arrayFornitori.length ; i++){
				this.arrayFornitori[i].SELEZIONATO = selezionato;
				for (var j = 0 ; j < this.arrayFornitori[i].ARRAY_QUOTE.length ; j++){
					this.arrayFornitori[i].ARRAY_QUOTE[j].SELEZIONATO = selezionato;
				}
			}
		},	 

		checkPadre: function(fornitore){
			utils.wait(()=>{
				var xx = utils.array.select(fornitore.ARRAY_QUOTE,"SELEZIONATO",1,1);
				if (xx.length > 0){
					fornitore.SELEZIONATO = 1;
				}
				else{
					fornitore.SELEZIONATO = 0;
				}
			});

		},

		getScadenzari : function(){
			global.utils.ajax('travel/scadenzari/list',{}, (response,status) => {    
				this.arrayScadenzari = response.data;
			});
		},	   

		generaScadenzari :function () {
			var arrayFornitori = utils.array.select(this.arrayFornitori,"SELEZIONATO",1);
			global.utils.ajax('travel/scadenzari/genera',arrayFornitori, (response,status) => {    
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
					this.getCostiNonLavorati();
				}
			});
		},

		openPopUpMovimento : function(scadenzario){
			this.movimentoSelezionato = {
				ID_MOVIMENTO:0,
				FK_ID_SCADENZARIO:scadenzario.ID_SCADENZARIO,
				IMPORTO:scadenzario.IMPORTO,
				SEGNO:'-',
				FK_ID_ENTE:this.$root.idEnteCorrente,
				FK_ID_AZIENDA_FORNITORE:scadenzario.FK_ID_AZIENDA_FORNITORE};
				this.filters.openPopUp("popUpMovimento");
		},

		openOffCanvas : function(){
            var myOffcanvas = document.getElementById('panel_right_ordini')
            var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
            bsOffcanvas.show();
        },

		openSchedaOrdine : function(ordine){
            this.ordineSelezionato = utils.clone(ordine);
            this.$refs.schedaOrdine.openSchedaOrdine(ordine);
        },
		
		salvaMovimento : function(){
			global.utils.ajax('travel/movimento/insert',this.movimentoSelezionato, (response,status) => {    
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
					this.filters.closePopUp("popUpMovimento");
				}
			});

        },

		filteredQuote : function(arrayQuote){
			var arr = new Array();
			if (this.search.PNR.VALUE != ''){
				const filtroPnr = this.search.PNR.VALUE.toLowerCase();
				for (var i = 0 ; i < arrayQuote.length ; i++){
					if (arrayQuote[i].PNR.toString().toLowerCase().includes(filtroPnr)){
						arr.push(arrayQuote[i]);
					}
				}
			}
			else{
				arr = arrayQuote;
			}
			var arrayFinale = new Array();
			if (this.search.FK_ID_STATO.VALUE != ''){
				const filtroStato = this.search.FK_ID_STATO.VALUE;
				for (var i = 0 ; i < arr.length ; i++){
					if (arr[i].FK_ID_STATO == filtroStato){
						arrayFinale.push(arr[i]);
					}
				}
				return arrayFinale;
			}
			else{
				return arr;
			}
		},

		getSommaCostiFornitoreFiltrati : function(fornitore){
			var arr = this.filteredQuote(fornitore.ARRAY_QUOTE);
			var importo = 0;
			for (var i = 0 ; i < arr.length ; i++){
				importo += parseFloat(arr[i].COSTO_TOTALE);
			}
			return importo;
		},

		openRoute : function(route){
            global.router.push(route);
        },	


    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayFornitori,this.search);            
            return arr;
        },

		paginatedListScadenzari(){
            var arr = utils.inGrid(this.arrayScadenzari,this.searchScadenzari);            
            return arr;
        },
    }, 

	watch : {
		intervalloDate : function(valore,valoreOld){
            if (this.intervalloDate != null && this.intervalloDate != undefined){ 
                this.dataStart = this.filters.formatDate(this.intervalloDate[0]);
                this.dataEnd = this.filters.formatDate(this.intervalloDate[1]);
            }
        },
	},

    created : function(){
        
    },
	
}
</script>
