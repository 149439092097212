<template>
        
    <!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
                    <a href="#" class="d-none d-lg-inline-block text-dark fw-semibold cursor-pointer"> Contabilità </a>  <span class="d-none d-lg-inline-block">\</span>
                    <span class="text-muted ms-1"><Translate :path="'dizionario.base.GESTIONE'"></Translate> Costi</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
						
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;">
						
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(10)" title="Opzioni Contabilità" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(10)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(10)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
            </div>
        </div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#scadenzari-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1" v-on:click="getScadenzari()">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7"></i>
								<span class="d-lg-none ms-1">Scadenzari</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.GESTIONE'" class="me-1"></Translate>Scadenzari</span>
							</div>
						</a>
					</li>
					<li class="nav-item d-lg-none ms-auto">
						<a href="#profile_nav" class="navbar-nav-link navbar-nav-link-icon rounded-pill rounded-pill-small collapsed" data-bs-toggle="collapse" aria-expanded="false">
							<i class="ph-caret-down collapsible-indicator"></i>
						</a>
					</li>
				</ul>


				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0 select-small">
						<li class="nav-item">
	
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="scadenzari-a">  
				<div class="card shadow mb-xs-3 mb-lg-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="scadenzario-a">
									<div class="row">
										<div class="col-8 col-md-2 col-lg-2 mbx-1">
											<input type="text" class="form-control form-control-sm" placeholder="filtre x Fornitore" v-model="search.ALIAS.VALUE">
										</div> 
										<div class="col-4 col-md-2 col-lg-2 mbx-1">
											<select class="form-select form-select-sm" placeholder="filtre x Stato" v-model="search.SALDATO.VALUE">
												<option value="">Tutti</option>
												<option value="0">Non Saldato</option>
												<option value="1">Saldato</option>
											</select>
										</div>
										<div class="col-12 col-md-4 col-lg4 mbx-1">

										</div>
										<div class="col-12 col-md-4 col-lg-4 mbx-1">

										</div>
									</div>
								</form>
							</div>
						</div>
					</div>

					<div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="div_table_resp tableFixHead" id="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable text-nowrap"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
	                                    <th class="table-head-sm" style="width: 6%;"> #<br /><span class="float-end">Ord. </span></th>
	                                    <th class="table-head-sm"> Fornitore </th>
										<th class="table-head-sm text-center" style="width: 10%;">Totale<br />Importo</th>
	                                    <th class="table-head-sm text-center" style="width: 10%;">Totale<br />Pagato </th>
										<th class="table-head-sm text-center" style="width: 10%;">Totale<br />Residuo</th>
										<th class="table-head-sm text-center" style="width: 10%;">Data<br />Scadenza</th>
										<th class="table-head-sm text-center" style="width: 12%;">Stato</th>
	                                    <th class="table-head-sm text-center" style="width: 5%;"> Azioni </th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                <tr v-for="scadenzario in paginatedList.arr" v-bind:key="scadenzario.ID_SCADENZARIO">
                                        <td class="td-class fs-sm">
											<span>{{ scadenzario.ID_SCADENZARIO }}</span><br />
											<span>&nbsp;</span>
										</td>
	                                    <td class="td-class fs-sm">
											<span class="text-primary fw-bold text-uppercase cursor-pointer" v-on:click="openPopUpQuote(scadenzario)">{{ scadenzario.ALIAS }}</span>
	                                    </td>
	                                    <td class="td-class text-center fs-sm">
											<span>&euro;</span> {{ scadenzario.IMPORTO }}
	                                    </td>
	                                    <td class="td-class text-center fs-sm">
											<span>&euro;</span> {{ scadenzario.PAGATO }}
	                                    </td>
										<td class="td-class text-center fs-sm">
											<div class="fw-bold" v-bind:class="{'text-danger': scadenzario.RESIDUO > 0,'text-success': scadenzario.RESIDUO == 0}">&euro; {{ scadenzario.RESIDUO }}</div>
										</td>
										<td class="td-class text-center">
											<div class="fw-bold">April 18</div>
											<span class="fs-sm text-muted">2015</span>
										</td>
	                                    <td class="td-class text-center fs-sm">
											<span class="badge badge-xs" v-bind:class="{'bg-danger' : scadenzario.SALDATO == 0, 'bg-success':scadenzario.SALDATO == 1} ">
												<span v-if="scadenzario.SALDATO == 0">Non Saldato</span>
												<span v-else>Saldato</span>
											</span>
	                                    </td>
										<td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
	                                                <div class="dropdown-header text-uppercase font-size-xs line-height-xs">Movimenti</div>
	                                                <a href="#" class="dropdown-item" v-on:click="openPopUpMovimento(scadenzario)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Genera Movimento</a>
	                                                              
	                                            </div>
	                                        </div>
		                                </td>
	                                </tr>
	                            </tbody>
	                        </table>
		                </div>

		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">PRATICHE: {{ paginatedList.count }}</span> 
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end pag-mobile">
		                            <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH"></Pagine>
		                        </div>
		                    </div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
		                	</div>
		            	</div>
		
		            </div>
        		</div>
			</div>		


        </div>
	</div>


	<!-- START MODALI -->
	<div class="modal fade" id="popUpMovimento" role="dialog" tabindex="-1">
	    <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 85% !important;">Scheda Prodotto</h6>   
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div> 
                <div class="modal-body p-2"> 
                    
			
					<div class="row">
						<div class="col-md-6 mb-3">
							<label class="form-label">Importo:</label>	
							<div class="input-group">
								<span class="input-group-text"><i class="far fa-euro-sign fa-fw"></i></span>
								<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="movimentoSelezionato.IMPORTO" :tabindex="4">
							</div>
						</div>
						<div class="col-md-6 mb-3">
							<label class="form-label">Data Pagamento:</label>	
							<Datepicker v-model="movimentoSelezionato.DATA_MOVIMENTO" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" :tabindex="7"></Datepicker>
						</div>
					</div>	

					<div class="row">
						<div class="col-md-6 mb-3">
							<label class="form-label">Conto:</label>
							<div class="input-group">
								<span class="input-group-text"><i class="far fa-money-check-edit-alt fa-fw"></i></span>
								<select class="form-select form-select-sm" v-model="movimentoSelezionato.FK_ID_CONTO">
									<option v-for="conto in filters.getOpzioniTipologiche(3)" v-bind:value="conto.ID_OPZIONE_TIPOLOGICA" v-bind:key="conto.ID_OPZIONE_TIPOLOGICA">{{ conto.DESCRIZIONE }}</option>
								</select>
							</div>
						</div>
						<div class="col-md-6 mb-3">
							<label class="form-label">Movimento:</label>
							<div class="input-group">
								<span class="input-group-text"><i class="far fa-donate fa-fw"></i></span>
								<select class="form-select form-select-sm" v-model="movimentoSelezionato.FK_ID_CONTO">
									<option v-for="conto in filters.getOpzioniTipologiche(3)" v-bind:value="conto.ID_OPZIONE_TIPOLOGICA" v-bind:key="conto.ID_OPZIONE_TIPOLOGICA">{{ conto.DESCRIZIONE }}</option>
								</select>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 mb-3">
							<label class="form-label">Descrizione:</label>
							<div class="input-group">
								<span class="input-group-text">@</span>
								<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="movimentoSelezionato.DESCRIZIONE" :tabindex="4">
							</div>
						</div>
					</div>

                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i>Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaMovimento()"><i class="fal fa-save fa-fw me-1"></i>Salva Modifiche </button>                                   
                </div>                
            </div>
        </div>
    </div>

	<div class="modal fade" id="popUpScadenzarioQuote" role="dialog" tabindex="-1">
	    <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 85% !important;">Scheda elenco Ordini</h6>   
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div> 
                <div class="modal-body p-2" style="height: 32rem;">

					<h5 class="label-bar"><i class="fad fa-shopping-bag fa-fw me-1"></i> Titolo Fornitore</h5>
					<div class="div_table_resp">
						<table class="table table-bordered table-togglable text-nowrap"> 
							<thead class="sticky-top">
								<tr class="bg-secondary text-white">
									<th class="table-head-sm" style="width: 9%;"> #<br /><span class="float-end">Ord. </span></th>
									<th class="table-head-sm"> Fornitore </th>
									<th class="table-head-sm text-center" style="width: 12%;">Partenza<br />Ritorno</th>
									<th class="table-head-sm text-center" style="width: 11%;">Totale<br />Importo</th>
									<th class="table-head-sm text-center" style="width: 11%;">Totale<br />Pagato </th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="quota in scadenzarioSelezionato.quote" v-bind:value="quota.ID_SCADENZARIO" v-bind:key="quota.ID_SCADENZARIO">
									<td class="td-class fs-sm">
										{{ quota.FK_ID_ORDINE }}<br />
										<span>&nbsp;</span>
									</td>
									<td class="td-class fs-sm">{{ quota.DESCRIZIONE_PADRE }}<br />{{ quota.DESCRIZIONE }}</td>
									<td class="td-class text-center fs-sm">{{ quota.DATA_START }}<br />{{ quota.DATA_END }}</td>
									<td class="td-class text-center fs-sm">&euro; {{ quota.PREZZO_TOTALE }}</td>
									<td class="td-class text-center fs-sm">&euro; {{ quota.COSTO_TOTALE }}</td>
								</tr>
							</tbody>
						</table>
					</div>
			<!--
			ID_QUOTA": 5663, 
			"FK_ID_QUOTA_PRINCIPALE": 149814, 
			"FK_ID_ORDINE": 12579, 
			"FK_ID_PRODOTTO": 1254, 
			"FK_ID_MODULO": 2, 
			"FK_ID_SERVIZIO": 0, 
			"FK_ID_OPZIONE": 493, 
			"FK_ID_PRICE": 0, 
			"FK_ID_AZIENDA_FORNITORE": 8613, 
			"FK_ID_STATO": 450, 
			"DESCRIZIONE_PADRE": "FRUIT VILLAGE SHARM EL SHEIKH Amphoras Beach", 
			"DESCRIZIONE": "Camera Superior Vista Giardino", 
			"PREZZO_TOTALE": "723.75", 
			"COSTO_TOTALE": "364.00", 
			"COMMISSIONI": "0.00", 
			"COMMISSIONI_ATTIVE": "0.00", 
			"IMPORTO_EXTRA": "0.00", 
			"MODALITA_DI_CALCOLO": 
			"TOTALE_A_PERSONA", 
			"DATA_START": "2024-09-21", 
			"DATA_END": "2024-09-28", 
			"TOT_ADU": 1, 
			"TOT_CHD": 0, 
			"TOT_INF": 0, 
			"FLG_EXTRA_FACOLTATIVO": 0, 
			"FLG_EXTRA_OBBLIGATORIO": 0, 
			"FLG_VISIBILE": 1, 
			"FLG_STAMPABILE": 1, 
			"FLG_CONFERMATA": 1, 
			"FLG_QUOTA_SOLO_COSTI": 0, 
			"FLG_DOCUMENTI": 1, 
			"FLG_VOUCHER": 1, 
			"FK_ID_TIPO_FISCALE": 3, 
			"FK_ID_IVA_PREZZO": 25, 
			"FK_ID_IVA_COSTO": 25, 
			"FK_ID_IVA_COMMISSIONI": 12, 
			"FK_ID_IVA_COMMISSIONI_ATTIVE": 12, 
			"FLG_CATTURA_EASY": 0, 
			"DATA_CATTURA": "2024-08-01 19:01:49", 
			"NOTE": "", 
			"DATI_RICERCA": "", 
			"FK_ID_GRUPPO": 0, 
			"FK_ID_GENERICO": 0, 
			"CUSTOM_1": "", 
			"CUSTOM_2": "", 
			"FK_ID_CAMPO_OLD": 346020, 
			"FK_ID_SCADENZARIO": 1, 
			"FK_ID_QUOTA": 5663, 
			"IMPORTO": "0.00"
			-->


                </div>
                <div class="modal-footer bg-light p-2" style="display: block;">
					<div class="row">
						<div class="col-12 col-md-8 d-none d-sm-block" :tabindex="27">
							<div style="position: relative; top: 4px;">
								<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="LORDO"><strong class=" me-1">L:</strong> € </span>
								<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="COMMISSIONE"><strong class=" me-1">A:</strong> € </span>
								<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="NETTO"><strong class=" me-1">N:</strong> € </span>
							</div>
						</div>
						<div class="col-9 col-md-4" :tabindex="27">
							<button type="button" class="btn btn-link float-end" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i>Chiudi </button>
						</div>	
					</div>
                </div>                
            </div>
        </div>
    </div>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'


export default {
    name:"Scadenzari",		 

    components:{
        Pagine,
    },		   

    data:function(){
        return {
            apiStartup:'travel/scadenzari',
            filters:global.filters,
			rs : utils.generateRandomString(30),
			GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
			movimentoSelezionato:{},
			arrayScadenzari : new Array(),
			scadenzarioSelezionato:{quote:new Array()},
			search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ALIAS:{TYPE:'TEXT',VALUE:''},
				SALDATO:{TYPE:'COMBO',VALUE:''},
	        },

        }
    },

    methods:{ 
		getScadenzari : function(){
			global.utils.ajax('travel/scadenzari/list',{}, (response,status) => {    
				this.arrayScadenzari = response.data;
			});
		},

		openPopUpMovimento : function(scadenzario){
			this.movimentoSelezionato = {
				ID_MOVIMENTO:0,
				FK_ID_SCADENZARIO:scadenzario.ID_SCADENZARIO,
				IMPORTO:scadenzario.IMPORTO,
				SEGNO:'-',
				FK_ID_ENTE:this.$root.idEnteCorrente,
				FK_ID_AZIENDA_FORNITORE:scadenzario.FK_ID_AZIENDA_FORNITORE};
				this.filters.openPopUp("popUpMovimento");
		},
		
		salvaMovimento : function(){
			global.utils.ajax('travel/movimento/insert',this.movimentoSelezionato, (response,status) => {    
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
					this.filters.closePopUp("popUpMovimento");
					this.getScadenzari();
				}
			});

        },

		openPopUpQuote : function(scadenzario){
			global.utils.ajax('travel/scadenzario',scadenzario, (response,status) => { 
				this.scadenzarioSelezionato = utils.clone(response.data);
				this.filters.openPopUp("popUpScadenzarioQuote");
			});
		},


    },

    computed: {
		paginatedList(){
            var arr = utils.inGrid(this.arrayScadenzari,this.search);            
            return arr;
        },
    }, 

	watch : {

	},

    created : function(){
        this.getScadenzari();
    },
	
}
</script>
