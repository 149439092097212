<template>

	<b class="screen-overlay"></b>

	<!-- Page content -->
	<div class="page-content" style="height: 100%;">

		<!-- Main sidebar -->
		<div class="sidebar sidebar-dark sidebar-main sidebar-expand-lg" v-bind:class="{'sidebar-expand-lg':utente.ID_UTENTE > 0}" v-show="utente.ID_UTENTE > 0">

			<!-- Sidebar header -->
			<div class="sidebar-section bg-black bg-opacity-10 border-bottom border-bottom-white border-opacity-10">
				<div class="sidebar-logo d-flex justify-content-center align-items-center">
					<router-link  class="d-inline-flex align-items-center py-2" href="#" :to="'Dashboard'">
						<img src="./assets/images/logo_icon_light.png" class="sidebar-logo-icon" alt="">
                        <img src="./assets/images/logo_light.png" class="sidebar-resize-hide ms-3" height="22" alt="">
					</router-link>

					<div class="sidebar-resize-hide ms-auto">
						<button id="btnCollapseMain" type="button" class="btn btn-flat-white btn-icon btn-sm rounded-pill border-transparent sidebar-control sidebar-main-resize d-none d-lg-inline-flex">
							<i class="ph-arrows-left-right"></i>
						</button>

						<button type="button" class="btn btn-flat-white btn-icon btn-sm rounded-pill border-transparent sidebar-mobile-main-toggle d-lg-none">
							<i class="ph-x"></i>
						</button>
					</div>
				</div>
			</div>
			<!-- /sidebar header -->

			<!-- Sidebar content -->
			<div class="sidebar-content" v-show="utente.ID_UTENTE > 0">

				<div class="sidebar-section">
					<div class="sidebar-section-body d-flex justify-content-center">
					</div>
				</div>

				<!-- Main navigation -->
				<div class="sidebar-section">
					<ul class="nav nav-sidebar" data-nav-type="accordion">

						<!-- Main -->
						<li class="nav-item-header pt-0">
							<div class="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide">Main</div>
							<i class="ph-dots-three sidebar-resize-show"></i>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Dashboard') == true}" v-on:click="openRoute('/Dashboard')">
								<i class="ph-house"></i>
								<span>Cruscotto</span>
							</a>
						</li>

						<!-- UTENTI -->
						<li class="nav-item-header">
							<div class="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide">Utenti</div>
							<i class="ph-dots-three sidebar-resize-show"></i>
						</li>
						<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('UtentiClienti') == true}" v-on:click="openRoute('/UtentiClienti')"><i class="ph-users"></i><span>Gestione Clienti</span></a></li>
						<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Agenzie') == true}" v-on:click="openRoute('/Agenzie')"><i class="ph-layout"></i><span>Gestione Agenzie</span></a></li>
						<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Fornitori') == true}" v-on:click="openRoute('/Fornitori')"><i class="ph-address-book"></i><span>Gestione Fornitori</span></a></li>
						<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('UtentiContatti') == true}" v-on:click="openRoute('/UtentiContatti')"><i class="ph-users"></i><span>Gestione Contatti</span></a></li>

						<!-- PRODOTTO TURISTICO -->
						<li class="nav-item-header">
							<div class="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide">Prodotto</div>
							<i class="ph-dots-three sidebar-resize-show"></i>
						</li>
						<li class="nav-item nav-item-submenu">
							<a href="#" class="nav-link">
								<i class="ph-buildings"></i>
								<span>Elenco Prodotti</span>
							</a>
							<ul class="nav-group-sub collapse">
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Activities') == true}" v-on:click="openRoute('/Activities')"><i class="fal fa-bullhorn fa-fw fa-lg"></i><span>Gestione Attivit&agrave;</span></a></li>			
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Hotels') == true}" v-on:click="openRoute('/Hotels')"><i class="fal fa-concierge-bell fa-fw fa-lg"></i><span>Gestione Hotels</span></a></li>	
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Packages') == true}" v-on:click="openRoute('/Packages')"><i class="fal fa-suitcase-rolling fa-fw fa-lg"></i><span>Gestione Pacchetti</span></a></li>			
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Flights') == true}" v-on:click="openRoute('/Flights')"><i class="fal fa-paper-plane fa-fw fa-lg"></i><span>Gestione Trasporti</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Extra') == true}" v-on:click="$root.prodotto={ID_PRODOTTO:0},openRoute('/Extra')"><i class="fal fa-paper-plane fa-fw fa-lg"></i><span>Gestione Extra</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Varianti') == true}" v-on:click="openRoute('/Varianti')"><i class="fal fa-paper-plane fa-fw fa-lg"></i><span>Gestione Varianti</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('CardsSeason') == true}" v-on:click="openRoute('/CardsSeason')"><i class="fal fa-credit-card fa-fw fa-lg"></i><span>Gestione Tessere</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Shops') == true}" v-on:click="openRoute('/Shops')"><i class="fal fa-shopping-cart fa-fw fa-lg"></i><span>Gestione Shops</span></a></li>
							</ul>
						</li>

						<!-- GESTIONE GESTIONE -->
						<li class="nav-item-header">
							<div class="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide">Gestione</div>
							<i class="ph-dots-three sidebar-resize-show"></i>
						</li>
						<li class="nav-item nav-item-submenu">
							<a href="#" class="nav-link">
								<i class="ph-globe-hemisphere-west"></i>
								<span>Gestione Destinazioni</span>
							</a>
							<ul class="nav-group-sub collapse" style="">
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('GeoStati') == true}" v-on:click="openRoute('/GeoStati')"><i class="fal fa-map-marker-alt fa-fw fa-lg"></i><span>Elenco Nazioni</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('GeoRegioni') == true}" v-on:click="openRoute('/GeoRegioni')"><i class="fal fa-cloud fa-fw fa-lg"></i><span>Elenco Regioni</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('GeoProvince') == true}" v-on:click="openRoute('/GeoProvince')"><i class="fal fa-asterisk fa-fw fa-lg"></i><span>Elenco Province</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('GeoComuni') == true}" v-on:click="openRoute('/GeoComuni')"><i class="fal fa-home fa-fw fa-lg"></i><span>Elenco Comuni</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('GeoAree') == true}" v-on:click="openRoute('/GeoAree')"><i class="fal fa-tag fa-fw fa-lg"></i><span>Elenco Aree Nielsen</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('GeoAeroporti') == true}" v-on:click="openRoute('/GeoAeroporti')"><i class="fal fa-location-arrow fa-fw fa-lg"></i><span>Porti / Aeroporti</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Airlines') == true}" v-on:click="openRoute('/Airlines')"><i class="ph-star-four"></i><span>Gestione Compagnie</span></a></li>
							</ul>
						</li> 

						<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Categorie') == true}" v-on:click="openRoute('/Categorie')"><i class="ph-folder-notch-open"></i><span>Gestione Categorie</span></a></li>
						
						<li class="nav-item nav-item-submenu">
							<a href="#" class="nav-link">
								<i class="ph-notepad"></i>
								<span>Elenco Contenuti</span>
							</a>
							<ul class="nav-group-sub collapse" style="">
								<!--<li class="nav-item" v-show="filters.hasFunzionalita(45)"><a href="#" class="nav-link" v-on:click="openRoute('/Contenuti')"><i class="fal fa-file-alt fa-fw fa-lg"></i><span>Gestione Contenuti</span></a></li>	-->
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Pages') == true}" v-on:click="openRoute('/Pages')"><i class="fal fa-file fa-fw fa-lg"></i><span>Gestione Pagine</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Blogs') == true}" v-on:click="openRoute('/Blogs')"><i class="fal fa-blog fa-fw fa-lg"></i><span>Gestione Blog</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('News') == true}" v-on:click="openRoute('/News')"><i class="fal fa-newspaper fa-fw fa-lg"></i><span>Gestione News</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Manuals') == true}" v-on:click="openRoute('/Manuals')"><i class="fal fa-comment-alt fa-fw fa-lg"></i><span>Gestione Manuali</span></a></li>
							</ul>
						</li>

						<!-- GESTIONE MODULI -->
						<li class="nav-item-header">
							<div class="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide">Moduli</div>
							<i class="ph-dots-three sidebar-resize-show"></i>
						</li>
						<li class="nav-item nav-item-submenu">
							<a href="#" class="nav-link">
								<i class="ph-squares-four"></i>
								<span>Gestione Moduli</span>
							</a>
							<ul class="nav-group-sub collapse" style="">
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Banners') == true}" v-on:click="openRoute('/Banners')"><i class="fal fa-ad fa-fw fa-lg"></i><span>Gestione Pubblicit&agrave;</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Coupons') == true}" v-on:click="openRoute('/Coupons')"><i class="fal fa-recycle fa-fw fa-lg"></i><span>Gestione Coupon</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Faqs') == true}" v-on:click="openRoute('/Faqs')"><i class="fal fa-question fa-fw fa-lg"></i><span>Gestione Faqs</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Flyers') == true}" v-on:click="openRoute('/Flyers')"><i class="fal fa-book-open fa-fw fa-lg"></i><span>Gestione Locandine</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Gustbook') == true}" v-on:click="openRoute('/Gustbook')"><i class="fal fa-book fa-fw fa-lg"></i><span>Gestione Guestbook</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Messages') == true}" v-on:click="openRoute('/Messages')"><i class="fal fa-comment-alt fa-fw fa-lg"></i><span>Gestione Messaggi</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Newsletters') == true}" v-on:click="openRoute('/Newsletters')"><i class="fal fa-mail-bulk fa-fw fa-lg"></i><span>Gestione Newsletter</span></a></li>
							</ul>
						</li> 
						
						<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Routers') == true}" v-on:click="openRoute('/Routers')"><i class="ph-plugs"></i><span>Gestione Routers</span></a></li>


					</ul>
				</div>
				<!-- /main navigation -->

			</div>
			<!-- /sidebar content -->

		</div>
		<!-- /main sidebar -->	

		<!-- Main content -->
		<div class="content-wrapper">	

			<!-- Navigation -->
			<div class="navbar navbar-dark navbar-expand-lg navbar-static shadow" v-show="utente.ID_UTENTE > 0">
				<div class="container-fluid">
					
					<div class="d-flex d-lg-none me-2">
						<button type="button" class="navbar-toggler sidebar-mobile-main-toggle bg-light bg-opacity-20 text-reset rounded-pill me-2">
							<i class="ph-arrows-left-right"></i>
						</button>
						<div class="d-xl-none">
							<button type="button" class="navbar-toggler collapsed" data-bs-toggle="collapse" data-bs-target="#navbar-navigation" aria-expanded="false">
								<i class="ph-list"></i>
							</button>
						</div>
						<router-link class="d-inline-flex align-items-center" href="#" :to="'Dashboard'" style="margin-left: 4rem !important; margin-right: 50%;">
							<img src="./assets/images/logo_icon_light.png" class="sidebar-logo-icon" alt="">
						</router-link>
						<div class="d-xl-none float-end">
							<button type="button" class="navbar-toggler collapsed" data-bs-toggle="collapse" data-bs-target="#navbar-mixed" aria-expanded="false">
								<i class="ph-squares-four"></i>
							</button>
						</div>
					</div>

					<div class="navbar-collapse collapse" id="navbar-navigation" style="">
						<ul class="navbar-nav mt-2 mt-xl-0">

							<MenuRoot v-if="utente.ID_UTENTE > 0 && arrayMenus.length > 0" :items="menuCorrente(1)" :type="'nav-superiore'" @route-click="openRoute"></MenuRoot>

						</ul>
					</div>	

					<div class="navbar-collapse collapse" id="navbar-mixed">
						<ul class="nav flex-row ms-auto mt-xs-1">
							
							<li class="nav-item dropdown">
								<a href="#" class="navbar-nav-link rounded dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
									<img :src="filters.getImgPath('LOGOS',idEnteCorrente+'.png')" class="rounded-circle me-2" height="24" alt="" /> 
									<span class="hidden-xs" v-if="enteCorrente != undefined">{{enteCorrente.LABEL}}</span>
								</a>
								<div class="dropdown-menu">
									<div v-for="ente in $root.arrayEnti" v-bind:key="ente.ID_ENTE">
										<a href="#" v-on:click="switchEnte(ente)" class="dropdown-item">
											<img :src="filters.getImgPath('LOGOS',ente.ID_ENTE+'.png')" width="24" height="24" class="rounded-circle me-2" alt="" />
											<span class="fw-bold">{{ ente.LABEL }}</span>
										</a>
									</div>
								</div>
							</li>

							<li class="nav-item dropdown mt-1 me-xs-1">
								<a href="#" class="navbar-nav-link rounded dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
									<Flag :country="currentLang" class="me-1"></Flag>
								</a>
								<div class="dropdown-menu dropdown-menu-start">
									<div v-for="lang in arrayLingue" v-bind:key="lang.ID_GEO_STATO">
										<a href="#" v-on:click="currentLang = lang.ID_GEO_STATO" class="dropdown-item h-25px" >
											<Flag :country="lang.ID_GEO_STATO" class="me-1"></Flag> {{ lang.NOME }}
										</a>
									</div>
								</div>
							</li>
							
							<li class="nav-item nav-item-dropdown-lg dropdown dropdown-leftms-lg-2">
								<a href="#" class="navbar-nav-link navbar-nav-link-icon rounded-pill" data-bs-toggle="dropdown" data-bs-auto-close="outside">
									<i class="ph-chats"></i>
									<span class="badge bg-yellow text-black position-absolute top-0 end-0 translate-middle-top zindex-1 rounded-pill mt-1 me-1">{{ contatoreMessaggi }} </span>
								</a>
								<div class="dropdown-menu dropdown-menu-end wmin-lg-400 p-0">
									<div class="d-flex align-items-center p-3">
										<h6 class="mb-0">Ultime Chat</h6>
									</div>
									<div class="box-prodotto">
										<div class="dropdown-menu-scrollable pb-0" v-for="msg in arrayMessaggiNonLetti.messaggi_non_letti" v-bind:value="msg.ID_MESSAGE" v-bind:key="msg.ID_MESSAGE">
											<a href="#" class="dropdown-item align-items-start text-wrap py-2">
												<div class="status-indicator-container me-3">
													<img v-if="msg.ID_PERSONA_FISICA > 0 && msg.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',msg.URL_AVATAR)" class="w-40px h-40px rounded-pill" alt="" />
													<img v-if="msg.URL_AVATAR == 'placeholder.jpg'" src="./assets/images/face11.jpg" class="w-40px h-40px rounded-pill" alt="">
													<span class="status-indicator " v-bind:class="{'bg-danger':msg.STATO_ACCESSO == 0,'bg-success':msg.STATO_ACCESSO == 1,'bg-warning':msg.STATO_ACCESSO == 2}"></span>
												</div>
												<div class="flex-1">
													<span class="fw-semibold">{{ msg.USERNAME }}</span>
													<span class="text-muted float-end fs-sm">{{ msg.DATA_INVIO }}</span>
													<div class="text-muted">{{ msg.TEXT }}</div>
												</div>
											</a>
										</div>
									</div>
									<div class="d-flex border-top py-2 px-3">
										<a class="text-body cursor-pointer" v-on:click="markAllAsRead()">
											Segna come letti
											<i class="far fa-check-double me-1"></i>
										</a>
										<a href="#" class="text-body ms-auto cursor-pointer" v-on:click="openRoute('/Chat')">
											Val alla Chat
											<i class="ph-arrow-circle-right ms-1"></i>
										</a>
									</div>
								</div>
							</li>

							<li class="nav-item me-lg-3 me-xs-2">
								<a href="#" class="navbar-nav-link navbar-nav-link-icon rounded-pill" v-on:click="notifiche()">
									<i class="ph-bell"></i>
									<span class="badge bg-yellow text-black position-absolute top-0 end-0 translate-middle-top zindex-1 rounded-pill mt-1 me-1">
										{{ contatoreNotifiche }}
									</span>
								</a>
							</li>

							<li class="nav-item nav-item-dropdown-lg dropdown ms-3">
								<a href="#" class="navbar-nav-link align-items-center rounded-pill p-1" data-bs-toggle="dropdown">
									<div class="status-indicator-container">
										<img v-if="utente.ID_UTENTE > 0 && utente.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',utente.URL_AVATAR)" class="w-32px h-32px rounded-pill" alt="" />
										<div v-if="utente.URL_AVATAR == 'placeholder.jpg' || utente.ID_UTENTE == 0" class="btn-nophoto w-32px h-32px rounded-pill">No<br />Photo</div>
										<span class="status-indicator " v-bind:class="getUserStatusClass(utente.ID_UTENTE,arrayUtentiAdmin)"></span>
									</div>
									<span class="d-none d-lg-inline-block mx-lg-2">{{ utente.USERNAME }}</span>
								</a>
			
								<div class="dropdown-menu dropdown-menu-end">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="ph-baby me-2"></i><span class="text-muted me-1">|</span>Il mio stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item" v-on:click="setUserStatus(1)"><i class="far fa-thumbs-up text-success fa-fw me-2"></i>Disponibile</a>
											<a href="#" class="dropdown-item" v-on:click="setUserStatus(2)"><i class="far fa-thumbs-down text-warning fa-fw me-2"></i>Non disturbare</a>
										</div>
									</div>
									<a href="#" class="dropdown-item" v-on:click="openRoute('/Tasks')">
										<i class="ph-envelope-open me-2"></i>To Do
										<span class="badge bg-primary rounded-pill ms-auto">26</span>
									</a>
									<a href="#" class="dropdown-item" v-if="livello == 5">
										<i class="ph-currency-circle-dollar me-2"></i>Sottoscrizioni
									</a>
									<a href="#" class="dropdown-item" v-if="livello == 5">
										<i class="ph-shopping-cart me-2"></i>Ordini
									</a>
									<div class="dropdown-divider"></div>
									<a href="#" v-on:click="openOffCanvas();openSchedaVisualizzazioneUtente(utente)" class="dropdown-item">
										<i class="ph-user-circle me-2"></i>Mostra Profilo
									</a>
									<div class="dropdown-divider"></div>
									<a href="#" v-on:click="logout()" class="dropdown-item">
										<i class="ph-sign-out me-2 text-danger"></i>Logout
									</a>
								</div>
							</li>

						</ul>

					</div>
					
				</div>
			</div>
			<!-- /navigation -->
			
			<!-- Inner content -->
			<div class="content-inner">

				<!--<router-view/> -->
				<router-view v-slot="{ Component }">
					<component ref="view" :is="Component" />
				</router-view>

			</div>
			<!-- /inner content -->

			<!-- Footer -->
			<div class="navbar navbar-sm border-top" v-show="utente.ID_UTENTE > 0">
				<div class="container-fluid">
					<span>&copy; 2024 <a href="https://www.webfortravel.it" target="_blank">Web For Travel</a></span>

					<ul class="nav flex-row">
						<li class="nav-item ms-md-1">
							<a href="#" class="navbar-nav-link navbar-nav-link-icon rounded" v-on:click="openRoute('/Manuals')">
								<div class="d-flex align-items-center">
									<i class="icon-book me-1"></i>
									<span class="d-none d-md-inline-block ms-1"><Translate :path="'dizionario.base.OPEN_MANUALE'"></Translate></span>
								</div>
							</a>
						</li>
						<li class="nav-item ms-md-1">
							<a href="#" class="navbar-nav-link navbar-nav-link-icon rounded" v-on:click="openRoute('/Tickets')">
								<div class="d-flex align-items-center">
									<i class="icon-lifebuoy me-1"></i>
									<span class="d-none d-md-inline-block ms-1"><Translate :path="'dizionario.base.TICKET'"></Translate></span>
								</div>
							</a>
						</li>
						<li class="nav-item nav-item-dropdown-lg dropup ms-md-1">
							<a  href="#" class="navbar-nav-link navbar-nav-link-icon text-pink bg-pink bg-opacity-10 fw-semibold rounded" :class="{ 'disabled': disableMail }"  :disabled="disableMail"  v-on:click="openRoute('/Inbox')">
								<i class="ph-envelope-simple-open me-1"></i>
								<span class="d-none d-md-inline-block ms-1"><Translate :path="'dizionario.base.OPEN_EMAIL'"></Translate></span>
							</a>
						</li>
						<li class="nav-item nav-item-dropdown-lg dropup ms-md-1">
							<a href="#" class="navbar-nav-link navbar-nav-link-icon text-success bg-success bg-opacity-10 fw-semibold rounded" v-on:click="openRoute('/Chat')">
								<i class="ph-chat-circle-text me-1"></i>
								<span class="d-none d-md-inline-block ms-1"><Translate :path="'dizionario.base.OPEN_CHAT'"></Translate></span>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<!-- /footer -->

		</div>
        <!-- /main content -->

	</div>
	<!-- /page content -->	


	<div class="modal" id="popUpConfirm"  >
		<div class="modal-dialog"  >
			<div class="modal-content" >
				<div class="modal-header modal-header-info">
					<h5 class="modal-title">{{alertConfirm.TITLE}}</h5>
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
					</button>
				</div>
				<div class="modal-body">
				<div class="row">
					<div class="col-md-2" style="margin-top:10px;font-size:40px">
						<i class="fas " v-bind:class="alertConfirm.ICON"></i>
					</div>
					<div class="col-md-10 align-items-center" style="margin-top:10px">
						<div v-html="alertConfirm.MESSAGE"></div>
					</div>
				</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-danger" v-on:click="alertConfirm.KOHandler()"><i class="fa fa-times fa-fw"></i>{{alertConfirm.KO}}</button>
					<button type="button" class="btn btn-success" v-on:click="alertConfirm.OKHandler()"><i class="fa fa-check fa-fw"></i>{{alertConfirm.OK}}</button>
				</div>
			</div>
		</div>
	</div>

	<SchedaUtente :ref="'schedaUtente'" @updateGriglia="getUtenti()"></SchedaUtente>


	<div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right_help" data-bs-backdrop="false" role="dialog" tabindex="-1">
		<div class="offcanvas-header bg-warning text-white" style="height: 4rem;">
			<h5 class="offcanvas-title">Help</h5>
			<button type="button" class="btn btn-light btn-sm btn-icon border-transparent rounded-pill" data-bs-dismiss="offcanvas">
				<i class="ph-x"></i>
			</button>
		</div>
		<div class="offcanvas-body p-2">

			<SchedaVisualizzazioneHelp :ref="'schedaVisualizzazioneHelp'"></SchedaVisualizzazioneHelp>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i><Translate class="text-uppercase" :path="'dizionario.base.BTN_CLOSE'"></Translate></span>     
        </div>  
	</div>
		    
    <div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right_user" data-bs-backdrop="false" role="dialog" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
			<h6 class="modal-title" style="width: 65% !important;">SCHEDA INFORMATIVA: </h6>  
			<div class="btn-group float-end" :tabindex="2" v-show="livello == 5">
				<a href="#" class="badge bg-yellow text-dark p-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Comunicazioni</a>
				<div class="dropdown-menu dropdown-position dropdown-menu-end" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);">
					<a href="#" class="dropdown-item"><i class="fal fa-print me-2"></i> Stampa scheda </a>
					<a href="#" class="dropdown-item"><i class="fal fa-envelope me-2"></i> Invia comunicazione </a>
					<a href="#" class="dropdown-item"><i class="fal fa-paper-plane me-2"></i> Invia sms </a>
				</div>
			</div> 	
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">

			<SchedaVisualizzazioneUtente :ref="'schedaVisualizzazioneUtente'" @modificaUtente="modificaUtente($event)"></SchedaVisualizzazioneUtente>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i><Translate class="text-uppercase" :path="'dizionario.base.BTN_CLOSE'"></Translate></span>     
        </div>  
	</div>

	<div class="offcanvas offcanvas-end offcanvas-size-lg" id="panel_notifiche" data-bs-backdrop="false" role="dialog" tabindex="-1">
		<div class="offcanvas-header bg-light" style="height: 4rem;">
			<h5 class="offcanvas-title">Gestione Notifiche</h5>
			<button v-on:click="chiudiOffCanvas()" type="button" class="btn btn-light btn-sm btn-icon border-transparent rounded-pill" data-bs-dismiss="offcanvas">
				<i class="ph-x"></i>
			</button>
		</div>
		<div class="offcanvas-body p-0">
			
			<Notifiche :ref="'schedaNotifiche'" :mostra="mostraNotifiche" @chiudi="mostraNotifiche = false" :arrayNotifiche="arrayNotifiche" @getNotifiche="getNotifiche"></Notifiche>
			
		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i><Translate class="text-uppercase" :path="'dizionario.base.BTN_CLOSE'"></Translate></span>     
        </div>
	</div>


</template>


<script>
import Img from '@/components/utils/Img.vue'
import Flag from '@/components/utils/Flag.vue'
import MenuRoot from '@/components/utils/MenuRoot.vue'
import googlePlaces from '@/composables/googlePlaces';
import SchedaUtente from '@/components/SchedaUtente.vue'
import SchedaVisualizzazioneHelp from '@/components/SchedaVisualizzazioneHelp.vue'
import SchedaVisualizzazioneUtente from '@/components/SchedaVisualizzazioneUtente.vue' 
import Notifiche from '@/components/Notifiche.vue' 


export default {
    name: 'App', 

	components:{
		Img,
		Flag,
		MenuRoot,
		SchedaUtente,
		SchedaVisualizzazioneHelp,
		SchedaVisualizzazioneUtente,
		Notifiche,

    },

	props:["livello"],

    data:function(){
        return {
			alertConfirm:{
				MESSAGE:"prova"
			},
			filters:global.filters,
			zindex:1100,
			title:'Dashboard',
			icon:'fa-home',
			ordineSelezionato:{},
			preventivoSelezionato:{},
			sidebarHasBeenResized:false,
			passwordUtente:{},
			isAdmin : false,
			isGod : false,
			utente:{funzionalita: new Array()},
			arrayMenu: new Array(),
			arrayMenus: new Array(),
			menuSelezionato: new Array(),
			idStagioneCorrente:0,
			isMobile:false,
			previousRoute:"/",
			currentLang:118,
			GLOBAL_IMAGES_PATH:'',
			GLOBAL_THEME_IMAGES_PATH:'',
			savedSearch:{},
			idSetting:0,
			prodotto:{},
			stagione:{},
			percorsoLang:'',
			sottoProdotto:{},
			geoLangSelezionato:{},
			idEnteCorrente:0,
			mostraNotifiche : false,
			FK_ID_MODULO_TIPOLOGICHE:0,
			arrayModuli : new Array(
					{ID:1,DESCRIZIONE:"ATTIVITA'"},
					{ID:2,DESCRIZIONE:"HOTEL"},
					{ID:3,DESCRIZIONE:"PACCHETTI"},
					{ID:4,DESCRIZIONE:"VOLI"},
					{ID:5,DESCRIZIONE:"CAMERE"},
					),
			dizionario:{},
			//////////////////////////// TIPOLOGICHE 					
			arrayTipologieOpzioni : new Array(),
			arrayUtentiAdmin : new Array(),
			arrayEnti : new Array(),
			arrayCodiciIva : new Array(),
			arrayTipoFiscale : new Array(),
			arrayTipoModuli : new Array(),
			arrayTipoCategoria : new Array(),
			arrayTipoContenuto : new Array(),
			arrayModalitaCalcolo : new Array(),
			arrayPromotori : new Array(),
			arrayAgenti : new Array(),
			arrayConti : new Array(),
			arrayGeoStati : new Array(),
			arrayGeoComuni : new Array(),
			arrayAeroporti : new Array(),
			arrayLivelli : new Array(),
			arrayGruppi : new Array(),
			arrayFunzionalita : new Array(),
			arrayTipoAgenzia : new Array(),
			arrayTipoFornitore : new Array(),
			arrayLingue : new Array(),
			arrayRuoliUtente : new Array(),
			arrayStagioni : new Array(),
			arrayRtsType : new Array(),
			arrayPraticaStato : new Array(),
			arrayPraticaBook : new Array(),
			arrayPraticaTipo : new Array(),
			arrayPraticaPagamento : new Array(),
			arrayPraticaDocumento : new Array(),
			arrayAllOpzioni : new Array(),
			arrayNetwork : new Array(),
			statistiche: {},
			currentModulo:0,
			COSTANTI:{},
			VERSIONE:'',
			CHAT_PATH:'',
			arrayChatUtente : new Array(),
			connessioneSocket: 0,
            appoggio: {
                socket: null
            },
			contatoreNotifiche : 0,
			contatoreMessaggi : 0,
			arrayNotifiche : new Array(),
			arrayMessaggiNonLetti : new Array(),
			allowedDates : new Array(),
			disableMail : true,
			newEmailCount: 0,
			socketConnected : false,
			cartellaSelezionata: '',
        } 
    },

    methods : {
		logout : function(){
			if(this.appoggio && this.appoggio.socket){
				this.appoggio.socket.emit('updateUserStatus', { userId : this.utente.ID_UTENTE})
				this.appoggio.socket.disconnect();
				this.socketConnected = false;
				this.appoggio.socket = null;
			}
			global.utils.ajax('core/logout',{}, (response) => {
				//$('.sidebar').removeClass("sidebar-main-resized");
				this.$root.utente = {};
				global.utils.deleteCookie("session_token");
				global.router.push("Login");
				this.arrayMenu = new Array();
			});
   		},

      	isCurrent : function(menu){
			if ("/"+menu == this.currentRouteName){
				return true;
			}
      	},
		
		confirm : function(alertConfirm){
			this.alertConfirm = alertConfirm;
			this.filters.openPopUp("popUpConfirm");
		},

		setHeader : function(_title,_icon){
			this.title = _title;
			this.icon = _icon;
		},

		back : function(){
			global.router.go(-1);
		},

		modificaPassword : function(){
			this.filters.openPopUp("popUpModificaPassword");
		},

		switchEnte : function(ente){
			global.utils.ajax('travel/switchtEnte',{FK_ID_ENTE:ente.ID_ENTE}, (response,status) => {
				if (response.esito == 0){
					global.utils.alert.success("Operazione correttamente eseguita");
					this.idEnteCorrente = ente.ID_ENTE;
					//$('#listaEnti').removeClass("show");
					//$('#divListaEnti').removeClass("show");
					utils.removeClass("listaEnti","show");
					utils.removeClass("divListaEnti","show");

					//console.log(this.$refs.view);
					if (typeof this.$refs.view.refreshEnte == 'function'){
						this.$refs.view.refreshEnte();
					}
					//this.$root.$emit('ente-cambiato', ente.ID_ENTE);					
				}
			});
		}, 

		openSchedaUtente: function(){
			this.$refs.schedaUtente.openSchedaUtente(this.utente);
		},
		
		modificaProfilo : function(){
			global.router.push("Profilo");
		},

		openRoute : function(route){
			//console.log('sto aprendo');
			global.router.push(route);
		},

		doModificaPassword : function(){
			//)7.HRK=L3M!xiyRq,vZ
			this.filters.closePopUp("popUpModificaPassword");
			global.utils.ajax('core/utente/password/update',this.passwordUtente, (response,status) => {
				if (response.esito == 0){
					global.utils.alert.success("Operazione correttamente eseguita");
				}
			});
		},

		getDataColor : function(route){
			return route.DESCRIZIONE;
		},

		openPopUpLogin : function(){
			this.filters.openPopUp("popUpRecoveryLogin");
		},

		checkMobile : function(route){
			if (this.isMobile == false){
			return true;
			}
			else{
			if (route.ROUTE == '/Soci' || route.ROUTE == '/attivita' || route.ROUTE == '/abbonamenti'){
				return true;
			}
			}
			return false;
		},

		getLang : function(){
			return this.currentlang;
		},

		refreshUtentiAdmin : function(){
			global.utils.ajax('travel/utentiAdmin',{}, (response,status) => {
				if (response.esito == 0){
					this.arrayUtentiAdmin = response.data;
				}
			});
		},

		confTravelTipologiche : function(idModulo){
			this.FK_ID_MODULO_TIPOLOGICHE = idModulo;
            global.router.push("ConfGenerali");
		},

		getLangModulo : function(idModulo){
			var modulo = utils.array.select(this.arrayTipoModuli,"ICONA",idModulo,1)[0];
			return modulo;
		},

		confTravelLingue : function(idModulo){
			this.currentModulo = idModulo;
			this.percorsoLang = this.getLangModulo(idModulo).CUSTOM_2;
			global.router.push("Lingua");
		},

		preventivo : function(){
			this.ordineSelezionato = {ID_ORDINE:0,INTESTATARIO_FK_ID_PERSONA_FISICA:0,INTESTATARIO_FK_ID_PERSONA_GIURIDICA:0};
			this.openRoute('/Quota');
		},

		getMenu : function(idType){
			utils.ajax("core/menu/singolo",{ID_TYPE:idType}, (response) => {
                this.menuSelezionato = response.data;
				return response.data
            });
		},

		getMenuAlls : function(){
			utils.ajax("core/menu/childrens",{}, (response) => {
                this.arrayMenus = response.data;
            });
		},

		////////////////////////////////////////////////////////////////// GESTIONE DELLE LINGUE ///////////////////////////////////////////////////////////
		translate : function(percorso){
			if (this.dizionario != null){
				var percorsi = percorso.split(".");
				var parziale = this.dizionario;
				for (var i = 0 ; i < percorsi.length ; i++){
					parziale = parziale[percorsi[i]];
					if (parziale == undefined){
						return "";
					}
				}
				return parziale[this.currentLang];
			}
			else{
				return "";
			}
			/*
			else{
				utils.wait(()=>{
					this.translate(lingua,percorso);
				});
			}*/
		},

		getDizionarioPercorso : function(percorso){
			var percorsi = percorso.split(".");
			var parziale = this.dizionario.dizionario;
			for (var i = 0 ; i < percorsi.length ; i++){
				parziale = parziale[percorsi[i]];
				if (parziale == undefined){
					return null;
				}
			}
			var arrayDefinizioni = new Array();
			Object.keys(parziale).forEach(key => {
				for (var i = 0 ; i < filters.root.arrayLingue.length ; i++){
					var FK_ID_LINGUA = filters.root.arrayLingue[i].ID_GEO_STATO;
					var val = parziale[key][FK_ID_LINGUA];
					arrayDefinizioni.push({DEFINIZIONE:key,VALORE:val,FK_ID_LINGUA:FK_ID_LINGUA,FK_ID_DEFINIZIONE:key,ID_DEFINIZIONE:i.toString()+"_"+FK_ID_LINGUA});
				}
			});
			return arrayDefinizioni;
		},

		getDizionario : function(handler = null){
			global.utils.ajax('travel/dizionario',{}, (response,status) => {
				this.dizionario = response.data; 
				if (handler != null)          {
					handler(this.dizionario);
				}
			});
		},

		svuotaCache : function(){
			global.utils.ajax('core/cache', (response,status) => {
				if (response.esito == 0){
					global.utils.alert.success("Operazione correttamente eseguita");
				}
			});
		},


		help : function(_modulo){
			var myOffcanvas = document.getElementById('panel_right_help')
			var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
			bsOffcanvas.show();
			this.$refs.schedaVisualizzazioneHelp.openSchedaVisualizzazioneHelp(_modulo);
		},

		setUserStatus(newStatus) {
			this.isDropdownOpen = false;
			const userId = this.utente.ID_UTENTE;
			global.utils.ajax('chad_2/admin/updateUserStatus', { userId: userId, newStatus: newStatus }, (response) => {
				if (response.esito == 0) {
					this.utente.STATO_ACCESSO = newStatus;
					this.appoggio.socket.emit('updateUserStatus', { userId: userId, newStatus: newStatus }); // invia evento al server
				} else {
					console.error('Errore nel cambiare lo stato dell\'utente', response);
				}
			});
		},

		getUtentiAdmin(_fake){
			global.utils.ajax('core/admin/utenti/light', {fake: _fake}, (response, status) => {
				if (response.esito == 0) {
					this.arrayUtentiAdmin = response.data;
				} else {
					console.error('Errore nel recupero degli utenti:', response);
				}
			});
		},

		cronJob : function(){
			global.utils.ajax('core/admin/aggiornautenti', {fake:true}, (response,status) => {
				if (response.esito == 0){
					global.utils.ajax('core/admin/utenti/light', {fake:true}, (response,status) => {
						if (response.esito == 0){
							global.utils.alert.success("Utenti recuperati");
							this.arrayUtentiAdmin = response.data;
						}
						else {
							global.utils.alert.warning("Non ho recuperato gli utenti ");
						}
					});
				}
				else {
					global.utils.alert.warning("Nessun Utente Aggiornato ");
				}
			});
		},

        getUserStatusClass(userId,_arrayUtenti) {
			if(this.utente && this.utente.ID_UTENTE == userId){
				if (this.utente.STATO_ACCESSO == 1) return 'bg-success';  
                if (this.utente.STATO_ACCESSO == 2) return 'bg-warning';
				return 'bg-danger'  
			}

            const utente = _arrayUtenti.find(utente => utente.ID_UTENTE == userId);
            if (utente) {
                if (utente.STATO_ACCESSO == 1) return 'bg-success';  
                if (utente.STATO_ACCESSO == 2) return 'bg-warning';  
                 
            }
            return 'bg-danger';
        },

		openOffCanvas : function(){
			var myOffcanvas = document.getElementById('panel_right_user')
			var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
			bsOffcanvas.show();
		},

		openSchedaVisualizzazioneUtente : function(utente,tab = ""){
			this.utenteSelezionato = utils.clone(utente);	
			this.$refs.schedaVisualizzazioneUtente.openSchedaVisualizzazioneUtente(utente,tab);
		},

		connectSocket() {
			if(!this.appoggio.socket){
				utils.connect(this.CHAT_PATH, this, 'http', this.handleSocketConnect);
			}
		},

		handleSocketConnect() {
			if (!this.appoggio.socket || !this.appoggio.socket.connected) {
				console.warn("Socket non pronto, attendo connessione...");
				return;
			}
			console.info("CONNESSIONE SOCKET AVVENUTA");
			if (this.utente && this.utente.ID_UTENTE) {
				console.log("Invio evento joinApp per l'utente:", this.utente.ID_UTENTE);
				this.appoggio.socket.emit("joinApp", this.utente.ID_UTENTE);
			}

			
			this.socketConnected = true;
			if(this.appoggio.socket){
				//console.log('passo qui');
				this.appoggio.socket.on("new-email", (data) => {
					this.newEmailCount += data.numNewMsgs;
					utils.alert.success(`📩 Hai ${this.newEmailCount} nuove email!`);
				});
			}

        },

		startHandler : function(){
			this.arrayMenu = new Array();
			global.router.push("Start");
			//1.VERIFICO ESISTENZA TOKEN
			//console.log("*** AVVIO APPLICAZIONE ***");
			var token = global.utils.getCookie("session_token");
			//SE E' PRESENTE IL TOKEN, PROVIAMO A RECUPERARE L'Utente
			if (token != "" && token != undefined && token != "undefined"){
				global.utils.SESSION_TOKEN = token;
				global.utils.ajax('core/logged',{}, (response) => {
					if (response.esito == 0 && response.data.UTENTE.ID_UTENTE > 0){
						this.utente = response.data.UTENTE;
						this.arrayMenu = response.data.UTENTE.menuItems;
						let userId = this.utente.ID_UTENTE;
						//console.log(userId);
						if(this.socketConnected == false){
							this.connectSocket();
							this.socketConnected = true
						}
						/*if (userId) {
							try {
								this.appoggio.socket.emit('joinApp', userId);
							} catch (error) {
								console.error("Errore durante l'invio dell'evento 'joinApp' sul socket:", error);
								this.showError("Impossibile connettersi all'applicazione. Riprova più tardi.");
							}
						}*/
						for (var i = 0 ; i < this.utente.funzionalita.length ; i++){
							if (this.utente.funzionalita[i].FK_ID_ITEM > 0){
								this.arrayMenu.push(this.utente.funzionalita[i].menuItem);
							}
						}
						
						const utente = this.arrayUtentiAdmin.find(utente => utente.ID_UTENTE == this.utente.ID_UTENTE);
						if(utente){
							const userID = utente.ID_UTENTE;
							if(this.appoggio && this.appoggio.socket){
								this.appoggio.socket.emit('updateUserStatus', { userId : userID, newStatus : 1})
							}
						}
						global.router.push("Dashboard");
						this.getUtentiAdmin(false);			
						this.checkIfGod();
						this.getNotifiche();
						this.appoggio.socket.on('userStatusUpdated', (data) => {
							const { userId, newStatus } = data;
							if(this.utente.ID_UTENTE == userId){
								this.utente.STATO_ACCESSO = newStatus
							}

							const utente = this.arrayUtentiAdmin.find(utente => utente.ID_UTENTE == userId);
							if (utente) {
								utente.STATO_ACCESSO = newStatus; // Aggiorna lo stato dell'utente
							}
							console.log("Lo stato dell'utente" + userId + "è stato aggiornato a"+ newStatus)
						});
						this.getMenuAlls();
						this.aggiornaMessaggiDaApp();
					}
					else {
						global.router.push("Login");
						this.arrayMenu = new Array();
					}
				});
			}
			else{
				//this.$forceUpdate();
				this.arrayMenu = new Array();
				utils.wait(()=>{
					global.router.push("Login");
				})

			}
			
			
			
		},

		getNotifiche() {
            console.log('Recupero delle notifiche dal server...');
            utils.ajax('shared/notifiche', {FK_ID_UTENTE : this.$root.utente.ID_UTENTE}, (response) => {
                if (response && response.esito == 0) {
					this.arrayNotifiche = response.data;
					this.contatoreNotifiche = this.arrayNotifiche.length;
                }
            },false);
        },

		notifiche : function(){
			var myOffcanvas = document.getElementById('panel_notifiche')
			var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
			bsOffcanvas.show();
			this.getNotifiche();
			this.mostraNotifiche = true;
		},

		getMessaggiNonLetti() {
			console.log('Recupero dei messaggi...');
			utils.ajax('chad_2/messaggi_non_letti/utente', { FK_ID_UTENTE: this.$root.utente.ID_UTENTE }, (response) => {
				if (response && response.esito == 0) {
					this.arrayMessaggiNonLetti = response.data;
					this.contatoreMessaggi = this.arrayMessaggiNonLetti.messaggi_non_letti.length
				}
			},false);
		},

		playSound(soundPath) {
			const audio = new Audio(soundPath);
			audio.play().catch((error) => {
				console.warn('Errore nella riproduzione del suono:', error);
			});
		},

		aggiornaMessaggiDaApp() {
			if (this.appoggio.socket) {
				this.appoggio.socket.on('receiveMessage', (message) => {
					if (message.FK_ID_USER !== this.utente.ID_UTENTE) {
						this.playSound(require('@/assets/sounds/bloop.mp3'));
						this.getMessaggiNonLetti();
					}
				});
				this.appoggio.socket.on('messagesRead', (data) => {
					console.log("CLIENT: Arrivato markAsRead con data =", data);
					this.arrayMessaggiNonLetti.messaggi_non_letti = 
					this.arrayMessaggiNonLetti.messaggi_non_letti.filter(msg => msg.FK_ID_CHAT !== data.chatId);
					this.contatoreMessaggi = this.arrayMessaggiNonLetti.messaggi_non_letti.length;
					setTimeout(() => {
						this.getMessaggiNonLetti();
					}, 500);
				});
			}
		},

		chiudiOffCanvas() {
			this.mostraNotifiche = false;
		},
		
        checkIfGod() {
            utils.ajax('user/details', { ID_UTENTE: this.$root.utente.ID_UTENTE }, (response) => {
                this.isGod = response.data.isGod;
            });
        },

		markAllAsRead() {
			utils.ajax('chad_2/messaggi/markMultipleAsRead', {ID_UTENTE : this.$root.utente.ID_UTENTE}, (response) => {        
				if (response && response.esito == 0) {
				this.getMessaggiNonLetti();
				utils.alert.success('Tutti i messaggi sono stati aggiornati come letti.');
				} else {
				utils.alert.danger('Errore nell\'aggiornamento dello stato dei messaggi.');
				}
			},false);
		},
			
		goToLogs : function(folder,modulo){
			this.cartellaSelezionata = folder;
			global.router.push(modulo);
			
		} 

    },

    computed : {
		currentRouteName() {
			return this.$route.path;
		},

		mainMenuActive(){
			for (var i = 0 ; i < this.arrayMenu.length ; i++){
				if (this.arrayMenu[i].ROUTE == this.currentRouteName){
					return 1;
				}
			}
			return 0;
		},

		enteCorrente(){
			var ente = utils.array.select(this.arrayEnti,"ID_ENTE",this.idEnteCorrente,1)[0];
			return ente;
		},

		menuCorrente(){
			return(idType) => {
				return utils.array.select(this.arrayMenus,"FK_ID_TYPE",idType);
			}
		}

    },

    created : function(){
		var apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
		this.GLOBAL_THEME_IMAGES_PATH = process.env.VUE_APP_THEME_IMAGES_PATH;
		this.VERSIONE =  process.env.VUE_APP_VERSION_NUMBER;
		this.CHAT_PATH =  process.env.VUE_APP_CHAT_PATH;
		const link = "https://maps.googleapis.com/maps/api/js?key="+apiKey+"&libraries=places&callback=Function.prototype"; //&callback=indirizzoSelezionato
		googlePlaces(link);
        var ua = navigator.userAgent;
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
            this.isMobile = true;
        else
            this.isMobile = false;

		
		
        //global.utils.BASE_API = "http://localhost/dryland_2/CORE/SERVER/API/"; //process.env.VUE_APP_API_URL;
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.getDizionario();
		this.startHandler();
        
		this.allowedDates = this.filters.generaArrayDate()
	
		
		setTimeout(() => {
			
			/*
            $('.modal').on('show.bs.modal',  (e) => {
                setTimeout(() => {
                    this.zindex++;
                    $('.modal-backdrop').last().css("z-index",this.zindex);
                    this.zindex++;
                    $('.modal.fade.show').last().css("z-index",this.zindex); 
                }, 200);
            }); */                   
        }, 400);

		//const userId = this.utente ? this.utente.ID_UTENTE : null;
		//this.appoggio.socket.emit('joinApp', userId);

    },
    
    watch:{
		currentlang(valore,valoreOld){
			if (valore != valoreOld){
			/*
			setTimeout(() => {
				global.t.currentlang = valore;
			},500)*/
			}
		},

        $route (valore,valoreOld){			
			//console.log(valore.path,valoreOld.path);
			this.previousRoute = valoreOld.path;
			if (valore.path == "/Pagamento"){
				this.previousRoute = "/pagamenti";
			}
			var list = document.getElementsByClassName("tooltip")
			for (var i = 0 ; i < list.length ; i++) {
				list[i].remove();
			}

			var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
			var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
			return new bootstrap.Popover(popoverTriggerEl)
			})

			var arr = document.getElementsByClassName("offcanvas")
			for (var i = 0 ; i < arr.length ; i++){
				arr[i].classList.remove("show")
			}
			global.utils.wait(()=>{
				global.utils.resizeContent();
			});
			
        }
    },

	mounted() {
		setTimeout(()=>{
			this.disableMail = false;
		},5000)
		
		/*document.addEventListener('click', () => {
			const audio = new Audio('assets/sounds/placeholder.mp3'); // Un suono silenzioso o vuoto
			audio.play().catch(() => {
				console.log('Riproduzione audio abilitata.');
			});
			document.removeEventListener('click', this); // Rimuovi il listener dopo il primo clic
		});
		
		/*const savedNotificationsEnabled = localStorage.getItem('notificationsEnabled');
		if (savedNotificationsEnabled !== null) {
			this.notificationsEnabled = JSON.parse(savedNotificationsEnabled);
		}
		this.appoggio.socket.on('connect', () => {
			const registraUtenteAlSocket = () => {
				const userId = this.utente ? this.utente.ID_UTENTE : null;

				if (userId) {
					console.log(`Registrazione utente ${userId} al socket`);
					this.appoggio.socket.emit('joinApp', userId);
				} else {
					console.warn('User ID non disponibile. Attendo che diventi disponibile...');
				}
			};
			registraUtenteAlSocket();
			this.$watch('utente', (newVal) => {
				if (newVal && newVal.ID_UTENTE) {
					registraUtenteAlSocket();
				}
			});
		});*/
	
	}


}
</script>
