<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">

        <!-- breadcrumb -->
        <div class="page-header-content d-lg-flex">
            <div class="d-flex">
                <h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
                    <a href="#" class="text-dark fw-semibold cursor-pointer"> Template </a> \ 
                    <span class="text-muted">Chat</span>
                </h4>
                <a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
                    <i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
                </a>
            </div>
            <div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
                <div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">

                    <a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 me-2 position-10" style="text-align: left;">
                     
                    </a>

                    <div class="vr flex-shrink-0 my-2 mx-3"></div>

                    <div class="d-inline-flex float-end mt-sm-0">
                        <a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche()" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
                            <i class="ph-lightning"></i>
                        </a>
                        <a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue()" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
                            <i class="ph-flag"></i>
                        </a>
                        <a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help()" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
                            <i class="icon-question7" style="color: orange;"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- /breadcrumb -->

        <!-- Profile navigation -->
        <div class="navbar navbar-expand-lg border-top py-1">
            <div class="container-fluid">
                <ul class="nav navbar-nav flex-row flex-fill" role="tablist">
                    <li class="nav-item me-1" role="presentation">
                        <a href="#chat-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
                            <div class="d-flex align-items-center mx-lg-1">
                                <i class="icon-menu7 me-2"></i>Chat
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /profile navigation -->	

    </div>
    <!-- /page header -->

	<!-- Content area -->
    <div class="content content-1 chat-container">
        <div class="d-lg-flex">

            <!-- Left sidebar component -->
            <div class="sidebar sidebar-component sidebar-expand-lg bg-transparent shadow-none me-lg-3" style="display: block;">
                <div class="sidebar-content list-chat" :class="{ hidden: chatSelezionata && isMobile }">

                    <ListChat 
                        :ref="'listChat'" 
						@chat-selezionata="handleChatSelezionata"
						:arrayUtentiAdmin="$root.arrayUtentiAdmin" 
                        :writingState="writingState" 
					/>

                </div>
            </div>
            <!-- /left sidebar component -->

            <!-- Right content -->
            <div class="flex-1">
                        
                <div class="chat-comp" v-if="chatSelezionata" :class="{ 'chat-comp-mobile': isMobile }" @click.stop>
                    
                    <ChatComp 
                        :ref = "'chatComp'"
						@messageSent="updateLastMessage" 
                        @isWriting="updateWritingState" 
                        @stopWriting="updateWritingState" 
                        :chatId="chatSelezionata.ID_CHAT" 
                        @close="closeChatComp" 
                        :arrayUtentiAdmin="$root.arrayUtentiAdmin"  
                        @updateUnreadMessages="handleUpdateUnreadMessages" 
                        @messaggiAggiornati="handleAggiornaChat" 
					/>
                </div>
                   
            </div>
            <!-- /right content -->

        </div>
    </div>

</template>



<script>

import ListChat from '../../components/ListChat';
import ChatComp from '../../components/ChatComp.vue';

export default {
    components: {
        ListChat,
        ChatComp
    },  

    data() {
        return {
            chatSelezionata: null, // ID della chat attualmente selezionata
            isMobile: false, // Variabile per determinare se il dispositivo è mobile
            writingState: {}
        };
    },		 

    methods: {
        handleChatSelezionata(idChat) {
            this.chatSelezionata = idChat; // Imposta la chat selezionata
            if(this.$refs.chatComp){
                this.$refs.chatComp.resetMessageInput()
            }
        },

        updateWritingState(data) {
            const { chatId, userId, username, isWriting } = data;
            if (userId !== this.$root.utente.ID_UTENTE) {
                this.writingState = {
                    ...this.writingState,
                    [chatId]: { userId, username, isWriting }
                };
            }
        },

        closeChatComp() {
            this.chatSelezionata = null; // Chiude la chat
        },

        checkMobile() {
            this.isMobile = window.innerWidth <= 768; // Considera mobile se la larghezza è <= 768px
        },

        aggiornaListChat(){
            this.$refs.listChat.getChat();
        },

        updateLastMessage(messaggio) {
            this.$refs.listChat.updateChatLastMessage(messaggio); // Chiamiamo un metodo in ListChat
        },

        handleAggiornaChat(){
            if(this.chatSelezionata){
                this.$refs.chatComp.getMessaggiPerChat();
            }
        },
        
        handleUpdateUnreadMessages({chatId}){
            this.$refs.listChat.updateUnreadMessages(chatId)
        },

        setupSocketListeners() {
            this.$root.appoggio.socket.on('typing', (data) => {
                this.updateWritingState(data);
            });
            this.$root.appoggio.socket.on('stopTyping', (data) => {
                this.updateWritingState(data);
            });
        },
    },

    created() {
        this.setupSocketListeners();
        this.checkMobile();
        window.addEventListener("resize", this.checkMobile); // Controlla la larghezza dello schermo al ridimensionamento
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.checkMobile); //importante perchè altrimenti  l'evento di ridimensionamento continua
    }

};
</script>


<style>
    .list-chat.hidden{display:none}
    .close-btn{position:absolute;top:10px;right:10px;background:0 0;border:none;font-size:24px;font-weight:700;cursor:pointer;color:#888}
    .close-btn:hover{color:#000}
    @media (max-width:768px){
        .chat-container{flex-direction:column;padding:0;overflow:hidden}
        .list-chat{flex-basis:auto;margin-right:0;width:100%;z-index:1}
        .chat-comp{z-index:10;width:100%}
        .chat-comp-mobile{background-color:#fff}
        .hidden{display:none}
    }
</style>

