<template>

    <div class="d-flex flex-column flex-lg-row mb-0" >
        <div class="me-lg-3 mb-2 mb-lg-0">
            <img :src="riga.prodotto.IMMAGINE_PRODOTTO" class="squared-circle" style="width: 67px;" alt="" />
        </div>
        <!-- DATI QUOTA PRINCIPALE -->
        <div class="flex-fill">
            <span class="text-primary fw-semibold cursor-pointer" v-on:click="openProdotto(riga.prodotto)">
                <span class="d-sm-none">{{ filters.accorciaTesto(riga.prodotto.DESCRIZIONE,30) }}</span>
                <span class="d-none d-md-block">{{ riga.prodotto.DESCRIZIONE }}</span>
            </span>

            <!-- DATI QUOTA PRINCIPALE SUBMENU -->
            <div class="row ms-0">
                <div class="col-9 " v-bind:class="{'ps-0':modalita == 2}">
                    <ul class="list-inline fs-sm mb-0 mt-0">
                        <span class="badge bg-dark bg-opacity-20 text-reset badge-xs">{{ filters.getLabel($root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',riga.prodotto.FK_ID_TIPO,'DESCRIZIONE') }}</span>
                        <span class="ms-1 me-1">|</span> 
                        <li class="list-inline-item">
                            <i class="fad fa-map-signs fa-fw me-1"></i> 
                            <a href="#" class="cursor-pointer">
                                {{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',riga.prodotto.FK_ID_COMUNE,'STATO') }} - 
                                {{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',riga.prodotto.FK_ID_COMUNE,'REGIONE') }}, 
                                {{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',riga.prodotto.FK_ID_COMUNE,'COMUNE') }} 
                            </a>
                        </li>
                    </ul> 
                    <p class="fs-sm mb-3">
                        <span class="me-1"><i class="ph-thumbs-up text-success"></i>  </span>
                    </p>  
                </div>
                <div class="col-3 text-end">
                    <div class="text-indigo " v-bind:class="{'testo-12':modalita == 2,'testo-10':modalita == 1}">
                        <label class="form-label mb-0">Prezzo</label><br>
                        <span style="font-weight: bold;">&euro; {{ filters.formattaImporto(getTotaleQuote(riga.quote)) }}</span>
                    </div>
                </div>
            </div>           
        </div>
    </div>
    <!-- DATI QUOTE FIGLIE -->
    
    <!-- QUOTE DEL PACCHETTO -->
    <div v-for="(quota,$i) in riga.quote" v-bind:key="quota.ID_QUOTA">
        <div style="width:100%; padding:5px; border: 1px solid #eee;">
            <button class="btn btn-default"  v-on:click="clickCameraPacchetto(quota)"><i class="fad fa-lg" v-bind:class="{'fa-folder':quota.CLIENT_VISIBILE == 0,'fa-folder-open':quota.CLIENT_VISIBILE == 1}"></i></button>
            <span class="cursor-pointer ms-1" v-if="riga.quote.length > 0">Quota {{$i+1}}</span>
            <!-- BOTTONE AZIONI -->
            <span class="fs-sm text-muted float-end">
                <div class="dropdown align-self-center" v-show="modalita == 2">
                    <a href="#" class="badge bg-secondary bg-opacity-20 text-secondary text-body" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="ph-list"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end" style="">
                        <span class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="modificaDate(quota)"><i class="icon-truck me-2"></i> Gestione </span>
                        <span class="dropdown-item" v-on:click="editQuota(quota)"><i class="icon-truck me-2"></i> Edita Quota</span>
                        <span class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-file-download me-2"></i> Quota solo Costi</span>
                        <span class="dropdown-item" v-on:click="setQuotaVisibile(quota,0)" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0 && quota.FLG_VISIBILE == 1"><i class="icon-wallet me-2"></i> Nascondi quota</span>
                        <span class="dropdown-item" v-on:click="setQuotaVisibile(quota,1)" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0 && quota.FLG_VISIBILE == 0"><i class="icon-wallet me-2"></i> Rendi quota visibile</span>
                        <div class="dropdown-divider" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"></div>
                        <span class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="eliminaQuota(quota)"><i class="icon-warning2 me-2"></i> Elimina Quota</span>
                    </div>
                </div>
            </span>           
        </div>
        <div style="margin-left:50px">
            
            <!-- SERVIZI DEL PACCHETTO -->
            <div v-for="q in quota.quote" v-bind:key="q.ID_QUOTA" v-show="(quota.CLIENT_VISIBILE == 0) == false">
                <!-- HOTEL -->
                <template v-if="q.FK_ID_MODULO == 2 || q.FK_ID_MODULO == 5">
                    <RigaQuotaHotel :modalita="modalita" :riga="componiRigaPerQuoteServizi(q)" @modifica="editQuota($event)" @modificaDate="modificaDate($event)" @elimina="eliminaQuota($event)" @selezionaProdotto="openProdotto($event)"></RigaQuotaHotel>
                </template>
                <!-- VOLI -->
                <template v-if="q.FK_ID_MODULO == 4 ">
                    <RigaQuotaVoli :modalita="modalita" :riga="componiRigaPerQuoteServizi(q)" @modifica="editQuota($event)" @modificaDate="modificaDate($event)" @elimina="eliminaQuota($event)" @selezionaProdotto="openProdotto($event)"></RigaQuotaVoli>
                </template>
                <!-- ATTIVITA -->
                <template v-if="q.FK_ID_MODULO == 1 ">
                    <RigaQuotaAttivita :modalita="modalita" :riga="componiRigaPerQuoteServizi(q)" @modifica="editQuota($event)" @modificaDate="modificaDate($event)" @elimina="eliminaQuota($event)" @selezionaProdotto="openProdotto($event)"></RigaQuotaAttivita>
                </template>
            </div>

        </div>
    </div>  
        

</template>



<script>

import RigaQuotaHotel from '@/components/RigaQuotaHotel.vue';
import RigaQuotaVoli from '@/components/RigaQuotaVoli.vue';
import RigaQuotaAttivita from '@/components/RigaQuotaAttivita.vue';

export default {
    name:'RigaQuotaPacchetto',

    emits: ['elimina', 'modifica', 'modificaDate','selezionaProdotto'],

    props:['riga','modalita'],

    components:{
        RigaQuotaHotel,
        RigaQuotaVoli,
        RigaQuotaAttivita
    },

    data:function(){
        return {
            filters:global.filters,
        }
    },
    
    methods:{
        eliminaQuota : function(quota){
            this.$emit("elimina",quota);
        },
        editQuota : function(quota){
            console.log("Pacchetto",quota);
            this.$emit("modifica",quota);            
        },
        setQuotaVisibile : function(quota,flgVisibile){
            quota.FLG_VISIBILE = flgVisibile;
            utils.ajax("travel/ordine/quota/update",quota, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            });            
        },
        modificaDate : function(quota){
            this.$emit("modificaDate",quota);            
        },
        getStileTitolo : function(){
            var stile = {};
            if (this.quota.FK_ID_QUOTA_PRINCIPALE > 0){
                stile['padding-left'] = "40px";
            }
            return stile;
        },
        getStileRiga : function(){
            var stile = {};
            if (this.quota.FK_ID_QUOTA_PRINCIPALE > 0){
                stile['font-size'] = "14px";
            }
            else{
                stile['font-size'] = "18px";
            }
            return stile;
        },
        getLblStatoQuota : function(quota){
            switch (quota.FK_ID_STATO){
                case 1:
                    return "CONFERMATA";
                case 2:
                    return "IN RICHIESTA";
                default:
                    return "-";
            }
        },
        openProdotto : function(prodotto){
            if(this.riga.FK_ID_MODULO != 6){
                this.$emit("selezionaProdotto",prodotto); 
            }
        },
        getTotaleQuote : function(quote){
            var totale = 0;
            for (var i = 0 ; i < quote.length; i++){
                totale += parseFloat(quote[i].PREZZO_TOTALE);
            }
            return totale;
        },
        getLabel : function(FK_ID_TIPO){
            const tipo = utils.array.select(this.$root.arrayAllOpzioni,"ID_OPZIONE_TIPOLOGICA",FK_ID_TIPO,1)[0];
            return tipo != undefined ? tipo.DESCRIZIONE : '';
        },
        componiRigaPerQuoteServizi(quota){
            if (quota.FK_ID_MODULO == 2 || (quota.FK_ID_MODULO == 5)){
                return {
                    prodotto:quota.prodottoPadre,
                    ID_PRODOTTO:quota.prodottoPadre.ID_PRODOTTO,
                    quote:new Array(quota),
                    FK_ID_MODULO : quota.FK_ID_MODULO
                }
            }
            else{
                return {
                    prodotto:quota.prodotto,
                    ID_PRODOTTO:quota.prodotto.ID_PRODOTTO,
                    quote:new Array(quota),
                    FK_ID_MODULO : quota.FK_ID_MODULO
                }
            }
        },
        clickCameraPacchetto : function(quota){
            if (quota.CLIENT_VISIBILE == 1){
                quota.CLIENT_VISIBILE = 0;
                
            }
            else{
                quota.CLIENT_VISIBILE = 1;
            }
        }
        
       
    },
    created : function(){
    }
}
</script>
