<template>
     
    <div class="row" style="height: 8.75rem;">
        <div class="col-md-3 mb-2">
            <img v-if="utente.ID_UTENTE > 0 && utente.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',utente.URL_AVATAR)" class="rounded" style="width: 150px !important; max-height: 160px;" alt="" />
            <div v-if="utente.URL_AVATAR == 'placeholder.jpg'" src="../assets/images/placeholders/placeholder.jpg" class="rounded" style="width: 150px !important;" alt=""></div>
        </div>
        <div class="col-md-9 mb-2" style="height: 6.7rem;">
            <h4 class="text-primary mb-1"> 
                {{utente.persona.COGNOME}} {{ utente.persona.NOME }}
                <span v-on:click="modificaUtente()" class="badge bg-danger bg-opacity-20 text-danger cursor-pointer float-end p-1">
                    <span class="fw-semibold text-danger">Modifica Utente</span>
                </span>
            </h4>
            <p class="text-dark mb-1"><i class="fal fa-tag fa-fw me-1"></i><span class="text-light me-1">|</span>{{ getLabelOpzioneUtente('RUOLI')}}</p>
            <p class="text-dark mb-1"><i class="fal fa-calendar fa-fw me-1"></i><span class="text-light me-1">|</span>{{ filters.formatDateTime(utente.DATA_CREAZIONE) }}</p>
            <p class="fs-sm text-dark mb-1">&nbsp;</p>
            <p class="mb-0">&nbsp;</p>
        </div> 
    </div>

    <p>&nbsp;</p>

    <div class="row bg-light" style="border-top: 1px solid #ccc;">
        <div class="col-12 ps-0 pe-0">
            <div class="navbar navbar-expand-lg bg-light border-bottom py-1">
                <div class="container-fluid">
                    <ul class="nav navbar-nav flex-row flex-fill" role="tablist">
                        <li class="nav-item me-1" role="presentation">
                            <a href="#scheda-1" id="tab-ana" class="navbar-nav-link navbar-nav-link-icon active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-info"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Anagrafica</span>
                                </div>
                            </a>
                        </li>
                        <li v-show="utente.FK_ID_LIVELLO != 5" class="nav-item me-1" role="presentation">
                            <a href="#scheda-2" class="navbar-nav-link navbar-nav-link-icon rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-list-checks"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Permessi</span>
                                </div>
                            </a>
                        </li>
                        <li v-show="utente.FK_ID_LIVELLO == 5" class="nav-item me-1" role="presentation">
                            <a href="#scheda-3" id="tab-ordini" class="navbar-nav-link navbar-nav-link-icon rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-coins"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Ordini</span>
                                </div>
                            </a>
                        </li>
                        <li v-show="utente.FK_ID_LIVELLO == 5" class="nav-item me-1" role="presentation">
                            <a href="#scheda-4" class="navbar-nav-link navbar-nav-link-icon rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-cards"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Tessere</span>
                                </div>
                            </a>
                        </li>
                        <li v-show="utente.FK_ID_LIVELLO == 5" class="nav-item me-1" role="presentation">
                            <a href="#scheda-5" class="navbar-nav-link navbar-nav-link-icon rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-clock-clockwise"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Storico</span>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item me-1" role="presentation">
                            <a href="#scheda-6" class="navbar-nav-link navbar-nav-link-icon rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-megaphone"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Accessi</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="content content-2">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="scheda-1">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-3"><i class="ph-info me-2"></i>Anagrafica</legend>	
                        <div class="row mb-1">
                            <div class="col-lg-4">
                                <div class="form-floating">
                                    <div class="form-control-plaintext" style="border-bottom: 1px dashed #ccc;"> {{ utente.persona.NOME }} </div>
                                    <label class="fs-sm">NOME</label>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-floating">
                                    <div class="form-control-plaintext" style="border-bottom: 1px dashed #ccc;"> {{ utente.persona.COGNOME }} </div>
                                    <label class="fs-sm">COGNOME</label>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-floating">
                                    <div class="form-control-plaintext text-uppercase" style="border-bottom: 1px dashed #ccc;"><Flag :country="utente.persona.FK_ID_NAZIONALITA" class="rounded me-2"></Flag>{{ utente.persona.nazionalita.NOME }}  </div>
                                    <label class="fs-sm">NAZIONALITÀ</label>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-1">
                            <div class="col-lg-4">
                                <div class="form-floating">
                                    <div class="form-control-plaintext text-uppercase" v-if="utente.persona.comuneNascita != null"  style="border-bottom: 1px dashed #ccc;"> {{ utente.persona.comuneNascita.NOME }} </div>
                                    <label class="fs-sm">NATA A</label>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-floating">
                                    <div class="form-control-plaintext" style="border-bottom: 1px dashed #ccc;"> {{ utente.persona.DATA_NASCITA }} </div>
                                    <label class="fs-sm">IL</label>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-floating">
                                    <div class="form-control-plaintext text-uppercase" style="border-bottom: 1px dashed #ccc;"> {{ utente.persona.CODICE_FISCALE }} </div>
                                    <label class="fs-sm">CODICE FISCALE</label>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-1">
                            <div class="col-lg-6">
                                <div class="form-floating">
                                    <div class="form-control-plaintext text-uppercase" style="border-bottom: 1px dashed #ccc;"> {{ utente.persona.INDIRIZZO }} N {{ utente.persona.CIVICO }} </div>
                                    <label class="fs-sm">INDIRIZZO RESIDENZA</label>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-floating">
                                    <div class="form-control-plaintext" style="border-bottom: 1px dashed #ccc;"> {{ utente.persona.CAP }} </div>
                                    <label class="fs-sm">CAP</label>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-floating">
                                    <div class="form-control-plaintext text-uppercase" v-if="utente.persona.comuneResidenza != null" style="border-bottom: 1px dashed #ccc;"> {{ utente.persona.comuneResidenza.NOME }} <small>({{ utente.persona.PROV }})</small></div>
                                    <label class="fs-sm">CITTÀ</label>
                                </div>
                            </div>
                        </div>

	                    <div class="row mb-1">
	                        <div class="col-lg-6">
	                            <div class="form-floating">
	                                <div class="form-control-plaintext text-lowercase" style="border-bottom: 1px dashed #ccc;"> {{ utente.persona.EMAIL }} </div>
	                                <label class="fs-sm">EMAIL</label>
	                            </div>
	                        </div>
	                        <div class="col-lg-4">
	                            <div class="form-floating">
	                                <div class="form-control-plaintext" style="border-bottom: 1px dashed #ccc;"> {{ utente.persona.TELEFONO }} </div>
	                                <label class="fs-sm">TELEFONO</label>
	                            </div>
	                        </div>
	                        <div class="col-lg-2">
	                            <div class="form-floating">
	                                <div class="form-control-plaintext" style="border-bottom: 1px dashed #ccc;"> {{ utente.persona.SDI }} </div>
	                                <label class="fs-sm">SDI</label>
	                            </div>
	                        </div>
	                    </div>


                    </fieldset>
                </form>
            </div>
            
			<div v-show="utente.FK_ID_LIVELLO != 5" class="tab-pane fade" id="scheda-2">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-2"><i class="ph-list-checks me-2"></i>Moduli e Funzioni abilitate</legend>	

                        <div class="box-29">
                            <table class="table table-bordered table-hover table-striped table-togglable">
                                <thead class="sticky-top">
                                    <tr class="bg-secondary text-white">
                                        <th class="table-head-sm" style="">Modulo <span class="float-end me-3">All</span></th> 
                                        <th class="table-head-sm" style="width: 10%;">Read</th> 
                                        <th class="table-head-sm" style="width: 10%;">Add</th> 
                                        <th class="table-head-sm" style="width: 10%;">Edit</th>  
                                        <th class="table-head-sm" style="width: 10%;">Copy</th>  
                                        <th class="table-head-sm" style="width: 10%;">Delete</th> 
                                    </tr>
                                </thead> 
                                <tbody>
                                    <tr v-for="gruppo in arrayGruppi" v-bind:key="gruppo.ID_GRUPPO" v-show="gruppo.FLG_PROFILO == 0">
                                        <td style="">
                                            {{gruppo.DESCRIZIONE}}
                                        </td> 
                                        <td class="text-center fs-sm"  v-for="funzionalita in gruppo.FUNZIONALITA" v-bind:key="funzionalita.ID_FUNZIONALITA">
                                            <span class="badge bg-primary" v-if="funzionalita.SELEZIONATO == 1">SI</span>
                                            <span class="badge bg-light text-dark" v-else>NO</span>
                                        </td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </fieldset>
                </form>        
            </div>            

            <div v-show="utente.FK_ID_LIVELLO == 5" class="tab-pane fade" id="scheda-3">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-1 mb-3"><i class="ph-coins me-2"></i>Storico delle prenotazioni
                            <span class="float-end" style="position: relative; top: -5px;">
                                <select class="form-select form-select-sm form-select-slim changeyear" id="comboAnno" v-model="searchOrdini.ANNO_ORDINE.VALUE" :tabindex="4">
                                    <option value="">Sel. Anno</option>
                                    <option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
                                </select>
                            </span> 
                        </legend>	
                    
                        <div class="tableFixHead tableFixHeadRoles">
                            <table class="table table-bordered table-hover table-striped table-togglable">
                                <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:12%;"><span >PNR</span></th>
										<th class="table-head-sm" style="width:16%;"><span>Data e ora</span></th>
										<th class="table-head-sm" style=""><span>TITOLO</span></th>
										<th class="table-head-sm text-center" style="width:16%;"><span>Importo</span></th>
										<th class="table-head-sm text-center" style="width:17%;"><span>Stato</span></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="ordine in paginatedListOrdini.arr" v-bind:key="ordine.ID_ORDINE">
										<td class="td-class fs-sm" style=""><span class="badge bg-info bg-opacity-20 text-info badge-xs cursor-pointer"> {{ ordine.PNR_FORMATTATO }} </span></td>
										<td class="td-class fs-sm" style=""><span> {{filters.formatDate(ordine.DATA_CREAZIONE) }} </span></td>
										<td class="td-class fs-sm" style=""><span> {{ ordine.TITOLO_PRATICA }} </span></td>
                                        <td class="td-class fs-sm text-center" style=""><span>&euro; {{ ordine.PREZZO_TOTALE }} </span></td>
                                        <td class="td-class fs-sm text-center" style=""><span class="badge bg-opacity-20 badge-xs text-lowercase" v-bind:class="getBtnStatusClass(ordine)"> {{ filters.getLabel($root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',ordine.FK_ID_STATO,'DESCRIZIONE') }} </span></td>
									</tr>
								</tbody>
                            </table>
                        </div>                         
                        
                    </fieldset>
                </form>  
            </div>           

            <div v-show="utente.FK_ID_LIVELLO == 5" class="tab-pane fade" id="scheda-4">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-3"><i class="ph-cards me-2"></i>Storico delle Tesssere
                            
                        </legend>	
                      
                        
                        
                    </fieldset>
                </form>  
            </div>

            <div v-show="utente.FK_ID_LIVELLO == 5" class="tab-pane fade" id="scheda-5">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-3"><i class="ph-clock-clockwise me-2"></i>Storico del cliente
                            
                        </legend>	
                    
                        <div class="tableFixHead tableFixHeadRoles">
                            <div class="list-feed">
                                <div class="list-feed-item">
                                    <div class="text-muted">Jan 12, 12:47</div>
                                    <a href="#">David Linner</a> requested refund for a double bank card charge
                                </div>

                                <div class="list-feed-item">
                                    <div class="text-muted">Jan 11, 10:25</div>
                                    User <a href="#">Christopher Wallace</a> from Google is awaiting for staff reply
                                </div>

                                <div class="list-feed-item">
                                    <div class="text-muted">Jan 10, 09:37</div>
                                    Ticket <strong>#43683</strong> has been resolved by <a href="#">Victoria Wilson</a>
                                </div>

                                <div class="list-feed-item">
                                    <div class="text-muted">Jan 9, 08:28</div>
                                    <a href="#">Eugene Kopyov</a> merged <strong>Master</strong>, <strong>Demo</strong> and <strong>Dev</strong> branches
                                </div>

                                <div class="list-feed-item">
                                    <div class="text-muted">Jan 8, 07:58</div>
                                    All sellers have received payouts for December, 2016!
                                </div>

                                <div class="list-feed-item">
                                    <div class="text-muted">Jan 7, 06:32</div>
                                    <a href="#">Chris Arney</a> created a new ticket <strong>#43136</strong> and assigned to <a href="#">John Nod</a>
                                </div>
                            </div>
                        </div> 
                        
                    </fieldset>
                </form>  
            </div>

			<div class="tab-pane fade" id="scheda-6">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-2"><i class="ph-megaphone me-2"></i>Elenco Accessi</legend>	
                      
                        <div class="box-29">
                            <table class="table table-bordered table-hover table-striped table-togglable">
                                <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:6%;"><span >Utente</span></th>
										<th class="table-head-sm" style="width:10%;"><span>Data e ora</span></th>
										<th class="table-head-sm" style="width:10%;"><span>Azione</span></th>
										<th class="table-head-sm" style="width:10%;"><span>Ip</span></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="accesso in paginatedListAccessi.arr" v-bind:key="accesso.ID_ACCESSO">
										<td class="td-class fs-sm" style=""><span class="badge bg-primary">{{accesso.USERNAME}}</span></td>
										<td class="td-class fs-sm" style=""><span class="fw-bold cursor-pointer">{{filters.formatDateTime(accesso.DATA_ACCESSO)}}</span></td>
										<td class="td-class fs-sm" style=""><span>{{accesso.INDIRIZZO_IP}}</span></td>
                                        <td class="td-class fs-sm" style=""><span>{{accesso.INDIRIZZO_IP}}</span></td>
									</tr>
								</tbody>
                            </table>
                        </div> 
                        
                    </fieldset>
                </form>  
            </div>

        </div>
    </div>
              

</template>



<script>

import Flag from '@/components/utils/Flag.vue'

export default {
   name:"SchedaVisualizzazioneUtente",

   props:["modulo"],
   emits:['modificaUtente'],

   components:{
		Flag,
   },

   data:function(){
       return{
            filters:global.filters,
            utente : {persona:{comuneNascita:{},comuneResidenza:{},nazionalita:{}},opzioni:new Array()},        
            ts:new Date().getTime(),
            arrayGruppi:new Array(),
            arrayAccessi:new Array(),
            arrayOrdini:new Array(),
            searchGruppi:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:1000000000},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            searchAccessi:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:1000000000},
                /*
                ESITO:{TYPE:'COMBO',VALUE:''},
                USERNAME:{TYPE:'TEXT',VALUE:''},
                SESSION_ID:{TYPE:'TEXT',VALUE:''},
                DATA_ACCESSO:{TYPE:'DATE',VALUE:''},
                */
            },
            anno:new Date().getFullYear(),
            searchOrdini:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:1000000000},
                ANNO_ORDINE:{TYPE:'COMBO',VALUE: ''},
            },
       }
   },

   methods:{
        openSchedaVisualizzazioneUtente : function(utente, tab = "", opzioni = null){
            utils.ajax("travel/utente",{ID_UTENTE:utente.ID_UTENTE}, (response) => {
                this.utente = response.data.UTENTE;
                this.arrayGruppi = new Array();
                this.arrayGruppi = utils.clone(this.$root.arrayGruppi);
                for (var j = 0 ; j < this.arrayGruppi.length ; j++){
                    for (var i = 0 ; i < this.utente.gruppi.length ; i++){                        
                        if (this.utente.gruppi[i].ID_GRUPPO == this.arrayGruppi[j].ID_GRUPPO){
                            this.arrayGruppi[j].SELEZIONATO = 1;
                        }
                    }
                    for (var l = 0 ; l < this.utente.funzionalita.length ; l++){
                        for (var k = 0 ; k < this.arrayGruppi[j].FUNZIONALITA.length ; k++){ 
                            if ( this.utente.funzionalita[l].ID_FUNZIONALITA == this.arrayGruppi[j].FUNZIONALITA[k].ID_FUNZIONALITA ){
                                this.arrayGruppi[j].FUNZIONALITA[k].SELEZIONATO = 1;
                            }
                        }                            
                    }
                } 
                utils.ajax("core/admin/accessi",{ID_UTENTE:utente.ID_UTENTE}, (response) => {
                    this.arrayAccessi = response.data
                    utils.ajax("travel/ordini",{ID_UTENTE:utente.ID_UTENTE,ANNO_ORDINE:0}, (response) => {
                        this.arrayOrdini = response.data
                        for (var i = 0 ; i < this.arrayOrdini.length ; i++){
                            this.arrayOrdini[i].PNR_FORMATTATO = this.filters.getPnr(this.arrayOrdini[i]);
                            this.arrayOrdini[i].SELEZIONATO = 0;
                        }
                    });
                    if (tab == "ordini"){
                        var t = document.getElementById("tab-ordini");
                        t.click();
                        if (opzioni != null){
                           this.searchOrdini.ANNO_ORDINE.VALUE = opzioni;
                        }
                    }
                    else{
                        var t = document.getElementById("tab-ana");
                        t.click();
                    }
                });
                

            });           
        },

        modificaUtente : function(){
            this.$emit("modificaUtente",this.utente);
        },

        getLabelOpzioneUtente : function(label){
            var opzione = utils.array.select(this.utente.opzioni,"LABEL",label,1)[0];
            if (opzione != undefined){
                return filters.getLabel(this.$root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',opzione.VALORE,'DESCRIZIONE');
            }
        },

        getBtnStatusClass : function(ordine){
            var arr = utils.array.select(this.$root.arrayPraticaStato,"ID_OPZIONE_TIPOLOGICA",ordine.FK_ID_STATO,1);
            if (arr.length > 0){
                return "bg-"+ arr[0].ICONA + " " + "text-" + arr[0].ICONA ;
            }
            return "";
        },

   },

   computed:{
        paginatedListGruppi(){
            var arr = utils.inGrid(this.arrayGruppi,this.searchGruppi);
            return arr;
        },
        paginatedListAccessi(){
            var arr = utils.inGrid(this.arrayAccessi,this.searchAccessi);
            return arr;
        },
        paginatedListOrdini(){
            var arr = utils.inGrid(this.arrayOrdini,this.searchOrdini);
            return arr;
        },
       
   },

   created:function(){
        this.arrayGruppi = utils.clone(this.$root.arrayGruppi);
    }

}
</script>