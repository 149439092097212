<template>
        
    <!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
                    <a href="#" class="d-none d-lg-inline-block text-dark fw-semibold cursor-pointer"> Contabilità </a>  <span class="d-none d-lg-inline-block">\</span>
                    <span class="text-muted ms-1"><Translate :path="'dizionario.base.GESTIONE'"></Translate> Prima Nota</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
						
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;">
						
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(10)" title="Opzioni Contabilità" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(10)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(10)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
            </div>
        </div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#prima-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7"></i>
								<span class="d-lg-none ms-1">Prima Nota</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.GESTIONE'" class="me-1"></Translate>Prima Nota</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#prima-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1" v-on:click="statisticheStartup();">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>	
								<span class="d-lg-none ms-1">Stats.</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate></span>
							</div>
						</a>
					</li> 
					<li class="nav-item d-lg-none ms-auto">
						<a href="#profile_nav" class="navbar-nav-link navbar-nav-link-icon rounded-pill rounded-pill-small collapsed" data-bs-toggle="collapse" aria-expanded="false">
							<i class="ph-caret-down collapsible-indicator"></i>
						</a>
					</li>
				</ul>

			   	<Datepicker autocomplete="off" class="ms-lg-auto bg-light text-center mt-xs-1" style="width:320px;" v-model="intervalloDate" range multi-calendars multi-calendars-solo :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" :partial-range="false" placeholder="Data Ordine da > a" :tabindex="2" />
				<span class="btn btn-primary btn-sm ms-1" v-on:click="getMovimenti()">AGGIORNA</span>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0 select-small">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
									<i class="ph-gear me-1"></i> Azioni Multiple
									<span class="d-lg-none ms-2">More</span>
								</a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="ph-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
		                                <div class="dropdown-menu">
											<a v-on:click="esportaElenco(2)" class="dropdown-item cursor-pointer"><i class="ph-file-xls fa-fw me-2"></i><span class="text-muted me-1">|</span>Formato Excel</a>
											<a v-on:click="esportaElenco(1)" class="dropdown-item cursor-pointer"><i class="ph-file-pdf fa-fw me-2"></i><span class="text-muted me-1">|</span>Formato Pdf</a>
		                                </div>
	                            	</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="ph-trash me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina selezionati</a>
								</div>
							</div>
						</li>
						<li class="nav-item">
	
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="prima-a">
				<div class="card shadow mb-xs-3 mb-lg-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="primo-a">
									<div class="row">
										<div class="col-8 col-md-1 col-lg-1 mbx-1">
		                                    <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Movimento" :tabindex="6" v-model="search.ID_MOVIMENTO.VALUE" />
										</div> 
										<div class="col-4 col-md-2 col-lg-2 mbx-1">
		                                    <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Ragione sociale" :tabindex="6" v-model="search.DESC_FORNITORE.VALUE" />
										</div>
										<div class="col-12 col-md-2 col-lg-2 mbx-1">
		                                    <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Note" :tabindex="6" v-model="search.DESCRIZIONE.VALUE" />
										</div>
										<div class="col-4 col-md-2 col-lg-2 mbx-1">
		                                    <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Utente" :tabindex="6" v-model="search.USERNAME.VALUE" />
										</div>
										<div class="col-4 col-md-2 col-lg-2 mbx-1">
											<select class="form-select form-select-sm" data-placeholder="Conto" :tabindex="20" v-model="search.FK_ID_CONTO.VALUE">
												<option value="">Selezione</option>
												<option v-for="conto in $root.arrayConti" v-bind:key="conto.ID_OPZIONE_TIPOLOGICA" v-bind:value="conto.ID_OPZIONE_TIPOLOGICA">&nbsp;&nbsp;&nbsp;{{ conto.CUSTOM_1 }}</option>
											</select>
										</div>
										<div class="col-4 col-md-1 col-lg-1 mbx-1"></div>
										<div class="col-4 col-md-2 col-lg-2 mbx-1"></div>
									</div>
								</form>
							</div>
						</div>
					</div>

					<div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="div_table_resp tableFixHead" id="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable text-nowrap"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width: 6%;"> #<br /><span class="float-end">Ord. </span></th>
										<th class="table-head-sm">Ragione Sociale<br />Contatti</th>
										<th class="table-head-sm" style="width:9%;"> Data<br />Movimento</th>
										<th class="table-head-sm" style="width:20%;">Descrizione</th>
										<th class="table-head-sm" style="width:13%;">Importo</th>
										<th class="table-head-sm" style="width:13%;">Utente</th>
										<th class="table-head-sm" style="width:15%;">Conto</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="movimento in paginatedList.arr" v-bind:key="movimento.ID_MOVIMENTO">
                                        <td class="td-class fs-sm">
											<span>{{ movimento.ID_MOVIMENTO }}</span><br />
											<small class="badge badge-xs bg-light text-primary float-end">&nbsp;</small>
										</td>
	                                    <td class="td-class fs-sm">
											<span class="text-primary fw-bold text-uppercase cursor-pointer" v-on:click="openOffCanvas();openPopUpMovimento(movimento)">{{ movimento.INTESTATARIO_MOVIMENTO }}</span><br />
											<span class="text-muted">&nbsp;</span>
	                                    </td>
										<td class="td-class text-center fs-sm">
											<span>{{ filters.formatDate(movimento.DATA_MOVIMENTO) }}</span>
										</td>
	                                    <td class="td-class fs-sm">
											{{ movimento.DESCRIZIONE }}
	                                    </td>
										
										<td class="td-class fs-sm">
											<span class="" v-bind:class="{ 'text-success':movimento.SEGNO == '+','text-danger':movimento.SEGNO == '-' }">&euro; {{ movimento.SEGNO }} {{ movimento.IMPORTO }}</span>
										</td>

										<td class="td-class text-center fs-sm">
											<div class="fw-bold"> {{ movimento.USERNAME }}</div>
											<div class="fw-bold"> {{ movimento.UTENTE }}</div>
										</td>
										<td class="td-class text-center fs-sm">
											<div class="fw-bold">{{ movimento.DESC_CONTO }}</div>
										</td>
										<!--
										{ 
										 "FK_ID_SCADENZARIO": 1, 
										 "FK_ID_AZIENDA_FORNITORE": 8613, 
										 "FK_ID_UTENTE": 1, 
										 "FK_ID_CONTO": 54, 
										 "SEGNO": "-", 
										 "": "aaaa", 
										 "FK_ID_ENTE": 1,  
										 "DATA_CREAZIONE": "0000-00-00 00:00:00", 
										
										 }
										-->
									</tr>
								</tbody>
							</table>
						</div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">MOVIMENTI: {{ paginatedList.count }}</span> 
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">TOTALE: </span>
                                </div>
                          	</div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end pag-mobile">
		                            <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH"></Pagine>
		                        </div>
						    </div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
							</div>
			        	</div>
		
					</div>
				</div>
			</div>

			<div class="tab-pane fade" id="prima-b"> 

				<div class="row">
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-users ph-2x text-primary me-3"></i>
								<div class="flex-fill text-end">
									<h4 class="mb-0"></h4>
									<span class="text-muted">Totale Incassato</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-thumbs-up ph-2x text-success me-3"></i>

								<div class="flex-fill text-end">
									<h4 class="mb-0"></h4>
									<span class="text-muted">Utenti attivi</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-thumbs-down ph-2x text-danger me-3"></i>
								<div class="flex-fill text-end">
									<h4 class="mb-0"></h4>
									<span class="text-muted">Utenti 0 pratiche</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0"></h4>
									<span class="text-muted">Utenti bannati</span>
								</div>
								<i class="ph-house-simple ph-2x text-danger ms-3"></i>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0">2365</h4>
									<span class="text-muted">Utenti </span>
								</div>
								<i class="ph-sketch-logo ph-2x text-indigo ms-3"></i>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0">389,438</h4>
									<span class="text-muted">Utenti </span>
								</div>
								<i class="ph-globe-hemisphere-east ph-2x text-purple ms-3"></i>
							</div>
						</div>
					</div>
				</div>

				<Statistiche ></Statistiche> 

			</div>

        </div>
	</div>


	<!-- START MODALI -->
	<div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right_ordini" role="dialog" data-bs-backdrop="false" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
			<h5 class="offcanvas-title fw-semibold">{{ movimentoSelezionato.DESCRIZIONE }}</h5>
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">
			
		<!-- PARTE 1: intestatario
			
			TROVI TUTTO DENTRO LA VARIABILE intestatario che è una persona giuridica (quindi movimentoSelezionato.intestatario.personaGiuridica) SE movimentoSelezionato.tipologiaMovimento IN (1,2)
			SE, INVECE, movimentoSelezionato.tipologiaMovimento == 3 TROVI l'intestatario in movimentoSelezionato.intestatario.personaFisica

		-->

		<!-- PARTE 2: intestatario

			se movimentoSelezionato.tipologiaMovimento == 1 
			DEVI MOSTRARE IN FORMATO TABELLARE LE QUOTE CHE STANNO DENTRO
			movimentoSelezionato.scadenzario.QUOTE;

			else

			mostrare in formato tabellare gli incassi che stanno dentro 
			movimentoSelezionato.incassi

		-->

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <div style="position: relative; top: 2px;">
                <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>
            </div>       
        </div>  
	</div>  

	<!--
	<div class="modal fade" id="popUpScadenzarioQuote" role="dialog" tabindex="-1">
	    <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 85% !important;">Scheda Prodotto</h6>   
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div> 
                <div class="modal-body p-2" style="height: 32rem;">




                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaMovimento()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>                
            </div>
        </div>
    </div>
	-->
	

</template>



<script>

//import Pagine from '@/components/utils/Pagine.vue'
import Datepicker from '@vuepic/vue-datepicker' 
import Statistiche from '@/components/Statistiche.vue'


export default {
    name:"PrimaNota",		 

    components:{
        //Pagine,
        Datepicker,
		Statistiche
    },		   

    data:function(){
        return {
            apiStartup:'travel/ordini',
            filters:global.filters,
			rs : utils.generateRandomString(30),
			GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            arrayMovimenti:new Array(),
			intervalloDate:{},
			dataStart:'',
			dataEnd:'',
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				ID_MOVIMENTO:{TYPE:'TEXT',VALUE:''},
	            DESC_FORNITORE:{TYPE:'TEXT',VALUE:''},
	            DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
				USERNAME:{TYPE:'TEXT',VALUE:''},
				FK_ID_CONTO:{TYPE:'COMBO',VALUE: ''},
	        },
			movimentoSelezionato:{intestatario:{},scadenzario:{},incassi:new Array()}
			
        }
    },

    methods:{		
		getMovimenti : function(){
			global.utils.ajax('travel/movimenti',{DATA_START:this.dataStart,DATA_END:this.dataEnd,FK_ID_MODULO:1}, (response,status) => {    
				this.arrayMovimenti = response.data;
			});
		},

		openOffCanvas : function(){
            var myOffcanvas = document.getElementById('panel_right_ordini')
            var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
            bsOffcanvas.show();
        },
		
		openPopUpMovimento : function(movimento){
			utils.ajax("travel/movimento",{ID_MOVIMENTO:movimento.ID_MOVIMENTO}, (response) => {
				this.movimentoSelezionato = utils.clone(response.data);
			});			
		},

		esportaElenco : function(formato){
			var filtri = utils.clone(this.search);
			filtri.DATA_START = this.dataStart;
			filtri.DATA_END = this.dataEnd;
			global.utils.ajax('core/report/basic/token',{modalita:'MOONLIGHT_esportaPrimaNota',filtri:filtri}, (response) => {
				if (response.esito == 0){
					window.open(utils.BASE_API + "core/report?ID_REPORT=1002&token="+response.messaggio+"&formato="+formato + "&SESSION_TOKEN="+global.utils.SESSION_TOKEN);
				}
			});
		}

    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayMovimenti,this.search);            
            return arr;
        },
		
    }, 

	watch : {
		intervalloDate : function(valore,valoreOld){
            if (this.intervalloDate != null && this.intervalloDate != undefined){ 
                this.dataStart = this.filters.formatDate(this.intervalloDate[0]);
                this.dataEnd = this.filters.formatDate(this.intervalloDate[1]);
            }
        },
	},

    created : function(){
       	
    },
	
}
</script>


<style scoped>
	.grafico { min-height: 451px; }
</style>