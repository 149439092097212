<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
					<router-link href="#" class="text-dark cursor-pointer" :to="'Varianti'"> Elenco Varianti </router-link> \
					<a href="#" class="text-dark"> {{ $root.appoggio.variante.CODICE_VARIANTE }}  </a> \
					<span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Price</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-flex align-items-center text-body lh-1 py-sm-2" style="text-align: left;" v-on:click="nuovaVariantePrice()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1">Nuova Variante</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(5)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
            </div>
        </div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#varianti-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.ELENCO'"></Translate> Varianti</span>
							</div>
						</a>
					</li>
				</ul>

				<a class="rounded btn bg-danger bg-opacity-20 btn-sm-light me-2" v-on:click="getVarianti()" v-show="ordineHasChanged">Annulla ordinamento</a>
				<a class="rounded btn bg-success bg-opacity-20 btn-sm-light" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</a>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<a href="#" class="btn btn-light btn-icon btn-sm rounded dropdown-toggle" data-bs-toggle="dropdown">
								<i class="ph-gear me-1"></i> Azioni Multiple
								<span class="d-lg-none ms-2">More</span>
							</a>
							<div class="dropdown-menu dropdown-position dropdown-menu-end">
								<a href="#" class="dropdown-item" @click="modificaVariantiPrice()">
									<i class="ph-image me-2"></i> Modifica Price
								</a>
								<a href="#" class="dropdown-item" v-on:click="deleteVariantiPrice()">
									<i class="ph-trash me-2"></i> Elimina Price
								</a>
							</div>
						</li>
						<li class="nav-item ms-3">
								
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
	<div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="varianti-a">
		        <div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="variante-a">
			                        <div class="row">           
			                            <div class="col-5 col-md-1">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Variante" v-model="search.ID_VARIANTE_PRICE.VALUE" :tabindex="1" />
			                            </div>           
			                            <div class="col-7 col-md-2">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Prodotto" v-model="search.TITOLO.VALUE" :tabindex="1" />
			                            </div>  
										<div class="col-8 col-md-2">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Opzione" v-model="search.OPZIONE.VALUE" :tabindex="1" />
										</div>              
			                            <div class="col-12 col-md-2">
											<select class="form-select form-select-sm" data-placeholder="Stato" v-model="search.TARGET.VALUE" :tabindex="4">
												<option value="">Target</option>
												<option v-bind:value="'PREZZO'">PREZZO</option>
												<option v-bind:value="'COSTO'">COSTO</option>
												<option v-bind:value="'MARKUP'">MARKUP</option>
												<option v-bind:value="'QUOTA'">QUOTA</option>
											</select>
			
			                            </div>
			                            <div class="col-8 col-md-2">
											<select class="form-select form-select-sm" data-placeholder="Stato" v-model="search.TIPO_AZIONE.VALUE" :tabindex="4">
												<option value="">Tipo operazione</option>
												<option v-bind:value="'MINUS_PERC'">MINUS_PERC</option>
												<option v-bind:value="'MINUS_VAL'">MINUS_VAL</option>
												<option v-bind:value="'PLUS_PERC'">PLUS_PERC</option>
												<option v-bind:value="'PLUS_VAL'">PLUS_VAL</option>
												<option v-bind:value="'FIXED_PRICE'">FIXED_PRICE</option>
											</select>
										</div>

										<div class="col-12 col-md-3">
											<div class="row">
												<div class="col-8 col-md-3">
												</div>
												<div class="col-6 col-md-5">
					                            </div>
												<div class="col-6 col-md-4">
													<a href="#collapse-a" class="btn btn-secondary btn-sm w-100 collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
														+ FILTRI
													</a>
					                            </div>  
					                        </div>
										</div>
					                </div>
								</form>
				            </div>
		                </div>
		            </div>
							 
			        <div class="card-body mt-0 mb-0">
						<div class="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
			                            <th class="table-head-sm" style="width:2%;"> <input id="chkAllVarianti" v-on:click="filters.selectAll('chkAllVarianti',paginatedList.arr)" type="checkbox" class=""> </th>
			                            <th class="table-head-sm" style="width:6%;"> # </th>
			                            <th class="table-head-sm">Nome Prodotto /<br />SEO / Codice <button class="btn btn-danger btn-sm" v-show="this.search.FK_ID_OPZIONE.VALUE > 0" v-on:click="this.search.FK_ID_OPZIONE.VALUE = '';this.search.FK_ID_PRODOTTO.VALUE = ''">Rimuovi evidenza</button></th>
			                            <th class="table-head-sm" style="width:12%;">Valido Dal /<br />Valido Al</th>
			                            <th class="table-head-sm" style="width:15%;">Tipo azione</th>
			                            <th class="table-head-sm" style="width:5%;">Tipo pax</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Stato</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Azioni</th>
			                        </tr>
			                    </thead>
		                        <tbody>
		                            <tr v-for="price in paginatedList.arr" v-bind:key="price.ID_VARIANTE_PRICE">
		                                <td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="price.SELEZIONATO" class=""> 
										</td>
		                                <td class="td-class fs-sm">
											<span>{{price.ID_VARIANTE_PRICE}}</span><br />
											<small class="badge bg-light text-primary float-end"> </small>
										</td>
		                                <td class="td-class fs-sm">
		                                    <span v-on:click="modificaVariantiPrice(price)" class="text-primary fw-bold cursor-pointer">{{price.TITOLO}} | {{ price.OPZIONE }}</span><br />
		                                    <span class="badge bg-dark me-1">a</span> 
		                                    <span class="badge bg-success me-1">Prenotabile</span> 
		                                    <span class="badge bg-info me-1" v-on:click="mettiInEvidenza(price)">Metti in Evidenza</span> 
		                                    <span class="badge bg-success">Visibile</span> 
		                                </td>
		                                <td class="td-class fs-sm">{{filters.formatDate(price.DATA_START)}}<br>{{filters.formatDate(price.DATA_END)}}</td>
		                                <td class=""><span class="bg" v-bind:class="{'bg-success': (price.TIPO_AZIONE == 'MINUS_VAL' || price.TIPO_AZIONE == 'MINUS_PERC'),'bg-info':(price.TIPO_AZIONE == 'FIXED_VAL'), 'badge-danger': (price.TIPO_AZIONE == 'PLUS_VAL' || price.TIPO_AZIONE == 'PLUS_PERC')}">{{price.TARGET}} |  {{price.TIPO_AZIONE}}  | {{price.VALORE}}</span></td>
		                                <td class="td-class fs-sm">{{filters.formatDate(price.EARLYB_DATA_START)}}<br>{{filters.formatDate(price.EARLYB_DATA_END)}}</td>
		                                <td class="">{{price.TIPO_AZIONE}}
										</td>
		                                <td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
	                                                <a href="#" class="dropdown-item" v-on:click="modificaVariantiPrice(price)"><i class="fal fa-pencil fa-fw"></i> Modifica Variante</a>
													<div class="dropdown-divider"></div>
	                                                <a href="#" class="dropdown-item" v-on:click="copiaVariante(price)"><i class="fal fa-copy fa-fw"></i> Copia Variante</a>
													<div class="dropdown-divider"></div>
	                                                <a href="#" class="dropdown-item" v-on:click="deleteVariantiPrice(price)"><i class="fal fa-trash-alt fa-fw"></i> Elimina Variante</a>
	                                            </div>
	                                        </div>
		                                </td>
		                            </tr>
		                        </tbody>
		                    </table>
	                    </div>
		
		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
		                        <div class="float-end">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
			                    </div>
	                		</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
	            			</div>
	        			</div>
					</div>
				</div>	   
    		</div>	
 
		</div>    
	</div>


	<!-- START MODALI -->
	<div class="modal fade" id="popUpVariantePrice" role="dialog" tabindex="-1">
	    <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 85% !important;">SCHEDA VARIANTE</h6>   
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
                </div> 
                <div class="modal-body p-2" style="height: 33rem;">
					<div class="row">
						<div class="col-md-3" v-show="variantePrice.FLG_INSERT == 1">
							<button class="btn btn-primary text-center" v-on:click="openRicercaProdotti()">Seleziona prodotti</button>
						</div>
						<div class="col-md-9">
							<h2>{{ lblProdottiSelezionati }}</h2>
						</div>
					</div>
					<hr />
					<div class="card p-2" v-for="(v,$index) in variantePrice.varianti" v-bind:key="v">
						<div class="row">
							<div class="col-md-5">
								<div class="row">
									<div class="form-group col-md-6" style="margin-bottom:.75rem">
										<Datepicker  v-model="v.DATA_START" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" placeholder="Valido Dal" :tabindex="7"></Datepicker>
									</div>
									<div class="form-group col-md-6" style="margin-bottom:.75rem">
										<Datepicker  v-model="v.DATA_END" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" placeholder="Valido Al" :tabindex="7"></Datepicker>
									</div>
								</div>	
							</div>
							<div class="col-md-5">
								<div class="row">
									<div class="form-group col-md-6" style="margin-bottom:.75rem">
										<Datepicker  v-model="v.EARLYB_DATA_START" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" placeholder="E.B. Inizio" :tabindex="7"></Datepicker>
									</div> 
									<div class="form-group col-md-6" style="margin-bottom:.75rem">
										<Datepicker  v-model="v.EARLYB_DATA_END" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" placeholder="E.B. Fine" :tabindex="7"></Datepicker>
									</div>
								</div>
							</div>
							<div class="col-md-2 form-group" style="margin-bottom:.75rem">
								<select class="form-select form-select-sm" v-model="v.EARLYB_GIORNI">
									<option value="0">E.B. giorni Tutti</option>
									<option v-for="i in 100" v-bind:value="i" v-bind:key="i">{{i}}</option>
								</select>
							</div>
						</div>
						

					

						<div class="row">
							<div class="form-group col-md-2" style="margin-bottom:.75rem">
								<select class="form-select form-select-sm" v-model="v.MIN_STAY">
									<option value="0">Min stay</option>
									<option v-for="i in 31" v-bind:value="i" v-bind:key="i">{{i}}</option>
								</select>
							</div>
							<div class="form-group col-md-2" style="margin-bottom:.75rem">
								<select class="form-select form-select-sm" v-model="v.MAX_STAY">
									<option value="0">Min stay</option>
									<option v-for="i in 31" v-bind:value="i" v-bind:key="i">{{i}}</option>
								</select>
							</div>
							<div class="form-group col-md-2" style="margin-bottom:.75rem">
								<select class="form-select form-select-sm" v-model="v.TARGET">
									<option value="">Target</option>
									<option v-bind:value="'PREZZO'">PREZZO</option>
									<option v-bind:value="'COSTO'">COSTO</option>
									<option v-bind:value="'MARKUP'">MARKUP</option>
									<option v-bind:value="'QUOTA'">QUOTA</option>
								</select>
							</div>
							<div class="form-group col-md-2" style="margin-bottom:.75rem">
								<select class="form-select form-select-sm" v-model="v.TIPO_AZIONE">
									<option value="">Tipo operazione</option>
									<option v-bind:value="'MINUS_PERC'">MINUS_PERC</option>
									<option v-bind:value="'MINUS_VAL'">MINUS_VAL</option>
									<option v-bind:value="'PLUS_PERC'">PLUS_PERC</option>
									<option v-bind:value="'PLUS_VAL'">PLUS_VAL</option>
									<option v-bind:value="'FIXED_PRICE'">FIXED_PRICE</option>
								</select>
							</div>
							<div class="form-group col-md-2" style="margin-bottom:.75rem">
								<input type="text" class="form-control form-control-sm" placeholder="Valore" v-model="v.VALORE">
							</div>
							<div class="form-group col-md-2" style="margin-bottom:.75rem">
								<button class="btn btn-danger" v-show="variantePrice.FLG_INSERT == 1" v-on:click="variantePrice.varianti.splice($index,1)"></button>
								<!-- <input type="checkbox" class="form-control form-control-sm" true-value="1" false-value="0" placeholder="Prezzo" v-model="v.FLG_COSTO">Crea variante anche per il costo -->
							</div>
						</div> 	
					</div>
					<button class="btn btn-success float-end" v-show="variantePrice.FLG_INSERT == 1" v-on:click="addVariante()">+</button>




				</div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaVariantePrice()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>                
            </div>
        </div>
    </div>



	<RicercaProdotti @prodottiSelezionati="handlerProdottiSelezionati($event)" :ref="'ricercaProdotti'"></RicercaProdotti>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import RicercaProdotti from '@/components/RicercaProdotti.vue'
import Datepicker from '@vuepic/vue-datepicker';
import { utils } from '../../assets/js/utils';


export default {
    name:"Variante",  

    components:{
        Pagine,
		RicercaProdotti,
        Datepicker
    }, 

    data:function(){
        return {
            filters:global.filters,
			GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),            
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25}, 
				ID_VARIANTE_PRICE:{TYPE:'TEXT',VALUE:''},       
				TITOLO:{TYPE:'TEXT',VALUE:''},      
				OPZIONE:{TYPE:'TEXT',VALUE:''},  
				TARGET:{TYPE:'COMBO',VALUE:''},      
				TIPO_AZIONE:{TYPE:'COMBO',VALUE:''},
				FK_ID_OPZIONE:{TYPE:'COMBO',VALUE:''},
				FK_ID_PRODOTTO:{TYPE:'COMBO',VALUE:''},
            },
            variantePrice : {prodottiSelezionati:new Array(),varianti:new Array(),FLG_INSERT:1},
            variante:{arrayPrice:new Array()},
        }
    },

    methods : {
		getVariante : function(){
			utils.ajax("travel/variante",{ID_VARIANTE:this.$root.appoggio.variante.ID_VARIANTE},(response)=>{
				this.variante = utils.clone(response.data);            
			});
		},

		openRicercaProdotti : function(){
			this.$refs.ricercaProdotti.openRicerca();
		},

		nuovaVariantePrice : function(){
			this.variantePrice = {FK_ID_VARIANTE:this.variante.ID_VARIANTE,prodottiSelezionati:new Array(),varianti:new Array(),FLG_INSERT:1};
			this.filters.openPopUp('popUpVariantePrice');
		},
		modificaVariantiPrice : function(price = null){
			if (price == null){
				this.variantePrice = {FK_ID_VARIANTE:this.variante.ID_VARIANTE,prodottiSelezionati:new Array(),varianti:new Array(),FLG_INSERT:0};
				this.variantePrice.prodottiSelezionati = utils.array.select(this.variante.arrayPrice,"SELEZIONATO",1);
				if (this.variantePrice.prodottiSelezionati.length > 0){
					this.variantePrice.varianti.push({
						DATA_START:'',
						DATA_END:'',
						EARLYB_DATA_START:'',
						EARLYB_DATA_END:'',
						EARLYB_GIORNI:0,
						MIN_STAY:0,
						MAX_STAY:0,
						TARGET:'',
						TIPO_AZIONE:'',
						VALORE:0,
					});
					this.filters.openPopUp('popUpVariantePrice');
				}
				else{
					utils.alert.warning("Selezionare almeno una price");
				}
			}
			else{
				this.variantePrice = {FK_ID_VARIANTE:this.variante.ID_VARIANTE,prodottiSelezionati:new Array(),varianti:new Array(),FLG_INSERT:0};
				this.variantePrice.prodottiSelezionati = new Array(price);
				var p = utils.clone(price);
				p.DATA_START = this.filters.formatDate(p.DATA_START);
				p.DATA_END = this.filters.formatDate(p.DATA_END);
				p.EARLYB_DATA_START = this.filters.formatDate(p.EARLYB_DATA_START);
				p.EARLYB_DATA_END = this.filters.formatDate(p.EARLYB_DATA_END);
				this.variantePrice.varianti.push(p);
				this.filters.openPopUp('popUpVariantePrice');
			}
		},
		handlerProdottiSelezionati : function($event){
			console.log($event);
			this.variantePrice.prodottiSelezionati = $event;
		},
		addVariante : function(){
			this.variantePrice.varianti.push({
				DATA_START:'',
				DATA_END:'',
				EARLYB_DATA_START:'',
				EARLYB_DATA_END:'',
				EARLYB_GIORNI:0,
				MIN_STAY:0,
				MAX_STAY:0,
				TARGET:'',
				TIPO_AZIONE:'',
				VALORE:0,
			});
		},
		salvaVariantePrice : function(){
			if (this.variantePrice.FLG_INSERT == 1){
				utils.ajax("travel/varianteprice/insert",{ID_VARIANTE:this.$root.appoggio.variante.ID_VARIANTE,variantePrice:this.variantePrice},(response)=>{
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.filters.closePopUp('popUpVariantePrice');
						this.getVariante();
					}
				});				
			}
			else{
				utils.ajax("travel/varianteprice/update",{variantePrice:this.variantePrice},(response)=>{
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.filters.closePopUp('popUpVariantePrice');
						this.getVariante();
					}
				});	
			}
		},
		deleteVariantiPrice : function(price = null){
			utils.alert.confirm("Sei sicuro di voler eliminare le price selezionate?",()=>{
				var arr = new Array();
				if (price != null){
					arr.push(price);
				}
				else{
					arr = utils.array.select(this.variante.arrayPrice,"SELEZIONATO",1);
				}
				if (arr.length > 0){
					utils.ajax("travel/varianteprice/delete",{price:arr},(response)=>{
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getVariante();
					}
				});	
				}
				else{
					utils.alert.warning("Selezionare almeno una price");
				}
			},this.$root);
		},
		mettiInEvidenza : function(price){
			this.search.FK_ID_OPZIONE.VALUE = price.FK_ID_OPZIONE;
			this.search.FK_ID_PRODOTTO.VALUE = price.FK_ID_PRODOTTO;
		}
		
    },	  

    computed:{
        paginatedList(){
            var arr = utils.inGrid(this.variante.arrayPrice,this.search);
            return arr;
        },

		lblProdottiSelezionati(){
			var d = utils.array.distinct(this.variantePrice.prodottiSelezionati,  "FK_ID_PRODOTTO");
			return d.length + " prodotti selezionati con relative opzioni";
		}

    },		

    created : function(){
        this.getVariante();
    }
}
</script>