<template>

    <h6 class="profile-username" style="padding: 5px 0px 0 0px;">
        <i class="far fa-users fa-fw"></i> Storico incassi 
        <div v-show="modalita != 3" class="dropdown float-end">
            <a href="#" class="badge bg-indigo bg-opacity-20 text-indigo d-inline-flex align-items-center dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="icon-cog5 me-1"></i>Azioni Multiple
            </a>
            <div class="dropdown-menu dropdown-menu-end" style="">
                <a href="#" class="dropdown-item" v-on:click="addIncasso(1)">
                    <span class="border border-width-4 border-danger rounded-pill me-2"></span>Genera pagamento
                </a>
                <a href="#" class="dropdown-item" v-on:click="addIncasso(2)">
                    <span class="border border-width-4 border-info rounded-pill me-2"></span>Genera 3 rate
                </a>
                <a href="#" class="dropdown-item" v-on:click="addIncasso(3)">
                    <span class="border border-width-4 border-primary rounded-pill me-2"></span>Genera 4 rate
                </a>
            </div>
        </div>
    </h6>
    <div class="box-pax">
        <table class="table table-bordered table-hover table-striped table-ext">
            <thead class="sticky-top">
                <tr class="bg-secondary text-white">
                    <th v-show="modalita == 1" class="table-head-sm-2 text-center" style="width: 30%;">Data / Importo</th> 
                    <th v-show="modalita == 1" class="table-head-sm-2 text-center" style="width: 29%;">Flusso / Tipo</th> 
                    <th v-show="modalita == 1" class="table-head-sm-2 text-center" style="width: 36%;">Note</th> 
                    <th v-show="modalita == 1" class="table-head-sm-2 text-center" style="width: 5%;">#</th> 
                    
                    <th v-show="modalita == 2" class="table-head-sm-2 text-center" style="width: 21%;">Data / Importo</th> 
                    <th v-show="modalita == 2" class="table-head-sm-2 text-center" style="width: 26%;">Movimento / Tipo</th> 
                    <th v-show="modalita == 2" class="table-head-sm-2 text-center" style="width: 26%;">Note</th>
                    <th v-show="modalita == 2" class="table-head-sm-2 text-center" style="width: 21%;">Modifica</th> 
                    <th v-show="modalita == 2" class="table-head-sm-2 text-center" style="width: 6%;">Azioni</th> 
                </tr>
            </thead> 
            <tbody>
                <tr v-for="incasso in arrayIncassi" v-bind:key="incasso.ID_INCASSO" v-show="modalita == 2">
                    <td class="text-center">
                        <Datepicker  v-model="incasso.DATA_INCASSO" model-type="dd/MM/yyyy HH:mm" :format="'dd/MM/yyyy HH:mm'" auto-apply></Datepicker>
                        <input class="form-control form-control-sm mt-1" type="text" v-model="incasso.IMPORTO" data-placeholder="Importo">                       
                    </td>  
                    <td class="text-center">
                        <select class="form-select form-select-sm" v-model="incasso.FK_ID_CONTO" data-placeholder="Tipo">
                            <option value="0">Seleziona Movimento</option>
                            <option v-for="opzione in getModalitaIncassoPrincipali()" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA">{{ opzione.DESCRIZIONE }}</option>
                        </select>
                        <select class="form-select form-select-sm" :disabled="!incasso.FK_ID_CONTO > 0" v-model="incasso.FK_ID_SUB_MODALITA_INCASSO" data-placeholder="Tipo">
                            <option value="0">Seleziona Pagamento</option>
                            <option v-for="conto in getSubModalitaIncasso(incasso)" v-bind:key="conto.ID_OPZIONE_TIPOLOGICA" v-bind:value="conto.ID_OPZIONE_TIPOLOGICA">{{ conto.DESCRIZIONE }}</option>
                        </select>
                    </td> 
                    <td class="text-center">
                        <textarea class="form-control" aria-multiline="true" v-model="incasso.NOTE"  placeholder="Inserisci una nota"></textarea>                   
                    </td> 
                    <td class="text-center">
                        <Datepicker :disabled="incasso.ID_INCASSO == 0" v-model="incasso.DATA_MODIFICA" model-type="dd/MM/yyyy HH:mm" :format="'dd/MM/yyyy HH:mm'" auto-apply></Datepicker>                           
                    </td> 
                    <td class="text-center">
                        <span class="fs-sm text-muted">
                            <div class="dropdown align-self-center">
                                <a href="#" class="badge bg-danger bg-opacity-20 text-danger text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="ph-list"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end p-0 mt-0" style="">
                                    <a href="#" class="dropdown-item p-1 m-1" v-on:click="salvaIncasso(incasso)"><i class="icon-truck me-2"></i>Salva incasso</a>
                                    <div class="dropdown-divider"></div>
                                    <a href="#" class="dropdown-item p-1 m-1" v-on:click="deleteIncasso(incasso)"><i class="icon-warning2 text-danger me-2"></i>Elimina incasso</a>
                                </div>
                            </div>
                        </span> 
                    </td>
                </tr>
                <tr v-for="incasso in arrayIncassi" v-bind:key="incasso.ID_INCASSO" v-show="modalita==1">
                    <td class="text-center">
                        <Datepicker :disabled="true" v-model="incasso.DATA_INCASSO" model-type="dd/MM/yyyy HH:mm" :format="'dd/MM/yyyy HH:mm'" auto-apply></Datepicker>                       
                    </td> 
                    <td class="text-center">
                        <select class="form-control form-control-sm" v-model="incasso.FK_ID_CONTO" data-placeholder="Tipo">
                            <option value="0">Seleziona Movimento</option>
                            <option v-for="opzione in getModalitaIncassoPrincipali()" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA">{{ opzione.DESCRIZIONE }}</option>
                        </select>
                    </td>  
                    <td class="text-center" colspan="2">
                        <textarea class="form-control mb-1" rows="1" aria-multiline="true" v-model="incasso.NOTE"  placeholder="Inserisci una nota"></textarea> 
                    </td> 
                </tr>
                <tr v-for="incasso in arrayIncassi" v-bind:key="incasso.ID_INCASSO" v-show="modalita==1">
                    <td class="text-center">
                        <input class="form-control form-control-sm" type="text" v-model="incasso.IMPORTO" data-placeholder="Importo">
                    </td>
                    <td class="text-center">
                        <select class="form-control form-control-sm" :disabled="!incasso.FK_ID_CONTO > 0" v-model="incasso.FK_ID_SUB_MODALITA_INCASSO" data-placeholder="Tipo">
                            <option value="0">Seleziona Pagamento</option>
                            <option v-for="conto in getSubModalitaIncasso(incasso)" v-bind:key="conto.ID_OPZIONE_TIPOLOGICA" v-bind:value="conto.ID_OPZIONE_TIPOLOGICA">{{ conto.DESCRIZIONE }}</option>
                        </select>
                    </td>
                    <td class="text-center">
                        <Datepicker :disabled="incasso.ID_INCASSO == 0" v-model="incasso.DATA_MODIFICA" model-type="dd/MM/yyyy HH:mm" :format="'dd/MM/yyyy HH:mm'" auto-apply style="width: 10.5rem !important;"></Datepicker> 
                    </td>
                    <td class="text-center">
                        <span class="fs-sm text-muted">
                            <div class="dropdown align-self-center">
                                <a href="#" class="badge bg-danger bg-opacity-20 text-danger text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="ph-list"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end p-0 mt-0" style="">
                                    <a href="#" class="dropdown-item p-1 m-1" v-on:click="salvaIncasso(incasso)"><i class="icon-truck me-2"></i>Salva incasso</a>
                                    <div class="dropdown-divider"></div>
                                    <a href="#" class="dropdown-item p-1 m-1" v-on:click="deleteIncasso(incasso)"><i class="icon-warning2 text-danger me-2"></i>Elimina incasso</a>
                                </div>
                            </div>
                        </span> 
                    </td>
                </tr>
                <!--   <button class="btn btn-success" v-on:click="addIncasso()">Nuovo</button>-->
            </tbody>
        </table>
    </div>  

</template>

<script>

import Datepicker from '@vuepic/vue-datepicker';
import { utils } from '../assets/js/utils';


export default {
    name:"GrigliaIncassi",
    //modalita 1: sola lettura - modalita 2: modifica
    props:['modalita'],
    components:{
        Datepicker
    },
    data:function(){
        return{
            filters:global.filters, 
            ordine:{incassi:new Array()},
            arrayIncassi:new Array()
        }
    },
    methods:{
        startup : function(ordine,ricaricaOrdini = 1){
            this.ordine = {incassi:new Array()};
            if (ricaricaOrdini == 1){
                utils.ajax("travel/ordine",{ID_ORDINE:ordine.ID_ORDINE}, (response) => {
                    this.ordine = response.data;                      
                    this.impostaArrayIncassi(utils.clone(this.ordine.incassi));    
                });
            }
            else{
                this.ordine = utils.clone(ordine);
                this.impostaArrayIncassi(utils.clone(this.ordine.incassi));    
            }
        },
        impostaArrayIncassi : function(arrayIncassi){
            this.arrayIncassi = new Array();
            for (var i = 0 ; i < arrayIncassi.length ; i++){
                arrayIncassi[i].DATA_INCASSO = this.filters.formatDateTimeDp(arrayIncassi[i].DATA_INCASSO);
                arrayIncassi[i].DATA_MODIFICA = this.filters.formatDateTimeDp(arrayIncassi[i].DATA_MODIFICA);
                this.arrayIncassi.push(arrayIncassi[i]);
            } 
        },
        salvaIncasso : function(incasso){
            var api = incasso.ID_INCASSO > 0 ? "travel/incasso/update" : "travel/incasso/insert";
            utils.ajax(api,incasso,(response)=>{
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    if (incasso.ID_INCASSO == 0){
                        incasso.ID_INCASSO = response.id_inserito;
                    }
                    //this.startup(this.ordine);
                }
            });
        },
        modificaIncasso : function(incasso){
            var inc = utils.clone(incasso);
            inc.DATA_INCASSO = this.filters.formatDateTimeSecondsDatePicker(incasso.DATA_INCASSO);
            this.incassoSelezionato = inc;
        },
        deleteIncasso : function(incasso){         
            if (incasso.ID_INCASSO > 0){
                utils.alert.confirm("Sei sicuro di voler eliminare questo incasso?",()=>{
                    utils.ajax("travel/incasso/delete",incasso,(response)=>{
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        //this.startup(this.ordine);
                    }
                });
                },this.$root);
            }
            else{
                var i = utils.array.getItemIndex(this.arrayIncassi,"TEMP_ID",incasso.TEMP_ID);
                if (i > -1){
                    this.arrayIncassi.splice(i,1);
                }                
            }            
        },
        addIncasso : function(numRate){
            var residuo = this.ordine.residuo;
            var rata = Math.floor(residuo / numRate);
            var resto = residuo % numRate;
            for (var i = 0 ; i < numRate ; i++){
                var importoRata = rata;
                if (i == 0){
                    importoRata += resto;
                }
                this.arrayIncassi.push({ID_INCASSO:0,TEMP_ID:new Date().getTime()+i,FK_ID_CONTO:0,FK_ID_SUB_MODALITA_INCASSO:0,FK_ID_ORDINE:this.ordine.ID_ORDINE,IMPORTO:importoRata,DATA_INCASSO:this.filters.formatDateTimeDp(new Date())});
            }            
        },
        getSubModalitaIncasso : function(incasso){
            if (incasso.FK_ID_CONTO > 0){
                var principale = utils.array.select(this.$root.arrayConti,"ID_OPZIONE_TIPOLOGICA",incasso.FK_ID_CONTO,1)[0];
                if (principale == undefined){
                    return new Array();
                }
                var arr = utils.array.select(this.$root.arrayConti,"CUSTOM_2",principale.CUSTOM_2);
                if (incasso.FK_ID_SUB_MODALITA_INCASSO == undefined){
                    incasso.FK_ID_SUB_MODALITA_INCASSO = 0;//arr[0].ID_OPZIONE_TIPOLOGICA;
                }
                else{
                    var check = utils.array.select(arr,"ID_OPZIONE_TIPOLOGICA",incasso.FK_ID_SUB_MODALITA_INCASSO,1);
                    if (check.length == 0){
                        incasso.FK_ID_SUB_MODALITA_INCASSO = 0;//arr[0].ID_OPZIONE_TIPOLOGICA;
                    }
                }                
                return arr;
            }
            return new Array();
        },
        getModalitaIncassoPrincipali : function(){
            var arr = new Array();
            for (var i = 0 ; i< this.$root.arrayConti.length ; i++){
                if (this.$root.arrayConti[i].ID_OPZIONE_TIPOLOGICA == 46 || this.$root.arrayConti[i].ID_OPZIONE_TIPOLOGICA == 47 || this.$root.arrayConti[i].ID_OPZIONE_TIPOLOGICA == 48  || this.$root.arrayConti[i].ID_OPZIONE_TIPOLOGICA == 49 ){
                    arr.push(utils.clone(this.$root.arrayConti[i]));
                }
            }
            return arr;
        },
        printIncasso : function(){
            /*
            utils.ajax("travel/incasso/insert",this.incasso,(response)=>{
                if (response.esito == 0){
                    this.openIncasso(this.ordine,{ID_INCASSO:0,FK_ID_ORDINE:this.ordine.ID_ORDINE},0);
                }
            });*/
        },

        stampaRiepilogo : function(){
            /*
            utils.ajax("travel/incasso/insert",this.incasso,(response)=>{
                if (response.esito == 0){
                    this.openIncasso(this.ordine,{ID_INCASSO:0,FK_ID_ORDINE:this.ORDINE.ID_ORDINE},0);
                }
            });*/
        }
    }
}
</script>