<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
                    <a href="#" class="text-dark fw-semibold cursor-pointer" v-on:click="getLingue()"> Preferenze </a> \ 
                    <span class="text-muted"> <Translate :path="'dizionario.base.LINGUA'"></Translate> </span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
	
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 me-2 position-10" style="text-align: left;" v-on:click="nuovaDefinizione()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Definizione</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche()" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue()" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help()" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
			</div>
        </div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" v-for="lang in $root.arrayLingue" v-bind:key="lang.ID_GEO_STATO" role="presentation">
						<a :href="'#tab_lingua'+lang.ID_GEO_STATO" class="navbar-nav-link navbar-nav-link-icon navbar-sm lingua rounded" data-bs-toggle="tab" :id="'btnLingua'+lang.ID_GEO_STATO" v-on:click="linguaSelezionata = lang.ID_GEO_STATO" aria-selected="true" role="tab">
							<Flag :country="lang.ID_GEO_STATO" class="me-1"></Flag> {{ lang.NOME }}
						</a>
					</li>
				</ul> 





				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Utenti selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti gli Utenti</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina Utenti</a>
								</div>
							</div>
						</li>	   
						<li class="nav-item">
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade" v-for="lang in $root.arrayLingue" v-bind:key="lang.ID_GEO_STATO" :id="'tab_lingua'+lang.ID_GEO_STATO">
        		<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#">
									<div class="row ">  
										<div class="col-7 col-md-2 col-lg-2 mbx-1">
											<span style="position: relative; top: 5px;"><code> Definizioni lingua {{ lang.NOME }}</code></span>
										</div>
										<div class="col-5 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Titolo" :tabindex="1" v-model="search.DEFINIZIONE.VALUE" />
										</div>	
										<div class="col-5 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Definizione" :tabindex="1" v-model="search.VALORE.VALUE" />
										</div>	  
										<div class="col-7 col-md-4 col-lg-4">
	
										</div> 
										<div class="col-12 col-md-2 col-lg-2">
											<a href="#" class="btn btn-primary btn-sm float-end" v-on:click="salvaDefinizioni()"><i class="icon-floppy-disk me-2"></i> SALVA MODIFICHE</a>
										</div>
									</div>
								</form>
							</div>
						</div>
			        </div>

			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="tableFixHead" id="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
			                      		<th class="table-head-sm" style="width:2%;"> <input id="chkAllDefinizioni" v-on:click="filters.selectAll('chkAllDefinizioni',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:25%;">Titolo / Definizione</th>
			                            <th class="table-head-sm">Testo descrittivo</th>
			                        </tr>
			                    </thead>
		                        <tbody>
									<tr v-for="definizione in paginatedList.arr" v-bind:key="definizione">
										<td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="definizione.SELEZIONATO" class=""> 
										</td>
										<td class="td-class fs-sm">
											<input type="text" style="text-transform: uppercase; font-weight: bold;" class="form-control form-control-sm text-danger" v-model="definizione.DEFINIZIONE" v-on:keyup="updateDefinizioniAltreLingue(definizione)">
										</td>
										<td class="td-class fs-sm">
											<input type="text" class="form-control form-control-sm" v-model="definizione.VALORE">
										</td>
									</tr>
								</tbody>
							</table>
						</div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">DEFINIZIONI: {{ paginatedList.count }}</span> 
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
			                    </div>
			                </div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
			            	</div>
			        	</div> 
					</div>
                </div>
            </div>

        </div>
    </div>

</template>



<script>						

import Pagine from '@/components/utils/Pagine.vue'
import Flag from '@/components/utils/Flag.vue'
import { utils } from '../assets/js/utils';	   


export default {
    name:"Lingua",	
	
    props:['modulo'], 
			
    components:{
        Pagine,
		Flag
    },				

    data:function(){
        return {
            percorsoLang:'',
            t:global.t,
            arrayDefinizioni : new Array(),
			linguaSelezionata:0,
	        filters:global.filters,
			GLOBAL_IMAGES_PATH:'',
			GLOBAL_THEME_IMAGES_PATH:'',
	        search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            DEFINIZIONE:{TYPE:'TEXT',VALUE:''},
	            VALORE:{TYPE:'TEXT',VALUE:''},
	        },
        }
		
    }, 

	methods:{
		updateDefinizioniAltreLingue : function(definizione){
			for (var i = 0 ; i < this.arrayDefinizioni.length ; i++){
				if (this.arrayDefinizioni[i].FK_ID_DEFINIZIONE == definizione.FK_ID_DEFINIZIONE){
					if (definizione.ID_DEFINIZIONE != this.arrayDefinizioni[i].ID_DEFINIZIONE){
						this.arrayDefinizioni[i].FK_ID_DEFINIZIONE = definizione.DEFINIZIONE;
						this.arrayDefinizioni[i].DEFINIZIONE = definizione.DEFINIZIONE;
					}
				}
			}
			definizione.FK_ID_DEFINIZIONE = definizione.DEFINIZIONE;
		},	

		salvaDefinizioni : function(){
			var itemFinale = {};
			var arrayRaggr = utils.array.raggruppaArray(utils.clone(this.arrayDefinizioni), "FK_ID_DEFINIZIONE" ,  "FK_ID_DEFINIZIONE", "FK_ID_DEFINIZIONE",   "ARRAY_FIGLI",0,0);
			for (var i = 0 ; i < arrayRaggr.length ; i++){
				itemFinale[arrayRaggr[i].FK_ID_DEFINIZIONE] = {};
				for (var j = 0 ; j < arrayRaggr[i]['ARRAY_FIGLI'].length ; j++){
					var figlio = arrayRaggr[i]['ARRAY_FIGLI'][j];
					itemFinale[arrayRaggr[i].FK_ID_DEFINIZIONE][figlio.FK_ID_LINGUA] = figlio.VALORE != undefined ? figlio.VALORE : "";
				}
			}
			utils.ajax("travel/dizionario/salva",{percorso:this.percorsoLang,dizionario:itemFinale}, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
					this.$root.getDizionario((arr)=>{
						this.arrayDefinizioni = this.$root.getDizionarioPercorso(this.percorsoLang);
					});
				}
			});	
		},	

		nuovaDefinizione : function(){
			const ts = Date.now();
			for (var i = 0 ; i < this.$root.arrayLingue.length ; i++){
				const idDefinizione = ts + "_" + this.$root.arrayLingue[i].ID_GEO_STATO;
				this.arrayDefinizioni.push({FK_ID_LINGUA:this.$root.arrayLingue[i].ID_GEO_STATO,ID_DEFINIZIONE : idDefinizione, FK_ID_DEFINIZIONE : "NUOVA" , DEFINIZIONE : "NUOVA" })
			}
		}	

	},	

    computed: {
        paginatedList(){
			if (this.arrayDefinizioni == null){
				return {
					count:0,
					arr:new Array()
				}
			}
			var arrayPerLingua = utils.array.select(this.arrayDefinizioni,"FK_ID_LINGUA",this.linguaSelezionata);
            var arr = utils.inGrid(arrayPerLingua,this.search);
            return arr;
        },	 

    },

    created : function(){
        this.percorsoLang = this.$root.percorsoLang;
		this.linguaSelezionata = this.$root.arrayLingue[0].ID_GEO_STATO;
		this.GLOBAL_THEME_IMAGES_PATH = process.env.VUE_APP_THEME_IMAGES_PATH;
        this.arrayDefinizioni = this.$root.getDizionarioPercorso(this.percorsoLang);
		if (this.arrayDefinizioni == null){
			this.arrayDefinizioni = new Array();
		}
		/*
		setTimeout(() => {
			var tabEl = document.querySelectorAll('.lingua');
			for (var i = 0 ; i < tabEl.length; i++){
				tabEl[i].addEventListener('shown.bs.tab',  (event) => {
					//event.target // newly activated tab
					//event.relatedTarget // previous active tab
					var tab = event.target.dataset.tab;
					this.currentLang = tab;
					this.changeComboStagioneLingua();
				});
			}
		},300);*/
        utils.wait(()=>{
			var btn = document.getElementById('btnLingua'+this.linguaSelezionata);
            btn.click();
        });
        
    },

}
</script>

<style scoped>
.table td { padding: 0.254rem 0.3rem; }
</style>