<template>

	<!-- Page header -->
	<div class="page-header page-header-light">
		<div class="page-header-content header-elements-lg-inline">
			<div class="page-title d-flex">
				<h4 class="d-none d-sm-block">
					<router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
					<a href="#" class="d-inline-block text-dark"><span class="font-weight-semibold"> Accessi </span></a> \
					<span class="text-muted">Logs Errori </span>
					<code> - Lingua italiano</code>
				</h4>
				<h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Logs Errori </h4>
				<a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
			</div>
			<div class="header-elements d-none">
				<a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
			</div>
		</div>
	</div>
	<!-- /page header -->
	
	<!-- Content area -->
	<div class="content content-2">

		<div class="card shadow mb-0">
			<div class="collapse show">
				<div class="card-body card-body-2">
					<div class="alert alert-2 alert-secondary alert2 border-0">
						<form action="#" class="">
							<div class="row ">
								<div class="col-12 col-md-1 form-group-filter">
									<select class="form-control form-control-sm" data-placeholder="Livello" :tabindex="3" v-model="search.logLevel.VALUE">
										<option value="">TUTTI</option>
										<option value="DEBUG"> &nbsp; Debug</option>
										<option value="ERROR"> &nbsp; Errori</option>
									</select>
								</div>
								<div class="col-12 col-md-2 form-group-filter">
									<Datepicker  v-model="search.time" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']"></Datepicker>
								</div>
								<div class="col-12 col-md-2 form-group-filter">
									<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Messaggio" :tabindex="1" v-model="search.message.VALUE" />
								</div>
								<div class="d-none d-sm-block col-md-5 form-group-filter">
								</div> 
								<div class="col-12 col-md-1 form-group-filter">
									<select class="form-control form-control-sm" data-placeholder="Seleziona Anno">
										<option>Anno</option>
									</select>
								</div>
								<div class="col-md-1 form-group-filter">
									<a class="btn btn-secondary btn-sm btn-block" v-on:click="getErrori()">
										<span style="font-weight: 500;">Aggiorna</span>				
									</a>          
								</div>  
							</div>
						</form>
					</div>
				</div> 
			</div>
			<div class="card-body card-body-2 card-body-nobottom card-body-notop"> 
				<div class="tableFixHeadLg">
					<table class="table table-bordered table-hover table-striped table-togglable"> 
						<thead>
							<tr>
								<th class="table-head-sm" style="width:10%;"><span >Livello</span></th>
								<th class="table-head-sm" style="width:10%;"><span>Data e ora</span></th>
								<th class="table-head-sm" style="width:30%;"><span >API</span></th>
								<th class="table-head-sm" style=""><span >Messaggio</span></th>
								<th class="table-head-sm" style="width:5.4%;">Azioni</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="errore in paginatedList.arr" v-bind:key="errore.ID_ERRORE">
								<td style=""><span class="badge badge-danger">{{errore.level}}</span></td>
								<td style=""><span class="text-primary fw-bold cursor-pointer">{{filters.formatDateTime(errore.time)}}</span></td>
								<td style=""><span>{{errore.message}}</span></td>
								<td style=""><span>{{filters.accorciaTesto(getStringErrore(errore))}}</span></td>
								<td class="text-center">
									<div class="list-icons" :tabindex="26">
										<div class="dropdown">
											<a href="#" class="list-icons-item" data-bs-toggle="dropdown" v-on:click="openStackTrace(errore)"><i class="icon-menu9"></i></a>
											<div class="dropdown-menu dropdown-menu-right">
											</div>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>     
				</div>

				<div class="row">
					<div class="col-md-11" :tabindex="27">
						<div class="float-end"> 
							<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH"></Pagine>
						</div>
					</div>
					<div class="col-md-1" :tabindex="28">
						<select class="form-control wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
							<option value="5">5</option>
							<option value="10">10</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="75">75</option>
							<option value="100">100</option>
							<option value="250">250</option>
							<option value="500">500</option>
						</select>
					</div>
				</div>

			</div>	
		</div>	

	</div>

	<!-- Modal -->
	<div class="modal fade" id="popUpStackTrace" tabindex="-1" role="dialog"  >
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-danger text-white">
					<h6 class="modal-title">Log dettaglio Errore</h6>
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-md-2 form-group-1"><label class="font-weight-semibold">File:</label></div>
						<div class="col-md-10 form-group-1"><input type="text" class="form-control" v-model="erroreSelezionato.message" /></div>
						<div class="col-md-2"><label class="font-weight-semibold">Descrizione:</label></div>
						<div class="col-md-10"><p>{{getStringErrore(erroreSelezionato)}}</p></div>
					</div>                      
					<pre class="box_error" style="font-size:10px" v-if="erroreSelezionato.hasOwnProperty('stackTrace')">
						<strong>{{ erroreSelezionato.context.utente }}</strong><br/>
						{{erroreSelezionato.stackTrace}}<br/>
						{{erroreSelezionato.context.parametri}}
					</pre>
					<pre style="font-size:10px" v-else>{{erroreSelezionato.context}}</pre>
				</div>
				<div class="modal-footer">

				</div>
			</div>
		</div>
	</div>
        
</template>



<script>
import Pagine from '@/components/utils/Pagine.vue'
import Datepicker from '@vuepic/vue-datepicker'


export default {
    name:"Errori",
    components:{
        Pagine,
		Datepicker,
    },
    data: function () {
        return {   
            filters:global.filters,
            arrayErrori: new Array(),
            selectedMenuItem:{},
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                logLevel:{TYPE:'COMBO',VALUE:''},
                message:{TYPE:'TEXT',VALUE:''}
            },
            erroreSelezionato:{}
        }
    },

    methods: {
        getErrori: function () {
            utils.ajax("core/errori",{}, (response) => {
                this.arrayErrori = response.data;
            });
        },

        getStringErrore:function(_errore){
            var item = _errore.context;
            if (item == undefined)
                return "";
            if (item.hasOwnProperty("message")){
                return item.message;
            }
            else {
                if (item.hasOwnProperty("query")){
                    return item.errore ;
                }
                return "";
            }
        },

        openStackTrace:function(errore){
			this.filters.openPopUp("popUpStackTrace"); 		
            this.erroreSelezionato = utils.clone(errore);
            console.log(this.erroreSelezionato);
            if (this.erroreSelezionato.context.hasOwnProperty("message")){
                this.erroreSelezionato.stackTrace = this.erroreSelezionato.context.stackTrace;
            }
            else {
                if (this.erroreSelezionato.context.hasOwnProperty("query")){
                    this.erroreSelezionato.stackTrace = this.erroreSelezionato.context.query; //+ "\n\n---\n" + this.erroreSelezionato.context.parametri.join(",") ;
                }
            }
        }
    },
    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayErrori,this.search);
            return arr;
        }
    },
    created: function () {
        this.getErrori();
    },
}
</script>