<template>
        
    <!-- Page header -->
    <div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
        <div class="page-header-content  d-lg-flex">
            <div class="d-flex">
                <h4 class="page-title mb-0">
                    <router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link> 
					<router-link href="#" class="d-none d-lg-inline-block text-dark fw-semibold cursor-pointer" :to="'Ordini'"> <Translate :path="'dizionario.base.ELENCO'"></Translate> Pratiche </router-link> <span class="d-none d-lg-inline-block">\</span>
                    <span class="text-muted ms-0" v-if="ordine.ID_ORDINE > 0">Pratica PNR {{filters.getPnr(ordine)}} <small class="cursor-pointer ms-1" v-on:click="getOrdine()">[# {{ordine.ID_ORDINE}}]</small></span><span v-else>Nuova Pratica</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
                <div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">	
                    <div class="dropdown w-100 w-sm-auto">
                        <a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10 dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" style="text-align: left;">
                            <i class="ph-printer fw-bold me-1 btn btn-outline-success w-32px h-32px rounded-pill"></i>
                            <div class="me-auto me-lg-1 ms-1">
                                <div class="fs-sm text-muted mb-0">
									<Translate :path="'dizionario.base.ELENCO'"></Translate> Documenti
								</div>
                                <div class="text-success fw-semibold">Stampe</div>
                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg-end w-100 w-lg-auto wmin-300 wmin-sm-350 pt-0">
                            <a href="#" class="dropdown-item active py-2" v-on:click="stampaRiepilogo()">
                                <div class="fw-semibold">Stampa riepilogo</div>
                            </a>
                            <a href="#" class="dropdown-item active py-2" v-on:click="stampaRiepilogo()">
                                <div class="fw-semibold">Estratto conto</div>
                            </a>
                        </div>
                    </div>

                    <div class="vr flex-shrink-0 my-2 mx-3"></div>

                    <div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(10)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(this.modulo)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
                </div>
	        </div>
		</div>
		<!-- /breadcrumb -->


    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">

        <div class="row">
            <div class="col-12 col-xl-5">
                <div class="card shadow mb-xs-3 mb-lg-0">
                    <div class="card-header bg-dark bg-opacity-10 text-info pt-1">
                        <div class="row">
                            <div class="col-6 col-sm-3 col-md-4 col-lg-4 col-xl-4" v-show="ordine.ID_ORDINE > 0">
                                <label class="form-label text-black mb-0">Pnr Pratica</label>
                                <div class="input-group">
                                    <span class="input-group-text"><i class="far fa-code fa-fw"></i></span>
                                    <input type="text" class="form-control" placeholder="Ex: 0001" v-bind:value="filters.getPnr(ordine)" readonly="readonly">
                                </div>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <label class="form-label text-danger mb-0">Stato pratica:</label>
                                <select class="form-select" data-placeholder="Sel. Stato" tabindex="4" v-model="ordine.FK_ID_STATO">  
                                    <option v-bind:value="0">Sel. Stato</option>
                                    <option v-for="stato in $root.arrayPraticaStato" v-bind:key="stato.ID_OPZIONE_TIPOLOGICA" v-bind:value="stato.ID_OPZIONE_TIPOLOGICA">&nbsp;&nbsp;{{ stato.DESCRIZIONE }}</option>                                                                      
                                </select>
                            </div>
                            <div class="col-8 col-sm-4 col-md-4 col-lg-4 col-xl-4" v-show="ordine.FK_ID_STATO == 454">
                                <label class="form-label text-danger mb-0">Data opzione:</label>
                                <Datepicker v-model="ordine.DATA_SCADENZA_OPZIONE" model-type="dd/MM/yyyy HH:mm" :format="'dd/MM/yyyy HH:mm'" auto-apply></Datepicker>
                                <!-- <Datepicker  v-model="ordine.DATA_SCADENZA_OPZIONE" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']"></Datepicker>-->
                            </div> 
                        </div>
                    </div>
                    <div class="card-body mt-0 mb-0"> 

                        <div class="row" style="border-bottom: 1px solid #ccc;">
                            <div class="col-6 col-sm-6 col-md-3 col-lg-4 col-xl-4 mb-1">
                                <label class="form-label text-danger mb-0">Data:</label>
                                <Datepicker v-model="ordine.DATA_CREAZIONE" model-type="dd/MM/yyyy HH:mm" :format="'dd/MM/yyyy HH:mm'" auto-apply></Datepicker>
                            </div>
                             <div class="col-6 col-sm-6 col-md-3 col-lg-4 col-xl-4 mb-1">
                                <label class="form-label text-danger mb-0">Utente:</label>
                                <select class="form-select form-select-sm" data-placeholder="Utente" tabindex="4" v-model="ordine.FK_ID_UTENTE_ADMIN">                                        
                                    <option value="0">Sel. Admin</option>  
                                    <option v-for="utente in $root.arrayUtentiAdmin" v-bind:value="utente.ID_UTENTE" v-bind:key="utente.ID_UTENTE">&nbsp;{{utente.USERNAME}}</option>                                                                                
                                </select>
                            </div>
                            <div class="col-6 col-sm-6 col-md-3 col-lg-4 col-xl-4 mb-1">
                                <label class="form-label text-danger mb-0">Sito Web:</label>
                                <select class="form-select form-select-sm" data-placeholder="Sito Web" tabindex="4" v-model="ordine.FK_ID_ENTE">
                                    <option value="0">Sel. Ente</option>  
                                    <option v-for="ente in $root.arrayEnti" v-bind:value="ente.ID_ENTE" v-bind:key="ente.ID_ENTE">&nbsp;{{ente.LABEL}}</option>                                                                     
                                </select>
                            </div>
                        
                            <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-1">
                                <label class="form-label text-danger mb-0">Tipo pratica:</label>
                                <select class="form-select form-select-sm" data-placeholder="Tipologia pratica" tabindex="4" v-model="ordine.FLG_GRUPPI"> 
                                    <option v-bind:value="0">Sel. Pratica</option> 
                                    <option v-for="pratica in $root.arrayPraticaTipo" v-bind:key="pratica.ID_OPZIONE_TIPOLOGICA" v-bind:value="pratica.ID_OPZIONE_TIPOLOGICA">&nbsp;&nbsp;{{ pratica.DESCRIZIONE }}</option> 
                                </select>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-1">
                                <label class="form-label text-danger mb-0">Tipo booking:</label>
                                <select class="form-select form-select-sm" data-placeholder="Tipologia Booking" tabindex="4" v-model="ordine.FK_ID_TIPO">
                                    <option v-bind:value="0">Sel. Tipo</option> 
	                                <option v-for="tipo in $root.arrayPraticaBook" v-bind:key="tipo.ID_OPZIONE_TIPOLOGICA" v-bind:value="tipo.ID_OPZIONE_TIPOLOGICA">&nbsp;&nbsp;{{ tipo.DESCRIZIONE }}</option> 
                                </select>
                            </div>                                
                            <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-1">
                                <label class="form-label text-danger mb-0">Easy:</label>
                                <input type="text" class="form-control form-control-sm" v-model="ordine.FK_ID_PRATICA_EASY" placeholder="Pnr Easy">
                            </div> 

                            <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-1">
                                <label class="form-label text-danger mb-0">Date soggiorno: <span data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Tipologia descrizioneTipoProdotto" data-content="assss" class="badge badge-warning badge-xs fs-12 text-white cursor-pointer pop_over float-end" v-on:click="ricalcolaDateSoggiorno()">Ricalcola</span></label>
                                <Datepicker style="width:100%" v-model="dataSoggiornoRange" @update:model-value="datePickerChangeHandler()"  range multi-calendars multi-calendars-solo  :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" :partial-range="true" />
                            </div> 
                        
                            <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 mb-1">
                                <label class="form-label text-danger mb-0">Titolo della Pratica:</label> 
                                <input type="text" class="form-control form-control-sm" v-model="ordine.TITOLO_PRATICA" placeholder="Titolo Pacchetto">
                            </div>

                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-1">
                                <label class="form-label text-danger mb-0">Stato di destinazione:</label> 
                                <Select2 v-model="ordine.FK_ID_STATO_NAZIONALE" :w="250" :api="'core/geo/stati/select2'" :apiselected="'core/basic/element?className=Stato&ID=ID_STATO&DESCRIZIONE=NOME&select2=1'" ::tabindex="2"></Select2>
                            </div>

                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-1">
                                <label class="form-label text-danger mb-0">Tipo fiscale</label>
                                <select class="form-select form-select-sm" data-placeholder="Tipologia Fiscale" tabindex="4" v-model="ordine.FK_ID_TIPO_FISCALE">
                                    <option v-bind:value="0">Seleziona</option> 
                                    <option v-for="tipo in $root.arrayTipoFiscale" v-bind:value="tipo.ID_OPZIONE_TIPOLOGICA" v-bind:key="tipo.ID_OPZIONE_TIPOLOGICA">{{ tipo.DESCRIZIONE }}</option>
                                </select>
                            </div>  
                            <div class="col-5 col-sm-4 col-md-5 col-lg-5 col-xl-5 mb-2">
                                <label class="form-label text-danger mb-0">Netta/Comm:</label>
                                <select class="form-select form-select-sm" data-placeholder="Netta / comm" tabindex="4" @change="flgCommissionabileChangeHandler()" v-model="ordine.FLG_COMMISSIONABILE">
                                    <option v-bind:value="-1">Seleziona</option> 
                                    <option value="1">&nbsp;&nbsp;Commissionabile</option>  
                                    <option value="0">&nbsp;&nbsp;Netta</option>                                    
                                </select> 
                            </div>
                            <div class="col-7 col-sm-8 col-md-7 col-lg-7 col-xl-7 mb-2">
                                <label class="form-label text-danger mb-0">Commissione:</label>
                                <select class="form-select form-select-sm" data-placeholder="Commissione" :disabled="ordine.FLG_COMMISSIONABILE == 0" tabindex="4" v-model="ordine.MODALITA_COMMISSIONE">
                                    <option v-bind:value="0">Seleziona</option> 
                                    <option value="1" >&nbsp;&nbsp;Autofattura</option> 
                                    <option value="2" >&nbsp;&nbsp;Sconto commmerciale</option>                                    
                                </select> 
                            </div>
                        </div> 

                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="card mb-2">
                                    <div class="card-header bg-light d-flex justify-content-between pt-1 pb-0">
                                        <h4 class="font-weight-semibold">Agenzia</h4>
                                        <div class="ms-3 align-self-center">
                                            <button class="btn btn-warning float-end btn-sm" style="position: relative; top: -5px;" v-on:click="modificaAgenzia()">
                                                <i class="fal fa-store-alt fa-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body p-2">
                                        <h6 class="card-title"><span class="text-muted">{{ordine.agency.personaGiuridica.DENOMINAZIONE}}</span></h6>
                                        <p class="card-text"><span class="text-muted"></span></p>
                                    </div>
                                </div> 
                            </div>
                            <div class="col-12">
                                <div class="card mb-0">
                                    <div class="card-header bg-light d-flex justify-content-between pt-1 pb-0">
                                        <h4 class="font-weight-semibold">
                                            Intestatario
                                            <small v-show="ordine.INTESTATARIO_FK_ID_PERSONA_FISICA > 0">(Privato)</small>
                                            <small v-show="ordine.INTESTATARIO_FK_ID_PERSONA_GIURIDICA > 0">(Aziendale)</small>
                                        </h4>
                                        <div class="ms-3 align-self-center">
                                            <div class="dropdown float-end" style="position: relative !important; top: -5px;">
                                                <button class="btn btn-warning btn-sm dropdown-toggle" type="button"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fal fa-user fa-lg"></i>
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                    <button class="dropdown-item" v-on:click="modificaIntestatario(1)" type="button"><i class="fa-fw fal fa-search"></i> Privato</button>
                                                    <button class="dropdown-item" v-on:click="modificaIntestatario(2)" type="button"><i class="fa-fw fal fa-search"></i> Aziendale</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row" v-if="ordine.INTESTATARIO_FK_ID_PERSONA_FISICA > 0">
                                            <div class="col-2">
                                                <div class="profile-image text-center">
                                                    <img src="https://bootdey.com/img/Content/avatar/avatar7.png" width="38" height="38" alt="" />
                                                </div>
                                            </div>
                                            <div class="col-10">
                                                <div class="row">
                                                    <div class="col-2 mb-1">
                                                        <select class="form-select form-select-sm" data-placeholder="Commissione" tabindex="4" v-model="ordine.intestatarioPersonaFisica.SESSO" disabled>
                                                            <option v-bind:value="0">Seleziona</option> 
                                                            <option value="1" >&nbsp;&nbsp;Mr</option> 
                                                            <option value="2" >&nbsp;&nbsp;Mrs</option>                                    
                                                        </select> 
                                                    </div>
                                                    <div class="col-3 mb-1">
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="ordine.intestatarioPersonaFisica.COGNOME" readonly />
                                                    </div>
                                                    <div class="col-4 mb-1">
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="ordine.intestatarioPersonaFisica.NOME" readonly />
                                                    </div>
                                                    <div class="col-3">
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-if="ordine.intestatarioPersonaFisica.nazionalita != undefined" v-model="ordine.intestatarioPersonaFisica.nazionalita.NOME" readonly />
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-3 mb-1">
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="ordine.intestatarioPersonaFisica.DATA_NASCITA" readonly />
                                                    </div>
                                                    <div class="col-5 mb-1">
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="ordine.intestatarioPersonaFisica.EMAIL" readonly />
                                                    </div>
                                                    <div class="col-4 mb-1">
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="ordine.intestatarioPersonaFisica.TELEFONO" readonly />
                                                    </div>
                                                </div>
                                                <!--
                                                <div class="row">
                                                    <div class="col-3">
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="ordine.intestatarioPersonaFisica.INDIRIZZO" readonly />
                                                    </div>
                                                    <div class="col-2">
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="ordine.intestatarioPersonaFisica.CIVICO" readonly />
                                                    </div>
                                                    <div class="col-2">
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="ordine.intestatarioPersonaFisica.CAP" readonly />
                                                    </div>
                                                    <div class="col-3">
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="ordine.intestatarioPersonaFisica.COMUNE" readonly />
                                                    </div>
                                                    <div class="col-2">
                                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="ordine.intestatarioPersonaFisica.PROV" readonly />
                                                    </div>
                                                </div>
                                                -->
                                            </div>

                                            <!--
                                            <div class="col-2">
                                                <div class="profile-image float-md-right"> 
                                                    <img src="https://bootdey.com/img/Content/avatar/avatar7.png" width="38" height="38" alt=""> 
                                                </div>
                                            </div>
                                            <div class="col-10">
                                                <h6 class="mt-0 mb-0">
                                                    <span class="text-muted">{{ordine.intestatarioPersonaFisica.COGNOME}} {{ordine.intestatarioPersonaFisica.NOME}}</span>
                                                    <span class="text-muted float-end">{{ordine.intestatarioPersonaFisica.SESSO}}</span>
                                                </h6>
                                                <i class="fad fa-birthday-cake fa-fw"></i> <span class="text-muted me-2">{{ordine.intestatarioPersonaFisica.DATA_NASCITA}}</span>
                                                <i class="fad fa-flag fa-fw"></i> <span class="text-muted">{{ordine.intestatarioPersonaFisica.FK_ID_NAZIONALITA}} </span>
                                            </div>
                                            <div class="col-12 mt-2">
                                                <p class="mb-0"><i class="fad fa-map-signs fa-fw"></i> <span class="text-muted">Via Carlo Dossi 80, 00137 Roma (RM)</span></p>
                                                <p>
                                                    <i class="fad fa-envelope-open fa-fw"></i> <span class="text-muted me-2">{{ordine.intestatarioPersonaFisica.EMAIL}}</span>
                                                    <i class="fad fa-phone-square-alt fa-fw"></i> <span class="text-muted">{{ordine.intestatarioPersonaFisica.TELEFONO}}</span>
                                                </p>
                                            </div>  
                                            -->              
                                        </div>
                                        <div class="row" v-if="ordine.INTESTATARIO_FK_ID_PERSONA_GIURIDICA > 0">
                                            <div class="col-2">
                                                <div class="profile-image float-md-right"> 
                                                    <img src="https://bootdey.com/img/Content/avatar/avatar7.png" width="38" height="38" alt=""> 
                                                </div>
                                            </div>
                                            <div class="col-10">
                                                <h6 class="mt-0 mb-0">
                                                    <span class="text-muted">{{ordine.intestatarioPersonaGiuridica.DENOMINAZIONE}}</span>
                                                    <span class="text-muted float-end">{{ordine.intestatarioPersonaGiuridica.FK_ID_ENTE}}</span>
                                                </h6>
                                                <i class="fad fa-balance-scale fa-fw"></i> <span class="text-muted me-2">{{ordine.intestatarioPersonaGiuridica.PARTITA_IVA}}</span>
                                                <i class="fad fa-thumbtack fa-fw"></i> <span class="text-muted me-2">{{ordine.intestatarioPersonaGiuridica.CODICE_DESTINATARIO}}</span>
                                                <i class="fad fa-flag fa-fw"></i> <span class="text-muted">{{ordine.intestatarioPersonaGiuridica.FK_ID_NAZIONALITA}} </span>
                                            </div>
                                            <div class="col-12 mt-2">
                                                <p class="mb-0"><i class="fad fa-map-signs fa-fw"></i> <span class="text-muted">{{ordine.intestatarioPersonaGiuridica.INDIRIZZO}} 80, {{ordine.intestatarioPersonaGiuridica.CAP}} {{ordine.intestatarioPersonaGiuridica.FK_ID_COMUNE}} (RM)</span></p>
                                                <p>
                                                    <i class="fad fa-envelope-open fa-fw"></i> <span class="text-muted me-2">{{ordine.intestatarioPersonaGiuridica.EMAIL}}</span>
                                                    <i class="fad fa-phone-square-alt fa-fw"></i> <span class="text-muted">{{ordine.intestatarioPersonaGiuridica.TELEFONO}}</span>
                                                </p>
                                            </div>                
                                        </div>                                            
                                    </div>
                                </div> 
                            </div> 
                        </div>                                                 

                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-7">               
                <div class="card shadow mb-0">
                    <div class="card-header pt-1">

                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">

                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <label class="form-label text-black mb-0">Lordo:</label>
                                        <div class="input-group input-group-sm">
                                            <input readonly type="text" class="form-control form-control-sm" placeholder="Prezzo" v-bind:value="ordine.PREZZO_TOTALE" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                            <span class="input-group-text ps-1 pe-1 bg-info">
                                                <button type="button" class="btn btn-info btn-sm" v-on:click="ricalcolaValore('PREZZO_TOTALE')"><i class="fa fa-sync"></i></button>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <label class="form-label text-black mb-0">Netto:</label>
                                        <div class="input-group input-group-sm">
                                            <input readonly type="text" class="form-control form-control-sm" placeholder="Prezzo" v-bind:value="ordine.PREZZO_TOTALE" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                            <span class="input-group-text ps-1 pe-1 bg-info">
                                                <button type="button" class="btn btn-info btn-sm" v-on:click="ricalcolaValore('PREZZO_TOTALE')"><i class="fa fa-sync"></i></button>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <label class="form-label text-black mb-0">Commissioni:</label>
                                        <div class="input-group input-group-sm">
                                            <input readonly type="text" class="form-control form-control-sm" placeholder="Commissioni" v-bind:value="ordine.COMMISSIONI"  aria-label="Recipient's username" aria-describedby="basic-addon2">
                                            <span class="input-group-text ps-1 pe-1 bg-info">
                                                <button type="button" class="btn btn-info btn-sm" v-on:click="ricalcolaValore('COMMISSIONI')"><i class="fa fa-sync"></i></button>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <label class="form-label text-black mb-0">Costo</label>
                                        <div class="input-group input-group-sm">
                                            <input readonly type="text" class="form-control form-control-sm" placeholder="Costo" v-bind:value="ordine.COSTO_TOTALE"  aria-label="Recipient's username" aria-describedby="basic-addon2">
                                            <span class="input-group-text ps-1 pe-1 bg-info">
                                                <button type="button" class="btn btn-info btn-sm" v-on:click="ricalcolaValore('COSTO_TOTALE')"><i class="fa fa-sync"></i></button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="form-label text-black mb-0">&nbsp;</label>
                                <button class="btn btn-success-100 border-secondary mt-3 float-end" v-on:click="salvaOrdine(1)"><i class="far fa-sign-out fa-fw me-2"></i> SALVA E ESCI </button>                                   
                                <button class="btn btn-success mt-3 me-3 float-end" v-on:click="salvaOrdine(0)"><i class="far fa-save fa-fw me-2"></i> SALVA</button> 
                            </div>
                        </div>

                    </div>
                    <div class="card-body box-card mt-0 mb-0" id="box-card">
                        <TabsOrdine :ref="'tabsOrdine'" :modalita="2" @eventoSelezionato="openSchedaOrdineEvento($event)"  @selezionaProdotto="openSchedaProdotto($event)" @elimina="getOrdine()" @modifica="modificaQuota($event)" @modificaDate="modificaDate($event)"  ></TabsOrdine>
                    </div>
                </div>
            </div>

        </div>

    </div>


    <RicercaPersonaGiuridica :tipo="1" :ref="'ricercaPg'" @selezionato="doModificaAgenzia($event)"></RicercaPersonaGiuridica>
    <RicercaPersonaGiuridica :tipo="3" :ref="'ricercaPgIntestatario'" @selezionato="doModificaIntestatario($event,2)"></RicercaPersonaGiuridica>
    <RicercaPersonaFisica :tipo="1" :ref="'ricercaPfIntestario'" @selezionato="doModificaIntestatario($event,1)"></RicercaPersonaFisica>
    <EditQuota @updateQuota="getOrdine()" :ref="'editQuota'"></EditQuota>

    <SchedaQuota :idOrdine="ordine.ID_ORDINE" :ref="'schedaQuota'" @salva="getOrdine()"></SchedaQuota>
    <SchedaEmailOrdine :tipo="1" :ref="'schedaEmail'"></SchedaEmailOrdine>


    <div id="popUpPax" class="modal fade">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" >
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> DATI PARTECIPANTE </span>
                    </h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">	
                    <Pax v-model="paxSelezionato"></Pax>
                </div>                
                <div class="modal-footer bg-light">
                    Vuoi Procedere?&nbsp;&nbsp;&nbsp;
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">NO ESCI</button>&nbsp;
                    <button class="btn btn-success btn-sm" v-on:click="salvaPax()">SALVA</button>
                </div>
            </div>
        </div>	
    </div>

    <div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right" data-bs-backdrop="false" role="dialog" tabindex="-1">
        <div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
            <h5 class="offcanvas-title fw-semibold" style="width: 85% !important;">SCHEDA STORICO</h5>
                <select class="form-select" v-model="ordine.ID_LOG_SELEZIONATO" @change="openSchedaOrdineEvento({ID_LOG:ordine.ID_LOG_SELEZIONATO})"><option v-for="log in ordine.storico" v-bind:key="log.ID_LOG" v-bind:value="log.ID_LOG">{{ filters.formatDateTimeSeconds(log.DATA_AZIONE) }}</option></select>
            <button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
        </div>

        <div class="offcanvas-body p-2">

            <SchedaOrdine :ref="'schedaOrdine'" ></SchedaOrdine>

        </div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <div style="position: relative; top: 2px;">
                <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>
            </div>       
        </div>  
    </div>

    <div class="modal fade" id="openSchedaProdotto" data-bs-backdrop="false" role="dialog" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title"><i class="fa fa-fw fa-key fa-fw"></i> SCHEDA PRODOTTO</h6>  
                    <button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
                </div>
                <div class="modal-body">

                    <SchedaVisualizzazioneProdotto :ref="'schedaVisualizzazioneProdotto'" ></SchedaVisualizzazioneProdotto>   

                </div>
                <div class="modal-footer bg-light p-1">                                
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
                </div>
            </div>
        </div>
    </div> 


   

</template>



<script>

import Datepicker from '@vuepic/vue-datepicker'
import Select2 from '@/components/utils/Select2.vue'
import TabsOrdine from '@/components/TabsOrdine.vue'
import RicercaPersonaGiuridica from '@/components/RicercaPersonaGiuridica.vue'
import RicercaPersonaFisica from '@/components/RicercaPersonaFisica.vue'
import EditQuota from '@/components/EditQuota.vue'
import Pax from '@/components/common/Pax.vue'
import SchedaQuota from '@/components/SchedaQuota.vue'
import SchedaVisualizzazioneProdotto from '@/components/SchedaVisualizzazioneProdotto.vue'
import SchedaEmailOrdine from '@/components/SchedaEmailOrdine.vue'
import SchedaOrdine from '@/components/SchedaOrdine.vue'	



export default {
    name:'Ordine',

    components:{
        Datepicker,
        Select2,
        TabsOrdine,
        RicercaPersonaGiuridica,
        RicercaPersonaFisica,
        EditQuota,
        Pax,
        SchedaOrdine,
        SchedaQuota,
        SchedaVisualizzazioneProdotto,
        SchedaEmailOrdine
    },

    data:function(){
        return {
            filters:global.filters,
            paxSelezionato:{personaFisica:{}},
            dataSoggiornoRange:new Array(),
            ordine:{
                ID_ORDINE:0,
                PREZZO_TOTALE:0,
                COSTO_TOTALE:0,
                COMMISSIONI:0,
                FK_ID_STATO : '',
                FK_ID_TIPO : '',
                FLG_GRUPPI : '',
                FK_ID_STATO_PAGAMENTO:1,
                FK_ID_TIPO_FISCALE : this.$root.COSTANTI.ORDINE_DEFAULT_TIPO_FISCALE,
                FLG_COMMISSIONABILE : 1,
                MODALITA_COMMISSIONE : 0,
                quote:{},
                agency:{personaGiuridica:{}},
                intestatarioPersonaFisica:{},
                intestatarioPersonaGiuridica:{},
            }
        }
    },

    methods:{
        openQuota: function(){
            global.router.push("Quota");
        },
        getOrdine : function(){
            utils.ajax("travel/ordine",{ID_ORDINE:this.ordine.ID_ORDINE}, (response) => {
                if (response.esito == 0){
                    var ordine = response.data;
                    this.dataSoggiornoRange = new Array();
                    this.dataSoggiornoRange.push(new Date(this.filters.formatDateReverse(ordine.DATA_INIZIO_SOGGIORNO)).toString());
                    this.dataSoggiornoRange.push(new Date(this.filters.formatDateReverse(ordine.DATA_FINE_SOGGIORNO)).toString());
                    //ordine.DATA_CREAZIONE = this.filters.formatDateTimeSecondsDatePicker(ordine.DATA_CREAZIONE);
                    this.ordine = utils.clone(ordine);
                    this.$refs.tabsOrdine.startup(this.ordine);
                }
            });  
        },
        modificaAgenzia : function(){
            this.$refs.ricercaPg.openRicerca();
        },
        modificaIntestatario : function(tipo){
            if (tipo == 1){
                this.$refs.ricercaPfIntestario.openRicerca();
            }
            else{
                this.$refs.ricercaPgIntestatario.openRicerca();
            }
        },
        salvaOrdine : function(flgEsci = 0){
            if (this.ordine.ID_ORDINE == 0){
                utils.ajax("travel/ordine/genera",this.ordine, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.ordine.ID_ORDINE = response.id_inserito;
                        if (flgEsci == 1){
                            global.router.push("Ordini");
                        }
                        else{
                            this.getOrdine();
                        }
                    }
                });  
            }
            else{
                utils.ajax("travel/ordine/update",this.ordine, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        if (flgEsci == 1){
                            global.router.push("Ordini");
                        }
                    }
                }); 
            }
        },
        doModificaAgenzia : function(pg){
            this.ordine.FK_ID_AZIENDA_AGENZIA = pg.ID_AZIENDA;
            this.ordine.agency = {ID_AZIENDA:pg.ID_AZIENDA,personaGiuridica:utils.clone(pg)};
            if (this.ordine.ID_ORDINE > 0){
                this.salvaOrdine();
            }
        },
        doModificaIntestatario : function(p,tipo){
            if (tipo == 1){
                this.ordine.INTESTATARIO_FK_ID_PERSONA_FISICA = p.ID_PERSONA_FISICA;
                this.ordine.intestatarioPersonaFisica = utils.clone(p);
                this.ordine.INTESTATARIO_FK_ID_PERSONA_GIURIDICA = 0;
                this.ordine.intestatarioPersonaGiuridica = {};
            }
            else{
                this.ordine.INTESTATARIO_FK_ID_PERSONA_GIURIDICA = p.ID_PERSONA_GIURIDICA;
                this.ordine.intestatarioPersonaGiuridica = utils.clone(p);
                this.ordine.INTESTATARIO_FK_ID_PERSONA_FISICA = 0;
                this.ordine.intestatarioPersonaFisica = {};
            }
            if (this.ordine.ID_ORDINE > 0){
                this.salvaOrdine();
            }
        },
        nuovaQuota : function(id){
            this.$root.ordineSelezionato = utils.clone(this.ordine);
            global.router.push("Quota");
        },
        ricalcolaValore : function(valore){
            utils.alert.confirm("Sei sicuro di voler ricalcolare il valore del campo <strong>" + valore + "</strong>?",()=>{
                utils.ajax("travel/ordine/ricalcola/valore",{ID_ORDINE:this.ordine.ID_ORDINE,VALORE:valore}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getOrdine();
                    }
                });
            },this.$root);
        },
        modificaQuota : function(quota){
            this.$refs.editQuota.openSchedaQuota(quota);
        },
        eliminaQuota : function(quota){
            this.getOrdine();
        },
        modificaPax : function(pax){
            this.paxSelezionato = utils.clone(pax);   
            this.filters.openPopUp("popUpPax");  
        },
        salvaPax : function(pax){
            utils.ajax("travel/ordine/pax/update",this.paxSelezionato, (response) => {
                if (response.esito == 0){
                    this.filters.closePopUp("popUpPax");  
                    this.getOrdine();
                }
            });                      
        },
        modificaDate : function(quota){
            this.$refs.schedaQuota.openSchedaQuoteEdit(quota,this.ordine);
        },        
        datePickerChangeHandler : function(){
            if (this.dataSoggiornoRange.length > 0){
                this.ordine.DATA_INIZIO_SOGGIORNO = this.filters.formatDate(this.dataSoggiornoRange[0]);
                this.ordine.DATA_FINE_SOGGIORNO = this.filters.formatDate(this.dataSoggiornoRange[1]);
            }
        },
        openSchedaProdotto : function(prodotto){
            this.$refs.schedaVisualizzazioneProdotto.openSchedaProdotto(prodotto);
        },
        ricalcolaDateSoggiorno : function(){
            utils.ajax("travel/ordine/ricalcola/date",{ID_ORDINE:this.ordine.ID_ORDINE}, (response) => {
                if (response.esito == 0){
                    this.getOrdine();
                }
            });              
        },
        stampaRiepilogo : function(){
            this.$refs.schedaEmail.startup(this.ordine);
        },
        openSchedaOrdineEvento : function(log){
            this.ordine.ID_LOG_SELEZIONATO = log.ID_LOG;
            var myOffcanvas = document.getElementById('panel_right')
            var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
            bsOffcanvas.show();
            this.$refs.schedaOrdine.openSchedaOrdineEvento(log);
        },
        flgCommissionabileChangeHandler : function(){
            this.ordine.MODALITA_COMMISSIONE = 0;
        }
    },

    computed:{
        quoteVisibili : function(){
            var arr = new Array();
            for (var i = 0; i < this.ordine.quote.length ; i++){
               // if (this.ordine.quote[i].FK_ID_QUOTA_PRINCIPALE == 0){
                    arr.push(this.ordine.quote[i]);
              //  }
            }
            return arr;
        }
    },  

    created : function(){
        if (this.$root.ordineSelezionato.ID_ORDINE > 0){
            this.ordine.ID_ORDINE = this.$root.ordineSelezionato.ID_ORDINE;
            this.getOrdine();
        }
        else{
            this.ordine = utils.clone(this.$root.ordineSelezionato);
            this.ordine.FK_ID_UTENTE_ADMIN = this.$root.utente.ID_UTENTE;
        }
    }
}
</script>