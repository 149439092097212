import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


// NOME TEMPLATE: LIMITLESS
import "./assets/css/library/css-loader.css" 
import "./assets/css/library/loader-default.css" 

import "./assets/fonts/fonts-awesome-all.css" 
import "./assets/fonts/fonts-roboto.css" 
import "./assets/fonts/fonts-icomoon_2.css" 
import "./assets/fonts/fonts-notosans.css" 
import "./assets/fonts/inter/inter.css" 
import "./assets/fonts/phosphor/styles.min.css" 

import 'animate.css';

import "./assets/css/library/all.min.css" 
import "./assets/css/custom.css"

//import 'popper.js';

import "./assets/js/library/bootstrap.bundle.min.js";
import "./assets/js/library/d3.min.js";
import Notify from 'simple-notify'
import 'simple-notify/dist/simple-notify.css'
import 'vue3-emoji-picker/css'

window.bootstrap = require('./assets/js/library/bootstrap.bundle.min.js');

import AppTemplate from "./assets/js/library/app.js";
import io from 'socket.io-client';


//import "./assets/js/select2.min.js";
import * as echarts from 'echarts/dist/echarts.js';
import utils from "./assets/js/utils.js"
import filters from "./assets/js/filters.js"
import t from "./assets/js/t.js"

import moment from 'moment';
import CKEditor from '@ckeditor/ckeditor5-vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Translate from '@/components/utils/Translate.vue'
import 'vue-multiselect/dist/vue-multiselect.css';

global.AppTemplate = AppTemplate.AppTemplate;
global.router = router;
global.echarts = echarts;
global.utils = utils.utils;
global.filters = filters.filters;
global.t = t.t;
global.io = io.io;

global.moment = moment;

global.sprint = createApp(App).use(router).use(CKEditor).use(Datepicker);
global.sprint.component('Datepicker', Datepicker);
global.sprint.component('Translate', Translate);

global.sprint.config.devtools = true
global.sprint.mount('#app');
global.utils.createLoader();



window.addEventListener("resize", (event) => {
    global.utils.resizeContent();
});
