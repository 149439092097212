<template>

    <!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link> 
                    <a href="#" class="d-none d-lg-inline-block text-dark fw-semibold cursor-pointer" v-on:click="getOrdini()"> Pratiche </a> <span class="d-none d-lg-inline-block">\</span>
                    <span class="text-muted ms-1"><Translate :path="'dizionario.base.ELENCO'"></Translate> Pratiche</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
	
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;" v-on:click="openOrdine({ID_ORDINE:0})">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1 ms-1">
							<div class="fs-sm text-muted mb-0">
								<Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Ordine
							</div>
							<div class="text-success fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(56)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(56)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(56)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>   
	                </div>
	            </div>
	        </div>
		</div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#ordini-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7"></i>	 
								<span class="d-lg-none ms-1">Pratiche</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Pratiche</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1" v-on:click="openRoute('/Preventivi')">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-calendar"></i>
								<span class="d-lg-none ms-1">Prev.</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Preventivi</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#ordini-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1" v-on:click="statisticheStartup();">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>
								<span class="d-lg-none ms-1">Stats.</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate></span>
							</div>
						</a>
					</li>
					<li class="nav-item d-lg-none ms-auto">
						<a href="#profile_nav" class="navbar-nav-link navbar-nav-link-icon rounded-pill rounded-pill-small collapsed" data-bs-toggle="collapse" aria-expanded="false">
							<i class="ph-caret-down collapsible-indicator"></i>
						</a>
					</li>
				</ul>

           		<Datepicker autocomplete="off" class="ms-lg-auto bg-light text-center mt-xs-1" style="width:320px;" v-model="dataCreazioneRange" @update:model-value="datePickerChangeHandler()" range multi-calendars :format="'dd/MM/yyyy HH:mm'" auto-apply :partial-range="false" placeholder="Filtra per data inserimento" :tabindex="2" />

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0 select-small">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
									<i class="ph-gear me-1"></i> Azioni Multiple
									<span class="d-lg-none ms-2">More</span>
								</a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
		                        		<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
		                                </div>
		                            </div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
		                                <div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Ordini selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti gli Ordini</a>
		                                </div>
	                            	</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina selezionati</a>
								</div>
							</div>
						</li>
						<li class="nav-item ms-3">
	                        <select class="form-select form-select-sm changeyear" id="comboAnno" @change="getOrdini()" v-model="search.ANNO_ORDINE.VALUE" :tabindex="4">
	                            <option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
	                        </select>
						</li>
					</ul>
			   </div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="ordini-a">
        		<div class="card shadow mb-xs-3 mb-lg-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-warning btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								MOSTRA FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="ordine-a">
		                            <div class="row">
		                                <div class="col-6 col-md-1 col-lg-1 mbx-1">
		                                    <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id pratica" :tabindex="6" v-model="search.ID_ORDINE.VALUE" />
		                                </div> 
		                                <div class="col-6 col-md-1 col-lg-1 mbx-1">
		                                    <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Pnr-pratica" :tabindex="7" v-model="search.PNR_FORMATTATO.VALUE" />
		                                </div>  
		                                <div class="col-12 col-md-2 col-lg-2 mbx-1">
		                                    <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Intestatario Pratica" :tabindex="8" v-model="search.INTESTATARIO.VALUE" />
		                                </div>	 
		                                <div class="col-12 col-md-2 col-lg-2 mbx-1">
		                                    <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Titolo Pratica" :tabindex="9" v-model="search.TITOLO_PRATICA.VALUE" />
		                                </div> 
										<div class="col-12 col-md-6 col-lg-6">
		                                    <div class="row">
		                                        <div class="col-6 col-md-2 col-lg-2 mbx-1">
		                                            <select class="form-select form-select-sm" data-placeholder="Sito Web" :tabindex="10" v-model="search.FK_ID_ENTE.VALUE">
		                                                <option v-bind:value="''">Sel. Ente</option> 
                                                        <option v-for="ente in $root.arrayEnti" v-bind:key="ente.ID_ENTE" v-bind:value="ente.ID_ENTE"> - {{ ente.LABEL }}</option> 
		                                            </select>
		                                        </div>
		                                        <div class="col-6 col-md-2 col-lg-2 mbx-1">
		                                            <select class="form-select form-select-sm" data-placeholder="Tipologia booking" tabindex="11" v-model="search.FK_ID_TIPO.VALUE">
		                                                <option v-bind:value="''">Sel. Tipo</option> 
		                                                <option v-for="tipo in $root.arrayPraticaBook" v-bind:key="tipo.ID_OPZIONE_TIPOLOGICA" v-bind:value="tipo.ID_OPZIONE_TIPOLOGICA"> - {{ tipo.DESCRIZIONE }}</option> 
		                                            </select>
		                                        </div>
		                                        <div class="col-6 col-md-2 col-lg-2 mbx-1">
		                                            <select class="form-select form-select-sm" data-placeholder="Tipologia pratica" tabindex="12" v-model="search.FLG_GRUPPI.VALUE"> 
		                                                <option v-bind:value="''">Sel. Pratica</option> 
                                                        <option v-for="pratica in $root.arrayPraticaTipo" v-bind:key="pratica.ID_OPZIONE_TIPOLOGICA" v-bind:value="pratica.ID_OPZIONE_TIPOLOGICA"> - {{ pratica.DESCRIZIONE }}</option> 
		                                            </select>
		                                        </div>
		                                        <div class="col-6 col-md-2 col-lg-2 mbx-1">
		                                            <select class="form-select form-select-sm" data-placeholder="Stato Saldo" :tabindex="13" v-model="search.STATO_PAGAMENTO.VALUE">
		                                                <option v-bind:value="''">Sel. Saldo</option>
                                                        <option v-for="pago in $root.arrayPraticaPagamento" v-bind:key="pago.ID_OPZIONE_TIPOLOGICA" v-bind:value="pago.ID_OPZIONE_TIPOLOGICA"> - {{ pago.DESCRIZIONE }}</option>  
		                                            </select>
		                                        </div>
		                                        <div class="col-6 col-md-2 col-lg-2">
		                                            <select class="form-select form-select-sm" @change="gestioneCambioFiltroStato()" data-placeholder="Stato Pratica" v-model="search.FK_ID_STATO.VALUE" :tabindex="14">
                                                        <option v-bind:value="''">Sel. Stato</option>
                                                        <option v-for="stato in $root.arrayPraticaStato" v-bind:key="stato.ID_OPZIONE_TIPOLOGICA" v-bind:value="stato.ID_OPZIONE_TIPOLOGICA"> - {{ stato.DESCRIZIONE }}</option>  
		                                            </select>
		                                        </div>
												<div class="col-6 col-md-2 col-lg-2">
													<a href="#collapse-a" class="btn btn-flat-info btn-sm w-100 collapsed" data-bs-toggle="collapse" v-on:click="filters.resizeGrid('collapse-a','tableFixHead','box-card')" role="button" aria-expanded="false">
														+ FILTRI
													</a>
												</div>  
		                                    </div>
		                                </div>  
		                            </div>
		                            <div class="collapse" id="collapse-a" style="">
		                                <div class="row ">  
		                                    <div class="col-12 col-md-2 mt-1">
                                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Destinazione" v-model="search.FK_ID_UTENTE.VALUE"/>
		                                    </div>         
		                                    <div class="col-12 col-md-3">
		                                        <div class="row ">
		                                            <div class="col-6 col-md-6 mt-1">
		                                                <Datepicker model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" v-model="search.DATA_INIZIO_SOGGIORNO.VALUE_FROM"></Datepicker>
		                                            </div> 
		                                            <div class="col-6 col-md-6 mt-1">
		                                                <Datepicker model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" v-model="search.DATA_INIZIO_SOGGIORNO.VALUE_TO"></Datepicker>
		                                            </div>
		                                        </div>
		                                    </div>
                                            <div class="col-12 col-md-3">
		                                        <div class="row ">
                                                    <div class="col-6 col-md-6 mt-1">
                                                        <select class="form-select form-select-sm" data-placeholder="Promotore" :tabindex="20" v-model="search.FK_ID_PROMOTORE.VALUE">
                                                            <option value="">Promotore </option>  
                                                            <option v-for="promotore in arrayPromotori" v-bind:key="promotore.ID_PROMOTORE" v-bind:value="promotore.ID_PROMOTORE">{{ promotore.COGNOME }} {{ promotore.NOME }}</option> 
                                                        </select>
                                                    </div>
                                                    <div class="col-6 col-md-6 mt-1">
                                                        <select class="form-select form-select-sm" data-placeholder="Promotore" :tabindex="20" v-model="search.FK_ID_UTENTE.VALUE">
                                                            <option value="">Agente </option>  
                                                            <option v-for="agente in arrayAgenti" v-bind:key="agente.ID_AGENTE" v-bind:value="agente.ID_AGENTE">{{ agente.COGNOME }} {{ agente.NOME }}</option> 
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
		                                    <div class="col-6 col-md-2">
		                                        
		                                    </div>
		                                    <div class="col-6 col-md-2">
		                                        
		                                    </div>
		                                </div>
		                            </div>
								</form>
		                    </div>
		                </div>
		            </div>
				
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="div_table_resp tableFixHead" id="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable text-nowrap"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
		                                <th class="table-head-sm" style="width: 2%;"> <input id="chkAllOrdini" v-on:click="filters.selectAll('chkAllOrdini',paginatedList.arr)" type="checkbox" class=""> </th>
		                                <th class="table-head-sm" style="width: 6%;"> # </th>
		                                <th class="table-head-sm text-center" style="width: 7%;">Data creazione</th>
		                                <th class="table-head-sm">Titolo pratica<br />Durata e Passeggeri</th>
		                                <th class="table-head-sm" style="width: 21%;"> Intestatario </th>
		                                <th class="table-head-sm" style="width: 10%;">Totale </th>
		                                <th class="table-head-sm" style="width: 9%;">Saldo </th>
		                                <th class="table-head-sm text-center" style="width: 8%;">Stato Pratica</th>
		                                <th class="table-head-sm text-center" style="width: 5%;">Catturata</th>
		                                <th class="table-head-sm text-center" style="width: 5%;">Azioni</th>
		                            </tr>
		                        </thead>
		                        <tbody>
		                            <tr v-for="ordine in paginatedList.arr" v-bind:key="ordine.ID_ORDINE">
		                                <td class="td-class text-center">
											<span class="footable-toggle"></span>
		                                    <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="ordine.SELEZIONATO" class="">
		                                </td>
                                        <td class="td-class fs-sm">
											<span>{{filters.getPnr(ordine)}}</span><br />
											<small class="badge badge-xs bg-light text-primary float-end">{{ordine.ID_ORDINE}}</small>
										</td>
		                                <td class="td-class text-center fs-sm">
											{{filters.formatDateTime(ordine.DATA_CREAZIONE)}}<br />
                                            <span class="badge badge-xs bg-dark bg-opacity-20 text-reset">{{ordine.FK_ID_ENTE}}</span>
										</td>
		                                <td class="td-class fs-sm">
                                            <span class="badge badge-xs" v-bind:class="getTipoPraticaClass(ordine)">{{getTipoPratica(ordine)}}</span> -
                                            <span class="text-primary fw-bold text-uppercase cursor-pointer" v-on:click="openOffCanvas();openSchedaOrdine(ordine)" style="line-height: 1.54;">{{ordine.DESTINAZIONE}} - {{ filters.accorciaTesto(ordine.TITOLO_PRATICA,39) }}</span>
                                            <br/>
											<span class="text-muted fs-sm fw-normal">
		                                    	<i class="far fa-calendar fa-fw"></i> {{filters.formatDate(ordine.DATA_INIZIO_SOGGIORNO)}} - 
		                                    	<i class="far fa-calendar fa-fw"></i> {{filters.formatDate(ordine.DATA_FINE_SOGGIORNO)}}
		                                    	<i class="far fa-users fa-fw ms-2"></i> 2 Adu, 0 Chd, 0 Inf
										   	</span>
		                                </td>
		                                <td class="td-class fs-sm">
                                            <span class="badge badge-xs bg-opacity-20" v-bind:class="getTipoBookClass(ordine)">{{getTipoBook(ordine)}}</span> -
		                                    <span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="openSchedaUtente(ordine)" v-show="ordine.FK_ID_AZIENDA_AGENZIA > 0">{{filters.accorciaTesto(ordine.AGENZIA,35)}}</span>
		                                    <span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="openSchedaUtente(ordine)" v-show="ordine.FK_ID_AZIENDA_AGENZIA == 0 && ordine.INTESTATARIO_FK_ID_PERSONA_FISICA > 0">{{filters.accorciaTesto(ordine.INTESTATARIO_PERSONA_FISICA,35)}}</span>
		                                    <span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="openSchedaUtente(ordine)" v-show="ordine.FK_ID_AZIENDA_AGENZIA == 0 && ordine.INTESTATARIO_FK_ID_PERSONA_GIURIDICA > 0">{{filters.accorciaTesto(ordine.INTESTATARIO_PERSONA_GIURIDICA,35)}}</span>
		                                    <br />
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px solid rgb(221, 221, 221);" :title="ordine.EMAIL" data-bs-toggle="tooltip" data-bs-placement="top" v-bind:href="`mailto:${ordine.EMAIL}`"><i class="fal fa-envelope fa-fw"></i> Email</span>
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px solid rgb(221, 221, 221);" :title="ordine.TELEFONO" data-bs-toggle="tooltip" data-bs-placement="top" v-bind:href="`tel:${ordine.TELEFONO}`"><i class="fal fa-phone fa-fw"></i> Tel.</span>
		                                </td>
                                        <td class="td-class fs-sm">
											<div>
												<i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Totale: <a href="#">&euro; {{ordine.PREZZO_TOTALE}}</a>
											</div>
											<div>
												<i class="ph-clock fs-base lh-base align-top text-danger me-1"></i> Comm: <a href="#">&euro; {{ordine.COMMISSIONI}}</a>
											</div>
										</td>
                                        <td class="td-class text-center fs-sm">
                                            <span v-show="ordine.STATO_PAGAMENTO == 465" class="badge bg-danger badge-xs bg-opacity-20 text-danger font-weight-semibold cursor-pointer ms-2" v-on:click="openIncassi(ordine)">Da Saldare</span>
                                            <span v-show="ordine.STATO_PAGAMENTO == 466" class="badge bg-indigo badge-xs bg-opacity-20 text-indigo font-weight-semibold cursor-pointer ms-2" v-on:click="openIncassi(ordine)">Acconto</span>
                                            <span v-show="ordine.STATO_PAGAMENTO == 467" class="badge bg-success badge-xs bg-opacity-20 text-success font-weight-semibold cursor-pointer ms-2" v-on:click="openIncassi(ordine)">Saldato</span>
                                        </td>
		                                <td class="td-class text-center fs-sm">
		                                    <span class="badge badge-xs cursor-pointer" v-bind:class="getBtnStatusClass(ordine)" v-on:click="openStatoOrdine()">
		                                        {{filters.getLblStatoOrdine(ordine.FK_ID_STATO)}}
		                                    </span>
		                                </td>
		                                <td class="td-class text-center fs-sm">
		                                    <span class="badge badge-xs bg-warning bg-opacity-20 text-warning cursor-pointer" v-on:click="openCattura(ordine)"><i class="fas fa-thumbs-down fa-fw"></i></span>
		                                </td>
		                                <td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
	                                                <div class="dropdown-header text-uppercase font-size-xs line-height-xs">Pratica</div>
	                                                <a href="#" class="dropdown-item" v-on:click="openOrdine(ordine)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica pratica</a>
	                                                <a href="#" class="dropdown-item" v-on:click="storicoOrdine(ordine)"><i class="fad fa-list fa-fw me-2"></i><span class="text-muted me-1">|</span>Storico pratica</a>
	                                                <div class="dropdown-header text-uppercase font-size-xs line-height-xs">Stampe</div>
	                                                <a href="#" class="dropdown-item" v-on:click="stampaRiepilogo(ordine)"><i class="fad fa-envelope fa-fw me-2"></i><span class="text-muted me-1">|</span>Riepilogo email</a>
	                                                <a href="#" class="dropdown-item" v-on:click="stampePratica(ordine,1001)"><i class="fad fa-file-invoice-dollar fa-fw me-2"></i><span class="text-muted me-1">|</span>Estratto conto</a>
	                                                <a href="#" class="dropdown-item" v-on:click="stampePratica(ordine,1002)"><i class="fad fa-file-contract fa-fw me-2"></i><span class="text-muted me-1">|</span>Contratto viaggio</a>                                        
	                                                <a href="#" class="dropdown-item" v-on:click="deletePratica(ordine)"><i class="fad fa-trash-alt fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Gestione documenti</a>                                        
	                                            </div>
	                                        </div>
		                                </td>
		                            </tr>
		                        </tbody>
		                    </table>
		                </div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">PRATICHE: {{ paginatedList.count }}</span> 
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">LORDO: {{ filters.formattaImporto(getTotali.LORDO) }}</span> - 
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">COMM: {{ filters.formattaImporto(getTotali.COMM )}}</span> = 
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">NETTO: {{filters.formattaImporto(getTotali.NETTO)}}</span>
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end pag-mobile">
		                            <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH"></Pagine>
		                        </div>
		                    </div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
		                	</div>
		            	</div>
		        	</div>
		    	</div>
			</div>

            <div class="tab-pane fade" id="ordini-c">

				<div class="row">
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-users ph-2x text-primary me-3"></i>
								<div class="flex-fill text-end">
									<h4 class="mb-0">{{ getTotali.COUNT }}</h4>
									<span class="text-muted">Totale Ordini</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-thumbs-up ph-2x text-success me-3"></i>

								<div class="flex-fill text-end">
									<h4 class="mb-0">{{ getTotali.USER_OK }}</h4>
									<span class="text-muted">Utenti attivi</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-thumbs-down ph-2x text-danger me-3"></i>
								<div class="flex-fill text-end">
									<h4 class="mb-0">{{  getTotali.USER_KO }}</h4>
									<span class="text-muted">Utenti 0 pratiche</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0"></h4>
									<span class="text-muted">Utenti bannati</span>
								</div>
								<i class="ph-house-simple ph-2x text-danger ms-3"></i>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0">2365</h4>
									<span class="text-muted">Utenti </span>
								</div>
								<i class="ph-sketch-logo ph-2x text-indigo ms-3"></i>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0">389,438</h4>
									<span class="text-muted">Utenti </span>
								</div>
								<i class="ph-globe-hemisphere-east ph-2x text-purple ms-3"></i>
							</div>
						</div>
					</div>
				</div>

				<Statistiche ></Statistiche> 

			</div>

		</div>		
	</div>


	<!-- START MODALI -->
    <div class="modal fade" id="popUpStatoOrdine" role="dialog" tabindex="-1">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> CAMBIO STATO PRATICA </span>
                    </h6>
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">	  

                    <p class="sepH_c" style="font-size: 14px; text-align: center;">
                        <span id="modal_status_subtitle">sei sicuro di voler cambiare lo stato di questa Pratica?</span> <br> 
                        <span id="modal_status_accept">Accettando, la pratica e relative Quote, passeranno al nuovo stato selezionato.</span>
                    </p> 
                    <hr> 
                    <div class="row">
                        <div class="col-md-4">
                            <h6><strong>1.</strong> Scegli la lingua</h6> 
                            <div class="form-group">
                                <select id="modal_status_lang_combo" class="form-select form-select-sm">
                                    <option value="de">Tedesco</option>
                                    <option value="en">Inglese</option>
                                    <option value="es">Spagnolo</option>
                                    <option value="fr">Francese</option>
                                    <option selected="selected" value="it">Italiano</option>
                                </select>
                            </div>
                        </div> 
                        <div class="col-md-4">
                            <h6><strong>2.</strong> Cambia lo Stato</h6> 
                            <div class="form-group">
                                <select name="order_status" id="modal_order_status" class="form-select form-select-sm">
                                    <option value="0">Seleziona uno stato</option>
                                    <option value="delivered"> - Mantieni in Confermata</option>
                                    <option value="cancelled"> - Cancella Prenotazione</option>
                                </select> 
                                <span id="numeroPratica" style="display: none;"></span>
                            </div>
                        </div> 
                        <div class="col-md-4">
                            <h6><strong>3.</strong> Invia la Email</h6> 
                            <div class="form-group">
                                <select name="order_service" id="flgInvioConferma" class="form-select form-select-sm">
                                    <option value="0">Seleziona</option> 
                                    <option value="1">SI</option> 
                                    <option value="2">NO</option>
                                </select> 
                                <span id="numeroPratica"></span>
                            </div>
                        </div> 
                    </div> 
                    <div class="row"> 
                        <div class="col-md-6">
                            <h6><strong>4.</strong> Pratica da gestire con Penali?</h6> 
                            <div class="form-group">
                                <select class="form-select form-select-sm">
                                    <option value="0">NO. Procedi a cancellare tutti gli importi</option> 
                                    <option value="1">SI. Procedi a gestire con penali</option>
                                </select>
                            </div>
                        </div> 
                        <div class="col-md-6">
                            <h6><strong>4.</strong> Penale &euro;?</h6> 
                            <div class="form-group">
                                <input class="form-select form-select-sm">
                            </div>
                        </div>
                    </div> 
                    <div class="row"> 
                        <div class="col-md-12">
                            <h6>
                                <strong>5.</strong> Invio Email Servizi <br>
                                <small class="text-muted mt-0">(Flegga le caselle sottostanti per inviare le mail di conferma o disdetta ai relativi operatori.)</small>
                            </h6> 
                            <div class="row">
                                <div class="col-md-4">
                                    <label>
                                        <input type="checkbox" name="suppemailok" id="suppemailok" value="1"> 
                                        <span class="label label-primary" style="padding-left: 10px; position: relative; top: -3px;">Fornitori</span>
                                    </label> <br>
                                    <button class="btn btn-light btn-sm">Anteprima Email</button>
                                </div> 
                                <div class="col-md-4">
                                    <label>
                                        <input type="checkbox" name="advemailok" id="advemailok" value="1"> 
                                        <span class="label label-primary" style="padding-left: 10px; position: relative; top: -3px;">Agenzie</span>
                                    </label> <br>
                                    <button class="btn btn-light btn-sm">Anteprima Email</button>
                                </div> 
                                <div class="col-md-4">
                                    <label>
                                        <input type="checkbox" name="cliemailok" id="cliemailok" value="1"> 
                                        <span class="label label-primary" style="padding-left: 10px; position: relative; top: -3px;">Clienti</span>
                                    </label> <br>
                                    <button class="btn btn-light btn-sm">Anteprima Email</button>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="modal-footer bg-light p-2">
                    <input type="hidden" name="old_status" id="old_status" value="ordine.status'" />
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end"><i class="fal fa-print fa-fw me-1"></i> Cambia Stato </button> <!--  v-on:click="sendStatus()" -->                                  
                </div>
            </div>
        </div>
    </div>    

    <div class="modal fade" id="popUpDettaglioOrdine" role="dialog" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> Intestatario </span>
                    </h6>
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">  

                   {{ ordineSelezionato }}

                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end"><i class="fal fa-print fa-fw me-1"></i> Si Importa </button> <!--  v-on:click="sendStatus()" -->                                  
                </div>
            </div>
        </div>
    </div>  
    
    <div class="modal fade" id="popUpStoricoOrdine" role="dialog" tabindex="-1">
    	<div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-danger text-white">
                    <h6 class="modal-title">Logs dettagli Azioni - </h6>
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body"> 

                    <div class="tableFixHead">
						<table class="table table-bordered table-hover table-striped table-togglable"> 
							<thead>
								<tr>
									<th class="table-head-sm" style="width:20%;"> Data </th>
									<th class="table-head-sm" style="width:20%;">Utente</th>
									<th class="table-head-sm" style="">Api</th>
								<th class="table-head-sm" style="width:5.4%;">Azione</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="operazione in ordineSelezionato.storico" v-bind:key="operazione.ID_LOG">
									<td class="table-head-sm" style="">{{ filters.formatDateTimeSeconds(operazione.DATA_AZIONE) }}</td>
									<td class="table-head-sm" style="">{{ operazione.USERNAME }}</td>
									<td class="table-head-sm font-size-xs" style="">{{ operazione.DESCRIZIONE }}</td>
									<td class="text-center">
										<div class="list-icons" :tabindex="26">
											<a href="#" class="list-icons-item" v-on:click="apriDettaglioAzione(azione)"><i class="icon-menu9"></i></a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>                                  
                </div>
            </div>
        </div>
    </div> 

    <div class="modal fade" id="popUpVisualizzazioneProdotto" role="dialog" tabindex="-1">
	    <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 85% !important;">Scheda Prodotto</h6>   
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
                </div> 
                <div class="modal-body p-2" style="height: 32rem;"> 

                    <SchedaVisualizzazioneProdotto :ref="'schedaVisualizzazioneProdotto'"></SchedaVisualizzazioneProdotto>                   
        
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>                                 
                </div>               
            </div>
        </div>
    </div>   
    
    <div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right_ordini" role="dialog" data-bs-backdrop="false" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
			<h5 class="offcanvas-title fw-semibold">{{ ordineSelezionato.TITOLO_PRATICA }}</h5>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"></button>
		</div>

		<div class="offcanvas-body p-2">

			<SchedaOrdine :ref="'schedaOrdine'" @selezionaProdotto="openSchedaProdotto($event)" ></SchedaOrdine>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <div style="position: relative; top: 2px;">
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="LORDO"><strong class=" me-1">L:</strong> € {{ filters.formattaImporto(ordineSelezionato.PREZZO_TOTALE) }}</span>
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="COMMISSIONE"><strong class=" me-1">A:</strong> € {{ filters.formattaImporto(ordineSelezionato.COMMISSIONI) }}</span>
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="NETTO"><strong class=" me-1">N:</strong> € {{ filters.formattaImporto(ordineSelezionato.PREZZO_TOTALE - ordineSelezionato.COMMISSIONI) }}</span>
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="COSTO"><strong class=" me-1">C:</strong> € {{ filters.formattaImporto(ordineSelezionato.COSTO_TOTALE) }}</span>
                <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>
            </div>       
        </div>  
	</div> 

    <SchedaUtente :ref="'schedaUtente'"></SchedaUtente> 
    <SchedaAzienda :ref="'schedaAzienda'" :tipo="1"></SchedaAzienda>
    <SchedaIncasso :ref="'schedaIncasso'"></SchedaIncasso>
    <SchedaEmailOrdine :ref="'schedaEmail'" :tipo="1"></SchedaEmailOrdine>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
//import Select2 from '@/components/utils/Select2.vue'
import Datepicker from '@vuepic/vue-datepicker'
import SchedaIncasso from '@/components/SchedaIncasso.vue'
import SchedaUtente from '@/components/SchedaUtente.vue'
import SchedaAzienda from '@/components/SchedaAzienda.vue'	
import PersonaFisica from '@/components/common/PersonaFisica.vue'	
import PersonaGiuridica from '@/components/common/PersonaGiuridica.vue'	
import SchedaOrdine from '@/components/SchedaOrdine.vue'	
import SchedaVisualizzazioneProdotto from '@/components/SchedaVisualizzazioneProdotto.vue'
import SchedaEmailOrdine from '@/components/SchedaEmailOrdine.vue'
import Statistiche from '@/components/Statistiche.vue'	


export default {
    name:"Ordini",
	  
    emits:['selezionaProdotto'],
    
    components:{
        Pagine,
        //Select2,
        Datepicker,
        SchedaUtente,
		SchedaAzienda,
        //PersonaGiuridica, 
        //PersonaFisica,
        SchedaOrdine,
        SchedaIncasso,
        SchedaVisualizzazioneProdotto,
        SchedaEmailOrdine,
		Statistiche
    },			 

    data:function(){
        return {
            apiStartup:'travel/ordini',
            filters:global.filters,
			GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            arrayOrdini:new Array(),
            arrayPromotori : new Array(),
            intestatarioSelezionato:{},
            ordineSelezionato:{storico:new Array()},
            periodoSelezionato:{},
            dataCreazioneRange:{},
            prodottoSelezionato:{FK_ID_MODULO:0},
            arrayCategorie:new Array(),
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
	            ID_ORDINE:{TYPE:'TEXT',VALUE:''},
                PNR_FORMATTATO:{TYPE:'TEXT',VALUE:''},
                CODICE_ORDINE:{TYPE:'TEXT',VALUE:''},
                FK_ID_STATO:{TYPE:'COMBO',VALUE: ''},
                VISIBILE_DEFAULT:{TYPE:'COMBO',VALUE: 1},
	            INTESTATARIO:{TYPE:'TEXT',VALUE:''}, 
                TITOLO_PRATICA:{TYPE:'TEXT',VALUE:''},
                FK_ID_TIPO:{TYPE:'COMBO',VALUE:''},
                FK_ID_ENTE:{TYPE:'COMBO',VALUE:''},
                FK_ID_AZIENDA_FORNITORE:{TYPE:'COMBO',VALUE:''},               
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
                FLG_GRUPPI:{TYPE:'COMBO',VALUE:''}, 
                STATO_PAGAMENTO:{TYPE:'COMBO',VALUE:''}, 
                FK_ID_PROMOTORE:{TYPE:'COMBO',VALUE:''}, 
                FK_ID_UTENTE:{TYPE:'COMBO',VALUE:''},   
                DATA_INIZIO_SOGGIORNO:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},              
                DATA_CREAZIONE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},
	        },
			statsOrdini:{},
			searchStatisticheOrdini:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},
				FK_ID_PROVINCIA:{TYPE:'COMBO',VALUE:''},
				FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''},
				FK_ID_TIPO:2,
				FLG_GRUPPI:{TYPE:'COMBO',VALUE:''},
				FK_ID_NETWORK:{TYPE:'COMBO',VALUE:''},					
            },
			arrayStatisticheOrdiniFatt : new Array(),
			searchStatisticheOrdiniFatt:{
				PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},
				FK_ID_PROVINCIA:{TYPE:'COMBO',VALUE:''},
				FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''},
				FK_ID_TIPO:2,
				FLG_GRUPPI:{TYPE:'COMBO',VALUE:''},
				FK_ID_NETWORK:{TYPE:'COMBO',VALUE:''},
				DATA_CREAZIONE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},	
			}

        }
    },

    methods:{
        getOrdini:function(){
            utils.ajax(this.apiStartup,{ANNO_ORDINE:this.search.ANNO_ORDINE.VALUE}, (response) => {
                if (response.esito == 0){
                    this.arrayOrdini = response.data;
                    for (var i = 0 ; i < this.arrayOrdini.length ; i++){
                        this.arrayOrdini[i].PNR_FORMATTATO = this.filters.getPnr(this.arrayOrdini[i]);
                        this.arrayOrdini[i].SELEZIONATO = 0;
                    }
                }
            });  
        },

        openOrdine:function(ordine){
            if (ordine.ID_ORDINE == 0){               
                ordine = {
                    ID_ORDINE:0,
                    PREZZO_TOTALE:0,
                    COSTO_TOTALE:0,
                    COMMISSIONI:0,
                    FK_ID_STATO:0,
                    FK_ID_STATO_PAGAMENTO:1,
                    FK_ID_TIPO:0,
                    FK_ID_TIPO_FISCALE:0,
                    FLG_COMMISSIONE:0,
                    FK_ID_STATO_NAZIONALE:118,
                    FK_ID_UTENTE:this.$root.utente.ID_UTENTE,
                    FLG_GRUPPI:0,
                    FK_ID_ENTE:this.$root.idEnteCorrente,
                    FLG_COMMISSIONABILE:0,
                    MODALITA_COMMISSIONE:0,
                    DATA_CREAZIONE:this.filters.formatDateTimeDp(new Date()),
                    quote:{},
                    agency:{personaGiuridica:{}},
                    intestatarioPersonaFisica:{},
                    intestatarioPersonaGiuridica:{},
                };
            }
            this.$root.ordineSelezionato = utils.clone(ordine);
            global.router.push("Ordine");
        },

        openIncassi : function(ordine){
            this.$refs.schedaIncasso.openIncasso(ordine);
        },

        nuovaQuota : function(id){
            this.$root.ordineSelezionato = utils.clone(this.ordine);
            global.router.push("Quota");
        },
		
        getBtnStatusClass : function(ordine){
            var arr = utils.array.select(this.$root.arrayPraticaStato,"ID_OPZIONE_TIPOLOGICA",ordine.FK_ID_STATO,1);
            if (arr.length > 0){
                return "bg-"+ arr[0].ICONA;
            }
            return "";
        },

        getTipoBookClass : function(ordine){
            var arr = utils.array.select(this.$root.arrayPraticaBook,"ID_OPZIONE_TIPOLOGICA",ordine.FK_ID_TIPO,1);
            if (arr.length > 0){
                return "bg-"+ arr[0].ICONA+" text-"+ arr[0].ICONA;
            }
            return "";
        },
		
        getTipoBook : function(ordine){
            var arr = utils.array.select(this.$root.arrayPraticaBook,"ID_OPZIONE_TIPOLOGICA",ordine.FK_ID_TIPO,1);
            if (arr.length > 0){
                return arr[0].CUSTOM_1;
            }
            return "";
        },

        getTipoPraticaClass : function(ordine){
            var arr = utils.array.select(this.$root.arrayPraticaTipo,"ID_OPZIONE_TIPOLOGICA",ordine.FLG_GRUPPI,1);
            if (arr.length > 0){
                return "bg-indigo";
            }
            return "";
        },

        getTipoPratica : function(ordine){
            var arr = utils.array.select(this.$root.arrayPraticaTipo,"ID_OPZIONE_TIPOLOGICA",ordine.FLG_GRUPPI,1);
            if (arr.length > 0){
                return arr[0].DESCRIZIONE;
            }
            return "";
        },

        openStatoOrdine : function(){
			this.ordineSelezionato = {ID_ORDINE:0};
            this.filters.openPopUp("popUpStatoOrdine");
        },

        getDateRange : function(ordine){
            $('.daterange-predefined').daterangepicker(
                {
                    startDate: moment().subtract(29, 'days'),
                    endDate: moment(),
                    minDate: '01/01/2014',
                    maxDate: '12/12/2019',
                    dateLimit: { days: 60 },
                    timePicker: true,
                    parentEl: '.content-inner',
                    showDropdowns: true,
                    ranges: {
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    }
                },
                function(start, end) {
                    $('.daterange-predefined span').html(start.format('MMMM D, YYYY') + ' &nbsp; - &nbsp; ' + end.format('MMMM D, YYYY'));
                    $.jGrowl('Date range has been changed', { header: 'Update', theme: 'bg-primary text-white', position: 'center', life: 1500 });
                }
            );

            // Display date format
            $('.daterange-predefined span').html(moment().subtract(29, 'days').format('MMMM D, YYYY') + ' &nbsp; - &nbsp; ' + moment().format('MMMM D, YYYY'));
        }, 

        openSchedaUtente: function(ordine){
            if (ordine.FK_ID_AZIENDA_AGENZIA > 0){
                this.$refs.schedaAzienda.openSchedaAzienda({ID_AZIENDA:ordine.FK_ID_AZIENDA_AGENZIA});
            }
            else{
                if(ordine.INTESTATARIO_FK_ID_PERSONA_FISICA > 0){
                    this.$refs.schedaUtente.openSchedaUtente({ID_UTENTE:ordine.FK_ID_UTENTE_INTESTATARIO});
                }
                else{
                    utils.ajax("travel/personaGiuridica",{ID_PERSONA_GIURIDICA:ordine.INTESTATARIO_FK_ID_PERSONA_GIURIDICA}, (response) => {
                        if (response.esito == 0){
                            this.intestatarioSelezionato = response.data;
                        }
                    }); 
                }
                /*
                this.filters.openPopUp('#popUpIntestatario'); 
                var modal = $('#popUpIntestatario');
                modal.modal('show'); */
            }
        },

        openSchedaOrdine : function(ordine){
            this.ordineSelezionato = utils.clone(ordine);
            this.$refs.schedaOrdine.openSchedaOrdine(ordine);
        },

        datePickerChangeHandler : function(){
            this.search.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
            if (this.dataCreazioneRange != null && this.dataCreazioneRange.length > 0){
                this.search.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRange[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRange[1])};
            }
        },

		dataCreazioneRangeChangeHandler : function(){
            this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
            if (this.dataCreazioneRangeFatt != null && this.dataCreazioneRangeFatt.length > 0){
                this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRangeFatt[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRangeFatt[1])};
            }
        },

        gestioneCambioFiltroStato : function(){
            if (this.search.FK_ID_STATO.VALUE == 879 || this.search.FK_ID_STATO.VALUE == 658 ||this.search.FK_ID_STATO.VALUE == 659 ){
                this.search.VISIBILE_DEFAULT.VALUE = 0;
            } 
            else{
                this.search.VISIBILE_DEFAULT.VALUE = 1;
            }
            //this.search.VISIBILE_DEFAULT.VALUE = this.search.FK_ID_STATO.VALUE < 6 ? 1 : 0;
        },
        
        openSchedaProdotto : function(prodotto){    
            this.prodottoSelezionato = utils.clone(prodotto);
            this.$refs.schedaVisualizzazioneProdotto.openSchedaVisualizzazioneProdotto(prodotto.ID_PRODOTTO);
            this.filters.openPopUp("popUpVisualizzazioneProdotto");
        },

        refreshEnte : function(){
            this.getOrdini();
        },

        storicoOrdine : function(ordine){
            utils.ajax("travel/ordine",{ID_ORDINE:ordine.ID_ORDINE,FLG_STORICO:1}, (response) => {
                this.ordineSelezionato = response.data;
                this.filters.openPopUp("popUpStoricoOrdine");   
            });             
        },
        stampaRiepilogo : function(ordine){
            this.$refs.schedaEmail.startup(ordine);
        },

        stampePratica : function(ordine,idReport){
            window.open(utils.BASE_API + "core/report?ID_REPORT="+idReport+"&ID_ORDINE="+ordine.ID_ORDINE + utils.getSessionToken());
        },

        openRoute : function(route){
            global.router.push(route);
        },	

        openOffCanvas : function(){
            var myOffcanvas = document.getElementById('panel_right_ordini')
            var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
            bsOffcanvas.show();
        },
		
		getStatisticheOrdiniFatt(){
			utils.ajax('travel/statistiche/ordini/agenzie',this.searchStatisticheOrdiniFatt, (response) => {
				this.arrayStatisticheOrdiniFatt = response.data;
			});			
		},

        getStatisticheOrdiniChart : function(){
            //$('.grafico').removeAttr("_echarts_instance_");
            var arr = document.getElementsByClassName("grafico");//.removeAttribute("disabled");
            for (var i = 0; i < arr.length; i++){
                arr[i].removeAttribute("_echarts_instance_");
                arr[i].innerHTML = "";
            }
            utils.ajax('travel/statistiche/ordini/importo',this.searchStatisticheOrdini, (response) => {
                var option= {};
                //var chart1 = echarts.init(document.getElementById('chart1'));
                let chart1 = echarts.getInstanceByDom (document.getElementById ('chart1')); // get some words DOM node has echarts instance.
                if (chart1 == null) {// If not, it is initialized.
                    chart1 = echarts.init(document.getElementById('chart1'));
                }

                var arrayDistinctMesi = utils.array.distinct(response.data,"MESE","MESE");
                var arrayDistinctAnni = utils.array.distinct(response.data,"ANNO","ANNO");
                var series = new Array();
                var arrayAnni = [];
                for (var i = 0 ; i < arrayDistinctAnni.length ; i++){
                    arrayAnni.push(arrayDistinctAnni[i].ANNO.toString());
                }
                var arrayMesi = [];
                for (var i = 0 ; i < arrayDistinctMesi.length ; i++){
                    arrayMesi.push(utils.array.select(utils.arrayMesi,"ID_MESE",arrayDistinctMesi[i].MESE,1)[0].DESCRIZIONE);
                }
                for (var i = 0 ; i < arrayDistinctAnni.length ; i++){
                    var serie = {
                        name: arrayDistinctAnni[i].ANNO.toString(),
                        type: 'bar',
                        barGap: 0.2,
                        data: []
                    };
                    for (var j = 0 ; j < arrayDistinctMesi.length ; j++){
                        var trovato = 0;
                        for (var k = 0 ; k < response.data.length ; k++ ){
                            if (response.data[k].MESE == arrayDistinctMesi[j].MESE && response.data[k].ANNO == arrayDistinctAnni[i].ANNO){
                            trovato = 1;
                            serie.data.push(parseFloat(response.data[k].IMPORTO));
                            break;
                            }
                        }
                        if (trovato == 0){
                            serie.data.push(0);
                        }
                    }
                    series.push(serie);
                }
                option = {
                    tooltip: {
                        trigger: 'item',
                        formatter : (params) => {
                            return "<strong>" + params.name + " " +  params.seriesName + "</strong><br/>€ "+ utils.formatNumber(params.data) ;
                        }
                    },
                    legend: {
                        data: arrayAnni
                    },
                    toolbox: {
                        show: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        feature: {
                            mark: {show: true},
                            magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
                            restore: {show: true},
                            saveAsImage: {show: true}
                        }
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisTick: {show: false},
                            data: arrayMesi
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel : {
                            formatter : (param) => {
                                return utils.formatNumberInt(param);//"<strong>" + params.name + " " +  params.seriesName + "</strong><br/>"+ params.data ;
                            }
                            },
                        }
                    ],
                    series: series
                };    
                chart1.setOption(option);
                         
            });
        },

		statisticheStartup : function(){
			utils.ajax('travel/statistiche/agenzie/startup',{}, (response) => {
				this.statsOrdini = response.data;
				this.getStatisticheOrdiniChart();
			});
		},

    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayOrdini,this.search);            
            return arr;
        },
        
        getTotali(){
            var filtrato = global.utils.filter(this.arrayOrdini,this.search);
            var lordo = 0;
            var comm = 0;
            for (var i = 0 ; i < filtrato.length ; i++){
                lordo += parseFloat(filtrato[i].PREZZO_TOTALE);
                comm += parseFloat(filtrato[i].COMMISSIONI);
            }
            return {
                LORDO:lordo,
                COMM:comm,
                NETTO:lordo-comm,
                COUNT:filtrato.length
            }
        }
    }, 

    created : function(){
        this.arrayPromotori = this.$root.arrayPromotori;
        this.arrayAgenti = this.$root.arrayAgenti;
		this.filters.wait(300,()=>{
			var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
			var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
			return new bootstrap.Tooltip(tooltipTriggerEl)
			})
		});
        this.getOrdini();
    }
}
</script>


<style scoped>
	.grafico { min-height: 451px; }
</style>