<template>
        
    <!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link> 
                    <a href="#" class="d-none d-lg-inline-block text-dark fw-semibold cursor-pointer" v-on:click="getIncassi()"> Contabilità </a> <span class="d-none d-lg-inline-block">\</span>
                    <span class="text-muted ms-1"><Translate :path="'dizionario.base.GESTIONE'"></Translate> Incassi</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;">
						
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(10)" title="Opzioni Contabilità" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(10)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(10)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
	        </div>
		</div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#incassi-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-clippy"></i>
								<span class="d-lg-none ms-1">Incassi</span>
								<span class="d-none d-lg-inline-block ms-1">Gestione Incassi</span>
							</div>
						</a>
					</li>
					<li class="nav-item d-lg-none ms-auto">
						<a href="#profile_nav" class="navbar-nav-link navbar-nav-link-icon rounded-pill rounded-pill-small collapsed" data-bs-toggle="collapse" aria-expanded="false">
							<i class="ph-caret-down collapsible-indicator"></i>
						</a>
					</li>
				</ul>

			    <Datepicker autocomplete="off" class="ms-lg-auto bg-light text-center mt-xs-1" style="width:320px;" v-model="intervalloDate" range multi-calendars multi-calendars-solo :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" :partial-range="false" placeholder="Data Ordine da > a" :tabindex="2" />
				<span class="btn btn-primary btn-sm ms-1" v-on:click="getIncassi()">AGGIORNA</span>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0 select-small">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
									<i class="ph-gear me-1"></i> Azioni Multiple
									<span class="d-lg-none ms-2">More</span>
								</a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<a class="dropdown-item cursor-pointer" v-on:click="esportaElenco(2)"><i class="far fa-excel fa-fw me-2"></i><span class="text-muted me-1">|</span>Formato Excel</a>
								</div>
							</div>
						</li>
						<li class="nav-item">
	
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
	<!-- /page header -->

    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="incassi-a">
        		<div class="card shadow mb-xs-3 mb-lg-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="incasso-a">
		                            <div class="row ">
										<div class="col-4 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="filtra x Ordine" :tabindex="2" v-model="search.PNR.VALUE"  />
										</div>
										<div class="col-12 col-md-2 col-lg-2 mbx-1">
											<select class="form-select form-select-sm" placeholder="filtra x Tipologia" :tabindex="20" v-model="search.TIPO.VALUE">
												<option value="">filtra x tipologia</option>
												<option value="1">Agenzia</option>
												<option value="2">Cliente</option>
											</select>
										</div>
	                                    <div class="col-8 col-md-2 col-lg-2 mbx-1">
	                                        <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="filtra x Cliente" :tabindex="2" v-model="search.ALIAS.VALUE"  />
	                                    </div> 
										<div class="col-12 col-md-2 col-lg-2 mbx-1">
											<select class="form-select form-select-sm" placeholder="filtra x stato incasso" :tabindex="20" v-model="search.STATO_INCASSO_CLIENTE.VALUE">
												<option value="">filtra x stato</option>
												<option value="1">Regolare</option>
												<option value="2">Parzialmente irregolare</option>
												<option value="3">Totalmente irregolare</option>
											</select>
										</div>	
										<div class="col-12 col-md-4 col-lg-4 mbx-1">
										</div>
									</div>
								</form>
							</div>	
						</div>
					</div>

					<div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="div_table_resp box-33" id="tableFixHead">
							<table class="table table-bordered table-togglable text-nowrap"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;">
											<i class="fal fa-minus-square fa-fw text-light cursor-pointer"></i><br />
											<i class="fal fa-plus-square fa-fw text-light cursor-pointer"></i>
										</th>
										<th class="table-head-sm">Tipo / Intestatario</th>
										<th class="table-head-sm" style="width:14%;">Importi</th>
										<th class="table-head-sm" style="width:18%;">Stato Incasso</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td colspan="4" class="fs-sm" style="background-color: white;">
											<div class="accordion" id="accordion_collapsed">

												<div class="accordion-item" v-for="intestatario in paginatedListRaggr.arr" v-bind:key="intestatario.INDEX">
													<h2 class="accordion-header bg-light" style="height: 2.75rem !important;">
														<div class="row">	
															<div class="col-md-8" style="line-height: 2.1rem;">	
																<button class="accordion-button fw-semibold" style="display: contents;" type="button" data-bs-toggle="collapse" :data-bs-target="'#accordion_intestatario'+intestatario.INDEX" aria-expanded="true">
																	<span v-if="intestatario.TIPO == 1" class="badge badge-xs bg-danger ms-2">A</span> 
																	<span v-else class="badge badge-xs bg-primary ms-2">C</span> - 
																	<span>{{ intestatario.ALIAS }}</span>
																</button>
															</div>	
															<div class="col-md-2">
																<span class="btn fs-lg p-1 m-1 me-3 float-end" v-bind:class="getClasseBoxImportoResiduo(intestatario)" v-on:click="openIncassoMultiplo(intestatario,{ID_INCASSO:0,IMPORTO:intestatario.IMPORTO_TOTALE_RESIDUO,STATO_INCASSO:1})" >
																	Tot. residuo &euro; {{ filters.formattaImporto(intestatario.IMPORTO_TOTALE_RESIDUO_DA_CONFERMARE) }}
																</span>
															</div>	
															<div class="col-md-2"></div>
														</div>
													</h2>
													<div :id="'accordion_intestatario'+intestatario.INDEX" class="accordion-collapse collapse show" style="">
														<div class="accordion-body pt-0">
															<table class="table table-bordered table-togglable text-nowrap m-1 ms-3">
																<template v-for="ordine in filtraOrdini(intestatario.ARRAY_ORDINI)" v-bind:key="ordine.ID_ORDINE">
																	<tr>
																		<td class="td-class" style="width: 8%;">
																			<span class="text-secondary fw-bold cursor-pointer" v-on:click="openOffCanvas();openSchedaOrdine(ordine)">{{ filters.getPnr(ordine) }}</span>
																		</td>
																		<td class="td-class" style="width: 42%;">
																			<span class="badge bg-secondary text-secondary bg-opacity-20 badge-xs text-body">{{ filters.getLabelOpzione(ordine.FK_ID_STATO) }}</span> - 
																			<span class="text-secondary fw-bold">{{ ordine.TITOLO_PRATICA }}</span>
																		</td>
																		<td class="td-class text-secondary fw-bold" style="width: 10%;">Dal {{ filters.formatDate(ordine.DATA_INIZIO_SOGGIORNO) }} Al {{ filters.formatDate(ordine.DATA_FINE_SOGGIORNO) }}</td>
																		<td class="td-class text-right" style="width: 20%; text-align: right;"><span class="text-secondary fw-bold me-2">&euro; {{ filters.formattaImporto(ordine.PREZZO_TOTALE) }}</span></td>
																		<td class="td-class" style="width: 10%;"></td>
																		<td class="td-class" style="width: 10%;"> </td>
																	</tr>
																	<tr v-for="incasso in ordine.ARRAY_INCASSI" v-bind:key="incasso.ID_INCASSO">
																		<td class="td-class"></td>
																		<td class="td-class text-dark">
																			<span class="text-dark me-3">{{ filters.formatDateTime(incasso.DATA_INCASSO) }}</span>
																			{{ incasso.NOTE }}
																		</td>
																		<td class="td-class"></td>
																		<td class="td-class" style="text-align: right;"><span class="text-dark me-2">&euro; {{ filters.formattaImporto(incasso.IMPORTO) }}</span></td>
																		<td class="td-class"><span class="badge bg-opacity-20 " v-on:click="openIncasso(incasso)" v-bind:class="getClasseIncasso(incasso)">{{ getStatoIncasso(incasso) }}</span></td>
																		<td class="td-class">
																			<button v-on:click="openPopUpMovimento(incasso.FK_ID_MOVIMENTO)" class="btn btn-success btn-outline" v-if="incasso.FK_ID_MOVIMENTO > 0">Movimento prima nota</button>	
																		</td>
																	</tr>
																</template>
															</table>
														</div>
													</div>
												</div>

											</div>				
										</td>
									</tr>								
								</tbody>
							</table>
						</div> 

						<div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
								<div style="position: relative; top: 10px;">
									<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">PRATICHE: {{ paginatedListRaggr.count }}</span> 
								</div>
							</div>
							<div class="col-9 col-md-6" :tabindex="27">
								<div class="float-end pag-mobile">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListRaggr.count" :numpagine="search.PAGINATION.LENGTH"></Pagine>
								</div>
							</div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>

					</div>
				</div>
			</div>

			
        </div>
	</div>


	<!-- START MODALI -->
    <div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right_ordini" role="dialog" data-bs-backdrop="false" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
			<h5 class="offcanvas-title fw-semibold">{{ ordineSelezionato.TITOLO_PRATICA }}</h5>
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">

			<SchedaOrdine :ref="'schedaOrdine'" @selezionaProdotto="openSchedaProdotto($event)" ></SchedaOrdine>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <div style="position: relative; top: 2px;">
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="LORDO"><strong class=" me-1">L:</strong> € {{ filters.formattaImporto(ordineSelezionato.PREZZO_TOTALE) }}</span>
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="COMMISSIONE"><strong class=" me-1">A:</strong> € {{ filters.formattaImporto(ordineSelezionato.COMMISSIONI) }}</span>
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="NETTO"><strong class=" me-1">N:</strong> € {{ filters.formattaImporto(ordineSelezionato.PREZZO_TOTALE - ordineSelezionato.COMMISSIONI) }}</span>
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="COSTO"><strong class=" me-1">C:</strong> € {{ filters.formattaImporto(ordineSelezionato.COSTO_TOTALE) }}</span>
                <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>
            </div>       
        </div>  
	</div>  

	<div class="modal fade" id="popUpIncasso" role="dialog"  tabindex="-1" >
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> Scheda Incasso </span>
                    </h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">

					<div class="row">
						<div class="col-md-12 mb-2" v-show="intestatarioSelezionato.ARRAY_INCASSI != undefined && intestatarioSelezionato.ARRAY_INCASSI.length > 0">
							<label class="form-label">Seleziona gli ordini incassati</label>
							<div class="border p-3 rounded">
								<div class="d-flex align-items-center" v-for="incasso in intestatarioSelezionato.ARRAY_INCASSI" v-bind:key="incasso">
									<input type="checkbox" v-model="incasso.SELEZIONATO" v-bind:true-value="1" v-bind:false-value="0" >
									<label class="ms-2" for="dc_ls_c">€{{ filters.formattaImporto(incasso.IMPORTO) }} | Pratica {{ incasso.ORDINE.PNR }}</label>
								</div>
							</div>
						</div>
						<div class="col-md-3 mb-2">
							<label class="form-label">Data Incasso:</label>
							<Datepicker :disabled="incassoSelezionato.FK_ID_MOVIMENTO > 0" v-model="incassoSelezionato.DATA_INCASSO" model-type="dd/MM/yyyy HH:mm" :format="'dd/MM/yyyy HH:mm'" auto-apply></Datepicker>                       
						</div>
						<div class="col-md-3 mb-2"> 
							<label class="form-label">Importo:</label>
							<div class="input-group input-group-sm">
								<span class="input-group-text"><i class="far fa-euro-sign"></i></span>
								<input v-if="!(intestatarioSelezionato.ARRAY_INCASSI != undefined && intestatarioSelezionato.ARRAY_INCASSI.length > 0)" class="form-control form-control-sm" type="text" v-model="incassoSelezionato.IMPORTO" data-placeholder="Importo">
								<input v-else class="form-control form-control-sm" type="text" :disabled="true" v-bind:value="filters.formattaImporto(totaleResiduoIntestatarioSelezionato)" data-placeholder="Importo">
							</div>
						</div>
						<div class="col-md-3 mb-2">
							<label class="form-label">Movimento:</label>
							<div class="input-group input-group-sm">
								<span class="input-group-text"><i class="far fa-tag fa-fw"></i></span>
								<select class="form-select form-select-sm" v-model="incassoSelezionato.FK_ID_SUB_MODALITA_INCASSO" placeholder="Seleziona Movimento">
									<option value="0">Seleziona Movimento</option>
									<option v-for="opzione in getModalitaIncassoPrincipali()" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA">{{ opzione.DESCRIZIONE }}</option>
								</select>
							</div>
						</div>
						<div class="col-md-3 mb-2">
							<label class="form-label">Pagamento:</label>
							<div class="input-group input-group-sm">
								<span class="input-group-text"><i class="far fa-university fa-fw"></i></span>
								<select class="form-select form-select-sm" :disabled="!incassoSelezionato.FK_ID_SUB_MODALITA_INCASSO > 0" v-model="incassoSelezionato.FK_ID_CONTO" data-placeholder="Tipo">
									<option value="0">...attesa movimento</option>
									<option v-for="conto in getSubModalitaIncasso(incassoSelezionato)" v-bind:key="conto.ID_OPZIONE_TIPOLOGICA" v-bind:value="conto.ID_OPZIONE_TIPOLOGICA">{{ conto.DESCRIZIONE }}</option>
								</select>
							</div>
						</div>
						<div class="col-md-9 mb-2"> 
							<label class="form-label">Note Incasso (o causale):</label>
							<textarea class="form-control" aria-multiline="true" v-model="incassoSelezionato.NOTE"  placeholder="Inserisci una nota"></textarea>                   
						</div> 
						<div class="col-md-3 mb-2">
							<label class="form-label">Stato Incasso:</label>
							<div class="input-group input-group-sm">
								<span class="input-group-text"><i class="far fa-university fa-fw"></i></span>
								<select class="form-select form-select-sm" :disabled="(intestatarioSelezionato.ARRAY_INCASSI != undefined && intestatarioSelezionato.ARRAY_INCASSI.length > 0)" v-model="incassoSelezionato.STATO_INCASSO" data-placeholder="Stato Incasso">
									<option value="0">Da confermare</option>
									<option value="1">Incassato</option>
								</select>
							</div>
						</div>
					</div>	
                            
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaIncasso()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div> 
            </div>
        </div>
    </div>

	<div class="modal fade" id="popUpMovimento" role="dialog"  tabindex="-1" >
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> Scheda Movimento </span>
                    </h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">

					<div class="row">
						<div class="col-md-12 mb-2">
							<label class="form-label">Incassati</label>
							<div class="border p-3 rounded">
								<div class="d-flex align-items-center" v-for="incasso in movimentoSelezionato.incassi" v-bind:key="incasso">
									<label class="ms-2" for="dc_ls_c">€{{ filters.formattaImporto(incasso.IMPORTO) }} | Pratica {{ incasso.PNR }}</label>
								</div>
							</div>
						</div>
						<div class="col-md-4 mb-2">
							<label class="form-label">Data Incasso:</label>
							<input class="form-control form-control-sm" type="text" :disabled="true" v-model="movimentoSelezionato.DATA_MOVIMENTO" placeholder="Data Incasso">
						</div>
						<div class="col-md-4 mb-2"> 
							<label class="form-label">Importo:</label>
							<div class="input-group input-group-sm">
								<span class="input-group-text"><i class="far fa-euro-sign"></i></span>
								<input class="form-control form-control-sm" type="text" :disabled="true" v-bind:value="filters.formattaImporto(movimentoSelezionato.IMPORTO)" data-placeholder="Importo">
							</div>
						</div>
						<div class="col-md-4 mb-2">
							<label class="form-label">Conto:</label>
							<div class="input-group input-group-sm">
								<span class="input-group-text"><i class="far fa-university fa-fw"></i></span>
								<input class="form-control form-control-sm" type="text" :disabled="true" v-bind:value="filters.getLabelOpzione(movimentoSelezionato.FK_ID_CONTO)" placeholder="Conto">
							</div>
						</div>
						<div class="col-md-12 mb-2"> 
							<label class="form-label">Note Incasso (o causale):</label>
							<textarea class="form-control" aria-multiline="true" v-model="movimentoSelezionato.DESCRIZIONE"  placeholder="Inserisci una nota"></textarea>                   
						</div> 
					</div>	
                            
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
					<button class="btn btn-danger ms-3 me-3" v-on:click="deleteMovimento()"><i class="fal fa-save fa-fw me-1"></i> Canella Movimento </button>                                   
                    <button class="btn btn-secondary float-end" v-on:click="salvaMovimento()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div> 
            </div>
        </div>
    </div>

    <div class="modal fade" id="popUpCatturaOrdine" role="dialog" >
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> IMPORTA DATI NEL GESTIONALE </span>
                    </h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">  

                    <p class="text-center" style="font-size: 14px;">
                        <span id="modal_status_subtitle">sei sicuro di voler importare questa Pratica nel tuo Gestionale?</span> <br> 
                        <span id="modal_status_accept">Proseguendo, la Pratica e le relative Quote, saranno importate nel tuo Gestionale.</span>
                    </p> 
                    <hr> 
                    <div class="row">
                        <div class="col-md-6">
                            <h6><strong>1.</strong> Seleziona Gestionale</h6> 
                            <div class="form-group">
                                <select name="order_status" class="form-control form-control-sm" disabled="disabled">
                                    <option value="easy">Cattura Easy</option>
                                </select>
                            </div>
                        </div> 
                        <div class="col-md-6">
                            <h6><strong>2.</strong> Scegli Operazione</h6>
                            <div class="form-group">
                                <select class="form-control form-control-sm" disabled="disabled">
                                    <option value="Set_Pratica">Importa pratica</option> 
                                    <option value="Set_Quota">Importa quota</option> 
                                    <option value="Set_Incassi">Importa incasso</option> 
                                    <option value="anagrafica">Importa anagrafica</option> 
                                    <option value="voucher">Importa voucher</option>
                                </select>
                            </div>
                        </div> 
                    </div>

                </div>
                <div class="modal-footer bg-light">
                    Vuoi Procedere&nbsp;&nbsp;&nbsp;
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">NO ESCI</button>&nbsp;
                    <button type="button" class="btn btn-success btn-sm">SI IMPORTA</button><!--  v-on:click="sendStatus()" -->
                </div>
            </div>
        </div>
    </div> 


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import Datepicker from '@vuepic/vue-datepicker'
import SchedaOrdine from '@/components/SchedaOrdine.vue'


export default {
    name:"Incassi",

    components:{
        Pagine,
        Datepicker,
		SchedaOrdine,
    },

    data:function(){
        return {
            apiStartup:'travel/incassi',
            filters:global.filters,
			rs : utils.generateRandomString(30),
			GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            ordineSelezionato:{},
            arrayIncassiRaggr:new Array(),
			arrayIncassi : new Array(),
            incassoSelezionato:{},
			periodoSelezionato:{},
			intestatarioSelezionato:{},
			movimentoSelezionato:{incassi:new Array()},
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				ALIAS:{TYPE:'TEXT',VALUE:''},
				TIPO:{TYPE:'COMBO',VALUE:''},
				PNR:{TYPE:'TEXT_IN_ARRAY',VALUE:'',CUSTOM_ARRAY:'ARRAY_ORDINI'},
				STATO_INCASSO_CLIENTE:{TYPE:'COMBO',VALUE:''},
	        },
			intervalloDate:{},
			dataStart:'',
			dataEnd:'',

        }
    },

    methods:{
		getIncassi : function(){
			global.utils.ajax('travel/incassiordini',{DATA_START:this.dataStart,DATA_END:this.dataEnd,FK_ID_MODULO:1}, (response,status) => {    
				this.arrayIncassiRaggr = response.data;
			});
		},  

		selectAllOrdini : function () {
			var selezionato = document.getElementById("chkAllOrdini").checked == true ? 1 : 0;
			for (var i = 0 ; i < this.arrayIncassiRaggr.length ; i++){
				this.arrayIncassiRaggr[i].SELEZIONATO = selezionato;
				for (var j = 0 ; j < this.arrayIncassiRaggr[i].ARRAY_ORDINI.length ; j++){
					this.arrayIncassiRaggr[i].ARRAY_ORDINI[j].SELEZIONATO = selezionato;
				}
			}
		},	   

        openIncassi : function(ordine){
            this.$refs.schedaIncasso.openIncasso(ordine);
        },

        nuovaQuota : function(id){
            this.$root.incassoSelezionato = utils.clone(this.incasso);
            global.router.push("Quota");
        },

		datePickerChangeHandler : function(){
            this.search.DATA_INCASSO = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
            if (this.dataCreazioneRange != null && this.dataCreazioneRange.length > 0){
                this.search.DATA_INCASSO = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRange[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRange[1])};
            }
        },

        getBtnStatusClass : function(incasso){
            switch (incasso.STATO_INCASSO){
                case 1:
                    return 'badge-success'
                case 2:
                    return 'badge-primary'
                case 3:
                    return 'badge-info'
				case 4:
                    return 'badge-warning'
                case 5:
                    return 'badge-yellow'
                case 6:                
                    return "badge-secondary";
                case 7:
                case 8:
                    return 'badge-danger'
                default :
                    return 'badge-light'
            }
        },

        getTipoBook : function(incasso){
            var arr = utils.array.select(this.$root.arrayPraticaBook,"ID_OPZIONE_TIPOLOGICA",incasso.FK_ID_TIPO,1);
            if (arr.length > 0){
                return arr[0].CUSTOM_1;
            }
            return "";
    	},

        getTipoBookClass : function(incasso){
            switch (incasso.FK_ID_TIPO){
                case 1:
                    return "badge-danger";
                case 2:
                    return "badge-info";                   
                case 3:
                    return "badge-warning";
                case 4:
                    return "badge-primary";                   
            }
            return "";
        },

        getSubModalitaIncasso : function(incasso){
            if (incasso.FK_ID_SUB_MODALITA_INCASSO > 0){
                var principale = utils.array.select(this.$root.arrayConti,"ID_OPZIONE_TIPOLOGICA",incasso.FK_ID_SUB_MODALITA_INCASSO,1)[0];
                if (principale == undefined){
                    return new Array();
                }
                var arr = utils.array.select(this.$root.arrayConti,"CUSTOM_2",principale.CUSTOM_2);
                if (incasso.FK_ID_CONTO == undefined){
                    incasso.FK_ID_CONTO = 0;//arr[0].ID_OPZIONE_TIPOLOGICA;
                }
                else{
                    var check = utils.array.select(arr,"ID_OPZIONE_TIPOLOGICA",incasso.FK_ID_CONTO,1);
                    if (check.length == 0){
                        incasso.FK_ID_CONTO = 0;//arr[0].ID_OPZIONE_TIPOLOGICA;
                    }
                }                
                return arr;
            }
            return new Array();
        },	   

        getModalitaIncassoPrincipali : function(){
            var arr = new Array();
            for (var i = 0 ; i< this.$root.arrayConti.length ; i++){
                if (this.$root.arrayConti[i].ID_OPZIONE_TIPOLOGICA == 46 || this.$root.arrayConti[i].ID_OPZIONE_TIPOLOGICA == 47 || this.$root.arrayConti[i].ID_OPZIONE_TIPOLOGICA == 48  || this.$root.arrayConti[i].ID_OPZIONE_TIPOLOGICA == 49 ){
                    arr.push(utils.clone(this.$root.arrayConti[i]));
                }
            }
            return arr;
        },
		

		getClasseIncasso : function(incasso){
			if (incasso.ID_INCASSO == 0){
				return "bg-danger text-danger";
			}
			else {
				if (incasso.STATO_INCASSO == 0){
					return "bg-warning text-warning";
				}
				return "bg-success text-success"; 
			}
        },

		getStatoIncasso : function(incasso){
			if (incasso.ID_INCASSO == 0){
				return "Residuo";
			}
			else {
				if (incasso.STATO_INCASSO == 0){
					return "Da confermare";
				}
				return "Incassato"; 
			}
		},
		
		getClasseBoxImportoResiduo : function(intestatario) {
			switch (intestatario.STATO_INCASSO_CLIENTE){
				case 1:
					return 'link-success border-success';
				case 2:
					return 'link-warning border-warning';
				case 3:
				default:
					return 'link-danger border-danger';
			}
			
		},

		openOffCanvas : function(){
            var myOffcanvas = document.getElementById('panel_right_ordini')
            var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
            bsOffcanvas.show();
        },

        openSchedaOrdine : function(incasso){
            this.$refs.schedaOrdine.openSchedaOrdine(incasso);
        },
 
		openIncasso : function(incasso){
			this.intestatarioSelezionato = {};
			this.incassoSelezionato = utils.clone(incasso);
			if (incasso.ID_INCASSO > 0){
				this.incassoSelezionato.DATA_INCASSO = this.filters.formatDateTimeDp(this.incassoSelezionato.DATA_INCASSO);
			}		
			else{
				this.incassoSelezionato.DATA_INCASSO = global.moment(new Date()).format('DD/MM/YYYY HH:mm')
			}				
			this.filters.openPopUp("popUpIncasso");
		},	

		openIncassoMultiplo : function(intestatario,incasso){
			if (intestatario.IMPORTO_TOTALE_RESIDUO > 0){
				this.incassoSelezionato = utils.clone(incasso);
				this.intestatarioSelezionato = utils.clone(intestatario);
				this.intestatarioSelezionato.ARRAY_INCASSI = new Array();
				this.incassoSelezionato.DATA_INCASSO = global.moment(new Date()).format('DD/MM/YYYY HH:mm');
				for (var i = 0 ; i < this.intestatarioSelezionato.ARRAY_ORDINI.length ; i++){
					for (var j = 0 ; j < this.intestatarioSelezionato.ARRAY_ORDINI[i].ARRAY_INCASSI.length ; j++){
						if (this.intestatarioSelezionato.ARRAY_ORDINI[i].ARRAY_INCASSI[j].ID_INCASSO == 0){
							var inc = utils.clone(this.intestatarioSelezionato.ARRAY_ORDINI[i].ARRAY_INCASSI[j]);
							inc.ORDINE = utils.clone(this.intestatarioSelezionato.ARRAY_ORDINI[i]);
							inc.SELEZIONATO = 1;
							this.intestatarioSelezionato.ARRAY_INCASSI.push(inc);
						}
					}
				}
				this.filters.openPopUp("popUpIncasso");
			}
			else{
				utils.alert.info("Tutti gli incassi sono stati registrati per questo cliente");
			}

		},

		salvaIncasso : function(){
			if (this.incassoSelezionato.ID_INCASSO == 0){
				if (this.intestatarioSelezionato.hasOwnProperty("ARRAY_INCASSI") && this.intestatarioSelezionato.ARRAY_INCASSI.length > 0){
					this.incassoSelezionato.ARRAY_INCASSI = this.intestatarioSelezionato.ARRAY_INCASSI;
				}
				utils.ajax("travel/incasso/insert",this.incassoSelezionato, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getIncassi();
						this.filters.closePopUp("popUpIncasso");									
					}
				});	
			}
			else{
				utils.ajax("travel/incasso/update",this.incassoSelezionato, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getIncassi();
						this.filters.closePopUp("popUpIncasso");									
					}
				});				
			}
		},

		filtraOrdini : function(arrayOrdini){
			var arr = new Array();
			if (this.search.PNR.VALUE != ''){
				for (var i = 0 ; i < arrayOrdini.length ; i++){
					const esito = arrayOrdini[i].PNR.toString().toLowerCase().includes(this.search.PNR.VALUE.toLowerCase());
					if (esito == true){
						arr.push(arrayOrdini[i]);
					}
				}
				return arr;
			}
			else{
				return arrayOrdini;
			}		
		},

		esportaElenco : function(formato){
			var arr = utils.filter(this.arrayIncassiRaggr,this.search);
			var arrayFinale = new Array();
			for (var i = 0 ; i < arr.length ; i++){
				if (arr[i].TIPO == 1 ){
					const item = {ID_CLIENTE:arr[i].ID_AZIENDA, TIPO : 1};
					arrayFinale.push(item);
				}
				if (arr[i].TIPO == 2 ){
					const item = {ID_CLIENTE:arr[i].ID_PERSONA_FISICA, TIPO : 2};
					arrayFinale.push(item);
				}
			}
			global.utils.ajax('core/report/basic/token',{modalita:'MOONLIGHT_esportaIncassi',clienti:arrayFinale,params:{DATA_START:this.dataStart,DATA_END:this.dataEnd}}, (response) => {
				if (response.esito == 0){
					window.open(utils.BASE_API + "core/report?ID_REPORT=1003&token="+response.messaggio+"&formato="+formato + "&SESSION_TOKEN="+global.utils.SESSION_TOKEN);
				}
			});
		},

		openPopUpMovimento : function(idMovimento){
			this.filters.openPopUp("popUpMovimento");
			utils.ajax("travel/movimento",{ID_MOVIMENTO:idMovimento}, (response) => {
				this.movimentoSelezionato = utils.clone(response.data);
			});
		},

		deleteMovimento : function(){
			utils.alert.confirm("Sei sicuro di voler eliminare questo movimento?",()=>{
				utils.ajax("travel/movimento/delete",{ID_MOVIMENTO:this.movimentoSelezionato.ID_MOVIMENTO}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getIncassi();
						this.filters.closePopUp("popUpMovimento");
					}
				});
			},this.$root);
		},

		salvaMovimento : function(){
			utils.ajax("travel/movimento/update",this.movimentoSelezionato, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getIncassi();
						this.filters.closePopUp("popUpMovimento");
					}
				});
		}
    },

    computed: {
		paginatedListRaggr(){
			var arr = utils.inGrid(this.arrayIncassiRaggr,this.search);
	        return arr;
		},
        paginatedList(){
			var arr = utils.inGrid(this.arrayIncassi,this.search);
	        return arr;

            if (this.search.STATO_INCASSO.VALUE == 0){
                var arr = utils.clone(this.arrayIncassi);
                for (var i = arr.length -1 ; i > -1 ; --i){
                    if (arr[i].STATO_INCASSO > 5){
                        arr.splice(i,1);
                    }
                }
                return utils.inGrid(arr,this.search);
            }
            else{
	            var arr = utils.inGrid(this.arrayIncassi,this.search);
	            return arr;
            }            
        },
		totaleResiduoIntestatarioSelezionato(){
			var importo = 0;
			if( this.intestatarioSelezionato.ARRAY_INCASSI == undefined){
				return 0;
			}
			for (var i = 0 ; i < this.intestatarioSelezionato.ARRAY_INCASSI.length ; i++){
				if (this.intestatarioSelezionato.ARRAY_INCASSI[i].SELEZIONATO == 1){
					importo += parseFloat(this.intestatarioSelezionato.ARRAY_INCASSI[i].IMPORTO);
				}
			}
			return importo;
		},

        getTotali(){
            var filtrato = global.utils.filter(this.arrayIncassi,this.search);
            var lordo = 0;
            var comm = 0;
            for (var i = 0 ; i < filtrato.length ; i++){
                lordo += parseFloat(filtrato[i].PREZZO_TOTALE);
                comm += parseFloat(filtrato[i].COMMISSIONI);
            }
            return {
                LORDO:lordo,
                COMM:comm,
                NETTO:lordo-comm,
                COUNT:filtrato.length
            }
        },

    }, 

	watch : {
		intervalloDate : function(valore,valoreOld){
            if (this.intervalloDate != null && this.intervalloDate != undefined){ 
                this.dataStart = this.filters.formatDate(this.intervalloDate[0]);
                this.dataEnd = this.filters.formatDate(this.intervalloDate[1]);
            }
        },
	},

    created : function(){
        
    },
	
}
</script>		   
