<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
					<a href="#" class="d-none d-lg-inline-block text-dark fw-semibold cursor-pointer" v-on:click="getTypes()"> Template </a> <span class="d-none d-lg-inline-block">\</span>
					<span class="text-muted ms-1">Gestione Menu</span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
	
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;">
						
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(35)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue()" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help()" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
			</div>
        </div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#menus-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>
								<Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Posizioni
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#menus-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-vignette me-2"></i>
								<Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Menu
							</div>
						</a>
					</li>
				</ul>

				

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Menu selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti i Menu</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina selezionati</a>
								</div>
							</div>
						</li>	   
						<li class="nav-item">					
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->

	<!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="menus-a">
				<div class="card shadow mb-xs-3 mb-lg-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="menu-a">
									<div class="row ">
										<div class="col-5 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Posizione" v-model="search.ID_TYPE.VALUE" :tabindex="1" />
										</div>
				                  		<div class="col-5 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Descrizione" v-model="search.DESCRIZIONE.VALUE" :tabindex="1" />
										</div>  	
			                            <div class="col-7 col-md-2 col-lg-2 mbx-1">
		                                    
			                            </div>               
			                            <div class="col-12 col-md-4 col-lg-4 mbx-1">
		
										</div>
										<div class="col-12 col-md-1 col-lg-1">
											<select class="form-select form-select-sm" data-placeholder="Stato" v-model="search.FLG_ATTIVO.VALUE" :tabindex="13">
												<option value="">Tutte </option>   
												<option value="1"> &nbsp; Attivate</option>
												<option value="0"> &nbsp; Disattivate</option>
											</select>
										</div>
										<div class="col-6 col-md-1 col-lg-2">
			                                <a href="#" class="btn btn-flat-info btn-sm w-100 collapsed" role="button" aria-expanded="false" v-on:click="newType()">
			                                    AGGIUNGI POSIZIONE
											</a>
										</div>
									</div>
								</form>
							</div>
						</div> 
			        </div>
						
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="div_table_resp tableFixHead" id="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable text-nowrap"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllTypes" v-on:click="filters.selectAll('chkAllTypes',search.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:6%;"> # </th>
										<th class="table-head-sm">Nome Posizione</th>
										<th class="table-head-sm" style="width:15%;">Sito /<br />Posizione</th>
										<th class="table-head-sm text-center" style="width:5%;">Visibile</th>
										<th class="table-head-sm text-center" style="width:5%;">Stato</th>
										<th class="table-head-sm text-center" style="width:5%;">Azioni</th>
									</tr>
								</thead>
		                        <tbody>
									<tr v-for="place in paginatedList.arr" v-bind:key="place.ID_TYPE">
		                                <td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="place.SELEZIONATO" class=""> 
										</td>
										<td class="td-class fs-sm">
											<span>{{ place.ID_TYPE}}</span><br /> 
											<small class="badge bg-light badge-xs text-muted float-end cursor-pointer">&nbsp;</small>
										</td>
										<td class="td-class fs-sm">
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="editType(place)">{{place.DESCRIZIONE}}</span><br />	                                   
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;" data-bs-toggle="tooltip" data-bs-placement="top" title="Ente"><i class="fal fa-tag fa-fw"></i> </span> 
										</td>
										<td class="td-class fs-sm">
											<div><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> <a href="#" style="position: relative; top: 1.5px;">{{filters.getLabel($root.arrayEnti,'ID_ENTE',place.FK_ID_ENTE,'LABEL')}}</a></div>
											<div>&nbsp;</div>
										</td>
										<td class="td-class fs-sm text-center">
											<span v-on:click="updateTypeFlgVisibile(place)" v-show="place.FLG_VISIBILE == 1" class="badge bg-indigo badge-xs text-white cursor-pointer">Pubblico</span>
											<span v-on:click="updateTypeFlgVisibile(place)" v-show="place.FLG_VISIBILE == 0" class="badge bg-pink badge-xs text-white cursor-pointer">Admin</span>
										</td>		                                
										<td class="td-class text-center">
											<ISwitch @toggle="updateStatoType(place)" :size="'sm'" v-model="place.FLG_ATTIVO" style="z-index: 0;"></ISwitch>
										</td>
										<td class="td-class text-center">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="editType(place)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Menu</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteType(place)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Menu</a>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI: </span>
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">POSIZIONI: {{ paginatedList.count }}</span> 
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
								<div class="float-end pag-mobile">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH"></Pagine>
								</div>
							</div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="tab-pane fade" id="menus-b">
                <div class="card shadow mb-0 mb-xs-1">
                    <div class="card-body mt-0 mb-0">
                        <div class="tab-content">

                            <div class="tab-pane active" role="tabpanel" style="padding: 0;"> 
								<div class="d-lg-none p-1">
									<div class="d-grid gap-2">
										<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
											VEDI FILTRI
										</a>
									</div>
								</div>
								<div class="collapse show" id="collapse-all" style=""> 

									<div class="alert alert-2 alert-secondary border-0">
										<form autocomplete="off" action="#" id="menu-b">
											<div class="row ">
												<div class="col-5 col-md-1 col-lg-1 mbx-1">
													<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Menu" v-model="searchMenu.ID_MENU.VALUE" :tabindex="1" />
												</div>
						                  		<div class="col-5 col-md-2 col-lg-2 mbx-1">
													<select class="form-select form-select-sm" data-placeholder="Stato" v-model="searchMenu.FK_ID_TYPE.VALUE" :tabindex="13">
														<option value="">Seleziona Posizione </option>   
														<option v-for="place in arrayTypes" v-bind:key="place.ID_TYPE" v-bind:value="place.ID_TYPE"> &nbsp;{{ place.DESCRIZIONE }} </option>
													</select>
												</div>  	
					                            <div class="col-7 col-md-2 col-lg-2 mbx-1">
													<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Descrizione" v-model="searchMenu.DESCRIZIONE.VALUE" :tabindex="1" />
					                            </div>               
					                            <div class="col-12 col-md-4 col-lg-4 mbx-1">
		
		
		
		
		
		
		
				
												</div>
												<div class="col-12 col-md-1 col-lg-1">
													<select class="form-select form-select-sm" data-placeholder="Stato" v-model="searchMenu.FLG_ATTIVO.VALUE" :tabindex="13">
														<option value="">Tutti </option>   
														<option value="1"> &nbsp; Attivati</option>
														<option value="0"> &nbsp; Disattivati</option>
													</select>
												</div>
												<div class="col-6 col-md-1 col-lg-2">
					                                <a href="#" class="btn btn-flat-info btn-sm w-100 collapsed" role="button" aria-expanded="false" v-on:click="newMenu()">
					                                    AGGIUNGI VOCE MENU
													</a>
												</div>
											</div>
										</form>
									</div>
								</div> 
						
                                <div class="box-card mt-2" id="box-card">

									<Tree 
										:array-types="arrayTypes" 
										:array-items-filter="arrayItemsFilter" 
										:array-items="paginatedListMenu.arr" 
										:propsMap="{id:'ID_MENU',parentId:'ID_MENU_PADRE',label:'DESCRIZIONE',flg:'FLG_ATTIVO',children:'children' }" 
										@update-success="getMenuAlls" 
										@salva-menu="getMenuAlls">
									</Tree>

                                </div>
		
							</div>
						</div>
					</div>
				</div>
			</div> 
		</div>
	</div>

		
	<!-- START MODALI -->
	<div class="modal fade" id="popUpType" role="dialog" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
					<h5 class="modal-title">Menu</h5>
					<button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
				</div>
				<div class="modal-body">

					<div class="row">
						<div class="col-12 mb-2">
							<label class="text-danger custom-control custom-control-right">Descrizione</label>
							<input type="text" class="form-control" placeholder="Descrizione" v-model="typeSelezionata.DESCRIZIONE">
						</div>
						<div class="col-12 mb-2">
							<label class="text-danger custom-control custom-control-right">Sito Web</label>
							<select class="form-select" placeholder="" v-model="typeSelezionata.FK_ID_ENTE">
								<option v-for="ente in $root.arrayEnti" v-bind:value="ente.ID_ENTE" v-bind:key="ente.ID_ENTE">
									{{ ente.LABEL }}
								</option>
							</select>
						</div>
					</div>

				</div>
				<div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
					<button type="button" class="btn btn-secondary float-end" v-on:click="saveType()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>
				</div>
			</div>
		</div>
	</div>


    <div class="modal fade" id="popUpMenuRoot" role="dialog" tabindex="-1">
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
					<h5 class="modal-title">Elementi Menu: {{menuSelezionato.DESCRIZIONE}}</h5>
					<button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
				</div>
				<div class="modal-body">

					<div class="row">
						<div class="col-md-6">
							<label class="text-danger custom-control custom-control-right">Posizione</label>
							<select class="form-select" v-model="menuSelezionato.FK_ID_TYPE" :tabindex="20">
								<option value="0">Seleziona Posizione</option>
								<option v-for="position in this.arrayTypes" v-bind:key="position.ID_TYPE" v-bind:value="position.ID_TYPE" v-show="position.FLG_ATTIVO == 1">&nbsp;&nbsp;&nbsp;{{position.DESCRIZIONE}}</option>
							</select>
						</div>
						<div class="col-md-6">
                            <label class="text-danger custom-control custom-control-right">Padre</label>
							<select class="form-select" v-model="menuSelezionato.ID_MENU_PADRE" :tabindex="20">
								<option value="0">Seleziona Padre</option>
								<option v-for="padre in this.arrayItemsFilter" v-bind:key="padre.ID_MENU" v-bind:value="padre.ID_MENU" v-show="padre.FLG_ATTIVO == 1">&nbsp;&nbsp;&nbsp;{{padre.DESCRIZIONE}}</option>
							</select>                          
                        </div>
					</div>
					
					<hr />

					<div class="row">
						<div class="col-md-3 mb-2">
							<label class="text-danger custom-control custom-control-right">Titolo</label>
							<select class="form-select" v-model="menuSelezionato.FLG_TIPOLOGIA">
								<option value="1">Voce Menu</option>
								<option value="0">Linea divisoria</option>
							</select> 							
						</div>
						<div class="col-md-9 mb-2"></div>

						<div class="col-md-6 mb-2">
							<label class="text-danger custom-control custom-control-right">Titolo</label>
							<input type="text" v-model="menuSelezionato.DESCRIZIONE" class="form-control" placeholder="Descrizione">
						</div>
						<div class="col-md-6 mb-2">
							<label class="text-danger custom-control custom-control-right">Link Web</label>
							<input type="text" v-model="menuSelezionato.ROUTE" class="form-control" placeholder="Link Web">
						</div>

						<div class="col-md-3 mb-2">
							<label class="text-danger custom-control custom-control-right">Colore</label>
							<input type="text" v-model="menuSelezionato.COLORE" class="form-control" placeholder="Colore">
						</div>
						<div class="col-md-3 mb-2">

						</div>
						<div class="col-md-6 mb-2">
							<label class="custom-control custom-control-right">Icona</label>
							<input type="text" v-model="menuSelezionato.ICONA" class="form-control" placeholder="Icona">
						</div>

						<div class="col-md-3 mb-2">

						</div>
						<div class="col-md-3 mb-2">
							
						</div>
						<div class="col-md-3 mb-2">
							<label class="text-danger custom-control custom-control-right">Ordine</label>
							<input type="number" v-model="menuSelezionato.ORDINE" class="form-control" placeholder="Ordine">
						</div>
						<div class="col-md-3 mb-2">
							<label class="text-danger custom-control custom-control-right">Stato</label>
							<select class="form-select" v-model="menuSelezionato.FLG_ATTIVO" :tabindex="21">
								<option value="1">&nbsp;&nbsp;&nbsp;Attivo</option>
								<option value="0">&nbsp;&nbsp;&nbsp;Disattivo</option>
							</select>
						</div>
					</div>

				</div>
				<div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
					<button class="btn btn-secondary float-end" v-on:click="saveMenu()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>
				</div>
			</div>
		</div>
	</div>

</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import Tree from '@/components/Tree.vue';


export default {
    name:"ConfMenus",	

    components:{
        Pagine,
        ISwitch,
		Tree,

    },

    data: function () {
        return {   
            apiStartup:'core/menu',
            filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
			rs : utils.generateRandomString(30),
			arrayTypes: new Array(),
			typeSelezionata:{},
			arrayMenus: new Array(),
			arrayItemsFilter: new Array(),
            menuSelezionato:{FLG_TIPOLOGIA : 1},
			newMenuRoot:0,
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	          	ID_TYPE:{TYPE:'TEXT',VALUE:''},
				DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
				FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'},

            },

			searchMenu:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	          	ID_MENU:{TYPE:'TEXT',VALUE:''},
				DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
				FK_ID_TYPE:{TYPE:'COMBO',VALUE: ''},
				FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'},

            },
			currentLang:0,

        }
		
    },

    methods: {
		getTypes : function() {
            utils.ajax(this.apiStartup,{}, (response) => {
                this.arrayTypes = response.data;
            });
        },

        newType: function(){
            this.typeSelezionata = utils.nuovoElemento(this.typeSelezionata,{ID_TYPE:0,DESCRIZIONE:""},"popUpType");
        },
		
        editType : function(nav){
            this.typeSelezionata = utils.modificaElemento(this.typeSelezionata,nav,"popUpType");
        },

        deleteType : function(nav){
            utils.alert.confirm("Sicuro di voler eliminare il Menu e tutte le sue voci?",() => {
                utils.ajax('core/basic/delete',{className:"MenuItem","id" : menu.ID_TYPE}, (response) => {
                    this.getTypes();
                });
            });
        },

        saveType : function(){
			if(this.menuSelezionato.ID_TYPE > 0){
				utils.ajax('core/menu/update',this.typeSelezionata, (response) => {
					if (response.esito == 0){
						this.filters.closePopUp("popUpType");
						utils.alert.success(response.messaggio);
						this.getTypes();
					}
				});
			}
			else {
				this.typeSelezionata.FLG_ATTIVO = 1;
				this.typeSelezionata.FLG_VISIBILE = 1;
				utils.ajax('core/menu/insert',this.typeSelezionata,(response)=>{
					if (response.esito == 0){
						this.filters.closePopUp("popUpType");
						utils.alert.success(response.messaggio);
						this.getTypes();
					}
				});
			}

			/*
			if(this.typeSelezionata.ID_TYPE > 0){
				
				utils.salvaElemento("core/menu/",this.typeSelezionata,"ID_TYPE","popUpType",(response) => {
					console.log(this.typeSelezionata);
					if (response.esito == 0){
						utils.alert.success(response.messaggio);

						/*
						this.$root.appoggio.socket.emit('moduleEvent', {
							FK_ID_MODULO: 48, // ID del modulo
							FK_ID_TIPOLOGIA: 6,
							FK_ID_OPERAZIONE: response.id_inserito, // ID dell'operazione (es. pagamento)
							FK_ID_UTENTE: this.$root.utente.ID_UTENTE, // Utente che ha fatto l'operazione
							USERNAME: this.$root.utente.USERNAME
						});
						*/
						/*
						this.getTypes();
						this.filters.closePopUp("popUpType");
					}
				});
			}
			else {
				//utils.alert.danger('Inserire il titolo del Menu');

			}
			*/
        },

        updateStatoType : function(type){
            setTimeout(() => {
                utils.ajax("core/menu/stato/update",type, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }  
                }); 
            },250);
        }, 

		updateTypeFlgVisibile : function(type){
			type.FLG_VISIBILE = type.FLG_VISIBILE == 1 ? 0 : 1;
			utils.ajax("core/menu/update",type, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					type.FLG_VISIBILE = type.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
		},


		//---------//
		getMenuAlls : function(){
			utils.ajax("core/menu/childrens",{}, (response) => {
                this.arrayMenus = response.data;
				this.getMenuForFilter();
				
            });
		},

		getMenuForFilter: function(){
			utils.ajax("core/menu/childrens/filter",{}, (response) => {
				this.arrayItemsFilter = response.data;
			});
		},

        getMenu : function(menu){
            utils.ajax("core/menu/children",{ID_MENU:menu.ID_MENU}, (response) => {
                var arr = utils.clone(this.arrayMenus); 
				//this.ordineHasChanged = false;
                for (var i = 0 ; i < arr.length ; i++){
                    if (arr[i].ID_MENU == menu.ID_MENU){
                        var item = utils.clone(arr[i]);
                        item.open = true;
                        item.children = response.data.items;
                        for (var j = 0 ; j < item.children.length ; j++){
                            item.children[j].children = item.children[j].items;
                            item.open = true;
                        }
                        arr[i] = item;
                        break;
                    }
                }
                this.arrayMenus = arr;
                this.$forceUpdate();
            });
        },

        newMenu : function(){
			this.newMenuRoot = 1;
            this.menuSelezionato = utils.nuovoElemento(this.menuSelezionato,{DESCRIZIONE:"",ID_MENU:0,FK_ID_TYPE:0,FLG_VISIBILE:1,FLG_ATTIVO:1,ID_MENU_PADRE:0,FLG_TIPOLOGIA:1},"popUpMenuRoot");
        },
		
        editMenu : function(menu){
            if (menu.hasOwnProperty("ID_MENU")){
                this.menuSelezionato = utils.modificaElemento(this.menuSelezionato,menu,"popUpMenuChildren");
            }
            else{
                this.menuSelezionato = utils.modificaElemento(this.menuSelezionato,menu,"popUpMenuChildren");
            }
        },

        deleteMenu : function (menu){
            utils.alert.confirm("Sicuro di voler eliminare la Posizione e tutti i suoi Menu?",() => {
                utils.ajax('core/basic/delete',{className:"MenuItem","id" : menu.ID_MENU}, (response) => {
                    this.getMenuAlls();
                });
            });
        },
		
        saveMenu : function(){
			if(this.newMenuRoot == 0){
				if(this.menuSelezionato.FK_ID_TYPE > 0){
					if(this.menuSelezionato.ID_MENU > 0){
						utils.ajax('core/menu/children/update',this.menuSelezionato, (response) => {
							if (response.esito == 0){
								this.filters.closePopUp("popUpMenuChildren");
								this.getMenuAlls();
							}
						});
					}
					else {
						utils.ajax('core/menu/children/insert',this.menuSelezionato,(response)=>{
							if (response.esito == 0){
								this.filters.closePopUp("popUpMenuChildren");
								this.getMenuAlls();
							}
						});
					}
				}
				else {
					utils.alert.danger('Selezionare una tipologia di Menu');
				}	
			}
			else{
				utils.ajax('core/menu/children/insert',this.menuSelezionato,(response)=>{
					if (response.esito == 0){
						this.filters.closePopUp("popUpMenuRoot");
						this.getMenuAlls();						
					}
				});
			}
        },

		updateStatoMenu : function(menu){
            setTimeout(() => {
                utils.ajax("core/menu/children/stato/update",menu, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }  
                }); 
            },250);
        }, 

		updateMenuFlgVisibile : function(menu){
			menu.FLG_VISIBILE = menu.FLG_VISIBILE == 1 ? 0 : 1;
			utils.ajax("core/menu/children/update",menu, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					menu.FLG_VISIBILE = menu.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
		},


    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayTypes,this.search);
            return arr;
    	},
        paginatedListMenu(){
            var arr = utils.inGridRecursive(this.arrayMenus,this.searchMenu);
            return arr;
    	},
	},

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		//this.ordineHasChanged = false;
		this.getTypes();
		this.getMenuAlls();
		this.getMenuForFilter();
    }

}
</script>