<template>
        
	<!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
					<a href="#" class="d-inline-block text-dark" v-on:click="getPreventivo()"> Pratiche </a> \
					<a href="#" class="d-inline-block text-dark" v-on:click="openRoute('/Preventivi')"> Elenco Preventivi </a> \
					<span class="text-muted">Nuovo Preventivo</span>
				</h4>
				<h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i>>Nuovo Preventivo</h4>
				<a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
			</div>
			<div class="header-elements d-none">
				<div class="d-flex justify-content-center">
					<a href="#" class="btn btn-link btn-float text-body" v-on:click="openRoute('/Quota')"><i class="icon-earth"></i> <span>DISPONIBILITA'</span></a>
                    <a href="#" class="btn btn-link btn-float text-body" v-on:click="openRoute('/Ordini')"><i class="icon-earth"></i> <span>PRATICHE</span></a>
					<a href="#" class="btn btn-link btn-float text-pink" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
				</div>
			</div>
		</div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg navbar-light border-top">
			<div class="text-left d-lg-none w-100">
				<button type="button" class="navbar-toggler dropdown-toggle" data-bs-toggle="collapse" data-target="#navbar-second">
					<i class="icon-menu7 me-2"></i> Nuovo Preventivo
				</button>
			</div>
			<div class="navbar-collapse collapse" id="navbar-second">
				<ul class="nav navbar-nav">
					<li class="nav-item">
						<a href="#preventivo-a" class="navbar-nav-link text-success active" data-bs-toggle="tab">
							<i class="icon-calendar3 me-2"></i> <strong>Nuovo Preventivo</strong>
						</a>
					</li>
				</ul>

				<ul class="navbar-nav ms-lg-auto">
					<li class="nav-item">
					</li>
					<li class="nav-item ms-4">
					</li>
				</ul>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->

	<!-- Content area -->
	<div class="content content-2">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="preventivo-a">
				<div class="card shadow mb-0">
					<div class="card-body card-body-2">
						<div class="alert alert-2 alert-secondary alert2 border-0">
							<Ricerca :modalita="3" @onresult="ricercaResultHandler($event)" @changeModulo="changeModuloHandler($event)" :ref="'ricerca'"></Ricerca>
						</div>	
						<div class="tab-content mt-1">
							<div class="tab-pane active" role="tabpanel" style="padding: 0;">
								<div class="row">
									<div class="col-9">			
										<nav class="tabbable mt-1">
											<ul class="nav nav-tabs mt-0 mb-1" id="tabs" role="tablist" style="margin-top:14px">
												<li class="nav-item">
													<a class="nav-link isprint-tab" data-bs-toggle="tab" data-tab="opzioni" href="#opzioni" role="tab" aria-controls="opzioni" aria-selected="true">
														TUTTO
													</a>
												</li>
											</ul>
										</nav>
										<div class="tableFixHead mt-1" style="min-height: 35.5rem !important; max-height: 35.5rem !important;">  

											<div class="row" v-if="arrayRisultatiRicerca.length !== 0 && currentModulo() != 4">
												<div v-for="risultato in getRisultatiRicerca" v-bind:key="risultato" v-bind:class="{'col-xl-4':modalitaVistaRisultati == 1, 'col-12':modalitaVistaRisultati==2}">
													<CardRisultatoRicerca :modalitaVistaRisultati="modalitaVistaRisultati"  :selezionabile="true" :risultato="risultato" @selezionato="openSchedaQuota(risultato)"></CardRisultatoRicerca>
												</div>	
											</div>
											<div class="row mt-2" v-if="arrayRisultatiRicerca.length !== 0 && currentModulo() == 4">
												<h4 v-show="arrayRisultatiRicerca.length > 0" class="col-12">Voli di andata</h4>
												<div v-for="risultato in getRisultatiRicerca" v-bind:key="risultato" class="col-xl-4">
													<CardRisultatoRicerca :modalitaVistaRisultati="modalitaVistaRisultati" :risultato="risultato" @selezionato="openSchedaQuota(risultato)"></CardRisultatoRicerca>
												</div>										
											</div> 
											<div class="row mt-2" v-if="arrayRisultatiRicerca.length !== 0 && divVoliRitornoVisibile() && currentModulo() == 4">
												<h4 v-show="arrayRisultatiRicerca.length > 0" class="col-12">Voli di ritorno</h4>
												<div v-for="risultato in getRisultatiRicercaRitorno" v-bind:key="risultato" class="col-xl-4">
													<CardRisultatoRicerca :modalitaVistaRisultati="modalitaVistaRisultati" :risultato="risultato" @selezionato="openSchedaQuota(risultato)"></CardRisultatoRicerca>
												</div>
											</div>									
										
											
										</div>
									</div>
									<div class="col-3">
										<div class="card shadow mt-1 mb-2">
											<div class="card-header card-header-2 bg-light header-elements-inline py-0">
												<h6 class="card-title py-2"><strong>Il Tuo Preventivo</strong></h6>
											</div>
											<div class="card-body p-2">
												<!-- INTESTATARIO -->
												<div class="row">
													<div class="col-2">
														<div class="profile-image text-center"> 
															<img src="https://bootdey.com/img/Content/avatar/avatar7.png" width="38" height="38" alt=""> 
														</div>
													</div>
													<div class="col-10">
														<h6 class="mt-0 mb-0">
															<span class="text-muted">Mr - Castelforte Egidio</span>
															<span class="text-muted float-end"><i class="fad fa-flag fa-fw"></i> IT</span>
														</h6>
														<p>
															<i class="fad fa-envelope-open fa-fw"></i> <span class="text-muted me-2">Email</span>
															<i class="fad fa-phone-square-alt fa-fw"></i> <span class="text-muted">Cell:</span>
														</p>
													</div>             
												</div>

												<!-- RICERCA -->
												<div class="card-body card-body-2 border-bottom-2 border-light">
													<div class="d-sm-flex flex-sm-wrap mb-1">
														<div class="font-weight-semibold">Destinazione:</div>
														<div class="ms-sm-auto mt-2 mt-sm-0">Paestum, Italia</div>
													</div>
													<div class="d-sm-flex flex-sm-wrap mb-2">
														<div class="font-weight-semibold">Soggiorno:</div>
														<div class="ms-sm-auto mt-2 mt-sm-0"><a href="#">02/06/2024</a> / <a href="#">09/06/2024</a></div>
													</div>
												</div>
												
												<!-- PRODOTTI -->
												<ul class="media-list mt-2">
													<h6>Proposta N° 1</h6>
													<li class="media mt-1">
														<div class="me-3 align-self-center">
															<img src="../../assets/images/placeholders/placeholder.jpg" width="35" alt="" class="top-0">
														</div>

														<div class="media-body">
															<div class="font-weight-semibold">SETTIMANA SHARM EL SHEIK</div>
															<ul class="list-inline list-inline-dotted list-inline-condensed fs-12 text-muted">
																<li class="list-inline-item">Camera 1</li>
																<li class="list-inline-item">1 - CHD: 0 - INF: 0</li>
															</ul>
														</div>

														<div class="ms-3">
															<div class="list-icons">
																<a href="#" class="list-icons-item"><strong>€ 500</strong></a>
															</div>
														</div>
													</li>
												</ul>

											</div>
										</div>
									</div>
								</div>
								
							</div> 
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>		

	<!-- Modal -->
	<div class="modal fade" id="popUpSearchPreventivo"  role="dialog"  >
        <div class="modal-dialog modal-lg"> 
            <div class="modal-content" >
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">Nuovo preventivo</h6>   
                    <button type="button" class="close" data-bs-dismiss="modal">X</button>
                </div>
                <div class="modal-body" style="padding: 0.8rem !important;">

					<form class="form-horizontal">
					  	<div class="form-group">
							<div class="col-lg-8">
								<label class="custom-file">
									<input type="file" class="custom-file-input"><span class="custom-file-label">Cerca</span>
								</label>
							</div>
					  	</div>
					</form>
					<div class="outer_div">
						<div class="table-responsive">
							<table class="table" style="font-size: 12px;">
								<tbody>
									<tr class="warning">
										<th>Codice</th>
										<th>Titolo</th>
										<th>Marca</th>
										<th><span class="pull-right">Qnt.</span></th>
										<th><span class="pull-right">Prezzo</span></th>
										<th style="width: 36px;"></th>
									</tr>
									<tr>
										<td>25</td>
										<td>Mouse inalambrico</td>
										<td>Mega</td>
										<td class="col-xs-1">
										<div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="cantidad_1" value="1">
										</div></td>
										<td class="col-xs-2"><div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="precio_venta_1" value="10.00">
										</div></td>
										<td><span class="pull-right"><a href="#" onclick="agregar('1')"><i class="glyphicon glyphicon-plus"></i></a></span></td>
									</tr>
									<tr>
										<td>TCL01</td>
										<td>Teclado multimedia</td>
										<td>Mega</td>
										<td class="col-xs-1">
										<div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="cantidad_2" value="1">
										</div></td>
										<td class="col-xs-2"><div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="precio_venta_2" value="15.00">
										</div></td>
										<td><span class="pull-right"><a href="#" onclick="agregar('2')"><i class="glyphicon glyphicon-plus"></i></a></span></td>
									</tr>
									<tr>
										<td>ILK059</td>
										<td>Nuevo mini 2.4g micro inalambrico de teclado</td>
										<td>Mega</td>
										<td class="col-xs-1">
										<div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="cantidad_3" value="1">
										</div></td>
										<td class="col-xs-2"><div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="precio_venta_3" value="25.00">
										</div></td>
										<td><span class="pull-right"><a href="#" onclick="agregar('3')"><i class="glyphicon glyphicon-plus"></i></a></span></td>
									</tr>
									<tr>
										<td>4520</td>
										<td>TECLADO para HP 4520</td>
										<td>HP</td>
										<td class="col-xs-1">
										<div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="cantidad_4" value="1">
										</div></td>
										<td class="col-xs-2"><div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="precio_venta_4" value="40.00">
										</div></td>
										<td><span class="pull-right"><a href="#" onclick="agregar('4')"><i class="glyphicon glyphicon-plus"></i></a></span></td>
									</tr>
									<tr>
										<td>A081</td>
										<td>Altavoz de la computadora</td>
										<td>HP</td>
										<td class="col-xs-1">
										<div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="cantidad_5" value="1">
										</div></td>
										<td class="col-xs-2"><div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="precio_venta_5" value="18.00">
										</div></td>
										<td><span class="pull-right"><a href="#" onclick="agregar('5')"><i class="glyphicon glyphicon-plus"></i></a></span></td>
									</tr>
									<tr>
										<td colspan="5"><span class="pull-right"><ul class="pagination pagination-large"><li class="disabled"><span><a>‹ Prev</a></span></li><li class="active"><a>1</a></li><li><a href="javascript:void(0);" onclick="load(2)">2</a></li><li><span><a href="javascript:void(0);" onclick="load(2)">Next ›</a></span></li></ul></span></td>
									</tr>
								</tbody>
							</table>
						</div>

					</div>
				</div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaPreventivo()"><i class="far fa-plus-square fa-fw"></i> SALVA</button>                                   
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">CHIUDI <i class="far fa-sign-out-alt fa-fw"></i> </button>
                </div>
			</div>
		</div>
	</div>				

    <SchedaUtente :ref="'schedaUtente'" ></SchedaUtente> 
    <SchedaAzienda :ref="'schedaAzienda'" :tipo="1" ></SchedaAzienda>

</template>



<script>

import Ricerca from '@/components/Ricerca.vue'
import SchedaUtente from '@/components/SchedaUtente.vue'
import SchedaAzienda from '@/components/SchedaAzienda.vue'	
import CardRisultatoRicerca from '@/components/CardRisultatoRicerca.vue'

export default {
    name:"Preventivo",
    components:{
		Ricerca,
        SchedaUtente,
		SchedaAzienda,
		CardRisultatoRicerca
    },
    emits:['selezionaProdotto'],
    
    data:function(){
        return {
			anno:new Date().getFullYear(),
            apiStartup:'',
			arrayRisultatiRicerca : new Array(),
            filters:global.filters,
            intestatarioSelezionato:{},
            preventivoSelezionato:{storico:new Array()},
            periodoSelezionato:{},
            dataCreazioneRange:{},
            prodottoSelezionato:{FK_ID_MODULO:0},
			modalitaVistaRisultati:2,
			search:{
				PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:1000},	          	
				STATO_DISPONIBILITA:{TYPE:'COMBO',VALUE:''},
				ORDINAMENTO:{TYPE:'COMBO',VALUE:''},
			},
        }
    },

    methods:{
		getPreventivo : function(){
            utils.ajax("travel/preventivo",{ID_PREVENTIVO:this.preventivo.ID_PREVENTIVO}, (response) => {
                this.preventivo = response.data;
            });  
        },
		changeModuloHandler : function(idModulo){
			this.FK_ID_MODULO = idModulo;
			this.arrayRisultatiRicerca = new Array();
		},
		ricercaResultHandler : function(data){
            this.arrayRisultatiRicerca = new Array();
            this.arrayRisultatiRicerca = data;
			this.FLG_VOLO_ANDATA_SELEZIONATO = 0;		
			$('#linkRisultati'+this.FK_ID_MODULO).click();
        },
		divVoliRitornoVisibile : function(){
			if (this.$refs.ricerca != undefined && this.$refs.ricerca.dati != undefined){
				return this.$refs.ricerca.dati.FLG_SOLA_ANDATA == 0;
			}
			return false;			
		},
		currentModulo : function(){
			if (this.$refs.ricerca == undefined || this.$refs.ricerca.dati == undefined || this.$refs.ricerca.dati.FK_ID_MODULO == 0){
				return 0;
			}
			return this.$refs.ricerca.dati.FK_ID_MODULO;
		},
		ordinamentoRisultati : function(arr){
			if (this.search.ORDINAMENTO.VALUE == 1 || this.search.ORDINAMENTO.VALUE == 2){
				arr = utils.array.ordinaArray(arr,"PREZZO_TOTALE");
				if (this.search.ORDINAMENTO.VALUE == 2){
					arr = arr.reverse();
				}
			}
			else{
				arr = utils.array.ordinaArray(arr,"DESCRIZIONE");
				if (this.search.ORDINAMENTO.VALUE == 4){
					arr = arr.reverse();
				}
			}			
			return arr;
		},
		openRoute : function(route){
			global.router.push(route);
		},
    },
    computed: {
		getRisultatiRicerca(){			
			var arr =  utils.inGrid(this.arrayRisultatiRicerca,this.search).arr; 
			return this.ordinamentoRisultati(arr);
		},
		getRisultatiRicercaRitorno : function(){
			var arrayResult = new Array();
			for (var i = 0 ; i < this.arrayRisultatiRicerca.length ; i++){
				if (this.arrayRisultatiRicerca[i].FLG_RITORNO == 1){
					arrayResult.push(this.arrayRisultatiRicerca[i]);
				}
			}
			var arr =  utils.inGrid(arrayResult,this.search).arr; 
			return this.ordinamentoRisultati(arr);
		}
    }, 
    created : function(){
		this.preventivo = utils.clone(this.$root.preventivoSelezionato);
		this.getPreventivo();
    }
}
</script>