<template>

    <div class="card">
        <div class="card-header pt-3 pb-2 d-flex">
            <div class="wmin-lg-600 p-0">
                <div class="d-flex align-items-center p-1">
                    <h6 class="mb-0">Chat: {{ chatHeader }}</h6>
                    <div class="ms-auto">
                        <a href="#" class="text-body ms-2 collapsed" data-bs-toggle="collapse" data-bs-target="#collapse-button-expanded" aria-expanded="false">
                            <i class="ph-magnifying-glass"></i>
                        </a>
                        <div class="ms-auto">
                            <button class="close-btn float-end" @click.stop="closeComponent">&times;</button>
                        </div>
                    </div>
                </div>
                <div class="collapse-horizontal collapse" id="collapse-button-expanded" style="">
                    <div class="px-3 mb-0">
                        <div class="form-control-feedback form-control-feedback-start" style="position: relative; top: -2.25rem; left: 20rem;">
                            <input class="form-control form-control-sm w-auto" autocomplete="off" name="filterUsername" placeholder="Filtra per Test" type="text" v-model="search.TEXT.VALUE">
                            <div class="form-control-feedback-icon">
                                <i class="ph-magnifying-glass"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Action toolbar -->
        <div class="card-body bg-light" style="overflow: auto">
            <div v-if="isLoading" class="loading-spinner">
                <div class="spinner"></div>
                <span class="title_chat">Caricamento...</span>
            </div>

            <div class="media-chat-scrollable p-3" @scroll="handleScroll">
                <div class="media-chat vstack gap-2" v-for="(messages,data) in paginatedList" v-bind:key="data">
                    
                    <div class="text-center justify-content-center text-muted mx-0">
                        <span v-if="messages.length > 0"  class="badge bg-dark bg-opacity-20 text-reset fs-sm px-2">{{ filters.formatDayWithDate(data) }}</span>
                    </div>

                    <div v-for="message in messages" v-bind:key="message.ID_MESSAGE" class="media-chat-item align-items-start gap-3 mb-3 w-50" :class="{'media-chat-item-reverse hstack': message.USERNAME === $root.utente.USERNAME, 'hstack': message.USERNAME !== $root.utente.USERNAME}">
                        <a href="#" class="d-block status-indicator-container">
                            <img v-if="message.FK_ID_USER > 0 && message.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',message.URL_AVATAR)" class="w-40px h-40px rounded-pill" alt="" />
                            <div v-if="message.URL_AVATAR == 'placeholder.jpg' || message.FK_ID_USER == 0"><img src="../assets/images/placeholders/placeholder.jpg" class="w-40px h-40px rounded-pill" alt=""></div>
                            <span class="status-indicator "  v-bind:class="$root.getUserStatusClass(message.FK_ID_USER,$root.arrayUtentiAdmin)"></span>
                        </a>
                        <div>
                            <div class="media-chat-message">{{ message.TEXT }}</div>
                            <div class="fs-sm text-muted mt-2">
                                <i v-if="message.FK_ID_USER == $root.utente.ID_UTENTE" class="ph-checks me-1 " v-bind:class="{'text-muted':message.FK_ID_STATO_MESSAGGIO == 1,'text-primary':message.FK_ID_STATO_MESSAGGIO == 2}"></i>
                                {{ message.ORA_INVIO }}
                            </div>
                        </div> 
                    </div>

                </div>
            </div>

        </div>
        <div class="card-footer">

            <div class="row align-items-center">
                <div class="col-md-1">
                    <div class="btn-group dropup">
                        <button type="button" class="btn btn-light btn-icon border-transparent rounded-pill btn-sm me-1" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="ph-smiley"></i>
                        </button>
                        <ul class="dropdown-menu py-0">
                            <EmojiPicker @select="addEmoji" />
                        </ul>
                    </div>
                    <a href="#" class="btn btn-light btn-icon border-transparent rounded-pill btn-sm me-1" data-bs-popup="tooltip" aria-label="Send file" data-bs-original-title="Send file">
                        <i class="ph-paperclip"></i>
                    </a>
                </div>
                <div class="col-md-9">
                    <input @keydown.enter.prevent="inserisciMessaggio" @input="handleTyping" class="form-control form-control-content w-100" contenteditable="" placeholder="Scrivi un messaggio..." v-model="messaggio.TEXT">
                </div>
                <div class="col-md-2">
                    <button v-show="this.messaggio.TEXT != ''" type="button" class="btn btn-primary ms-auto float-end" v-on:click="inserisciMessaggio()">
                        Send<i class="ph-paper-plane-tilt ms-2"></i>
                    </button>
                </div>
            </div>

        </div>
    </div>


</template>



<script>

import EmojiPicker from 'vue3-emoji-picker'


export default {
    name: "ChatComp",

    props: {
        chatId: Number,
        utentiSelezionati: {
            type: Array,
            default: () => []
        }
    },

    components:{
        EmojiPicker
    },

    data() {
        return {
            filters: global.filters,
            search: {
                TEXT: { TYPE: 'TEXT', VALUE: '' },
            },
            arrayMessaggi: {messaggi:{}},
            arrayChat: [],
            messaggio: { TEXT: '', FK_ID_USER: this.$root.utente.ID_UTENTE, USERNAME: this.$root.utente.USERNAME },
            utente: { FK_ID_USER_CREATOR: this.$root.utente.ID_UTENTE },
            chatHeader: '' ,
            isSending: false,
            isWriting: false,
            isLoading: false,
            limit:15,
            offset:0,
            allMessagesLoaded: false,
            typingTimeOut: null, 
            chatState: {},
            showEmojiPicker: false
        };
    },

    methods: {
        handleScroll(event) {
            const container = event.target;
            if (container.scrollTop <= 5 && !this.isLoading && !this.allMessagesLoaded) {
                this.getMessaggiPerChat();
            }
        },

        resetMessageInput(){
            this.messaggio.TEXT = '';
        },

        inserisciMessaggio() {
            if(!this.messaggio.TEXT.trim() || this.isSending ){
                return;
            }
            this.isSending = true;
            const messaggioClone = {
                ...this.messaggio,
                FK_ID_CHAT : this.chatId,
                DATA_INVIO : filters.formatDateReverse(filters.formatDate(new Date())),
                ORA_INVIO : filters.formatTimeOnly(new Date()),
                URL_AVATAR : this.$root.utente.URL_AVATAR,
                FK_ID_STATO_MESSAGGIO : 1
            };
            
            if (this.verifySocketConnection()) {
                utils.ajax('chad_2/messaggio', messaggioClone, (response) => {
                    if (response.esito == 0) {
                        messaggioClone.ID_MESSAGE = response.data.id_inserito;
                        this.aggiungiMessaggiInChat(messaggioClone)
                        this.$root.appoggio.socket.emit('sendMessage', messaggioClone);
                        this.$emit('messageSent',messaggioClone);
                        this.messaggio.TEXT = ''; 
                        clearTimeout(this.typingTimeOut);
                        this.$root.appoggio.socket.emit('stopTyping', { chatId: this.chatId, userId: this.$root.utente.ID_UTENTE})
                    }
                    this.isSending = false;
                },false);
            }
            else {
                console.error('Socket non disponibile o sessione scaduta');
                this.isSending = false;
            }
        },

        handleTyping() {
            if (this.verifySocketConnection()) {
                // Invia l'evento "sta scrivendo"
                this.$root.appoggio.socket.emit('typing', { chatId: this.chatId, userId: this.$root.utente.ID_UTENTE, username: this.$root.utente.USERNAME, isWriting:true });
                // Reset del timeout se continua a scrivere
                clearTimeout(this.typingTimeOut);
                // Se non scrive per 1 secondi, inviaMO "ha smesso di scrivere"
                this.typingTimeOut = setTimeout(() => {
                    this.$root.appoggio.socket.emit('stopTyping', { chatId: this.chatId, userId: this.$root.utente.ID_UTENTE, username: this.$root.utente.USERNAME, isWriting:false });
                }, 1000);
            }
        },


        getMessaggiPerChat() {
            if(this.isLoading || this.allMessagesLoaded) return;
            this.isLoading = true;
            utils.ajax('chad_2/messaggi/chat', {FK_ID_CHAT: this.chatId, FK_ID_USER: this.$root.utente.ID_UTENTE,limit: this.limit,offset: this.offset }, (response) => {
                if(response.esito == 0){
                    const nuoviMessaggi = response.data.messaggi;
                    if (!nuoviMessaggi || Object.keys(nuoviMessaggi).length == 0) {
                        this.allMessagesLoaded = true;
                    } 
                    else {
                        this.offset += this.limit;
                        for (var data in nuoviMessaggi) {
                            if (!this.arrayMessaggi.messaggi[data]) {
                            this.arrayMessaggi.messaggi[data] = [];
                            }
                            this.arrayMessaggi.messaggi[data] = nuoviMessaggi[data].concat(
                            this.arrayMessaggi.messaggi[data]
                            );
                        }
                    }

                    for(const date in nuoviMessaggi) {
                        for(const message of nuoviMessaggi[date]){
                            if(message.FK_ID_USER !== this.$root.ID_UTENTE && message.FK_ID_STATO_MESSAGGIO == 1 ){
                                this.markAsRead()
                            }
                        }
                    }
                    
                    if (this.arrayMessaggi.utenti) {
                        this.chatHeader = this.arrayMessaggi.utenti.map((utente) => utente.USERNAME === this.$root.utente.USERNAME ? 'Tu' : utente.USERNAME).join(', ');
                    }
                   
                }
                this.isLoading = false;
            },() => {this.isLoading = false;},false);   
        },

        getChatUsers() {
            return this.arrayMessaggi && this.arrayMessaggi.utenti ? this.arrayMessaggi.utenti : [];
        },

        markAsRead() {
            if (this.verifySocketConnection()) {
                this.$root.appoggio.socket.emit('markAsRead', { chatId: this.chatId, userId: this.$root.utente.ID_UTENTE });
                utils.ajax('chad_2/messaggi/markAsRead', { idChat: this.chatId, idUtente: this.$root.utente.ID_UTENTE }, (response) => {
                    if (response.esito == 0) {
                        console.log('Messaggi marcati come letti nel DB');
                       this.$emit('updateUnreadMessages', {chatId:this.chatId})
                    } else {
                        console.error('Errore nella marcatura dei messaggi come letti', response);
                    }
                },false);
            }
        },

        handleRecivedMessage(message) {
            console.log("Messaggio ricevuto via socket:", message);
            if (message.FK_ID_CHAT == this.chatId) {
                if(message.FK_ID_USER != this.$root.utente.ID_UTENTE){
                     this.aggiungiMessaggiInChat(message);
                }
                if (this.$root.utente.ID_UTENTE != message.FK_ID_USER) {
                    this.$root.appoggio.socket.emit('markAsRead', { chatId: this.chatId, userId: this.$root.utente.ID_UTENTE });
                    utils.ajax('chad_2/messaggi/updateStatus', { idMessaggio: message.ID_MESSAGE, newStatus: 2 }, (response) => {
                        if (response.esito == 0) {
                            console.log('Messaggio aggiornato come letto');
                            message.FK_ID_STATO_MESSAGGIO = 2; 
                        }
                    });
                }
            }
            this.isWriting = false;
        },

        aggiungiMessaggiInChat(message){
            const dateKey = message.DATA_INVIO;
            if(!this.arrayMessaggi.messaggi[dateKey]){
                this.arrayMessaggi.messaggi[dateKey] = [];
            }
            this.arrayMessaggi.messaggi[dateKey].push(message);
            if (this.$root.utente.ID_UTENTE != message.FK_ID_USER) {
                message.FK_ID_STATO_MESSAGGIO = 2;  
            }
        },

        verifySocketConnection() {
            if (!this.$root.appoggio || !this.$root.appoggio.socket) {
                console.warn("Socket non disponibile, tentando la riconnessione...");
                this.reconnectSocket();
                return false;
            }
            return true;
        },
            
        reconnectSocket() {
            if (this.$root.utente && this.$root.utente.ID_UTENTE) {
                this.$root.appoggio.socket = io(this.$root.CHAT_PATH); 
                this.setupSocketListeners(); 
            }
        },

        setupSocketListeners() {
            this.$root.appoggio.socket.on('receiveMessage', this.handleRecivedMessage);
                this.$root.appoggio.socket.on('messagesRead', (data) => {
                    console.log(data.chatId);
                    console.log(this.chatId)
                if (data.chatId == this.chatId) {
                    for (let dateKey in this.arrayMessaggi.messaggi) {
                        const dayMessages = this.arrayMessaggi.messaggi[dateKey];
                        for (let i = 0; i < dayMessages.length; i++) {
                            const message = dayMessages[i];
                            if (message.FK_ID_USER == this.$root.utente.ID_UTENTE) {
                                message.FK_ID_STATO_MESSAGGIO = 2;
                            }
                        }
                    }
                }
            });
            this.$root.appoggio.socket.on('typing', (data) => {
                if (data.chatId === this.chatId && data.userId !== this.$root.utente.ID_UTENTE) {
                    this.$emit('isWriting', { chatId: this.chatId, userId: data.userId, isWriting: true });
                }
            });
            this.$root.appoggio.socket.on('stopTyping', (data) => {
                if (data.chatId === this.chatId && data.userId !== this.$root.utente.ID_UTENTE) {
                    this.$emit('stopWriting', { chatId: this.chatId, userId: data.userId, isWriting: false });
                }
            });
        },

        closeComponent() {
            this.$emit('close');
        },

        toogleEmojiPicker(){
            this.showEmojiPicker = !this.showEmojiPicker;
        },

        addEmoji(emoji){
            this.messaggio.TEXT += emoji.i
            this.showEmojiPicker = false
        },

        flattenMessagesByDate(arrayMessaggi){
            let result = [];
            Object.keys(arrayMessaggi.messaggi).forEach(date => {
                arrayMessaggi.messaggi[date].forEach(message => {
                    result.push({
                        ...message,
                        DATA_INVIO: date 
                    });
                });
            });
            return result;
        },

        groupMessagesByDate(filteredMessages){
            return filteredMessages.reduce((acc, message) => {
                const date = message.DATA_INVIO;
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(message);
                return acc;
            }, {});
        },

        filterMessages(messagesArray,searchText){
            return messagesArray.filter(message => {
                return (
                    message.TEXT.toLowerCase().includes(searchText.toLowerCase()) ||
                    message.USERNAME.toLowerCase().includes(searchText.toLowerCase())
                );
            });       
        },


    },

    computed: {
        // Passaggio 1: Flatten messages into an array
        orderedMessagesFlat() {
            return this.flattenMessagesByDate(this.arrayMessaggi)
            .sort((a, b) => {
                const dateDiff = new Date(b.DATA_INVIO) - new Date(a.DATA_INVIO); // Ordina per data decrescente
                if (dateDiff === 0) {
                    return new Date(b.ORA_INVIO) - new Date(a.ORA_INVIO); // Ordina per ora decrescente
                }
                return dateDiff;
            });
        },

            // Step 2: Filtra i messaggi ordinati
        filteredOrderedMessagesFlat() {
            const searchText = this.search.TEXT.VALUE.toLowerCase();
            return this.orderedMessagesFlat.filter(message => {
                return (
                    message.TEXT.toLowerCase().includes(searchText) ||
                    message.USERNAME.toLowerCase().includes(searchText)
                );
            });
        },

            // Step 3: Raggruppa i messaggi filtrati
        paginatedList() {
            return this.groupMessagesByDate(this.filteredOrderedMessagesFlat);
        }
    },

    watch: {
        chatId(newVal, oldVal) {
            if (newVal != oldVal) {
                this.arrayMessaggi = {messaggi: {}};
                this.offset = 0;
                this.allMessagesLoaded = false;
                this.isLoading = false;
                this.getMessaggiPerChat();
                this.resetMessageInput();
            }
        }
    },
    
    created() {
        this.getMessaggiPerChat();
        if(this.verifySocketConnection()){
            this.setupSocketListeners();
        }
    },

    beforeUnmount(){
        if (this.$root.appoggio && this.$root.appoggio.socket) {
            this.$root.appoggio.socket.off('receiveMessage');
            this.$root.appoggio.socket.off('messagesRead');
            this.$root.appoggio.socket.off('typing');
            this.$root.appoggio.socket.off('stopTyping');

        }
    }
}
</script>

<style scoped>
    .message{padding:10px;border-radius:15px;margin:5px;max-width:60%;position:relative;word-wrap:break-word}
    .message.sent{background-color:#addefb;align-self:flex-end;text-align:right}
    .message.received{background-color:#ceedf9;align-self:flex-start;text-align:left}
    .username{font-weight:700;color:#005180}
    .status{font-size:.75em;color:#888;position:absolute;bottom:5px;right:10px}
    .input-group{display:flex;align-items:center;margin-bottom:0}
    .input{border-radius:30px;flex:1;margin-right:10px}
    .circle-btn{border-radius:50%;background-color:#005180;height:38px;width:40px;display:flex;align-items:center;justify-content:center;border:none}
    .circle-btn i{color:#fff;font-size:16px}
</style>
