<template>

    <div class="row" style="height: 8.75rem;">
        <div class="col-md-4 mb-2">
            <img v-if="prodotto.ID_PRODOTTO > 0 && prodotto.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('PRODOTTI',prodotto.URL_AVATAR)" class="rounded" style="width: 200px !important;" alt="" />
            <div v-if="prodotto.URL_AVATAR == 'placeholder.jpg'" src="../assets/images/placeholders/placeholder.jpg" class="squared"></div>
        </div>
        <div class="col-md-8 mb-2" style="height: 6.7rem;">
            <h4 class="text-primary mb-1"> 
                {{ prodotto.lang.TITOLO }}
            </h4>
            <p class="text-dark mb-1"><i class="fal fa-tag fa-fw me-1"></i><span class="text-light me-1">|</span> vvv</p>
            <p class="text-dark mb-1"><i class="fal fa-calendar fa-fw me-1"></i><span class="text-light me-1">|</span>{{ filters.formatDateTime(prodotto.DATA_CREAZIONE) }}</p>
            <p class="fs-sm text-dark mb-1">&nbsp;</p>
            <p class="mb-0">&nbsp;</p>
        </div> 
    </div>

    <p>&nbsp;</p>

    

 



    <!--
    <div class="modal fade" id="popUpVisualizzazioneProdotto" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" >
                <div class="modal-header bg-secondary text-white border-0 p-2">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 75% !important;"> SCHEDA INFORMATIVA {{descrizioneTipoProdotto}}</h6>
                    <div class="btn-group float-end">
                        <a href="#" class="btn btn-flat-yellow my-1 me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Comunicazioni</a>
                        <div class="dropdown-menu dropdown-position dropdown-menu-end" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);">
                            <a href="#" class="dropdown-item me-1"><i class="fal fa-print fa-fw"></i><span class="text-muted ms-1 me-1">|</span>Stampa scheda </a>
                            <a href="#" class="dropdown-item me-1"><i class="fal fa-envelope fa-fw"></i><span class="text-muted ms-1 me-1">|</span>Invia comunicazione </a> 
                            <a href="#" class="dropdown-item me-1"><i class="fal fa-paper-plane fa-fw"></i><span class="text-muted ms-1 me-1">|</span>Invia sms </a> 
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body p-0"> 	
                   
                    <div class="row ps-2">
                        <div class="col-12 col-lg-1 ps-0 bg-light">
                            <ul class="nav nav-tabs nav-tabs-vertical nav-tabs-vertical-start bg-light m-1" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link border-0 active" id="linkGeneraleVisualizz" data-bs-toggle="tab" href="#main" role="tab" aria-controls="main" aria-selected="true" style="height: 5rem; padding-left: 1.1rem;">
                                        <i class="fal fa-fw fa-2x" v-bind:class="filters.iconaProdotto(prodotto.FK_ID_MODULO)"></i>
                                    </a>
                                </li> 
                                <li v-show="modulo == 4 && prodotto.ID_PRODOTTO > 0" class="nav-item">
                                    <a class="nav-link border-0" id="aeroporto-tab" data-bs-toggle="tab" href="#aeroporto" role="tab" aria-controls="aeroporto" aria-selected="true" style="height: 5rem; padding-left: 1.1rem;">
                                        <i class="fal fa-info fa-fw fa-2x"></i> 
                                    </a>
                                </li> 
                                <li v-show="modulo == 3 && prodotto.ID_PRODOTTO > 0" class="nav-item">
                                    <a class="nav-link border-0" id="composizione-tab" data-bs-toggle="tab" href="#composizione" role="tab" aria-controls="composizione" aria-selected="true" style="height: 5rem; padding-left: 1.1rem;">
                                        <i class="fal fa-info fa-fw fa-2x"></i> 
                                    </a>
                                </li>  
                                <li  class="nav-item">
                                    <a class="nav-link border-0" id="opzioniProdotto-tab" data-bs-toggle="tab" href="#opzioniProdotto" role="tab" aria-controls="opzioniProdotto" aria-selected="true" style="height: 5rem; padding-left: 1.1rem;">
                                        <i class="fal fa-info fa-fw fa-2x"></i> 
                                    </a>
                                </li>  
                                <li class="nav-item" v-if="modulo != 5 && prodotto.ID_PRODOTTO > 0">
                                    <a class="nav-link border-0" id="geo-tab" data-bs-toggle="tab" href="#geo" role="tab" aria-controls="geo" aria-selected="false" style="height: 5rem; padding-left: 1.1rem;">
                                        <i class="fal fa-info fa-fw fa-2x"></i> 
                                    </a>
                                </li>
                                <li class="nav-item" v-if="modulo != 5 && prodotto.ID_PRODOTTO > 0">
                                    <a class="nav-link border-0" id="info-tab" data-bs-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="false" style="height: 5rem; padding-left: 1.1rem;">
                                        <i class="fal fa-info fa-fw fa-2x"></i> 
                                    </a>
                                </li>
                                <li class="nav-item" v-show="false">
                                    <a class="nav-link border-0" id="logs-tab" data-bs-toggle="tab" href="#logs" role="tab" aria-controls="logs" aria-selected="false" style="height: 5rem; padding-left: 1.1rem;">
                                        <i class="fal fa-info fa-fw fa-2x"></i> 
                                    </a>
                                </li>
                            </ul>                       
                        </div>
                        <div class="col-12 col-lg-7 pe-0">   

                            <div class="tab-content profile-tab" id="myTabContent">

                                <div class="tab-pane fade show active" id="main" role="tabpanel" aria-labelledby="main-tab">
                                    
                                        <div class="tab-pane mb-2 fade show active" id="main" role="tabpanel" aria-labelledby="main-tab">
                                            <h5 class="d-flex flex-nowrap mt-2 my-1">
                                                <a href="#" class="me-2">{{prodotto.lang.TITOLO}}</a>
                                                <span class="text-success ms-auto">
                                                    <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                    <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                    <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                    <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                    <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                </span>
                                            </h5>
                                            <ul class="list-inline text-muted fs-sm mb-0">
                                                <li class="list-inline-item">
                                                    <i class="fal fa-tag fa-fw me-1"></i>
                                                    {{ filters.getLabel($root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',prodotto.FK_ID_TIPO,'DESCRIZIONE') }}
                                                </li>
                                                <li class="list-inline-item">
                                                    <i class="fad fa-map-signs fa-fw me-1"></i> 
                                                    <a href="#" class="cursor-pointer">
                                                        {{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',prodotto.FK_ID_COMUNE,'STATO') }} - 
                                                        {{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',prodotto.FK_ID_COMUNE,'REGIONE') }}, 
                                                        {{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',prodotto.FK_ID_COMUNE,'COMUNE') }} 
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        
                                        <div class="box-prodotto">
                                            <div v-html="prodotto.lang.TESTO_HOME"></div>
                                            <br><br>
                                            <div v-html="prodotto.lang.TESTO_BODY"></div>
                                        </div>	
                                   
                                </div>
                               

                                <div class="tab-pane fade" id="aeroporto" role="tabpanel" aria-labelledby="aeroporto-tab">
                                    <div class="box-agenda ms-2 p-2">
                                        
                                        
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="composizione" role="tabpanel" aria-labelledby="composizione-tab">
                                    <div class="box-agenda ms-2 p-2" >
                                        
                                        
                        
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="opzioniProdotto" role="tabpanel" aria-labelledby="opzioniProdotto-tab">
                                    <div class="box-agenda ms-2 p-2">

                                        

                                    </div>
                                </div>

                                <div class="tab-pane fade" id="geo" role="tabpanel" aria-labelledby="geo-tab" v-if="modulo != 5">
                                    <div class="box-agenda ms-2 p-2" >  
                                        
                                        
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab" v-if="modulo != 5">
                                    <div class="box-agenda ms-2 p-2" >        
                                        
    
                                    </div>
                                </div>                               

                                <div class="tab-pane fade" id="logs" role="tabpanel" aria-labelledby="logs-tab">
                                    <div class="box-agenda ms-2 p-2" >   

                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-4 ps-0">

                            <img v-if="prodotto.ID_PRODOTTO > 0 && prodotto.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('PRODOTTI',prodotto.URL_AVATAR)" class="img-fluid" alt="" style="height:100%;" />
                           
                        </div>
                    </div>

                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> Chiudi </button>
                </div>                
            </div>
        </div>
    </div>
    -->
  
</template>



<script>

//import Select2 from '@/components/utils/Select2.vue'

export default {
   name:"SchedaVisualizzazioneProdotto",

   props:["modulo"],

   components:{
       //Select2
   },

   data:function(){
       return{
           filters:global.filters,
           prodotto : {lang:{},dettagli:{}},        
           ts:new Date().getTime(),
       }
   },

   methods:{
        openSchedaVisualizzazioneProdotto : function(_id){
           utils.ajax("travel/prodotto",{ID_PRODOTTO:_id}, (response) => {
               this.prodotto = response.data;
           });
           
       },

   },
   
   computed:{
       descrizioneTipoProdotto: function(){
           switch (this.prodotto.FK_ID_MODULO){
               case 1:
                   return "ATTIVITA'";
               case 2:
                   return "HOTEL";
               case 3:
                   return "PACCHETTO";
               case 4:
                   return "VOLO";
               case 5:
                   return "CAMERE";
               default:
                   return "PRODOTTO";
           }
       }
   }

}
</script>