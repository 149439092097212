<template>
  <div class="card">
    <!-- Toolbar -->
    <div class="card-body d-sm-flex align-items-sm-center flex-sm-wrap">
      <button type="button" class="btn btn-primary me-3" @click="inviaEmail">
        <i class="ph-paper-plane-tilt"></i>
        <span class="d-none d-xl-inline-block ms-2">Send mail</span>
      </button>
      <div class="btn-group">
        <button type="button" class="btn btn-light" @click="salvaInBozza">
          <i class="ph-check"></i>
          <span class="d-none d-xl-inline-block ms-2">Save</span>
        </button>
        <button type="button" class="btn btn-light" @click="closeEmail">
          <i class="ph-trash"></i>
          <span class="d-none d-xl-inline-block ms-2" @click="cancelReply">Delete</span>
        </button>
      </div>
    </div>

    <!-- Mail details -->
    <div class="card-body border-top">
      <div class="row mx-0 px-2">
        <div class="col-2 d-none d-sm-block">
          <div class="py-2">To:</div>
        </div>
        <div class="col-10">
          <div class="d-sm-flex flex-sm-wrap">
            <input
              type="text"
              class="form-control flex-fill py-2 px-0 order-0 shadow-none border-0 rounded-0"
              placeholder="Add recipients"
              v-model="localEmail.receiver"
            />
            <div class="hstack gap-2 gap-sm-3 pt-sm-2 pb-2 ms-sm-auto">
              <a href="#email_cc" data-bs-toggle="collapse">Copy</a>
              <a href="#email_bcc" data-bs-toggle="collapse">Hidden copy</a>
            </div>
          </div>
        </div>
      </div>
      <!-- CC -->
      <div class="row collapse border-top mx-0 px-2" id="email_cc">
        <div class="col-2 d-none d-sm-block">
          <div class="py-2">CC:</div>
        </div>
        <div class="col-10">
          <input
            type="text"
            class="form-control flex-fill py-2 px-0 order-0 shadow-none border-0 rounded-0"
            placeholder="Add CC recipients"
             v-model="localEmail.cc"
          />
        </div>
      </div>
      <!-- BCC -->
      <div class="row collapse border-top mx-0 px-2" id="email_bcc">
        <div class="col-2 d-none d-sm-block">
          <div class="py-2">BCC:</div>
        </div>
        <div class="col-10">
          <input
            type="text"
            class="form-control flex-fill py-2 px-0 order-0 shadow-none border-0 rounded-0"
            placeholder="Add BCC recipients"
             v-model="localEmail.bcc"
          />
        </div>
      </div>
      <!-- Subject -->
      <div class="row border-top mx-0 px-2">
        <div class="col-2 d-none d-sm-block">
          <div class="py-2">Subject:</div>
        </div>
        <div class="col-10">
          <input
            type="text"
            class="form-control flex-fill py-2 px-0 order-0 shadow-none border-0 rounded-0"
            placeholder="Add subject"
            v-model="localEmail.subject"
          />
        </div>
      </div>
    </div>

    <!-- Editor Section -->
    <div class="card-body border-top">
      <div id="editor" class="ql-container ql-snow">
        <ckeditor :editor="editor" v-model="localEmail.body" :config="editorConfig"></ckeditor>
      </div>
    </div>

    <!-- Attachments Section -->
    <div class="card-body border-top">
      <h6 class="mb-2">Attachments</h6>
      <div class="hstack gap-2 flex-wrap">
        <div v-for="(file, index) in localEmail.attachments" :key="index" class="attachment-item">
          <div class="d-inline-flex align-items-center fw-semibold">
            <i class="ph-file text-primary me-2"></i>
            <span>{{ filters.accorciaTesto(file.name, 10) }}</span>
            <span class="fs-sm fw-normal text-muted ms-1">
              ({{ formatFileSize(file.size) }})
            </span>
            <button class="btn btn-link btn-sm text-danger ms-2" @click="removeFile(index)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-3">
        <h6 class="mb-2" @click="openFileExplorer" style="cursor: pointer;">
          Add Attachments <i class="fa fa-plus"></i>
        </h6>
        <input type="file" ref="fileInput" @change="handleFileUpload" multiple hidden />
      </div>
    </div>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'WriteEmail',
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    email: {
      type: Object,
      default: () => ({ receiver: '', subject: '', body: '', bodyHtml: '', attachments: [] }),
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDraftNew: false,
      localEmail: {
        sender: this.email.FROM || this.$root.utente.EMAIL,
        receiver: this.email.TO || "",
        cc: this.email.CC || "",
        bcc: this.email.BCC || "",
        subject: this.email.OGGETTO || "",
        body: this.email.CORPO || "",
        bodyHtml: this.email.CORPO_HTML || "",
        attachments: this.email.ALLEGATI || [],
        id: this.email.ID_EMAIL || null,
      },
      filters: global.filters,
      editor: ClassicEditor,
      editorConfig: {
        height: 200,
        resize_enabled: false,
        extraAllowedContent: 'div(*)',
      },
    };
  },
  methods: {
    closeEmail() {
      this.$emit('close');
    },

    inviaEmail() {
      if (!this.localEmail.receiver || !this.localEmail.subject || !this.localEmail.body) {
        utils.alert.warning("Compila tutti i campi prima di inviare l'email.");
        return;
      }
      let messageToSend = this.localEmail.body;
      if (this.localEmail.originalHtml) {
        messageToSend += this.localEmail.originalHtml;
      }
      let formData = new FormData();
      formData.append("sender", this.$root.utente.EMAIL);
      formData.append("from", this.$root.utente.persona.NOME + ' ' + this.$root.utente.persona.COGNOME);
      formData.append("receiver", this.localEmail.receiver);
      formData.append("cc", this.localEmail.cc ?? "");
      formData.append("bcc", this.localEmail.bcc ?? "");
      formData.append("subject", this.localEmail.subject);
      formData.append("body", messageToSend);
      this.localEmail.attachments.forEach((file, index) => {
        formData.append(`attachments[]`, file);
      });
      utils.uploadAjax(
        "core/smtp/send",
        formData,
        (response) => {
          if (response.esito == 0) {
            utils.alert.success("Email inviata con successo!");
            this.closeEmail();
          } else {
            utils.alert.warning("Errore nell'invio dell'email. Riprova.");
          }
        }
      );
    },

    salvaInBozza() {
      if (!this.localEmail.receiver && !this.localEmail.subject && !this.localEmail.body) {
          utils.alert.warning("Compila almeno un campo per salvare in bozza");
          return;
      }

      const isNewDraft = this.localEmail.id == null;

      const payload = {
          FOLDER: 'INBOX.Drafts',
          FK_ID_ENTE: 1,
          ID_UTENTE: this.$root.utente.ID_UTENTE,
          SENDER: this.$root.utente.EMAIL,
          RECEIVER: this.localEmail.receiver,
          SUBJECT: this.localEmail.subject,
          BODY: this.localEmail.body,
      };
      const url = isNewDraft ? 'core/imap/email/move/drafts' : 'core/imap/email/update/draft';
      if (!isNewDraft) {
          payload.ID_EMAIL = this.localEmail.id;
      }

      utils.ajax(url, payload, (response) => {
          if (response.esito == 0) {
              utils.alert.success("Bozza salvata con successo!");
              if (isNewDraft) {
                  this.localEmail.id = response.id || this.localEmail.id;
              }
              this.closeEmail();
          } else {
              utils.alert.error("Errore nel salvataggio della bozza. Riprova.");
          }
      });
    },
    openFileExplorer() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      this.localEmail.attachments.push(...files);
    },
    removeFile(index) {
      this.localEmail.attachments.splice(index, 1);
    },
    formatFileSize(size) {
      return size < 1024 * 1024
        ? `${(size / 1024).toFixed(2)} KB`
        : `${(size / 1024 / 1024).toFixed(2)} MB`;
    },
    cancelReply() {
      this.$emit('cancel-reply');
    },
  },

  watch: {
    email: {
      handler(newEmail) {
        this.localEmail = {
          sender: newEmail.FROM || this.$root.utente.EMAIL,
          subject: newEmail.OGGETTO || '',
          receiver: newEmail.TO || '',
          body: newEmail.CORPO || '',
          attachments: newEmail.ATTACHMENTS || [],
          id: newEmail.ID_EMAIL || null,  
        };
        this.isDraftNew = this.localEmail.id == null ? false : true;
      },
      deep: true,
      immediate: true,
    },
  },

  mounted(){
    if(this.email.CORPO_HTML) {
    this.localEmail.originalHtml = this.email.CORPO_HTML;
  }
  }

};
</script>
<style scoped>
.attachment-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px 12px;
  background: #f8f9fa;
}
</style>
