<template>
  	<div>
	
	    <!-- Page header -->
	    <div class="page-header page-header-light">
	        <div class="page-header-content header-elements-lg-inline">
	            <div class="page-title d-flex">
	                <h4 class="d-none d-sm-block">
	                    <router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
						<router-link href="#" class="d-inline-block text-dark" :to="'Hotels'"><span class="font-weight-semibold"> Hotels </span></router-link> \
						<a href="#" class="d-inline-block text-dark"><span class="font-weight-semibold"> {{prodotto.lang.TITOLO}} </span></a> \
	                    <span class="text-muted">Elenco Stagioni</span><code> - Lingua italiano</code>
	                </h4>
	                <h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Elenco Contenuti </h4>
	                <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
	            </div>
	            <div class="header-elements d-none">
	                <div class="d-flex justify-content-center">
	                    <a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovaStagione()"><i class="icon-googleplus5 text-success"></i><span>NUOVO</span></a>
	                    <a href="#" class="btn btn-link btn-float text-body"><i class="icon-air"></i><span>LISTINI</span></a>
	                    <div class="btn-group ms-1 position-static">
							<button type="button" class="btn btn-link btn-float text-body dropdown-toggle dropdown-toggle-2" data-bs-toggle="dropdown" aria-expanded="false">
								<i class="icon-power2"></i> <span>CONFIG</span>
							</button>
							<div class="dropdown-menu dropdown-menu-right" style="">
								<a href="#" class="dropdown-item"><i class="icon-cube4"></i> Configurazione Modulo</a>
								<div class="dropdown-divider"></div>
	                    		<a href="#" class="dropdown-item" v-on:click="$root.confTravelTipologiche()"><i class="icon-grid"></i> Configurazione Plug-In</a> 
							</div>
						</div>	                    
	                    <a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
	                </div>
	            </div>
	        </div>
	    </div>
	    <!-- /page header -->	
	
	    <!-- Content area -->
	    <div class="content content-2">
	
	        <div class="card shadow mb-0">
	            <div class="card-header card-header-2 header-elements-inline">
	                <h6 class="card-title"><i class="icon-filter3 me-2"></i> SEZIONE FILTRI</h6>
	                <div class="header-elements">
	                    <div class="list-icons">
	                        <a class="list-icons-item" data-action="collapse"></a>
	                    </div>
	                </div>
	            </div>
	            <div class="collapse show">
		            <div class="card-body card-body-2">
	                    <div class="alert alert-2 alert-secondary alert2 border-0">
		                    <div class="row ">
		                  		<div class="col-5 col-md-1 form-group-1">
		                     		<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Prodotto" :tabindex="1" v-model="search.ID_LANG.VALUE" />
		                    	</div>	
	                            <div class="col-7 col-md-1 form-group-1">
	                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Codice" :tabindex="2" v-model="search.FK_FK_ID_PRODOTTO.VALUE" />
	                            </div>                
	                            <div class="col-12 col-md-2 form-group-1">
	                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Titolo Prodotto" :tabindex="3" v-model="search.TITOLO.VALUE" />
	                            </div>
	                            <div class="d-none d-sm-block col-md-4 form-group-1">
	                            </div>
								<div class="col-12 col-md-4">
									<div class="row">
										<div class="col-md-4 form-group-1">
										</div>
										<div class="col-8 col-md-3 form-group-1">
			                                <select class="form-control form-control-sm" data-placeholder="Stato" :tabindex="7" v-model="search.FLG_ATTIVO.VALUE">
			                                    <option value="">Tutti </option>   
			                                    <option value="1"> &nbsp; Attivati</option>
			                                    <option value="0"> &nbsp; Disattivati</option>
			                                </select>
			                            </div>
			                            <div class="col-4 col-md-2 form-group-1">
			                                <select class="form-control form-control-sm" data-placeholder="Limite" :tabindex="8" v-model="search.PAGINATION.PAGE_SIZE">
			                                    <option value="5">5</option>
			                                    <option value="10">10</option>
			                                    <option value="25">25</option>
			                                    <option value="50">50</option>
			                                    <option value="75">75</option>
			                                    <option value="100">100</option>
			                                    <option value="250">250</option>
			                                    <option value="500">500</option>
			                                </select>
			                            </div> 
			                            <div class="col-md-3 form-group-1">
			                                <a href="#collapse-icon" class="btn btn-secondary btn-sm btn-block collapsed" data-bs-toggle="collapse" aria-expanded="false" :tabindex="9">
			                                    <span style="font-weight: 500;">Altri Filtri</span>
			                                </a>
			                            </div>  
			                        </div>
								</div>  
	                        </div>
	                    </div>
	                </div>
	            </div>
		        <div class="card-body card-body-2 card-body-nobottom card-body-notop">
	                <div class="row">
	                    <div class="col-md-2 d-none d-sm-block mb-1">
	                        <div class="btn-group position-static">
	                            <button type="button" class="btn btn-light btn-labeled btn-labeled-left dropdown-toggle btn-sm" data-bs-toggle="dropdown" aria-expanded="false"><b><i class="icon-cog5 "></i></b> Azioni Multiple</button>
	                            <div class="dropdown-menu dropdown-menu-left" style="">
	                                <a href="#" class="dropdown-item"><i class="icon-menu7"></i> Action</a>
	                                <a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Another action</a>
	                                <a href="#" class="dropdown-item"><i class="icon-mail5"></i> One more action</a>
	                                <div class="dropdown-divider"></div>
	                                <a href="#" class="dropdown-item"><i class="icon-gear"></i> Separated line</a>
	                            </div>
	                        </div>
	                    </div>
	                    <div class="col-md-9 d-none d-sm-block">
	
	                    </div>
						<div class="col-md-1 d-none d-sm-block">
							<select class="form-control form-control-sm" data-placeholder="Lingua" :tabindex="5">
								<option value="it">ITALIANO</option>
								<option value="de">GERMAN</option>
								<option value="en">ENGLISH</option>
								<option value="es">ESPANOL</option>
								<option value="fr">FRANCH</option>
							</select>
						</div>
	                </div> 
	                <table class="table table-bordered mt-1"> 
	                    <thead>
	                        <tr class="bg-secondary text-white">
	                            <th class="table-head-sm" style="width:2%;"> <input id="chkAllStagioni" v-on:click="filters.selectAll('chkAllStagioni',paginatedList.arr)" type="checkbox" class=""> </th>
	                            <th class="table-head-sm" style="width:3%;"> <i class="fa fa-code"></i> </th>
	                            <th class="table-head-sm" style="width:5.95%;"> # </th>
	                            <th class="table-head-sm" style="width:5%;"> Img </th>
	                            <th class="table-head-sm">Nome Prodotto /<br />SEO</th>
	                            <th class="table-head-sm" style="width:15%;">Titolo Stagione </th>
	                            <th class="table-head-sm" style="width:7%;">Mese Da </th>
								<th class="table-head-sm" style="width:7%;">Mese A </th>
	                            <th class="table-head-sm" style="width:5%;">Stato </th>
	                            <th class="table-head-sm" style="width:5.4%;">Azioni </th>
	                        </tr>
	                    </thead>
	                </table>
	                <div class="table-scrollable">
	                    <table class="table table-bordered table-hover table-striped">
	                        <tbody>
	                            <tr v-for="stagione in paginatedList.arr" v-bind:key="stagione.ID_LANG">
	                                <td class="text-center" style="width:2.4%;"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="stagione.SELEZIONATO" class=""> </td>
	                                <td class="text-center" style="width:3%;"><img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" /></td>
	                                <td class="" style="width:5.95%;">{{stagione.ID_LANG}} </td>
	                                <td class="" style="width:5%;">
	                                    <a :href="$root.GLOBAL_IMAGES_PATH+'/PRODOTTI/'+stagione.FK_ID_PRODOTTO+'/'+stagione.FK_ID_PRODOTTO+'.jpg'" data-popup="lightbox">
	                                        <img :src="$root.GLOBAL_IMAGES_PATH+'/PRODOTTI/'+stagione.FK_ID_PRODOTTO+'/'+stagione.FK_ID_PRODOTTO+'.jpg'" class="img-preview rounded" height="34" alt="" />
					                    </a>
	                                </td>
	                                <td class="">
	                                    <span v-on:click="modificaContenuti(stagione)" class="text-primary fw-bold cursor-pointer">{{stagione.TITOLO}}</span><br />
										&nbsp;
	                                </td>
	                                <td class="" style="width:15%;"><a href="#" v-on:click="modificaStagione(stagione)"><span class="badge badge-flat badge-pill border-secondary text-secondary">{{stagione.TITOLO_STAGIONE}} </span></a></td>
	                                <td class="" style="width:7%;"><span class="badge badge-flat badge-pill border-secondary text-secondary">{{stagione.MESE_DA}} </span></td>
									<td class="" style="width:7%;"><span class="badge badge-flat badge-pill border-secondary text-secondary">{{stagione.MESE_A}} </span></td>
	                                <td class="" style="width:5%;">
	                                    <ISwitch @toggle="updateStatoProdottoLang(stagione)" :size="'sm'" v-model="stagione.FLG_ATTIVO" style="margin-top:6px"></ISwitch>
									</td>
	                                <td class="text-center" style="width:5.4%;">
	                                    <div class="list-icons">
	                                        <div class="dropdown">
	                                            <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
	                                            <div class="dropdown-menu dropdown-menu-right">
                                                    <a href="#" class="dropdown-item" v-on:click="modificaStagione(stagione)"><i class="fal fa-pencil fa-fw"></i> Modifica Stagione</a>
													<div class="dropdown-divider"></div>
	                                                <a href="#" class="dropdown-item" v-on:click="modificaContenuti(stagione)"><i class="fal fa-edit fa-fw"></i>Gestione Contenuti</a>
	                                                <a href="#" class="dropdown-item"><i class="fal fa-images fa-fw"></i> Galleria Immagini</a>
													<div class="dropdown-divider"></div>
	                                                <a href="#" class="dropdown-item" v-on:click="deleteProdottoLang(stagione)"><i class="fal fa-trash-alt fa-fw text-danger"></i> Elimina Stagione</a>
	                                            </div>
	                                        </div>
	                                    </div>
	                                </td>
	                            </tr>
	                        </tbody>
	                    </table>
	                </div>
	
	                <div class="row">
	                    <div class="col-md-12 float-end">
	                        <div class="float-end">
		                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH"></Pagine>
		                    </div>
		                </div>
		            </div>
		
		        </div>
			</div>	    
	    </div>

        <div class="modal fade" id="popUpStagione" data-backdrop="true" role="dialog"  >
			<div class="modal-dialog">
				<div class="modal-content" >
					<div class="modal-header bg-secondary text-white">GESTIONE STAGIONI </div>
					<div class="modal-body">  
						<div class="row">
	                    	<div class="col-md-12 form-group">
								<label>Titolo Prodotto</label>
								<input class="form-control" type="text" disabled v-model="stagioneSelezionato.TITOLO" />
							</div>
							<div class="col-md-6 form-group">
								<label>Titolo Stagione</label>
								<input class="form-control" type="text" v-model="stagioneSelezionato.TITOLO_STAGIONE" />
							</div>
	                    	<div class="col-md-3 form-group">
								<label>Valido <strong>dal</strong> mese</label>
								<select class="form-control" v-model="stagioneSelezionato.MESE_DA">
									<option v-for="i in 12" v-bind:value="i" v-bind:key="i">{{i}}</option>
								</select>
							</div> 
							<div class="col-md-3 form-group">
								<label>Valido <strong>al</strong> mese</label>
								<select class="form-control" v-model="stagioneSelezionato.MESE_A">
									<option v-for="i in 12" v-bind:value="i" v-bind:key="i">{{i}}</option>
								</select>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-danger" data-bs-dismiss="modal">Annulla</button>
						<button type="button" class="btn btn-success" v-on:click="salvaStagione()">Salva</button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>



<script>
import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'

export default {
    name:"Stagioni",
    components:{
        Pagine,
        ISwitch,
    },
    props:["modulo"],
    data:function(){
		return{
	        filters:global.filters,
	        arrayStagioni:new Array(),
			GLOBAL_IMAGES_PATH:'',
	        search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_LANG:{TYPE:'TEXT',VALUE:''},
                FK_FK_ID_PRODOTTO:{TYPE:'COMBO',VALUE:''}, 
	            TITOLO:{TYPE:'TEXT',VALUE:''},               
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
	        },
	        stagioneSelezionato:{},
			prodottoLang:{},
			prodotto:{lang:{}},
      	}
    },

    methods:{
        getStagioni : function(){
            utils.ajax("travel/prodotto/contenuti",{ID_PRODOTTO: this.prodotto.ID_PRODOTTO}, (response) => {
                this.arrayStagioni = response.data;
            }); 
        },
        nuovaStagione : function(stagione){
            this.stagioneSelezionato = {
				ID_LANG:0,
				FK_ID_PRODOTTO:this.prodotto.ID_PRODOTTO,
				MESE_DA:1,
				MESE_A:12,
				FK_ID_GEO_STATO:118,
				FLG_ATTIVO:1
			};
			this.filters.openPopUp("popUpStagione");  
        },
        modificaStagione : function(stagione){
            this.stagioneSelezionato = utils.clone(stagione);
            this.filters.openPopUp("popUpStagione");             
        },
        salvaStagione : function(){
            const api = this.stagioneSelezionato.ID_LANG == 0 ? "travel/prodottolang/insert" : "travel/prodottolang/update";
            utils.ajax(api,this.stagioneSelezionato, (response) => {
                if (response.esito == 0){
                    this.filters.closePopUp("popUpStagione"); 
                    utils.alert.success(response.messaggio);
                    this.getStagioni();
                }
            });
        },
        modificaContenuti : function(stagione){
            this.$root.prodotto = this.prodotto;
            this.$root.stagione = stagione;
            global.router.push("ProdottoLang");
        }
    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayStagioni,this.search);
            return arr;
        },

    }, 

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
        utils.ajax("travel/prodotto",{ID_PRODOTTO:this.$root.prodotto.ID_PRODOTTO},(response)=>{
            this.prodotto = response.data;
            this.getStagioni(); 
        });
    },
}
</script>


