<template>

	<div class="modal fade" :id="'popUpSchedaUtente'+rs" role="dialog" tabindex="-1">
	    <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 85% !important;">Scheda: {{utente.persona.NOME}} {{utente.persona.COGNOME}}</h6>   
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
                </div> 
                <div class="modal-body p-2" style="height: 33rem;"> 

                    <div class="card card-noshadow mb-0">
                        <div class="card-body card-body-2">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                    <div class="sidebar-content">
                                        <div class="card sidebar-section-body text-center p-2">
                                            <div class="card-img-actions d-inline-block">
                                                <img v-if="utente.ID_UTENTE > 0 && utente.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',utente.URL_AVATAR)" class="img-fluid squared-circle rounded-0" alt="" width="215" style="max-height: 230px;" />
                                                <span v-if="utente.URL_AVATAR == 'placeholder.jpg' || utente.ID_UTENTE == 0">
                                                    <img src="../assets/images/placeholders/placeholder.jpg" class="img-fluid rounded-circle" width="150" height="150" alt="">
                                                </span>
                                                <div class="card-img-actions-overlay card-img rounded-circle">
                                                    <a href="#" class="btn btn-outline-white btn-icon rounded-pill" v-on:click="uploadImmagineUtente()">
                                                        <i class="ph-pencil"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <ul class="nav nav-sidebar" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <a href="#profile" id="datiPersonali" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab" v-on:click="tabSelezionato='datiPersonali'">
                                                    <i class="ph-user me-2"></i>Dati Personali
                                                </a>
                                            </li>
                                            <li v-show="utente.ID_UTENTE > 0 && $root.utente.FK_ID_LIVELLO > 2" class="nav-item" role="presentation">
                                                <a href="#schedule" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" v-on:click="tabSelezionato='datiAziendali'" tabindex="-1">
                                                    <i class="ph-calendar me-2"></i>Dati Aziendali
                                                </a>
                                            </li>
                                            <li v-show="utente.ID_UTENTE > 0 && utente.FK_ID_LIVELLO == 5" class="nav-item" role="presentation">
                                                <a href="#friends" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" v-on:click="tabSelezionato='friends'" tabindex="-1">
                                                    <i class="ph-users me-2"></i>Gestione parenti/amici
                                                </a>
                                            </li>
                                            <li v-show="utente.ID_UTENTE > 0 && utente.FK_ID_LIVELLO == 5" class="nav-item" role="presentation">
                                                <a href="#orders" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" v-on:click="tabSelezionato='pratiche'" tabindex="-1">
                                                    <i class="ph-bag me-2"></i>Elenco Pratiche
                                                </a>
                                            </li>
                                            <li v-show="utente.ID_UTENTE > 0 && $root.utente.FK_ID_LIVELLO > 2" class="nav-item" role="presentation">
                                                <a href="#cards" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" v-on:click="tabSelezionato='tessere'" tabindex="-1">
                                                    <i class="ph-cards me-2"></i>Elenco Tessere
                                                </a>
                                            </li>
                                            <li v-show="utente.ID_UTENTE > 0 && $root.utente.FK_ID_LIVELLO > 2" class="nav-item" role="presentation">
                                                <a href="#notification" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" v-on:click="tabSelezionato='notifiche'" tabindex="-1">
                                                    <i class="ph-vibrate me-2"></i>Notifiche
                                                </a>
                                            </li>
                                            <li v-show="utente.ID_UTENTE > 0" class="nav-item" role="presentation">
                                                <a href="#testi" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" v-on:click="tabSelezionato='contenuti'" tabindex="-1">
                                                    <i class="ph-text-t me-2"></i>Gestione Contenuti 
                                                </a>
                                            </li>
                                            <li v-show="this.utente.FK_ID_LIVELLO < 3 && utente.ID_UTENTE > 0" class="nav-item" role="presentation">
                                                <a href="#roles" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" v-on:click="tabSelezionato='permessi'" tabindex="-1">
                                                    <i class="ph-list me-2"></i>Gestione Permessi
                                                </a>
                                            </li>
                                            <li v-show="this.utente.FK_ID_LIVELLO < 3 && utente.ID_UTENTE > 0" class="nav-item" role="presentation">
                                                <a href="#notifiche" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" v-on:click="tabSelezionato='notifiche'" tabindex="-1">
                                                    <i class="ph-list me-2"></i>Gestione Notifiche
                                                </a>
                                            </li>
                                            <li v-show="utente.ID_UTENTE > 0 && $root.utente.FK_ID_LIVELLO < 4" class="nav-item" role="presentation">
                                                <a href="#password" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" v-on:click="tabSelezionato='configurazioni'" tabindex="-1">
                                                    <i class="ph-gear me-2"></i>Configurazioni Personali
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                
                                </div>
                                <div class="col-12 col-md-9 col-lg-9">
                                    <div class="tab-content flex-fill">
                                        
                                        <div class="tab-pane fade active show" id="profile" role="tabpanel">
                                            <h5 class="label-bar"><i class="fad fa-user fa-fw me-1"></i> Dati Personali</h5>
                                            <div class="box-utenti"> 
            
                                                <PersonaFisica :ref="'pf'" v-model="utente.persona"></PersonaFisica>

                                                <hr class="m-2" v-show="utente.ID_UTENTE == 0" />

                                                <div class="row" v-show="utente.ID_UTENTE == 0">
                                                    <div class="col-6 col-md-4"> 
                                                        <label class="form-label text-danger mb-1">Livello</label>
                                                        <select class="form-select form-select-sm" v-model="utente.FK_ID_LIVELLO">
                                                            <option v-for="livello in $root.arrayLivelli" v-bind:value="livello.ID_LIVELLO" v-bind:key="livello.ID_LIVELLO" v-show="livello.ID_LIVELLO > 1">{{ livello.DESCRIZIONE }}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-6 col-md-8"> 
                                                        <div class="row">
                                                            <div class="col-6 col-md-6">
                                                                <label class="form-label text-danger mb-1">Password</label>
                                                                <input autocomplete="off" type="password" class="form-control form-control-sm" :tabindex="1" v-model="utente.PASSWORD">
                                                            </div>
                                                            <div class="col-6 col-md-6">
                                                                <label class="form-label text-danger mb-1">Conferma password</label>
                                                                
                                                                    <input autocomplete="off" type="password" class="form-control form-control-sm" :tabindex="2" v-model="utente.CONFERMA_PASSWORD">
                                                                    
                                                                                                        
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div class="tab-pane fade" id="schedule" role="tabpanel">
                                            <h5 class="label-bar"><i class="fad fa-user fa-fw me-1"></i> Dati Aziendali</h5>
                                            <div class="box-utenti">
                                                <PersonaGiuridica></PersonaGiuridica>   
                                            </div>  
                                        </div>

                                        <div class="tab-pane fade" id="friends" role="tabpanel">
                                            <h5 class="label-bar"><i class="fad fa-users fa-fw me-1"></i> Gestione parenti/amici</h5>
                                            <div class="tableFixHead tableFixHeadRoles">
                                                <table class="table table-hover table-striped table-bordered">
                                                    <thead class="sticky-top">
                                                        <tr class="bg-secondary text-white">
                                                            <th class="table-head-sm-2" style="width: 6.6%;">#</th> 
                                                            <th class="table-head-sm-2" style="">Cognome e Nome</th> 
                                                            <th class="table-head-sm-2" style="width: 20%;">Ruolo</th>  
                                                        </tr>
                                                    </thead> 
                                                    <tbody>
                                                        <tr v-for="amico in utente.amici" v-bind:key="amico.ID_UTENTE" >
                                                            <td>
                                                                <img v-if="amico.ID_UTENTE > 0" :src="filters.getImgPath('USERS',amico.URL_AVATAR)" class="rounded shadow" alt="" :width="30" ></td>
                                                            <td>
                                                                <span class="text-primary fw-bold text-uppercase cursor-pointer" >{{ amico.SESSO == 1 ? 'Mr' : 'Mrs' }} - {{ amico.NOME }} {{ amico.COGNOME }}</span><!-- v-on:click="modificaUtente(utente)" -->
                                                                <span class="badge badge-danger badge-xs font-size-xs float-end cursor-pointer"  style="padding: 0.20rem 0.3rem !important;"><i class="fal fa-info fa-fw"></i> Note</span><br /><!-- v-on:click="showNoteUtente(utente)" -->
                                                                <a class="badge bg-light rounded-0 badge-xs font-size-xs text-dark me-1" style="border: 1px #dddddd solid; padding: 0.18rem 0.3rem !important;" v-bind:href="`mailto:${amico.EMAIL}`"><i class="fal fa-envelope fa-fw"></i> {{ utente.EMAIL }}</a>
                                                                <a class="badge bg-light rounded-0 badge-xs font-size-xs text-dark" style="border: 1px #dddddd solid; padding: 0.18rem 0.3rem !important;" v-bind:href="`tel:${amico.TELEFONO}`"><i class="fal fa-phone fa-fw"></i> {{utente.TELEFONO}}</a>
                                                                <a class="badge bg-light rounded-0 badge-xs font-size-xs text-dark cursor-pointer float-end" style="border: 1px #dddddd solid; padding: 0.18rem 0.3rem !important;margin-top: 2.5px;" ><i class="fal fa-key fa-fw" data-popup="tooltip" title="" data-original-title="Reset Password"></i> Reset Password</a><!-- v-on:click="resetPassword(utente)" -->
                                                            </td> 
                                                            <td style="">
                                                                Titolare
                                                                <span class="badge badge-secondary badge-xs font-size-xs cursor-pointer" v-on:click="login(amico)"><i class="fal fa-sign-in-alt fa-fw"></i> Accedi ora</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div class="tab-pane fade" id="orders" role="tabpanel">
                                            <h5 class="label-bar"><i class="fad fa-shopping-bag fa-fw me-1"></i> Elenco Pratiche</h5>
                                            <div class="box-utenti">       
                                                <OrdiniPopUp :modalita="1" :ref="'ordini'"></OrdiniPopUp>
                                            </div>
                                        </div>

                                        <div class="tab-pane fade" id="cards" role="tabpanel">
                                            <h5 class="label-bar"><i class="fad fa-bell fa-fw me-1"></i> Elenco Tessere</h5>
                                            <div class="box-utenti">
                                                <table class="table table-hover table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th class="table-head-sm-2" style="width: 7%;">#</th> 
                                                            <th class="table-head-sm-2" style="width: 5%;">Sesso</th> 
                                                            <th class="table-head-sm-2" style="">Cognome e Nome</th> 
                                                            <th class="table-head-sm-2" style="width: 17%;">Email</th>  
                                                            <th class="table-head-sm-2" style="width: 20%;">Telefono</th>  
                                                            <th class="table-head-sm-2" style="width: 5.4%;">Azioni</th> 
                                                        </tr>
                                                    </thead> 
                                                    <tbody>
                                                        <tr>
                                                            <td>V</td> 
                                                            <td>Mr </td> 
                                                            <td>Castelforte Egidio </td> 
                                                            <td style="text-align: center;">e.castelforte@gmail.com</td> 
                                                            <td style="text-align: center;">+39 3335383890</td> 
                                                            <td style="text-align: center;"><button class="btn btn-xs btn-block btn-default"> ... </button></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> 
                                        </div>

                                        <div class="tab-pane fade" id="notification" role="tabpanel">
                                            <h5 class="label-bar"><i class="fad fa-user fa-fw me-1"></i> Notifiche</h5>
                                            <div class="box-utenti">

                                            </div>  
                                        </div>

                                        <div class="tab-pane fade" id="testi" role="tabpanel">
                                            <h5 class="label-bar"><i class="fad fa-text fa-fw me-1"></i> Gestione Contenuti</h5>
                                            <div class="box-utenti">
                                                <div class="alert alert-primary border-0 alert-dismissible fade show">
                                                    Scrivi in questo spazio, note inerenti l'utente.
                                                    <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                                                </div>
                                                <ckeditor :editor="editor" v-model="utente.NOTE" :config="editorConfig"></ckeditor>                                     
                                            </div>
                                        </div>

                                        <div class="tab-pane fade" id="roles" role="tabpanel">
                                            <h5 class="label-bar mb-1"><i class="fad fa-list fa-fw me-1"></i> Gestione Permessi </h5>
                                            <div class="row mb-1">
                                                <div class="col-12 col-md-6">
                                                    <input type="text" class="form-control form-control-sm" placeholder="Descrizione" v-model="searchGruppi.DESCRIZIONE.VALUE" style="width: 315px !important;">
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <select class="form-select form-select-sm float-end me-1" placeholder="Eredita Profilo" v-model="idGruppoProfiloSelezionato" @change="updateProfiloUtente()" style="width: 9rem !important; margin-right: 1px !important;">
                                                        <option v-for="profilo in arrayGruppi" v-show="profilo.FLG_PROFILO == 1" v-bind:key="profilo.ID_GRUPPO" v-bind:value="profilo.ID_GRUPPO"> {{ profilo.DESCRIZIONE }}</option>                                    
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="tableFixHead tableFixHeadRoles">
                                                <table class="table table-bordered table-hover table-striped table-togglable">
                                                    <thead class="sticky-top">
                                                        <tr class="bg-secondary text-white">
                                                            <th class="table-head-sm" style="">Modulo <span class="float-end me-3">All</span></th> 
                                                            <th class="table-head-sm" style="width: 10%;">Read</th> 
                                                            <th class="table-head-sm" style="width: 10%;">Add</th> 
                                                            <th class="table-head-sm" style="width: 10%;">Edit</th>  
                                                            <th class="table-head-sm" style="width: 10%;">Copy</th>  
                                                            <th class="table-head-sm" style="width: 10%;">Delete</th> 
                                                        </tr>
                                                    </thead> 
                                                    <tbody>
                                                        <tr v-for="gruppo in paginatedListGruppi.arr" v-bind:key="gruppo.ID_GRUPPO" v-show="gruppo.FLG_PROFILO == 0">
                                                            <td style="">
                                                                {{gruppo.DESCRIZIONE}}
                                                                <span class="float-end"><ISwitch @toggle="updateGruppoUtente(gruppo)" :size="'sm'" v-model="gruppo.SELEZIONATO" style="z-index: 0;"></ISwitch></span>
                                                            </td> 
                                                            <td class="text-center "  v-for="funzionalita in gruppo.FUNZIONALITA" v-bind:key="funzionalita.ID_FUNZIONALITA">
                                                                <ISwitch @toggle="updateFunzionalitaUtente(funzionalita)" :size="'sm'" v-model="funzionalita.SELEZIONATO" style="z-index: 0;"></ISwitch> 
                                                            </td> 
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!--
                                            <div class="row ">
                                                <div class="col-12">
                                                    <div v-bind:style="getStyle(0)" class="dizionarioContainer" style="overflow:auto;" v-for="gruppo in arrayGruppi" v-bind:key="gruppo.ID_GRUPPO"  >
                                                        <div class="dizionario" style="margin:5px; float:left;" v-bind:style="getStyleDizionario(0)"  >
                                                            <div class="float-start"><i class="far" ></i> <span >{{gruppo.DESCRIZIONE}}</span></div>
                                                            <div class="float-end">
                                                                <ISwitch @toggle="updateGruppoUtente(gruppo)" :size="'sm'" v-model="gruppo.SELEZIONATO" style="z-index: 0;"></ISwitch>                                              
                                                            </div>                                                                                                    
                                                        </div>
                                                        <div v-bind:style="getStyle(1)" class="dizionarioContainer" style="overflow:auto; border-left: 1px solid #bbb" v-for="funzionalita in gruppo.FUNZIONALITA"  v-bind:key="funzionalita.ID_FUNZIONALITA" >
                                                            <div v-bind:style="getStileBorderWidth(1)" style="width:60px; border-top: 1px solid rgb(187, 187, 187); float: left; height: 22px; margin-top: 26px; margin-left: -20px;"></div>
                                                            <div class="dizionario" style="margin:5px;float:left;" v-bind:style="getStyleSubDizionario(1)" >
                                                                <div class="float-start">
                                                                    <i class="far"></i> <span >{{funzionalita.DESCRIZIONE}}</span>
                                                                </div>
                                                                <div class="float-end">
                                                                    <ISwitch @toggle="updateFunzionalitaUtente(funzionalita)" :size="'sm'" v-model="funzionalita.SELEZIONATO" style="z-index: 0;"></ISwitch>  
                                                                </div>                                                        
                                                            </div>
                                                        </div>
                                                    </div>                                            
                                                </div>                                                                               
                                            </div>
                                            -->
                                        </div>

                                        <div class="tab-pane fade" id="notifiche" role="tabpanel">
                                            <h5 class="label-bar mb-1"><i class="fad fa-list fa-fw me-1"></i> Gestione Notifiche </h5>
                                            <div class="row mb-1">
                                                <div class="col-12 col-md-6">
                                                    <input type="text" class="form-control form-control-sm" placeholder="Descrizione" style="width: 315px !important;">
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <!--
                                                    <select class="form-select form-select-sm float-end me-1" placeholder="Eredita Profilo" style="width: 9rem !important; margin-right: 1px !important;">
                                                        <option v-for="notifica in arrayTipologieNotifiche" v-bind:key="notifica.ID_TIPOLOGIA_NOTIFICA" v-bind:value="notifica.ID_TIPOLOGIA_NOTIFICA"> {{ notifica.TITOLO }}</option>                                    
                                                    </select>
                                                    -->
                                                </div>
                                            </div>
                                            <div class="tableFixHead tableFixHeadRoles">
                                                <table class="table table-bordered table-hover table-striped table-togglable">
                                                    <thead class="sticky-top">
                                                        <tr class="bg-secondary text-white">
                                                            <th class="table-head-sm" style="">Titolo Notifica</th>  
                                                            <th class="table-head-sm" style="width: 10%;">Azione</th> 
                                                        </tr>
                                                    </thead> 
                                                    <tbody>
                                                        <tr v-for="noti in arrayTipologieNotifiche" v-bind:key="noti.ID_TIPOLOGIA_NOTIFICA" v-bind:value="noti.ID_TIPOLOGIA_NOTIFICA">
                                                            <td style="">
                                                                {{noti.TITOLO}}
                                                            </td> 
                                                            <td class="text-center " >
                                                                <ISwitch @toggle="updateNotificaUtente(noti,this.utente)" v-model="noti.FLG_ATTIVO" :size="'sm'" style="z-index: 0;"></ISwitch>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div class="tab-pane fade" id="password" role="tabpanel">
                                            <h5 class="label-bar"><i class="fad fa-cogs fa-fw me-1"></i> Configurazioni Personali</h5>
                                            <!-- 17/04/2023 - UTILIZZIAMO LA CORE_OPZIONI_UTENTE (IN QUESTA TABELLA NON CI SONO RIFERIMENTI AD OPZIONI 
                                                REALMENTE CODIFICATE MA NEL CAMPO LABEL VIENE INSERITA LA DESCRIZIONE DELL'OPZIONE MENTRE NEL CAMPO VALORE IL RELATIVO VALORE) -->
                                            <div class="box-utenti">
                                                <form action="#">
                                                    <div class="row mb-1">
                                                        <label class="col-lg-3 col-form-label">SITO WEB:</label>
                                                        <div class="col-lg-1">
                                                            <span class="btn btn-light btn-sm" v-on:click="utente.enti = filters.select2AddAll(utente.enti,filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL'))">Tutti</span>
                                                        </div>
                                                        <div class="col-lg-8">
                                                            <Multiple v-bind:options="filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL')" v-model="utente.enti"></Multiple>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-1" v-for="opzione in arrayOpzioniUtente" v-bind:key="opzione.ID_OPZIONE" v-show="checkVisibilitaOpzione(opzione)"> <!-- v-show="opzione.FK_ID_LIVELLO == utente.FK_ID_LIVELLO" --> <!-- v-show="utente.FK_ID_LIVELLO <= opzione.MAX_FK_ID_LIVELLO_UTENTE" -->
                                                        <label class="col-lg-3 col-form-label">{{ opzione.LABEL }}:</label>
                                                        <div class="col-lg-9">
                                                            <select v-model="opzione.VALORE" class="form-select form-select-sm" :disabled="opzione.FLG_MODIFICABILE == 0 && $root.utente.FK_ID_LIVELLO > 1" >
                                                                <option v-for="opz in opzione.OPZIONI" v-bind:value="opz.ID_OPZIONE_TIPOLOGICA" v-bind:key="opz.DESCRIZIONE" v-show="opzione.FK_ID_TIPOLOGICA != 4 ? true : checkShowRuolo(opz)">{{ opz.DESCRIZIONE }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaUtente()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>                
            </div>
        </div>
    </div>
				   

    <div class="modal fade" :id="'popUpUploadImgUtente'+rs" role="dialog"  tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-light text-dark">
                    <h6 class="modal-title">Upload immagine Profilo</h6>
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">    
                    <Upload :args="argsUtente" @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>
            </div>
        </div>
    </div>	
    		  

</template>



<script>

import ISwitch from '@/components/utils/ISwitch.vue'
import Select2 from '@/components/utils/Select2.vue'
import Multiple from '@/components/utils/Multiple.vue'
import Upload from '@/components/utils/Upload.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PersonaFisica from '@/components/common/PersonaFisica.vue'
import PersonaGiuridica from '@/components/common/PersonaGiuridica.vue'
import OrdiniPopUp from '@/components/OrdiniPopUp.vue'	


export default {
    name:"SchedaUtente",
    emits:['updateGriglia'],
    props:['livello'], 

    components:{
        ISwitch,
        Upload,
        PersonaFisica,
        PersonaGiuridica,
        OrdiniPopUp,
        Multiple
    },	 

    data:function(){
        return{
            filters:global.filters,
            utente : {persona:{},NOTE:'',ID_UTENTE:0,enti:new Array()},
            //CORRISPONDENZA CON ID DELLE TIPOLOGICHE
            idGruppoProfiloSelezionato : 0,
            arrayGruppi:new Array(),
            arrayOpzioniTipologiche:new Array(),
            arrayOpzioniUtente:new Array(
                {LABEL:"RUOLI",     FK_ID_TIPOLOGICA:4, FK_ID_LIVELLO:[1,2,5],FLG_MODIFICABILE:0},
                {LABEL:"TOPBAR",    FK_ID_TIPOLOGICA:14,FK_ID_LIVELLO:[1,2],FLG_MODIFICABILE:0},
                {LABEL:"SIDEBAR",   FK_ID_TIPOLOGICA:15,FK_ID_LIVELLO:[1,2],FLG_MODIFICABILE:1},                
                {LABEL:"TIPOLOGIA", FK_ID_TIPOLOGICA:16,FK_ID_LIVELLO:[5],FLG_MODIFICABILE:1},
                {LABEL:"POSIZIONE", FK_ID_TIPOLOGICA:17,FK_ID_LIVELLO:[5],FLG_MODIFICABILE:1},
            ),
            searchGruppi:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:1000000000},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            rs : utils.generateRandomString(30),
            tabSelezionato : "datiPersonali",
            funzionalitaSelezionata:{},
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            },
            argsUtente:{
                modalita:'MOONLIGHT_uploadImgUtente',
            },
            arrayTipologieNotifiche: new Array(),
        }
    },

    methods:{
        openSchedaUtente : function(utente){
            if (utente.ID_UTENTE == 0){
                this.utente = utils.clone(utente);
                this.filters.openPopUp('popUpSchedaUtente'+this.rs);
            }
            else{
                utils.ajax("travel/utente",{ID_UTENTE:utente.ID_UTENTE}, (response) => {
                    this.utente = response.data.UTENTE;
                    this.argsUtente.ID_UTENTE = this.utente.ID_UTENTE;
                    this.arrayOpzioniTipologiche = response.data.OPZIONI;               
                    this.handlerGetUtente(response);
                    this.filters.openPopUp('popUpSchedaUtente'+this.rs);
                });
            }
            setTimeout(() => {
                var el = document.getElementById('datiPersonali');
                el.click();
            }, 300);
        }, 

        handlerGetUtente:function(response){            
            this.utente = response.data.UTENTE;  
            this.arrayOpzioniTipologiche = response.data.OPZIONI;      
            this.arrayGruppi = new Array();
            this.arrayGruppi = utils.clone(this.$root.arrayGruppi);
            for (var j = 0 ; j < this.arrayGruppi.length ; j++){
                for (var i = 0 ; i < this.utente.gruppi.length ; i++){                        
                    if (this.utente.gruppi[i].ID_GRUPPO == this.arrayGruppi[j].ID_GRUPPO){
                        this.arrayGruppi[j].SELEZIONATO = 1;
                    }
                }
                for (var l = 0 ; l < this.utente.funzionalita.length ; l++){
                    for (var k = 0 ; k < this.arrayGruppi[j].FUNZIONALITA.length ; k++){ 
                        if ( this.utente.funzionalita[l].ID_FUNZIONALITA == this.arrayGruppi[j].FUNZIONALITA[k].ID_FUNZIONALITA ){
                            this.arrayGruppi[j].FUNZIONALITA[k].SELEZIONATO = 1;
                        }
                    }                            
                }
            }     
            this.arrayOpzioniUtente  = new Array(
                {LABEL:"RUOLI",     FK_ID_TIPOLOGICA:4, FK_ID_LIVELLO:[1,2,5],FLG_MODIFICABILE:0},
                {LABEL:"TOPBAR",    FK_ID_TIPOLOGICA:14,FK_ID_LIVELLO:[1,2],FLG_MODIFICABILE:0},
                {LABEL:"SIDEBAR",   FK_ID_TIPOLOGICA:15,FK_ID_LIVELLO:[1,2],FLG_MODIFICABILE:1},                
                {LABEL:"TIPOLOGIA", FK_ID_TIPOLOGICA:16,FK_ID_LIVELLO:[5],FLG_MODIFICABILE:1},
                {LABEL:"POSIZIONE", FK_ID_TIPOLOGICA:17,FK_ID_LIVELLO:[5],FLG_MODIFICABILE:1},
            )
            for (i = 0 ; i < this.arrayOpzioniUtente.length ; i++){
                for (var j = 0 ; j < this.utente.opzioni.length; j++){
                    if (this.arrayOpzioniUtente[i].LABEL == this.utente.opzioni[j].LABEL){
                        this.arrayOpzioniUtente[i].VALORE = this.utente.opzioni[j].VALORE;
                        break;
                    }
                }
                var idTipologica = this.arrayOpzioniUtente[i].FK_ID_TIPOLOGICA;
                this.arrayOpzioniUtente[i].OPZIONI = utils.array.select(this.arrayOpzioniTipologiche,"FK_ID_TIPOLOGICA",idTipologica);
            }
            this.getTipologieNotificheUtente(this.utente);                    
        },

        salvaUtente : function(){
            if (this.tabSelezionato == "datiPersonali" || this.tabSelezionato == "contenuti"){
                var api = this.utente.ID_UTENTE > 0 ? "travel/utente/update" : "travel/utente/insert";
                utils.ajax(api,this.utente, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        if (this.utente.ID_UTENTE == 0){
                            this.utente.ID_UTENTE = response.id_inserito;
                        }
                        //if (response.data != undefined){
                        this.$emit("updateGriglia",response.data);
                        // }
                        /*** BLOCCO PER SALVATAGGIO NOTIFICHE ***/
                        const { FK_ID_MODULO,FK_ID_TIPOLOGIA,FK_ID_UTENTE } = response.notifica || {};
                        if (FK_ID_MODULO && FK_ID_TIPOLOGIA && FK_ID_UTENTE) {
                            this.$root.appoggio.socket.emit('moduleEvent', {FK_ID_MODULO,FK_ID_TIPOLOGIA,FK_ID_UTENTE});
                        } else {
                            console.warn("FK_ID_MODULO o FK_ID_TIPOLOGIA mancanti nella response, evento 'moduleEvent' non emesso.");
                        }
                        /*** FINE BLOCCO ***/
                    }
                });
            }
            else if (this.tabSelezionato == "configurazioni"){
                utils.ajax("travel/utente/opzioni/update",{ID_UTENTE:this.utente.ID_UTENTE,opzioni:this.arrayOpzioniUtente,enti:this.utente.enti}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.$emit("updateGriglia",response.data);
                    }
                });
            }

        },
        
        updateGruppoUtente : function (gruppo){
            setTimeout(() => {
                utils.ajax("core/utente/gruppo/update",{ID_GRUPPO:gruppo.ID_GRUPPO,ID_UTENTE:this.utente.ID_UTENTE,SELEZIONATO:gruppo.SELEZIONATO}, (response) => {
                    utils.ajax("travel/utente",{ID_UTENTE:this.utente.ID_UTENTE}, (response) => {
                        this.utente = response.data.UTENTE;
                        this.handlerGetUtente(response);                    
                    });
                });                    
            }, 300);
        },	

        updateFunzionalitaUtente : function (funzionalita){
            setTimeout(() => {
                utils.ajax("core/utente/funzionalita/update",{ID_FUNZIONALITA:funzionalita.ID_FUNZIONALITA,ID_UTENTE:this.utente.ID_UTENTE,SELEZIONATO:funzionalita.SELEZIONATO}, (response) => {
                    utils.ajax("travel/utente",{ID_UTENTE:this.utente.ID_UTENTE}, (response) => {
                        this.utente = response.data.UTENTE;
                        this.handlerGetUtente(response);                    
                    });
                });
            }, 300);
        }, 

        updateProfiloUtente : function(){
            utils.ajax("core/utente/profilo/update",{ID_UTENTE:this.utente.ID_UTENTE,ID_GRUPPO:this.idGruppoProfiloSelezionato}, (response) => {
                if (response.esito == 0){
                    utils.ajax("travel/utente",{ID_UTENTE:this.utente.ID_UTENTE}, (response) => {
                        this.utente = response.data.UTENTE;
                        this.handlerGetUtente(response);                    
                    });
                }
            });            
        },

        getBorderWidth(livello){
            return (30 + (60*livello))
        }, 

        getStileBorderWidth(){
            var stile = {};
            stile['width'] = this.getBorderWidth() + "px";
            return stile;
        },	

        getStyle:function(livello){
            //var calc = 'calc(100% - '+(100*(this.livello))+'px)';
            var padd = '60px';
            var stile = {};
            stile.width = "100%";
            if (livello > 0){
                stile['margin-left'] = ( 60)+"px";
                stile.width = "calc(100% - "+(60)+"px)";
            }
            return stile;
        },	

        getStyleDizionario:function(livello){
            var stile = {};
            var r = 104 - (20*livello);
            var g = 209 - (20*livello);
            var b = 254 - (20*livello);
            stile.width = "calc(100% - 20px)";
            return stile;
        },	

        getStyleSubDizionario:function(livello){
            var stile = {};
            var r = 104 - (20*livello);
            var g = 209 - (20*livello);
            var b = 254 - (20*livello);
            stile.width = "calc(100% - 60px)";
            return stile;
        },	

        uploadImmagineUtente : function(){
            this.filters.openPopUp("popUpUploadImgUtente"+this.rs);
        },

        successCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgUtente"+this.rs);
            utils.ajax("travel/utente",{ID_UTENTE:this.utente.ID_UTENTE}, (response) => {
                this.utente = response.data.UTENTE;
                this.handlerGetUtente(response);  
                this.$emit("updateGriglia",response.data);                  
            });
        },

        errorCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgUtente"+this.rs);
        }, 

        checkShowRuolo : function(ruolo){	
			if (this.utente.FK_ID_LIVELLO != 5){
				if (ruolo.CUSTOM_1 == "" || ruolo.CUSTOM_1 == this.utente.FK_ID_LIVELLO){
					return true;
				}
			}
			else{
				if (ruolo.CUSTOM_1 == this.utente.FK_ID_LIVELLO){
					return true;
				}
			}
			return false;
		},

        checkVisibilitaOpzione : function(opzione){
            var opz = utils.array.select(this.arrayOpzioniUtente,"FK_ID_TIPOLOGICA",opzione.FK_ID_TIPOLOGICA,1)[0];
            for (var i = 0 ; i < opz.FK_ID_LIVELLO.length ; i++){
                if (opz.FK_ID_LIVELLO[i] == this.utente.FK_ID_LIVELLO){
                    return true
                }
            }
            return false;
        },

        getTipologieNotificheUtente : function(utente){
            utils.ajax("shared/notifiche/tipologie/utenti",{ID_UTENTE:utente.ID_UTENTE}, (response) => {
                if(response.esito == 0){
                    this.arrayTipologieNotifiche = response.data;
                }               
            });
        },
        
        updateNotificaUtente : function (noti,utente){
            const payload = {
                ID_TIPOLOGIA_NOTIFICA:noti.FK_TIPOLOGIA_NOTIFICA,
                ID_UTENTE:utente.ID_UTENTE,
                FLG_ATTIVO: noti.FLG_ATTIVO == 1 ? 0 : 1,
            }
            setTimeout(() => {
                utils.ajax("shared/notifiche/utente/update",payload, (response) => {
                    if(response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }
                });                    
            }, 250);
        },	


    }, 

    computed:{
        paginatedListGruppi(){
            var arr = utils.inGrid(this.arrayGruppi,this.searchGruppi);
            return arr;
        },	
    },	   

    created:function(){
        this.arrayGruppi = utils.clone(this.$root.arrayGruppi);
    }

}
</script>