<template>
	<div>

        <!-- Page header -->
        <div class="page-header page-header-light shadow">
            <div class="page-header-content d-lg-flex">
                <div class="d-flex">
                    <h4 class="page-title mb-0">
                        <router-link href="#" class="d-inline-block text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
                        <span class="text-muted">Dashboard</span>
                    </h4>
                    <a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
                        <i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
                    </a>
                </div>
                <div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
                    <div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
                        <div class="d-inline-flex mt-3 mt-sm-0">
                            <a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="filters.openRoute('/Quota')" title="Disponibilità'" data-bs-toggle="tooltip" data-bs-placement="left">
                                <i class="ph-earth"></i>
                            </a>
                            <a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="filters.openRoute('/Preventivi')" title="Preventivi" data-bs-toggle="tooltip" data-bs-placement="left">
                                <i class="ph-balance"></i>
                            </a>
                            <a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="filters.openRoute('/Statistiche')" title="Statistiche" data-bs-toggle="tooltip" data-bs-placement="left">
                                <i class="ph-lightning"></i>
                            </a>
                            <a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
                                <i class="icon-question7" style="color: orange;"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /page header -->

        <!-- Content area -->
        <div class="content content-1">

            <div class="row">
                <div class="col-12 col-lg-10">

                    <div class="row">
                        <div class="col-lg-4">
                            <div class="card card bg-light shadow">
                                <div class="card-header card-header-2">
                                    <h1 class="card-title">
                                        <Translate :path="'dizionario.base.BENVENUTO'"></Translate> {{utente.USERNAME}}</h1>
                                </div>
                                <div class="card-body card-body-2">
                                    
                                    <p><Translate :path="'dizionario.base.SYS_PRONTO'"></Translate></p>
                                    <section id="map" ref="map"></section>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="row">   
                                <div class="col-6 col-xl-6">
                                    <div class="card card-body">
                                        <div class="media mb-3">
                                            <div class="media-body">
                                                <h6 class="font-weight-semibold mb-0">Errori MySql</h6>
                                                <span class="text-muted d-none d-sm-block">April, 19th</span>
                                            </div>

                                            <div class="ms-3 align-self-center">
                                                <i class="icon-server icon-2x text-danger opacity-75"></i>
                                            </div>
                                        </div>
                                        <div class="progress mb-2" style="height: 0.125rem;">
                                            <div class="progress-bar bg-danger" style="width: 80%">
                                                <span class="sr-only">80% Complete</span>
                                            </div>
                                        </div>   
                                        <div>
                                            <span class="badge bg-light float-end"><router-link href="#" class="d-inline-block text-dark" :to="'Errori'">vai</router-link></span>
                                            Vedi il <span class="d-none d-sm-inline">file</span> Log
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-xl-6">
                                    <div class="card card-body">
                                        <div class="media mb-3">
                                            <div class="media-body">
                                                <h6 class="font-weight-semibold mb-0">Errori Php</h6>
                                                <span class="text-muted d-none d-sm-block">April, 19th</span>
                                            </div>

                                            <div class="ms-3 align-self-center">
                                                <i class="icon-pulse2 icon-2x text-primary opacity-75"></i>
                                            </div>
                                        </div>
                                        <div class="progress mb-2" style="height: 0.125rem;">
                                            <div class="progress-bar bg-primary" style="width: 80%">
                                                <span class="sr-only">80% Complete</span>
                                            </div>
                                        </div>   
                                        <div>
                                            <span class="badge bg-light float-end" v-on:click="openPopUp()">vai</span>
                                            Vedi il <span class="d-none d-sm-inline">file</span> Log
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                        </div>         
                    </div>

                    <div class="row">
                        <div class="col-lg-8">
                            <div class="card card-nobottom">
                                <div class="card-header card-header-2 header-elements-sm-inline mb-2">
                                    <h6 class="card-title">Support tickets</h6>
                                    <div class="header-elements">
                                        <a class="text-body daterange-ranges font-weight-semibold cursor-pointer dropdown-toggle">
                                            <i class="icon-calendar3 me-2"></i>
                                            <span>March 9 - April 7</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="card-body card-body-2">
                                    <ul class="nav nav-tabs nav-tabs-highlight nav-justified">
                                        <li class="nav-item"><a href="#tab-tikA" class="nav-link active" data-bs-toggle="tab"> Ticket Attivi </a></li>
                                        <li class="nav-item"><a href="#tab-tikO" class="nav-link" data-bs-toggle="tab"> Ticket Risolti</a></li>
                                        <li class="nav-item"><a href="#tab-tikC" class="nav-link" data-bs-toggle="tab"> Ticket Chiusi</a></li>
                                    </ul>
                                    <div class="tab-content">
                                        <div class="tab-pane fade show active" id="tab-tikA">
                                            <div class="mb-3">
                                                <div class="table-responsive">
                                                    <table class="table table-scrollable-1 text-nowrap">
                                                        <thead>
                                                            <tr class="bg-secondary text-white">
                                                                <th style="width: 50px">Due</th>
                                                                <th style="width: 300px;">User</th>
                                                                <th>Description</th>
                                                                <th class="text-center" style="width: 20px;"><i class="icon-arrow-down12"></i></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center">
                                                                    <h6 class="mb-0">12</h6>
                                                                    <div class="fs-12 text-muted line-height-1">hours</div>
                                                                </td>
                                                                <td>
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="me-3">
                                                                            <a href="#" class="btn btn-teal rounded-pill btn-icon btn-sm">
                                                                                <span class="letter-icon">A</span>
                                                                            </a>
                                                                        </div>
                                                                        <div>
                                                                            <a href="#" class="text-body font-weight-semibold letter-icon-title">Annabelle Doney</a>
                                                                            <div class="text-muted fs-12"><span class="badge badge-mark border-primary me-1"></span> Active</div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <a href="#" class="text-body">
                                                                        <div class="font-weight-semibold">[#1183] Workaround for OS X selects printing bug</div>
                                                                        <span class="text-muted">Chrome fixed the bug several versions ago, thus rendering this...</span>
                                                                    </a>
                                                                </td>
                                                                <td class="text-center">
                                                                    <div class="list-icons">
                                                                        <div class="dropdown">
                                                                            <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu7"></i></a>
                                                                            <div class="dropdown-menu dropdown-menu-right">
                                                                                <a href="#" class="dropdown-item"><i class="icon-undo"></i> Quick reply</a>
                                                                                <a href="#" class="dropdown-item"><i class="icon-history"></i> Full history</a>
                                                                                <div class="dropdown-divider"></div>
                                                                                <a href="#" class="dropdown-item"><i class="icon-checkmark3 text-success"></i> Resolve issue</a>
                                                                                <a href="#" class="dropdown-item"><i class="icon-cross2 text-danger"></i> Close issue</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center">
                                                                    <h6 class="mb-0">16</h6>
                                                                    <div class="fs-12 text-muted line-height-1">hours</div>
                                                                </td>
                                                                <td>
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="me-3">
                                                                            <a href="#">
                                                                                <img src="" class="rounded-circle" width="32" height="32" alt="">
                                                                            </a>
                                                                        </div>
                                                                        <div>
                                                                            <a href="#" class="text-body font-weight-semibold">Chris Macintyre</a>
                                                                            <div class="text-muted fs-12"><span class="badge badge-mark border-primary me-1"></span> Active</div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <a href="#" class="text-body">
                                                                        <div class="font-weight-semibold">[#1249] Vertically center carousel controls</div>
                                                                        <span class="text-muted">Try any carousel control and reduce the screen width below...</span>
                                                                    </a>
                                                                </td>
                                                                <td class="text-center">
                                                                    <div class="list-icons">
                                                                        <div class="dropdown">
                                                                            <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu7"></i></a>
                                                                            <div class="dropdown-menu dropdown-menu-right">
                                                                                <a href="#" class="dropdown-item"><i class="icon-undo"></i> Quick reply</a>
                                                                                <a href="#" class="dropdown-item"><i class="icon-history"></i> Full history</a>
                                                                                <div class="dropdown-divider"></div>
                                                                                <a href="#" class="dropdown-item"><i class="icon-checkmark3 text-success"></i> Resolve issue</a>
                                                                                <a href="#" class="dropdown-item"><i class="icon-cross2 text-danger"></i> Close issue</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>                                    
                                        </div>
                                        <div class="tab-pane fade" id="tab-tikO">
                                            <div class="table-responsive">
                                                <table class="table table-scrollable-1 text-nowrap">
                                                    <thead>
                                                        <tr class="bg-success text-white">
                                                            <th style="width: 50px">Due</th>
                                                            <th style="width: 300px;">User</th>
                                                            <th>Description</th>
                                                            <th class="text-center" style="width: 20px;"><i class="icon-arrow-down12"></i></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="text-center">
                                                                <h6 class="mb-0">12</h6>
                                                                <div class="fs-12 text-muted line-height-1">hours</div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex align-items-center">
                                                                    <div class="me-3">
                                                                        <a href="#" class="btn btn-teal rounded-pill btn-icon btn-sm">
                                                                            <span class="letter-icon">A</span>
                                                                        </a>
                                                                    </div>
                                                                    <div>
                                                                        <a href="#" class="text-body font-weight-semibold letter-icon-title">Annabelle Doney</a>
                                                                        <div class="text-muted fs-12"><span class="badge badge-mark border-primary me-1"></span> Active</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <a href="#" class="text-body">
                                                                    <div class="font-weight-semibold">[#1183] Workaround for OS X selects printing bug</div>
                                                                    <span class="text-muted">Chrome fixed the bug several versions ago, thus rendering this...</span>
                                                                </a>
                                                            </td>
                                                            <td class="text-center">
                                                                <div class="list-icons">
                                                                    <div class="dropdown">
                                                                        <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu7"></i></a>
                                                                        <div class="dropdown-menu dropdown-menu-right">
                                                                            <a href="#" class="dropdown-item"><i class="icon-undo"></i> Quick reply</a>
                                                                            <a href="#" class="dropdown-item"><i class="icon-history"></i> Full history</a>
                                                                            <div class="dropdown-divider"></div>
                                                                            <a href="#" class="dropdown-item"><i class="icon-checkmark3 text-success"></i> Resolve issue</a>
                                                                            <a href="#" class="dropdown-item"><i class="icon-cross2 text-danger"></i> Close issue</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">
                                                                <h6 class="mb-0">16</h6>
                                                                <div class="fs-12 text-muted line-height-1">hours</div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex align-items-center">
                                                                    <div class="me-3">
                                                                        <a href="#">
                                                                            <img src="" class="rounded-circle" width="32" height="32" alt="">
                                                                        </a>
                                                                    </div>
                                                                    <div>
                                                                        <a href="#" class="text-body font-weight-semibold">Chris Macintyre</a>
                                                                        <div class="text-muted fs-12"><span class="badge badge-mark border-primary me-1"></span> Active</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <a href="#" class="text-body">
                                                                    <div class="font-weight-semibold">[#1249] Vertically center carousel controls</div>
                                                                    <span class="text-muted">Try any carousel control and reduce the screen width below...</span>
                                                                </a>
                                                            </td>
                                                            <td class="text-center">
                                                                <div class="list-icons">
                                                                    <div class="dropdown">
                                                                        <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu7"></i></a>
                                                                        <div class="dropdown-menu dropdown-menu-right">
                                                                            <a href="#" class="dropdown-item"><i class="icon-undo"></i> Quick reply</a>
                                                                            <a href="#" class="dropdown-item"><i class="icon-history"></i> Full history</a>
                                                                            <div class="dropdown-divider"></div>
                                                                            <a href="#" class="dropdown-item"><i class="icon-checkmark3 text-success"></i> Resolve issue</a>
                                                                            <a href="#" class="dropdown-item"><i class="icon-cross2 text-danger"></i> Close issue</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>                            
                                                    </tbody>
                                                </table>
                                            </div>  
                                        </div>
                                        <div class="tab-pane fade" id="tab-tikC">
                                            <div class="table-responsive">
                                                <table class="table table-scrollable-1 text-nowrap">
                                                    <thead>
                                                        <tr class="bg-danger text-white">
                                                            <th style="width: 50px">Due</th>
                                                            <th style="width: 300px;">User</th>
                                                            <th>Description</th>
                                                            <th class="text-center" style="width: 20px;"><i class="icon-arrow-down12"></i></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="text-center">
                                                                <h6 class="mb-0">12</h6>
                                                                <div class="fs-12 text-muted line-height-1">hours</div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex align-items-center">
                                                                    <div class="me-3">
                                                                        <a href="#" class="btn btn-teal rounded-pill btn-icon btn-sm">
                                                                            <span class="letter-icon">A</span>
                                                                        </a>
                                                                    </div>
                                                                    <div>
                                                                        <a href="#" class="text-body font-weight-semibold letter-icon-title">Annabelle Doney</a>
                                                                        <div class="text-muted fs-12"><span class="badge badge-mark border-primary me-1"></span> Active</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <a href="#" class="text-body">
                                                                    <div class="font-weight-semibold">[#1183] Workaround for OS X selects printing bug</div>
                                                                    <span class="text-muted">Chrome fixed the bug several versions ago, thus rendering this...</span>
                                                                </a>
                                                            </td>
                                                            <td class="text-center">
                                                                <div class="list-icons">
                                                                    <div class="dropdown">
                                                                        <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu7"></i></a>
                                                                        <div class="dropdown-menu dropdown-menu-right">
                                                                            <a href="#" class="dropdown-item"><i class="icon-undo"></i> Quick reply</a>
                                                                            <a href="#" class="dropdown-item"><i class="icon-history"></i> Full history</a>
                                                                            <div class="dropdown-divider"></div>
                                                                            <a href="#" class="dropdown-item"><i class="icon-checkmark3 text-success"></i> Resolve issue</a>
                                                                            <a href="#" class="dropdown-item"><i class="icon-cross2 text-danger"></i> Close issue</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">
                                                                <h6 class="mb-0">16</h6>
                                                                <div class="fs-12 text-muted line-height-1">hours</div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex align-items-center">
                                                                    <div class="me-3">
                                                                        <a href="#">
                                                                            <img src="" class="rounded-circle" width="32" height="32" alt="">
                                                                        </a>
                                                                    </div>
                                                                    <div>
                                                                        <a href="#" class="text-body font-weight-semibold">Chris Macintyre</a>
                                                                        <div class="text-muted fs-12"><span class="badge badge-mark border-primary me-1"></span> Active</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <a href="#" class="text-body">
                                                                    <div class="font-weight-semibold">[#1249] Vertically center carousel controls</div>
                                                                    <span class="text-muted">Try any carousel control and reduce the screen width below...</span>
                                                                </a>
                                                            </td>
                                                            <td class="text-center">
                                                                <div class="list-icons">
                                                                    <div class="dropdown">
                                                                        <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu7"></i></a>
                                                                        <div class="dropdown-menu dropdown-menu-right">
                                                                            <a href="#" class="dropdown-item"><i class="icon-undo"></i> Quick reply</a>
                                                                            <a href="#" class="dropdown-item"><i class="icon-history"></i> Full history</a>
                                                                            <div class="dropdown-divider"></div>
                                                                            <a href="#" class="dropdown-item"><i class="icon-checkmark3 text-success"></i> Resolve issue</a>
                                                                            <a href="#" class="dropdown-item"><i class="icon-cross2 text-danger"></i> Close issue</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>                            
                                                    </tbody>
                                                </table>
                                            </div>  
                                        </div>
                                    </div>                               
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <!-- VEDERE COME CREARLO COME WIDGET -->
                            <div class="card card-nobottom">
                                <div class="card-body card-body-2">
                                    <ul class="nav nav-tabs nav-tabs-highlight nav-justified mb-0">
                                        <li class="nav-item"><a href="#tab-desc" class="nav-link active" data-bs-toggle="tab"> Log Admin </a></li>
                                        <li class="nav-item"><a href="#tab-spec" class="nav-link" data-bs-toggle="tab"> Log Agenzie</a></li>
                                        <li class="nav-item"><a href="#tab-shipping" class="nav-link" data-bs-toggle="tab"> Log Utenti</a></li>
                                    </ul>
                                    <div class="tab-content">
                                        <div class="tab-pane fade show active" id="tab-desc">
                                            <div class="mb-3">
                                                <h6 class="font-weight-semibold mt-2">
                                                    <i class="icon-calendar me-2"></i>Elenco accessi di Oggi
                                                    <span class="ms-1">(93)</span>
                                                    <span class="badge badge-primary ms-auto float-end"><router-link href="#" class="d-inline-block text-white" :to="'Accessi'">tutti</router-link></span>
                                                </h6>
                                                <div class="dropdown-divider mb-2"></div>
                                                <a href="#" class="dropdown-item">
                                                    <i class="icon-file-text2"></i> And hello exotic staunch 
                                                </a>
                                                <a href="#" class="dropdown-item">
                                                    <i class="icon-file-text2"></i> That and well ecstatically
                                                </a>
                                                <a href="#" class="dropdown-item">
                                                    <i class="icon-file-text2"></i> Sheared coasted so concurrent
                                                </a>
                                                <a href="#" class="dropdown-item">
                                                    <i class="icon-file-text2"></i> Into darn intrepid belated
                                                </a>
                                            </div>
                                        </div>

                                        <div class="tab-pane fade" id="tab-spec">
                                            <div class="mb-3">
                                                <h6 class="font-weight-semibold mt-2">
                                                    <i class="icon-calendar me-2"></i>Elenco accessi di Oggi
                                                    <span class="ms-1">(93)</span>
                                                    <span class="badge badge-primary ms-auto float-end">tutti</span>
                                                </h6>
                                                <div class="dropdown-divider mb-2"></div>
                                                <a href="#" class="dropdown-item">
                                                    <i class="icon-file-text2"></i> And hello exotic staunch 
                                                </a>
                                                <a href="#" class="dropdown-item">
                                                    <i class="icon-file-text2"></i> That and well ecstatically
                                                </a>
                                                <a href="#" class="dropdown-item">
                                                    <i class="icon-file-text2"></i> Sheared coasted so concurrent
                                                </a>
                                                <a href="#" class="dropdown-item">
                                                    <i class="icon-file-text2"></i> Into darn intrepid belated
                                                </a>
                                            </div>
                                        </div>

                                        <div class="tab-pane fade" id="tab-shipping">
                                            <div class="mb-3">
                                                <h6 class="font-weight-semibold mt-2">
                                                    <i class="icon-calendar me-2"></i>Elenco accessi di Oggi
                                                    <span class="ms-1">(93)</span>
                                                    <span class="badge badge-primary ms-auto float-end">tutti</span>
                                                </h6>
                                                <div class="dropdown-divider mb-2"></div>
                                                <a href="#" class="dropdown-item">
                                                    <i class="icon-file-text2"></i> And hello exotic staunch 
                                                </a>
                                                <a href="#" class="dropdown-item">
                                                    <i class="icon-file-text2"></i> That and well ecstatically
                                                </a>
                                                <a href="#" class="dropdown-item">
                                                    <i class="icon-file-text2"></i> Sheared coasted so concurrent
                                                </a>
                                                <a href="#" class="dropdown-item">
                                                    <i class="icon-file-text2"></i> Into darn intrepid belated
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                                      

                </div>
                <div class="col-xl-2">

                    <div class="row">
                        <div class="col-6 col-md-12">
                            <div class="card card-body mb-2">
                                <div class="d-flex align-items-center">
                                    <i class="ph-package ph-2x text-success me-3"></i>
                                    <div class="flex-fill text-end">
                                        <h4 class="mb-0">{{ $root.statistiche.TOT_ORDINI }}</h4>
                                        <span class="text-muted">Totale Ordini</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-12">
                            <div class="card card-body mb-2">
                                <div class="d-flex align-items-center">
                                    <i class="ph-trophy ph-2x text-warning me-3"></i>
                                    <div class="flex-fill text-end">
                                        <h4 class="mb-0">{{ $root.statistiche.TOT_PREVENTIVI }}</h4>
                                        <span class="text-muted">Totale Preventivi</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-12">
                            <div class="card card-body mb-2">
                                <div class="d-flex align-items-center">
                                    <i class="ph-users-three ph-2x text-primary me-3"></i>
                                    <div class="flex-fill text-end">
                                        <h4 class="mb-0">652,549</h4>
                                        <span class="text-muted">Totale Clienti</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-12">
                            <div class="card card-body mb-2">
                                <div class="d-flex align-items-center">
                                    <i class="ph-handshake ph-2x text-secondary me-3"></i>
                                    <div class="flex-fill text-end">
                                        <h4 class="mb-0">652,549</h4>
                                        <span class="text-muted">Totale Agenzia</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header header-elements-inline">
                            <h6 class="card-title mb-0">Gestione Prodotto</h6>
                        </div>

                        <div class="card-body">
                            <ul class="list-unstyled mb-0">
                                <li class="mb-2">
                                    <div class="d-flex align-items-center mb-1">Totale Attività <span class="text-muted ms-auto">50%</span></div>
                                    <div class="progress" style="height: 0.375rem;">
                                        <div class="progress-bar bg-indigo" style="width: 50%">
                                            <span class="sr-only">50% Complete</span>
                                        </div>
                                    </div>
                                </li>
                                <li class="mb-2">
                                    <div class="d-flex align-items-center mb-1">Totale Hotel <span class="text-muted ms-auto">70%</span></div>
                                    <div class="progress" style="height: 0.375rem;">
                                        <div class="progress-bar bg-danger" style="width: 70%">
                                            <span class="sr-only">70% Complete</span>
                                        </div>
                                    </div>
                                </li>
                                <li class="mb-2">
                                    <div class="d-flex align-items-center mb-1">Totale Pacchetti <span class="text-muted ms-auto">80%</span></div>
                                    <div class="progress" style="height: 0.375rem;">
                                        <div class="progress-bar bg-success" style="width: 80%">
                                            <span class="sr-only">80% Complete</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex align-items-center mb-1">Totale Voli <span class="text-muted ms-auto">60%</span></div>
                                    <div class="progress" style="height: 0.375rem;">
                                        <div class="progress-bar bg-primary" style="width: 60%">
                                            <span class="sr-only">60% Complete</span>
                                        </div>
                                    </div>
                                </li>

                                <li class="mb-2">
                                    <div class="d-flex align-items-center mb-1">Totale Tessere Raspa <span class="text-muted ms-auto">50%</span></div>
                                    <div class="progress" style="height: 0.375rem;">
                                        <div class="progress-bar bg-info" style="width: 50%">
                                            <span class="sr-only">50% Complete</span>
                                        </div>
                                    </div>
                                </li>
                                <li class="mb-2">
                                    <div class="d-flex align-items-center mb-1">Totale Tessere WTP <span class="text-muted ms-auto">50%</span></div>
                                    <div class="progress" style="height: 0.375rem;">
                                        <div class="progress-bar bg-info" style="width: 50%">
                                            <span class="sr-only">50% Complete</span>
                                        </div>
                                    </div>
                                </li>
                                <li class="mb-2">
                                    <div class="d-flex align-items-center mb-1">Totale Tessere GoSkipass <span class="text-muted ms-auto">50%</span></div>
                                    <div class="progress" style="height: 0.375rem;">
                                        <div class="progress-bar bg-info" style="width: 50%">
                                            <span class="sr-only">50% Complete</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>               

                </div>
            </div>

        </div>
        <!-- /content area -->

        <!-- Modal -->
        <div class="modal fade" id="popUpNuovo" role="dialog">
            <div class="modal-dialog modal-lg"> 
                <div class="modal-content" >
                    <div class="modal-header modal-header-2 bg-secondary text-white">
                        <h6 class="modal-title modal-title-2 w-80 d-none d-sm-block"> SCHEDA </h6> 
                        <select class="form-control form-control-sm me-3" :tabindex="1" style="width: 150px;">
                            <option>y</option>
                        </select>  
                        <div class="header-elements float-end">                                                          
                            <div class="btn-group float-end">
                                <button type="button" class="btn btn-info btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fa fa-bolt fa-fw"></i> AZIONI
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-print"></i> Stampa scheda </a>
                                    <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-envelope"></i> Invia comunicazione </a> 
                                    <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-paper-plane"></i> Invia sms </a> 
                                </div>
                            </div>
                        </div>
                        <button type="button" class="close" data-bs-dismiss="modal">X</button>
                    </div>
                    <div class="modal-body" style="padding: 0 !important;">

                    
                        
                    </div>
                    <div class="modal-footer modal-footer-1 bg-light">
                        <button class="btn btn-success float-end btn-sm"><i class="far fa-plus-square fa-fw"></i> SALVA</button>                                   
                        <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">CHIUDI <i class="far fa-sign-out-alt fa-fw"></i> </button>
                    </div>  
                </div>
            </div>
        </div>

    </div>  
</template>



<script>
//import DatePicker from '@/components/utils/DatePicker.vue'
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export default {
    name:'Dashboard1',	

    components:{
        //ckeditor: ClassicEditor.component,
    },					

    props: ['todo'],		

    data:function(){
        return{
            filters:global.filters,
            t:global.t,
            utente:{},
            today: new Date().toISOString().split('T')[0],
            startInterval:{},
            arrayRisultatiRicerca:new Array(),
            /*
            testo: "ciao mamma guarda come mi diverto",
            testo2: "ciao mamma guarda come mi ffffffff",
            dataDiProva:'28/04/2022',
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            }
            */
        }
    },

    methods:{
        goToRoute: function(route){
            global.router.push(route);
        }, 

        varianti : function(){
            this.$root.appoggio.prodotto = {ID_PRODOTTO:1};
            this.$root.changeRoute('varianti');
        },

        ricercaResultHandler : function(data){
            this.arrayRisultatiRicerca = new Array();
            this.arrayRisultatiRicerca = data.RISULTATI;
        },

        testAlert : function(){   
            utils.alert.success("ciaone");
            utils.alert.warning("ciaone");
            utils.alert.info("ciaone");
            utils.alert.primary("ciaone");
            utils.alert.danger("ciaone");
        },

        openPopUp : function(){
            this.filters.openPopUp("popUpNuovo");
            
            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
                $('#logos').toggleClass('active');
            });
        } 

      
    },


    created: function () {
        this.utente = this.$root.utente;
		this.filters.wait(300,()=>{
			//$('[data-popup="tooltip"]').tooltip();
			var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
			var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
			return new bootstrap.Tooltip(tooltipTriggerEl)
			})
		});

    }, 

}
</script>