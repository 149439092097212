<template>
    
    <div class="card">
        
        <WriteEmail 
            v-if="showReply" 
            ref="writeEmail" 
            :email="replyEmailData" 
            @email-sent="handleEmailSent"
            @cancel-reply="cancelReply">
        </WriteEmail>

        <!-- Toolbar -->
        <div v-if="showReply == false" class="card-body text-center">
            <div class="btn-group">
                <button type="button" class="btn btn-light">
                    <i class="ph-arrow-bend-up-left"></i>
                    <span class="d-none d-xl-inline-block ms-2" @click="replyEmail">Reply</span>
                </button>
                <button type="button" class="btn btn-light">
                    <i class="ph-arrow-bend-double-up-left"></i>
                    <span class="d-none d-xl-inline-block ms-2" @click="replyAllEmail">Reply to all</span>
                </button>
                <button type="button" class="btn btn-light">
                    <i class="ph-arrow-bend-up-right"></i>
                    <span class="d-none d-xl-inline-block ms-2" @click="forwardEmail">Forward</span>
                </button>
            </div>
        </div>
        <!-- /toolbar -->

        <!-- Mail details -->
        <div class="card-body border-top">
            <div class="d-flex flex-column flex-lg-row">
                <a href="#" class="d-none d-lg-block me-lg-3 mb-3 mb-lg-0">
                    <div :class="['d-inline-flex', 'align-items-center', 'justify-content-center', letterColor, 'text-white', 'lh-1', 'w-20px', 'h-20px', 'rounded-pill']">
                        <span class="letter-icon">{{ initials }}</span>
                    </div>
                </a>

                <div class="flex-fill">
                    <h6 class="mb-0">{{ email.OGGETTO }} </h6>
                    <div><span class="letter-icon-title"></span> {{ email.FROM }}</div>
                </div>

                <div class="align-self-lg-center ml-lg-3 mt-3 mt-lg-0">
                    <div>12:49 pm</div>
                </div>
            </div>
        </div>
        <!-- /mail details -->


        <!-- Mail container -->
        <div class="card-body border-top">
            <div class="overflow-auto mw-100 email-container">

                <div v-html="email.CORPO_HTML"></div> 

            </div>
        </div>
        <!-- /mail container -->


        <!-- Attachments -->
        <div class="card-body border-top" v-if="email.ALLEGATI">
            <h6 class="mb-2">{{ email.ALLEGATI.length }} allegati</h6>

            <div class="hstack gap-2 flex-wrap">
                <div v-for="allegato in email.ALLEGATI" :key="allegato.nome" class="border rounded p-2 mt-2" :style="{ backgroundColor: filters.getIconsExt(allegato.extension).backgroundColor }">
                    <div class="d-inline-flex align-items-center fw-semibold my-1">
                        <i :class="[ filters.getIconsExt(allegato.extension).icon, filters.getIconsExt(allegato.extension).iconColor, 'fa-fw fa-lg me-1']"></i>
                        <a v-on:click="scanAllegato(allegato)" target="_blank">{{ allegato.nome }}</a>
                        <span class="fs-sm fw-normal text-muted ms-1">({{ allegato.extension.toUpperCase() }})</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- /attachments -->

    </div>

    
    <div class="modal fade" id="alertAllegati" data-backdrop="true" role="dialog" aria-modal="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Scansione sicurezza</h5>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="allegatoSelezionato">
                        <h4>Scansione di: {{ allegatoSelezionato.nome }}</h4>
                        <div class="progress mt-4">
                            <div class="progress-bar" 
                                :class="{
                                    'progress-bar-striped progress-bar-animated': scanProgress < 100,
                                    'bg-success': scanResult === 'safe',
                                    'bg-danger': scanResult === 'unsafe'
                                }" 
                                role="progressbar" 
                                :style="{ width: scanProgress + '%' }">
                                {{ scanProgress }}%
                            </div>
                        </div>
                        <div v-if="scanResult === 'safe'" class="alert alert-success mt-3">
                            File verificato con successo!
                        </div>
                        
                        <div v-if="scanResult === 'unsafe'" class="alert alert-danger mt-3">
                            <i class="ph-warning-circle me-2"></i>
                            File bloccato per motivi di sicurezza
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>



<script>
import WriteEmail from '@/components/WriteEmail.vue';


export default {
   
    components: {
        WriteEmail,
    },

    data(){
        return {
            apiStartup:'core/imap/getEmail',
            filters:global.filters,
            email : {},
            replyEmailData: {
                sender: '',
                receiver: '',
                subject: '',
                body: '',
                id: null,
                attachments: [],
            },
            showReply : false,
            allegatoSelezionato : {},
            scanProgress: 0,
            isScanning: false,
            scanInterval: null,
            scanResult: null
        }
    },

    methods: {
        getSchedaEmail : function (idEmail) {
            utils.ajax(this.apiStartup,{ID_EMAIL : idEmail, FK_ID_ENTE : 1 , ID_UTENTE : this.$root.utente.ID_UTENTE}, (response) => {
                let emailHtml = response.data.CORPO_HTML;
                emailHtml = this.sanitizeHtml(emailHtml);
                this.email = { ...response.data, CORPO_HTML: emailHtml };
                if (this.emailContainsDangerousContent(emailHtml)) {
                    console.warn("ATTENZIONE: L'email conteneva contenuti potenzialmente dannosi (CSS, JS o XML)!");
                } else {
                    console.log("L'email è stata pulita con successo.");
                }
                this.showReply = false;
            }); 
        }, 

        sanitizeHtml(html) {
            if (!html) return "";

            return html
                .replace(/<style[\s\S]*?<\/style>/gi, "")
                .replace(/<link[^>]+>/gi, "")
                .replace(/<script[\s\S]*?<\/script>/gi, "")
                .replace(/<\?xml[\s\S]*?\?>/gi, "")
                .replace(/\son\w+="[^"]*"/gi, "")
                .replace(/\son\w+='[^']*'/gi, "");
        },

        emailContainsDangerousContent(html) {
            return /<style[\s\S]*?<\/style>|<link[^>]+>|<script[\s\S]*?<\/script>|<\?xml[\s\S]*?\?>|\son\w+="|on\w+'/gi.test(html);
        },

        scanAllegato(allegato) {
            if (this.isScanning) return;
            
            this.isScanning = true;
            this.allegatoSelezionato = { ...allegato };
            this.scanProgress = 0;
            this.scanResult = null;
            
            this.filters.openPopUp("alertAllegati");
            const progressInterval = setInterval(() => {
                if (this.scanProgress < 90) this.scanProgress += 10;
            }, 300);
            utils.ajax('core/scan/email/allegato',{FK_ID_ENTE: 1,ID_UTENTE: this.$root.utente.ID_UTENTE,file_content: allegato.contenuto,file_name: allegato.nome},(response) => {
                clearInterval(progressInterval);
                this.scanProgress = 100;
                if (response.esito == 0) {
                    this.scanResult = 'safe';
                    setTimeout(() => {
                        this.filters.closePopUp("alertAllegati");
                        this.openAllegatiOnChrome(allegato);
                        this.isScanning = false;
                    }, 1500);
                } else {
                    this.scanResult = 'unsafe';
                    this.isScanning = false;
                }
            },
            (error) => {
                clearInterval(progressInterval);
                this.filters.closePopUp("alertAllegati");
                alert("Errore durante la scansione: " + error.messaggio);
                this.isScanning = false;
            });
        },

        chiudiEmail() {
            this.$emit('chiudiEmail'); 
        },

        openAllegatiOnChrome(allegato){
            utils.openFileOnChrome(allegato)
        },

        replyEmail() {
            this.replyEmailData = {
                FROM: this.$root.utente.EMAIL,
                TO: this.extractEmail(this.email.FROM),
                OGGETTO: "Re: " + this.email.OGGETTO,
                CORPO: "\n\n--- Original Message ---\nFrom: " + this.email.FROM +
                    "\nDate: " + this.email.DATA +
                    "\nSubject: " + this.email.OGGETTO +
                    "\n\n",
                CORPO_HTML: this.email.CORPO_HTML,
                ID_EMAIL: null,
                ATTACHMENTS: []
            };
            this.showReply = true;
        },

        replyAllEmail() {
            let recipients = [];
            if (this.email.FROM) {
                recipients.push(this.extractEmail(this.email.FROM));
            }
            if (this.email.DESTINATARI && this.email.DESTINATARI.TO) {
                recipients = recipients.concat(this.email.DESTINATARI.TO);
            }
            if (this.email.DESTINATARI && this.email.DESTINATARI.CC) {
                recipients = recipients.concat(this.email.DESTINATARI.CC);
            }
            let currentUser = this.$root.utente.EMAIL.toLowerCase();
            recipients = recipients.filter((addr, index, self) =>
                addr.toLowerCase() !== currentUser && self.indexOf(addr) === index
            );
            this.replyEmailData = {
                FROM: this.$root.utente.EMAIL,
                TO: recipients.join(', '),
                OGGETTO: "Re: " + this.email.OGGETTO,
                CORPO: "\n\n--- Original Message ---\nFrom: " + this.email.FROM +
                    "\nDate: " + this.email.DATA +
                    "\nSubject: " + this.email.OGGETTO +
                    "\n\n" + this.email.CORPO,
                ID_EMAIL: null,
                ATTACHMENTS: []
            };
            this.showReply = true;
        },

        forwardEmail() {
            this.replyEmailData = {
                FROM: this.$root.utente.EMAIL,
                TO: "",
                OGGETTO: "Fwd: " + this.email.OGGETTO,
                CORPO: "\n\n--- Forwarded message ---\nFrom: " + this.email.FROM +
                    "\nDate: " + this.email.DATA +
                    "\nSubject: " + this.email.OGGETTO +
                    "\n\n" + this.email.CORPO, 
                CORPO_HTML: this.email.CORPO_HTML,
                ID_EMAIL: null,
                ATTACHMENTS: this.email.ALLEGATI ? [...this.email.ALLEGATI] : []
            };
            this.showReply = true;
        },

        cancelReply() {
            this.showReply = false;
        },

        extractEmail(str) {
            const match = str.match(/<([^>]+)>/);
            return match ? match[1].trim() : str.trim();
        },

        getTagEmail : function(from){
            if(!from) return "";
            const match = from.match(/^(.*?)\s*<.*?>$/);
            var result = match ? match[1] : from;
            var words = result.split(/(?=[A-Z])|\s+/);
            var iniziali = words.map(word => word.charAt(0).toUpperCase()).join("");
            return iniziali
        },


    },
    computed: {
        initials() {
            return this.email && this.email.FROM ? this.filters.getInitials(this.email.FROM) : '';
        },
        letterColor() {
            return this.email && this.email.FROM ? this.filters.getColorForEmail(this.email.FROM) : 'bg-primary';
        }
    },

    
};
</script>

<style>
.email-container { all: unset; }
.icon-large { font-size: 2rem; }
</style>