<template>

    <template v-if="type == 'nav-superiore'">
        <li v-for="item in items" :key="item.ID_ITEM" class="nav-item dropdown ms-xl-1">
            <template v-if="item.children && item.children.length">
                <a v-if="item.FLG_TIPOLOGIA == 1" href="#" class="navbar-nav-link rounded dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" @click.prevent="$emit('route-click', item.ROUTE || '#')">
                    <div v-html="item.ICONA"></div>{{ item.DESCRIZIONE }}
                </a>
                <div v-else class="dropdown-divider"></div>

                <div class="dropdown-menu">
                    <SubMenu
                        v-for="child in item.children"
                            :key="child.ID_ITEM"
                            :item="child"
                            :type = "type"
                            @route-click="onRouteClick">
                    </SubMenu>
                </div>
            </template>
            <template v-else>
                <a href="#" class="navbar-nav-link rounded" @click.prevent="$emit('route-click', item.ROUTE || '#')">
                    <div v-html="item.ICONA"></div>{{ item.DESCRIZIONE }}
                </a>
        </template>
        </li>
    </template>

    <template v-else-if="type == 'nav-laterale'">
        <li v-for="item in items" :key="item.ID_ITEM" class="nav-item" :class="{'nav-item-submenu nav-item-open': item.children?.length}">
            <a href="#" class="nav-link" @click.prevent="$emit('route-click', item.ROUTE || '#')">
                <div v-html="item.ICONA"></div><span>{{ item.DESCRIZIONE }}</span>
            </a>
            <ul v-if="item.children?.length" class="nav-group-sub collapse">
                <SubMenu
                    v-for="child in item.children"
                    :key="child.ID_ITEM"
                    :item="child"
                    :type="type"
                    @route-click="onRouteClick">
                </SubMenu>
            </ul>
        </li>
    </template>
</template>
  
<script>
import SubMenu from "@/components/utils/SubMenu.vue";


export default {

    name: "MenuRoot",

    emits: ['route-click'],

    components: {
        SubMenu,
    },

    props: {
        items: {
            type: Array,
            required: true,
        },
        type: {
            type: String,
            default: 'nav-superiore',
        },
    },

    methods: {
        onRouteClick(route) {
            this.$emit("route-click", route);
        },
    },

}
</script>

<style>
   
    
.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}
    
   
</style>
