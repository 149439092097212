<template>
    <div>
        
        <div class="modal fade" id="popUpOrdinamento" role="dialog"  tabindex="-1">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-header-2 bg-light text-dark">
                        <h6 class="modal-title">Upload immagine Profilo</h6>
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">    
                        
                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="itemSelezionato.ORDINE" :tabindex="1">

                    </div>
                    <div class="modal-footer modal-footer-1 bg-light">
                        <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                        <button class="btn btn-success float-end btn-sm" v-on:click="salvaOrdinamento()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
import { computed } from 'vue'


export default {
    name:"PopupOrdinamento",
    emits:['ordinamentoAggiornato'],
    data:function(){
        return{
            filters:global.filters,
            itemSelezionato:{},
            arrayItems: new Array(),
            chiave : '',
            ordineIniziale:0
        }
    },

    methods:{
        openPopUpOrdinamento : function(item,arrayItems,chiave){
            this.itemSelezionato = utils.clone(item);   
            this.arrayItems = utils.clone(arrayItems);
            this.chiave = chiave;
            this.filters.openPopUp("popUpOrdinamento");
            this.ordineIniziale = item.ORDINE;
        },

        salvaOrdinamento : function(){
            this.arrayItems = utils.array.ordinaArray(this.arrayItems,"ORDINE");
            //ho incrementato l'ordine
            if (this.ordineIniziale < this.itemSelezionato.ORDINE){
                for (var ordineParziale = this.ordineIniziale - 1 ; ordineParziale < this.itemSelezionato.ORDINE  ; ordineParziale++){
                    if (this.arrayItems[ordineParziale][this.chiave] != this.itemSelezionato[this.chiave]){
                        --this.arrayItems[ordineParziale].ORDINE;
                    }
                    else{
                        this.arrayItems[ordineParziale].ORDINE = parseInt(this.itemSelezionato.ORDINE);
                    }                    
                }
            }
            else if (this.ordineIniziale > this.itemSelezionato.ORDINE){
                for (var ordineParziale = this.itemSelezionato.ORDINE - 1 ; ordineParziale < this.ordineIniziale  ; ordineParziale++){
                    if (this.arrayItems[ordineParziale][this.chiave] != this.itemSelezionato[this.chiave]){
                        this.arrayItems[ordineParziale].ORDINE++;
                    }
                    else{
                        this.arrayItems[ordineParziale].ORDINE = parseInt(this.itemSelezionato.ORDINE);
                    }                    
                }
            }
            this.arrayItems = utils.array.ordinaArray(this.arrayItems,"ORDINE");
            this.filters.closePopUp("popUpOrdinamento");
            this.$emit("ordinamentoAggiornato",this.arrayItems);
        }


    },

    created : function(){

       
    }
}
</script>