<template>
      
    <!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link> 
                    <a href="#" class="hidden-xs text-dark fw-semibold cursor-pointer" v-on:click="getEmails()"> Mailbox </a> 
					<span class="hidden-xs ms-1">\</span>
                    <span class="text-muted ms-1"><Translate :path="'dizionario.base.ELENCO'"></Translate> email</span>
                </h4>
				<a href="#page_header" class="hidden-lg btn btn-light align-self-center collapsed border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;" v-on:click="openWriteEmail()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1 ms-1">
							<div class="fs-sm text-muted mb-0">
								<Translate :path="'dizionario.base.BTN_NUOVA'"></Translate> Email
							</div>
							<div class="text-success fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(10)" title="Opzioni Email" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.goToLogs('EMAIL','Logs')" title="Logs Emails" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-clipboard"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(this.modulo)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-question" style="color: red;"></i>
						</a>
	                </div>
	            </div>
            </div>
		</div>
		<!-- /breadcrumb -->

        <!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#emails-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7"></i>
								<span class="hidden-lg ms-1">Email</span>
								<span class="hidden-xs ms-1"><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Email</span>
							</div>
						</a>
					</li>
					<li>
						<button type="button" class="navbar-toggler sidebar-mobile-secondary-toggle rounded-pill">
							<i class="ph-arrow-left"></i>
						</button>
					</li>
					
				</ul>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0 select-small">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a class="dropdown-item" v-on:click="markAsRead()"><i class="far fa-check-square fa-fw me-2"></i>Segna come letto</a>
											<a class="dropdown-item" v-on:click="markAsUnread()"><i class="far fa-square fa-fw me-2"></i>Segna come da leggere</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item" v-on:click="restoreMultipleEmails()"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Ripristina Email</a>
									<a href="#" class="dropdown-item" v-on:click="deleteEmails()"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina Email</a>
								</div>
							</div>
						</li>
						<li class="nav-item">

						</li>
					</ul>
				</div>

			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="emails-a">
				<div class="row">
					<div class="col-md-3 col-lg-3">
						<div class="sidebar sidebar-component sidebar-expand-lg bg-transparent shadow-none">
							<div class="sidebar-content">
								<div class="card">
									<div class="card-header border-bottom text-center">
										<button type="button" class="btn btn-primary text-center" v-on:click="getFolders()">
											<i class="ph-pencil"></i>
											<span class="d-none d-lg-inline-block ms-2">Ricevi nuove Email</span>
										</button>
									</div>
									<div class="card-body">
										<ul class="nav nav-sidebar" data-nav-type="accordion">
											<li class="nav-item-header text-muted">Navigazione Cartelle</li>
											<li class="nav-item" v-for="(folder, index) in arrayFolders" :key="index">
												<a href="#" class="nav-link" :class="{'active': folderSelezionato === folderStyleForGet(folder.name)}" v-on:click="openMailRoute(folderStyleForGet(folder.name))">
													<i class="me-2 " :class="folderIcons(folder.name)"></i>{{ folderStyle(folder.name) }}
													<div v-if="folderStyleForGet(folder.name) === 'INBOX'" class="ms-auto">
														<span class="badge bg-danger rounded-pill ms-auto" data-bs-toggle="tooltip" data-bs-placement="top" title="Nuove Email">
															{{ folder.unread }}
														</span>
														<span class="badge bg-success rounded-pill ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Email Totali">
															{{ folder.total }}
														</span>
													</div>
													<span v-else class="badge bg-success rounded-pill ms-auto" data-bs-toggle="tooltip" data-bs-placement="top" title="Email Totali">
														{{ folder.total }}
													</span>
												</a>
											</li>
											<li class="nav-item-header text-muted">&nbsp;</li>
										</ul>
									</div>
									<div class="card-footer border-top text-center">
										<button class="btn btn-flat-secondary btn-sm" v-on:click="openModalFolder()">
											<i class="ph-chats ph-sm me-1"></i>Crea nuova Cartella
										</button>
										<button class="btn btn-flat-indigo btn-sm float-end" v-on:click="openGestisciFolder()">
											<i class="ph-chats ph-sm me-1"></i>Mostra tutte
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-9 col-lg-9">

						<AllEmails ref="allEmails"
						:array-emails="arrayEmails" 
						:array-criteria="arrayCriteria" 
						:folderSelezionato="folderSelezionato"
						:array-folders = "arrayFolders"
						:pagination="search.PAGINATION.LENGTH"
						:trash-folder-full-path="trashFolderFullPath"
						:archivio-folder-full-path="archivioFolderFullPath"
						:total-count = "totalCount"
						@emailSelezionata="setEmailSelezionata" 
						@criterio-cambiato="getEmails"
						@flg-cambiato="getEmails"
						@vista-cambiata="getEmails"
						@open-compose="openComposeEmail"
						@pagination-changed="updatePagination"
						@open-restore-modal = 'restoreMultipleEmails'
						@update-folder-counter="handleFolderCounterUpdate"
						>
						</AllEmails>

					</div>
				</div>
            </div>

   		</div>
    </div>


	<div class="modal fade" id="popUpFolder" role="dialog" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title"><i class="fa fa-fw fa-key fa-fw"></i> Crea una nuova Cartella</h6>  
                    <button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
                </div>
				<div class="modal-body">

					<input type="text" class="form-control" placeholder="Nuova Folder" v-model="folderSelezionatoInput">

                </div>
				<div class="modal-footer bg-light p-1"> 
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
					<button type="button" class="btn btn-success float-end btn-sm" v-on:click="saveFolder(folderSelezionato.name)"><i class="far fa-plus-square fa-fw"></i> SALVA</button>                                   
				</div>
            </div>
        </div>
    </div>

	
	<div class="modal fade" id="popUpGestioneFolder" role="dialog" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title"><i class="fa fa-fw fa-key fa-fw"></i> Gestisci Cartelle</h6>  
                    <button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
                </div>
				<div class="modal-body">

					<!-- <div class="div_table_resp tableFixHead" id="tableFolders"> -->
					<table class="table table-bordered table-hover table-striped table-togglable text-nowrap">
						<thead class="sticky-top">
							<tr class="bg-secondary text-white">
								<th class="table-head-sm" style="width:50%;">Cartella</th>
								<th class="table-head-sm" style="width:10%;">Azioni</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(folder, index) in arrayFolders"  :key="index">
								<td>
									<a href="#" @click.prevent="openMailRoute(folderStyleForGet(folder.name))">{{ folderStyle(folder.name) }}</a>
								</td>
								<td>
									<div class="btn-group" role="group" aria-label="Basic example">
										<button data-toggle="tooltip" data-placement="top" title="Modifica la Pagina" class="btn btn-warning btn-sm float-right" v-on:click="editFolder(folder.name)"><i class="fa fa-pencil"></i></button>
										<button data-toggle="tooltip" data-placement="top" title="Elimina la Pagina" class="btn btn-danger btn-sm float-right" v-on:click="deleteFolder(folder.name)"><i class="fa fa-trash-alt"></i></button>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<!-- 	</div> -->

                </div>
				<div class="modal-footer bg-light p-1"> 
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
					<button type="button" class="btn btn-success float-end btn-sm" v-on:click="saveFolder(this.folderSelezionato)"><i class="far fa-plus-square fa-fw"></i> SALVA</button>                                   
				</div>
            </div>
        </div>
    </div>

	<div class="modal fade" id="restoreFolderModal" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Seleziona la cartella di destinazione</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <select v-model="selectedRestoreFolder" class="form-select">
                        <option v-for="folder in arrayFolders" :key="folder.name" :value="folder.name">
                            {{ folderStyle(folder.name) }}
                        </option>
                    </select>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annulla</button>
                    <button type="button" class="btn btn-primary" 
                        @click="selectedEmailsForRestore.length <= 1 ? this.$refs.allEmails.restoreEmail(emailSelezionata, selectedRestoreFolder) : confirmRestoreMultipleEmails()">
                        Conferma Ripristino
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>



<script>	 

//import DatePicker from '@/components/utils/DatePicker.vue'
import AllEmails from '@/components/AllEmails.vue';




export default {
    name:"Inbox",
	
    components:{
        //DatePicker,
		AllEmails,
    },			

    data:function(){
        return {
            apiStartup:'core/imap/getUserEmails',
            filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
            arrayEmails:new Array(),
            emailSelezionata:{},
			arrayFolders:new Array(),
			arrayCriteria: new Array(),
			folderSelezionato:{ name: 'INBOX', fullPath: "{imaps.aruba.it:993}INBOX" },
			folderSelezionatoInput:"",
			folderSelezionatoOldName:"",
			totalCount : 0,
			composeEmails: new Array(),
			start:0,
			limit: 50,
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                FROM:{TYPE:'TEXT',VALUE:''},
                OGGETTO:{TYPE:'TEXT',VALUE:''},
                CRITERIO: { TYPE: 'COMBO', VALUE: '' },
	        },
			selectedEmailsForRestore: [],
        	selectedRestoreFolder: "",
			
        }
    },

    methods:{
		updatePagination({ page, start, limit, totalCount }) {
			this.search.PAGINATION.PAGE_NUMBER = page;
			this.search.PAGINATION.LENGTH = Math.ceil(totalCount / this.search.PAGINATION.PAGE_SIZE);
			this.start = start;
			this.limit = limit;
			this.getEmails({ start: start, limit: limit });
		},

        getEmails(payload = {}) { 
			const { criterio = "ALL", folder = this.folderSelezionato.name || "INBOX", start = this.start, limit = this.limit } = payload; 
				utils.ajax(this.apiStartup, {
					FK_ID_ENTE: 1,
					ID_UTENTE: this.$root.utente.ID_UTENTE,
					FOLDER: folder,  
					CRITERIO: criterio,
					START: start,
					LIMIT: limit, 
					SESSION_TOKEN: global.utils.SESSION_TOKEN,
				}, (response) => {
				this.arrayEmails = Array.isArray(response.data.emails) ? [...response.data.emails] : [];
				this.totalCount = response.data.total
				this.search.PAGINATION.LENGTH = Math.ceil(this.totalCount / this.search.PAGINATION.PAGE_SIZE);
			});
		}, 

		handleFolderCounterUpdate({ folder, totalDelta = 0, unreadDelta = 0, importantDelta = 0 }) {
			const idx = this.arrayFolders.findIndex(f => f.name === folder);
			if (idx === -1) {
				console.error("Cartella non trovata:", folder, "in", this.arrayFolders);
				return;
			}
			
			if (idx !== -1) {
				this.arrayFolders[idx].total += totalDelta;
				if (typeof this.arrayFolders[idx].unread !== 'undefined') {
				this.arrayFolders[idx].unread += unreadDelta;
				}
				if (typeof this.arrayFolders[idx].important !== 'undefined') {
				this.arrayFolders[idx].important += importantDelta;
				}
			} else {
				console.warn("Cartella non trovata:", folder);
			}
		},

        getFolders() {
			utils.ajax('core/imap/getFolderEmails', { 
				FK_ID_ENTE: 1, 
				ID_UTENTE: this.$root.utente.ID_UTENTE 
			}, (response) => {
				this.arrayFolders = response.data;
				const inboxFolder = this.arrayFolders.find(f => 
				this.folderStyleForGet(f.name) === "INBOX"
				);
				if (inboxFolder) {
				this.folderSelezionato = {
					name: "INBOX",
					fullPath: inboxFolder.name
				};
				}
				this.search.FROM.VALUE = "";
				this.search.OGGETTO.VALUE = "";
				this.search.CRITERIO.VALUE = "";
				this.currentCriteria = "ALL";

				if (this.$refs.allEmails) {
					this.$refs.allEmails.search.FROM.VALUE = "";
					this.$refs.allEmails.search.OGGETTO.VALUE = "";
					this.$refs.allEmails.search.CRITERIO.VALUE = "";
				}
				
				this.getEmails({ criterio: "ALL", folder: this.folderSelezionato.name });
			});
		}, 

		getCriteria : function () {
            utils.ajax('core/imap/getCriteriaEmails',{FK_ID_ENTE:this.$root.idEnteCorrente, ID_UTENTE:this.$root.utente.ID_UTENTE}, (response) => {
                this.arrayCriteria = response.data;
            }); 
        },

		setEmailSelezionata(email) {
			this.emailSelezionata = { ...email };
        },

		resetEmailSelezionata() {
            this.emailSelezionata = {};
        },

		openModalFolder: function(){
			this.folderSelezionatoOldName = undefined;
			this.folderSelezionatoInput = ""
			this.filters.openPopUp("popUpFolder");
		},
		openGestisciFolder: function(){
			this.filters.openPopUp("popUpGestioneFolder");
		},
		openComposeEmail(email) {
       		console.log("Ricevuta bozza:", email);
			this.composeEmails.push({
				id: Date.now(),
				email: email, 
				isDraft: true
			});
		},

		folderStyleForGet : function (folder){
			return folder.split("}")[1];
		},

		folderStyle : function (folder){
			if(folder.includes("INBOX.")){
				return folder.split("INBOX.")[1];
			} else {
				return "inBox"
			}
		},
		
		folderIcons : function (name){
			var fold = name.split("INBOX")[1];
			switch (fold){
                case '': return 'ph-envelope-open';
                case '.Drafts': return 'ph-file-dotted';
                case '.SPAM': return 'ph-share';
                case '.Sent': return 'ph-warning-octagon';
				case '.Trash': return 'ph-trash';
                case '.Templates': return 'ph-star';
                case '.Archivio': return 'ph-star';
				default: return 'ph-folder'
            }
		},

		saveFolder : function () {
			const formattedFolderName = "INBOX." + this.folderSelezionatoInput;
			let payload = {
				FK_ID_ENTE: this.$root.idEnteCorrente,
				ID_UTENTE: this.$root.utente.ID_UTENTE,
				OLD_FOLDER: this.folderSelezionatoOldName != undefined ? this.folderStyleForGet(this.folderSelezionatoOldName) : "", 
				NEW_FOLDER: formattedFolderName 
			};
			let url = this.folderSelezionatoOldName ? 'core/imap/folder/update' : 'core/imap/folder/insert';
            utils.ajax(url,payload, (response) => {
				if(response.esito == 0){
					this.getFolders();
					this.filters.closePopUp('popUpFolder');
					utils.alert.success(response.messaggio);
				}
            }); 
        },

		deleteFolder : function(folder){
			utils.alert.confirm("Sei sicuro di voler eliminare la Cartella selezionata?",()=>{
				let payload = {FK_ID_ENTE: 1, ID_UTENTE: this.$root.utente.ID_UTENTE, FOLDER: this.folderStyleForGet(folder)}
				let url = 'core/imap/folder/delete';
				utils.ajax(url,payload, (response) => {
					if(response.esito == 0){
						this.getFolders();
						this.filters.closePopUp('popUpFolder');
						utils.alert.success(response.messaggio);
					}
				}); 
			},this.$root);
		},

		openWriteEmail() {
			this.$refs.allEmails.openOffCanvasForWrite({
				sender: this.$root.utente.EMAIL,
				receiver: '',
				subject: '',
				body: '',
				id: null,
				attachments: [],
			});
		},

		openMailRoute(folderShortName) {
			const folder = this.arrayFolders.find(f => 
				this.folderStyleForGet(f.name) === folderShortName
			);
			if (folder) {
				this.folderSelezionato = {
				name: folderShortName,
				fullPath: folder.name
				};
			} else {
				this.folderSelezionato = {
				name: folderShortName,
				fullPath: folderShortName
				};
			}
			this.getEmails();
		},

		//funzioni multiple
		markAsRead() {
			let selectedEmails = this.getSelectedEmails();
			if (selectedEmails.length == 0) {
				utils.alert.warning("Nessuna email selezionata");
				return;
			}

			let ids = selectedEmails.map(email => email.ID_EMAIL);

			utils.ajax('core/imap/email/seenMultiple', {
				FK_ID_ENTE: 1,
				ID_UTENTE: this.$root.utente.ID_UTENTE,
				IDS_EMAIL: ids,
				FOLDER: this.folderSelezionato.name
			}, (response) => {
				if (response.esito == 0) {
					selectedEmails.forEach(email => email.VISTA = true);
					this.getFolders();
					utils.alert.success("Email segnate come lette");
				}
			});
		},
		markAsUnread() {
			let selectedEmails = this.getSelectedEmails();
			if (selectedEmails.length == 0) {
				utils.alert.warning("Nessuna email selezionata");
				return;
			}

			let ids = selectedEmails.map(email => email.ID_EMAIL);

			utils.ajax('core/imap/email/unseenMultiple', {
				FK_ID_ENTE: 1,
				ID_UTENTE: this.$root.utente.ID_UTENTE,
				IDS_EMAIL: ids,
				FOLDER: this.folderSelezionato.name
			}, (response) => {
				if (response.esito == 0) {
					selectedEmails.forEach(email => email.VISTA = false);
					this.getFolders();
					utils.alert.success("Email segnate come non lette");
				}
			});
		},

		deleteEmails() {
			let selectedEmails = this.getSelectedEmails();
			if (selectedEmails.length === 0) {
				utils.alert.warning("Nessuna email selezionata");
				return;
			}
			let ids = selectedEmails.map(email => email.ID_EMAIL);
			if (this.folderSelezionato.name === "INBOX.Trash") {
				utils.alert.confirm("Sei sicuro di voler eliminare definitivamente le email selezionate?",() => {
					utils.ajax('core/imap/email/deletePermanentlyMultiple', {
						FK_ID_ENTE: 1,
						ID_UTENTE: this.$root.utente.ID_UTENTE,
						IDS_EMAIL: ids,
						FOLDER: this.folderSelezionato.name
					}, (response) => {
						if (response.esito == 0) {
							this.getFolders();
							utils.alert.success("Email eliminate definitivamente");
						}
					});
				},this.$root);
			} else {
				utils.alert.confirm("Sei sicuro di voler spostare le email selezionate nel cestino?",() => {
					utils.ajax('core/imap/email/deleteMultiple', {
						FK_ID_ENTE: 1,
						ID_UTENTE: this.$root.utente.ID_UTENTE,
						IDS_EMAIL: ids,
						FOLDER: this.folderSelezionato.name
					}, (response) => {
						if (response.esito == 0) {
							this.getFolders();
							utils.alert.success("Email spostate nel cestino");
						}
					});
				},this.$root);
			}
		},


		restoreMultipleEmails(email = null) {
			if (email) {
				this.selectedEmailsForRestore = [email.ID_EMAIL];
				this.emailSelezionata = utils.clone(email);
			} else {
				let selectedEmails = this.getSelectedEmails();
				if (selectedEmails.length === 0) {
					utils.alert.warning("Nessuna email selezionata per il ripristino");
					return;
				}
				this.selectedEmailsForRestore = selectedEmails.map(email => email.ID_EMAIL);
			}
			this.selectedRestoreFolder = ""; 
			this.filters.openPopUp("restoreFolderModal");
		},

		confirmRestoreMultipleEmails() {
			if (!this.selectedRestoreFolder) {
				utils.alert.warning("Seleziona una cartella di destinazione per il ripristino");
				return;
			}
			utils.ajax('core/imap/email/restoreMultiple', {
				FK_ID_ENTE: 1,
				ID_UTENTE: this.$root.utente.ID_UTENTE,
				IDS_EMAIL: this.selectedEmailsForRestore,
				FOLDER: this.folderSelezionato.name, // Cestino
				DESTINATION_FOLDER: this.folderStyleForGet(this.selectedRestoreFolder)
			}, (response) => {
				if (response.esito == 0) {
					this.getFolders();
					utils.alert.success("Email ripristinate con successo");
					this.filters.closePopUp("restoreFolderModal");
				}
			});
		},

		getSelectedEmails() {
       		return this.arrayEmails.filter(email => email.SELEZIONATO === 1);
   		},
        
    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayEmails,this.search);
            return arr; 
        },
		trashFolderFullPath() {
			const trash = this.arrayFolders.find(f => 
				this.folderStyleForGet(f.name).toUpperCase() === "TRASH"
			);
			return trash ? trash.name : "{imaps.aruba.it:993}INBOX.Trash";
		},
		archivioFolderFullPath() {
			const archivio = this.arrayFolders.find(f => 
			this.folderStyleForGet(f.name) === "Archivio"
			);
			return archivio ? archivio.name : "{imaps.aruba.it:993}INBOX.Archivio";
		},
  		inboxFolderFullPath() {
			const inbox = this.arrayFolders.find(f => this.folderStyleForGet(f.name) === "INBOX");
			return inbox ? inbox.name : "{imaps.aruba.it:993}INBOX";
		},
    }, 

	watch: {
		folderSelezionato: {
			handler(newVal) {
				if (newVal.name) {
					this.folderSelezionatoInput = this.folderStyle(newVal.name); 
				}
			},
			deep: true
		}
	},

    created : function(){
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
        this.utenteCorrente = utils.clone(this.$root.utente);
		this.getCriteria();
		this.getFolders();
        
    },
    
}
</script>
