<template>

    <Dashboard0 v-show="utente.FK_ID_LIVELLO == 0"></Dashboard0>
    <Dashboard1 v-show="utente.FK_ID_LIVELLO == 1"></Dashboard1>
    <Dashboard2 v-show="utente.FK_ID_LIVELLO == 2"></Dashboard2>
    <Dashboard3 v-show="utente.FK_ID_LIVELLO == 3"></Dashboard3>

</template>



<script>
import Dashboard0 from '@/views/admins/Dashboard0.vue'
import Dashboard1 from '@/views/admins/Dashboard1.vue'
import Dashboard2 from '@/views/admins/Dashboard2.vue'
import Dashboard3 from '@/views/admins/Dashboard3.vue'


export default {
    name:"Dashboard",
    
    components:{
        Dashboard0,
        Dashboard1,
        Dashboard2,
        Dashboard3,
        
    },
    
    data:function(){
        return{
            filters:global.filters,
            t:global.t,
            utente:{},
            today: new Date(),
            startInterval:{},
            arrayRisultatiRicerca:new Array(),
            testo: "ciao mamma guarda come mi diverto"
        }
    },

    props: ['todo'],

    methods:{
        goToRoute: function(route){
            global.router.push(route);
        },
        varianti : function(){
            this.$root.appoggio.prodotto = {ID_PRODOTTO:1};
            this.$root.changeRoute('varianti');
        },
        ricercaResultHandler : function(data){
            this.arrayRisultatiRicerca = new Array();
            this.arrayRisultatiRicerca = data.RISULTATI;
        },
        testAlert : function(){   
            utils.alert.success("ciaone");
            utils.alert.warning("ciaone");
            utils.alert.info("ciaone");
            utils.alert.primary("ciaone");
            utils.alert.danger("ciaone");
        }
      
    },

    created: function () {
        this.utente = utils.clone(this.$root.utente);
        global.utils.ajax('travel/home',{}, (response,status) => {
            this.$root.arrayTipologieOpzioni = response.data.OPZIONI;
            this.$root.arrayEnti = response.data.ENTI;
            this.$root.idEnteCorrente = response.data.ENTE.ID_ENTE;
            this.$root.arrayUtentiAdmin = response.data.UTENTI_ADMIN;
            this.$root.arrayCodiciIva = response.data.CODICI_IVA;
            this.$root.arrayTipoModuli = response.data.TIPO_MODULI;
            this.$root.arrayTipoFiscale = response.data.TIPO_FISCALE;
            this.$root.arrayTipoCategoria = response.data.TIPO_CATEGORIA;
            this.$root.arrayTipoContenuto = response.data.TIPO_CONTENUTO;
            this.$root.arrayModalitaCalcolo = response.data.MODALITA_DI_CALCOLO;
            this.$root.arrayPromotori = response.data.PROMOTORI;
            this.$root.arrayNetwork = response.data.NETWORK;
            this.$root.arrayAgenti = response.data.AGENTI;
            this.$root.arrayConti = response.data.CONTI;
            this.$root.arrayGeoStati = response.data.GEO_STATI;
            this.$root.arrayLivelli = response.data.LIVELLI;
            this.$root.arrayFunzionalita = response.data.FUNZIONALITA;
            this.$root.arrayGruppi = response.data.GRUPPI;
            this.$root.arrayRuoliUtente = response.data.RUOLI_UTENTE;
            this.$root.arrayRtsType = response.data.RTS_TYPE;
            
            for (var i = 0 ; i < this.$root.arrayGruppi.length ; i++) {
                this.$root.arrayGruppi[i].FLG_PROFILO = 0;
                if (this.$root.arrayGruppi[i].DESCRIZIONE.search("PROFILO: ") > -1){
                    this.$root.arrayGruppi[i].DESCRIZIONE = this.$root.arrayGruppi[i].DESCRIZIONE.replace("PROFILO: ","");
                    this.$root.arrayGruppi[i].FLG_PROFILO = 1;
                }                
            }
            this.$root.arrayTipoAgenzia = response.data.TIPO_AGENZIA;
            this.$root.arrayTipoFornitore = response.data.TIPO_FORNITORE;
            this.$root.arrayLingue = response.data.LINGUE;
            this.$root.arrayStagioni = response.data.STAGIONI;

            this.$root.arrayPraticaStato = response.data.TIPO_PRATICA_STATO;
            this.$root.arrayPraticaBook = response.data.TIPO_PRATICA_BOOK;
            this.$root.arrayPraticaTipo = response.data.TIPO_PRATICA;
            this.$root.arrayPraticaPagamento = response.data.TIPO_PRATICA_PAGAMENTO;
            this.$root.arrayPraticaDocumento = response.data.TIPO_PRATICA_DOCUMENTI;
            this.$root.statistiche = response.data.STATISTICHE;
            this.$root.arrayAllOpzioni = response.data.ALL_OPZIONI;
            this.$root.arrayGeoComuni = response.data.GEO_COMUNI;
            this.$root.arrayAeroporti = response.data.AEROPORTI;
            this.$root.COSTANTI = response.data.COSTANTI;

            global.filters.init(this.$root);
            var opzioneSidebar = this.filters.getOpzioneUtente("SIDEBAR");
            if (opzioneSidebar == 244){
                document.getElementById("btnCollapseMain").click();
                console.log("passo qui");
            }
            this.$forceUpdate(); 
        });
        return;
        if (this.$root.sidebarHasBeenResized == false){
            this.$root.sidebarHasBeenResized = true;
            setTimeout(() => {
                $('.sidebar-main-resize').click();
            },1000);
        }
        
        

    },
}
</script>