<template>    
    <div>

        <!-- Page header -->
        <div class="page-header page-header-light shadow">
            <div class="page-header-content d-lg-flex">
                <div class="d-flex">
                    <h4 class="page-title mb-0">
                        <router-link href="#" class="d-inline-block text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
                        <span class="text-muted">Dashboard</span>
                    </h4>
                    <a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
                        <i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
                    </a>
                </div>
                <div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
                    <div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
                        <div class="d-inline-flex mb-xs-1 mt-sm-0">
                            <a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(57)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
                                <i class="icon-question7" style="color: orange;"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /page header -->

        <!-- Content area -->
        <div class="content content-1">

            <div class="row">
                <div class="col-lg-3">
                    <div v-bind:class="getClasseBarra()" class="card bg-danger text-white shadow">
                        <div class="card-header card mb-0 pt-1 pb-1">
                            <h1 class="card-title mb-0">
                            <Translate :path="'dizionario.base.BENVENUTO'"></Translate> {{utente.USERNAME}}</h1>
                        </div>
                        <div class="card-body pt-1">
                            <p class="">
                                Non ci sono nuovi aggiornamenti.<br />
                                <Translate :path="'dizionario.base.SYS_PRONTO'"></Translate>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-6 col-xl-2">
                            <div class="card card-body">
                                <div class="d-flex align-items-center mb-3">
                                    <i class="icon-credit-card icon-2x text-primary opacity-75 me-3"></i>
                                    <div class="flex-fill">
                                        <h6 class="mb-0">
                                            Oggi
                                            <span class="float-end">125</span>
                                        </h6>
                                        <span class="text-muted">
                                            Tot:
                                            <span class="float-end">1980</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="progress mb-2" style="height: 0.125rem;">
                                    <div class="progress-bar bg-primary" style="width: 67%"></div>
                                </div>
                                <div class="text-primary fw-bold cursor-pointer" v-on:click="filters.openRoute('/ListCards')">Tessere RaspaClub</div>
                            </div>
                        </div>
                        <div class="col-6 col-xl-2">
                            <div class="card card-body">
                                <div class="d-flex align-items-center mb-3">
                                    <i class="icon-credit-card icon-2x text-indigo opacity-75 me-3"></i>
                                    <div class="flex-fill">
                                        <h6 class="mb-0">
                                            Oggi
                                            <span class="float-end">125</span>
                                        </h6>
                                        <span class="text-muted">
                                            Tot:
                                            <span class="float-end">1980</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="progress mb-2" style="height: 0.125rem;">
                                    <div class="progress-bar bg-indigo" style="width: 67%"></div>
                                </div>
                                <div class="text-indigo fw-bold cursor-pointer" v-on:click="filters.openRoute('/ListCards')">Tessere GoSkipass</div>
                            </div>
                        </div>
                        <div class="col-6 col-xl-2">
                            <div class="card card-body">
                                <div class="d-flex align-items-center mb-3">
                                    <i class="icon-credit-card icon-2x text-purple opacity-75 me-3"></i>
                                    <div class="flex-fill">
                                        <h6 class="mb-0">
                                            Oggi
                                            <span class="float-end">125</span>
                                        </h6>
                                        <span class="text-muted">
                                            Tot:
                                            <span class="float-end">1980</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="progress mb-2" style="height: 0.125rem;">
                                    <div class="progress-bar bg-purple" style="width: 67%"></div>
                                </div>
                                <div class="text-purple fw-bold cursor-pointer" v-on:click="filters.openRoute('/ListCards')">Tessere ViaLattea</div>
                            </div>
                        </div>
                        <div class="col-6 col-xl-2">
                            <div class="card card-body">
                                <div class="d-flex align-items-center mb-3">
                                    <i class="icon-bag icon-2x text-teal opacity-75 me-3"></i>
                                    <div class="flex-fill">
                                        <h6 class="mb-0">
                                            Oggi
                                            <span class="float-end">125</span>
                                        </h6>
                                        <span class="text-muted">
                                            Tot:
                                            <span class="float-end">{{ $root.statistiche.TOT_ORDINI }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="progress mb-2" style="height: 0.125rem;">
                                    <div class="progress-bar bg-teal" style="width: 67%"></div>
                                </div>
                                <div class="text-teal fw-bold cursor-pointer" v-on:click="filters.openRoute('/Ordini')">Tot: Pratiche</div>
                            </div>
                        </div>
                        <div class="col-6 col-xl-2">
                            <div class="card card-body">
                                <div class="d-flex align-items-center mb-3">
                                    <i class="icon-stack-text icon-2x text-warning opacity-75 me-3"></i>
                                    <div class="flex-fill">
                                        <h6 class="mb-0">
                                            Oggi
                                            <span class="float-end">125</span>
                                        </h6>
                                        <span class="text-muted">
                                            Tot:
                                            <span class="float-end">{{ $root.statistiche.TOT_PREVENTIVI }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="progress mb-2" style="height: 0.125rem;">
                                    <div class="progress-bar bg-warning" style="width: 67%"></div>
                                </div>
                                <div class="text-warning fw-bold cursor-pointer" v-on:click="filters.openRoute('/Preventivi')">Tot: Preventivi</div>
                            </div>
                        </div>
                        <div class="col-6 col-xl-2">
                            <div class="card card-body">
                                <div class="d-flex align-items-center mb-3">
                                    <i class="icon-users icon-2x text-secondary opacity-75 me-3"></i>
                                    <div class="flex-fill">
                                        <h6 class="mb-0">
                                            Oggi
                                            <span class="float-end">125</span>
                                        </h6>
                                        <span class="text-muted">
                                            Tot:
                                            <span class="float-end">1980</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="progress mb-2" style="height: 0.125rem;">
                                    <div class="progress-bar bg-secondary" style="width: 67%"></div>
                                </div>
                                <div class="text-secondary fw-bold cursor-pointer" v-on:click="filters.openRoute('/UtentiClienti')">Tot: Utenti</div>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="card mb-3">
                        <div class="card-header d-sm-flex align-items-sm-center py-sm-0" style="display: unset !important;">
                            <h5 class="py-sm-2 my-sm-1">
                                Aggiornamenti
                                <div class="header-elements float-end">
                                    <a class="daterange-ranges cursor-pointer dropdown-toggle">
                                        <i class="icon-calendar3 me-2"></i>
                                        <span class="fs-sm">8 Agosto</span>
                                    </a>
                                </div>
                            </h5>
                        </div>
                        <div class="card-body">
                            <ul class="nav nav-tabs nav-tabs-underline nav-justified mb-0" role="tablist">
                                <li class="nav-item" role="presentation"><a href="#tab-d1" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">TRANSAZIONI</a></li>
                                <li class="nav-item" role="presentation"><a href="#tab-d2" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">ORDINI</a></li>
                                <li class="nav-item" role="presentation"><a href="#tab-d3" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">PREVENTIVI</a></li>
                                <li class="nav-item" role="presentation"><a href="#tab-d4" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">REGISTRAZIONI</a></li>
                                <li class="nav-item" role="presentation"><a href="#tab-d5" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">TICKET</a></li>
                            </ul>
                            <div class="tab-content card-body">

                                <div class="tab-pane fade active show" id="tab-d1" role="tabpanel"> 
                                    <div class="bg-light mt-1">
                                        <p class="fs-lg fw-bold p-1">
                                            <i class="icon-calendar me-2"></i>Elenco Transazioni di Oggi
                                            <span class="ms-1">(93)</span>
                                        </p> 
                                    </div> 
                                    <div class="table-responsive">
                                        <div class="box-memo">
                                            <table class="table text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th class="w-100">Application</th>
                                                        <th>Time</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <a href="#" class="d-inline-block me-3">
                                                                    <img src="../../assets/images/brands/bing.png" alt="" height="36">
                                                                </a>
                                                                <div>
                                                                    <a href="#" class="text-body fw-semibold letter-icon-title">Sigma application</a>
                                                                    <div class="text-muted fs-sm">New order</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span class="text-muted">06:28 pm</span>
                                                        </td>
                                                        <td>
                                                            <strong>$49.90</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <a href="#" class="d-inline-block me-3">
                                                                    <img src="../../assets/images/brands/bing.png" alt="" height="36">
                                                                </a>
                                                                <div>
                                                                    <a href="#" class="text-body fw-semibold letter-icon-title">Alpha application</a>
                                                                    <div class="text-muted fs-sm">Renewal</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span class="text-muted">04:52 pm</span>
                                                        </td>
                                                        <td>
                                                            <strong>$90.50</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <a href="#" class="d-inline-block me-3">
                                                                    <img src="../../assets/images/brands/bing.png" alt="" height="36">
                                                                </a>
                                                                <div>
                                                                    <a href="#" class="text-body fw-semibold letter-icon-title">Delta application</a>
                                                                    <div class="text-muted fs-sm">Support</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span class="text-muted">01:26 pm</span>
                                                        </td>
                                                        <td>
                                                            <strong>$60.00</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <a href="#" class="d-inline-block me-3">
                                                                    <img src="../../assets/images/brands/bing.png" alt="" height="36">
                                                                </a>
                                                                <div>
                                                                    <a href="#" class="text-body fw-semibold letter-icon-title">Omega application</a>
                                                                    <div class="text-muted fs-sm">Support</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span class="text-muted">11:46 am</span>
                                                        </td>
                                                        <td>
                                                            <strong>$55.00</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <a href="#" class="d-inline-block me-3">
                                                                    <img src="../../assets/images/brands/bing.png" alt="" height="36">
                                                                </a>
                                                                <div>
                                                                    <a href="#" class="text-body fw-semibold letter-icon-title">Alpha application</a>
                                                                    <div class="text-muted fs-sm">Renewal</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span class="text-muted">10:29 am</span>
                                                        </td>
                                                        <td>
                                                            <strong>$90.50</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <a href="#" class="d-inline-block me-3">
                                                                    <img src="../../assets/images/brands/bing.png" alt="" height="36">
                                                                </a>
                                                                <div>
                                                                    <a href="#" class="text-body fw-semibold letter-icon-title">Alpha application</a>
                                                                    <div class="text-muted fs-sm">Renewal</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span class="text-muted">10:29 am</span>
                                                        </td>
                                                        <td>
                                                            <strong>$90.50</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="tab-d2" role="tabpanel">  
                                    <div class="bg-light mt-1">
                                        <p class="fs-lg fw-bold p-1">
                                            <i class="icon-calendar me-2"></i>Elenco Ordini di Oggi
                                            <span class="ms-1">(93)</span>
                                        </p> 
                                    </div>                                        
                                    <div class="table-responsive">
                                        <div class="box-memo">
                                            <table class="table text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 50px">Due</th>
                                                        <th style="width: 300px;">User</th>
                                                        <th>Description</th>
                                                        <th class="text-center" style="width: 20px;">
                                                            <i class="ph-dots-three"></i>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td class="text-center">
                                                            <h6 class="mb-0">12</h6>
                                                            <div class="fs-sm text-muted lh-1">hours</div>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <a href="#" class="d-inline-flex align-items-center justify-content-center bg-teal text-white lh-1 rounded-pill w-40px h-40px me-3">
                                                                    <span class="letter-icon">AD</span>
                                                                </a>
                                                                <div>
                                                                    <a href="#" class="text-body fw-semibold letter-icon-title">Annabelle Doney</a>
                                                                    <div class="d-flex align-items-center text-muted fs-sm">
                                                                        <span class="bg-danger rounded-pill p-1 me-2"></span>
                                                                        Blocker
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a href="#" class="text-body">
                                                                <div class="fw-semibold">[#1183] Workaround for OS X selects printing bug</div>
                                                                <span class="text-muted">Chrome fixed the bug several versions ago, thus rendering this...</span>
                                                            </a>
                                                        </td>
                                                        <td class="text-center">
                                                            <div class="dropdown">
                                                                <a href="#" class="text-body" data-bs-toggle="dropdown">
                                                                    <i class="ph-list"></i>
                                                                </a>
                                                                <div class="dropdown-menu dropdown-menu-end">
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-arrow-bend-up-left me-2"></i>
                                                                        Quick reply
                                                                    </a>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-clock-counter-clockwise me-2"></i>
                                                                        Full history
                                                                    </a>
                                                                    <div class="dropdown-divider"></div>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-checks text-success me-2"></i>
                                                                        Resolve issue
                                                                    </a>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-x text-danger me-2"></i>
                                                                        Close issue
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>                                            
                                </div>

                                <div class="tab-pane fade" id="tab-d3" role="tabpanel">
                                    <div class="bg-light mt-1">
                                        <p class="fs-lg fw-bold p-1">
                                            <i class="icon-calendar me-2"></i>Elenco Preventivi di Oggi
                                            <span class="ms-1">(125)</span>
                                        </p> 
                                    </div>                                        
                                    <div class="table-responsive">
                                        <div class="box-memo">
                                            <table class="table text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 50px">Due</th>
                                                        <th style="width: 300px;">User</th>
                                                        <th>Description</th>
                                                        <th class="text-center" style="width: 20px;">
                                                            <i class="ph-dots-three"></i>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td class="text-center">
                                                            <h6 class="mb-0">12</h6>
                                                            <div class="fs-sm text-muted lh-1">hours</div>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <a href="#" class="d-inline-flex align-items-center justify-content-center bg-teal text-white lh-1 rounded-pill w-40px h-40px me-3">
                                                                    <span class="letter-icon">AD</span>
                                                                </a>
                                                                <div>
                                                                    <a href="#" class="text-body fw-semibold letter-icon-title">Annabelle Doney</a>
                                                                    <div class="d-flex align-items-center text-muted fs-sm">
                                                                        <span class="bg-danger rounded-pill p-1 me-2"></span>
                                                                        Blocker
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a href="#" class="text-body">
                                                                <div class="fw-semibold">[#1183] Workaround for OS X selects printing bug</div>
                                                                <span class="text-muted">Chrome fixed the bug several versions ago, thus rendering this...</span>
                                                            </a>
                                                        </td>
                                                        <td class="text-center">
                                                            <div class="dropdown">
                                                                <a href="#" class="text-body" data-bs-toggle="dropdown">
                                                                    <i class="ph-list"></i>
                                                                </a>
                                                                <div class="dropdown-menu dropdown-menu-end">
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-arrow-bend-up-left me-2"></i>
                                                                        Quick reply
                                                                    </a>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-clock-counter-clockwise me-2"></i>
                                                                        Full history
                                                                    </a>
                                                                    <div class="dropdown-divider"></div>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-checks text-success me-2"></i>
                                                                        Resolve issue
                                                                    </a>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-x text-danger me-2"></i>
                                                                        Close issue
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="tab-d4" role="tabpanel">
                                    <div class="bg-light mt-1">
                                        <p class="fs-lg fw-bold p-1">
                                            <i class="icon-calendar me-2"></i>Elenco Registrazioni di Oggi
                                            <span class="ms-1">(12)</span>
                                        </p> 
                                    </div>                                        
                                    <div class="table-responsive">
                                        <div class="box-memo">
                                            <table class="table text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Campaign</th>
                                                        <th>Client</th>
                                                        <th>Changes</th>
                                                        <th>Budget</th>
                                                        <th>Status</th>
                                                        <th class="text-center" style="width: 20px;">
                                                            <i class="ph-dots-three"></i>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <a href="#" class="d-block me-3">
                                                                    <img src="../../assets/images/brands/facebook.svg" class="rounded-circle" width="36" height="36" alt="">
                                                                </a>
                                                                <div>
                                                                    <a href="#" class="text-body fw-semibold">Facebook</a>
                                                                    <div class="text-muted fs-sm">
                                                                        <span class="d-inline-block bg-primary rounded-pill p-1 me-1"></span>
                                                                        02:00 - 03:00
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><span class="text-muted">Mintlime</span></td>
                                                        <td><span class="text-success"><i class="ph-trend-up me-2"></i> 2.43%</span></td>
                                                        <td><h6 class="mb-0">$5,489</h6></td>
                                                        <td><span class="badge bg-primary bg-opacity-10 text-primary">Active</span></td>
                                                        <td class="text-center">
                                                            <div class="dropdown">
                                                                <a href="#" class="text-body" data-bs-toggle="dropdown">
                                                                    <i class="ph-list"></i>
                                                                </a>
                                                                <div class="dropdown-menu dropdown-menu-end">
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-chart-line me-2"></i>
                                                                        View statement
                                                                    </a>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-pencil me-2"></i>
                                                                        Edit campaign
                                                                    </a>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-lock-key me-2"></i>
                                                                        Disable campaign
                                                                    </a>
                                                                    <div class="dropdown-divider"></div>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-gear me-2"></i>
                                                                        Settings
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="tab-d5" role="tabpanel">
                                    <div class="bg-light mt-1">
                                        <p class="fs-lg fw-bold p-1">
                                            <i class="icon-calendar me-2"></i>Elenco Tickets Aperti
                                            <span class="ms-1">(11)</span>
                                        </p> 
                                    </div>                                        
                                    <div class="table-responsive">
                                        <div class="box-memo">
                                            <table class="table text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 50px">Due</th>
                                                        <th style="width: 300px;">User</th>
                                                        <th>Description</th>
                                                        <th class="text-center" style="width: 20px;">
                                                            <i class="ph-dots-three"></i>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td class="text-center">
                                                            <h6 class="mb-0">12</h6>
                                                            <div class="fs-sm text-muted lh-1">hours</div>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <a href="#" class="d-inline-flex align-items-center justify-content-center bg-teal text-white lh-1 rounded-pill w-40px h-40px me-3">
                                                                    <span class="letter-icon">AD</span>
                                                                </a>
                                                                <div>
                                                                    <a href="#" class="text-body fw-semibold letter-icon-title">Annabelle Doney</a>
                                                                    <div class="d-flex align-items-center text-muted fs-sm">
                                                                        <span class="bg-danger rounded-pill p-1 me-2"></span>
                                                                        Blocker
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a href="#" class="text-body">
                                                                <div class="fw-semibold">[#1183] Workaround for OS X selects printing bug</div>
                                                                <span class="text-muted">Chrome fixed the bug several versions ago, thus rendering this...</span>
                                                            </a>
                                                        </td>
                                                        <td class="text-center">
                                                            <div class="dropdown">
                                                                <a href="#" class="text-body" data-bs-toggle="dropdown">
                                                                    <i class="ph-list"></i>
                                                                </a>
                                                                <div class="dropdown-menu dropdown-menu-end">
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-arrow-bend-up-left me-2"></i>
                                                                        Quick reply
                                                                    </a>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-clock-counter-clockwise me-2"></i>
                                                                        Full history
                                                                    </a>
                                                                    <div class="dropdown-divider"></div>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-checks text-success me-2"></i>
                                                                        Resolve issue
                                                                    </a>
                                                                    <a href="#" class="dropdown-item">
                                                                        <i class="ph-x text-danger me-2"></i>
                                                                        Close issue
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>                                        
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-3">

                    <div class="card">
                        <div class="card-header d-flex align-items-center">
                            <h5 class="mb-0">TO DO</h5>
                            <div class="ms-auto">
                                <span class="badge bg-indigo">Oggi</span>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="box-26">
                                <div class="d-flex mb-2">
                                    <div class="me-3">
                                        <input type="checkbox" class="form-check-input" id="task1">
                                    </div>
                                    <div class="flex-fill fs-sm">
                                        <h6 class="fs-sm mb-0">
                                            <label for="task1" class="fw-semibold cursor-pointer mb-0">Resplendent much during</label>
                                            <span class="badge badge-xs bg-info bg-opacity-20 text-info float-end">Apr 28, 21:39</span>
                                        </h6>
                                        Urchin that understood yikes special ladybug
                                    </div>
                                </div>
                                <div class="d-flex mb-2">
                                    <div class="me-3">
                                        <input type="checkbox" class="form-check-input" id="task2">
                                    </div>
                                    <div class="flex-fill fs-sm">
                                        <h6 class="fs-sm mb-0">
                                            <label for="task2" class="fw-semibold cursor-pointer mb-0">Insect far hound</label>
                                        </h6>
                                        Hey where more that much meanly shivered
                                    </div>
                                </div>
                                <div class="d-flex mb-2">
                                    <div class="me-3">
                                        <input type="checkbox" class="form-check-input" id="task3">
                                    </div>
                                    <div class="flex-fill fs-sm">
                                        <h6 class="fs-sm mb-0">
                                            <label for="task3" class="fw-semibold cursor-pointer mb-0">The him father parish</label>
                                        </h6>
                                        Reran sincere said monkey one slapped
                                    </div>
                                </div>
                                <div class="d-flex mb-2">
                                    <div class="me-3">
                                        <input type="checkbox" class="form-check-input" id="task1">
                                    </div>
                                    <div class="flex-fill fs-sm">
                                        <h6 class="fs-sm mb-0">
                                            <label for="task1" class="fw-semibold cursor-pointer mb-0">Resplendent much during</label>
                                        </h6>
                                        Urchin that understood yikes special ladybug
                                    </div>
                                </div>
                                <div class="d-flex mb-2">
                                    <div class="me-3">
                                        <input type="checkbox" class="form-check-input" id="task1">
                                    </div>
                                    <div class="flex-fill fs-sm">
                                        <h6 class="fs-sm mb-0">
                                            <label for="task1" class="fw-semibold cursor-pointer mb-0">Resplendent much during</label>
                                        </h6>
                                        Urchin that understood yikes special ladybug
                                    </div>
                                </div>
                                <div class="d-flex mb-2">
                                    <div class="me-3">
                                        <input type="checkbox" class="form-check-input" id="task1">
                                    </div>
                                    <div class="flex-fill fs-sm">
                                        <h6 class="fs-sm mb-0">
                                            <label for="task1" class="fw-semibold cursor-pointer mb-0">Resplendent much during</label>
                                        </h6>
                                        Urchin that understood yikes special ladybug
                                    </div>
                                </div>
                                <div class="d-flex mb-2">
                                    <div class="me-3">
                                        <input type="checkbox" class="form-check-input" id="task1">
                                    </div>
                                    <div class="flex-fill fs-sm">
                                        <h6 class="fs-sm mb-0">
                                            <label for="task1" class="fw-semibold cursor-pointer mb-0">Resplendent much during</label>
                                        </h6>
                                        Urchin that understood yikes special ladybug
                                    </div>
                                </div>
                                <div class="d-flex mb-2">
                                    <div class="me-3">
                                        <input type="checkbox" class="form-check-input" id="task1">
                                    </div>
                                    <div class="flex-fill fs-sm">
                                        <h6 class="fs-sm mb-0">
                                            <label for="task1" class="fw-semibold cursor-pointer mb-0">Resplendent much during</label>
                                        </h6>
                                        Urchin that understood yikes special ladybug
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <a class="float-end" href="#" v-on:click="filters.openRoute('/Tasks')">Mostra tutto</a>
                        </div>
                    </div>

                </div>
                <div class="col-lg-3">
                    
                    <div class="mb-3">
                        <div class="d-flex btn-group">
                            <button type="button" class="btn btn-light w-100 flex-column rounded-0 rounded-start py-2" v-on:click="filters.openRoute('/Quota')">
                                <i class="ph-currency-circle-dollar text-purple ph-2x mb-1"></i>
                                Nuovo Ordine
                            </button>

                            <button type="button" class="btn btn-light w-100 flex-column rounded-0 py-2" v-on:click="filters.openRoute('/Preventivi')">
                                <i class="ph-note text-primary ph-2x mb-1"></i>
                                Preventivi
                            </button>

                            <button type="button" class="btn btn-light w-100 flex-column rounded-0 rounded-end py-2" v-on:click="$root.cronJob()">
                                <i class="ph-magic-wand text-danger ph-2x mb-1"></i>
                                Prima Nota
                            </button>
                        </div>									
                    </div>                  

                    <div class="card mb-3">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-4">
                                    <h5 class="mb-0">Vendite</h5>
                                </div>
                                <div class="col-4">
                                    <select class="form-select form-select-sm" id="comboAnno" onchange="aggiornaGraficoDashBoard()">
                                        <option selected="selected" value="2023">2023</option><option value="2022">2022</option><option value="2021">2021</option>
                                    </select>
                                </div>
                                <div class="col-4">
                                    <select class="form-select form-select-sm" id="comboStagione" onchange="aggiornaGraficoDashBoard()">
                                        <option value="">Stagione</option>
                                        <option value="2">2018/2019</option>
                                        <option value="3">2019/2020</option>
                                        <option value="4">2020/2021</option>
                                        <option value="5">2021/2022</option>
                                        <option value="6">2022/2023</option>
                                        <option value="7" selected="selected">2023/2024</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="svg-center" id="pie_basic_legend">
                                <svg width="120" height="120">
                                    <g transform="translate(60,60)">
                                        <g class="d3-arc d3-slice-border" style="cursor: pointer;">
                                            <path d="M3.5514757175273244e-15,58A58,58 0 0,1 -56.51120830069507,-13.05692675921303L0,0Z" style="fill: rgb(41, 182, 246);" transform="translate(0,0)"></path>
                                        </g>
                                        <g class="d3-arc d3-slice-border" style="cursor: pointer;">
                                            <path d="M-56.51120830069507,-13.05692675921303A58,58 0 0,1 57.407307263279556,8.270494107331828L0,0Z" style="fill: rgb(102, 187, 106);" transform="translate(0,0)"></path>
                                        </g>
                                        <g class="d3-arc d3-slice-border" style="cursor: pointer;">
                                            <path d="M57.407307263279556,8.270494107331828A58,58 0 0,1 1.7757378587636622e-14,58L0,0Z" style="fill: rgb(239, 83, 80);" transform="translate(0,0)"></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>	
                        </div>	
                    </div>
                    
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-4">
                                    <h5 class="mb-0">Iscrizioni</h5>
                                </div>
                                <div class="col-4">
                                    <select class="form-select form-select-sm" id="comboAnno" onchange="aggiornaGraficoDashBoard()">
                                        <option selected="selected" value="2023">2023</option><option value="2022">2022</option><option value="2021">2021</option>
                                    </select>
                                </div>
                                <div class="col-4">
                                    <select class="form-select form-select-sm" id="comboStagione" onchange="aggiornaGraficoDashBoard()">
                                        <option value="">Stagione</option>
                                        <option value="1">2017/2018</option>
                                        <option value="2">2018/2019</option>
                                        <option value="3">2019/2020</option>
                                        <option value="4">2020/2021</option>
                                        <option value="5">2021/2022</option>
                                        <option value="6">2022/2023</option>
                                        <option value="7" selected="selected">2023/2024</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="svg-center" id="pie_basic_legend">
                                <svg width="120" height="120">
                                    <g transform="translate(60,60)">
                                        <g class="d3-arc d3-slice-border" style="cursor: pointer;">
                                            <path d="M3.5514757175273244e-15,58A58,58 0 0,1 -56.51120830069507,-13.05692675921303L0,0Z" style="fill: rgb(41, 182, 246);" transform="translate(0,0)"></path>
                                        </g>
                                        <g class="d3-arc d3-slice-border" style="cursor: pointer;">
                                            <path d="M-56.51120830069507,-13.05692675921303A58,58 0 0,1 57.407307263279556,8.270494107331828L0,0Z" style="fill: rgb(102, 187, 106);" transform="translate(0,0)"></path>
                                        </g>
                                        <g class="d3-arc d3-slice-border" style="cursor: pointer;">
                                            <path d="M57.407307263279556,8.270494107331828A58,58 0 0,1 1.7757378587636622e-14,58L0,0Z" style="fill: rgb(239, 83, 80);" transform="translate(0,0)"></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>	
                        </div>	
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>



<script>
//import DatePicker from '@/components/utils/DatePicker.vue'
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export default {
    name:'Dashboard2',	

    components:{
        //ckeditor: ClassicEditor.component,
    },					

    props: ['todo'],		

        data:function(){
        return{
            filters:global.filters,
            t:global.t,
            utente:{},
            today: new Date().toISOString().split('T')[0],
            startInterval:{},
            arrayRisultatiRicerca:new Array(),
        }
    },

    methods:{
        goToRoute: function(route){
            global.router.push(route);
        }, 

        varianti : function(){
            this.$root.appoggio.prodotto = {ID_PRODOTTO:1};
            this.$root.changeRoute('varianti');
        },

        ricercaResultHandler : function(data){
            this.arrayRisultatiRicerca = new Array();
            this.arrayRisultatiRicerca = data.RISULTATI;
        },

        testAlert : function(){   
            utils.alert.success("ciaone");
            utils.alert.warning("ciaone");
            utils.alert.info("ciaone");
            utils.alert.primary("ciaone");
            utils.alert.danger("ciaone");
        },

        openRoute : function(route){
            global.router.push(route);
        },
        
        getClasseBarra : function(){
            var colore = this.filters.getOpzioneUtente("TOPBAR");
            switch (colore){
                case 234:
                    return "bg-danger";
            }
            return "bg-info";
        }
            
    },

    created: function () {
        this.utente = this.$root.utente;

    },


}
</script>