<template>

    <!-- Page header -->
    <div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
					<a href="#" class="text-dark fw-semibold cursor-pointer" v-on:click="getTasks()"> Tasks </a> \
					<span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Cose da Fare</span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
						
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;" v-on:click="nuovaTask()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1 ms-1">
							<div class="fs-sm text-muted mb-1">
								<Translate :path="'dizionario.base.BTN_NUOVA'"></Translate> Task
							</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(90)" title="Opzioni Tasks" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(90)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(90)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#tasks-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Tasks
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation" v-show="livello == 5">
						<a href="#tasks-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1" v-on:click="statisticheStartup();">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity me-2"></i><span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.BTN_STATISTICHE'" class="me-1"></Translate></span>
							</div>
						</a>
					</li>
				</ul>
				
				<a class="rounded btn bg-danger bg-opacity-20 btn-sm-light me-2" v-on:click="getTasks()" v-show="ordineHasChanged">Annulla ordinamento</a>
				<a class="rounded btn bg-success bg-opacity-20 btn-sm-light" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</a>
 
				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Task selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti i Task</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina selezionati</a>
								</div>
							</div>
						</li>
						<li class="nav-item ms-3">
							<SelectLingue class="form-select form-select-sm" @change="getTasks()" v-model="currentLang">
							</SelectLingue>						
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
    <!-- /page header -->

	<!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="tasks-a">
				<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="task-a">
									<div class="row">
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Utente" v-model="search.ID_TASK.VALUE" :tabindex="7" />
										</div>
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
										</div>
										
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
											
										</div>
										<div class="col-6 col-md-1 col-lg-7 mbx-1">

										</div>
										<div class="col-6 col-md-1 col-lg-1">
											<select class="form-select form-select-sm" data-placeholder="Stato" v-model="search.FLG_ATTIVO.VALUE" :tabindex="13">
												<option value="">Tutti </option>   
												<option value="1"> &nbsp; Attivati</option>
												<option value="0"> &nbsp; Disattivati</option>
											</select>
										</div>
										<div class="col-6 col-md-1 col-lg-1">
			                                <a href="#collapse-a" class="btn btn-flat-info btn-sm w-100 collapsed" data-bs-toggle="collapse" v-on:click="filters.resizeGrid('collapse-a','tableFixHead','box-card')" role="button" aria-expanded="false">
			                                    + FILTRI
											</a>
										</div>
									</div>	
								</form>
							</div>
						</div> 
					</div>
				
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="tableFixHead" id="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">

									</tr>
								</thead>
								<draggable tag="tbody" @end="ordineChangeHandler($event)">
									<tr v-for="task in paginatedList.arr" v-bind:key="task.ID_TASK">
										<td class="td-class text-center">
											<ISwitch @toggle="updateStatoTask(task)" :size="'sm'" v-model="task.FLG_ATTIVO" style="z-index: 0;"></ISwitch>
										</td>
										<td class="td-class text-center">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="editMenu(task)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Voce Menu</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteMenu(task)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Voce Menu</a>
												</div>
											</div>
										</td>
									</tr>
								</draggable>
							</table>
						</div>

					    <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
					            <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">TASKS: {{ paginatedList.count }}</span> 
					            </div>
					        </div>
							<div class="col-9 col-md-6" :tabindex="27">
					            <div class="float-end">
					                <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
					            </div>
					        </div>
					        <div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
					                <option value="5">5</option>
					                <option value="10">10</option>
					                <option value="25">25</option>
					                <option value="50">50</option>
					                <option value="75">75</option>
					                <option value="100">100</option>
					                <option value="250">250</option>
					                <option value="500">500</option>
					            </select>
					        </div>
					    </div>
					</div>
				</div>
			</div>

			<div class="tab-pane fade" id="tasks-b">


			</div>


		</div>
	</div>	


	<!-- START MODALI -->
	<div class="modal fade" id="modalNote" role="dialog" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
					<h5 class="modal-title">Menu</h5>
					<button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
                </div>
				<div class="modal-body">

					

				</div>
				<div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
					<button type="button" class="btn btn-secondary float-end" v-on:click="saveTask()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>
				</div>
			</div>
		</div>
	</div> 

    <div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right" data-bs-backdrop="false" role="dialog" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
			<h6 class="modal-title" style="width: 65% !important;">SCHEDA INFORMATIVA: </h6>  
			<div class="btn-group float-end" :tabindex="2" v-show="livello == 5">
				<a href="#" class="badge bg-yellow text-dark p-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Comunicazioni</a>
				<div class="dropdown-menu dropdown-position dropdown-menu-end" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);">
					<a href="#" class="dropdown-item"><i class="fal fa-print me-2"></i> Stampa scheda </a>
					<a href="#" class="dropdown-item"><i class="fal fa-envelope me-2"></i> Invia comunicazione </a>
					<a href="#" class="dropdown-item"><i class="fal fa-paper-plane me-2"></i> Invia sms </a>
				</div>
			</div> 	
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">

			

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>     
        </div>  
	</div>	 


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import SelectLingue  from '@/components/utils/SelectLingue.vue'
import { VueDraggableNext } from 'vue-draggable-next'


export default {
    name:"Tasks",	

    components:{
        Pagine,
        ISwitch,
		SelectLingue,
        draggable: VueDraggableNext,

    },

    data:function(){
        return {
            apiStartup:'travel/tasks',
            filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            arrayTasks:new Array(),
            taskSelezionato:{},
			dataCreazioneRange:{},
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_TASK:{TYPE:'TEXT',VALUE:''},                   
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 

	        },
			currentLang:0,

        }
		
    },

    methods:{
        getTasks : function(){
            utils.ajax(this.apiStartup,{}, (response) => {
                this.arrayTasks = response.data;
            });  
        },

        openTask : function(task){
            this.$root.taskSelezionato = utils.clone(task);
            global.router.push("Task");
        },
		
        newTask : function(){ 
			this.taskSelezionato = {ID_TASK:0,FLG_ATTIVO:1};
            this.filters.openPopUp("popUpTask");
        },

        editTask : function(task){            
            utils.ajax('travel/task',task, (response) => {
                this.taskSelezionato = utils.clone(response.data);
                this.filters.openPopUp("popUpTask");
            });             
        },

        deleteTask : function(task){
            utils.alert.confirm("Sicuro di voler eliminare questo Task?",() => {
                utils.ajax('travel/basic/delete',{className:"task","id" : task.ID_TASK}, (response) => {
                    this.getTasks();
                });
            });
        },

        saveTask : function(){
            var api = this.taskSelezionato.ID_TASK > 0 ? "travel/task/update" : "travel/task/insert";
            utils.ajax(api,this.taskSelezionato, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    utils.ajax(this.apiStartup,task, (response) => {                        
                        this.filters.closePopUp("popUpTask");
                        
                    }); 
                }
            });
        }, 
		
        updateStatoTask : function(task){
            setTimeout(() => {
                utils.ajax("travel/task/stato/update",type, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }  
                }); 
            },250);
        }, 

		updateTaskFlgVisibile : function(task){
			type.FLG_VISIBILE = type.FLG_VISIBILE == 1 ? 0 : 1;
			utils.ajax("travel/task/update",type, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					type.FLG_VISIBILE = type.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
		},

        getBtnStatusClass : function(task){
            switch (task.FK_ID_STATO){
                case 1:
                    return 'badge-success'
                case 2:
                    return 'badge-primary'
                case 3:
                    return 'badge-info'
				case 4:
                    return 'badge-warning'
                case 5:
                    return 'badge-yellow'
                case 6:                
                    return "badge-secondary";
                case 7:
                case 8:
                    return 'badge-danger'
                default :
                    return 'badge-light'
            }
        },

        getTipoBookClass : function(task){
            switch (task.FK_ID_TIPO){
                case 1:
                    return "badge-danger";
                case 2:
                    return "badge-info";                   
                case 3:
                    return "badge-warning";
                case 4:
                    return "badge-primary";                   
            }
            return "";
        },

        getDateRange : function(task){
            $('.daterange-predefined').daterangepicker(
                {
                    startDate: moment().subtract(29, 'days'),
                    endDate: moment(),
                    minDate: '01/01/2014',
                    maxDate: '12/12/2019',
                    dateLimit: { days: 60 },
                    timePicker: true,
                    parentEl: '.content-inner',
                    showDropdowns: true,
                    ranges: {
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    }
                },
                function(start, end) {
                    $('.daterange-predefined span').html(start.format('MMMM D, YYYY') + ' &nbsp; - &nbsp; ' + end.format('MMMM D, YYYY'));
                    $.jGrowl('Date range has been changed', { header: 'Update', theme: 'bg-primary text-white', position: 'center', life: 1500 });
                }
            );

            // Display date format
            $('.daterange-predefined span').html(moment().subtract(29, 'days').format('MMMM D, YYYY') + ' &nbsp; - &nbsp; ' + moment().format('MMMM D, YYYY'));
        },

        openSchedaTask : function(task){
            this.$refs.schedaTask.openSchedaTask(task);
        },

		openRoute : function(route){
			global.router.push(route);
		},

		ordineChangeHandler : function(event){
			this.arrayTasks = this.filters.draggableChangeHandler(event,this.arrayTasks,this.search,"ID_TASK");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arrayTasks.length ; i++){
					arr.push({"ID": this.arrayTasks[i].ID_TASK,"O": this.arrayTasks[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'Stato',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getTasks();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},

		openOffCanvas : function(){
			var myOffcanvas = document.getElementById('panel_right')
			var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
			bsOffcanvas.show();
		},

		openSchedaVisualizzazioneTask : function(utente,tab = ""){
			this.taskSelezionato = utils.clone(task);	
			this.$refs.schedaVisualizzazioneTask.openSchedaVisualizzazioneTask(task,tab);
		},

		datePickerChangeHandler : function(){
			this.search.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
			if (this.dataCreazioneRange != null && this.dataCreazioneRange.length > 0){
				this.search.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRange[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRange[1])};
			}
		},

		dataCreazioneRangeChangeHandler : function(){
            this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
            if (this.dataCreazioneRangeFatt != null && this.dataCreazioneRangeFatt.length > 0){
                this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRangeFatt[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRangeFatt[1])};
            }
        },


    },

    computed: {
        paginatedList(){
			var arr = utils.inGrid(this.arrayTasks,this.search);
	        return arr;

            if (this.search.FK_ID_STATO.VALUE == 0){
                var arr = utils.clone(this.arrayTasks);
                for (var i = arr.length -1 ; i > -1 ; --i){
                    if (arr[i].FK_ID_STATO > 5){
                        arr.splice(i,1);
                    }
                }
                return utils.inGrid(arr,this.search);
            }
            else{
	            var arr = utils.inGrid(this.arrayTasks,this.search);
	            return arr;
            }            
        },

        getTotali(){
			var attive = 0;
            var inattive = 0; 
			for (var i = 0; i < this.arrayTasks.length; i++) {
				if (this.arrayTasks[i].FLG_ATTIVO == 1) {
					attive++;
				} else {
					inattive++;
				}
            }
            return {
                COUNT:this.arrayTasks.length,
				ADVOK:attive,
				ADVKO:inattive
            }
        },

    },

	watch:{
	
    }, 

    created : function(){
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.ordineHasChanged = false;
        this.getTasks();
    }

}
</script>