<template>

    <div class="p-3">
        <div v-for="(notifica,index) in arrayNotifiche" :key="index" class="d-flex align-items-start mb-3" :class="notificaClass(notifica.ID_NOTIFICA)">
           
                <a href="#" class="status-indicator-container me-3">
                    <img src="./../assets/images/face11.jpg" class="w-40px h-40px rounded-pill" alt="">
                    <span class="status-indicator bg-success"></span>
                </a>
                <div class="flex-fill">
                    <a href="#" class="fw-semibold">{{ notifica.USERNAME }}</a> - {{ notifica.TITOLO }}<br />
                    {{ notifica.DESCRIZIONE }}
                    <div class="my-2">
                        <button class="badge bg-success badge-xs me-1" v-on:click="leggiNotifica(notifica.ID_NOTIFICA,$root.utente.ID_UTENTE)">
                            <i class="ph-checks ph-sm me-1"></i> Letto
                        </button>
                    </div>
                    <div class="fs-sm text-muted mt-1">{{ notifica.ORARIO }}</div>
                </div>
            
        </div>        
    </div>

</template>



<script>

export default {
    name:"Notifiche",

    props:{
        mostra: {
            type: Boolean,
            required: true
        },
        arrayNotifiche: {
            type: Array,
            required: true
        }
    }, 

    data() {
        return {
            isAnimated : new Array(),
        };
    },

    methods: {
        /*
        openSchedaNotifiche() {
            console.log('Recupero delle notifiche dal server...');
            utils.ajax('shared/notifiche', {FK_ID_UTENTE : this.$root.utente.ID_UTENTE}, (response) => {
                if (response && response.esito == 0) {
                this.notifiche = response.data;
                }
            },false);
        },
        */

        /*
        riceviNotifica(notifica) {
            console.log('Nuova notifica ricevuta tramite socket:', notifica);
            this.$forceUpdate();
            /*this.notifiche.unshift({
                ...notifica,
                ORARIO: new Date().toLocaleString()
            });
            
            //this.salvaNotificaInDb(notifica);
        },
        */

        handleNewNotification(){
            if(!this.mostra){
                global.utils.alert.info("C'è una nuova Notifica per te");
                this.$emit('getNotifiche');
            }
            else{
                this.$emit('getNotifiche');
            }
        },

        leggiNotifica(idNotifica,idUtente) {
            utils.ajax('shared/notifiche/leggiNotifica', {FK_ID_NOTIFICA : idNotifica, FK_ID_UTENTE : idUtente}, (response) => {
                if (response.esito == 0) {
                    console.log('Notifica Letta');
                    this.$emit('getNotifiche');
                    this.isAnimated[idNotifica] = !this.isAnimated[idNotifica];
                } 
                else {
                    console.error('Errore nella marcatura delle Notifiche', response);
                }
            },false);
        },

        notificaClass(id){
            return this.isAnimated[id] ? 'card animate__animated animate__backOutRight' : 'card';
        }
        

       
    },

    beforeUnmount() {
        this.$root.appoggio.socket.off('newNotification', this.handleNewNotification);
    },  

    watch: {
        /*
        mostra(val) {
            if (val) {
                this.openSchedaNotifiche(); // Recupera le notifiche
                this.$root.appoggio.socket.on('newNotification', this.openSchedaNotifiche); // Ascolta l'evento
            } else {
                this.$root.appoggio.socket.off('newNotification', this.openSchedaNotifiche); // Rimuovi il listener
            }
        }
        */

        mostra(val){
            //this.$emit('getNotifiche');
        }
    },

    created() {
        if(this.$root.appoggio.socket || this.appoggio) {
            this.$root.appoggio.socket.on('newNotification', this.handleNewNotification);
        }
    }, 

    
};

</script>
