<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
					<a href="#" class="d-none d-lg-inline-block text-dark fw-semibold cursor-pointer"> Utenti </a> <span class="d-none d-lg-inline-block">\</span>
					<span class="text-muted ms-1">Gestione Logs </span> 
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>	 
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">

					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;">
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(this.modulo)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#logs-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7"></i> 
								<span class="d-lg-none ms-1">Accessi</span>
								<span class="d-none d-lg-inline-block ms-12"><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate> Accessi</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#logs-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-code"></i>
								<span class="d-lg-none ms-1">Errori</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate> Errori</span>
							</div>
						</a>
					</li>
					<li class="nav-item d-lg-none ms-auto">
						<a href="#profile_nav" class="navbar-nav-link navbar-nav-link-icon rounded-pill rounded-pill-small collapsed" data-bs-toggle="collapse" aria-expanded="false">
							<i class="ph-caret-down collapsible-indicator"></i>
						</a>
					</li>
				</ul>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0 select-small">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
							</div>
						</li>
						<li class="nav-item">

						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->
	
	<!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="logs-a">
				<div class="card shadow mb-xs-3 mb-lg-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
		                <div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="utente-a">
									<div class="row "> 
										<div class="col-12 col-md-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Username" :tabindex="2" v-model="search.USERNAME.VALUE">
										</div>
										<div class="col-12 col-md-2 mbx-1">
											<Datepicker model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" v-model="search.DATA_ACCESSO.VALUE"></Datepicker>
										</div>	  
										<div class="d-none d-sm-block col-md-6 mbx-1">
										</div>
										<div class="col-12 col-md-1 mbx-1">
											<select class="form-control form-control-sm" data-placeholder="Seleziona Anno">
												<option>Anno</option>
			
											</select>
										</div>
										<div class="col-md-1 mbx-1">
											<a class="btn btn-secondary btn-sm btn-block" v-on:click="getAccessi()">
												<span style="font-weight: 500;">Aggiorna</span>				
											</a>          
										</div>  
									</div>
								</form>
							</div>
						</div> 
					</div>
				
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="div_table_resp tableFixHead" id="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable text-nowrap"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:10%;"><span >Utente</span></th>
										<th class="table-head-sm" style="width:10%;"><span>Data e ora</span></th>
										<th class="table-head-sm" style=""><span>Session Id</span></th>
										<th class="table-head-sm" style="width:10%;"><span>Ip</span></th>
										<th class="table-head-sm" style="width:5.4%;">Azioni</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="accesso in paginatedList.arr" v-bind:key="accesso.ID_ACCESSO">
										<td class="td-class fs-sm" style=""><span class="badge bg-indigo">{{accesso.USERNAME}}</span></td>
										<td class="td-class fs-sm" style=""><span class="text-primary fw-bold cursor-pointer">{{filters.formatDateTime(accesso.DATA_ACCESSO)}}</span></td>
										<td class="td-class fs-sm" style=""><span>{{accesso.SESSION_ID}}</span></td>
										<td class="td-class fs-sm" style=""><span>{{accesso.INDIRIZZO_IP}}</span></td>
										<td class="td-class text-center fs-sm">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="openStackTrace(accesso)"><i class="fad fa-pencil fa-fw me-2"></i>| Visualizza Stack</a>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>     
						</div>

		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end pag-mobile">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>
					</div>	
				</div>	
			</div>

		</div>
	</div>	


	<!-- START MODALI -->
	<div class="modal fade" id="popUpStackTrace" role="dialog" tabindex="-1">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-danger text-white">
					<h6 class="modal-title">Log dettaglio Accesso</h6>
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-md-2 form-group-1"><label class="font-weight-semibold">Utente:</label></div>
						<div class="col-md-10 form-group-1"><input type="text" class="form-control" v-model="accessoSelezionato.USERNAME" /></div>
						<div class="col-md-2"><label class="font-weight-semibold">Data accesso:</label></div>
						<div class="col-md-10"><p>{{filters.formatDateTime(accessoSelezionato.DATA_ACCESSO)}}</p></div>
					</div>                      
					<pre class="box_error" style="font-size:10px">
						{{accessoSelezionato.SESSION_ID}}
					</pre>
				</div>
				<div class="modal-footer">

				</div>
			</div>
		</div>
	</div>



</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import Datepicker from '@vuepic/vue-datepicker'


export default {
    name:"Accessi",	

    components:{
        Pagine,
		Datepicker,
    },

    data: function () {
        return {   
            filters:global.filters,
            arrayAccessi: new Array(),
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                ESITO:{TYPE:'COMBO',VALUE:''},
                USERNAME:{TYPE:'TEXT',VALUE:''},
                SESSION_ID:{TYPE:'TEXT',VALUE:''},
                DATA_ACCESSO:{TYPE:'DATE',VALUE:''},
            },
            accessoSelezionato:{} 

        }
    },

    methods: {
        getAccessi: function () {
            utils.ajax("core/admin/accessi",{}, (response) => {
                this.arrayAccessi = response.data;
            });
        },	 

        getStringErrore:function(_accesso){
            var item = _accesso.context;
            if (item == undefined)
                return "";
            if (item.hasOwnProperty("SESSION_ID")){
                return item.SESSION_ID;
            }
            else {
                if (item.hasOwnProperty("query")){
                    return item.accesso ;
                }
                return "";
            }
    	}, 

        openStackTrace:function(accesso){
			this.filters.openPopUp("popUpStackTrace"); 
            this.accessoSelezionato = utils.clone(accesso);
            console.log(this.accessoSelezionato);
            if (this.accessoSelezionato.context.hasOwnProperty("SESSION_ID")){
                this.accessoSelezionato.stackTrace = this.accessoSelezionato.context.stackTrace;
            }
            else {
                if (this.accessoSelezionato.context.hasOwnProperty("query")){
                    this.accessoSelezionato.stackTrace = this.accessoSelezionato.context.query + "\n\n---\n" + this.accessoSelezionato.context.parametri.join(",") ;
                }
            }
        }, 

        updateStatoAccessi : function(accessi){
            setTimeout(() => {
                utils.ajax('core/admin/accessi/stato/update',utente, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
						this.filters.closePopUp("popUpUtente"); 
                    }  
                }); 
            },250);
        },

    },	 

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayAccessi,this.search);
            return arr;
        }  

    },	   

    created: function () {
        this.getAccessi();
    },

}
</script>

<style scoped>

	/* XS */
	@media (min-width: 362px) and (max-width: 575px) {
		.rounded-pill-small { padding: 0.17rem; top: 3px; background-color: #F3F4F6; }
		.select-small { width: 100%; flex-direction: row !important; flex: 1 1 auto !important; }

		.div_table_resp {  overflow: scroll; }
		.div_table_resp table { border-spacing: 0; }
		.div_table_resp th:nth-child(1) { left: 0; width: 10px; min-width: 10px; }
		.div_table_resp td:nth-child(1) { background: #fefefe; z-index: 1; }
		.div_table_resp th:nth-child(1) { z-index: 300;}
		.div_table_resp th:nth-child(1), .checkbox { position: relative; z-index: 1; }
	
	
	}
</style>