<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
					<a href="#" class="d-none d-lg-inline-block text-dark fw-semibold cursor-pointer" v-on:click="getAziende()"> Aziende </a> <span class="d-none d-lg-inline-block">\</span>
					<span class="text-muted ms-1"><Translate :path="'dizionario.base.ELENCO'"></Translate> {{tipoName}}</span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">	

					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;" v-on:click="nuovaAzienda()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1 ms-1">
							<div class="fs-sm text-muted mb-1">
								<div v-if="tipo == 1"><Translate :path="'dizionario.base.BTN_NUOVA'"></Translate> Agenzia</div>
								<div v-else><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Fornitore</div>
							</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a v-show="this.modulo != 2" href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(tipo == 1 ? 24 : 25)" title="Opzioni Azienda" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(tipo == 1 ? 24 : 25)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(tipo == 1 ? 24 : 25)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#aziende-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7"></i>
								<span class="d-lg-none ms-1">{{tipoName}}</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate> {{tipoName}}</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation" v-show="tipo == 1">
						<a href="#aziende-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-calendar"></i>	 
								<span class="d-lg-none ms-1">Visite</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Visite</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#aziende-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1" v-on:click="statisticheStartup();">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i> 
								<span class="d-lg-none ms-1">Stats.</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate></span>
							</div>
						</a>
					</li>
					<li class="nav-item d-lg-none ms-auto">
						<a href="#profile_nav" class="navbar-nav-link navbar-nav-link-icon rounded-pill rounded-pill-small collapsed" data-bs-toggle="collapse" aria-expanded="false">
							<i class="ph-caret-down collapsible-indicator"></i>
						</a>
					</li>
				</ul>

 
				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0 select-small">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Aziende selezionate</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti le Aziende</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina Aziende</a>
								</div>
							</div>
						</li>
						<li class="nav-item">

						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->

	<!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="aziende-a">
				<div class="card shadow mb-xs-3 mb-lg-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="azienda-a">
									<div class="row ">
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Azienda" v-model="search.ID_PERSONA_GIURIDICA.VALUE" :tabindex="7" />
										</div>	
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
											<select class="form-select form-select-sm" v-model="search.FK_ID_TIPOLOGIA_AZIENDA.VALUE" data-placeholder="Tipologia azienda" :tabindex="14" >
												<option v-bind:value="''">Tipologia</option>
												<option v-for="t in arrayTipologieAzienda" v-bind:value="t.ID_OPZIONE_TIPOLOGICA" v-bind:key="t.ID_OPZIONE_TIPOLOGICA" v-show="t.FLG_ATTIVO == 1" > &nbsp; {{ t.DESCRIZIONE }}</option>
											</select>
										</div>
										<div class="col-12 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Azienda" v-model="search.DENOMINAZIONE.VALUE" :tabindex="9" />
										</div>
										<div class="col-12 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Email Azienda" v-model="search.EMAIL.VALUE" :tabindex="9" />
										</div>
										<div class="col-12 col-md-4 col-lg-4">
											<div class="row ">
												<div class="col-6 col-md-4 col-lg-4 mbx-1">
													<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="P. IVA Azienda" v-model="search.PARTITA_IVA.VALUE" :tabindex="9" />
												</div>
												<div v-show="tipo == 1" class="col-6 col-md-4 col-lg-4 mbx-1">
													<select class="form-select form-select-sm" v-show="tipo == 1" data-placeholder="Promotori" :tabindex="13">
														<option>Promotori</option>
													</select>
												</div>
												<div v-show="tipo == 1" class="col-12 col-md-4 col-lg-4 mbx-1">
													<select class="form-select form-select-sm" v-model="search.FK_ID_NETWORK.VALUE" data-placeholder="Network" :tabindex="14" v-show="tipo == 1">
														<option v-bind:value="''" >Network</option>
														<option v-for="net in arrayNetwork" v-bind:value="net.ID_OPZIONE_TIPOLOGICA" v-bind:key="net.ID_OPZIONE_TIPOLOGICA"> &nbsp; {{ net.DESCRIZIONE }}</option>
													</select>
												</div>
											</div>
										</div>
										<div class="col-6 col-md-1 col-lg-1">
											<select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="15" v-model="search.FLG_ATTIVO.VALUE">
												<option value="">Tutti </option>   
												<option value="1"> &nbsp; Attivate</option>
												<option value="0"> &nbsp; Disattivate</option>
											</select>
										</div>
										<div class="col-6 col-md-1 col-lg-1">
			                                <a href="#collapse-a" class="btn btn-flat-info btn-sm w-100 collapsed" data-bs-toggle="collapse" v-on:click="filters.resizeGrid('collapse-a','tableFixHead','box-card')" role="button" aria-expanded="false">
			                                    + FILTRI
											</a>
										</div>  
									</div>
									<div class="collapse" id="collapse-a" style="">
										<div class="row">
											<div class="col-12 col-md-2 col-lg-2 mt-1">
												<Datepicker autocomplete="off" class="bg-light" style="width:100%" v-model="dataCreazioneRange" @update:model-value="datePickerChangeHandler()"  range multi-calendars :format="'dd/MM/yyyy HH:mm'" auto-apply  :partial-range="false" placeholder="Data registrazione" :tabindex="2" />
											</div> 
											<div class="col-md-6 col-lg-6">
												<div class="row"> 
													<div class="col-12 col-md-4 col-lg-4 mt-1">
														<Select2 :w="250" :api="'core/geo/regioni/select2'" :apiselected="'core/basic/element?className=Regione&ID=ID_REGIONE&DESCRIZIONE=NOME&select2=1'" placeholder="Regione" :tabindex="18"></Select2>
													</div>
													<div class="col-12 col-md-4 col-lg-4 mt-1">
														<Select2 :w="250" :api="'core/geo/comuni/select2'" :apiselected="'core/basic/element?className=Comune&ID=ID_COMUNE&DESCRIZIONE=NOME&select2=1'" placeholder="Città" :tabindex="19"></Select2>
													</div>
													<div class="col-6 col-md-2 col-lg-2 mt-1">
														<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Cap" :tabindex="10" v-model="search.EMAIL.VALUE" />
													</div>
													<div class="col-6 col-md-2 col-lg-2 mt-1">
														<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Provincia" :tabindex="10" v-model="search.EMAIL.VALUE" />
													</div>
												</div>
											</div>
											<div class="col-md-4 col-lg-4">
												<div v-show="tipo == 1" class="row"> 
													<div class="col-6 col-md-3 col-lg-3 mt-1">
														<select class="form-select form-select-sm" data-placeholder="Sito Web" :tabindex="27">
															<option value="">Sito </option>   
															<option value="mapo"> &nbsp; MapoTravel</option>
															<option value="mapoworld"> &nbsp; MapoWorld</option>
														</select>
													</div>
													<div class="col-6 col-md-3 col-lg-3 mt-1">
														<select class="form-select form-select-sm" data-placeholder="Gruppo" :tabindex="25">
															<option value="">Gruppo </option>   
															<option value="1"> &nbsp; Attivate</option>
															<option value="0"> &nbsp; Disattivate</option>
														</select>
													</div>
													<div class="col-6 col-md-3 col-lg-3 mt-1">
														<select class="form-select form-select-sm" data-placeholder="Comm" :tabindex="26">
															<option value="">Comm </option>   
															<option value="1"> &nbsp; Attivate</option>
															<option value="0"> &nbsp; Disattivate</option>
														</select>
													</div>
													<div class="col-6 col-md-3 col-lg-3 mt-1">
														<select class="form-select form-select-sm" data-placeholder="Visite" :tabindex="27">
															<option value="">Visite </option>   
															<option value="1"> &nbsp; Attivate</option>
															<option value="0"> &nbsp; Disattivate</option>
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div> 
					</div>
				
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="div_table_resp tableFixHead" id="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable text-nowrap"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllAziende" v-on:click="filters.selectAll('chkAllAziende',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:6%;"> #<br /><span class="float-end"> </span></th>
										<th class="table-head-sm text-center" style="width:4%;"> Img </th>
										<th class="table-head-sm" style="">
											<span v-if="tipo == 1">Nome {{tipoName}}<br />Contatti</span>
											<span v-else>Nome {{tipoName}}<br />Tipologia</span>
										</th>
										<th class="table-head-sm" style="width:17%;">
											<span v-if="tipo == 1">Promotore /<br />Gruppo - Pratiche</span>
											<span v-else>Referente /<br />Contatti</span>
										</th>
										<th class="table-head-sm" style="width:17%;">Data Registrazione /<br />Citt&agrave; / Regione</th>
										<th class="table-head-sm" style="width:17%;">Fatturato</th>
										<th class="table-head-sm text-center" style="width:5%;">Stato</th>
										<th class="table-head-sm text-center" style="width:5%;">Azioni</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="azienda in paginatedList.arr" v-bind:key="azienda.ID_PERSONA_GIURIDICA">
										<td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="azienda.SELEZIONATO" class=""> 
										</td>
										<td class="td-class fs-sm">
											<span>{{azienda.ID_PERSONA_GIURIDICA}}</span><br />
											<small class="badge bg-light text-primary float-end">&nbsp;</small>
										</td>
										<td class="td-class text-center">
											<img v-if="azienda.ID_AZIENDA > 0 && azienda.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('AZIENDE',azienda.URL_AVATAR)" class="squared" style="height: 40px;" alt="" />
											<span v-if="azienda.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto squared fs-sm" style="height: 40px !important; line-height: 10px; padding-top: 8px; font-size: 0.6rem !important;">No<br />Photo</span>										
										</td>
										<td class="td-class fs-sm">
											<span v-show="azienda.NOTE != ''"><span class="badge badge-xs bg-danger text-danger bg-opacity-20 cursor-pointer me-2 mb-0" title="Note Azienda" data-bs-toggle="tooltip" data-bs-placement="top" v-on:click="showNote(azienda)"><i class="fal fa-info fa-fw"></i></span></span>
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="openOffCanvas();openSchedaVisualizzazioneAzienda(azienda)">{{azienda.ALIAS}}</span><br />
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;"><i class="far fa-share-alt fa-fw"></i> {{azienda.DESC_TIPOLOGIA_PRODOTTO}}</span>
											<span v-show="tipo == 1" class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;"><i class="far fa-user fa-fw"></i> Barbara Marangi</span>
										</td>
										<td class="td-class fs-sm">
											<div v-show="tipo == 1">
												<span class="text-dark">{{azienda.PROMOTORI}}</span>
												<span class="float-end"><i class="far fa-eye fa-fw text-muted" v-bind:style="getStileColore(azienda)" data-bs-toggle="tooltip" data-bs-placement="top" :title="'Visitata'"></i></span><br />
												<span class="text-muted">Gruppo</span>
											</div>
											<div v-show="tipo == 2"> 
												<span class="text-dark">Barbara Marangi</span><br />
												<span class="badge bg-light badge-xs text-dark me-1" style="border: 1px #dddddd solid;" data-bs-toggle="tooltip" data-bs-placement="top" :title="azienda.EMAIL" v-bind:href="`mailto:${azienda.EMAIL}`"><i class="fal fa-envelope fa-fw"></i> Email</span>
												<span class="badge bg-light badge-xs text-dark" style="border: 1px #dddddd solid;" data-bs-toggle="tooltip" data-bs-placement="top" :title="azienda.TELEFONO" v-bind:href="`tel:${azienda.TELEFONO}`"><i class="fal fa-phone fa-fw"></i> Tel.</span>
											</div>
										</td>
										<td class="td-class fs-sm">	
											<span class="badge bg-light badge-xs text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Data di Registrazione"><i class="fal fa-calendar-alt fa-fw"></i> {{filters.formatDate(azienda.DATA_CREAZIONE)}}</span><br />
											<span class="text-muted me-1" v-show="azienda.FK_ID_NAZIONALITA == 118">{{ azienda.GEO_REGIONE }} - {{ azienda.GEO_COMUNE }}</span>
											<span class="text-muted me-1" v-show="azienda.FK_ID_NAZIONALITA != 118">{{ azienda.COMUNE }} - {{ azienda.GEO_STATO }} </span>
										</td>
										<td class="td-class fs-sm">
											<div><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> <span class="double-items">Fatturato {{ annoCorrente }}:</span> <a href="#" class="double-items" v-on:click="openOffCanvas();openSchedaVisualizzazioneAzienda(azienda,'ordini')"> {{ azienda.FATTURATO_ANNO_CORRENTE }} </a></div>
											<div><i class="ph-clock fs-base lh-base align-top text-danger me-1"></i> <span class="double-items">Fatturato {{ annoPrecedente }}:</span> <a href="#" class="double-items" v-on:click="openOffCanvas();openSchedaVisualizzazioneAzienda(azienda,'ordini')"> {{ azienda.FATTURATO_ANNO_PREC }} </a></div>
										</td>
										<td class="td-class text-center">
											<ISwitch @toggle="updateStatoAzienda(azienda)" v-model="azienda.FLG_ATTIVO" :size="'sm'" style="z-index: 0;"></ISwitch>
										</td>
										<td class="td-class text-center">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="modificaAzienda(azienda)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Azienda</a>
													<a href="#" class="dropdown-item" v-on:click="modificaUtenti(azienda)"><i class="fad fa-8 fa-fw me-2"></i><span class="text-muted me-1">|</span>Elenco Agenti</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="login(agente)"><i class="fad fa-sign-in-alt fa-fw me-2"></i><span class="text-muted me-1">|</span>Login diretto</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteAzienda(azienda)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Azienda</a>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2"><span v-if="tipo == 1">AGENZIE</span><span v-else>FORNITORI</span>: {{ paginatedList.count }}</span> 
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">FATTURATO: {{ filters.formattaImporto(getTotali.FATTURATO) }}</span>
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end pag-mobile">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div> 
						</div>
					</div>
				</div>
			</div>

			<div v-show="tipo == 1" class="tab-pane fade" id="aziende-b">
				<div class="card shadow mb-0">
					<div class="collapse show">
						<div class="card-body">
							<div class="alert alert-2 alert-secondary border-0">
								<form id="azienda-b" action="#">
									<div class="row ">
										<div class="col-6 col-md-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Azienda" :tabindex="7" v-model="search.ID_PERSONA_GIURIDICA.VALUE" />
										</div> 
										<div class="col-12 col-md-2">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Agenzia" :tabindex="9" v-model="search.DENOMINAZIONE.VALUE" />
										</div>
										<div class="col-12 col-md-2">
											<Datepicker autocomplete="off" class="bg-light" style="width:253px" v-model="dataCreazioneRange" @update:model-value="datePickerChangeHandler()"  range multi-calendars   :format="'dd/MM/yyyy HH:mm'" auto-apply  :partial-range="false" :tabindex="2" placeholder="Seleziona Range Date" />
										</div>
										<div class="col-6 col-md-2">
											<select class="form-select form-select-sm" data-placeholder="Promotori" :tabindex="13" v-show="tipo == 1">
												<option>Promotori</option>
											</select>
										</div>
										<div class="col-6 col-md-2">
											<select class="form-control form-control-sm" v-model="search.FK_ID_NETWORK.VALUE" data-placeholder="Network" :tabindex="14" v-show="tipo == 1">
												<option v-bind:value="''" >Network</option>
												<option v-for="net in arrayNetwork" v-bind:value="net.ID_OPZIONE_TIPOLOGICA" v-bind:key="net.ID_OPZIONE_TIPOLOGICA" >{{ net.DESCRIZIONE }}</option>
											</select>
										</div>
										<div class="col-4 col-md-1">
											<select class="form-control form-control-sm" data-placeholder="Visite" :tabindex="27">
												<option value="">Visite </option>   
												<option value="1"> &nbsp; Attivate</option>
												<option value="0"> &nbsp; Disattivate</option>
											</select>
										</div>
										<div class="col-6 col-md-1">
											<select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="15" v-model="search.FLG_ATTIVO.VALUE">
												<option value="">Tutti </option>   
												<option value="1"> &nbsp; Attivate</option>
												<option value="0"> &nbsp; Disattivate</option>
											</select>
										</div>
										<div class="col-6 col-md-1">
											<a href="#collapse-b" class="btn btn-secondary btn-sm btn-block collapsed" data-bs-toggle="collapse" aria-expanded="false" :tabindex="16">
												<span style="font-weight: 500;"><i class="far fa-plus fa-fw"></i> FILTRI</span>
											</a>
										</div>  
									</div>
									<div class="collapse" id="collapse-b" style="">
										<div class="row">
											<div class="col-12 col-md-2">
												<Select2 :w="250" :api="'core/geo/stati/select2'" :apiselected="'core/basic/element?className=Stato&ID=ID_STATO&DESCRIZIONE=NOME&select2=1'" placeholder="Seleziona Nazione" :tabindex="17"></Select2>
											</div>
											<div class="col-6 col-md-2">
												<Select2 :w="250" :api="'core/geo/regioni/select2'" :apiselected="'core/basic/element?className=Regione&ID=ID_REGIONE&DESCRIZIONE=NOME&select2=1'" placeholder="Seleziona Regione" :tabindex="18"></Select2>
											</div>
											<div class="col-6 col-md-2">
												<Select2 :w="250" :api="'core/geo/comuni/select2'" :apiselected="'core/basic/element?className=Comune&ID=ID_COMUNE&DESCRIZIONE=NOME&select2=1'" placeholder="Seleziona Citt&agrave;" :tabindex="19"></Select2>
											</div>
											<div class="col-md-6">
											</div>  
										</div>
									</div>
								</form>
							</div>
						</div> 
					</div>
				
					<div class="card-body box-card mt-0 mb-0">
						<div class="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
								<thead>
									<tr>
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllVisite" v-on:click="filters.selectAll('chkAllVisite',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:6%;"> # </th>
										<th class="table-head-sm" style="">Nome {{tipoName}}</th>
										<th class="table-head-sm" style="width:15%;">Citt&agrave; /<br />Regione</th>
										<th class="table-head-sm" style="width:15%;">Promotore</th>
										<th class="table-head-sm" style="width:15%;">Data Visita</th>
										<th class="table-head-sm" style="width:5%;">Azioni</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="azienda in paginatedList.arr" v-bind:key="azienda.ID_PERSONA_GIURIDICA">
										<td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="azienda.SELEZIONATO" class=""> </td>
										<td class="fs-sm">
											{{azienda.ID_PERSONA_GIURIDICA}}<br />
											&nbsp;
										</td>
										<td class="">
											<span class="text-primary fw-bold text-uppercase cursor-pointer" v-on:click="openSchedaAzienda(azienda)">{{azienda.ALIAS}}</span><br />
											<span data-popup="tooltip" :title="azienda.EMAIL" class="badge bg-light rounded-0 badge-xs fs-sm text-dark me-1" style="border: 1px #dddddd solid; padding: 0.18rem 0.3rem !important;" v-bind:href="`mailto:${azienda.EMAIL}`"><i class="fal fa-envelope fa-fw"></i> Email</span>
											<span data-popup="tooltip" :title="azienda.TELEFONO" class="badge bg-light rounded-0 badge-xs fs-sm text-dark me-1" style="border: 1px #dddddd solid; padding: 0.18rem 0.3rem !important;" v-bind:href="`tel:${azienda.TELEFONO}`"><i class="fal fa-phone fa-fw"></i> Tel.</span>
										</td>
										<td class="fs-sm">
											<span class="text-dark me-1" v-if="azienda.FK_ID_NAZIONALITA == 118">
												{{ azienda.GEO_COMUNE }} ({{ azienda.GEO_PROVINCIA }})<br />{{ azienda.GEO_REGIONE }}
											</span>
											<span class="text-dark me-1" v-if="azienda.FK_ID_NAZIONALITA != 118">
												{{ azienda.COMUNE }}<br />{{ azienda.GEO_STATO }}
											</span>
										</td>
										<td class="fs-sm">
											{{azienda.PROMOTORI}}
										</td>
										<td class="fs-sm">
											<br />
											<div class="badge badge-danger badge-xs fs-sm text-center cursor-pointer mb-0" v-on:click="showNote(azienda)" style="padding: 0.20rem 0.3rem !important;"><i class="fal fa-info fa-fw"></i> Note</div>
										</td>
										<td class="text-center">
											<div class="list-icons" :tabindex="26">
												<div class="dropdown">
													<a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
													<div class="dropdown-menu dropdown-menu-right">
														<a href="#" class="dropdown-item" v-on:click="modificaAzienda(azienda)"><i class="far fa-pencil fa-fw"></i> Modifica Azienda</a>
														<a href="#" class="dropdown-item" v-on:click="modificaUtenti(azienda)"><i class="fad fa-8 fa-fw me-2"></i><span class="text-muted me-1">|</span>Elenco Agenti</a>
														<div class="dropdown-divider"></div>
														<div class="dropdown-divider"></div>
														<a href="#" class="dropdown-item" v-on:click="deleteAzienda(azienda)"><i class="far fa-trash fa-fw"></i> Elimina Azienda</a>
													</div>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

							<div class="row page-bottom">
								<div class="col-8 col-md-11" :tabindex="27">
								<div class="float-end">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)" ></Pagine>
								</div>
							</div>
								<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="tab-pane fade" id="aziende-c">

				<div class="row">
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-users ph-2x text-primary me-3"></i>
								<div class="flex-fill text-end">
									<h4 class="mb-0">{{ getTotali.COUNT }}</h4>
									<span class="text-muted">Totale {{tipoName}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-thumbs-up ph-2x text-success me-3"></i>

								<div class="flex-fill text-end">
									<h4 class="mb-0">{{ getTotali.AZIENDE_CON_ORDINI }}</h4>
									<span class="text-muted">{{tipoName}} attivi</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-thumbs-down ph-2x text-danger me-3"></i>
								<div class="flex-fill text-end">
									<h4 class="mb-0">{{  getTotali.AZIENDE_SENZA_ORDINI }}</h4>
									<span class="text-muted">{{tipoName}} 0 pratiche</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0">{{  getTotali.AZIENDE_KO }}</h4>
									<span class="text-muted">{{tipoName}} bannati</span>
								</div>
								<i class="ph-house-simple ph-2x text-danger ms-3"></i>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0">{{ filters.formattaImporto(getTotali.FATTURATO) }} </h4>
									<span class="text-muted">Tot. Fatturato {{ annoCorrente}} </span>
								</div>
								<i class="ph-sketch-logo ph-2x text-indigo ms-3"></i>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0">{{ filters.formattaImporto(getTotali.FATTURATO_PRECEDENTE) }}</h4>
									<span class="text-muted">Tot. Fatturato {{ annoPrecedente}}  </span>
								</div>
								<i class="ph-globe-hemisphere-east ph-2x text-purple ms-3"></i>
							</div>
						</div>
					</div>
				</div>
				
				<Statistiche :ref="'statistiche'" :modalita="tipo == 1 ? 2 : 3 "></Statistiche>

			</div>

			<SchedaAzienda :ref="'schedaAzienda'" @aziendaSalvata="getAziende()" :tipo="tipo" ></SchedaAzienda>

		</div>
	</div>	


	<!-- START MODALI -->
	<div class="modal fade" id="modalNote" role="dialog" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
					<h6 class="modal-title"><i class="fa fa-fw fa-key fa-fw"></i> NOTE IMPORTANTI</h6>
                    <button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
				</div>
				<div class="modal-body">

					<div class="textarea rounded" v-html="aziendaSelezionata.NOTE"></div>

				</div>
				<div class="modal-footer bg-light p-1">                                
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
				</div>
			</div>
		</div>
	</div> 

	<div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right" data-bs-backdrop="false" role="dialog" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
			<h6 class="modal-title" style="width: 65% !important;">SCHEDA INFORMATIVA: </h6>  
			<div class="btn-group float-end" :tabindex="2">
				<a href="#" class="badge bg-yellow text-dark p-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Comunicazioni</a>
				<div class="dropdown-menu dropdown-position dropdown-menu-end" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);">
					<a href="#" class="dropdown-item"><i class="fal fa-print me-2"></i> Stampa scheda </a>
					<a href="#" class="dropdown-item"><i class="fal fa-envelope me-2"></i> Invia comunicazione </a>
					<a href="#" class="dropdown-item"><i class="fal fa-paper-plane me-2"></i> Invia sms </a>
				</div>
			</div> 	
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">

			<SchedaVisualizzazioneAzienda :ref="'schedaVisualizzazioneAzienda'" @modificaAzienda="modificaAzienda($event)"></SchedaVisualizzazioneAzienda>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>     
        </div>  
	</div>

	
</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import Select2 from '@/components/utils/Select2.vue'
import Datepicker from '@vuepic/vue-datepicker' 
import SchedaAzienda from '@/components/SchedaAzienda.vue'	
import SchedaVisualizzazioneAzienda from '@/components/SchedaVisualizzazioneAzienda.vue'
import Statistiche from '@/components/Statistiche.vue'	


export default {
    name:"Aziende",	

    props:['tipo'],	 

    components:{
        Pagine,
        ISwitch,
        Select2,
        Datepicker,
		SchedaAzienda,
		SchedaVisualizzazioneAzienda,
		Statistiche
    },

    data: function () {
        return {   
            apiStartup:'travel/aziende',
            filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            livelloMaxFiltroSocieta: 2,
            arrayAziende: new Array(),	
            aziendaSelezionata:{personaFisica:{}},
            aziendaCorrente:{},
			modulo:0,
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                ID_PERSONA_GIURIDICA:{TYPE:'TEXT',VALUE:''},
				DENOMINAZIONE : {TYPE:'TEXT',VALUE:''},
                EMAIL:{TYPE:'TEXT',VALUE:''},
                NOME:{TYPE:'TEXT',VALUE:''},
				SESSO:{TYPE:'TEXT',VALUE:''},
                COGNOME:{TYPE:'TEXT',VALUE:''},
				CODICE_FISCALE:{TYPE:'TEXT',VALUE:''},
				ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
				DATA_CREAZIONE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},
				PARTITA_IVA:{TYPE:'TEXT',VALUE:''},
				INDIRIZZO:{TYPE:'TEXT',VALUE:''},
				CAP:{TYPE:'TEXT',VALUE:''},
				FK_ID_TIPOLOGIA_AZIENDA:{TYPE:'COMBO',VALUE:''},
				FK_ID_NETWORK:{TYPE:'COMBO',VALUE:''},							
                FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'},

            },
            selectedMenuItem:{},
			dataCreazioneRange:{},
			dataCreazioneRangeFatt:{},
            arrayCampiPopUpAzienda : new Array(),
			arrayNetwork:new Array(),
			arrayTipologieAzienda:new Array(),
			statsAgenzie:{},
			searchStatisticheOrdini:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},
				FK_ID_PROVINCIA:{TYPE:'COMBO',VALUE:''},
				FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''},
				FK_ID_TIPO:2,
				FLG_GRUPPI:{TYPE:'COMBO',VALUE:''},
				FK_ID_NETWORK:{TYPE:'COMBO',VALUE:''},					
            },
			arrayStatisticheOrdiniFatt : new Array(),
			searchStatisticheOrdiniFatt:{
				PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},
				FK_ID_PROVINCIA:{TYPE:'COMBO',VALUE:''},
				FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''},
				FK_ID_TIPO:2,
				FLG_GRUPPI:{TYPE:'COMBO',VALUE:''},
				FK_ID_NETWORK:{TYPE:'COMBO',VALUE:''},
				DATA_CREAZIONE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},	
			}
		
        }
    },

    methods: {
        getAziende: function () {
            utils.ajax(this.apiStartup,{FK_ID_TIPO:this.tipo,ANNO:this.search.ANNO_ORDINE.VALUE}, (response) => {
                this.arrayAziende = response.data;
				utils.ajax("travel/azienda/tipologiche",{TIPO:this.tipo}, (response) => {
					this.arrayNetwork = utils.array.select(response.data,"FK_ID_TIPOLOGICA",this.$root.COSTANTI.TIPOLOGICA_AGENZIA_NETWORK);
					this.arrayTipologieAzienda = utils.array.select(response.data,"FK_ID_TIPOLOGICA",this.tipo == 1 ? this.$root.COSTANTI.TIPOLOGICA_AGENZIA_TIPOLOGIE : this.$root.COSTANTI.TIPOLOGICA_FORNITORI_TIPOLOGIE);
				});
            }); 
        },				  

        nuovaAzienda:function(){
			this.aziendaSelezionata = {ID_AZIENDA:0,personaGiuridica:{},FK_ID_ENTE:this.$root.idEnteCorrente,FLG_ATTIVO:1,FK_ID_TIPO:this.tipo};
            this.$refs.schedaAzienda.openSchedaAzienda(this.aziendaSelezionata);
        },		 

        modificaAzienda : function(azienda){
            this.$refs.schedaAzienda.openSchedaAzienda(azienda);
        },	

        updateStatoAzienda : function(azienda){
            setTimeout(() => {
                utils.ajax('travel/azienda/stato/update?tipo='+this.tipo,azienda, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }  
                }); 
            },250);
        },  
      
        resetPassword : function(azienda){
            this.aziendaSelezionata = utils.clone(azienda);
            this.filters.openPopUp("modalPassword");  
        },

        doResetPassword : function(){
			this.filters.closePopUp("modalPassword"); 
            utils.ajax('core/admin/azienda/password/reset',this.aziendaSelezionata, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }  
            }); 
        },
        
        showNote : function(azienda){
            this.aziendaSelezionata = utils.clone(azienda);
			this.filters.openPopUp("modalNote"); 
        },

		login : function(agente){
			utils.ajax('travel/agente/login',{ID_UTENTE:utente.ID_UTENTE}, (response) => {
				if (response.esito == 0){
					window.location.reload();
				}
			});
		},

        getTipologia: function(azienda){
            if(this.FK_ID_PERSONA_GIURIDICA = 0){
                return 'Privato';
            } 
            else {
                return 'Aziendale';
            } 
        },

		logAzioni : function(agente){	
			this.utenteSelezionato = utils.clone(agente);
			this.filters.openPopUp("popUpLogAzioniUtente"); 			
		},

		aggiornaLogAzioni : function(){
			utils.ajax('core/agente/azioni',{ID_UTENTE:this.utenteSelezionato.ID_UTENTE,DATA_DA:this.searchAzioni.DATA_CREAZIONE.VALUE_FROM,DATA_A:this.searchAzioni.DATA_CREAZIONE.VALUE_TO}, (response) => {
				console.log(response.data);
				this.arrayAzioniUtente = response.data;
			});			
		},	

		apriDettaglioAzione : function(agente){
			this.azioneSelezionata = azione;
			this.filters.openPopUp("popUpDettaglioLogAzioniAzienda");	
		},

		getStileColore : function(azienda){
			return {color:azienda.COLORE};
		},

		openOffCanvas : function(){
			var myOffcanvas = document.getElementById('panel_right')
			var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
			bsOffcanvas.show();
		},

		openSchedaVisualizzazioneAzienda : function(azienda,tab = ""){
			this.aziendaSelezionata = utils.clone(azienda);
			this.$refs.schedaVisualizzazioneAzienda.openSchedaVisualizzazioneAzienda(azienda,this.tipo,tab);
		}, 

		datePickerChangeHandler : function(){
			this.search.DATA_ADD = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
			if (this.dataCreazioneRange != null && this.dataCreazioneRange.length > 0){
				this.search.DATA_ADD = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRange[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRange[1])};
			}
		}, 

		dataCreazioneRangeChangeHandler : function(){
			this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
			if (this.dataCreazioneRangeFatt != null && this.dataCreazioneRangeFatt.length > 0){
				this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRangeFatt[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRangeFatt[1])};
			}
		},

		statisticheStartup : function(){
			this.$refs.statistiche.statisticheStartup();
		},
        
    },	

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayAziende,this.search);
            return arr;
        },	

		paginatedListFatt(){
            var arr = utils.inGrid(this.arrayStatisticheOrdiniFatt,this.searchStatisticheOrdiniFatt);
            return arr;
        },
		
        tipoName(){
            switch (this.tipo){
                case 1:		return 'Agenzie';
                case 2:		return 'Fornitori';
            }
			return "";
        },		 

        iconTipo(){
            switch (this.tipo){
                case 1:		return 'fa-hotel';
                case 2:		return 'fa-hotel';
            }
			return "";
        },

		getTotali(){
			var fatturato = 0;
			var fatturatoT1 = 0;
			var attive = 0;
            var inattive = 0; 
			var arrayAziende = utils.filter(this.arrayAziende,this.search);
			for (var i = 0; i < arrayAziende.length; i++) {
				fatturato += parseFloat(arrayAziende[i].FATTURATO_ANNO_CORRENTE);
				fatturatoT1 += parseFloat(arrayAziende[i].FATTURATO_ANNO_PREC);
				if (arrayAziende[i].FLG_ATTIVO == 1) {
					attive++;
				} else {
					inattive++;
				}
            }
			var entitaConOrdini = 0;
			if (this.$refs.statistiche != undefined && this.$refs.statistiche != null ){
				entitaConOrdini = this.$refs.statistiche.statsAziende.TOT_AZIENDE_CON_ORDINI;
			}
            return {
                COUNT:this.arrayAziende.length,
				AZIENDE_CON_ORDINI:entitaConOrdini,
				AZIENDE_SENZA_ORDINI:parseFloat(this.arrayAziende.length) - parseFloat(entitaConOrdini),
				FATTURATO:fatturato,
				FATTURATO_PRECEDENTE:fatturatoT1,
				AZIENDE_OK:attive,
				AZIENDE_KO:inattive,
            }
        },

		annoCorrente : function(){
			return new Date().getFullYear();
		},

		annoPrecedente : function(){
			return new Date().getFullYear() - 1;
		}

    },

	watch:{
	
	},

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
        this.aziendaCorrente = utils.clone(this.$root.azienda);
        this.getAziende();
		switch (this.tipo){
			case 2:
				this.modulo = 25;
				break;
			case 1:
				this.modulo = 24;
				break;
		}
        
    },

}
</script>

<style scoped>
	.grafico { min-height: 451px; }

	/* XS */
	@media (min-width: 362px) and (max-width: 575px) {
		.rounded-pill-small { padding: 0.17rem; top: 3px; background-color: #F3F4F6; }
		.select-small { width: 100%; flex-direction: row !important; flex: 1 1 auto !important; }

		.div_table_resp {  overflow: scroll; }
		.div_table_resp table { border-spacing: 0; }
		.div_table_resp th:nth-child(1) { left: 0; width: 10px; min-width: 10px; }
		.div_table_resp td:nth-child(1) { background: #fefefe; z-index: 1; }
		.div_table_resp th:nth-child(1) { z-index: 300;}
		.div_table_resp th:nth-child(1), .checkbox { position: relative; z-index: 1; }
	
	
	}
</style>