<template>

    <!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
					<a href="#" class="text-dark fw-semibold" v-on:click="getProdotto()"> Prodotto </a> \
					<router-link href="#" class="text-dark cursor-pointer" :to="'Hotels'"> Elenco Hotels </router-link> \
					<a href="#" class="text-dark">{{hotel.lang.TITOLO}} </a> \
                    <span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Camere</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-flex align-items-center text-body lh-1 py-sm-2" style="text-align: left;" v-on:click="nuovaCamera()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1">Nuova Camera</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(5)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
            </div>
        </div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#camere-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.ELENCO'"></Translate> Camere</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#" v-on:click="openRoute('/Calendario')" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-calendar"></i>
								<span class="d-none d-lg-inline-block ms-2">Calendario</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#camere-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate></span>
							</div>
						</a>
					</li>
				</ul>

				<a class="rounded btn bg-danger bg-opacity-20 btn-sm-light me-2" v-on:click="getProdotto()" v-show="ordineHasChanged">Annulla ordinamento</a>
				<a class="rounded btn bg-success bg-opacity-20 btn-sm-light" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</a>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<a href="#" class="btn btn-light btn-icon btn-sm rounded dropdown-toggle" data-bs-toggle="dropdown">
								<i class="ph-gear me-1"></i> Azioni Multiple
								<span class="d-lg-none ms-2">More</span>
							</a>
							<div class="dropdown-menu dropdown-position dropdown-menu-end">
								<a href="#" class="dropdown-item">
									<i class="ph-image me-2"></i> Update cover
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-clipboard-text me-2"></i> Update info
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-trash me-2"></i> Elimina
								</a>
							</div>
						</li>
						<li class="nav-item ms-3">
							<select class="form-select form-select-sm changeyear" id="comboAnno" @change="getProdotti()" v-model="search.ANNO_ORDINE.VALUE" :tabindex="4">
								<option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
							</select>	
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
	<div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="camere-a">
        		<div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="camera-a">
				                    <div class="row ">
				                  		<div class="col-5 col-md-1">
				                     		<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Camera" :tabindex="1" v-model="search.ID_PRODOTTO.VALUE" />
				                    	</div>	
										<div class="col-5 col-md-1">
				                     		<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Vecchio" :tabindex="1" v-model="search.FK_ID_CAMPO_OLD.VALUE" />
				                    	</div>	
			                            <div class="col-7 col-md-1">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Codice" :tabindex="2" v-model="search.CODICE_PRODOTTO.VALUE" />
			                            </div>                
			                            <div class="col-md-2">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Camera" :tabindex="3" v-model="search.DESCRIZIONE.VALUE" />
			                            </div>
									  	<div class="d-none d-sm-block col-md-3">
			
									  	</div>
									  	<div class="col-8 col-md-1">
										</div>
										<div class="col-12 col-md-3">
											<div class="row">
												<div class="col-8 col-md-3">
												</div>
												<div class="col-6 col-md-5">
													<select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="7" v-model="search.FLG_ATTIVO.VALUE">
														<option value="">Stato </option>   
					                                    <option value="1"> &nbsp; Attivate</option>
					                                    <option value="0"> &nbsp; Disattivate</option>
					                                </select>
					                            </div>
												<div class="col-6 col-md-4">
													<a href="#collapse-a" class="btn btn-secondary btn-sm w-100 collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
														+ FILTRI
													</a>
												</div>
											</div>
										</div>  
			                        </div>
							 	</form>
		                    </div>
		                </div>
		            </div>
		
			        <div class="card-body mt-0 mb-0">
						<div class="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
			                            <th class="table-head-sm" style="width:2%;"> <input id="chkAllCamere" v-on:click="filters.selectAll('chkAllCamere',paginatedList.arr)" type="checkbox" class=""> </th>
			                            <th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
			                            <th class="table-head-sm" style="width:6%;"> #<br /><span class="float-end">Ord. </span></th>
			                            <th class="table-head-sm text-center" style="width:4%;"> Img </th>
			                            <th class="table-head-sm" style="width:20%;">Nome Prodotto / SEO /<br />Codice - Fornitore</th>
			                            <th class="table-head-sm text-center" style="width:4%;">Q.t&agrave;</th>
			                            <th class="table-head-sm text-center">Vendibile<br />sul sito</th>
			                            <th class="table-head-sm" style="width:15%;">Sub Allotemt /<br />Durata</th>
										<th class="table-head-sm text-center" style="width:5%;">Visibile</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Stato</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Azioni</th>
			                        </tr>
			                    </thead>
		                        <draggable tag="tbody" @end="ordineChangeHandler($event)">
		                            <tr v-for="prodotto in paginatedList.arr" v-bind:key="prodotto.ID_PRODOTTO">
		                                <td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="prodotto.SELEZIONATO" class=""> 
										</td>
		                                <td class="td-class text-center"><img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" /></td>
		                                <td class="td-class fs-sm">
											<span>{{prodotto.ID_PRODOTTO}}</span><br />
											<small class="badge bg-light text-primary float-end cursor-pointer"> {{prodotto.ORDINE}} </small>
										</td>
		                                <td class="td-class text-center">
											<img v-if="prodotto.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('PRODOTTI/',prodotto.URL_AVATAR)" class="rounded" width="50" height="35" alt="" />
											<span v-if="prodotto.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto fs-sm" style="line-height: 1rem !important;">No<br />Photo</span>
		                                </td>
		                                <td class="td-class">
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="openSchedaProdotto(prodotto)">{{prodotto.DESCRIZIONE}}</span><br /> 
		                                    <span class="badge bg-light badge-xs text-dark me-1" style="border: 1px #dddddd solid;"><i class="fal fa-code fa-fw"></i> {{prodotto.CODICE_PRODOTTO}}</span> 
											<span class="badge bg-light badge-xs text-dark" style="border: 1px #dddddd solid;"><i class="fal fa-tag fa-fw"></i> {{prodotto.FORNITORE}}</span>
		                                </td>
		                                <td class="td-class text-center fs-sm">
											<span class="badge bg-secondary bg-opacity-20 text-secondary">{{prodotto.HAS_CALENDARIO}}</span>
										</td>
		                                <td class="td-class fs-sm">
											<a href="#" class="text-primary cursor-pointer" v-on:click="calendario(prodotto)">{{ prodotto.OPZIONI }}</a>
										</td>
		                                <td class="fs-12"> 
											<span class="badge bg-light border-start border-width-3 text-body rounded-start-0 border-info">
											</span>
										</td>
										<td class="td-class fs-sm text-center">
											<span class="badge bg-indigo badge-xs text-white cursor-pointer" v-on:click="updateFlgVisibile(prodotto)" v-show="prodotto.FLG_VISIBILE == 1">Pubblico</span>
											<span class="badge bg-pink badge-xs text-white cursor-pointer" v-on:click="updateFlgVisibile(prodotto)" v-show="prodotto.FLG_VISIBILE == 0">Admin</span>
										</td>
		                                <td class="td-class text-center fs-sm">
		                                    <ISwitch @toggle="updateStatoProdotto(prodotto)" :size="'sm'" v-model="prodotto.FLG_PRENOTABILE" style="z-index: 0;"></ISwitch>
										</td>
		                                <td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
	                                                <div class="dropdown-header text-uppercase font-size-xs line-height-xs">Camera</div>
	                                                <a href="#" class="dropdown-item" v-on:click="modificaCamera(prodotto)"><i class="fad fa-pencil fa-fw ms-2 me-2"></i> Modifica Camera</a>
	                                                <a href="#" class="dropdown-item" v-on:click="calendario(prodotto)"><i class="fad fa-calendar fa-lg fa-fw ms-2 me-2"></i> Calendario Camera</a>
	                                                <a href="#" class="dropdown-item" v-on:click="variantiProdotto(prodotto)"><i class="fad fa-plus-square fa-lg fa-fw ms-2 me-2"></i> Supplementi</a>
	                                                <a href="#" class="dropdown-item" v-on:click="galleriaCamera(prodotto)"><i class="fad fa-images fa-lg fa-fw ms-2 me-2"></i> Galleria Camera</a>                                        
	                                                <div class="dropdown-header text-uppercase font-size-xs line-height-xs">Admin</div>
	                                                <a href="#" class="dropdown-item" v-on:click="storicoOrdine(ordine)"><i class="fad fa-list fa-lg fa-fw ms-2 me-2"></i> Storico Camera</a>
	                                                <a href="#" class="dropdown-item" v-on:click="deleteProdotto(prodotto)"><i class="fad fa-trash-alt fa-lg fa-fw text-danger ms-2 me-2"></i> Elimina Camera</a>                                        
		                                        </div>
		                                    </div>
		                                </td>
		                            </tr>
		                        </draggable>
		                    </table>
		                </div>

		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
		                        <div class="float-end">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
			                    </div>
	                		</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
	            			</div>
	        			</div>
		
					</div>
				</div>	   
    		</div>	
 
            <div class="tab-pane fade" id="camere-b">
        		<div class="card shadow mb-0">
		            <div class="collapse show">
			       
		            </div>

					<div class="card-body mt-0 mb-0">	

							
					</div>
				</div>
            </div>

            <div class="tab-pane fade" id="camere-c">
        		<div class="card shadow mb-0">
		            <div class="collapse show">
			       
		            </div>

					<div class="card-body mt-0 mb-0">	

							
					</div>
				</div>
            </div>


		</div>    
	</div>


	<!-- START MODALI -->
	<div class="modal fade" id="popSchedaCamera" role="dialog"  tabindex="-1">
	    <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 85% !important;"> Scheda Camera </h6>                                                        
                    <div class="btn-group float-end" :tabindex="2">
						<a href="#" class="btn btn-sm btn-yellow btn-yellow-100 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Comunicazioni</a>
						<div class="dropdown-menu dropdown-position dropdown-menu-end" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);">
                            <a href="#" class="dropdown-item"><i class="fal fa-print me-2"></i> Modifiche multiple </a>
                            <a href="#" class="dropdown-item"><i class="fal fa-envelope me-2"></i> Clona stessa camera </a> 
                            <a href="#" class="dropdown-item"><i class="fal fa-paper-plane me-2"></i> Clona camera divers </a> 
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div> 
                <div class="modal-body p-2"> 

                    <div class="d-lg-flex align-items-lg-start">
                        <div class="sidebar sidebar-component sidebar-expand-lg bg-transparent shadow-none me-lg-3">
                            <div class="sidebar-content">
                                <div class="card sidebar-section-body text-center p-2">
                                    <div class="card-img-actions d-inline-block">
                                        <img v-if="prodottoSelezionato.ID_PRODOTTO > 0 && prodottoSelezionato.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('PRODOTTO',prodottoSelezionato.URL_AVATAR)" class="img-fluid squared-circle rounded-0" alt="" width="215" height="150" />
                                        <span v-if="prodottoSelezionato.URL_AVATAR == 'placeholder.jpg' || prodottoSelezionato.ID_UTENTE == 0">
                                            <img src="../../assets/images/placeholders/placeholder.jpg" class="img-fluid rounded-circle" width="150" height="150" alt="">
                                        </span>
                                        <div class="card-img-actions-overlay card-img rounded-circle">
                                            <a href="#" class="btn btn-outline-white btn-icon rounded-pill" v-on:click="uploadImmagineProdotto()">
                                                <i class="ph-pencil"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <ul class="nav nav-sidebar" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a href="#room-main" class="nav-link active" id="room-main-tab" data-bs-toggle="tab" aria-selected="true" role="tab">
                                            <i class="ph-info me-2"></i>Info Generali
                                        </a>
                                    </li>
                                    <li v-show="prodottoSelezionato.ID_PRODOTTO > 0" class="nav-item" role="presentation">
                                        <a href="#room-opzpro" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1" v-on:click="assegnaTooltip()">
                                            <i class="ph-calendar me-2"></i>Configurazioni Camera
                                        </a>
                                    </li>
                                    <li v-show="prodottoSelezionato.ID_PRODOTTO > 0" class="nav-item" role="presentation">
                                        <a href="#room-logs" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
                                            <i class="ph-code me-2"></i>Log Accessi
                                        </a>
                                    </li>
                                </ul>
							</div>
						</div>	 

						<div class="tab-content flex-fill">

							<div class="tab-pane fade active show" id="room-main" role="tabpanel">
                               <h5 class="label-bar"><i class="fad fa-info-square fa-fw me-1"></i> Info Generali</h5>
                               <div class="box-utenti"> 

									<div class="row mt-1">
										<div class="col-6 col-md-4 form-group-1">
											<label class="text-danger custom-control custom-control-right">Codice 
												<span class="pop_over float-end" data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Codice  descrizioneTipoProdotto" data-content="assss"><i class="far fa-info-circle text-primary"></i></span>
											</label>
											<div class="input-group input-group-sm" tabindex="1">
												<span class="input-group-prepend"><span class="input-group-text"><i class="far fa-code fa-fw"></i></span></span>
												<input autocomplete="off" type="text" class="form-control form-control-sm valid" v-model="prodottoSelezionato.CODICE_PRODOTTO">
											</div>
										</div>
										<div class="col-12 col-md-8 form-group-1">
											<label class="text-danger custom-control custom-control-right">Fornitore</label> 
											<span v-on:click="nuovoFornitore()"  class="badge bg-yellow badge-xs text-dark cursor-pointer float-end">nuovo</span>
											<Select2 :w="250" :api="'travel/s2/fornitori'" :apiselected="'travel/s2/fornitore?a=0'" v-model="prodottoSelezionato.FK_ID_AZIENDA_FORNITORE" :tabindex="16"></Select2>
										</div>
									
									</div>

									<hr class="hr-1" />

									<div class="row mt-2">
										<div class="col-12 col-md-8">	 

											<div class="row">
												<div class="col-12 col-md-12 form-group-1">
													<label class="text-danger custom-control custom-control-right">Nome Camera </label>
													<div class="input-group input-group-sm" :tabindex="3">
														<span class="input-group-prepend"><span class="input-group-text"><i class="far fa-pennant fa-fw"></i></span></span>
														<input autocomplete="off" type="text" class="form-control form-control-sm valid" v-model="prodottoSelezionato.DESCRIZIONE">
													</div>
												</div>
												<div class="col-12 col-md-12 form-group-1">
													<label class="text-danger custom-control custom-control-right">Slogan Camera </label>
													<div class="input-group input-group-sm" :tabindex="3">
														<span class="input-group-prepend"><span class="input-group-text"><i class="far fa-pennant fa-fw"></i></span></span>
														<input autocomplete="off" type="text" class="form-control form-control-sm valid" v-model="prodottoSelezionato.lang.SOTTOTITOLO">
													</div>
												</div>

												<div class="col-12 form-group-1">
													<label class="text-danger custom-control custom-control-right">Servizi  
														<span class="pop_over float-end" data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Tematiche associate" data-content="assss"><i class="far fa-info-circle text-primary"></i></span>
													</label>
													<Select2 :multiple="true" v-bind:options="new Array()" v-model="prodottoSelezionato.categorie" :tabindex="14"></Select2>
												</div> 
											</div>
											
										</div>
										<div class="col-12 col-md-4" style="border-left: 1px solid #ddd;">

											<div class="row">
												<div class="col-12 form-group-1">
													<label class="text-danger custom-control custom-control-right">Superficie 
														<span class="pop_over float-end" data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Tag speciale" data-content="assss"><i class="far fa-info-circle text-primary"></i></span>
													</label>
													<input autocomplete="off" type="text" v-model="prodottoSelezionato.dettagli.ROOM_SUPERFICIE" class="form-control form-control-sm valid">
												</div>

												<div class="col-6 form-group-1">
													<label class="text-danger custom-control custom-control-right">N° Letti 
														<span class="pop_over float-end" data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Tag speciale" data-content="assss"><i class="far fa-info-circle text-primary"></i></span>
													</label>
													<input autocomplete="off" type="text" class="form-control form-control-sm valid">
												</div>
												<div class="col-6 form-group-1">
													<label class="text-danger custom-control custom-control-right">N° Bagni 
														<span class="pop_over float-end" data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Tag speciale" data-content="assss"><i class="far fa-info-circle text-primary"></i></span>
													</label>
													<input autocomplete="off" type="text" class="form-control form-control-sm valid">
												</div>

												<div class="col-12 mb-5 form-group-1">
													<label class="text-danger custom-control custom-control-right">Superficie 
														<span class="pop_over float-end" data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Tag speciale" data-content="assss"><i class="far fa-info-circle text-primary"></i></span>
													</label>
													<input autocomplete="off" type="text" class="form-control form-control-sm valid">
												</div>
											</div>
											
										</div>
									</div> 

									<div class="row mt-2">
										<div class="col-12 col-md-6 form-group-1">

										</div>
										<div class="col-12 col-md-3 form-group-1">
											<label class="text-danger custom-control custom-control-right">Visibile 
												<span class="pop_over float-end" data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Visibile" data-content="assss"><i class="far fa-info-circle text-primary"></i></span>
											</label>
											<select class="form-control form-control-sm" v-model="prodottoSelezionato.FLG_VISIBILE" :tabindex="27">
												<option selected="selected">Seleziona</option>
												<option v-bind:value="1">Pubblico</option>        
												<option v-bind:value="0">Admin</option>        
											</select>
										</div>
										<div class="col-12 col-md-3 form-group-1">
											<label class="text-danger custom-control custom-control-right" style="font-weight: 400;">Prenotabile 
												<span class="pop_over float-end" data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Prenotabile" data-content="assss"><i class="far fa-info-circle text-primary"></i></span>
											</label>
											<div class="input-group input-group-sm" :tabindex="4">
												<span class="input-group-prepend"><span class="input-group-text"><i class="far fa-suitcase fa-fw"></i></span></span>
												<select class="form-control form-control-sm" v-model="prodottoSelezionato.FLG_PRENOTABILE">
													<option value="1" selected="selected">Si</option>
													<option value="0">No</option>
												</select>
											</div>

										</div>
                                        
									</div>

								</div>
							</div>

							<div class="tab-pane fade" id="room-opzpro" role="tabpanel" aria-labelledby="room-opzpro-tab">
								<h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 3rem;">
									<i class="fad fa-balance-scale fa-fw me-1"></i> Configurazioni Camera
									<span class="ms-3 badge bg-info badge-xs">{{ getCountCamereSelezionate }}</span>
								</h3>
								<div class="box-utenti">  

									<div class="kt-portlet__head" >
										<form class="isprint-filter" autocomplete="off"> 
											<input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Filtra per tipologie Camere" type="text" v-model="searchRtsType.DESCRIZIONE.VALUE">
										</form>
									</div>
									<hr class="hr-2"/>
									<div class="row">
										<div class="col-md-3 mb-2" v-on:click="setOpzione(opzione)" v-for="opzione in paginatedListRtsType.arr" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA">
											<div class="card mb-1 lift-panel shadow " v-bind:style="getStyleOpzione(opzione)" style="height:35px;">
												<div class="card-body d-flex h-100" style="padding:.25rem;padding-left:.5rem;padding-right:.5rem;">
													<div class="justify-content-center align-self-center cursor-pointer" style="font-size:10px;">
														<strong>{{opzione.CUSTOM_1}}</strong><br/>
														<span  data-bs-toggle="tooltip" data-bs-placement="bottom" :title="opzione.DESCRIZIONE" class="lblCamera">{{filters.accorciaTesto(opzione.DESCRIZIONE,35)}}</span>
													</div>
												</div>
											</div>
										</div>                                
									</div>                                     
								
								</div>
							</div>

                            <div class="tab-pane fade" id="room-logs" role="tabpanel">
                                <h5 class="label-bar mb-1"><i class="fad fa-code fa-fw me-1"></i> Log Accessi</h5>
                                <div class="row mb-1">
									<div class="col-6 col-md-4">
                                        <select class="form-select form-select-sm">
											<option value="opt1">Seleziona Data</option>
											<option value="opt2">Option 2</option>
											<option value="opt3">Option 3</option>
											<option value="opt4">Option 4</option>
											<option value="opt5">Option 5</option>
											<option value="opt6">Option 6</option>
											<option value="opt7">Option 7</option>
											<option value="opt8">Option 8</option>
										</select>
									</div>
									<div class="col-6 col-md-4">
                                        <select class="form-select form-select-sm">
											<option value="opt1">Seleziona Modulo</option>
											<option value="opt2">Option 2</option>
											<option value="opt3">Option 3</option>
											<option value="opt4">Option 4</option>
											<option value="opt5">Option 5</option>
											<option value="opt6">Option 6</option>
											<option value="opt7">Option 7</option>
											<option value="opt8">Option 8</option>
										</select>
									</div>
									<div class="d-block d-xl-none col-md-4">
									</div>
								</div>
                                <div class="tableFixHead tableFixHeadRoles">
                                    <table class="table table-bordered table-hover table-striped table-togglable">
                                        <thead class="sticky-top">
                                            <tr class="bg-secondary text-white">
												<th class="p-1 fs-12" style="width:20%;"> Data </th>
												<th class="p-1 fs-12" style="width:20%;">Modulo</th>
												<th class="p-1 fs-12">Pagina</th>
												<th class="p-1 fs-12 text-center" style="width:5%;">Azioni</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td class="p-1 font-size-xs">30/12/2021 12:58:01</td>
												<td class="p-1 font-size-xs"><span class="text-primary fw-bold">Home</span></td>
												<td class="p-1 font-size-xs">op=users_list</td>
												<td class="p-1 text-center">
													<a href="#" class="badge badge-flat border-secondary text-secondary"><i class="fal fa-eye"></i></a>
												</td>
											</tr>
										</tbody>
									</table>
								</div> 
							</div>

						</div>
					</div>
               
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaCamera()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
				</div>
			</div>
		</div>
    </div>
					 

    <div class="modal fade" :id="'popUpUploadImgProdotto'+rs" role="dialog" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-light text-dark">
                    <h6 class="modal-title">Upload immagine Prodotto</h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">    
                    <Upload :args="argsProdotto"  @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>
            </div>
        </div>
    </div>

	<SchedaAzienda @aziendaSalvata="setFornitore($event)" :ref="'schedaAzienda'" :tipo="2" ></SchedaAzienda>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import Select2 from '@/components/utils/Select2.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import Upload from '@/components/utils/Upload.vue'
import SchedaAzienda from '@/components/SchedaAzienda.vue'	



export default {
    name:"Camere",		   

    components:{
        Pagine,
        ISwitch,
        Select2,
        draggable: VueDraggableNext,
        Upload,
		SchedaAzienda,

    },

    data:function(){
		return{
            apiStartup:'travel/prodotto',
	        filters:global.filters,
			rs : utils.generateRandomString(30),
			GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            hotel:{lang:{},sottoProdotti:new Array()},
			prodottoLang:{},
			prodottoSelezionato:{lang:{},opzioni:new Array(),dettagli:{}},
			arrayRtsType:new Array(),
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_PRODOTTO:{TYPE:'TEXT',VALUE:''},
                CODICE_PRODOTTO:{TYPE:'TEXT',VALUE:''},
                FK_ID_TIPO:{TYPE:'COMBO',VALUE:''}, 
				DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
	            TITOLO:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_AZIENDA_FORNITORE:{TYPE:'COMBO',VALUE:''},
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
				ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
				FK_ID_CAMPO_OLD:{TYPE:'TEXT',VALUE:''},
	        },
			ordineHasChanged: false,
			searchRtsType:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:1000},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            argsProdotto:{
				modalita:'MOONLIGHT_uploadImgProdotto',
			},
        }
    }, 

    methods: {
		getProdotto : function(){
			utils.ajax("travel/prodotto",{ID_PRODOTTO:this.$root.prodotto.ID_PRODOTTO,FLG_SOTTOPRODOTTI:this.modulo == 2 ? 1 : 0,ANNO:this.search.ANNO_ORDINE.VALUE},(response)=>{
				this.ordineHasChanged = false;
				this.hotel = response.data;
			});
		},		   

        nuovaCamera : function(){
            this.openSchedaProdotto({ID_PRODOTTO:0,FK_ID_MODULO:5,FK_ID_PRODOTTO:this.hotel.ID_PRODOTTO,FK_ID_COMUNE:0,lang:{FLG_ATTIVO:1},dettagli:{}});
        },		 

        modificaCamera : function(prodotto){
            this.$refs.schedaProdotto.openSchedaProdotto(prodotto);
        },	   

        variantiProdotto : function(prodotto){
            this.$root.prodotto = prodotto;
            global.router.push("Varianti");
        }, 

        openSchedaProdotto: function(prodotto){
            //this.$refs.schedaProdotto.openSchedaProdotto(prodotto);
			this.arrayRtsType = utils.clone(this.$root.arrayRtsType);
			for (var i = 0 ; i < this.arrayRtsType.length ; i++) {
				this.arrayRtsType[i].SELEZIONATO = 0;
			}
			if (prodotto.ID_PRODOTTO > 0){
				this.argsProdotto.ID_PRODOTTO = prodotto.ID_PRODOTTO;
				utils.ajax("travel/prodotto",{ID_PRODOTTO:prodotto.ID_PRODOTTO},(response)=>{
					this.prodottoSelezionato = utils.clone(response.data);
					for (var i = 0 ; i < this.arrayRtsType.length ; i++) {
						var check = utils.array.select(this.prodottoSelezionato.opzioni,"FK_ID_OPZIONE",this.arrayRtsType[i].ID_OPZIONE_TIPOLOGICA,1)[0];
						if (check != undefined){
							this.arrayRtsType[i].SELEZIONATO = 1;
						}
					}
					this.filters.openPopUp("popSchedaCamera");
					var el = document.getElementById("room-main-tab");
					el.click();
				});				
			}
			else{
				this.prodottoSelezionato = utils.clone(prodotto);
				this.filters.openPopUp("popSchedaCamera");
				var el = document.getElementById("room-main-tab");
				el.click();
			}

        },	  

		nuovoFornitore : function(){
            var fornitore = {ID_AZIENDA:0,personaGiuridica:{},FK_ID_ENTE:this.$root.idEnteCorrente,FLG_ATTIVO:1};
            this.$refs.schedaAzienda.openSchedaAzienda(fornitore);
        },
		setFornitore(fornitore){
            this.prodotto.FK_ID_AZIENDA_FORNITORE = fornitore.ID_AZIENDA;
        },

		calendario : function(prodotto){
			this.$root.prodotto = {ID_PRODOTTO:this.hotel.ID_PRODOTTO};
			this.$root.sottoProdotto = prodotto;
            global.router.push("Calendario");
		}, 

		updateStatoProdotto : function(prodotto){
            setTimeout(() => {
                console.log(prodotto.FLG_ATTIVO)
                utils.ajax("travel/prodotto/update",prodotto, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getProdotti();
                    }
                });                
            },300)
        }, 

		/////////////////////////////////////////////////////////////////

      	esportazione : function(formato){
        	utils.report.token({modalita:'esportaProdotti',formato:formato});
      	},

		ordineChangeHandler : function(event){
			this.hotel.sottoProdotti = this.filters.draggableChangeHandler(event,this.hotel.sottoProdotti,this.search,"ID_PRODOTTO");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.hotel.sottoProdotti.length ; i++){
					arr.push({"ID": this.hotel.sottoProdotti[i].ID_PRODOTTO,"O": this.hotel.sottoProdotti[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'ProdottoTuristico',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getProdotti();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},

		setOpzione : function(opzione){
			opzione.SELEZIONATO = opzione.SELEZIONATO == 1 ? 0 : 1;
			utils.ajax("travel/prodotto/opzione/update",{SELEZIONATO:opzione.SELEZIONATO,ID_PRODOTTO:this.prodottoSelezionato.ID_PRODOTTO,FK_ID_OPZIONE:opzione.ID_OPZIONE_TIPOLOGICA}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            });   
		}, 

		getStyleOpzione(opzione){
            if (opzione.SELEZIONATO == 1){
                return {background:'#1cc88a',color:"#fff"};
            }
            return {};
        },

        uploadImmagineProdotto : function(){
            this.filters.openPopUp("popUpUploadImgProdotto"+this.rs);
        },
        
        successCaricamento:function(response){
            this.filters.closePopUp("popUpUploadImgProdotto"+this.rs);
            this.prodottoSelezionato = response.data;
			this.getProdotto();
        },

        errorCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgProdotto"+this.rs);
        },

		salvaCamera : function(){
            var api = this.prodottoSelezionato.ID_PRODOTTO > 0 ? "travel/prodotto/update" : "travel/prodotto/insert";
            utils.ajax(api,this.prodottoSelezionato, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    if (this.prodottoSelezionato.ID_PRODOTTO == 0){
                        this.prodottoSelezionato.ID_PRODOTTO = response.id_inserito;
                        this.argsProdotto.ID_PRODOTTO = response.id_inserito;
                    }
                }
            });
        },		  

        openRoute : function(route){
            global.router.push(route);
        },

		assegnaTooltip : function(){
			this.filters.wait(300,()=>{
				var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
				var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
				return new bootstrap.Tooltip(tooltipTriggerEl)
				})
			});
		}

    },	  

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.hotel.sottoProdotti,this.search);
            return arr;
        },	 
		paginatedListRtsType(){
            if (this.arrayRtsType == undefined || this.arrayRtsType == null)
                return new Array();
            var arr = global.utils.inGrid(this.arrayRtsType,this.searchRtsType);
            return arr;
        }, 
		getCountCamereSelezionate(){
			return utils.array.select(this.arrayRtsType,"SELEZIONATO",1).length;
		}

    }, 

    created : function(){
        this.getProdotto();
    }		   

}
</script>