<template>
	
    <!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link> 
					<a href="#" class="d-none d-lg-inline-block text-dark fw-semibold cursor-pointer" v-on:click="getProdotti()"> {{moduleName}} </a> <span class="d-none d-lg-inline-block">\</span>
					<span class="text-muted ms-1"><Translate :path="'dizionario.base.ELENCO'"></Translate> {{moduleName}}</span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">

					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;" v-on:click="nuovoProdotto()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1 ms-1">
							<div class="fs-sm text-muted mb-1">
								<Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> {{moduleName}}
							</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a v-show="this.modulo != 2" href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(this.modulo)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<div v-show="this.modulo == 2" class="dropdown w-100 w-sm-auto" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<a href="#" class="d-flex dropdown-toggle btn btn-outline-light btn-icon w-42px h-32px squared-pill ms-3" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
								<i class="ph-lightning"></i> 
							</a>
							<div class="dropdown-menu dropdown-menu-lg-end w-100 w-lg-auto wmin-300 wmin-sm-350 pt-0">
								<a href="#" class="dropdown-item active py-2" v-on:click="$root.confTravelTipologiche(this.modulo)">
									<i class="ph-buildings w-32px h-32px me-2"></i>
									<div>
										<div class="fw-semibold">Hotels</div>
										<div class="fs-sm text-muted">Gestione Configurazioni</div>
									</div>
								</a>
								<a href="#" class="dropdown-item py-2" v-on:click="$root.confTravelTipologiche(5)">
									<i class="ph-bed w-32px h-32px me-2"></i>
									<div>
										<div class="fw-semibold">Camere</div>
										<div class="fs-sm text-muted">Gestione Configurazioni</div>
									</div>
								</a>
							</div>
						</div>					

						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(this.modulo)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#product-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7"></i>
								<span class="d-lg-none ms-1">{{moduleName}}</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>{{moduleName}}</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation" v-show="this.modulo != 6">
						<a href="#product-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-calendar"></i>
								<span class="d-lg-none ms-1">Occup.</span>
								<span class="d-none d-lg-inline-block ms-1">Piano Occupazione</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#product-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1" v-on:click="statisticheStartup();">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>
								<span class="d-lg-none ms-1">Stats.</span>
								<span class="d-none d-lg-inline-block ms-1"><Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate></span>
							</div>
						</a>
					</li>
					<li class="nav-item d-lg-none ms-auto">
						<a href="#profile_nav" class="navbar-nav-link navbar-nav-link-icon rounded-pill rounded-pill-small collapsed" data-bs-toggle="collapse" aria-expanded="false">
							<i class="ph-caret-down collapsible-indicator"></i>
						</a>
					</li>
				</ul>

				<a class="rounded btn bg-danger bg-opacity-20 btn-sm-light me-2 mt-xs-1" v-on:click="getProdotti()" v-show="ordineHasChanged">Annulla ordinamento</a>
				<a class="rounded btn bg-success bg-opacity-20 btn-sm-light mt-xs-1" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</a>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0 select-small">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
									<i class="ph-gear me-1"></i>Azioni Multiple
									<span class="d-lg-none ms-2">More</span>
								</a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Prodotti selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti i Prodotti</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina Utenti</a>
								</div>
							</div>
						</li>
						<li class="nav-item">

						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="product-a">
        		<div class="card shadow mb-xs-3 mb-lg-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="prodotto-a">
				                    <div class="row ">
				                  		<div class="col-6 col-md-1 col-lg-1 mbx-1">
				                     		<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Prodotto" :tabindex="1" v-model="search.ID_PRODOTTO.VALUE" />
				                    	</div>	
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
				                     		<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Vecchio" :tabindex="1" v-model="search.FK_ID_CAMPO_OLD.VALUE" />
				                    	</div>	
			                            <div class="col-6 col-md-1 col-lg-1 mbx-1">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Codice" :tabindex="2" v-model="search.CODICE_PRODOTTO.VALUE" />
			                            </div> 	
										<div class="col-6 col-md-2 mbx-1">
											<select class="form-select form-select-sm" v-model="search.FK_ID_TIPO.VALUE" data-placeholder="Tipologia" :tabindex="3" >
												<option v-bind:value="''">Tipologia</option>
												<option v-for="t in arrayTipologieProdotto" v-bind:value="t.ID_OPZIONE_TIPOLOGICA" v-bind:key="t.ID_OPZIONE_TIPOLOGICA" >{{ t.DESCRIZIONE }}</option>
											</select>
										</div>               
			                            <div class="col-12 col-md-2 mbx-1">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Prodotto" :tabindex="3" v-model="search.DESCRIZIONE.VALUE" />
			                            </div>
										<div class="col-md-2 mbx-1">
											<Select2 :w="250" :api="'travel/s2/fornitori'" v-model="search.FK_ID_AZIENDA_FORNITORE.VALUE" :tabindex="13" :placeholder="'Seleziona Fornitore'"></Select2>
										</div>
										<div class="col-12 col-md-3">
											<div class="row">
												<div class="col-8 col-md-3">
												</div>
												<div class="col-6 col-md-5">
													<select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="7" v-model="search.FLG_ATTIVO.VALUE">
														<option value="">Stato </option>   
														<option value="1"> &nbsp; Attivati</option>
														<option value="0"> &nbsp; Disattivati</option>
													</select>
												</div> 
												<div class="col-6 col-md-4 col-lg-4">
													<a href="#collapse-a" class="btn btn-flat-info btn-sm w-100 collapsed" data-bs-toggle="collapse" v-on:click="filters.resizeGrid('collapse-a','tableFixHead','box-card')" role="button" aria-expanded="false">
														+ FILTRI
													</a>
												</div>
											</div>
										</div>  
			                        </div>
			                        <div class="collapse" id="collapse-a" style="">
			                            <div class="row">
			                                <div class="col-6 col-md-2 mt-1">
			                                    <Select2 :w="250" :api="'core/geo/stati/select2'" v-model="search.FK_ID_STATO.VALUE" :tabindex="10" :placeholder="'Sel. Stato'"></Select2>
			                                </div>
			                                <div class="col-6 col-md-2 mt-1">
			                                    <Select2 :w="250" :api="'core/geo/regioni/select2'"  v-model="search.FK_ID_REGIONE.VALUE" :tabindex="11" :placeholder="'Sel. Regione'"></Select2>
			                                </div>
			                                <div class="col-6 col-md-2 mt-1">
			                                    <Select2 :w="250" :api="'core/geo/comuni/select2'"  v-model="search.FK_ID_COMUNE.VALUE" :tabindex="12" :placeholder="'Sel. Comune'"></Select2>
			                                </div>   
		                                    <div class="col-6 col-md-2 mt-1">
												<select class="form-select form-select-sm" data-placeholder="Sel. Router" :tabindex="6">
													<option>Sel. Router</option>
												</select>
		                                    </div>
		                                    <div class="col-6 col-md-2 mt-1">
		
		                                    </div>
		                                    <div class="col-6 col-md-2 mt-1">
			                                </div>  
			                            </div>
			                        </div>
							 	</form>
		                    </div>
		                </div>
		            </div>
		
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="div_table_resp tableFixHead" id="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable text-nowrap"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
			                            <th class="table-head-sm" style="width:2%;"> <input id="chkAllProducts" v-on:click="filters.selectAll('chkAllProducts',paginatedList.arr)" type="checkbox" class=""> </th>
			                            <th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
			                            <th class="table-head-sm" style="width:6%;"> #<br /><span class="float-end">Ord. </span></th>
			                            <th class="table-head-sm text-center" style="width:4%;"> Img </th>
			                            <th class="table-head-sm">Nome Prodotto / SEO /<br />Codice</th>
										<th v-show="modulo == 2 || modulo == 5" class="table-head-sm" style="width:15%;">Servizio / Speciale<br />Categorie</th>
										<th v-show="modulo == 1 || modulo == 3 || modulo == 4" class="table-head-sm" style="width:15%;">Servizio / Durata /<br />Categorie</th>
										<th class="table-head-sm" style="width:17%;">Fornitore  /<br />Località</th>
			                            <th v-show="modulo == 2 || modulo == 5" class="table-head-sm text-center" style="width:5%;">Camere</th>
										<th v-show="modulo == 1 || modulo == 3 || modulo == 4" class="table-head-sm text-center" style="width:7%;">Data inizio<br />Data fine</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Hits</th>
										<th class="table-head-sm text-center" style="width:5%;">Visibile</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Stato</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Azioni</th>
			                        </tr>
			                    </thead>
		                        <draggable tag="tbody" handle=".handle" @end="ordineChangeHandler($event)">
		                            <tr v-for="prodotto in paginatedList.arr" v-bind:key="prodotto.ID_PRODOTTO">
		                                <td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="prodotto.SELEZIONATO" class=""> 
										</td>
		                                <td class="td-class text-center cursor-pointer handle">
											<img src="../assets/images/icons/arrow.png" width="22" height="22" alt="" />
										</td>
		                                <td class="td-class fs-sm">
											<span>{{prodotto.ID_PRODOTTO}}</span><br />
											<small class="badge bg-light text-primary float-end" v-on:click="modificaOrdinamento(prodotto)">{{prodotto.ORDINE}}</small>
										</td>
		                                <td class="td-class text-center">
											<img v-if="prodotto.ID_PRODOTTO > 0 && prodotto.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('PRODOTTI/',prodotto.URL_AVATAR)" class="rounded" width="50" height="35" alt="" />
											<span v-if="prodotto.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto fs-sm" style="line-height: 1rem !important;">No<br />Photo</span>
		                                </td>
		                                <td class="td-class fs-sm">
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="openOffCanvas();openSchedaVisualizzazioneProdotto(prodotto)">{{prodotto.DESCRIZIONE}}</span>
											<br />
											<span class="me-2" style="border: 1px #dddddd solid;"><i class="far fa-code fa-fw"></i> {{prodotto.CODICE_PRODOTTO}}</span> 
		                                    <span class="badge badge-xs cursor-pointer me-1" v-on:click="updateFlgPrenotabile(prodotto)" v-bind:class="{'bg-success':prodotto.FLG_PRENOTABILE == 1,'bg-danger':prodotto.FLG_PRENOTABILE == 0}"><i class="fal fa-tag fa-fw"></i> <span v-if="prodotto.FLG_PRENOTABILE == 1">Prenotabile</span><span v-else>Stop sale</span></span>  
		                                    <span class="badge bg-light badge-xs text-dark cursor-pointer me-1" style="border: 1px #dddddd solid;"><i class="fal fa-thumbs-up fa-fw"></i> Metti in Evidenza</span>
		                                </td>
		                                <td class="td-class fs-sm">
											<span class="badge bg-light border-start border-width-3 text-body rounded-start-0 border-info" v-show="modulo == 1 || modulo == 3 || modulo == 4">{{prodotto.DESC_TIPOLOGIA_PRODOTTO}} / {{prodotto.DURATA}}</span>
											<span class="badge bg-light border-start border-width-3 text-body rounded-start-0 border-info" v-show="modulo == 2 || modulo == 5">{{prodotto.DESC_TIPOLOGIA_PRODOTTO}} / {{prodotto.SPECIALE}}</span>	
											<br />
											<span class="text-muted fs-sm cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" :title="prodotto.CATEGORIE">Mostra Categorie</span>
										</td>
		                                <td class="fs-sm">
											<span style="line-height: 1.325rem !important">{{prodotto.FORNITORE}}</span><br />
											<span class="text-muted" style="line-height: 1.325rem !important">{{prodotto.GEO_STATO}}, {{prodotto.GEO_COMUNE}}</span>
										</td>
		                                <td class="fs-sm">
											<span class="badge bg-primary bg-opacity-20 text-primary cursor-pointer" v-show="modulo == 2 || modulo == 5" v-bind:class="{'bg-primary':prodotto.CAMERE>0,'bg-light text-body':prodotto.CAMERE==0}" v-on:click="camere(prodotto)">{{prodotto.CAMERE}}</span>
											<div v-show="modulo == 1 || modulo == 3 || modulo == 4">
												<i class="ph-calendar fs-base lh-base align-top text-success me-1" style="line-height: 1.325rem !important"></i><a class="cursor-default" href="#">{{filters.formatDate(prodotto.DATA_MIN)}}</a>
											</div>
											<div v-show="modulo == 1 || modulo == 3 || modulo == 4">
												<i class="ph-calendar fs-base lh-base align-top text-danger me-1" style="line-height: 1.325rem !important"></i><a class="cursor-default" href="#">{{filters.formatDate(prodotto.DATA_MAX)}}</a>
											</div>
										</td>
		                                <td class="td-class fs-sm text-center">
											<span class="text-secondary">{{prodotto.COUNTER}}</span>
										</td>
										<td class="td-class fs-sm text-center">
											<span v-on:click="updateFlgVisibile(prodotto)" v-show="prodotto.FLG_VISIBILE == 1" class="badge bg-indigo badge-xs text-white cursor-pointer">Pubblico</span>
											<span v-on:click="updateFlgVisibile(prodotto)" v-show="prodotto.FLG_VISIBILE == 0" class="badge bg-pink badge-xs text-white cursor-pointer">Admin</span>
										</td>
										<td class="td-class text-center">
		                                    <ISwitch @toggle="updateFlgAttivo(prodotto)" :size="'sm'" v-model="prodotto.FLG_ATTIVO" style="z-index: 0;"></ISwitch>
										</td>
		                                <td class="td-class text-center fs-sm">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="modificaProdotto(prodotto)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Prodotto</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="modificaContenuti(prodotto)"><i class="fad fa-text fa-fw me-2"></i><span class="text-muted me-1">|</span>Gestione Contenuti</a>
													<a v-show="modulo != 2" href="#" class="dropdown-item" v-on:click="goToExtra(prodotto)"><i class="fad fa-text fa-fw me-2"></i><span class="text-muted me-1">|</span>Gestione Extra</a>
													<a href="#" class="dropdown-item" v-show="modulo == 2" v-on:click="camere(prodotto)"><i class="fad fa-bed-alt fa-fw me-2"></i><span class="text-muted me-1">|</span>Gestione Camere</a>
													<a href="#" class="dropdown-item" v-on:click="calendario(prodotto)"><i class="fad fa-calendar fa-fw me-2"></i><span class="text-muted me-1">|</span>Gestione Calendario</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteProdotto(prodotto)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Prodotto</a>
												</div>
											</div>
		                                </td>
		                            </tr>
		                        </draggable>
		                    </table>
		                </div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">PRODOTTI: {{ paginatedList.count }}</span> 
                                    <span v-show="livello == 5" class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">FATTURATO: </span>
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end pag-mobile">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH"></Pagine>
			                    </div>
			                </div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
			            	</div>
			        	</div>
					</div>
				</div>
    		</div>

			<div class="tab-pane fade" id="product-b">
        	
		        <PianoOccupazione :modulo="modulo"> </PianoOccupazione>

			</div>

			<div class="tab-pane fade" id="product-c">

				<div class="row">
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-users ph-2x text-primary me-3"></i>
								<div class="flex-fill text-end">
									<h4 class="mb-0">{{ getTotali.COUNT }}</h4>
									<span class="text-muted">Totale {{tipoName}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-thumbs-up ph-2x text-success me-3"></i>

								<div class="flex-fill text-end">
									<h4 class="mb-0">{{ getTotali.USER_OK }}</h4>
									<span class="text-muted">Utenti attivi</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-thumbs-down ph-2x text-danger me-3"></i>
								<div class="flex-fill text-end">
									<h4 class="mb-0">{{  getTotali.USER_KO }}</h4>
									<span class="text-muted">Utenti 0 pratiche</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0"></h4>
									<span class="text-muted">Utenti bannati</span>
								</div>
								<i class="ph-house-simple ph-2x text-danger ms-3"></i>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0">2365</h4>
									<span class="text-muted">Utenti </span>
								</div>
								<i class="ph-sketch-logo ph-2x text-indigo ms-3"></i>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0">389,438</h4>
									<span class="text-muted">Utenti </span>
								</div>
								<i class="ph-globe-hemisphere-east ph-2x text-purple ms-3"></i>
							</div>
						</div>
					</div>
				</div>
								
				<Statistiche :modalita="tipo == 1 ? 2 : 3 "></Statistiche>

			</div>

			<SchedaProdotto @updateProdotto="getProdotti()" :modulo="modulo" :ref="'schedaProdotto'" :arrayCategorie="arrayCategorie"></SchedaProdotto>
			<PopupOrdinamento @ordinamentoAggiornato="handlerOrdinamentoAggiornato($event)" :modulo="modulo" :ref="'modalOrdinamento'"></PopupOrdinamento>

		</div>
	</div>	


	<!-- START MODALI -->
	<div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right_prodotto" role="dialog" data-bs-backdrop="false" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
			<h5 class="offcanvas-title fw-semibold">SCHEDA</h5>
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">
 
			<SchedaVisualizzazioneProdotto :ref="'schedaVisualizzazioneProdotto'" @modificaProdotto="modificaProdotto($event)"></SchedaVisualizzazioneProdotto>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
			<div style="position: relative; top: 2px;">
            	<span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>     
	        </div>  
		</div>	
	</div> 

    <div class="modal fade" :id="'popUpUploadImgProdotto'+rs" role="dialog" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
				<div class="modal-header bg-light text-dark border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title"><i class="fa fa-fw fa-key fa-fw"></i> NOTE IMPORTANTI</h6>  
                    <button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
                </div>
                <div class="modal-body"> 
   
                    <Upload :args="argsProdotto"  @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
	 
                </div>
				<div class="modal-footer bg-light p-1">                                
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div>
    </div>
	


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import Select2 from '@/components/utils/Select2.vue'
import SchedaProdotto from '@/components/SchedaProdotto.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import Upload from '@/components/utils/Upload.vue'
import PopupOrdinamento from '@/components/utils/PopupOrdinamento.vue'
import PianoOccupazione from '@/components/PianoOccupazione.vue'
import SchedaVisualizzazioneProdotto from '@/components/SchedaVisualizzazioneProdotto.vue' 
import Statistiche from '@/components/Statistiche.vue'	


export default {
    name:"Prodotti",

    props:["modulo"], 

    components:{
        Pagine,
        ISwitch,
        Select2,
        SchedaProdotto,
        draggable: VueDraggableNext,
        Upload,
		PopupOrdinamento,
		PianoOccupazione,
		SchedaVisualizzazioneProdotto,
		Statistiche
    },

    data:function(){
		return{
            apiStartup:'travel/prodotti',
	        filters:global.filters,
			rs : utils.generateRandomString(30),
			GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
	        arrayProdotti:new Array(),
	        prodottoSelezionato:{},
			arrayCategorie : new Array(),
			arrayTipologieProdotto : new Array(), 
	        search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
	            ID_PRODOTTO:{TYPE:'TEXT',VALUE:''},
                CODICE_PRODOTTO:{TYPE:'TEXT',VALUE:''},
                FK_ID_TIPO:{TYPE:'COMBO',VALUE:''}, 
	            DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_AZIENDA_FORNITORE:{TYPE:'COMBO',VALUE:''},         	
				FK_ID_STATO:{TYPE:'COMBO',VALUE:''},               
                FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},               
                FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''},              
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
				FK_ID_CAMPO_OLD:{TYPE:'TEXT',VALUE:''},
	        },
			ordineHasChanged: false,
			indirizzoSelezionato:{},
			prodottoExt : {lang:{},dettagli:{}},
            argsProdotto:{
                modalita:'MOONLIGHT_uploadImgGeo',
				tipologia:1
			},
			statsProdotti:{},
			searchStatisticheOrdini:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},
				FK_ID_PROVINCIA:{TYPE:'COMBO',VALUE:''},
				FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''},
				FK_ID_TIPO:2,
				FLG_GRUPPI:{TYPE:'COMBO',VALUE:''},
				FK_ID_NETWORK:{TYPE:'COMBO',VALUE:''},					
            },
			arrayStatisticheOrdiniFatt : new Array(),
			searchStatisticheOrdiniFatt:{
				PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},
				FK_ID_PROVINCIA:{TYPE:'COMBO',VALUE:''},
				FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''},
				FK_ID_TIPO:2,
				FLG_GRUPPI:{TYPE:'COMBO',VALUE:''},
				FK_ID_NETWORK:{TYPE:'COMBO',VALUE:''},
				DATA_CREAZIONE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},	
			}
			
      	}
    },

    methods:{
        getProdotti : function(){
            utils.ajax(this.apiStartup,{MODULO:this.modulo,ANNO:this.search.ANNO_ORDINE.VALUE}, (response) => {
				this.ordineHasChanged = false;
                this.arrayProdotti = response.data;
            });
        },	

        nuovoProdotto : function(){
            this.$refs.schedaProdotto.openSchedaProdotto({ID_PRODOTTO:0,FK_ID_MODULO:this.modulo,FK_ID_MODELLO_VENDITA:1,FK_ID_TAG_SPECIALE:0,lang:{FLG_ATTIVO:1},dettagli:{},opzioni:new Array()});
        },		   

        modificaProdotto : function(prodotto){
            this.$refs.schedaProdotto.openSchedaProdotto(prodotto);
        },	

        deleteProdotto : function(prodotto){
            utils.alert.confirm("Sei sicuro di voler eliminare il prodotto <strong>"+prodotto.TITOLO+"</strong>?",()=>{
                utils.ajax("travel/prodotto/delete",{ID_PRODOTTO:prodotto.ID_PRODOTTO}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getProdotti();
                    }
                });
            },this.$root)
        }, 

        updateFlgPrenotabile : function(prodotto){
			prodotto.FLG_PRENOTABILE = prodotto.FLG_PRENOTABILE == 1 ? 0 : 1;
			utils.ajax("travel/prodotto/update",prodotto, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					prodotto.FLG_PRENOTABILE = prodotto.FLG_PRENOTABILE == 1 ? 0 : 1;
				}
			});                
        },	

		updateFlgVisibile : function(prodotto){
			prodotto.FLG_VISIBILE = prodotto.FLG_VISIBILE == 1 ? 0 : 1;
			utils.ajax("travel/prodotto/update",prodotto, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					prodotto.FLG_VISIBILE = prodotto.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
        },	

		updateFlgAttivo : function(prodotto){
            setTimeout(() => {
                utils.ajax("travel/prodotto/lang/update/flgattivo",prodotto, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getProdotti();
                    }
                });                
            },250)
        },	

        calendario : function(prodotto){
            this.$root.prodotto = prodotto;
			this.$root.sottoProdotto = {};
            global.router.push("Calendario");
        },
		
		goToExtra : function(prodotto){
            this.$root.prodotto = prodotto;
			this.$root.sottoProdotto = {};
            global.router.push("Extra");
		},

        camere : function(prodotto){
            this.$root.prodotto = prodotto;
            global.router.push("Camere");
        },	

        openSchedaProdotto: function(prodotto){
            this.$refs.schedaProdotto.openSchedaProdotto(prodotto);
        },

		modificaContenuti : function(prodotto){
            this.$root.prodotto = prodotto;
            global.router.push("ProdottoLang");
        },

      	esportazione : function(formato){
        	utils.report.token({modalita:'esportaProdotti',formato:formato});
      	},

		ordineChangeHandler : function(event){
			this.arrayProdotti = this.filters.draggableChangeHandler(event,this.arrayProdotti,this.search,"ID_PRODOTTO");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arrayProdotti.length ; i++){
					arr.push({"ID": this.arrayProdotti[i].ID_PRODOTTO,"O": this.arrayProdotti[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'ProdottoTuristico',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getProdotti();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},

		modificaOrdinamento : function(prodotto){
			this.$refs.modalOrdinamento.openPopUpOrdinamento(prodotto,this.arrayProdotti,"ID_PRODOTTO");
		},
		
		handlerOrdinamentoAggiornato : function(_array){
			this.arrayProdotti = utils.clone(_array);
			this.salvaOrdinamento();
		},

		openOffCanvas : function(){
			var myOffcanvas = document.getElementById('panel_right_prodotto')
			var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
			bsOffcanvas.show();
		},

		openSchedaVisualizzazioneProdotto : function(prodotto,tab = ""){
			
			this.prodottoSelezionato = utils.clone(prodotto);	
			this.$refs.schedaVisualizzazioneProdotto.openSchedaVisualizzazioneProdotto(prodotto.ID_PRODOTTO,tab);

		}, 

		datePickerChangeHandler : function(){
			this.search.DATA_ADD = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
			if (this.dataCreazioneRange != null && this.dataCreazioneRange.length > 0){
				this.search.DATA_ADD = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRange[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRange[1])};
			}
		}, 

		dataCreazioneRangeChangeHandler : function(){
			this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
			if (this.dataCreazioneRangeFatt != null && this.dataCreazioneRangeFatt.length > 0){
				this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRangeFatt[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRangeFatt[1])};
			}
		},

		getStatisticheOrdiniFatt(){
			utils.ajax('travel/statistiche/ordini/prodotti',this.searchStatisticheOrdiniFatt, (response) => {
				this.arrayStatisticheOrdiniFatt = response.data;
			});			
		},
		
        getStatisticheOrdiniChart : function(){
            //$('.grafico').removeAttr("_echarts_instance_");
            var arr = document.getElementsByClassName("grafico");//.removeAttribute("disabled");
            for (var i = 0; i < arr.length; i++){
                arr[i].removeAttribute("_echarts_instance_");
                arr[i].innerHTML = "";
            }
            utils.ajax('travel/statistiche/ordini/importo',this.searchStatisticheOrdini, (response) => {
                var option= {};
                //var chart1 = echarts.init(document.getElementById('chart1'));
                let chart1 = echarts.getInstanceByDom (document.getElementById ('chart1')); // get some words DOM node has echarts instance.
                if (chart1 == null) {// If not, it is initialized.
                    chart1 = echarts.init(document.getElementById('chart1'));
                }

                var arrayDistinctMesi = utils.array.distinct(response.data,"MESE","MESE");
                var arrayDistinctAnni = utils.array.distinct(response.data,"ANNO","ANNO");
                var series = new Array();
                var arrayAnni = [];
                for (var i = 0 ; i < arrayDistinctAnni.length ; i++){
                    arrayAnni.push(arrayDistinctAnni[i].ANNO.toString());
                }
                var arrayMesi = [];
                for (var i = 0 ; i < arrayDistinctMesi.length ; i++){
                    arrayMesi.push(utils.array.select(utils.arrayMesi,"ID_MESE",arrayDistinctMesi[i].MESE,1)[0].DESCRIZIONE);
                }
                for (var i = 0 ; i < arrayDistinctAnni.length ; i++){
                    var serie = {
                        name: arrayDistinctAnni[i].ANNO.toString(),
                        type: 'bar',
                        barGap: 0.2,
                        data: []
                    };
                    for (var j = 0 ; j < arrayDistinctMesi.length ; j++){
                        var trovato = 0;
                        for (var k = 0 ; k < response.data.length ; k++ ){
                            if (response.data[k].MESE == arrayDistinctMesi[j].MESE && response.data[k].ANNO == arrayDistinctAnni[i].ANNO){
                            trovato = 1;
                            serie.data.push(parseFloat(response.data[k].IMPORTO));
                            break;
                            }
                        }
                        if (trovato == 0){
                            serie.data.push(0);
                        }
                    }
                    series.push(serie);
                }
                option = {
                    tooltip: {
                        trigger: 'item',
                        formatter : (params) => {
                            return "<strong>" + params.name + " " +  params.seriesName + "</strong><br/>€ "+ utils.formatNumber(params.data) ;
                        }
                    },
                    legend: {
                        data: arrayAnni
                    },
                    toolbox: {
                        show: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        feature: {
                            mark: {show: true},
                            magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
                            restore: {show: true},
                            saveAsImage: {show: true}
                        }
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisTick: {show: false},
                            data: arrayMesi
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel : {
                            formatter : (param) => {
                                return utils.formatNumberInt(param);//"<strong>" + params.name + " " +  params.seriesName + "</strong><br/>"+ params.data ;
                            }
                            },
                        }
                    ],
                    series: series
                };    
                chart1.setOption(option);
                         
            });
        },

		statisticheStartup : function(){
			utils.ajax('travel/statistiche/prodotti/startup',{}, (response) => {
				this.statsProdotti = response.data;
				this.getStatisticheOrdiniChart();
			});
		},

    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayProdotti,this.search);
            return arr;
        },

		paginatedListFatt(){
            var arr = utils.inGrid(this.arrayStatisticheOrdiniFatt,this.searchStatisticheOrdiniFatt);
            return arr;
        },

        moduleName(){
           return this.filters.moduleName(this.modulo);
        }, 

        iconaProdotto(){
           return this.filters.iconaProdotto(this.modulo);
        },	
  
		tipoModulo(){
			switch (this.modulo){
				case 1:		return '25';
				case 2:		return '30';
				case 3:		return '35';
				case 4:		return '40';
				case 5:		return '45';
				case 47:	return '30';
			}
			return "";
		},

		getTotali(){
			var attive = 0;
            var inattive = 0; 
			for (var i = 0; i < this.arrayProdotti.length; i++) {
				if (this.arrayProdotti[i].FLG_ATTIVO == 1) {
					attive++;
				} else {
					inattive++;
				}
            }
            return {
                COUNT:this.arrayProdotti.length,
				ADVOK:attive,
				ADVKO:inattive
            }
        },

    }, 	 

	watch:{
	
	},

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.filters.wait(300,()=>{
			utils.ajax('travel/prodotti/startup',{MODULO:this.modulo}, (response) => {
				this.arrayProdotti = response.data.PRODOTTI;
				var arrayCategorie = response.data.CATEGORIE;
				for (var i = 0 ; i < arrayCategorie.length ; i++){
					this.arrayCategorie.push({id:arrayCategorie[i].ID_CATEGORIA, text:arrayCategorie[i].DESCRIZIONE });
				}
				this.filters.wait(300,()=>{
					var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
					var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
					return new bootstrap.Tooltip(tooltipTriggerEl)
					})
				});

				var idTipologica = 0;
				switch (this.modulo){
					//attivita
					case 1:
						idTipologica = 25;
						break;
					//hotel
					case 2:
						idTipologica = 30;
						break;
					//pacchetti
					case 3:
						idTipologica = 35;
						break;
					//voli
					case 4:
						idTipologica = 40;
						break;
					//camere
					case 5:
						idTipologica = 45;
						break;
				}
				this.arrayTipologieProdotto = utils.array.select(response.data.OPZIONI,"FK_ID_TIPOLOGICA",idTipologica);
			});
		});

        if (this.$root.savedSearch.hasOwnProperty("modulo") && this.$root.savedSearch.modulo == this.modulo){
            this.search.DESCRIZIONE.VALUE = this.$root.savedSearch.TITOLO;
			this.$root.savedSearch.TITOLO = "";
        }
    },

}
</script>


<style scoped>
	.grafico { min-height: 451px; }
</style>