<template>
    <div>
        
        <div class="container">
        <h1>JavaScript - Drag and Drop</h1>
        <div class="drop-targets">
            <div class="box" @drop="drop">
                <div @dragstart="dragStart" class="item" id="item" draggable="true">
                </div>
            </div>
            <div class="box" @drop="drop"></div>
            <div class="box" @drop="drop"></div>
        </div>
    </div>


    </div>
</template>



<script>
export default {
    name:"Test",
    data:function(){
        return {
            filters:global.filters,            
            item:{},
            boxes:{},
        }
    },
    methods: {
        dragStart:function(e) {
            e.dataTransfer.setData('text/plain', e.target.id);
            setTimeout(() => {
                e.target.classList.add('hide');
            }, 0);
        },
        dragEnter:function (e) {
            e.preventDefault();
            e.target.classList.add('drag-over');
        },
        dragOver:function (e) {
            e.preventDefault();
            e.target.classList.add('drag-over');
        },

        dragLeave:function(e) {
            e.target.classList.remove('drag-over');
        },
        drop:function(e) {
            e.target.classList.remove('drag-over');
            // get the draggable element
            const id = e.dataTransfer.getData('text/plain');
            const draggable = document.getElementById(id);

            // add it to the drop target
            e.target.appendChild(draggable);

            // display the draggable element
            draggable.classList.remove('hide');
        }


    },
    created:function(){
        utils.wait(()=>{            
            this.boxes = document.querySelectorAll('.box');
            this.boxes.forEach(box => {
                
                //box.addEventListener('dragenter', this.dragEnter)
                box.addEventListener('dragover', this.dragOver);
                //box.addEventListener('dragleave', this.dragLeave);
                //box.addEventListener('drop', this.drop);
            });
        });
    },
}
</script>


