<template>

    <!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="mb-xs-1 text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
					<a href="#" class="d-none d-lg-inline-block text-dark fw-semibold cursor-pointer"> Preferenze </a> <span class="d-none d-lg-inline-block">\</span>
                    <span class="text-muted ms-1">Configurazione Siti </span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
						
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 position-10" style="text-align: left;">

					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(livello == 2 ? 21 : 22)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(livello == 2 ? 21 : 22)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
		            </div>
		        </div>
			</div>
		</div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#webconf-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>Info
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#webconf-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-pencil7 me-2"></i>Testi
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#webconf-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-sphere me-2"></i>Template
							</div>
						</a>
					</li>
				</ul>
 
				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
                            <div class="input-group">
                                <span class="input-group-text"><i class="ph-globe-hemisphere-east"></i></span>
                                <select class="form-select form-select-sm" data-placeholder="Ente" v-model="idEnteCorrente" @change="getConfigurazioniEnte()">
                                    <option v-for="ente in $root.arrayEnti" v-bind:key="ente.ID_ENTE" v-bind:value="ente.ID_ENTE"> {{ ente.LABEL }}</option>
                                </select>
                            </div>
						</li>
						<li class="nav-item">
	                        
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
    <!-- /page header -->
    
    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">

        <div class="card shadow mb-2">
			<div class="card-header d-lg-none p-1">
				<div class="d-grid gap-2">
					<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
						VEDI INFO
					</a>
				</div>
			</div>
            <div class="collapse show" id="collapse-all" style="">				
                <div class="card-body pb-2">
                    <div class="row">
                        <div class="col-12 col-md-10 col-lg-10 mbx-1">
                            <div class="alert alert-2 alert-secondary border-0">
                                <p style="padding: 0 2px; position:relative; top:5px;">
                                    I campi contrassegnati in <span class="text-danger">rosso</span> sono <strong>Obbligatori</strong>. 
                                    Inserire dati corretti e veritieri, la maggior parte sono pubblicati su documenti fiscali.
                                </p>	  
                            </div>                              
                        </div>
                        <div class="col-12 col-md-2 col-lg-2 mbx-1 d-grid gap-2">
                            <button type="submit" class="btn btn-primary btn-block float-end" v-on:click="salvaOpzioni()"><i class="icon-floppy-disk me-2"></i>Salva Modifiche</button>
                        </div>
                    </div>
				</div>	
			</div>
        </div>

		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="webconf-a">
        		<div class="card shadow mb-0 mb-xs-1">
                    <div class="card-body box-card mt-0 mb-0" id="box-card">
                        <div class="box-config" id="tableFixHead">
                            <div class="row">
		                        <div class="col-12 col-lg-8 mb-3">
                                    <fieldset v-if="arrayOpzioniConfigurazione.length > 0">
                                        <legend class="font-weight-semibold mb-2">Dati principali</legend>  
                                        <div class="row">
                                            <div v-for="opz1 in arrayOpzioniConfigurazione[0].opzioni" v-bind:key="opz1.LABEL" class="mb-2" v-bind:class="opz1.COL">
                                                <label class="form-label text-danger mb-1" v-html="opz1.ETICHETTA"></label>
                                                <IControl :campo="opz1" v-model="opz1.VALORE" :inputgroup="opz1.GROUP" :icon="opz1.ICON"></IControl>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
		                        <div class="col-12 col-lg-4" style="border-left: 1px solid rgba(0,0,0,.125);">
                                    <fieldset v-if="arrayOpzioniConfigurazione.length > 0" style="min-height: 14.4rem;">
                                        <legend class="font-weight-semibold mb-2">Contenuti Seo</legend>
                                        <ul class="nav nav-tabs nav-tabs-highlight">
                                            <li class="nav-item" v-for="seo in arrayOpzioniConfigurazione[1].opzioni" v-bind:key="seo.LABEL" >
                                                <a :href="'#tab-'+seo.ID_OPZIONE_BASE" class="nav-link" data-bs-toggle="tab" :id="'link_'+seo.ID_OPZIONE_BASE">{{ seo.ETICHETTA }}</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div class="tab-pane fade mt-2" v-for="seo in arrayOpzioniConfigurazione[1].opzioni" v-bind:key="seo.LABEL" :id="'tab-'+seo.ID_OPZIONE_BASE">
                                                <IControl :campo="seo" v-model="seo.VALORE" :inputgroup="seo.GROUP" :icon="seo.ICON"></IControl>
                                            </div>  
                                        </div>
                                    </fieldset>
                                </div>
                            </div>                    
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="webconf-b">
                <div class="card shadow mb-0 mb-xs-1">
                    <div class="card-body box-card mt-0 mb-0" id="box-card">
                        <div class="box-config" id="tableFixHead">
                            <div class="row">
                                <div class="col-lg-12">
                                    <fieldset v-if="arrayOpzioniConfigurazione.length > 0">
                                        <legend class="font-weight-semibold mb-2">Contenuti del sito</legend>
                                        <ul class="nav nav-tabs nav-tabs-highlight">
                                            <li class="nav-item" v-for="testi in arrayOpzioniConfigurazione[2].opzioni" v-bind:key="testi.LABEL" >
                                                <a :href="'#tab-'+testi.ID_OPZIONE_BASE" class="nav-link" data-bs-toggle="tab" :id="'link_'+testi.ID_OPZIONE_BASE">{{ testi.ETICHETTA }}</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div class="tab-pane fade mt-2" v-for="testi in arrayOpzioniConfigurazione[2].opzioni" v-bind:key="testi.LABEL" :id="'tab-'+testi.ID_OPZIONE_BASE">
                                                <IControl :campo="testi" v-model="testi.VALORE" :inputgroup="testi.GROUP" :icon="testi.ICON"></IControl>
                                            </div>  
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="webconf-c">
                <div class="card shadow mb-0 mb-xs-1">
                    <div class="card-body box-card mt-0 mb-0" id="box-card">
                        <div class="box-config" id="tableFixHead">
                            <div class="row">
                                <div class="col-12 col-lg-8 mb-3">
                                    <fieldset v-if="arrayOpzioniConfigurazione.length > 0">
                                        <legend class="font-weight-semibold mb-2">Template</legend>
                                        <div class="row">
                                            <div v-for="opz5 in arrayOpzioniConfigurazione[4].opzioni" v-bind:key="opz5.LABEL" class="mb-2" v-bind:class="opz5.COL">
                                                <label class="form-label text-danger mb-1" v-html="opz5.ETICHETTA"></label>
                                                <IControl :campo="opz5" v-model="opz5.VALORE" :inputgroup="opz5.GROUP" :icon="opz5.ICON"></IControl>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="col-12 col-lg-4" style="border-left: 1px solid rgba(0,0,0,.125);">
                                    <fieldset class="mb-2">
                                        <legend class="font-weight-semibold p-2 mb-1">Loghi</legend>
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <div class="form-group-1">
                                                    <div class="media mt-0">
                                                        <div class="me-3">
                                                            <a href="#">
                                                                <img :src="filters.getImgPath('ENTI',imgEnte(291))" width="100" height="100" class="rounded-pill" alt="">
                                                            </a>
                                                        </div>
                                                        <div class="media-body">
                                                            <span class="form-text text-muted"><strong>Logo Header</strong><br />Formati: jpg, png<br />Peso: 500Kb</span>
                                                            <button class="btn btn-info float-end" v-on:click="this.filters.openPopUp('popUpUploadLogoHeader')" style="position: relative; top: -25px;">Seleziona</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group-1">
                                                    <div class="media mt-0">
                                                        <div class="me-3">
                                                            <a href="#">
                                                                <img :src="filters.getImgPath('ENTI',imgEnte(292))" width="100" height="100" class="rounded-pill" alt="">
                                                            </a>
                                                        </div>
                                                        <div class="media-body">
                                                            <span class="form-text text-muted"><strong>Logo Footer</strong><br />Formati: jpg, png<br />Peso: 500Kb</span>
                                                            <button class="btn btn-info float-end" v-on:click="this.filters.openPopUp('popUpUploadLogoFooter')" style="position: relative; top: -25px;">Seleziona</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </fieldset> 
                                    <fieldset v-if="arrayOpzioniConfigurazione.length > 0">
                                        <legend class="font-weight-semibold mb-2">Manutenzione</legend>
                                        <div class="row">
                                            <div v-for="opz4 in arrayOpzioniConfigurazione[3].opzioni" v-bind:key="opz4.LABEL" class="mb-2" v-bind:class="opz4.COL">
                                                <label class="form-label text-danger mb-1" v-html="opz4.ETICHETTA"></label>
                                                <IControl :campo="opz4" v-model="opz4.VALORE" :inputgroup="opz4.GROUP" :icon="opz4.ICON"></IControl>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>



    <div class="modal fade" id="popUpUploadLogoHeader" role="dialog"  tabindex="-1" >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> Upload </span>
                    </h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">  
                    <Upload @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)" :args="argsLogoAzienda"  :qs="'&ID_ENTE='+idEnteCorrente"></Upload>
                </div>
            </div>
        </div>
    </div>  

    <div class="modal fade" id="popUpUploadLogoFooter" role="dialog"  tabindex="-1" >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> Upload </span>
                    </h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">  
                    <Upload @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)" :args="argsLogoFooter" :qs="'&ID_ENTE='+idEnteCorrente"></Upload>
                </div>
            </div>
        </div>
    </div>  

</template>



<script>
import IControl from '@/components/utils/IControl.vue'
import Upload from '@/components/utils/Upload.vue'

export default {
    name:"ConfWebsite",	

    components:{
        IControl,
        Upload
    },

    data: function () {
        return {   
            filters:global.filters,
            arrayFunzionalita: new Array(),
            searchFunzionalita:{

            },

            idEnteCorrente:0,
            arrayOpzioniConfigurazione:new Array(),
            arrayOpzioniConfigurazioneDefault : new Array(
                {
                    titolo:"Principale",
                    icona:'',
                    visible:false,
                    id:1,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:201, ETICHETTA:'Data apertura Sito',   LABEL:'SITE_STARTDATE',         TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-2',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-calendar'             },
                        {ID_OPZIONE_BASE:202, ETICHETTA:'Titolo Sito',          LABEL:'SITE_NAME',              TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-info-circle'          },
                        {ID_OPZIONE_BASE:203, ETICHETTA:'Slogan Sito',          LABEL:'SITE_SLOGAN',            TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-6',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-align-center'         },                        
                        {ID_OPZIONE_BASE:204, ETICHETTA:'URL Sito',             LABEL:'SITE_URL',               TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-link'                 },
                        {ID_OPZIONE_BASE:205, ETICHETTA:'Email Admin',          LABEL:'ADMIN_MAIL',             TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-envelope'             },                       
                        {ID_OPZIONE_BASE:206, ETICHETTA:'Tema Sito',            LABEL:'SITE_THEME',             TYPE:"TEXT",    VALORE:'-',     COL:'col-6 col-md-2',       CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-eye'                  },
                        {ID_OPZIONE_BASE:207, ETICHETTA:'Tema Admin',           LABEL:'ADMIN_THEME',            TYPE:"TEXT",    VALORE:'-',     COL:'col-6 col-md-2',       CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-eye'                  },                                                
                        {ID_OPZIONE_BASE:208, ETICHETTA:'URL Rewrite',          LABEL:'ADMIN_URL_REWRITE',      TYPE:"SELECT",  VALORE:0,       COL:'col-6 col-md-2',       OPZIONI:new Array({ID:'',DESCRIZIONE:'Seleziona'},{ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})  },
                        {ID_OPZIONE_BASE:209, ETICHETTA:'Codice Sicurezza',     LABEL:'ADMIN_CAPTCHA',          TYPE:"SELECT",  VALORE:0,       COL:'col-6 col-md-2',       OPZIONI:new Array({ID:'',DESCRIZIONE:'Seleziona'},{ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})  },
                        {ID_OPZIONE_BASE:210, ETICHETTA:'Mostra Editor',        LABEL:'ADMIN_ADVANCE_EDITOR',   TYPE:"SELECT",  VALORE:0,       COL:'col-6 col-md-2',       OPZIONI:new Array({ID:'',DESCRIZIONE:'Seleziona'},{ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})  },
                        {ID_OPZIONE_BASE:211, ETICHETTA:'Mostra Banner',        LABEL:'MOSTRA_BANNER',          TYPE:"SELECT",  VALORE:0,       COL:'col-6 col-md-2',       OPZIONI:new Array({ID:'',DESCRIZIONE:'Seleziona'},{ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})  },
                        {ID_OPZIONE_BASE:212, ETICHETTA:'TimeOut Sessione',     LABEL:'TIMEOUT_SESSIONE',           TYPE:"TEXT",  VALORE:3600,  COL:'col-6 col-md-2',       },
                        {ID_OPZIONE_BASE:213, ETICHETTA:'?',                    LABEL:'RELOAD_SESSIONE_SCADUTA',    TYPE:"SELECT",  VALORE:1,   COL:'col-6 col-md-2',       OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})  },
                        {ID_OPZIONE_BASE:214, ETICHETTA:'?',                    LABEL:'FLG_UNA_SESSIONE_PER_UTENTE',TYPE:"SELECT",  VALORE:0,   COL:'col-6 col-md-2',       OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})  },
                        {ID_OPZIONE_BASE:215, ETICHETTA:'Sogliola',             LABEL:'SOGLIA_MULTIPLE_INSERT',     TYPE:"TEXT",  VALORE:3000,  COL:'col-6 col-md-2',       },
                        {ID_OPZIONE_BASE:216, ETICHETTA:'DropBox',              LABEL:'DROPBOX_CLIENT_ID',          TYPE:"TEXT",  VALORE:'',    COL:'col-6 col-md-2',       },

                        {ID_OPZIONE_BASE:221, ETICHETTA:'Lingua di Default',    LABEL:'LANG_DEFAULT',       TYPE:"TEXT",    VALORE:'-',     COL:'col-6 col-md-2',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-user-cog' },
                        {ID_OPZIONE_BASE:222, ETICHETTA:'Formato ora Locale',   LABEL:'LANG_FORMAT_DATA',   TYPE:"TEXT",    VALORE:'-',     COL:'col-6 col-md-2',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-user-cog' },
                        {ID_OPZIONE_BASE:223, ETICHETTA:'Timezone del Sito',    LABEL:'LANG_TIMEZONE',      TYPE:"TEXT",    VALORE:'-',     COL:'col-6 col-md-2',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-user-cog' },
                        {ID_OPZIONE_BASE:224, ETICHETTA:'Fuso orario',          LABEL:'LANG_JET_LAG',       TYPE:"TEXT",    VALORE:'-',     COL:'col-6 col-md-2',   CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-user-cog' },
                        {ID_OPZIONE_BASE:225, ETICHETTA:'Formato Data',         LABEL:'LANG_LOCAL_TIME',    TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-4',  CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-user-cog' },                       

                        {ID_OPZIONE_BASE:231, ETICHETTA:'Api Microsoft Client ID',      LABEL:'API_MICROSOFT_CLIENT_ID',        TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-3 ',     CLASSE_CTRL:'',     GROUP:1,    ICON: 'fab fa-windows'  },
                        {ID_OPZIONE_BASE:232, ETICHETTA:'Api Microsoft Client Secret',  LABEL:'API_MICROSOFT_CLIENT_SECRET',    TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-3',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'fab fa-windows'  },                        
                        {ID_OPZIONE_BASE:233, ETICHETTA:'Api Google Key ID',            LABEL:'API_GOOGLE_KEY_ID',              TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-3',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'fab fa-google'   },                     
                        {ID_OPZIONE_BASE:234, ETICHETTA:'Api Google Client Secret',     LABEL:'API_GOOGLE_CLIENT_SECRET',       TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-3',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'fab fa-google'   },                                               
                          
                    )
                },
                {
                    titolo:"SEO",
                    icona:'',
                    visible:true,
                    id:2,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:251, ETICHETTA:'Titolo',           LABEL:'SEO_TITLE',          TYPE:"TEXT",        VALORE:'-',     COL:'col-12',   CLASSE_CTRL:'',                 GROUP:1,    ICON: 'far fa-user-cog' },
                        {ID_OPZIONE_BASE:252, ETICHETTA:'Descrizione',      LABEL:'SEO_DESCRIPTION',    TYPE:"TEXTAREA",    VALORE:'-',     COL:'col-12',   CLASSE_CTRL:'',     ROWS:'5',   GROUP:1,    ICON: 'far fa-user-cog' },
                        {ID_OPZIONE_BASE:253, ETICHETTA:'Parole Chiavi',    LABEL:'SEO_TAGS',           TYPE:"TEXTAREA",    VALORE:'-',     COL:'col-12',   CLASSE_CTRL:'',                 GROUP:1,    ICON: 'far fa-user-cog' },
                        {ID_OPZIONE_BASE:254, ETICHETTA:'Microdati',        LABEL:'SEO_MICRODATA',      TYPE:"TEXTAREA",    VALORE:'-',     COL:'col-12',   CLASSE_CTRL:'',     ROWS:'14',   GROUP:1,    ICON: 'far fa-user-cog' },
                    
                    )
                },
                {
                    titolo:"Custom",
                    icona:'',
                    visible:false,
                    id:3,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:261, ETICHETTA:'Orari',                LABEL:'CUSTOM_1',   TYPE:"TEXTAREA",    VALORE:'-',     COL:'col-12',  CLASSE_CTRL:'',     ROWS:'15',   GROUP:1,    ICON: ''    },
                        {ID_OPZIONE_BASE:262, ETICHETTA:'Foot1',                LABEL:'CUSTOM_2',   TYPE:"TEXTAREA",    VALORE:'-',     COL:'col-12',  CLASSE_CTRL:'',     ROWS:'15',   GROUP:1,    ICON: ''    },
                        {ID_OPZIONE_BASE:263, ETICHETTA:'Foot2',                LABEL:'CUSTOM_3',   TYPE:"TEXTAREA",    VALORE:'-',     COL:'col-12',  CLASSE_CTRL:'',     ROWS:'15',   GROUP:1,    ICON: ''    },
                        {ID_OPZIONE_BASE:264, ETICHETTA:'Foot3',                LABEL:'CUSTOM_4',   TYPE:"TEXTAREA",    VALORE:'-',     COL:'col-12',  CLASSE_CTRL:'',     ROWS:'15',   GROUP:1,    ICON: ''    },
                        {ID_OPZIONE_BASE:265, ETICHETTA:'Copyright',            LABEL:'CUSTOM_5',   TYPE:"TEXTAREA",    VALORE:'-',     COL:'col-12',  CLASSE_CTRL:'',     ROWS:'15',   GROUP:1,    ICON: ''    },
                        {ID_OPZIONE_BASE:266, ETICHETTA:'Covid-19',             LABEL:'CUSTOM_6',   TYPE:"TEXTAREA",    VALORE:'-',     COL:'col-12',  CLASSE_CTRL:'',     ROWS:'15',   GROUP:1,    ICON: ''    },
                        {ID_OPZIONE_BASE:267, ETICHETTA:'Green Pass',           LABEL:'CUSTOM_7',   TYPE:"TEXTAREA",    VALORE:'-',     COL:'col-12',  CLASSE_CTRL:'',     ROWS:'15',   GROUP:1,    ICON: ''    },

                    )
                },
                {
                    titolo:"Manutenzione",
                    icona:'far fa-life-ring',
                    visible:false,
                    id:4,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:271, ETICHETTA:'Sito Offline?',            LABEL:'OFFILINE',           TYPE:"SELECT",      VALORE:0,       COL:'col-4 col-md-3',  OPZIONI:new Array({ID:'',DESCRIZIONE:'Seleziona'},{ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})  },
                        {ID_OPZIONE_BASE:272, ETICHETTA:'Data riapertura',          LABEL:'OFFLINE_OPENDATA',   TYPE:"TEXT",        VALORE:'-',     COL:'col-8 col-md-4',  CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-calendar'             },
                        {ID_OPZIONE_BASE:273, ETICHETTA:'Titolo',                   LABEL:'OFFLINE_TITLE',      TYPE:"TEXT",        VALORE:'-',     COL:'col-12 col-md-5',  CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-lock-open'            },
                        {ID_OPZIONE_BASE:274, ETICHETTA:'Sottotitolo',              LABEL:'OFFLINE_SUBTITLE',   TYPE:"TEXT",        VALORE:'-',     COL:'col-12',           CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-ballot-check'         },
                        {ID_OPZIONE_BASE:275, ETICHETTA:'Messaggio sito offline',   LABEL:'OFFLINE_MESSAGE',    TYPE:"TEXTAREA",    VALORE:'-',     COL:'col-12',           CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-ballot-check'         },
                    )
                },
                {
                    titolo:"Design",
                    icona:'',
                    visible:false,
                    id:5,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:281, ETICHETTA:'Header Top',           LABEL:'HEADER_TOP',   TYPE:"SELECT",  VALORE:0,   COL:'col-6 col-md-2',     OPZIONI:new Array({ID:'',DESCRIZIONE:'Seleziona'},{ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})   },
                        {ID_OPZIONE_BASE:282, ETICHETTA:'Header Bar',           LABEL:'HEADER_BAR',   TYPE:"SELECT",  VALORE:0,   COL:'col-6 col-md-2',     OPZIONI:new Array({ID:'',DESCRIZIONE:'Seleziona'},{ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})   },
                        {ID_OPZIONE_BASE:283, ETICHETTA:'Header Principale',    LABEL:'HEADER_MAIN',  TYPE:"SELECT",  VALORE:0,   COL:'col-6 col-md-2',     OPZIONI:new Array({ID:'',DESCRIZIONE:'Seleziona'},{ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})   },
                        {ID_OPZIONE_BASE:284, ETICHETTA:'Carosello',            LABEL:'SEARCH_MAIN',  TYPE:"SELECT",  VALORE:0,   COL:'col-6 col-md-4',     OPZIONI:new Array(
                                                                                                                                                                                {ID:0,DESCRIZIONE:'Ricerca a sinistra allineata in mezzo con meteo.'},
                                                                                                                                                                                {ID:1,DESCRIZIONE:'Ricerca in Centro allineata a sinistra.'},
                                                                                                                                                                                {ID:2,DESCRIZIONE:'Ricerca in basso allineata a sinistra.'},
                                                                                                                                                                                {ID:3,DESCRIZIONE:'Ricerca a sinistra allineata in mezzo.'},
                                                                                                                                                                                {ID:4,DESCRIZIONE:'Ricerca al centro allineata a sinistra a piena pagina.'},
                                                                                                                                                                                {ID:5,DESCRIZIONE:'Ricerca al centro allineata a sinistra.'},
                                                                                                                                                                                {ID:6,DESCRIZIONE:'Ricarca a cavallo immagine allineato a sinistra.'},
                                                                                                                                                                                {ID:7,DESCRIZIONE:'Ricerca al centro allineata a sinistra a  mezza pagina.'},
                                                                                                                                                                            )},
                    )
                },
                {
                    titolo:"Loghi",
                    icona:'',
                    visible:false,
                    id:6,
                    opzioni: new Array(                                                                                                                                                       
                        {ID_OPZIONE_BASE:291, ETICHETTA:'Header ente',          LABEL:'HEADER_LOGO_ENTE',       TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-6',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-ballot-check'         },
                        {ID_OPZIONE_BASE:292, ETICHETTA:'Footer ente',          LABEL:'FOOTER_LOGO_ENTE',       TYPE:"TEXT",    VALORE:'-',     COL:'col-12 col-md-6',      CLASSE_CTRL:'',     GROUP:1,    ICON: 'far fa-calendar'             },

                    )
                },
                
            ),
            argsLogoAzienda:{
                modalita:'MOONLIGHT_uploadLogoConfig',
                tipo:1,//azienda
            },
            
            argsLogoFooter:{
                modalita:'MOONLIGHT_uploadLogoConfig',
                tipo:2,//footer
            },
            arrayOpzioniCustom : new Array(),

        }
    },

    methods: {
        getOpzioni : function(){
            utils.ajax('core/admin/opzioni',{ID_ENTE:this.idEnteCorrente}, (response) => {
                var arrayOpzioni = response.data;
                this.arrayOpzioniConfigurazione = utils.clone(this.arrayOpzioniConfigurazioneDefault);
                for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                    for (var j = 0 ; j < this.arrayOpzioniConfigurazione[i].opzioni.length ; j++){
                        this.arrayOpzioniConfigurazione[i].opzioni[j].CLASSE_CTRL = 'input-sm';
                        this.arrayOpzioniConfigurazione[i].opzioni[j].VMODEL = 'VALORE';
                        this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE = 0;
                        for (var k = arrayOpzioni.length-1 ; k > -1  ; --k){
                            if (this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE_BASE == arrayOpzioni[k].ID_OPZIONE_BASE){
                                this.arrayOpzioniConfigurazione[i].opzioni[j].VALORE = arrayOpzioni[k].VALORE;
                                this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE = arrayOpzioni[k].ID_OPZIONE;
                                arrayOpzioni.splice(k,1);
                                break;
                            }
                        }
                    }
                }
                this.arrayOpzioniCustom = utils.array.select(arrayOpzioni,"ID_OPZIONE_BASE",0);
                setTimeout(() => {
                    document.getElementById("tab-251").classList.add("show");
                    document.getElementById("tab-251").classList.add("active");                    
                    document.getElementById("link_251").classList.add("active");
                    document.getElementById("tab-261").classList.add("show");
                    document.getElementById("tab-261").classList.add("active");                    
                    document.getElementById("link_261").classList.add("active");
                }, 300);
                
                /*
                //AGGIUNGO LE OPZIONI CUSTOM DEL SITO
                for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                    if (this.arrayOpzioniConfigurazione[i].id == 100){
                        for (var j = 0 ; j < arrayOpzioni.length ; j++){
                            this.arrayOpzioniConfigurazione[i].opzioni.push({LABEL:arrayOpzioni[j].LABEL,VALORE:arrayOpzioni[j].VALORE,TYPE:'TEXTAREA',ID_OPZIONE:arrayOpzioni[j].ID_OPZIONE});
                        }
                        this.$forceUpdate();
                        break;
                    }
                }
				*/
            });
        },     

        salvaOpzioni : function(){
            var arr = new Array();
            for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                for (var j = 0 ; j < this.arrayOpzioniConfigurazione[i].opzioni.length ; j++){
                    arr.push(this.arrayOpzioniConfigurazione[i].opzioni[j]);
                }
            }
            for (var i = 0 ; i < this.arrayOpzioniCustom.length ; i++){
                arr.push(this.arrayOpzioniCustom[i]);
            }
            utils.ajax('core/admin/opzioni/update',{opzioni:arr,ID_ENTE:this.idEnteCorrente}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getOpzioni();
                }
            });
        },

        getConfigurazioniEnte : function(){
            this.getOpzioni();
        },

        successCaricamento : function(response){
            utils.alert.success(response.messaggio);
            this.getOpzioni();
        },

        errorCaricamento : function(response){
            utils.alert.warning(response.messaggio);
        },

        imgEnte(idOpzione){
            console.log("***");
            if (this.arrayOpzioniConfigurazione.length > 4){
                var img = utils.array.select(this.arrayOpzioniConfigurazione[4]['opzioni'],"ID_OPZIONE_BASE",idOpzione,1)[0];
                if (img != undefined){
                    return img['VALORE'];
                }
            }
            return "";
        },

        nuovaOpzioneCustom(){
            this.arrayOpzioniCustom.push({ID_OPZIONE_BASE:0, ETICHETTA:'-',LABEL:'-',TYPE:"TEXTAREA",VALORE:'-', COL:'col-12 col-md-6',CLASSE_CTRL:'',GROUP:1,ICON: 'far fa-ballot-check'},);
        },

    },

    computed: {
        uploadDisabled() {
            return this.files.length === 0;
        }
    },

    beforeCreate : function(){
        setTimeout(() => {
            global.utils.deltaGriglia = 100;   
            global.utils.resizeContent();     
        }, 300);
    },
    
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },

    created: function () {
        this.idEnteCorrente = this.$root.idEnteCorrente;
        this.getOpzioni();
    },

}
</script>